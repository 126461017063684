import { Action } from 'store/models';

export const namespace = 'CHART';

export const INIT_FETCH_SECONDARY_CHART_DATA = `${namespace}/INIT_FETCH_SECONDARY_CHART_DATA`;
export const POPULATE_PRIMARY_CHART_DATA = `${namespace}/POPULATE_PRIMARY_CHART_DATA`;

export const initFetchSecondaryChartData = () => ({
  type: INIT_FETCH_SECONDARY_CHART_DATA,
});

export const populatePrimaryChartData = (payload: Action) => ({
  type: POPULATE_PRIMARY_CHART_DATA,
  payload,
});
