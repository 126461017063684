import * as R from 'ramda';
import { timeDay, utcDay, utcMinute } from 'd3-time';
import getTodayWithTimezoneOffset from './getTodayWithTimezoneOffset';

const calculateToday = (dbToday: string, todayOffset: number) => {
  const timeOffset = new Date().getTimezoneOffset();

  if (dbToday && !todayOffset) {
    return timeDay.round(utcMinute.offset(new Date(dbToday), timeOffset));
  } else if (dbToday && todayOffset) {
    return utcDay.offset(
      timeDay.round(utcMinute.offset(new Date(dbToday), timeOffset)),
      todayOffset,
    );
  }
  const today = getTodayWithTimezoneOffset();

  if (!R.isNil(todayOffset) && todayOffset !== 0) {
    return timeDay.offset(today, todayOffset);
  }
  return today;
};

export default calculateToday;
