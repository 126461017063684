import { utcDay } from 'd3-time';
import * as R from 'ramda';
import { all, put, takeLatest, select } from 'redux-saga/effects';

import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';

import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import {
  initDrilldownTableFetch,
  setMaxDrilldownTableDate,
  setMinDrilldownTableDate,
  SET_DRILLDOWN_TABLE_PARAMS,
} from 'modules/drilldownTable/DrilldownTableActions';
import {
  getDrilldownTableParams,
  getIsMarkingRows,
} from 'modules/drilldownTable/DrilldownTableReducer';
import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';
import { SET_WELL_REASON } from 'modules/ui/UIActions';
import { getColumnMapping } from 'modules/well/WellReducer';
import { parseSearchParams } from 'modules/router/utils/router';

import {
  changeVisibilityStatus,
  fetchWellForecastDateRange,
  FETCH_GROUP_FORECAST,
  FETCH_GROUP_FORECAST_DATE_RANGE,
  FETCH_NET_GROUP_FORECAST,
  FETCH_NET_GROUP_FORECAST_DATE_RANGE,
  FETCH_WELL_FORECAST,
  FETCH_WELL_FORECAST_DATE_RANGE,
  populateGroupForecast,
  populateWellForecast,
} from './ExternalForecastActions';
import { InitialForecastData } from './models';
import { normalizeForecastData } from './utils';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import { POPULATE_PRIMARY_CHART_DATA } from 'modules/chart/ChartActions';
import { getCurrentWellId } from 'modules/ui/UIReducer';

export function* fetchExternalForecastSaga(action): Generator<any, any, any> {
  const reason: SET_WELL_REASON = action.payload?.reason;
  if (reason === 'URL_CHANGE') return;

  let wellId = action.payload?.id;
  if (!wellId) {
    wellId = yield select(getCurrentWellId);
  }

  const extemeDates = yield select(getExtremeDates);
  const searchString = window.location.search;
  const routeSearchParams = parseSearchParams(searchString);
  const dateSource = R.isNil(extemeDates) ? routeSearchParams : extemeDates;
  yield put(
    fetchWellForecastDateRange({
      wellId,
      minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
      maxDate: R.pathOr(new Date(), ['max'], dateSource),
    }),
  );
}

function* switchForecastSaga(action): Generator<any, any, any> {
  const compareOption = R.path(['payload', 'compareOption'], action);
  if (compareOption && compareOption !== COMPARE_OPTION.actual) {
    yield put(changeVisibilityStatus({ status: true }));
  }

  if (compareOption && compareOption !== COMPARE_OPTION.extVsCap) {
    const config = yield select(getAppConfig);
    const drilldowntTableParams = yield select(getDrilldownTableParams);
    const lastAcceptableDate = utcDay.offset(config.today, -1);
    const isMinDayOutOfBounds =
      utcDay.count(drilldowntTableParams.minDate, lastAcceptableDate) < 0;
    const isMaxDayOutOfBounds =
      utcDay.count(drilldowntTableParams.maxDate, lastAcceptableDate) < 0;
    const minDate = utcDay.offset(config.today, -8);

    if (isMinDayOutOfBounds) {
      yield put(setMinDrilldownTableDate(minDate));
    }
    if (isMaxDayOutOfBounds) {
      const maxDate = utcDay.offset(config.today, -1);
      yield put(setMaxDrilldownTableDate(maxDate));
      yield put(
        initDrilldownTableFetch({
          minDate,
          maxDate,
        }),
      );
    }
  }
}

function* normalizeGroupChartSaga(action): Generator<any, any, any> {
  const { groupForecastData } = getGraphqlPayload(action);
  const isMarkingRows = yield select(getIsMarkingRows);
  const chartData: InitialForecastData = JSON.parse(groupForecastData);
  const prevActionPayload = getGraphqlPrevActionVariables(action);
  const isNet =
    action.type === `${FETCH_NET_GROUP_FORECAST}_SUCCESS` ||
    action.type === `${FETCH_NET_GROUP_FORECAST_DATE_RANGE}_SUCCESS`;
  const {
    payload: { group },
  } = prevActionPayload;
  const columnMapping = yield select(getColumnMapping);
  const subject = isMarkingRows
    ? '_marked'
    : group
    ? columnMapping.find(column => column.columnIndex === group.columnIndex)
        .sourceName
    : 'all';
  const groupName = isMarkingRows ? '_marked' : group ? group.name : 'all';
  const firstDataIndex = chartData.groupData.findIndex(
    el => el.oil !== 0 || el.gas !== 0 || el.water !== 0,
  );
  const normalizedData = normalizeForecastData(
    chartData.groupData.slice(firstDataIndex),
  );

  yield put(
    populateGroupForecast({
      groupSubject: subject,
      groupName: groupName,
      forecastData: normalizedData,
      isNet,
    }),
  );
}

function* normalizeWellForecastSaga(action): Generator<any, any, any> {
  const { forecastData } = getGraphqlPayload(action);
  const data = JSON.parse(forecastData);

  if (R.isEmpty(data)) {
    const {
      payload: { wellId },
    } = getGraphqlPrevActionVariables(action);

    return yield put(
      populateWellForecast({
        data: [],
        isPartialData:
          action.type === `${FETCH_WELL_FORECAST_DATE_RANGE}_SUCCESS`,
        wellId,
      }),
    );
  }

  const normalizedData = normalizeForecastData(data);
  const wellId = data[0].wellId;

  yield put(
    populateWellForecast({
      data: normalizedData,
      isPartialData:
        action.type === `${FETCH_WELL_FORECAST_DATE_RANGE}_SUCCESS`,
      wellId,
    }),
  );
}

function* normalizePartialWellForecastSaga(action): Generator<any, any, any> {
  if (action.payload.type !== `${FETCH_WELL_FORECAST_DATE_RANGE}_SUCCESS`)
    return;

  yield normalizeWellForecastSaga(action.payload);
}

function* externalForecastSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(SET_DRILLDOWN_TABLE_PARAMS, switchForecastSaga),
    takeLatest(
      [
        `${FETCH_GROUP_FORECAST}_SUCCESS`,
        `${FETCH_NET_GROUP_FORECAST}_SUCCESS`,
        `${FETCH_GROUP_FORECAST_DATE_RANGE}_SUCCESS`,
        `${FETCH_NET_GROUP_FORECAST_DATE_RANGE}_SUCCESS`,
      ],
      normalizeGroupChartSaga,
    ),
    takeLatest(`${FETCH_WELL_FORECAST}_SUCCESS`, normalizeWellForecastSaga),
    takeLatest(POPULATE_PRIMARY_CHART_DATA, normalizePartialWellForecastSaga),
  ]);
}

export default externalForecastSagas;
