import { area, curveStepAfter } from 'd3-shape';
import { timeDay, timeHour } from 'd3-time';
import * as React from 'react';

import type { ProductionPoint } from 'modules/production/models/production';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import { getEventClipPathId } from '../../utils';

interface EventClickableAreaProps {
  eventCapacityData: { date: Date; capacity: number }[];
  eventProductionData: ProductionPoint[];
  eventIndex: number;
  isAxisDragging: boolean;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  productionKey: string;
  title: string;
  varianceEvent: VarianceEvent;
  xScale: any;
  yScale: any;
}

const EventClickableArea = ({
  eventCapacityData,
  eventProductionData,
  eventIndex,
  isAxisDragging,
  onVarianceDialogOpen,
  productionKey,
  title,
  varianceEvent,
  xScale,
  yScale,
}: EventClickableAreaProps) => {
  const additionalDates: any = [];
  if (
    eventProductionData[0] &&
    eventCapacityData[eventCapacityData.length - 1] &&
    eventCapacityData[eventCapacityData.length - 1].date.getTime() <
      eventProductionData[0].day.getTime()
  ) {
    const missingDays = Math.ceil(
      (eventProductionData[0].day.getTime() -
        eventCapacityData[eventCapacityData.length - 1].date.getTime()) /
        1000 /
        60 /
        60 /
        24,
    );
    for (let i = 0; i < missingDays + 1; i++) {
      if (!eventCapacityData[eventCapacityData.length - 1 - i * 2]) break;
      additionalDates.push(
        eventCapacityData[eventCapacityData.length - 1 - i * 2],
      );
    }
  }
  const createVarianceArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.date || 0))
        .y1((d: any) => yScale(d.capacity))
        .y0(yScale(0)),
    [xScale, yScale],
  );
  const createClipArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.day || 0))
        .y1((d: any) => {
          if (d.date) return yScale(d.capacity);
          return yScale(d[productionKey]);
        })
        .y0((d: any) => {
          if (d.date) return yScale.range()[0];
          return yScale.range()[1];
        })
        .curve(curveStepAfter),
    [xScale, yScale, productionKey],
  );

  const lastEventDayLimit = timeDay.offset(varianceEvent.dayEnd, 1).getTime();
  const firstEventDayLimit = varianceEvent.dayStart.getTime();

  const eventCapacityDataWithEmptyDate = React.useMemo(() => {
    if (eventCapacityData.length === 0) return eventCapacityData;
    const lastDate = eventCapacityData[0];

    eventCapacityData.unshift({
      ...lastDate,
      date: timeHour.offset(lastDate.date, 12),
    });

    return eventCapacityData;
  }, [eventCapacityData]);

  const filteredData = React.useMemo(() => {
    const filtered = eventProductionData.filter(
      dataPoint =>
        dataPoint.day.getTime() <= lastEventDayLimit &&
        dataPoint.day.getTime() >= firstEventDayLimit,
    );

    if (filtered.length === 0) return filtered;

    const lastProductionDate = filtered[filtered.length - 1].day;

    const daysLeft = timeDay.count(
      lastProductionDate,
      new Date(lastEventDayLimit),
    );
    if (daysLeft <= 0) return filtered;
    return filtered.concat(
      timeDay
        .range(
          timeDay.offset(lastProductionDate, 1),
          timeDay.offset(lastProductionDate, daysLeft + 1),
          1,
        )
        .map(day => ({
          day,
          boe: 0,
          gas: 0,
          glr: 0,
          gor: 0,
          oil: 0,
          water: 0,
          watercut: 0,
          total_liquid: 0,
          wellId: '',
        })),
    );
  }, []);

  const createProductionArea: (data: any) => any = area()
    .x((d: any) => xScale(d.day || 0))
    .y1((d: any) => yScale(d[productionKey]))
    .y0(yScale(0))
    .curve(curveStepAfter);

  const handleClick = React.useCallback(
    e => onVarianceDialogOpen(eventIndex, varianceEvent.id),
    [onVarianceDialogOpen, eventIndex, varianceEvent],
  );

  return (
    <>
      <path
        d={createVarianceArea(eventCapacityDataWithEmptyDate)}
        onMouseUp={handleClick}
        fill="transparent"
        cursor={isAxisDragging ? 'grabbing' : 'pointer'}
        clipPath={`url(#clickableEventClip${varianceEvent.id + productionKey})`}
        className="variance-interactive interactive panInteraction"
      />
      <defs>
        <clipPath id={`clickableEventClip${varianceEvent.id + productionKey}`}>
          <path
            d={
              filteredData.length !== 0
                ? createClipArea(additionalDates.concat(filteredData))
                : createClipArea(filteredData)
            }
          />
        </clipPath>
      </defs>
      <path
        d={createProductionArea(filteredData)}
        clipPath={`url(#${getEventClipPathId(title, varianceEvent.id)}`}
        onMouseUp={handleClick}
        fill="transparent"
        cursor={isAxisDragging ? 'grabbing' : 'pointer'}
        className="variance-interactive interactive panInteraction"
      />
    </>
  );
};

export default React.memo<EventClickableAreaProps>(EventClickableArea);
