import * as R from 'ramda';
import * as React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import { NormalizedSeriesMapping } from 'modules/series/models';

import {
  AvailableDataSeries,
  ChartOptionsGroup as ChartOptionsGroupType,
  DraggableOption,
  ITEM_TYPES,
  ItemTypes,
  ListChartOptions,
} from '../models';
import ChartOptionsGroup from './ChartOptionsGroup';
import IntergroupSpace from './IntergroupSpace';

const getGroupType = (
  optionsList: ListChartOptions,
  group: ChartOptionsGroupType,
): string =>
  group.options.length > 1 ? ITEM_TYPES.group : ITEM_TYPES.singleOptionGroup;

interface ChartOptionsSectionProps {
  groups: ChartOptionsGroupType[];
  hoveredGroupIndex: number | null;
  hoveredOptionIndex: number | null;
  isInsideAvaliableArea: boolean;
  isShowOriginOption: boolean;
  itemType: string;
  onChartTypeMenuOpen: (data: {
    optionId: string;
    elemRect: ClientRect;
    event: MouseEvent;
  }) => void;
  onAddOptionToGroup: (data: {
    groupId: string;
    optionId: string;
    newOptionIndex: number;
    previousGroupId?: string;
  }) => void;
  onCreateNewGroup: (data: {
    optionId: string;
    newGroupIndex: number;
    groupId?: string;
  }) => void;
  onMoveGroup: (groupIndex: number, hoveredGroupIndex: number) => void;
  onSetHoveredGroupIndex: (value: number | null) => void;
  onSetHoveredOptionIndex: (value: number | null) => void;
  onSetIsShowOriginOption: (value: boolean) => void;
  onSetNewOptionsOrder: (option: ChartOptionsGroupType) => void;
  onSetTempGroupIndex: (value: number | null) => void;
  onStopGroupMoving: () => void;
  onStopHovering: () => void;
  onSwitchOptionVisibility: (optionId: string) => void;
  options: ListChartOptions;
  onRemoveOption: (groupId: string, option: AvailableDataSeries) => void;
  seriesMapping: NormalizedSeriesMapping;
  tempGroupIndex: number | null;
  isInsideDragArea: boolean;
  setNewGroupIsCreated: any;
}

const ChartOptionsSection = ({
  groups,
  onAddOptionToGroup,
  hoveredGroupIndex,
  hoveredOptionIndex,
  isInsideAvaliableArea,
  isShowOriginOption,
  onChartTypeMenuOpen,
  onCreateNewGroup,
  onMoveGroup,
  onSetHoveredGroupIndex,
  onSetHoveredOptionIndex,
  onSetIsShowOriginOption,
  onSetNewOptionsOrder,
  onSetTempGroupIndex,
  onStopHovering,
  onSwitchOptionVisibility,
  options,
  onRemoveOption,
  seriesMapping,
  tempGroupIndex,
  isInsideDragArea,
  onStopGroupMoving,
  setNewGroupIsCreated,
}: ChartOptionsSectionProps) => {
  const [draggedGroupIndex, setDraggedGroupIndex] = React.useState<
    number | null
  >(null);

  const [movedOption, setMovedOption] = React.useState<DraggableOption>({
    optionId: '',
    groupId: '',
  });

  const onSetMovedOption = React.useCallback(
    (value: DraggableOption) => {
      setMovedOption(value);
    },
    [setMovedOption],
  );

  const onSetDraggedGroupIndex = React.useCallback(
    (index: number | null) => {
      setDraggedGroupIndex(index);
    },
    [setDraggedGroupIndex],
  );
  const [isShowDraggedGroup, setIsShowDraggedGroup] = React.useState(true);

  const onSetIsShowDraggedGroup = React.useCallback(
    (value: boolean) => {
      if (value !== isShowDraggedGroup) {
        setIsShowDraggedGroup(value);
      }
    },
    [setIsShowDraggedGroup, isShowDraggedGroup],
  );

  const [, connectDropTarget] = useDrop(
    {
      accept: ItemTypes.SERIES,
      collect: monitor => ({ isOver: monitor.isOver() }),
      drop: (item: any, monitor) => {
        if (
          !monitor.didDrop() &&
          item.props.itemType === ITEM_TYPES.available
        ) {
          const newGroupIndex = groups.length;
          onCreateNewGroup({
            optionId: item.props.option.id,
            newGroupIndex,
          });
        }
      },
    },
    [groups, onCreateNewGroup, onStopGroupMoving],
  );

  return connectDropTarget(
    <div style={{ height: '100%' }}>
      <ChartOptionsSection.Wrapper isHovered={false}>
        {groups && !R.isEmpty(groups) && (
          <ChartOptionsSection.Content>
            {groups.map((group, i) => (
              <div key={group.groupId}>
                {!(draggedGroupIndex === i && !R.isNil(hoveredOptionIndex)) && (
                  <IntergroupSpace
                    index={i}
                    key={'intergroup' + group.groupId}
                    hoveredGroupIndex={hoveredGroupIndex}
                    hoveredOptionIndex={hoveredOptionIndex}
                    isInsideAvaliableArea={isInsideAvaliableArea}
                    isShowDraggedGroup={isShowDraggedGroup}
                    onCreateNewGroup={onCreateNewGroup}
                    onSetDraggedGroupIndex={onSetDraggedGroupIndex}
                    onSetHoveredGroupIndex={onSetHoveredGroupIndex}
                    onSetHoveredOptionIndex={onSetHoveredOptionIndex}
                    onSetIsShowDraggedGroup={onSetIsShowDraggedGroup}
                    onSetIsShowOriginOption={onSetIsShowOriginOption}
                    onSetTempGroupIndex={onSetTempGroupIndex}
                    tempGroupIndex={tempGroupIndex}
                    isInsideDragArea={isInsideDragArea}
                    onStopHovering={onStopHovering}
                    nextGroup={group}
                    previousGroup={i === 0 ? null : groups[i - 1]}
                    options={options}
                    onStopGroupMoving={onStopGroupMoving}
                  />
                )}
                <ChartOptionsGroup
                  key={group.groupId}
                  currentGroup={group}
                  hoveredGroupIndex={hoveredGroupIndex}
                  hoveredOptionIndex={hoveredOptionIndex}
                  index={i}
                  isInsideAvaliableArea={isInsideAvaliableArea}
                  isShowOriginOption={isShowOriginOption}
                  itemType={getGroupType(options, group)}
                  movedOption={movedOption}
                  onAddOptionToGroup={onAddOptionToGroup}
                  onChartTypeMenuOpen={onChartTypeMenuOpen}
                  onCreateNewGroup={onCreateNewGroup}
                  onMoveGroup={onMoveGroup}
                  onSetHoveredGroupIndex={onSetHoveredGroupIndex}
                  onSetHoveredOptionIndex={onSetHoveredOptionIndex}
                  onSetIsShowDraggedGroup={onSetIsShowDraggedGroup}
                  onSetIsShowOriginOption={onSetIsShowOriginOption}
                  onSetMovedOption={onSetMovedOption}
                  onSetNewOptionsOrder={onSetNewOptionsOrder}
                  onSetTempGroupIndex={onSetTempGroupIndex}
                  isShowDraggedGroup={isShowDraggedGroup}
                  onStopHovering={onStopHovering}
                  onSwitchOptionVisibility={onSwitchOptionVisibility}
                  options={options}
                  onRemoveOption={onRemoveOption}
                  tempGroupIndex={tempGroupIndex}
                  seriesMapping={seriesMapping}
                  onStopGroupMoving={onStopGroupMoving}
                  setNewGroupIsCreated={setNewGroupIsCreated}
                />
              </div>
            ))}
            <IntergroupSpace
              index={groups.length}
              hoveredGroupIndex={hoveredGroupIndex}
              hoveredOptionIndex={hoveredOptionIndex}
              isInsideAvaliableArea={isInsideAvaliableArea}
              isShowDraggedGroup={isShowDraggedGroup}
              onCreateNewGroup={onCreateNewGroup}
              onSetDraggedGroupIndex={onSetDraggedGroupIndex}
              onSetHoveredGroupIndex={onSetHoveredGroupIndex}
              onSetHoveredOptionIndex={onSetHoveredOptionIndex}
              onSetIsShowDraggedGroup={onSetIsShowDraggedGroup}
              onSetIsShowOriginOption={onSetIsShowOriginOption}
              onSetTempGroupIndex={onSetTempGroupIndex}
              tempGroupIndex={tempGroupIndex}
              isInsideDragArea={isInsideDragArea}
              onStopHovering={onStopHovering}
              nextGroup={null}
              previousGroup={groups[groups.length - 1]}
              options={options}
              onStopGroupMoving={onStopGroupMoving}
            />
          </ChartOptionsSection.Content>
        )}
        {R.isEmpty(groups) && (
          <IntergroupSpace
            index={groups.length}
            hoveredGroupIndex={hoveredGroupIndex}
            hoveredOptionIndex={hoveredOptionIndex}
            isInsideAvaliableArea={isInsideAvaliableArea}
            isShowDraggedGroup={isShowDraggedGroup}
            onCreateNewGroup={onCreateNewGroup}
            onSetDraggedGroupIndex={onSetDraggedGroupIndex}
            onSetHoveredGroupIndex={onSetHoveredGroupIndex}
            onSetHoveredOptionIndex={onSetHoveredOptionIndex}
            onSetIsShowDraggedGroup={onSetIsShowDraggedGroup}
            onSetIsShowOriginOption={onSetIsShowOriginOption}
            onSetTempGroupIndex={onSetTempGroupIndex}
            tempGroupIndex={tempGroupIndex}
            isInsideDragArea={isInsideDragArea}
            onStopHovering={onStopHovering}
            nextGroup={null}
            previousGroup={null}
            options={options}
            onStopGroupMoving={onStopGroupMoving}
          />
        )}
      </ChartOptionsSection.Wrapper>
    </div>,
  );
};

ChartOptionsSection.Wrapper = styled.div`
  padding: 3px 16px 0;
  height: 100%;
`;

ChartOptionsSection.Header = styled.div`
  position: relative;
  top: 7px;
  width: 100%;
  background: white;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 60;
`;

ChartOptionsSection.Content = styled.div`
  position: relative;
  z-index: 50;
`;

export default ChartOptionsSection;
