import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import { isIdNew } from 'helpers';
import type { Action, RootState, Selector } from 'store/models';
import { filterWells } from 'modules/filter/utils/filter';
import type { Well } from 'modules/well/models/well';
import { getGraphqlPayload } from 'store/helpers';

import type { AllocIssue } from './models/allocIssue';
import {
  DELETE_LOCAL_ALLOC_ISSUE,
  POPULATE_ALLOC_ISSUES,
  CREATE_REMOTE_ALLOC_ISSUE,
} from './AllocIssueActions';
import { normalizeAllocIssues } from './utils';
import { Reducer } from 'redux';

export const STATE_KEY = 'allocIssue';

export interface AllocIssueState {
  [wellId: string]: AllocIssue[];
}

const initialState: AllocIssueState = {};

const AllocIssueReducer = (
  state: AllocIssueState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case POPULATE_ALLOC_ISSUES: {
      const issues = action.payload;

      return R.mergeRight(state, issues);
    }
    case DELETE_LOCAL_ALLOC_ISSUE: {
      const { wellId, id } = action.payload;
      const wellIssues = state[wellId] || [];
      const newWellIssues = wellIssues.filter(issue => issue.id !== id);

      return R.assoc<Record<string, any>, AllocIssueState>(
        wellId,
        newWellIssues,
        state,
      );
    }
    case CREATE_REMOTE_ALLOC_ISSUE: {
      const { wellId, dateStart, dateEnd } =
        action.payload.graphql.variables.payload;
      const wellIssues = state[wellId] || [];
      const newWellIssues = wellIssues.map(issue => {
        if (
          issue.dateStart.getTime() === dateStart.getTime() &&
          issue.dateEnd.getTime() === dateEnd.getTime()
        ) {
          return { ...issue, syncing: true };
        }
        return issue;
      });

      return R.assoc<Record<string, any>, AllocIssueState>(
        wellId,
        newWellIssues,
        state,
      );
    }
    case `${CREATE_REMOTE_ALLOC_ISSUE}_SUCCESS`: {
      const newIssue = getGraphqlPayload(action);
      const { wellId } = newIssue;
      const wellIssues = state[wellId] || [];
      const updatedWellIssues = wellIssues.map(issue => {
        if (
          isIdNew(issue.id) &&
          issue.syncing === true &&
          issue.dateStart.getTime() ===
            new Date(newIssue.dateStart).getTime() &&
          issue.dateEnd.getTime() === new Date(newIssue.dateEnd).getTime()
        ) {
          const normalized = normalizeAllocIssues([newIssue]);
          return normalized[wellId][0];
        }
        return issue;
      });

      return R.assoc<Record<string, any>, AllocIssueState>(
        wellId,
        updatedWellIssues,
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getAllAllocIssuesByWell = (state: RootState) =>
  state[STATE_KEY] as AllocIssueState;

export const getWellAllocIssues = createSelector(
  getAllAllocIssuesByWell,
  (_, props) => props.wellId as string,
  (allocIssues, wellId) => allocIssues[wellId] || [],
);

export const getAllocIssuesByStatusId = (_: RootState): AllocIssue => {
  const issuesByWell = R.propOr({}, STATE_KEY, _);
  const allIssues = R.flatten(R.values(issuesByWell));
  const issuesByStatusId = R.groupBy(R.prop('statusId'), allIssues);
  return issuesByStatusId;
};

export const getAllAllocIssueAsArray: Selector<AllocIssue[]> = R.compose<
  Record<string, any>,
  AllocIssueState,
  Record<string, any>,
  AllocIssue[][],
  AllocIssue[]
>(
  R.flatten,
  R.values,
  R.propOr<Record<string, any>, AllocIssueState, Record<string, any>>(
    {},
    STATE_KEY,
  ),
);

export const getFilteredAllocIssues = (
  _: RootState,
  filters: { [filterName: string]: string[] },
  wells: Well[],
): AllocIssue[] => {
  const allocIssues: AllocIssue[] = R.compose(
    R.flatten,
    R.values,
    R.propOr<any, any, any>({}, STATE_KEY),
  )(_);
  const filteredWells = filterWells(wells, filters);
  const wellIds = filteredWells.map(well => well.id);
  return allocIssues.filter(issue => wellIds.includes(issue.wellId));
};

export default filterActions(AllocIssueReducer, [
  DELETE_LOCAL_ALLOC_ISSUE,
  POPULATE_ALLOC_ISSUES,
  CREATE_REMOTE_ALLOC_ISSUE,
  `${CREATE_REMOTE_ALLOC_ISSUE}_SUCCESS`,
]) as Reducer<AllocIssueState>;
