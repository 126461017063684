import * as R from 'ramda';
import gql from 'graphql-tag';

import { GraphqlQueryAction, Action, ActionContext } from 'store/models';
import wellRequestsAbortController from 'modules/well/utils/wellRequestsAbortController';

import { NormalizedSeries } from './models';

export const namespace = 'SERIES';

export const FETCH_SERIES = `${namespace}/FETCH_SERIES`;
export const CLEAR_SERIES = `${namespace}/CLEAR_SERIES`;
export const FETCH_SENSOR_SERIES = `${namespace}/FETCH_SENSOR_SERIES`;
export const FETCH_CALCULATED_SERIES = `${namespace}/FETCH_CALCULATED_SERIES`;
export const REFETCH_SENSOR_SERIES = `${namespace}/REFETCH_SENSOR_SERIES`;
export const REFETCH_CALCULATED_SERIES = `${namespace}/REFETCH_CALCULATED_SERIES`;
export const INIT_FETCH_SENSOR_SERIES = `${namespace}/INIT_FETCH_SENSOR_SERIES`;
export const INIT_FETCH_CALCULATED_SERIES = `${namespace}/INIT_FETCH_CALCULATED_SERIES`;
export const INIT_FETCH_WELL_SERIES = `${namespace}/INIT_FETCH_WELL_SERIES`;
export const FETCH_SERIES_DATE_RANGE = `${namespace}/FETCH_SERIES_DATE_RANGE`;
export const REFETCH_SERIES = `${namespace}/REFETCH_SERIES`;
export const FETCH_SERIES_MAPPING = `${namespace}/FETCH_SERIES_MAPPING`;
export const FETCH_SENSOR_SERIES_MAPPING = `${namespace}/FETCH_SENSOR_SERIES_MAPPING`;
export const FETCH_CALCULATED_SERIES_MAPPING = `${namespace}/FETCH_CALCULATED_SERIES_MAPPING`;
export const FETCH_GROUP_SERIES = `${namespace}/FETCH_GROUP_SERIES`;
export const REFETCH_GROUP_SERIES = `${namespace}/REFETCH_GROUP_SERIES`;
export const SWITCH_SERIES_MAPPING = `${namespace}/SWITCH_SERIES_MAPPING`; // outdated
export const SET_GROUP_SERIES = `${namespace}/SET_GROUP_SERIES`;
export const POPULATE_SENSOR_SERIES = `${namespace}/POPULATE_SENSOR_SERIES`;
export const POPULATE_CALCULATED_SERIES = `${namespace}/POPULATE_CALCULATED_SERIES`;
export const POPULATE_WELL_SERIES = `${namespace}/POPULATE_WELL_SERIES`;
export const CLEAR_ALL_GROUP_SERIES = `${namespace}/CLEAR_ALL_GROUP_SERIES`;
export const CANCEL_PREVIOUS_SENSOR_SERIES_FETCHING = `${namespace}/CANCEL_PREVIOUS_SENSOR_SERIES_FETCHING`;

type FetchWellSeries = (payload: {
  wellId: string;
  seriesIndexes: [string];
}) => Action;
type FetchWellSeriesDateRange = (payload: {
  wellId: string;
  minDate: Date;
  maxDate: Date;
  series: string[];
}) => Action;

type fetchAction = () => GraphqlQueryAction;

const FETCH_SERIES_MAPPING_QUERY = gql`
  query {
    listSeriesMappingJSON {
      seriesMapping
    }
  }
`;

const FETCH_SENSOR_SERIES_MAPPING_QUERY = gql`
  query {
    listSensorSeriesJSON {
      sensorSeries
    }
  }
`;

const FETCH_CALCULATED_SERIES_MAPPING_QUERY = gql`
  query {
    listCalculatedSeries {
      calculatedSeriesId
      source
      name
      tableName
      color
      units
      wellCalculatedSeries {
        wellId
      }
    }
  }
`;

const FETCH_SERIES_QUERY = gql`
  query ($payload: SeriesInput!) {
    listWellSeries(data: $payload) {
      seriesChartData
    }
  }
`;

const FETCH_SENSOR_SERIES_QUERY = gql`
  query ($payload: SensorSeriesDataInput!) {
    listSensorSeriesData(data: $payload) {
      data
    }
  }
`;

const FETCH_CALCULATED_SERIES_QUERY = gql`
  query ($payload: CalculatedSeriesDataInput!) {
    listCalculatedSeriesData(data: $payload) {
      data
    }
  }
`;

const FETCH_SERIES_DATE_RANGE_QUERY = gql`
  query ($payload: SeriesDateRangeInput!) {
    listWellSeriesDateRange(data: $payload) {
      seriesChartData
    }
  }
`;

const GET_GROUP_SERIES_QUERY = gql`
  query ($payload: GroupSeriesInput!) {
    listGroupSeries(data: $payload) {
      seriesChartData
    }
  }
`;

export const fetchSeriesMapping: fetchAction = () => ({
  type: FETCH_SERIES_MAPPING,
  payload: {
    key: 'listSeriesMappingJSON',
    graphql: {
      query: FETCH_SERIES_MAPPING_QUERY,
    },
  },
});

export const fetchSensorSeriesMapping: fetchAction = () => ({
  type: FETCH_SENSOR_SERIES_MAPPING,
  payload: {
    key: 'listSensorSeriesJSON',
    graphql: {
      query: FETCH_SENSOR_SERIES_MAPPING_QUERY,
    },
  },
});

export const fetchCalculatedSeriesMapping: fetchAction = () => ({
  type: FETCH_CALCULATED_SERIES_MAPPING,
  payload: {
    key: 'listCalculatedSeries',
    graphql: {
      query: FETCH_CALCULATED_SERIES_MAPPING_QUERY,
    },
  },
});

export const fetchWellSeriesDateRange: FetchWellSeriesDateRange = payload => ({
  type: FETCH_SERIES_DATE_RANGE,
  payload: {
    key: 'listWellSeriesDateRange',
    graphql: {
      query: FETCH_SERIES_DATE_RANGE_QUERY,
      variables: {
        payload,
      },
    },
    options: {
      getContext: wellRequestsAbortController.getContext,
    },
  },
});

type fetchSensorSeriesAC = (
  payload: {
    seriesId: string;
    tagId: string;
    minDate: Date;
    maxDate: Date;
    pointsCount: number;
    wellId: string;
  },
  context: ActionContext,
) => GraphqlQueryAction;

export const fetchSensorSeries: fetchSensorSeriesAC = (payload, context) => ({
  type: FETCH_SENSOR_SERIES,
  payload: {
    meta: {
      seriesId: payload.seriesId,
    },
    key: 'listSensorSeriesData',
    graphql: {
      query: FETCH_SENSOR_SERIES_QUERY,
      variables: {
        payload: R.omit(['seriesId', 'wellId'], payload),
      },
    },
    context,
  },
  meta: {
    seriesId: payload.seriesId,
    wellId: payload.wellId,
  },
});

type fetchCalculatedSeriesAC = (payload: {
  tableName: string;
  minDate: Date;
  maxDate: Date;
  pointsCount: number;
  wellId: string;
  seriesId: string;
}) => GraphqlQueryAction;

export const fetchCalculatedSeries: fetchCalculatedSeriesAC = ({
  minDate,
  maxDate,
  pointsCount,
  tableName,
  wellId,
  seriesId,
}) => ({
  type: FETCH_CALCULATED_SERIES,
  payload: {
    key: 'listCalculatedSeriesData',
    graphql: {
      query: FETCH_CALCULATED_SERIES_QUERY,
      variables: {
        payload: {
          minDate,
          maxDate,
          pointsCount,
          tableName,
          wellId,
        },
      },
    },
  },
  meta: {
    seriesId: seriesId,
    wellId: wellId,
  },
});

export const fetchWellSeries: FetchWellSeries = payload => ({
  type: FETCH_SERIES,
  payload: {
    key: 'listWellSeries',
    graphql: {
      query: FETCH_SERIES_QUERY,
      variables: {
        payload: {
          wellId: payload.wellId,
          seriesIndexes: payload.seriesIndexes,
        },
      },
    },
    options: {
      getContext: wellRequestsAbortController.getContext,
    },
  },
});

type fetchGroupSeriesAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: { columnIndex: number; values: string[] }[];
}) => GraphqlQueryAction;

export const fetchGroupSeries: fetchGroupSeriesAC = payload => ({
  type: FETCH_GROUP_SERIES,
  payload: {
    key: 'listGroupSeries',
    graphql: {
      query: GET_GROUP_SERIES_QUERY,
      variables: { payload },
    },
  },
});

type setGroupSeriesAC = (payload: {
  groupSubject: string;
  groupName: string;
  series: NormalizedSeries[];
}) => Action;

export const setGroupSeries: setGroupSeriesAC = payload => ({
  type: SET_GROUP_SERIES,
  payload,
});

export const initFetchWellSeries = () => ({
  type: INIT_FETCH_WELL_SERIES,
});

type initFetchSensorSeriesAC = (payload: {
  chartWidth: number;
  series?: string[];
}) => Action;

export const initFetchSensorSeries: initFetchSensorSeriesAC = payload => ({
  type: INIT_FETCH_SENSOR_SERIES,
  payload,
});

type initFetchCalculatedSeriesAC = (payload: {
  chartWidth: number;
  series?: string[];
}) => Action;

export const initFetchCalculatedSeries: initFetchCalculatedSeriesAC =
  payload => ({
    type: INIT_FETCH_CALCULATED_SERIES,
    payload,
  });

export const refetchSensorSeries: initFetchSensorSeriesAC = payload => ({
  type: REFETCH_SENSOR_SERIES,
  payload,
});

export const refetchCalculatedSeries: initFetchCalculatedSeriesAC =
  payload => ({
    type: REFETCH_CALCULATED_SERIES,
    payload,
  });

type populateSensorSeriesAC = (payload: {
  seriesId: string;
  data: NormalizedSeries[];
}) => Action;

export const populateSensorSeries: populateSensorSeriesAC = payload => ({
  type: POPULATE_SENSOR_SERIES,
  payload,
});

type populateCalculatedSeriesAC = (payload: {
  seriesId: string;
  data: NormalizedSeries[];
}) => Action;

export const populateCalculatedSeries: populateCalculatedSeriesAC =
  payload => ({
    type: POPULATE_CALCULATED_SERIES,
    payload,
  });

type populateWellSeriesAC = (payload: {
  wellId: string;
  data: any[];
  isPartialData: boolean;
}) => Action;

export const populateWellSeries: populateWellSeriesAC = payload => ({
  type: POPULATE_WELL_SERIES,
  payload,
});

export const cancelPreviousSensorSeriesFetching = (): Action => ({
  type: CANCEL_PREVIOUS_SENSOR_SERIES_FETCHING,
});

type clearAllGroupSeriesAC = (payload?: {
  subject: string;
  item: string;
}) => Action;

export const clearAllGroupSeries: clearAllGroupSeriesAC = payload => ({
  type: CLEAR_ALL_GROUP_SERIES,
  payload,
});

type refetchGroupSeriesAC = (payload?: {
  subject: string;
  item: string;
}) => Action;

export const refetchGroupSeries: refetchGroupSeriesAC = payload => ({
  type: REFETCH_GROUP_SERIES,
  payload,
});
export const refetchSeries = (): Action => ({
  type: REFETCH_SERIES,
});

export const clearSeries = (): Action => ({
  type: CLEAR_SERIES,
});
