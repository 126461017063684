import { utcDay, utcHour } from 'd3-time';
import { line } from 'd3-shape';
import { format as d3Format } from 'd3-format';
import * as R from 'ramda';
import * as React from 'react';

import useComponentSize from 'hooks/useComponentSize';
import MainBarsGroup from 'modules/chart/components/MainBarsGroup';
import ExternalForecastLine from 'modules/externalForecast/components/ExternalForecastLine';
import { ForecastData } from 'modules/externalForecast/models';
import type { TooltipData, TrellisTooltipData } from 'modules/ui/models/ui';
import { getTrellisGeneralClipPathId, getTrellisId } from 'modules/chart/utils';

import type { GroupChartPoint } from '../models/groupChart';
import GroupSelectedBar from './GroupSelectedBar';
import { approximatedSearchByDate } from 'helpers/approximatedSearchByDate';
import { useSelector } from 'store/models';
import { getYAxisHovered } from 'modules/ui/UIReducer';
import { measureText } from 'modules/filter/utils/text';
interface SVGGroupTrellisProps {
  dataMap: { [id: string]: GroupChartPoint };
  groupChartData: GroupChartPoint[];
  groupForecastData: ForecastData;
  height: number;
  isAxisDragging: boolean;
  isDisplayingForecast: boolean;
  maxDataPoint: number;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  today: Date;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  regionOfInterestMode: boolean;
  xScale: any;
  yScale: any;
  yAxisLinePos: number | null;
  minSummedCapacityDate: Date;
  format: any;
}

const SVGGroupTrellis = ({
  dataMap,
  groupChartData,
  groupForecastData,
  height,
  isAxisDragging,
  isDisplayingForecast,
  onXAxisScaling,
  onSetTooltipData,
  today,
  tooltipData,
  trellisTitle,
  regionOfInterestMode,
  xScale,
  yScale,
  yAxisLinePos,
  minSummedCapacityDate,
  format,
}: SVGGroupTrellisProps) => {
  const svgEl: React.RefObject<HTMLElement | null> = React.useRef(null);
  const svgBoundingRect = useComponentSize(svgEl);

  const yAxisHovered = useSelector(getYAxisHovered);

  const onWheelHandler = React.useCallback(
    e => {
      onXAxisScaling(e, svgEl);
    },
    [svgEl, onXAxisScaling],
  );

  const capacityLine: (data: any) => any = React.useMemo(
    () =>
      line()
        .x((d: any) => xScale(utcHour.offset(d.day, 12) || 0))
        .y((d: any) => yScale(d.capacity)),
    [xScale, yScale],
  );

  const capacityData = React.useMemo(() => {
    if (!minSummedCapacityDate) return groupChartData;

    const index = approximatedSearchByDate(
      groupChartData,
      utcDay.floor(minSummedCapacityDate),
      (date, e) => date.getTime() - e.day.getTime(),
    );

    if (index < 0) return groupChartData;

    return groupChartData.slice(index);
  }, [groupChartData, minSummedCapacityDate]);

  const pathD = React.useMemo(
    () => capacityLine(capacityData),
    [capacityData, capacityLine],
  );

  const groupForecastWithEmptyDay = React.useMemo(() => {
    const lastDay = groupForecastData[groupForecastData.length - 1];
    if (!lastDay) return groupForecastData;

    return groupForecastData.concat([
      { ...lastDay, day: utcDay.offset(lastDay.day, 1) },
    ]);
  }, [groupForecastData]);

  const cursorRate = React.useMemo(
    () => d3Format(format)(yScale.invert(yAxisLinePos)),
    [yAxisLinePos, format, yScale],
  );

  const backgroundTextWidth = React.useMemo(
    () => measureText(cursorRate, 12) + 3,
    [cursorRate],
  );

  return (
    <svg
      //@ts-expect-error
      ref={svgEl}
      className="trellis_chart panInteraction"
      height="100%"
      id={getTrellisId(trellisTitle)}
      preserveAspectRatio="none"
      viewBox={`0 0 ${xScale.range()[1]} ${height}`}
      width={xScale.range()[1]}
      onWheel={onWheelHandler}
      style={{ cursor: isAxisDragging ? 'grabbing' : 'auto' }}
    >
      <defs>
        <clipPath id={getTrellisGeneralClipPathId(trellisTitle)}>
          <rect height={height} width={xScale.range()[1] - 1} />
        </clipPath>
      </defs>
      {groupChartData && !R.isEmpty(groupChartData) && (
        <MainBarsGroup
          data={groupChartData}
          purpose="production"
          xScale={xScale}
          yScale={yScale}
          phase={trellisTitle}
          type="group"
        />
      )}
      {isDisplayingForecast && (
        <ExternalForecastLine
          phase={
            trellisTitle === 'Total Liquid'
              ? 'total_liquid'
              : trellisTitle.toLocaleLowerCase()
          }
          forecastData={groupForecastWithEmptyDay}
          xScale={xScale}
          yScale={yScale}
        />
      )}

      <path
        stroke="black"
        strokeWidth="1"
        fill="none"
        d={pathD}
        vectorEffect="non-scaling-stroke"
        strokeLinejoin="bevel"
      />
      {yAxisLinePos && (
        <g>
          <g>
            <rect
              x={4}
              y={yAxisLinePos - 16}
              width={backgroundTextWidth}
              height={16}
              fill="#ffffff"
            />
            <text
              fontSize={12}
              style={{
                userSelect: 'none',
              }}
              textAnchor="start"
              x={5}
              y={yAxisLinePos - 4}
              fill="#c42424"
            >
              {cursorRate}
            </text>
          </g>
          <line
            stroke="#c42424"
            strokeWidth={1}
            x1={0}
            x2={xScale.range()[1]}
            y1={yAxisLinePos}
            y2={yAxisLinePos}
            vectorEffect="non-scaling-stroke"
          />
        </g>
      )}

      {!regionOfInterestMode && !yAxisHovered && (
        <GroupSelectedBar
          dataMap={dataMap}
          onSetTooltipData={onSetTooltipData}
          svgBoundingRect={svgBoundingRect}
          today={today}
          tooltipData={tooltipData}
          trellisTitle={trellisTitle}
          xScale={xScale}
          yScale={yScale}
          isDragging={isAxisDragging}
        />
      )}
    </svg>
  );
};

export default React.memo<SVGGroupTrellisProps>(SVGGroupTrellis);
