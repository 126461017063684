import InputCheckbox from 'components/InputCheckbox';
import useDrilldownTableState from 'modules/drilldownTable/hooks/useDrilldownTableState';
import useForecast from '../hooks/useForecast';
import { REForecastAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

const ForecastCheckbox = () => {
  const forecast = useForecast();
  const drilldown = useDrilldownTableState();
  if (!forecast.hasViewForecastPermission) return null;

  return (
    <ButtonTooltip content={REForecastAttributes}>
      <div>
        <InputCheckbox
          name="re-forecast"
          checked={forecast.isVisible}
          onChange={forecast.toggleVisibility}
          disabled={!drilldown.isComparingActual}
          label="RE Forecast"
          marginBottom={0}
        />
      </div>
    </ButtonTooltip>
  );
};

export default ForecastCheckbox;
