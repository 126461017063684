import * as React from 'react';
import styled from 'styled-components';

import DateInput from 'components/DateInput';
import Button from 'components/Button';

interface Props {
  disabled: boolean;
  dateStart: Date;
  dateEnd: Date;
  hasEndDate: boolean;
  range: {
    start: {
      min: Date;
      max: Date;
    };
    end: {
      min: Date;
      max: Date;
    };
  };
  onChangeDateStart: (value: Date) => void;
  onChangeDateEnd: (value: Date | null) => void;
  toggleNoEndDate: (value: boolean) => void;
}

export const RibbonDateInput = ({
  disabled,
  dateStart,
  range,
  hasEndDate,
  onChangeDateEnd,
  onChangeDateStart,
  toggleNoEndDate,
  dateEnd,
}: Props) => {
  const datePickerControls = React.useCallback(
    close => {
      return (
        <RibbonDateInput.DatePickerControls hasEndDate={hasEndDate}>
          {hasEndDate && (
            <Button
              width={104}
              onClick={() => {
                toggleNoEndDate(true);
              }}
            >
              No End Date
            </Button>
          )}

          <Button width={67} onClick={close}>
            Cancel
          </Button>
        </RibbonDateInput.DatePickerControls>
      );
    },
    [hasEndDate, toggleNoEndDate],
  );

  return (
    <RibbonDateInput.Container>
      <DateInput
        disabled={disabled}
        id="dayStart"
        name="dayStart"
        date={dateStart}
        min={range.start.min}
        max={range.start.max}
        onProcess={newDate => onChangeDateStart(newDate)}
        transparent
      />

      <RibbonDateInput.TextDash />

      {hasEndDate ? (
        <DateInput
          disabled={disabled}
          id="dayEnd"
          name="dayEnd"
          date={dateEnd}
          min={range.end.min}
          max={range.end.max}
          isInfinite
          onProcess={newDate => onChangeDateEnd(newDate)}
          transparent
          datePickerChildren={datePickerControls}
        />
      ) : (
        <RibbonDateInput.NoEndDateInput htmlFor="dayEnd">
          <RibbonDateInput.NoEndMessage>No End</RibbonDateInput.NoEndMessage>

          <RibbonDateInput.HiddenDateInput
            disabled={disabled}
            id="dayEnd"
            name="dayEnd"
            date={dateEnd}
            min={range.end.min}
            max={range.end.max}
            isInfinite
            onProcess={newDate => {
              if (!hasEndDate) toggleNoEndDate(false);

              onChangeDateEnd(newDate);
            }}
            datePickerChildren={datePickerControls}
            transparent
          />
        </RibbonDateInput.NoEndDateInput>
      )}
    </RibbonDateInput.Container>
  );
};

RibbonDateInput.Container = styled.div`
  height: 30px;
  width: 268px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid #c1c1c1;
  padding: 0;
`;

RibbonDateInput.TextDash = styled.div`
  width: 5px;
  height: 1px;
  background-color: ${(props: Record<string, any>) =>
    props.theme.colors.primaryText};
`;
RibbonDateInput.HiddenDateInput = styled(DateInput)`
  right: 0;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  z-index: -1;
  visibility: hidden;
`;

RibbonDateInput.NoEndDateInput = styled.label`
  position: relative;
  display: block;
`;

RibbonDateInput.NoEndMessage = styled.div`
  margin-left: 5px;
`;

RibbonDateInput.DatePickerControls = styled.div`
  display: flex;
  justify-content: ${({ hasEndDate }) =>
    hasEndDate ? 'space-between' : 'flex-end'};
  margin-top: 12px;
`;
