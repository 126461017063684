import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import * as React from 'react';
import styled from 'styled-components';

import { FeatureFlag } from 'modules/featureFlags/models';

interface DevSubMenuProps {
  featureFlags: FeatureFlag[];
  getFeatureFlagValue: (key: string) => boolean;
  toggleFeatureFlag: (key: string) => void;
  anchor: DOMRect;
  onClose: () => void;
}

const DevSubMenu = ({
  featureFlags,
  getFeatureFlagValue,
  toggleFeatureFlag,
  anchor,
  onClose,
}: DevSubMenuProps) => {
  return (
    <DevSubMenu.SubMenu
      anchor={anchor}
      onMouseLeave={() => setTimeout(onClose, 200)}
    >
      <List>
        {featureFlags.map(flag => (
          <DevSubMenu.FormControlLabel
            key={flag.key}
            label={flag.name}
            control={
              <Switch
                checked={getFeatureFlagValue(flag.key)}
                onChange={() => toggleFeatureFlag(flag.key)}
                value="quickEditMode"
                color="primary"
              />
            }
          />
        ))}
      </List>
    </DevSubMenu.SubMenu>
  );
};

DevSubMenu.SubMenu = styled(Paper)`
  position: absolute;
  top: ${props => props.anchor.top - 56}px;
  left: ${props => props.anchor.width}px;
  max-height: 55vh;
  overflow-y: auto;
`;

DevSubMenu.FormControlLabel = styled(FormControlLabel)`
  width: 290px;
  margin: 0 !important;
  line-height: 1em !important;
  height: 30px;
  padding: 0 24px 0 0 !important;

  & .MuiTypography-root {
    font-size: 0.875rem !important;
  }

  & > span {
    text-transform: capitalize;
  }
`;

DevSubMenu.FormGroup = styled(FormGroup)`
  width: 155px;
`;

DevSubMenu.FormLabel = styled.legend`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 9px;
  margin-left: 15px;
  margin-top: 10px;
`;
export default DevSubMenu;
