import Env from 'helpers/Env';
import type {
  MetricsPlugin as IMetricsPlugin,
  MetricsUser,
  MetricsEvent,
  MetricsError,
  MetricsPluginProps,
  MetricsPluginInternals,
  MetricsData,
} from '../models';

class MetricsPlugin implements IMetricsPlugin {
  private initialized: boolean;
  private onInitCallbacks: Array<() => void> = [];
  private internals: MetricsPluginInternals;
  public props: MetricsPluginProps;
  public config: any;
  public debug = false;

  constructor(internals: MetricsPluginInternals, props: MetricsPluginProps) {
    this.initialized = false;
    this.props = props;
    this.internals = internals;
    this.debug = Boolean(props.debug);
  }

  isInitialized = () => {
    return this.initialized;
  };

  isSourceCodeLoaded = () => {
    return this.internals.isSourceCodeLoaded();
  };

  identifyUser = (user: MetricsUser) => {
    if (this.debug) return this.debugMsg('identifyUser', user);
    return this.internals.identifyUser(user);
  };

  anonomizeUser = () => {
    if (this.debug) return this.debugMsg('anonomizeUser');
    return this.internals.anonomizeUser();
  };

  captureEvent = (event: MetricsEvent, data?: MetricsData) => {
    if (this.debug) return this.debugMsg('captureEvent', event);
    return this.internals.captureEvent(event, data);
  };

  captureError = (error: MetricsError, data?: MetricsData) => {
    if (this.debug) return this.debugMsg('captureError', error);
    return this.internals.captureError(error, data);
  };

  onInit = (cb: () => void) => {
    this.onInitCallbacks.push(cb);
  };

  initialize = (config?: any) => {
    if (Env.isNotSupported(this.props.env)) return;
    if (this.initialized) return;

    if (this.debug) {
      this.debugMsg('initialize', config);
    } else {
      this.internals.initialize(config);
    }

    this.config = config;
    this.initialized = true;
    this.onInitCallbacks.forEach(cb => cb());
  };

  private debugMsg = (method: string, data?: any) => {
    // eslint-disable-next-line no-console
    console.log(`[ ${this.props.id} ] -> ${method} \n`, data);
  };
}

export default MetricsPlugin;
