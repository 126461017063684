import React from 'react';
import { SDKResult } from '@loomhq/record-sdk';

type LoomContextData = {
  isSetup: boolean;
  configureButton?: SDKResult['configureButton'];
  visualizeLoom: (url: string) => void;
  hideLoom: () => void;
  getInlineLoomFor: (
    url: string,
    options?: { width?: number; height?: number },
  ) => Promise<string>;
};

export const LoomContext = React.createContext<LoomContextData | undefined>(
  undefined,
);
