import React from 'react';
import { ErrorMessage, Field } from 'formik';
import styled from 'styled-components';

import Input, { InputProps } from 'components/Input';

type FormikInputProps = {
  name: string;
  id?: string;
  label?: React.ReactNode;
  type?: string;
  placeholder?: string;
  autoComplete?: 'on' | 'off' | 'email' | 'password';
  haveInvalidError?: boolean;
} & Omit<InputProps, 'id'>;

const FormikInput = (props: FormikInputProps) => {
  const { label, id = props.name, ...inputProps } = props;

  return (
    <FormikInput.Wrapper>
      {label && <label htmlFor={id}>{label}</label>}
      <Field name={props.name}>
        {({ field, form, meta }) => {
          return (
            <FormikInput.BorderError
              isValid={!(meta.error && meta.touched) && !props.haveInvalidError}
            >
              <FormikInput.Input
                {...inputProps}
                isValid={
                  !(meta.error && meta.touched) && !props.haveInvalidError
                }
                id={id}
                name={field.name}
                value={field.value}
                type={props.type || 'text'}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                autoComplete={props.autoComplete || 'off'}
                placeholder={props.placeholder}
              />
            </FormikInput.BorderError>
          );
        }}
      </Field>
      <FormikInput.ValidationError
        name={props.name}
        data-spec-id="validation-error"
        component="p"
      />
    </FormikInput.Wrapper>
  );
};

FormikInput.Wrapper = styled.div`
  font-size: 14px;
  position: relative;
`;

FormikInput.BorderError = styled.div`
  border: ${(props: any) =>
    props.isValid ? '1px solid #c1c1c1;' : '1px solid #c60000;'};
  margin: 8px 0 22px;
`;

FormikInput.Input = styled(Input)`
  width: 100%;
  border: none;
`;

FormikInput.ValidationError = styled(ErrorMessage)`
  color: #c60000;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  left: 0;
  bottom: -15px;
`;

export default FormikInput;
