import gql from 'graphql-tag';

import type {
  Action,
  GraphqlQueryAction,
  GraphqlMutationAction,
} from 'store/models';
import wellRequestsAbortController from 'modules/well/utils/wellRequestsAbortController';

import type { VarianceEvent } from './models/varianceEvent';

export const namespace = 'VARIANCE_EVENT';

export const INIT_FETCH_VARIANCE_EVENTS = `${namespace}/INIT_FETCH_VARIANCE_EVENTS`;
export const FETCH_VARIANCE_EVENTS = `${namespace}/FETCH_VARIANCE_EVENTS`;
export const CREATE_REMOTE_VARIANCE_EVENT = `${namespace}/CREATE_REMOTE_VARIANCE_EVENT`;
export const SYNC_VARIANCE_EVENT_REMOTELY = `${namespace}/SYNC_VARIANCE_EVENT_REMOTELY`;
export const SYNC_VARIANCE_EVENT_LOCALLY = `${namespace}/SYNC_VARIANCE_EVENT_LOCALLY`;
export const UPDATE_REMOTE_VARIANCE_EVENT = `${namespace}/UPDATE_REMOTE_VARIANCE_EVENT`;
export const DELETE_REMOTE_VARIANCE_EVENT = `${namespace}/DELETE_REMOTE_VARIANCE_EVENT`;
export const UPDATE_REMOTE_VARIANCE_EVENT_DESCRIPTION = `${namespace}/UPDATE_REMOTE_VARIANCE_EVENT_DESCRIPTION`;

export const UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY = `${namespace}/UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY`;
export const UPDATE_VARIANCE_EVENT_DATES_LOCALLY = `${namespace}/UPDATE_VARIANCE_EVENT_DATES_LOCALLY`;
export const UPDATE_VARIANCE_EVENT_DESCRIPTION_LOCALLY = `${namespace}/UPDATE_VARIANCE_EVENT_DESCRIPTION_LOCALLY`;
export const UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY = `${namespace}/UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY`;
export const DELETE_VARIANCE_EVENT_LOCALLY = `${namespace}/DELETE_VARIANCE_EVENT_LOCALLY`;
export const CREATE_VARIANCE_EVENT_LOCALLY = `${namespace}/CREATE_VARIANCE_EVENT_LOCALLY`;
export const UPDATE_VARIANCE_EVENT_NOTES_LOCALLY = `${namespace}/UPDATE_VARIANCE_EVENT_NOTES_LOCALLY`;

export const POPULATE_VARIANCE_EVENTS = `${namespace}/POPULATE_VARIANCE_EVENTS`;
export const POPULATE_VARIANCE_EVENTS_AFTER_CREATING = `${namespace}/POPULATE_VARIANCE_EVENTS_AFTER_CREATING`;

const FETCH_VARIANCE_EVENTS_QUERY = gql`
  query ($wellId: ID!) {
    listVarianceEvents(wellId: $wellId)
  }
`;

type fetchVarianceEventsAC = (wellId: string) => GraphqlQueryAction;

export const fetchVarianceEvents: fetchVarianceEventsAC = wellId => ({
  type: FETCH_VARIANCE_EVENTS,
  payload: {
    key: 'listVarianceEvents',
    graphql: {
      query: FETCH_VARIANCE_EVENTS_QUERY,
      variables: {
        wellId,
      },
    },
  },
  options: {
    getContext: wellRequestsAbortController.getContext,
  },
});

const CREATE_REMOTE_VARIANCE_EVENT_MUTATION = gql`
  mutation ($payload: CreateVarianceEventInput!) {
    createVarianceEvent(data: $payload) {
      varianceEvent {
        id
        wellId
        dayStart
        dayEnd
        varianceOptionId
        extraInputsData
        notes
        description
      }
      message
      code
    }
  }
`;

type createRemoteVarianceEventAC = (
  payload: VarianceEvent,
) => GraphqlMutationAction;

export const createRemoveVarianceEvent: createRemoteVarianceEventAC =
  payload => ({
    type: CREATE_REMOTE_VARIANCE_EVENT,
    payload: {
      key: 'createVarianceEvent',
      graphql: {
        mutation: CREATE_REMOTE_VARIANCE_EVENT_MUTATION,
        variables: {
          payload,
        },
      },
    },
  });

type syncVarianceEventRemotelyAC = (payload: {
  varianceEventId: number;
  wellId: string;
}) => Action;

export const syncVarianceEventRemotely: syncVarianceEventRemotelyAC =
  payload => ({
    type: SYNC_VARIANCE_EVENT_REMOTELY,
    payload,
  });

type syncVarianceEventLocallyAC = (payload: {
  varianceEvent: VarianceEvent;
  code: number;
}) => Action;

export const syncVarianceEventLocally: syncVarianceEventLocallyAC =
  payload => ({
    type: SYNC_VARIANCE_EVENT_LOCALLY,
    payload,
  });

const UPDATE_REMOTE_VARIANCE_EVENT_MUTATION = gql`
  mutation ($payload: UpdateVarianceEventInput!) {
    updateVarianceEvent(data: $payload) {
      varianceEvent {
        id
        wellId
        dayStart
        dayEnd
        varianceOptionId
        extraInputsData
        notes
        description
      }
      message
      code
    }
  }
`;

type updateRemoteVarianceEventAC = (
  payload: VarianceEvent,
) => GraphqlMutationAction;

export const updateRemoteVarianceEvent: updateRemoteVarianceEventAC =
  payload => ({
    type: UPDATE_REMOTE_VARIANCE_EVENT,
    payload: {
      key: 'updateVarianceEvent',
      graphql: {
        mutation: UPDATE_REMOTE_VARIANCE_EVENT_MUTATION,
        variables: {
          payload,
        },
      },
    },
  });

const UPDATE_REMOTE_VARIANCE_EVENT_DESCRIPTION_MUTATION = gql`
  mutation ($payload: UpdateVarianceEventDescriptionInput!) {
    updateVarianceEventDescription(data: $payload) {
      id
      wellId
      dayStart
      dayEnd
      varianceOptionId
      extraInputsData
      notes
      description
    }
  }
`;

type updateRemoteVarianceEventDescriptionAC = (payload: {
  id: string;
  description: string | null;
}) => GraphqlMutationAction;

export const updateRemoteVarianceEventDescription: updateRemoteVarianceEventDescriptionAC =
  payload => ({
    type: UPDATE_REMOTE_VARIANCE_EVENT_DESCRIPTION,
    payload: {
      graphql: {
        key: 'updateVarianceEventDescription',
        mutation: UPDATE_REMOTE_VARIANCE_EVENT_DESCRIPTION_MUTATION,
        variables: {
          payload,
        },
      },
    },
  });

const DELETE_REMOTE_VARIANCE_EVENT_MUTATION = gql`
  mutation ($id: ID!) {
    deleteVarianceEvent(id: $id)
  }
`;

type deleteRemoteVerianceEventAC = (id: string) => GraphqlMutationAction;

export const deleteRemoteVarianceEvent: deleteRemoteVerianceEventAC = id => ({
  type: DELETE_REMOTE_VARIANCE_EVENT,
  payload: {
    graphql: {
      mutation: DELETE_REMOTE_VARIANCE_EVENT_MUTATION,
      variables: {
        id,
      },
    },
  },
});

type updateVarianceEventCategoryLocallyAC = (payload: {
  wellId: string;
  varianceEventId: string;
  varianceOptionId: string;
}) => Action;

export const updateVarianceEventCategoryLocally: updateVarianceEventCategoryLocallyAC =
  payload => ({
    type: UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY,
    payload,
  });

type updateVarianceEventDescriptionLocallyAC = (payload: {
  id: string;
  description: string | null;
  wellId: string;
}) => Action;

export const updateVarianceEventDescriptionLocally: updateVarianceEventDescriptionLocallyAC =
  payload => ({
    type: UPDATE_VARIANCE_EVENT_DESCRIPTION_LOCALLY,
    payload,
  });

type updateVarianceEventDatesLocallyAC = (payload: {
  wellId: string;
  varianceEventId: string;
  dates: Date[];
}) => Action;

export const updateVarianceEventDatesLocally: updateVarianceEventDatesLocallyAC =
  payload => ({
    type: UPDATE_VARIANCE_EVENT_DATES_LOCALLY,
    payload,
  });

type updateVarianceEventExtraInputsLocallyAC = (payload: {
  wellId: string;
  varianceEventId: string;
  extraInputsData: Record<string, any>;
}) => Action;

export const updateVarianceEventExtraInputsLocally: updateVarianceEventExtraInputsLocallyAC =
  payload => ({
    type: UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY,
    payload,
  });

type deleteVarianceEventLocallyAC = (payload: {
  wellId: string;
  varianceEventId: string;
}) => Action;

export const deleteVarianceEventLocally: deleteVarianceEventLocallyAC =
  payload => ({
    type: DELETE_VARIANCE_EVENT_LOCALLY,
    payload,
  });

type createVarianceEventLocallyAC = ({ wellId: string, date: Date }) => Action;

export const createVarianceEventLocally: createVarianceEventLocallyAC =
  payload => ({
    type: CREATE_VARIANCE_EVENT_LOCALLY,
    payload,
  });

type populateVarianceEventsAfterCreatingAC = (payload: {
  wellId: string;
  newVarianceEvents: { [id: string]: VarianceEvent };
}) => Action;

export const populateVarianceEventsAfterCreating: populateVarianceEventsAfterCreatingAC =
  payload => ({
    type: POPULATE_VARIANCE_EVENTS_AFTER_CREATING,
    payload,
  });

type populateVarianceEventsAC = (payload: {
  wellId: string;
  events: { [id: string]: VarianceEvent };
}) => Action;

export const populateVarianceEvents: populateVarianceEventsAC = payload => ({
  type: POPULATE_VARIANCE_EVENTS,
  payload,
});

type updateVarianceEventNotesLocallyAC = (payload: {
  wellId: string;
  varianceEventId: string;
  notes: string;
}) => Action;

export const updateVarianceEventNotesLocally: updateVarianceEventNotesLocallyAC =
  payload => ({
    type: UPDATE_VARIANCE_EVENT_NOTES_LOCALLY,
    payload,
  });

export const initFetchVarianceEvents = () => ({
  type: INIT_FETCH_VARIANCE_EVENTS,
});
