import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import {
  all,
  put,
  takeLatest,
  select,
  take,
  fork,
  spawn,
} from 'redux-saga/effects';
import { getGraphqlPayload } from 'store/helpers';
import { getFlagsStateMap } from 'modules/featureFlags/FeatureFlagsReducer';

import { SensorSeriesMapping, SeriesMapping } from 'modules/series/models';
import {
  FETCH_CALCULATED_SERIES_MAPPING,
  FETCH_SENSOR_SERIES_MAPPING,
  FETCH_SERIES_MAPPING,
} from 'modules/series/SeriesActions';
import { getSelectedRibbons } from 'modules/ui/UIReducer';

import {
  ADD_OPTIONS,
  SWITCH_CAV_OPTION_IN_TOP_CONTROLS,
  ADD_OPTIONS_GROUP,
  ADD_OPTION_TO_GROUP,
  addAvailableOptions,
  addOptions,
  REMOVE_OPTIONS_GROUP,
  removeAvailableOption,
  setInitialState,
  setAllCoreSeriesOption,
  setAvailableCoreSeries,
  setCoreSeriesOption,
  SET_DATA_SERIES_FROM_ROUTE,
  setNewGroupOrder,
  setAvailableOptions,
  switchOptionsVisible,
  setCustomColor,
  switchChartType,
  setCoreSeriesFromRoute,
  SET_CORESERIES_FROM_ROUTE,
  setCoreSeries,
  resetDataSeries,
  resetSensorSeries,
  addSensorOptions,
  ADD_SENSOR_OPTIONS,
} from './ChartOptionsActions';
import {
  getAllDataSeriesOptions,
  getCoreSeriesChartOption,
} from './ChartOptionsReducer';
import {
  AvailableDataSeries,
  coreSeries,
  ListChartOptions,
  STEP_LINE_CHART,
} from './models';
import {
  setCurrentSeriesConfiguration,
  SET_CURRENT_SERIES_LAYOUT,
  FETCH_BASE_SERIES_LAYOUTS,
  FETCH_USER_SERIES_LAYOUTS,
} from 'modules/seriesLayouts/SeriesLayoutsActions';
import {
  decodeCoreSeries,
  decodeDataSeries,
  decodeSensorSeries,
  parseRibbons,
} from 'modules/router/utils/router';
import { setRibbons, SET_RIBBONS, SWITCH_RIBBONS } from 'modules/ui/UIActions';
import { CalculatedSeriesMapping } from '../series/models/index';
import { resetCalculatedSeries } from './ChartOptionsActions';
import {
  getAllLayouts,
  getCurrentLayout,
} from 'modules/seriesLayouts/SeriesLayoutsReducer';
import isSensorOptionsAddedToOptions from './utils/isSensorAddedToOptions';

export interface ChartOption {
  id: string;
  title: string;
  isShow: boolean;
  chartType?: string;
}

function* addSereisToOptionsSaga(action): Generator<any, any, any> {
  const coreSeriesChartOption = yield select(getCoreSeriesChartOption);
  if (coreSeriesChartOption.length !== R.values(coreSeries).length) {
    yield put(setInitialState());
  }

  const seriesMapping: SeriesMapping[] = JSON.parse(
    getGraphqlPayload(action).seriesMapping,
  );
  const existingOptions: ListChartOptions = yield select(
    getAllDataSeriesOptions,
  );
  const existingSeriesOptions = Object.keys(existingOptions).reduce(
    (acc, key) => {
      if (!key.startsWith('s') && !key.startsWith('c'))
        acc[key] = existingOptions[key];
      return acc;
    },
    {} as ListChartOptions,
  );
  const options = seriesMapping.reduce((acc, series) => {
    acc[series.seriesIndex.toString()] = {
      id: series.seriesIndex.toString(),
      title: series.displayName + ', ' + series.units,
      isShow: false,
      chartType: STEP_LINE_CHART,
      units: series.units,
      color: series.color,
    };

    return acc;
  }, {} as ListChartOptions);
  const availableOptions = seriesMapping.map(
    (series: SeriesMapping): AvailableDataSeries => ({
      id: series.seriesIndex.toString(),
      title: series.displayName + ', ' + series.units,
    }),
  );
  const optionsKeys = Object.keys(existingSeriesOptions);
  if (
    optionsKeys.length !== seriesMapping.length ||
    (!R.path([optionsKeys[0], 'units'], existingSeriesOptions) &&
      !R.isEmpty(existingSeriesOptions))
  ) {
    yield put(resetDataSeries());
    yield put(addOptions(options));
    yield put(addAvailableOptions(availableOptions));

    return;
  }
  const isNotSame = seriesMapping.reduce((acc, series) => {
    const existingSeries = existingSeriesOptions[series.seriesIndex];
    if (
      !existingSeries ||
      existingSeries.title !== `${series.displayName}, ${series.units}`
    ) {
      return true;
    }

    return acc;
  }, false);

  if (isNotSame) {
    yield put(resetDataSeries());
    yield put(addOptions(options));
    yield put(addAvailableOptions(availableOptions));
  }
}

function* addSensorSeriesToOptionsSaga(action): Generator<any, any, any> {
  const seriesMapping: SensorSeriesMapping[] = JSON.parse(
    getGraphqlPayload(action).sensorSeries,
  );

  const options = seriesMapping.reduce((acc, series) => {
    const id = `s${series.sensorSeriesId}`;
    acc[id] = {
      id,
      title: `${series.name}, ${series.units ?? ''}`,
      isShow: false,
      chartType: STEP_LINE_CHART,
      units: series.units ?? '',
      color: series.color ?? '#000000',
    };

    return acc;
  }, {} as ListChartOptions);

  const availableOptions = seriesMapping.map(
    (series: SensorSeriesMapping): AvailableDataSeries => ({
      id: `s${series.sensorSeriesId}`,
      title: `${series.name}, ${series.units ?? ''}`,
    }),
  );

  const existingOptions: ListChartOptions = yield select(
    getAllDataSeriesOptions,
  );

  const existingSensorOption = Object.keys(existingOptions).reduce(
    (acc, key) => {
      if (key.startsWith('s')) acc[key] = existingOptions[key];
      return acc;
    },
    {} as ListChartOptions,
  );

  const isNotSame = seriesMapping.reduce((acc, series) => {
    const existingSeries = existingSensorOption['s' + series.sensorSeriesId];
    if (
      !existingSeries ||
      existingSeries.title !== `${series.name}, ${series.units}` ||
      existingSeries.color !== series.color
    ) {
      return true;
    }

    return acc;
  }, false);

  if (
    isNotSame ||
    seriesMapping.length !== Object.keys(existingSensorOption).length
  ) {
    yield put(resetSensorSeries());
    yield put(addOptions(options));
    yield put(addAvailableOptions(availableOptions));
  }
  yield put(addSensorOptions());
}
function* addCalculatedSeriesToOptionsSaga(): Generator<any, any, any> {
  const action = yield take(`${FETCH_CALCULATED_SERIES_MAPPING}_SUCCESS`);
  const calculatedMapping: CalculatedSeriesMapping[] =
    getGraphqlPayload(action);

  const options = calculatedMapping.reduce((acc, series) => {
    const id = `c${series.calculatedSeriesId}`;
    acc[id] = {
      id,
      title: `${series.name}, ${series.units ?? ''}`,
      isShow: false,
      chartType: STEP_LINE_CHART,
      units: series.units ?? '',
      color: series.color ?? '#000000',
    };

    return acc;
  }, {} as ListChartOptions);

  const availableOptions = calculatedMapping.map(
    (series: CalculatedSeriesMapping): AvailableDataSeries => ({
      id: `c${series.calculatedSeriesId}`,
      title: `${series.name}, ${series.units ?? ''}`,
    }),
  );
  const existingOptions: ListChartOptions = yield select(
    getAllDataSeriesOptions,
  );

  const existingCalculatedOption = Object.keys(existingOptions).reduce(
    (acc, key) => {
      if (key.startsWith('c')) acc[key] = existingOptions[key];
      return acc;
    },
    {} as ListChartOptions,
  );

  const isNotSame = calculatedMapping.reduce((acc, series) => {
    const existingSeries =
      existingCalculatedOption['c' + series.calculatedSeriesId];
    if (
      !existingSeries ||
      existingSeries.title !== `${series.name}, ${series.units}` ||
      existingSeries.color !== series.color
    ) {
      return true;
    }

    return acc;
  }, false);
  if (
    isNotSame ||
    calculatedMapping.length !== Object.keys(existingCalculatedOption).length
  ) {
    yield put(resetCalculatedSeries());
    yield put(addOptions(options));
    yield put(addAvailableOptions(availableOptions));
  }
}

function* removeAvailableoptionSaga(action): Generator<any, any, any> {
  const {
    payload: { optionId },
  } = action;
  yield put(removeAvailableOption({ optionId }));
}
function* removeGroupSaga(action): Generator<any, any, any> {
  const { payload } = action;
  const options: ListChartOptions = yield select(getAllDataSeriesOptions);
  const newAvailabelOptons = payload.options.map(id => ({
    id,
    title: options[id].title,
  }));
  yield put(addAvailableOptions(newAvailabelOptons));
}

function* addGroupThroughTopControlsSaga(action): Generator<any, any, any> {
  const {
    payload: { optionId, checked },
  } = action;
  if (optionId === 'all') {
    yield put(setAllCoreSeriesOption({ isShow: checked }));
    return;
  }
  yield put(
    setAvailableCoreSeries({ optionId: optionId, isAvailable: checked }),
  );
  yield put(setCoreSeriesOption({ optionId: optionId, isShow: !checked }));
}

function* setCustomColorAndChartTypeForDataSeries(groups) {
  yield all(
    groups.reduce((acc, group) => {
      group.forEach(({ id, customColor, chartType }) => {
        acc.push(
          put(
            setCustomColor({
              color: customColor ? '#' + customColor : '',
              id,
            }),
          ),
        );
        acc.push(put(switchChartType({ optionId: id, type: chartType })));
      });
      return acc;
    }, []),
  );
}

function* setDataSeriesFromRouteSeries(action): Generator<any, any, any> {
  const {
    payload: { dataSeries, sensorSeries },
  } = action;
  let existingOptions: ListChartOptions = yield select(getAllDataSeriesOptions);
  if (R.isEmpty(existingOptions)) {
    yield take(ADD_OPTIONS);
    existingOptions = yield select(getAllDataSeriesOptions);

    if (R.isEmpty(existingOptions)) return;
  }

  const flagState: Record<string, boolean> = yield select(getFlagsStateMap);

  if (
    flagState.sensorSeries &&
    !isSensorOptionsAddedToOptions(existingOptions)
  ) {
    yield take(ADD_SENSOR_OPTIONS);
    existingOptions = yield select(getAllDataSeriesOptions);
  }

  const existingGroups: any[] = [];
  const existingSeries = dataSeries
    .concat(sensorSeries)
    .reduce((acc, group) => {
      const existingGroupOptions = group.dataSeriesGroup.filter(
        option => existingOptions[option.id],
      );
      if (existingGroupOptions.length) {
        existingGroups.push(existingGroupOptions);
        return [
          ...acc,
          {
            groupId: uuid(),
            options: existingGroupOptions.map(option => option.id),
          },
        ];
      }
      return acc;
    }, []);

  const availableOptions = R.values(existingOptions).map(
    (series: ChartOption): AvailableDataSeries => ({
      id: series.id,
      title: series.title,
    }),
  );

  const unavailableOptions = existingSeries.reduce(
    (acc, group) => [...acc, ...group.options],
    [],
  );

  const actualAvailableOptions = availableOptions.filter(
    option => !unavailableOptions.includes(option.id),
  );

  yield put(
    switchOptionsVisible({
      options: unavailableOptions,
      show: true,
    }),
  );

  yield put(setAvailableOptions(actualAvailableOptions));
  yield put(setNewGroupOrder(existingSeries));
  yield* setCustomColorAndChartTypeForDataSeries(existingGroups);
}

function* setCoreSeriesFromRouteSeries(action): Generator<any, any, any> {
  const {
    payload: { series },
  } = action;
  let existingOptions: ChartOption[] = yield select(getCoreSeriesChartOption);
  if (R.isEmpty(existingOptions)) {
    yield take(ADD_OPTIONS);
    existingOptions = yield select(getCoreSeriesChartOption);
  }

  if (R.isEmpty(existingOptions)) {
    return;
  }

  const cavSeries = R.clone(coreSeries);
  Object.keys(cavSeries).forEach(key => {
    cavSeries[key] = {
      ...cavSeries[key],
      isShow: series.includes(key),
      isAvailable: !series.includes(key),
    };
  });

  yield put(setCoreSeries({ series: cavSeries }));
}

function* setSeriesFromSeriesLayout(action): Generator<any, any, any> {
  const { configuration } = action.payload;

  const coreSeriesString = configuration.match(/(?<=coreseries=)[^&]+&??/);
  const dataSeriesString = configuration.match(/(?<=dataseries=)[^&]+&??/);
  const sensorSeriesString = configuration.match(/(?<=sensorseries=)[^&]+&??/);
  const ribbonsString = configuration.match(/(?<=ribbons=)[^&]+&??/);

  const coreSeries = coreSeriesString
    ? decodeCoreSeries(coreSeriesString[0])
    : [];
  const dataSeries = dataSeriesString
    ? decodeDataSeries(dataSeriesString[0])
    : [];
  const sensorSeries = sensorSeriesString
    ? decodeSensorSeries(sensorSeriesString[0])
    : [];
  const ribbons = ribbonsString ? parseRibbons(ribbonsString[0]) ?? [] : [];

  yield put(setCoreSeriesFromRoute({ series: coreSeries }));
  yield* setDataSeriesFromRouteSeries({
    payload: { dataSeries, sensorSeries },
  });
  yield put(setRibbons(ribbons));
}

function* switchRibbonInLayoutConfiguration(action): Generator<any, any, any> {
  const selectedRibbons = yield select(getSelectedRibbons);
  const layout = yield select(getCurrentLayout);
  if (R.isEmpty(layout) || !layout.configuration) return;
  const coreSeries = layout.configuration.match(/(?<=coreseries=)[^&]+&??/);
  const dataSeries = layout.configuration.match(/(?<=dataseries=)[^&]+&??/);
  const sensorSeries = layout.configuration.match(/(?<=sensorseries=)[^&]+&??/);
  const newConfiguration: string[] = [];
  if (coreSeries) newConfiguration.push(`&coreseries=${coreSeries.join('-')}`);
  if (dataSeries) newConfiguration.push(`&dataseries=${dataSeries.join('-')}`);
  if (sensorSeries)
    newConfiguration.push(`&sensorseries=${sensorSeries.join('-')}`);
  if (Object.keys(selectedRibbons).length > 0)
    newConfiguration.push(`&ribbons=${Object.keys(selectedRibbons).join('-')}`);

  const allLayouts = yield select(getAllLayouts);

  if (allLayouts.length) {
    yield put(
      setCurrentSeriesConfiguration({
        configuration: newConfiguration.join(''),
      }),
    );
  } else {
    yield spawn(function* () {
      yield take([
        `${FETCH_BASE_SERIES_LAYOUTS}_SUCCESS`,
        `${FETCH_USER_SERIES_LAYOUTS}_SUCCESS`,
      ]);
      yield take([
        `${FETCH_BASE_SERIES_LAYOUTS}_SUCCESS`,
        `${FETCH_USER_SERIES_LAYOUTS}_SUCCESS`,
      ]);
      yield put(
        setCurrentSeriesConfiguration({
          configuration: newConfiguration.join(''),
        }),
      );
    });
  }
}

function* chartOptionsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(`${FETCH_SERIES_MAPPING}_SUCCESS`, addSereisToOptionsSaga),
    takeLatest(
      `${FETCH_SENSOR_SERIES_MAPPING}_SUCCESS`,
      addSensorSeriesToOptionsSaga,
    ),
    fork(addCalculatedSeriesToOptionsSaga),
    takeLatest(
      [ADD_OPTION_TO_GROUP, ADD_OPTIONS_GROUP],
      removeAvailableoptionSaga,
    ),
    takeLatest(SET_DATA_SERIES_FROM_ROUTE, setDataSeriesFromRouteSeries),
    takeLatest(SET_CORESERIES_FROM_ROUTE, setCoreSeriesFromRouteSeries),
    takeLatest(REMOVE_OPTIONS_GROUP, removeGroupSaga),
    takeLatest(
      SWITCH_CAV_OPTION_IN_TOP_CONTROLS,
      addGroupThroughTopControlsSaga,
    ),
    takeLatest(SET_CURRENT_SERIES_LAYOUT, setSeriesFromSeriesLayout),
    takeLatest(
      [SWITCH_RIBBONS, SET_RIBBONS],
      switchRibbonInLayoutConfiguration,
    ),
  ]);
}

export default chartOptionsSagas;
