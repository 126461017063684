import { getGraphqlPayload } from '../../../store/helpers';
import * as R from 'ramda';

export const parseVarianceEvents = (action: JSON): any => {
  const parseVarianceEvents = JSON.parse(getGraphqlPayload(action));
  return parseVarianceEvents.map(variance => {
    return R.compose(
      R.assocPath(['id'], variance.id.toString()),
      R.assocPath(['varianceOptionId'], variance.varianceOptionId.toString()),
    )(variance);
  });
};
