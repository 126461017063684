import * as React from 'react';
import styled from 'styled-components';

import Input from 'components/Input';
import { SearchIcon, CircleClearIcon } from 'components/Icons';
import { SearchAttributes } from '../ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

interface SearchFormProps {
  inputHandler: (input: string) => void;
  placeholder: string;
  value: string;
  width?: number;
  height?: number;
  fullWidth?: boolean;
}

const SearchForm = ({
  inputHandler,
  placeholder,
  value,
  width = 260,
  height = 26,
  fullWidth,
}: SearchFormProps) => {
  const inputRef = React.useRef<HTMLElement>(null);

  const clearInputField = React.useCallback(() => {
    inputHandler('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputHandler]);

  return (
    <ButtonTooltip content={SearchAttributes}>
      <SearchForm.Container>
        <SearchForm.LeftIcon>
          <SearchIcon />
        </SearchForm.LeftIcon>

        <SearchForm.Input
          ref={inputRef}
          value={value}
          placeholder={placeholder}
          isValid
          onChange={e => inputHandler(e.target.value)}
          width={width}
          height={height}
          fullWidth={fullWidth}
        />

        {value && (
          <SearchForm.RightIcon onClick={clearInputField}>
            <CircleClearIcon />
          </SearchForm.RightIcon>
        )}
      </SearchForm.Container>
    </ButtonTooltip>
  );
};

SearchForm.Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

SearchForm.LeftIcon = styled.div`
  position: absolute;
  left: 5px;
  height: 20px;
`;

SearchForm.RightIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 6px;
  height: 20px;
  cursor: pointer;
`;

SearchForm.Input = styled(Input)`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : width + 'px')};
  padding-left: 28px;
  padding-right: 34px;
  font-size: 14px;
  height: ${({ height }) => `${height}px`};
  line-height: ${({ height }) => `${height}px`};

  ::placeholder {
    color: #909090;
  }
`;

export default SearchForm;
