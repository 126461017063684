import { REHYDRATE } from 'redux-persist';
import { all, put, fork, take, takeLatest } from 'redux-saga/effects';

import { getGraphqlPayload } from 'store/helpers';
import { logRocketPlugin } from './services/Metrics';
import {
  FETCH_METRICS_TOOLS_CONFIG_SUCCESS,
  fetchMetricsToolsConfig,
} from './MetricsActions';

function* appInitialized(): Generator<any, any, any> {
  yield take(REHYDRATE);
  yield put(fetchMetricsToolsConfig());
}

function initMetricsTools(action) {
  const payload = getGraphqlPayload(action);

  logRocketPlugin.initialize(payload.logRocket);
}

function* metricsSagas(): Generator<any, any, any> {
  yield all([
    fork(appInitialized),
    takeLatest(FETCH_METRICS_TOOLS_CONFIG_SUCCESS, initMetricsTools),
  ]);
}

export default metricsSagas;
