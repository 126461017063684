import { CHARTS_OPTIONS } from 'modules/chart/models/chart';

const coreIds = Object.keys(CHARTS_OPTIONS);

const getSeriesType = (seriesId: string): string => {
  if (coreIds.includes(seriesId)) return 'Core';
  if (seriesId.startsWith('s')) return 'Sensor';
  if (seriesId.startsWith('c')) return 'Calculated';
  return 'Daily';
};

export default getSeriesType;
