export const ItemTypes = {
  LAYOUT: 'layout',
};

export interface SeriesLayoutOption {
  id: string;
  userId: string;
  name: string;
  configuration: string;
  order: number;
}

export interface ListSeriesLayouts {
  [key: string]: SeriesLayoutOption;
}

export type LayoutType = 'base' | 'user';

export interface CurrentLayout {
  id: string;
  configuration: string;
  type: LayoutType;
  unsaved: boolean;
}
