import { useEffect, useRef } from 'react';

export function useFirstRender() {
  const isFirstRender = useRef<boolean>(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return isFirstRender.current;
}
