import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import 'antd/dist/antd.min.css';

const baseStyles = createGlobalStyle`
  ${reset} /* stylelint-disable-line value-keyword-case */

  html, body {
    height: 100%;
  }

  body {
    background-color : #f5f5f5;
    color            : #484848;
    font-family      : "Lato", sans-serif;
    font-size        : 14px;
    user-select      : none;
    overflow         : hidden
  }

  select {
    height: 30px;
    background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
    border: 1px solid #c1c1c1;;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: #484848;
    padding-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &[disabled] {
      opacity: 0.85;
    }
  }

  input[type="text"][disabled] {
    opacity: 0.85;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition-delay: 99999s;
  }

  a {
    text-decoration: none;
    color          : inherit !important;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  ::-webkit-scrollbar-button {
    background-image  : url('');
    background-repeat : no-repeat;
    width             : 8px;
    height            : 0;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* temporarily return to default values */
  /* ::-webkit-resizer {
    background-image  : url('');
    background-repeat : no-repeat;
    width             : 7px;
    height            : 0;
  } */

  ::-webkit-scrollbar {
    width: 7px;
    height:7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius         : 0;
    background-color      : #484848;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color : #484848;
  }

  .MuiSnackbar-anchorOriginTopCenter {
    top: 70px !important;
  }

  .MuiTooltip-tooltip {
    && {
      font-family: 'Lato', sans-serif;
      color: #484848;
      background-color: #f2f2f2;
      font-size: 11px;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }
  }

  #root {
    height: 100%;
  }
`;

export default baseStyles;
