import * as Sentry from '@sentry/browser';
import { LOCATION_CHANGE } from 'connected-react-router';
import { Middleware } from 'redux';

import type { Action } from 'store/models';
import paths from 'modules/router/utils/paths';
import * as AllocIssueActions from 'modules/allocIssue/AllocIssueActions';
import * as AppActions from 'modules/app/AppActions';
import * as AuthActions from 'modules/auth/AuthActions';
import * as CapacityActions from 'modules/capacityChangeEvent/CapacityChangeEventActions';
import * as DrilldownTableActions from 'modules/drilldownTable/DrilldownTableActions';
import * as EventNoteActions from 'modules/eventNotes/EventNotesActions';
import * as ExternalForecastActions from 'modules/externalForecast/ExternalForecastActions';
import * as FilterActions from 'modules/filter/FilterActions';
import * as NoteAttachmentActions from 'modules/noteAttachment/NoteAttachmentActions';
import * as NoteActions from 'modules/notes/NotesActions';
import * as ProductionActions from 'modules/production/ProductionActions';
import * as SeriesLayoutActions from 'modules/seriesLayouts/SeriesLayoutsActions';
import * as UiActions from 'modules/ui/UIActions';
import * as InboxConversationActions from 'modules/inboxConversation/InboxConversationActions';
import * as VarianceEventActions from 'modules/varianceEvent/VarianceEventActions';

import * as MetricsActions from '../MetricsActions';
import { MetricsEventsKey } from '../models';
import MetricsService from '../services/Metrics';

const metricsMiddleware: Middleware =
  (store: any) => (next: any) => (action: Action) => {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
    });

    let eventKey: MetricsEventsKey = 'UNKNOWN';
    let eventData = {};

    switch (action.type) {
      case AppActions.AUTHENTICATED:
        eventKey = 'USER_LOGIN';
        break;
      case AuthActions.LOGOUT:
        eventKey = 'USER_LOGOUT';
        break;
      case AllocIssueActions.CREATE_REMOTE_ALLOC_ISSUE:
        eventKey = 'ALLOC_ISSUE_CREATE';
        break;
      case AllocIssueActions.DELETE_ALLOC_ISSUE:
        eventKey = 'ALLOC_ISSUE_DELETE';
        break;
      case AllocIssueActions.UPDATE_REMOTE_ALLOC_ISSUE:
        eventKey = 'ALLOC_ISSUE_UPDATE';
        break;
      case CapacityActions.CREATE_REMOTE_CAPACITY:
        eventKey = 'CAPACITY_CHANGE_CREATE';
        break;
      case CapacityActions.DELETE_REMOTE_CAPACITY:
        eventKey = 'CAPACITY_CHANGE_DELETE';
        break;
      case CapacityActions.UPDATE_REMOTE_CAPACITY:
        eventKey = 'CAPACITY_CHANGE_UPDATE';
        break;
      case DrilldownTableActions.SET_DRILLDOWN_TABLE_PARAMS:
        eventKey = 'UI_SET_DRILLDOWN_TABLE_PARAMS';
        eventData = action.payload;
        break;
      case DrilldownTableActions.SET_MIN_DRILLDOWN_TABLE_DATE:
        eventKey = 'UI_SET_DRILLDOWN_TABLE_DATE_MIN';
        eventData = { minDate: action.payload };
        break;
      case DrilldownTableActions.SET_MAX_DRILLDOWN_TABLE_DATE:
        eventKey = 'UI_SET_DRILLDOWN_TABLE_DATE_MAX';
        eventData = { maxDate: action.payload };
        break;
      case DrilldownTableActions.SET_DRILLDOWN_TABLE_PHASE:
        eventKey = 'UI_SET_DRILLDOWN_TABLE_PHASE';
        eventData = { phase: action.payload };
        break;
      case DrilldownTableActions.CONFIGURE_SORTING:
        eventKey = 'UI_SET_DRILLDOWN_TABLE_SORT_DIRECTION';
        eventData = action.payload;
        break;
      case DrilldownTableActions.INIT_CLEAR_DRILLDOWN_TABLE:
        eventKey = 'UI_REFRESH_DRILLDOWN_TABLE';
        break;
      case EventNoteActions.CREATE_EVENT_NOTE_REMOTELY:
        eventKey = 'EVENT_NOTE_CREATE';
        break;
      case EventNoteActions.REMOVE_EVENT_NOTE_REMOTELY:
        eventKey = 'EVENT_NOTE_DELETE';
        break;
      case EventNoteActions.UPDATE_EVENT_NOTE_REMOTELY:
        eventKey = 'EVENT_NOTE_UPDATE';
        break;
      case ExternalForecastActions.CHANGE_VISIBILITY_STATUS:
        eventKey = 'UI_TOGGLE_EXTERNAL_FORECAST';
        break;
      case FilterActions.SET_FILTER:
        eventKey = 'UI_SET_FILTER';
        eventData = action.payload;
        break;
      case FilterActions.ADD_SEVERAL_FILTERS:
        eventKey = 'UI_SET_FILTER';
        eventData = action.payload;
        break;
      case FilterActions.REMOVE_FILTER:
        eventKey = 'UI_REMOVE_FILTER';
        eventData = action.payload;
        break;
      case FilterActions.CLEAR_FILTER:
        eventKey = 'UI_REMOVE_FILTER';
        eventData = { filterName: action.payload };
        break;
      case FilterActions.CLEAR_ALL_FILTERS:
        eventKey = 'UI_CLEAR_FILTERS';
        break;
      case NoteAttachmentActions.UPLOAD_NOTE_ATTACHMENT:
        eventKey = 'NOTE_ATTACHMENT_UPLOAD';
        break;
      case NoteAttachmentActions.DELETE_NOTE_ATTACHMENT:
        eventKey = 'NOTE_ATTACHMENT_DELETE';
        break;
      case NoteActions.ADD_NOTE:
        eventKey = 'WELL_NOTE_CREATE';
        break;
      case NoteActions.REMOVE_NOTE:
        eventKey = 'WELL_NOTE_DELETE';
        break;
      case NoteActions.UPDATE_NOTE:
        eventKey = 'WELL_NOTE_UPDATE';
        break;
      case ProductionActions.FETCH_WELL_PRODUCTION:
        const actionPayload = action.payload.graphql.variables.payload;
        eventKey = 'WELL_LOAD_PRODUCTION';
        eventData = { wellId: actionPayload.wellId };
        break;
      case SeriesLayoutActions.CREATE_SERIES_LAYOUT_REMOTELY:
        eventKey = 'SERIES_LAYOUT_CREATE';
        break;
      case SeriesLayoutActions.REMOVE_SERIES_LAYOUT_REMOTELY:
        eventKey = 'SERIES_LAYOUT_DELETE';
        break;
      case SeriesLayoutActions.SET_CURRENT_SERIES_LAYOUT:
        eventKey = 'SERIES_LAYOUT_LOAD';
        break;
      case UiActions.OPEN_SHORTCUT_PANEL:
        eventKey = 'UI_TOGGLE_SHORTCUT_PANEL';
        break;
      case UiActions.OPEN_IMAGE_DETAILS_OVERLAY:
        eventKey = 'NOTE_ATTACHMENT_VIEW';
        break;
      case UiActions.ENABLE_NOTES_MODE:
        eventKey = 'UI_VIEW_NOTES';
        break;
      case UiActions.ENABLE_REGION_OF_INTEREST_MODE:
        eventKey = 'UI_SET_REGION_OF_INTEREST';
        break;
      case UiActions.ENABLE_ZOOM_IN_MODE:
        eventKey = 'UI_ZOOM_IN';
        break;
      case UiActions.SET_LEFT_PANEL_ACTIVE_TAB:
        if (action.payload === 'menu') {
          eventKey = 'UI_VIEW_LOGO_MENU';
        } else if (action.payload === 'drilldown') {
          eventKey = 'UI_VIEW_DRILLDOWN';
        } else if (action.payload === 'notes') {
          eventKey = 'UI_VIEW_NOTES';
        }
        break;
      case UiActions.DISABLE_GROUP_MODE:
        eventKey = 'UI_SET_CURRENT_GROUP';
        eventData = { groupBy: 'well' };
        break;
      case UiActions.SET_CURRENT_GROUP:
        eventKey = 'UI_SET_CURRENT_GROUP';
        eventData = { groupBy: action.payload.subject };
        break;
      case InboxConversationActions.SET_ACTIVE_FILTER:
        eventKey = 'UI_SET_INBOX_FILTER';
        eventData = { filterName: action.payload };
        break;
      case InboxConversationActions.MARK_AS_READ:
        eventKey = 'MARK_NOTES_AS_READ';
        eventData = { notes: action.payload?.graphql?.variables?.payload };
        break;
      case InboxConversationActions.MARK_AS_UNREAD:
        eventKey = 'MARK_NOTES_AS_UNREAD';
        eventData = { notes: action.payload?.graphql?.variables?.payload };
        break;
      case InboxConversationActions.ARCHIVE:
        eventKey = 'ARCHIVE_NOTES';
        eventData = { notes: action.payload?.graphql?.variables?.payload };
        break;
      case InboxConversationActions.GO_TO_PAGE:
        eventKey = 'UI_SET_INBOX_PAGE';
        eventData = { page: action.payload };
        break;
      case VarianceEventActions.CREATE_REMOTE_VARIANCE_EVENT:
        eventKey = 'VARIANCE_EVENT_CREATE';
        break;
      case VarianceEventActions.DELETE_REMOTE_VARIANCE_EVENT:
        eventKey = 'VARIANCE_EVENT_DELETE';
        break;
      case VarianceEventActions.UPDATE_REMOTE_VARIANCE_EVENT:
        eventKey = 'VARIANCE_EVENT_UPDATE';
        break;
      case DrilldownTableActions.SET_CURRENT_VARIANCE_OPTION:
        eventKey = 'SET_VARIANCE_CATEGORY_FILTER';
        eventData = action.payload;
        break;
      case DrilldownTableActions.ADD_MARKED_ROW:
        eventKey = 'UI_DRILLDOWN_MARK_ROW';
        eventData = { wellName: action.payload };
        break;
      case DrilldownTableActions.MARK_ALL_ROWS:
        eventKey = 'UI_DRILLDOWN_MARK_ALL_ROWS';
        break;
      case DrilldownTableActions.ADD_SEVERAL_MARKED_ROWS:
        eventKey = 'UI_DRILLDOWN_MARK_ROWS';
        eventData = { wellNames: action.payload };
        break;
      case DrilldownTableActions.REMOVE_MARKED_ROW:
        eventKey = 'UI_DRILLDOWN_UNMARK_ROW';
        eventData = { wellName: action.payload };
        break;
      case LOCATION_CHANGE: {
        const path = action.payload.location.pathname;
        if (path === paths.BACKLOG()) {
          eventKey = 'UI_VIEW_ALLOCATION_ISSUE_BACKLOG';
        } else if (path === paths.SPOTFIRE_DASHBOARDS()) {
          eventKey = 'UI_VIEW_SPOTFIRE_DASHBOARDS';
        } else {
          return next(action);
        }
        break;
      }
      case MetricsActions.TRACK_EVENT:
        eventKey = action.payload.key;
        eventData = action.payload.data || {};
        break;
      default:
        return next(action);
    }

    MetricsService.captureEvent(eventKey, eventData);

    return next(action);
  };

export default metricsMiddleware;
