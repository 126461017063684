import {
  CalculatedSeriesMapping,
  NormalizedCalculatedSeriesMapping,
} from '../models';

const normalizeCalculatedSeriesMapping = (
  mapping: CalculatedSeriesMapping[],
): NormalizedCalculatedSeriesMapping => {
  const normalizedMapping = mapping.reduce((acc, data) => {
    acc[`c${data.calculatedSeriesId}`] = {
      calculatedSeriesId: `c${data.calculatedSeriesId}`,
      name: data.name,
      source: data.source,
      color: data.color,
      units: data.units,
      tableName: data.tableName,
      tags: data.wellCalculatedSeries.map(t => ({
        wellId: t.wellId,
      })),
    };
    return acc;
  }, {});
  return normalizedMapping;
};

export default normalizeCalculatedSeriesMapping;
