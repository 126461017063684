import * as R from 'ramda';
import {
  call,
  all,
  put,
  select,
  takeLatest,
  takeLeading,
  delay,
} from 'redux-saga/effects';

import { TOKEN_EXPIRED } from 'modules/auth/models/auth';
import { LOGIN_VIA_SSO } from 'modules/auth/AuthActions';
import Metrics from 'modules/metrics/services/Metrics';

import {
  NOTIFICATION_CLOSE_DURATION,
  DEFAULT_NOTIFICATION_DURATION,
  SnackbarNotification,
  ERROR_MESSAGES_LIST,
} from './models/snackbar-notification';
import {
  PUSH,
  openNotification,
  hideNotification,
  popNotification,
  pushNotification,
} from './NotificationActions';
import { getLatestNotification } from './NotificationReducer';

export function* showNotification(notification: SnackbarNotification): any {
  yield put(openNotification(notification));
  yield delay(DEFAULT_NOTIFICATION_DURATION);
  yield put(hideNotification());
  yield delay(NOTIFICATION_CLOSE_DURATION);
}

export function* processNotificationQueue(): any {
  while (true) {
    const notification = yield select(getLatestNotification);
    if (!notification) {
      break;
    }

    yield call(showNotification, notification);
    yield put(popNotification());
  }
}

function* showErrorSaga(action): Generator<any, any, any> {
  if (action.error && action.error[0]) {
    const message = action.error[0].message;
    if (
      message === 'Must change password' &&
      !window.location.pathname.includes('/changePassword')
    ) {
      return;
    } else if (
      message === 'Not Authorized!' &&
      !window.location.pathname.includes('/login')
    ) {
      yield put(pushNotification({ message: 'Your account is inactive' }));
    } else if (message === TOKEN_EXPIRED) {
      return;
    } else if (
      R.values(ERROR_MESSAGES_LIST).includes(message) ||
      message.match(/User is not allowed edit/g)
    ) {
      yield put(
        pushNotification({
          message,
        }),
      );
    } else if (message === 'Not Authorised!') {
      Metrics.captureError({
        location: 'notification',
        originalError: new Error(
          `An error has occurred on the server. ${message}`,
        ),
      });

      yield put(
        pushNotification({
          message: `An error has occurred on the server. Please, try again`,
        }),
      );
    } else if (/Shared link is not valid/i.test(message)) {
      Metrics.captureError({
        location: 'notification',
        originalError: new Error(`Could not perform an action. ${message}`),
      });

      yield put(
        pushNotification({
          message: `An error has occurred on the server. ${message}`,
        }),
      );
    } else {
      if (/Email or password is invalid/i.test(message) === false) {
        Metrics.captureError({
          location: 'notification',
          originalError: new Error('Could not perform an action.' + message),
        });
      }
      if (action.type === `${LOGIN_VIA_SSO}_FAIL`) return;
      yield put(
        pushNotification({
          message:
            'Could not perform an action. Please, check your internet connection',
        }),
      );
    }
  }
}

function* notificationSagas(): Generator<any, any, any> {
  yield all([
    yield takeLeading(PUSH, processNotificationQueue),
    yield takeLatest(action => /_FAIL/g.test(action.type), showErrorSaga),
  ]);
}

export default notificationSagas;
