import * as R from 'ramda';
import * as React from 'react';

import { ForecastData } from 'modules/externalForecast/models';
import { createDataMapForSelectedBar } from 'modules/chart/utils';

import type { ProductionPoint } from 'modules/production/models/production';
import type { TooltipData } from 'modules/ui/models/ui';
import { timeDay } from 'd3-time';
import convertToLocalISOString from '../helpers/convertToLocalISOString';

type CapacityData = { date: Date; capacity: number }[];

interface UseGetCavTolltipDadaProps {
  capacityVarianceData: { date: Date; capacity: number }[][];
  forecastData: ForecastData;
  production: ProductionPoint[];
  tooltipData: TooltipData | null;
  trellisTitle: string;
}

const useGetCavTolltipData = ({
  capacityVarianceData,
  forecastData,
  production,
  tooltipData,
  trellisTitle,
}: UseGetCavTolltipDadaProps) => {
  const flatCapacity = React.useMemo(() => {
    const tempArr: CapacityData = [];
    return tempArr.concat(...capacityVarianceData);
  }, [capacityVarianceData]);

  const productionDataKey = trellisTitle.toLowerCase();
  const tooltipDate =
    R.pathOr(false, ['trellisTooltipData', 'day'], tooltipData) ||
    R.pathOr(false, ['dataSeriesTooltipData', 'day'], tooltipData) ||
    null;

  const dataMap = React.useMemo(
    () =>
      createDataMapForSelectedBar(
        production,
        productionDataKey,
        flatCapacity.slice(0, flatCapacity.length),
        [],
        [],
        forecastData,
      ),
    [production, productionDataKey, flatCapacity, forecastData],
  );

  return tooltipDate
    ? dataMap[convertToLocalISOString(timeDay.floor(tooltipDate))]
    : {};
};

export default useGetCavTolltipData;
