import React from 'react';
import { useSelector } from 'store/models';

import { getDrilldownTableParams } from 'modules/drilldownTable/DrilldownTableReducer';
import { COMPARE_OPTION } from '../models/drilldownTable';

const useDrilldownTableState = () => {
  const currentDrilldownTableState = useSelector(getDrilldownTableParams);

  const isComparingActual = React.useMemo(() => {
    return currentDrilldownTableState.compareOption === COMPARE_OPTION.actual;
  }, [currentDrilldownTableState.compareOption]);

  return {
    currentDrilldownTableState,
    isComparingActual,
  };
};

export default useDrilldownTableState;
