import React from 'react';
import { useSelector } from 'react-redux';

import { getExtremeDates } from 'modules/production/ProductionReducer';
import { NormalizedSeries } from '../models';
import getSeriesData from '../utils/getSeriesData';

type Props = {
  groupOptions: string[];
  series: { [key: string]: NormalizedSeries[] };
};

const useVisibleSeries = (props: Props) => {
  const { groupOptions, series } = props;
  const extremeDates = useSelector(getExtremeDates);

  const isEmpty = React.useMemo(() => {
    const isSeriesItemVisible = ({ day, ...values }: NormalizedSeries) =>
      Object.values(values).find(v => v > 0) &&
      day >= extremeDates.min &&
      day <= extremeDates.max;

    const visibleItem = groupOptions
      .map(id => getSeriesData(id, series))
      .find(d => d.find(isSeriesItemVisible));

    return !visibleItem;
  }, [series, groupOptions]);

  return {
    isEmpty,
  };
};

export default useVisibleSeries;
