import React from 'react';

type ClickOutsideProps = {
  action: (e: MouseEvent) => any;
  className?: string;
  ignoredIds?: string[];
  ignoredClassNames?: string[];
  children: React.ReactNode;
  wrapper?: typeof React.Component | 'div';
};

const ClickOutside = ({
  action,
  className,
  children,
  wrapper: Component = 'div',
  ignoredClassNames,
  ignoredIds,
}: ClickOutsideProps) => {
  const wrapper = React.useRef(null);

  const handleClickOutside = React.useCallback(
    (e: MouseEvent) => {
      const wrapperEl = wrapper?.current as HTMLElement | null;
      const targetEl = e.target as HTMLElement;
      if (!wrapperEl || wrapperEl.contains(targetEl)) return;

      if (ignoredClassNames?.length) {
        const hasClosestCn = (cn: string) => targetEl.closest(`.${cn}`);
        const isIgnored = ignoredClassNames.find(hasClosestCn);
        if (isIgnored) return;
      }

      if (ignoredIds?.length) {
        const hasClosestId = (id: string) => targetEl.closest(`#${id}`);
        const isIgnored = ignoredIds.find(hasClosestId);
        if (isIgnored) return;
      }

      action(e);
    },
    [wrapper, action, ignoredClassNames?.join(''), ignoredIds?.join('')],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  return (
    <Component ref={wrapper} className={className}>
      {children}
    </Component>
  );
};

export default ClickOutside;
