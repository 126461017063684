import * as R from 'ramda';

import type { CustomDrilldownTableItem } from 'modules/drilldownTable/models/drilldownTable';

const createGroupedCustomWellTable = (
  table: CustomDrilldownTableItem[] | null | undefined,
  subject = 'well',
) => {
  if (!subject) return table;

  if (subject === 'all')
    return [
      {
        all: 'All',
        value: table
          ? table.reduce((acc, item) => {
              return acc + item.value;
            }, 0)
          : 0,
        cap: table
          ? table.reduce((acc, item) => {
              return acc + item.cap;
            }, 0)
          : 0,
        var: table
          ? table.reduce((acc, item) => {
              return acc + item.var;
            }, 0)
          : 0,
        act: table
          ? table.reduce((acc, item) => {
              return acc + item.act;
            }, 0)
          : 0,
      },
    ];

  const groupedTable = R.groupBy(R.prop(subject), table);

  return R.keys(groupedTable).reduce((acc, item) => {
    const data = groupedTable[item].reduce(
      (acc, groupedItem) => ({
        value: acc.value + groupedItem.value,
        cap: acc.cap + groupedItem.cap,
        var: acc.var + groupedItem.var,
        act: acc.act + groupedItem.act,
      }),
      { act: 0, value: 0, var: 0, cap: 0 },
    );

    acc.push({ [subject]: item, ...data });
    return acc;
  }, []);
};

export default createGroupedCustomWellTable;
