import * as React from 'react';
import { NOTES_HEIGHT } from '../models';
import { useSelector } from '../../../store/models';
import { getCurrentWellId, getNotesSection } from '../../ui/UIReducer';
import { getLastWellEventsNotes } from '../../eventNotes/EventNotesReducer';
import {
  getWellVarianceEventsByIds,
  getWellVarianceEventsSortedByDate,
} from '../../varianceEvent/VarianceEventReducer';
import {
  getCapacityChangeEventsByIds,
  getWellCapacityEventsSorted,
} from '../../capacityChangeEvent/CapacityChangeEventReducer';
import {
  getRibbonEventsByIds,
  getRibbonOptionsColors,
} from '../../ribbon/RibbonReducer';
import {
  getVarianceCategoriesColors,
  getVarianceCategoriesTitles,
} from '../../category/CategoryReducer';
import { getCapacityCategoriesTitles } from '../../category/CategoryReducer';
import normalizeEvents from '../helpers/normalizeEvents';
import { useDispatch } from 'react-redux';
import { unhoverNotesRibbonSection } from '../../ui/UIActions';
import {
  toggleNotesRibbonSectionPin,
  hoverNotesRibbonSection,
} from '../../ui/UIActions';
import { getFlagsStateMap } from '../../featureFlags/FeatureFlagsReducer';

const useNotesParams = xScale => {
  const currentWellId = useSelector(getCurrentWellId);
  const featureFlags = useSelector(getFlagsStateMap);

  const varianceEventNotes = useSelector(store =>
    getLastWellEventsNotes(store, {
      wellId: currentWellId,
      eventType: 'variance',
    }),
  );
  const capacityEventNotes = useSelector(store =>
    getLastWellEventsNotes(store, {
      wellId: currentWellId,
      eventType: 'capacity',
    }),
  );

  const ribbonEventNotes = useSelector(store =>
    getLastWellEventsNotes(store, {
      wellId: currentWellId,
      eventType: 'ribbon',
    }),
  );
  const varianceEvents = useSelector(store =>
    getWellVarianceEventsByIds(store, {
      wellId: currentWellId,
      varianceEventIds: Object.keys(varianceEventNotes),
    }),
  );
  const varianceEventsSorted = useSelector(state =>
    getWellVarianceEventsSortedByDate(state, { wellId: currentWellId }),
  );
  const capacityEvents = useSelector(store =>
    getCapacityChangeEventsByIds(store, {
      wellId: currentWellId,
      capacityEventIds: Object.keys(capacityEventNotes),
    }),
  );
  const capacityEventsSorted = useSelector(state =>
    getWellCapacityEventsSorted(state, { wellId: currentWellId }),
  );
  const ribbonEvents = useSelector(store =>
    getRibbonEventsByIds(store, {
      wellId: currentWellId,
      ribbonEventIds: Object.keys(ribbonEventNotes),
    }),
  );
  const varianceColors = useSelector(getVarianceCategoriesColors);
  const varianceTitles = useSelector(getVarianceCategoriesTitles);
  const capacityTitles = useSelector(getCapacityCategoriesTitles);
  const ribbonColors = useSelector(getRibbonOptionsColors);

  const notesSection = useSelector(getNotesSection);

  const dispatch = useDispatch();

  const isNotesSectionShown = React.useMemo(
    () =>
      featureFlags.notesRibbonSection &&
      (notesSection.isHovered || notesSection.isPinned),
    [notesSection, featureFlags.notesRibbonSection],
  );

  const events = React.useMemo(() => {
    const varianceNormalizedEvents = normalizeEvents({
      normalizedEvents: [],
      eventsSorted: varianceEventsSorted,
      events: varianceEvents,
      xScale,
      eventNotes: varianceEventNotes,
      type: 'variance',
      titles: varianceTitles,
      optionIdPath: 'varianceOptionId',
      dayStartPath: 'dayStart',
      dayEndPath: 'dayEnd',
      eventColors: varianceColors,
    });

    const capacityNormalizedEvents = normalizeEvents({
      normalizedEvents: [...varianceNormalizedEvents],
      eventsSorted: capacityEventsSorted,
      events: capacityEvents,
      xScale,
      eventNotes: capacityEventNotes,
      type: 'capacity',
      titles: capacityTitles,
      optionIdPath: 'capacityChangeOptionId',
      dayStartPath: 'dayInit',
      dayEndPath: 'dayInit',
    });

    const ribbonNormalizedEvents = normalizeEvents({
      normalizedEvents: [...capacityNormalizedEvents],
      events: ribbonEvents,
      xScale,
      eventNotes: ribbonEventNotes,
      type: 'ribbon',
      optionIdPath: 'ribbonOptionId',
      dayStartPath: 'dayStart',
      dayEndPath: 'dayEnd',
      eventColors: ribbonColors,
    });

    return ribbonNormalizedEvents;
  }, [
    ribbonColors,
    capacityTitles,
    varianceTitles,
    varianceColors,
    ribbonEvents,
    capacityEvents,
    capacityEventsSorted,
    varianceEventsSorted,
    varianceEvents,
    ribbonEventNotes,
    capacityEventNotes,
    varianceEventNotes,
    currentWellId,
  ]);

  const height = React.useMemo(
    () =>
      events.sort((a, b) => b.position.y2 - a.position.y2)[0]?.position.y2 ||
      NOTES_HEIGHT,
    [events],
  );

  const onToggleNotesRibbonSectionPin = React.useCallback(
    () => dispatch(toggleNotesRibbonSectionPin()),
    [dispatch],
  );
  const onHoverNotesRibbonSection = React.useCallback(
    () => dispatch(hoverNotesRibbonSection()),
    [dispatch],
  );
  const onUnhoverNotesRibbonSection = React.useCallback(
    () => dispatch(unhoverNotesRibbonSection()),
    [dispatch],
  );

  return {
    events,
    height,
    notesSection,
    isNotesSectionShown,
    onToggleNotesRibbonSectionPin,
    onHoverNotesRibbonSection,
    onUnhoverNotesRibbonSection,
  };
};

export default useNotesParams;
