import Button from 'components/Button';
import LayoutDecorative from 'modules/app/layouts/LayoutDecorative';
import Metrics from 'modules/metrics/services/Metrics';
import React from 'react';

type Props = {
  children?: React.ReactNode;
};

type State = {
  hasError: boolean;
};

class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Metrics.captureError(
      {
        originalError: error,
        location: 'react',
      },
      errorInfo,
    );
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <LayoutDecorative contentTitle="Oh no!">
          <p style={{ fontSize: '16px' }}>
            An unexpected error occurred. Please click the button below to
            reload the app. A support ticket has already been filed, but if you
            continue to experience issues feel free to reach out to{' '}
            <a href="mailto:support@wiserocksoftware.com">
              support@wiserocksoftware.com
            </a>
            .
          </p>

          <Button width={110} onClick={this.handleReload}>
            Reload
          </Button>
        </LayoutDecorative>
      );
    }

    return this.props.children;
  }
}

export default AppErrorBoundary;
