import ButtonDropdownSplit from 'components/ButtonDropdownSplit';
import { SaveIcon } from 'components/Icons';
import Button from '../Button';
import React from 'react';
import styled from 'styled-components';
import ClickOutside from 'components/ClickOutside';
import { saveCurrentLayoutAttributes } from '../ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

type SaveDropDownButtonProps = {
  onSaveLayout: () => void;
  onSaveLayoutAsNewLayout: () => void;
  hasSaveOption?: boolean;
};

const LayoutDropdownButton = ({
  onSaveLayout,
  onSaveLayoutAsNewLayout,
  hasSaveOption = true,
}: SaveDropDownButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <ButtonTooltip content={saveCurrentLayoutAttributes}>
      <ButtonDropdownSplit.Wrapper onClick={() => setIsOpen(!isOpen)}>
        <ClickOutside action={() => setIsOpen(false)}>
          <>
            <LayoutDropdownButton.Button
              isSpan={true}
              width={48}
              height={30}
              isOpen={isOpen}
            >
              <SaveIcon />
              <LayoutDropdownButton.ToggleButton isOpen={isOpen}>
                <ButtonDropdownSplit.ToggleButtonArrow />
              </LayoutDropdownButton.ToggleButton>
            </LayoutDropdownButton.Button>
          </>

          {isOpen && (
            <LayoutDropdownButton.Menu onClick={() => setIsOpen(false)}>
              {hasSaveOption && (
                <div onClick={onSaveLayout}>
                  <LayoutDropdownButton.MenuItem>
                    Save
                  </LayoutDropdownButton.MenuItem>
                </div>
              )}
              <LayoutDropdownButton.MenuItem onClick={onSaveLayoutAsNewLayout}>
                Save as New Layout
              </LayoutDropdownButton.MenuItem>
            </LayoutDropdownButton.Menu>
          )}
        </ClickOutside>
      </ButtonDropdownSplit.Wrapper>
    </ButtonTooltip>
  );
};
LayoutDropdownButton.Button = styled(Button)`
  ${({ isOpen }) =>
    isOpen &&
    'background: rgba(10, 10, 10, 0.1); box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.05);'}
`;

LayoutDropdownButton.ToggleButton = styled(ButtonDropdownSplit.ToggleButton)`
  height: 28px;
  border: none;
  box-shadow: none;
  background: none;
`;

LayoutDropdownButton.Menu = styled(ButtonDropdownSplit.Menu)`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

LayoutDropdownButton.MenuItem = styled(ButtonDropdownSplit.MenuItem)`
  padding: 14px 16px;
`;

export default LayoutDropdownButton;
