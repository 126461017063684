const ArrayBoost = {
  forEach: <T>(array: T[], f: (item: T, index: number, array: T[]) => any) => {
    for (let i = 0; i < array.length; i++) {
      f(array[i], i, array);
    }
  },
  reduce: <T, AccType>(
    array: T[],
    f: (acc: AccType, item: T, index: number, array: T[]) => AccType,
    acc: AccType,
  ): AccType => {
    for (let i = 0; i < array.length; i++) {
      f(acc, array[i], i, array);
    }
    return acc;
  },
  map: <T, ReturningType>(
    array: T[],
    f: (item: T, index: number, array: T[]) => ReturningType,
  ): ReturningType[] => {
    const result: ReturningType[] = [];
    for (let i = 0; i < array.length; i++) {
      result.push(f(array[i], i, array));
    }
    return result;
  },
  filter: <T>(
    array: T[],
    f: (item: T, index: number, array: T[]) => boolean,
  ): T[] => {
    const result: T[] = [];
    for (let i = 0; i < array.length; i++) {
      if (f(array[i], i, array)) result.push(array[i]);
    }
    return result;
  },
  every: <T>(
    array: T[],
    f: (item: T, index: number, array: T[]) => boolean,
  ): boolean => {
    for (let i = 0; i < array.length; i++) {
      if (!f(array[i], i, array)) return false;
    }
    return true;
  },
  some: <T>(
    array: T[],
    f: (item: T, index: number, array: T[]) => boolean,
  ): boolean => {
    for (let i = 0; i < array.length; i++) {
      if (f(array[i], i, array)) return true;
    }
    return false;
  },
  find: <T>(
    array: T[],
    f: (item: T, index: number, array: T[]) => boolean,
  ): T | undefined => {
    for (let i = 0; i < array.length; i++) {
      if (f(array[i], i, array)) return array[i];
    }
  },
};

export default ArrayBoost;
