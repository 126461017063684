import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { SSO_REDIRECT_PATH_KEY } from 'modules/router/constants';
import { loginViaSSO } from '../AuthActions';
import { getIsAuthenticated } from '../AuthReducer';

export const AuthCallback = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const [path] = React.useState(() => {
    const defaultPath = '/dashboard';
    const exactPath = window.localStorage.getItem(SSO_REDIRECT_PATH_KEY);
    return exactPath ?? defaultPath;
  });

  React.useEffect(() => {
    const match = /(?<=code=).+&??/.exec(window.location.search);
    if (!match) return;

    dispatch(loginViaSSO({ code: match[0] }));
  }, []);

  return <>{isAuthenticated && <Redirect to={path} />}</>;
};
