import * as React from 'react';
import { ExtendedTabInfo } from 'components/Tabs/Model';
import { Tab } from './Tab';
import styled from 'styled-components';
import { EnvelopeIcon } from 'components/Icons';
import { InboxBadge } from './Badge';
import { useSelector } from 'store/models';
import { getUnreadConversations } from 'modules/inboxConversation/InboxConversationReducer';
import { notesAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

export const Inbox = ({ tab }: { tab?: ExtendedTabInfo }) => {
  const unreadNotes = useSelector(getUnreadConversations);

  return tab ? (
    <ButtonTooltip content={notesAttributes}>
      <Tab onClick={tab.openTab} isActive={tab.isActive}>
        <Inbox.TabName>{tab.tabName}</Inbox.TabName>
        <EnvelopeIcon width={21} height={21} fill="#484848" />
        {unreadNotes > 0 ? (
          <InboxBadge top={5} right={21}>
            {unreadNotes}
          </InboxBadge>
        ) : null}
      </Tab>
    </ButtonTooltip>
  ) : null;
};

Inbox.TabName = styled.div`
  margin-right: 6px;
`;
