import * as React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from '../Button';
import ClickOutside from '../ClickOutside';
import {
  ButtonTooltipAttributes,
  initialTooltipContent,
  savedLayoutsAttributes,
} from '../ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

type ButtonDropdownSplitProps = {
  children: React.ReactNode;
  buttonProps: Omit<ButtonProps, 'children'>;
  buttonChildren: React.ReactNode;
  buttonAction: (...args: any[]) => any;
  buttonTitle?: ButtonTooltipAttributes;
};

const ButtonDropdownSplit = ({
  buttonProps,
  buttonChildren,
  buttonAction,
  children,
  buttonTitle,
}: ButtonDropdownSplitProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <ButtonDropdownSplit.Wrapper>
      <ButtonTooltip
        content={buttonTitle ? buttonTitle : initialTooltipContent}
      >
        <Button {...buttonProps} onClick={buttonAction}>
          {buttonChildren}
        </Button>
      </ButtonTooltip>
      {Boolean(children) && (
        <ClickOutside action={() => setIsOpen(false)}>
          <ButtonTooltip content={savedLayoutsAttributes}>
            <ButtonDropdownSplit.ToggleButton
              height={buttonProps.height}
              onClick={() => setIsOpen(!isOpen)}
              isOpen={isOpen}
            >
              <ButtonDropdownSplit.ToggleButtonArrow />
            </ButtonDropdownSplit.ToggleButton>
          </ButtonTooltip>

          {isOpen && (
            <ButtonDropdownSplit.Menu onClick={() => setIsOpen(false)}>
              {children}
            </ButtonDropdownSplit.Menu>
          )}
        </ClickOutside>
      )}
    </ButtonDropdownSplit.Wrapper>
  );
};

ButtonDropdownSplit.Wrapper = styled.div`
  pointer-events: auto;
  position: relative;
  display: flex;
`;

ButtonDropdownSplit.ToggleButtonArrow = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`;

ButtonDropdownSplit.ToggleButton = styled(Button)`
  font-size: 8px;
  border-left: none;
  width: 20px;
  background: ${({ isOpen }) => (isOpen ? 'rgba(10,10,10,0.1)' : 'auto')};

  & > span {
    ${({ isOpen, theme }) =>
      isOpen
        ? `border-bottom: 4px solid ${theme.colors.primaryText}`
        : `border-top: 4px solid ${theme.colors.primaryText};`}
  }
`;

ButtonDropdownSplit.Menu = styled.div`
  top: 100%;
  right: -12px;
  position: absolute;
  z-index: 121;
  background: #fff;
  border-radius: 8px;
  padding: 0 0 8px;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.15), 0 0 12px 0 rgba(0, 0, 0, 0.07);
`;

ButtonDropdownSplit.MenuTitle = styled.p`
  margin: 0;
  padding: 10px 12px;
  font-weight: bold;
  white-space: nowrap;
`;

ButtonDropdownSplit.MenuItem = styled.div`
  cursor: pointer;
  padding: 5px 12px 5px 32px;
  white-space: nowrap;

  &:hover {
    background-color: #eee;
  }

  ${({ isActive }) =>
    isActive ? 'background-color: #e1e1e1; pointer-events: none;' : ''}
`;

export default ButtonDropdownSplit;
