import environmentConfig from 'config/EnvironmentConfig';
import Env from 'helpers/Env';

import type { MetricsPluginInternals } from '../../models';

// type Config = {
//   accId: string;
//   appId: string;
//   licenseKey: string;
// };

const newRelicPlugin: MetricsPluginInternals = {
  isSourceCodeLoaded: () => {
    return !!(
      window.newrelic &&
      window.newrelic.setCustomAttribute &&
      window.newrelic.addPageAction &&
      window.newrelic.noticeError
    );
  },

  identifyUser: user => {
    window.newrelic.setCustomAttribute('userId', user.id);
    window.newrelic.setCustomAttribute('userData', user);
  },

  anonomizeUser: () => {
    window.newrelic.setCustomAttribute('userId', null);
    window.newrelic.setCustomAttribute('userData', null);
  },

  captureEvent: (event, data) => {
    window.newrelic.addPageAction(
      event.name,
      Object.assign({ actionCode: event.code, actionData: data }, data),
    );
  },

  captureError: (error, data) => {
    window.newrelic.noticeError(
      error.originalError,
      Object.assign({ errorLocation: error.location }, data),
    );
  },

  initialize: () => {
    const packageVersion = environmentConfig.packageVersion;
    window.newrelic?.setCustomAttribute?.('releaseVersion', packageVersion);

    const sha1 = Env.getVariable('REACT_APP_GIT_SHA1');
    window.newrelic?.setCustomAttribute?.('releaseSHA', sha1);
    window.newrelic?.addRelease?.(
      `[FrontEnd] ${Env.getVariable('REACT_APP_NAME', 'local')}`,
      sha1,
    );
  },
};

export default newRelicPlugin;
