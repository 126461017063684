import type { RibbonOption } from '../models';
const normalizeRibbonOptionData = (options: RibbonOption[]): RibbonOption[] =>
  options.map((option: RibbonOption) => {
    option.extraInputsType = JSON.parse(option.extraInputsType);
    option.ribbonId = '' + option.ribbonId;
    option.id = '' + option.id;
    return option;
  });

export default normalizeRibbonOptionData;
