import { utcDay } from 'd3-time';
import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import { AppSettingType } from 'modules/appSettings/model';

import calculateToday from './utils/calculateToday';
import {
  FETCH_APP_CONFIG,
  NORMALIZE_APP_CONFIG,
  SET_APP_CONFIG_LOCALLY,
} from './AppConfigActions';
import { Action, RootState, Selector } from 'store/models';
import {
  AppConfig,
  LOCAL_APP_SETTINGS,
  LOCAL_APP_SETTINGS_KEYS,
} from './models/appConfig';
import getTodayWithTimezoneOffset from './utils/getTodayWithTimezoneOffset';
import { Reducer } from 'redux';
import { DEFAULT_BANNER } from './models/appConfig';

export const STATE_KEY = 'appConfig';

export type AppConfigState = { [key: string]: any };

const initialState: AppConfigState = {
  drilldownDefaultFromDate: utcDay.round(
    new Date(new Date().getFullYear(), 0, 1),
  ),

  drilldownDefaultToDate: utcDay.round(
    new Date(new Date().getFullYear(), 0, 31),
  ),
  today: getTodayWithTimezoneOffset(),
  loomJWS: null,
};

const AppConfigReducer = (
  state: AppConfigState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case NORMALIZE_APP_CONFIG: {
      const config = action.payload;
      const newConfig = {
        ...config,
        drilldownDefaultFromDate: utcDay.round(
          new Date(config.drilldownDefaultFromDate),
        ),
        drilldownDefaultToDate: utcDay.round(
          new Date(config.drilldownDefaultToDate),
        ),
        ...LOCAL_APP_SETTINGS.reduce((acc, setting) => {
          const value =
            config[setting.key] === undefined || config[setting.key] === null
              ? setting.default
              : setting.convert(config[setting.key]);
          acc[setting.key] = value;
          acc['default_' + setting.key] = value;
          return acc;
        }, {}),
        today: calculateToday(config.today, config.todayOffset),
        loomJWS: config.loomJWS,
      };
      return R.mergeAll([state, newConfig]);
    }
    case `${FETCH_APP_CONFIG}_FAIL`: {
      const newConfig: Partial<AppConfig> = {
        ...LOCAL_APP_SETTINGS.reduce((acc, setting) => {
          const value = setting.default;
          acc[setting.key] = value;
          acc['default_' + setting.key] = value;
          return acc;
        }, {}),
        loginBanner: { ...DEFAULT_BANNER },
      };

      return R.mergeAll([state, newConfig]);
    }
    case SET_APP_CONFIG_LOCALLY: {
      const payload = action.payload;

      return R.mergeAll([state, payload]);
    }
    default: {
      return state;
    }
  }
};

export const getAppConfig = (state: RootState) =>
  R.propOr({}, STATE_KEY, state) as AppConfig;

export const getLocalAppSettings: Selector<{ [key: string]: any }> =
  createSelector(getAppConfig, appConfig =>
    R.pick(LOCAL_APP_SETTINGS_KEYS, appConfig),
  );

export const getFormattedLocalAppSettings: Selector<AppSettingType<any>[]> =
  createSelector(getAppConfig, appConfig =>
    LOCAL_APP_SETTINGS.map(setting => ({
      ...R.pick(['name', 'key'], setting),
      current: appConfig[setting.key],
      default: appConfig['default_' + setting.key],
    })),
  );

export const getToday = (state: RootState) =>
  R.pathOr(initialState.today, [STATE_KEY, 'today'], state) as Date;

export default filterActions(AppConfigReducer as any, [
  SET_APP_CONFIG_LOCALLY,
  NORMALIZE_APP_CONFIG,
  `${FETCH_APP_CONFIG}_FAIL`,
]) as Reducer<AppConfigState>;
