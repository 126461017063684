import * as React from 'react';
import { TooltipOptionsType } from '../models/index';
import { Note } from '../../notes/models/notes';

interface EventTriangleProps {
  position: number;
  openTooltip: (data: TooltipOptionsType) => void;
  closeTooltip: () => void;
  note: Note;
}

const EventTriangle = ({
  position,
  note,
  openTooltip,
  closeTooltip,
}: EventTriangleProps) => {
  const onOpenTooltip = () => {
    openTooltip({
      type: 'external',
      x: position,
      y: 25,
      externalNote: note,
    });
  };

  return (
    <g
      style={{ position: 'relative', transform: 'translateX(-5px)' }}
      onMouseEnter={onOpenTooltip}
      onMouseLeave={closeTooltip}
      key={position}
      pointerEvents="auto"
    >
      <polygon
        fill="#0073B6"
        points={`${position},${0} ${position + 12},${0} ${position + 6},${8}`}
      />
    </g>
  );
};

export default EventTriangle;
