import * as React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { RiArrowDropDownLine } from 'react-icons/ri';

import { capitalize } from 'helpers/String';
import { useSelector } from 'store/models';
import { getSeriesMapping } from 'modules/series/SeriesReducer';
import { getCoreSeriesOptionList } from 'modules/chartOptions/ChartOptionsReducer';

import {
  DrilldownTableParams,
  COMPARE_OPTION,
} from '../../models/drilldownTable';
import {
  getIsCustomLayout,
  getIsCustomLayoutParams,
} from '../../DrilldownTableReducer';
import {
  setIsCustomLayout,
  setIsCustomLayoutParams,
} from '../../DrilldownTableActions';
import { WellDrilldownSelectMenu } from './WellDrilldownTableSelectMenu';

interface WellDrilldownSelectProps {
  currentDrilldownTableState: DrilldownTableParams;
  onSetCompareOption: (optionKey: string) => void;
  setDrilldownTableOption: (option: string) => void;
  setGrossNet: (grossNet: string) => void;
  setRateVolume: (rate: string) => void;
}

export const WellDrilldownSelect = ({
  currentDrilldownTableState,
  onSetCompareOption,
  setDrilldownTableOption,
  setGrossNet,
  setRateVolume,
}: WellDrilldownSelectProps) => {
  const dispatch = useDispatch();
  const isCustomLayout = useSelector(getIsCustomLayout);
  const seriesMapping = useSelector(getSeriesMapping);
  const coreSeriesMapping = useSelector(getCoreSeriesOptionList);
  const [isCustomLayoutLocally, setIsCustomLayoutLocally] =
    React.useState(isCustomLayout);
  const [isOpen, setIsOpen] = React.useState(false);

  const customLayoutsParams = useSelector(getIsCustomLayoutParams);

  const setCustomLayout = React.useCallback(
    (isCustom: boolean) => {
      dispatch(setIsCustomLayout(isCustom));
    },
    [setIsCustomLayout],
  );

  const setCustomLayoutParams = React.useCallback(
    (params: { seriesId: string; aggregateFn: string }) => {
      dispatch(setIsCustomLayoutParams(params));
    },
    [setIsCustomLayoutParams],
  );

  return (
    <WellDrilldownSelect.Container>
      <WellDrilldownSelect.DropdownButton onClick={() => setIsOpen(!isOpen)}>
        <WellDrilldownSelect.DropdownButtonNamesContainer>
          <WellDrilldownSelect.DropdownButtonName>
            {isCustomLayout && 'Custom'}
            {currentDrilldownTableState.compareOption ===
              COMPARE_OPTION.actual &&
              !isCustomLayout &&
              'Capacity vs Actual'}
            {currentDrilldownTableState.compareOption ===
              COMPARE_OPTION.extVsCap &&
              !isCustomLayout &&
              'RE Fcst vs Capacity'}
            {currentDrilldownTableState.compareOption ===
              COMPARE_OPTION.actVsExt &&
              !isCustomLayout &&
              'RE Fcst vs Actual'}
          </WellDrilldownSelect.DropdownButtonName>
          <WellDrilldownSelect.DropdownButtonTile>
            {!isCustomLayout &&
              `${currentDrilldownTableState.grossNet} ${currentDrilldownTableState.phase} ${currentDrilldownTableState.rateVolume}`}
            {isCustomLayout &&
              `${
                customLayoutsParams?.aggregateFn
                  ? capitalize(customLayoutsParams.aggregateFn)
                  : ''
              }(${
                customLayoutsParams?.seriesId
                  ? coreSeriesMapping?.[customLayoutsParams.seriesId]?.title ||
                    seriesMapping?.[customLayoutsParams.seriesId]?.sourceName
                  : ''
              })`}
          </WellDrilldownSelect.DropdownButtonTile>
        </WellDrilldownSelect.DropdownButtonNamesContainer>
        <WellDrilldownSelect.DropDownArrowIcon />
      </WellDrilldownSelect.DropdownButton>
      {isOpen && (
        <WellDrilldownSelectMenu
          currentDrilldownTableState={currentDrilldownTableState}
          onSetCompareOption={onSetCompareOption}
          setDrilldownTableOption={setDrilldownTableOption}
          setGrossNet={setGrossNet}
          setRateVolume={setRateVolume}
          setOpenMenu={setIsOpen}
          isCustomLayout={isCustomLayoutLocally}
          setIsCustomLayout={setCustomLayout}
          setIsCustomLayoutLocally={setIsCustomLayoutLocally}
          seriesMapping={seriesMapping}
          coreSeriesMapping={coreSeriesMapping}
          setCustomLayoutParams={setCustomLayoutParams}
          customLayoutsParams={customLayoutsParams}
        />
      )}
    </WellDrilldownSelect.Container>
  );
};

WellDrilldownSelect.Container = styled.div`
  width: 100%;
  height: 26px;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  color: #484848;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 8px;
`;

WellDrilldownSelect.DropdownButton = styled.div`
  width: 100%;
  height: 26px;
  border: 1px solid #c1c1c1;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

WellDrilldownSelect.DropdownButtonNamesContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding-left: 6px;
`;

WellDrilldownSelect.DropdownButtonName = styled.span`
  font-size: 14px;
`;

WellDrilldownSelect.DropdownButtonTile = styled.span`
  font-size: 12px;
  margin-left: 6px;
  color: #909090;
`;

WellDrilldownSelect.DropDownArrowIcon = styled(RiArrowDropDownLine)`
  font-size: 26px;
`;
