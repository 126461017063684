import gql from 'graphql-tag';
import { AC } from 'store/models';

export const namespace = 'SPOTFIRE';
export const FETCH_SPOTFIRE_SETTINGS = `${namespace}/FETCH_SETTINGS`;
export const FETCH_SPOTFIRE_DASHBOARDS = `${namespace}/FETCH_DASHBOARDS`;
export const FETCH_SPOTFIRE_SETTINGS_SUCCESS = `${FETCH_SPOTFIRE_SETTINGS}_SUCCESS`;
export const FETCH_SPOTFIRE_DASHBOARDS_SUCCESS = `${FETCH_SPOTFIRE_DASHBOARDS}_SUCCESS`;
export const SET_SPOTFIRE_FILTERED_WELLS = `${namespace}/SET_FILTERED_WELLS`;
export const SET_SPOTFIRE_MARKED_WELLS = `${namespace}/SET_MARKED_WELLS`;
export const SET_SPOTFIRE_VIEW_START_DATE = `${namespace}/SET_VIEW_START_DATE`;
export const SET_SPOTFIRE_VIEW_END_DATE = `${namespace}/SET_VIEW_END_DATE`;
export const SET_WINDOW = `${namespace}/SET_WINDOW`;

const spotfireDataQuery = gql`
  query {
    listSpotfireData {
      url
      apiVersion
    }
  }
`;

const spotfireDashboardsQuery = gql`
  query {
    listAllSpotfireDashboards {
      id
      name
      description
      path
      page
      customization
      parameters
      reloadInstances
      spotfireUrl
      apiVersion
      visible
      server
    }
  }
`;

export const fetchSpotfireSettings = () => ({
  type: FETCH_SPOTFIRE_SETTINGS,
  payload: {
    key: 'listSpotfireData',
    graphql: {
      query: spotfireDataQuery,
    },
  },
});

export const fetchSpotfireDashboards = () => ({
  type: FETCH_SPOTFIRE_DASHBOARDS,
  payload: {
    key: 'listAllSpotfireDashboards',
    graphql: {
      query: spotfireDashboardsQuery,
    },
  },
});

export const setSpotfireFilteredWells: AC<string[]> = payload => ({
  type: SET_SPOTFIRE_FILTERED_WELLS,
  payload,
});

export const setSpotfireMarkedWells: AC<string[]> = payload => ({
  type: SET_SPOTFIRE_MARKED_WELLS,
  payload,
});

export const setSpotfireViewStartDate: AC<string> = payload => ({
  type: SET_SPOTFIRE_VIEW_START_DATE,
  payload,
});

export const setSpotfireViewEndDate: AC<string> = payload => ({
  type: SET_SPOTFIRE_VIEW_END_DATE,
  payload,
});

export const setWindow = (payload: Window | null) => ({
  type: SET_WINDOW,
  payload,
});
