import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'store/models';
import styled from 'styled-components';
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa';

import { linkify } from 'helpers';

import Button from 'components/Button';
import type { User } from 'modules/user/models/user';
import { isInside } from 'modules/chart/utils';

import NoteImages from './NoteImages';
import EventNoteForm from './EventNoteForm';
import InactiveNoteAttachment from './InactiveNoteAttachment';
import { extensionsRegex, loomRegex } from '../models';
import { highlightMentions } from '../utils/mentions';
import { useLoomVisualizer } from 'context/loom';
import { getScreenInteraction } from 'modules/ui/UIReducer';
import { LoomAttachment } from './LoomAttachment';
import { GraphqlMutationAction } from 'store/models';

interface InactiveNoteProps {
  dateLabel: string;
  isCurrentUser: boolean;
  hideNotification: () => void;
  initialNoteAttachments: any[];
  initialText: string;
  isEdited: boolean;
  isEditing: boolean;
  noteAttachments: any[];
  noteId: string;
  noteText: string;
  notificate;
  onAttacmentClick: (fileId: string) => void;
  onCloseNoteImput: () => void;
  onDeleteClick: (noteId: string) => void;
  onDeleteExistedNoteAttachments: (
    timestamp: any,
    noteId: string,
    fileIds: string[],
  ) => void;
  onEditClick: (noteId: string) => void;
  onRemoveEventNote: (id: string) => void;
  onSetTempAttachments: (attachmentItem: any, noteId: string) => void;
  onSubmit: (text: string, attachments: any) => void;
  onUploadAttachment: (
    attachment: Record<string, any>,
  ) => GraphqlMutationAction;
  onUploadLoomAttachment: (url: string) => GraphqlMutationAction;
  setText: (text: string) => void;
  tempAttachments: any[];
  userName: string;
  usersByEmailName: { [emailName: string]: User };
  onDeleteTempAttachments: (timestamp: any, noteId: string, fileId) => void;
}

const InactiveNote = ({
  onDeleteClick,
  onEditClick,
  noteId,
  noteText,
  userName,
  dateLabel,
  isEdited,
  isCurrentUser,
  onSubmit,
  onAttacmentClick,
  onCloseNoteImput,
  onUploadAttachment,
  onUploadLoomAttachment,
  usersByEmailName,
  initialText,
  setText,
  tempAttachments,
  onRemoveEventNote,
  onSetTempAttachments,
  noteAttachments,
  isEditing,
  notificate,
  hideNotification,
  onDeleteTempAttachments,
  initialNoteAttachments,
  onDeleteExistedNoteAttachments,
}: InactiveNoteProps) => {
  const [attachmentsToDelete, setAttachmentsToDelete] = React.useState<
    string[]
  >([]);

  const [isHovered, setIsHovered] = React.useState(false);
  const isRecording = useSelector(getScreenInteraction).isRecording;

  const notDeletedNoteAttachments = React.useMemo(
    () => noteAttachments.filter(a => !attachmentsToDelete.includes(a.id)),
    [noteAttachments, attachmentsToDelete],
  );

  const loomVisualizer = useLoomVisualizer();

  const noteContainerRef: React.RefObject<HTMLElement | null> =
    React.useRef(null);
  const attachmentsToDisplay = React.useMemo(() => {
    const tempAttachmentsToDisplay = R.values(tempAttachments)
      .concat(notDeletedNoteAttachments)
      .reduce(
        (acc, attachment) => {
          if (loomRegex.test(attachment.url)) {
            acc.looms = R.assoc(attachment.id, attachment, acc.looms);
          } else if (extensionsRegex.test(attachment.name)) {
            acc.images = R.assoc(attachment.id, attachment, acc.images);
          } else {
            acc.files = R.assoc(attachment.id, attachment, acc.files);
          }

          return acc;
        },
        { images: {}, files: {} },
      );

    return {
      images: R.values(tempAttachmentsToDisplay.images),
      files: R.values(tempAttachmentsToDisplay.files),
      looms: R.values(tempAttachmentsToDisplay.looms),
    };
  }, [notDeletedNoteAttachments, tempAttachments]);

  const getContainerAreaCoords = React.useCallback(
    elementBoundingRect => ({
      x1: elementBoundingRect.x,
      x2: elementBoundingRect.x + elementBoundingRect.width,
      y1: elementBoundingRect.y,
      y2: elementBoundingRect.y + elementBoundingRect.height,
    }),
    [],
  );

  const deleteExistedNoteAttachment = React.useCallback(
    fileId => setAttachmentsToDelete([...attachmentsToDelete, fileId]),
    [attachmentsToDelete, setAttachmentsToDelete],
  );

  const onMouseClickBlurHandler = React.useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const currentPointerPosition = {
        clientX,
        clientY,
      };
      if (noteContainerRef.current && isEditing) {
        const noteContainerCoords = getContainerAreaCoords(
          noteContainerRef.current.getBoundingClientRect(),
        );
        const currentPointerIsInsideNote = isInside(
          currentPointerPosition,
          noteContainerCoords,
        );
        if (
          !currentPointerIsInsideNote &&
          noteText === initialText &&
          !attachmentsToDelete.length &&
          R.isEmpty(tempAttachments) &&
          !isRecording
        ) {
          onCloseNoteImput();
          setAttachmentsToDelete([]);
        }
      }
    },
    [
      getContainerAreaCoords,
      initialText,
      noteText,
      onCloseNoteImput,
      tempAttachments,
      isEditing,
      attachmentsToDelete,
      setAttachmentsToDelete,
      isRecording,
    ],
  );

  React.useEffect(() => {
    if (!isEditing) {
      setAttachmentsToDelete([]);
    }
  }, [isEditing]);

  React.useEffect(() => {
    const dashboardWrapper = document.querySelector('#imageDetailRoot');
    dashboardWrapper?.addEventListener(
      'click',
      onMouseClickBlurHandler as EventListener,
    );
    return () =>
      dashboardWrapper?.removeEventListener(
        'click',
        onMouseClickBlurHandler as EventListener,
      );
  }, [onMouseClickBlurHandler]);

  return (
    <InactiveNote.Wrapper
      isCurrentUser={isCurrentUser}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <InactiveNote.ContentWrapper ref={noteContainerRef}>
        {isCurrentUser && !isEditing && isHovered && (
          <InactiveNote.NoteActionsOverlay>
            <Button
              transparent
              width={20}
              height={20}
              onClick={() => onEditClick(noteId)}
              title="Edit"
            >
              <FaPencilAlt />
            </Button>

            <Button
              transparent
              width={20}
              height={20}
              onClick={() => onDeleteClick(noteId)}
              title="Delete"
            >
              <FaTrashAlt />
            </Button>
          </InactiveNote.NoteActionsOverlay>
        )}

        <InactiveNote.NoteInformationContainer isEditing={isEditing}>
          <InactiveNote.NoteInformation>
            <InactiveNote.UsernameLabel>{userName}</InactiveNote.UsernameLabel>

            {isEdited && !isEditing && (
              <InactiveNote.EditedLabel>(edited)</InactiveNote.EditedLabel>
            )}
          </InactiveNote.NoteInformation>

          <InactiveNote.NoteInformation>
            <InactiveNote.Date>{dateLabel}</InactiveNote.Date>
          </InactiveNote.NoteInformation>
        </InactiveNote.NoteInformationContainer>

        <InactiveNote.Divider />

        {isEditing ? (
          <InactiveNote.EditFormContainer>
            <EventNoteForm
              isDeleteInitialNoteAttachments={!!attachmentsToDelete.length}
              noteText={noteText}
              onSubmit={(text: string, attachments: any) => {
                if (!noteText && !notDeletedNoteAttachments.length) {
                  onRemoveEventNote(noteId);
                } else {
                  onDeleteExistedNoteAttachments(
                    undefined,
                    noteId,
                    attachmentsToDelete,
                  );
                  onSubmit(text, attachments);
                  setAttachmentsToDelete([]);
                }
              }}
              isForEdit={true}
              onUploadAttachment={onUploadAttachment}
              onUploadLoomAttachment={onUploadLoomAttachment}
              usersByEmailName={usersByEmailName}
              initialNoteAttachments={initialNoteAttachments}
              initialText={initialText}
              setText={setText}
              noteAttachments={noteAttachments}
              noteId={noteId}
              tempAttachments={tempAttachments}
              onSetTempAttachments={onSetTempAttachments}
              notificate={notificate}
              hideNotification={hideNotification}
              onDeleteTempAttachments={onDeleteTempAttachments}
            />
          </InactiveNote.EditFormContainer>
        ) : noteText ? (
          <InactiveNote.NoteText
            isCurrentUser={isCurrentUser}
            dangerouslySetInnerHTML={highlightMentions(
              linkify(noteText),
              usersByEmailName,
            )}
          />
        ) : null}

        {!R.isEmpty(attachmentsToDisplay.images) && (
          <InactiveNote.Images hasNoteText={noteText} isEditing={isEditing}>
            <NoteImages
              initialNoteAttachments={initialNoteAttachments}
              attachments={attachmentsToDisplay.images}
              deleteTempAttachment={(timestemp, fileId) =>
                onDeleteTempAttachments(timestemp, noteId, fileId)
              }
              isEditing={isEditing}
              onAttacmentClick={onAttacmentClick}
              deleteExistedNoteAttachment={deleteExistedNoteAttachment}
              isSentMessage={true}
            />
          </InactiveNote.Images>
        )}

        {!R.isEmpty(attachmentsToDisplay.files) && (
          <>
            <InactiveNote.Splitter
              hasNoteText={noteText}
              hasAnyImages={attachmentsToDisplay.images.length !== 0}
              isEditing={isEditing}
            />

            <EventNoteForm.Files style={{ marginBottom: 8 }}>
              {attachmentsToDisplay.files.map(attachmentItem => (
                <InactiveNoteAttachment
                  isUploaded={initialNoteAttachments.includes(attachmentItem)}
                  key={attachmentItem.id + attachmentItem.name}
                  attachment={attachmentItem}
                  isEditing={isEditing}
                  onDeleteTempAttachments={onDeleteTempAttachments}
                  noteId={noteId}
                  deleteExistedNoteAttachment={deleteExistedNoteAttachment}
                />
              ))}
            </EventNoteForm.Files>
          </>
        )}

        {!R.isEmpty(attachmentsToDisplay.looms) && (
          <EventNoteForm.Looms>
            {attachmentsToDisplay.looms.map(attachmentItem => (
              <LoomAttachment
                key={attachmentItem.id}
                attachment={attachmentItem}
                remove={() => {
                  onDeleteTempAttachments(
                    attachmentItem.timestamp,
                    noteId,
                    attachmentItem.id,
                  );
                }}
                isEditing={isEditing}
                visualize={() => loomVisualizer.visualize(attachmentItem.url)}
                getInlineLoom={() =>
                  loomVisualizer.getInlineLoomFor(attachmentItem.url, {
                    width: 330,
                    height: 200,
                  })
                }
              />
            ))}
          </EventNoteForm.Looms>
        )}
      </InactiveNote.ContentWrapper>
    </InactiveNote.Wrapper>
  );
};

InactiveNote.Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.05);
`;

InactiveNote.NoteInformationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  height: 16px;
  padding: 0 12px;
  margin: 8px 0;
`;

InactiveNote.Divider = styled.div`
  height: 1px;
  background-color: #e7e7e7;
`;

InactiveNote.NoteInformation = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

InactiveNote.ContentWrapper = styled.div`
  position: relative;
`;

InactiveNote.NoteActionsOverlay = styled.div`
  position: absolute;
  background: #fff;
  right: 0;
  top: 0;
  padding: 0 8px;
  display: flex;
  justify-content: flex-end;
  gap: 2px;

  button {
    background-color: #fff;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
  }

  svg {
    transform: scale(0.9);
  }
`;

InactiveNote.EditFormContainer = styled.div`
  padding: 12px 10px 10px 10px;
`;

InactiveNote.NoteText = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding: 10px 12px 10px 12px;
  white-space: pre-line;
  overflow: hidden;
  word-break: break-word;
  color: inherit;
  user-select: text;

  > a {
    color: #1890ff !important;
    text-decoration: auto;
  }
`;
InactiveNote.Images = styled.div`
  margin-top: ${props => (props.hasNoteText || props.isEditing ? '0' : '10px')};
`;

InactiveNote.Splitter = styled.div`
  height: 1px;
  background-color: ${props =>
    props.hasNoteText && !props.hasAnyImages ? '#e7e7e7' : ''};
  margin-bottom: ${props => {
    return props.hasAnyImages ? '3px' : props.isEditing ? '0' : '7px';
  }};
`;

InactiveNote.UsernameLabel = styled.p`
  font-weight: bold;
  font-size: 12px;
  color: inherit;
`;

InactiveNote.Date = styled.p`
  color: #9a9a9a;
`;

InactiveNote.EditedLabel = styled.p``;

InactiveNote.NoteButtonsWrapper = styled.div`
  justify-self: flex-end;
  display: grid;
  grid-template-columns: 18px 18px;

  gap: 8px;
`;

export default InactiveNote;
