import React from 'react';
import { LoomContext } from './LoomContext';

export const useLoomVisualizer = () => {
  const context = React.useContext(LoomContext);

  if (!context)
    throw new Error(
      'LoomRecorder should be used within a LoomRecorderProvider!',
    );

  const visualize = React.useCallback((url: string) => {
    context.visualizeLoom(url);
  }, []);

  const hide = React.useCallback(() => {
    context.hideLoom();
  }, []);

  return {
    visualize,
    hide,
    getInlineLoomFor: context.getInlineLoomFor,
  };
};
