import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { format } from 'd3-format';

import {
  DataForSeriesTooltip,
  TooltipData,
  TrellisTooltipData,
} from 'modules/ui/models/ui';
import { CHARTS_OPTIONS } from 'modules/chart/models/chart';

const getminMaxDataVlaue = ({
  secondaryCavTooltipData,
  seriesTooltipData,
  varianceTooltipData,
  groupVarianceExtremePoints,
}) => {
  if (secondaryCavTooltipData && !R.isEmpty(secondaryCavTooltipData)) {
    const production = R.pathOr(0, ['production'], secondaryCavTooltipData);
    const capacity = R.pathOr(0, ['capacity'], secondaryCavTooltipData);
    const maxValue = R.max(production, capacity);
    return { min: 0, max: maxValue };
  }
  if (seriesTooltipData && !R.isEmpty(seriesTooltipData)) {
    return seriesTooltipData.reduce((acc, data, i) => {
      if (i === 0) {
        acc.min = data.value;
        acc.max = data.value;
        return acc;
      }
      if (acc.min > data.value) {
        acc.min = data.value;
      }
      if (acc.max < data.value) {
        acc.max = data.value;
      }
      return acc;
    }, {});
  }
  if (varianceTooltipData && !R.isEmpty(varianceTooltipData)) {
    return { min: 0, max: varianceTooltipData.variance };
  }
  if (groupVarianceExtremePoints) {
    return groupVarianceExtremePoints;
  }
};

const getTooltipHeight = ({
  secondaryCavTooltipData,
  seriesTooltipData,
  isDisplayingForecast,
  varianceTooltipData,
  groupVarianceExtremePoints,
}) => {
  if (secondaryCavTooltipData && !R.isEmpty(secondaryCavTooltipData)) {
    return isDisplayingForecast ? 50 : 40;
  }
  if (seriesTooltipData && !R.isEmpty(seriesTooltipData)) {
    return seriesTooltipData.length * 11 + 16;
  }
  if (varianceTooltipData) {
    return 26;
  }
  if (groupVarianceExtremePoints) {
    return 26;
  }
  return 50;
};
interface SecondaryInformationTooltipProps {
  containerHeight: number;
  groupVarianceExtremePoints?: { min: number; max: number };
  isDisplayingForecast?: boolean;
  leftOffset: number;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  secondaryCavTooltipData?: TrellisTooltipData | any;
  seriesTooltipData?: DataForSeriesTooltip;
  varianceTooltipData?: { date: Date; variance: number };
  yScale: any;
  today: Date;
}

const SecondaryInformationTooltip = ({
  containerHeight,
  groupVarianceExtremePoints,
  isDisplayingForecast,
  leftOffset,
  tooltipData,
  secondaryCavTooltipData,
  seriesTooltipData,
  trellisTitle,
  varianceTooltipData,
  yScale,
  today,
}: SecondaryInformationTooltipProps) => {
  const isFutureDate = React.useMemo(() => {
    if (!tooltipData?.trellisTooltipData) return false;

    return tooltipData?.trellisTooltipData.day >= today;
  }, [tooltipData, today]);

  const hasDataToDisplay = React.useMemo(() => {
    if (!R.isEmpty(secondaryCavTooltipData ?? {})) return true;

    if (!R.isEmpty(varianceTooltipData ?? {})) return true;

    if (!R.isEmpty(groupVarianceExtremePoints ?? {}) && !isFutureDate)
      return true;

    if (seriesTooltipData?.some(s => s.value !== null)) return true;

    return false;
  }, [
    isFutureDate,
    secondaryCavTooltipData,
    isDisplayingForecast,
    varianceTooltipData,
    groupVarianceExtremePoints,
    seriesTooltipData,
  ]);

  const clientX =
    R.pathOr(false, ['trellisTooltipData', 'clientX'], tooltipData) ||
    R.pathOr(0, ['ribbonTooltipData', 'clientX'], tooltipData) ||
    R.pathOr(0, ['dataSeriesTooltipData', 'clientX'], tooltipData);

  const numbersFormat = React.useMemo(
    () => format(R.pathOr(',d', [trellisTitle, 'format'], CHARTS_OPTIONS)),
    [trellisTitle],
  );

  const production = R.pathOr(0, ['production'], secondaryCavTooltipData);
  const capacity = R.pathOr(null, ['capacity'], secondaryCavTooltipData);

  const minMaxValue = getminMaxDataVlaue({
    secondaryCavTooltipData,
    seriesTooltipData,
    varianceTooltipData: null,
    groupVarianceExtremePoints,
  });

  const componentHeight = getTooltipHeight({
    isDisplayingForecast,
    secondaryCavTooltipData,
    seriesTooltipData,
    varianceTooltipData: null,
    groupVarianceExtremePoints,
  });
  const y0 = yScale(R.pathOr(0, ['max'], minMaxValue));

  const potentiallyCoord = y0 - componentHeight;
  const yPosition =
    y0 < componentHeight
      ? 0
      : potentiallyCoord > containerHeight
      ? containerHeight - componentHeight
      : potentiallyCoord;

  if (tooltipData?.ribbonTooltipData || !hasDataToDisplay) {
    return null;
  }
  return (
    <SecondaryInformationTooltip.Tooltip
      style={{
        transform: `translate(${
          clientX - (leftOffset + 47)
        }px, ${yPosition}px)`,
      }}
    >
      {secondaryCavTooltipData && !R.isEmpty(secondaryCavTooltipData) ? (
        <SecondaryInformationTooltip.ContentWrapper>
          <>
            {!isFutureDate && (
              <>
                <span>Actual:</span>
                <SecondaryInformationTooltip.Value>
                  {numbersFormat(production)}
                </SecondaryInformationTooltip.Value>
              </>
            )}

            {capacity !== null && (
              <>
                <span>Capacity:</span>
                <SecondaryInformationTooltip.Value>
                  {numbersFormat(capacity)}
                </SecondaryInformationTooltip.Value>
              </>
            )}

            {secondaryCavTooltipData?.forecast && isDisplayingForecast ? (
              <>
                <span>RE Fcst:</span>
                <SecondaryInformationTooltip.Value>
                  {numbersFormat(
                    R.pathOr(0, ['forecast'], secondaryCavTooltipData),
                  )}
                </SecondaryInformationTooltip.Value>
              </>
            ) : null}
          </>
        </SecondaryInformationTooltip.ContentWrapper>
      ) : null}
      {/* Variace tooltip */}
      {varianceTooltipData && !R.isEmpty(varianceTooltipData) ? (
        <SecondaryInformationTooltip.ContentWrapper>
          <span>Variance:</span>
          <SecondaryInformationTooltip.Value>
            {numbersFormat(
              Math.abs(R.pathOr(0, ['variance'], varianceTooltipData)),
            )}
          </SecondaryInformationTooltip.Value>
        </SecondaryInformationTooltip.ContentWrapper>
      ) : null}
      {/* Group Variace tooltip */}
      {!varianceTooltipData &&
      groupVarianceExtremePoints &&
      !R.isEmpty(groupVarianceExtremePoints) ? (
        <SecondaryInformationTooltip.ContentWrapper>
          <span>Variance:</span>
          <SecondaryInformationTooltip.Value>
            {numbersFormat(
              Math.abs(
                R.pathOr(0, ['max'], groupVarianceExtremePoints) +
                  R.pathOr(0, ['min'], groupVarianceExtremePoints),
              ),
            )}
          </SecondaryInformationTooltip.Value>
        </SecondaryInformationTooltip.ContentWrapper>
      ) : null}
      {/* Data Series tooltip */}
      {seriesTooltipData
        ? seriesTooltipData.map(
            series =>
              series.value !== null && (
                <React.Fragment key={series.name + 'wrapper'}>
                  <SecondaryInformationTooltip.SeriesName color={series.color}>
                    {`${series.name}:`}
                  </SecondaryInformationTooltip.SeriesName>
                  <SecondaryInformationTooltip.SeriesValue
                    color={series.color}
                    key={series.name + 'value'}
                  >
                    {format(',d')(series.value)}
                  </SecondaryInformationTooltip.SeriesValue>
                </React.Fragment>
              ),
          )
        : null}
    </SecondaryInformationTooltip.Tooltip>
  );
};

SecondaryInformationTooltip.Tooltip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  min-width: 40px;
  background: white;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  justify-content: start;
  z-index: 700;
`;

SecondaryInformationTooltip.ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 0 7px;
`;

SecondaryInformationTooltip.SeriesWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 0 7px;
`;

SecondaryInformationTooltip.Value = styled.span`
  text-align: right;
  min-width: 15px;
`;

SecondaryInformationTooltip.Category = styled.span`
  grid-area: 5 / 1 / 5 / 3;
  text-align: center;
`;

SecondaryInformationTooltip.SeriesValue = styled.span`
  min-width: 15px;
  text-align: right;
  color: ${props => props.color};
`;

SecondaryInformationTooltip.SeriesName = styled.span`
  min-width: 25px;

  color: ${props => props.color};
`;

export default SecondaryInformationTooltip;
