import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getAllFeatureFlags,
  getToogleableFeatureFlags,
  getFlagsStateMap,
  flagValueGetter,
} from '../FeatureFlagsReducer';
import {
  fetchAllFeatureFlags,
  toggleFlagLocally,
} from '../FeatureFlagsActions';
import { FeatureFlag } from '../models';

const useFeatureFlags = () => {
  const dispatch = useDispatch();
  const allFlags = useSelector(getAllFeatureFlags);
  const toggleableFlags: FeatureFlag[] = useSelector(getToogleableFeatureFlags);

  const flagsState: ReturnType<typeof getFlagsStateMap> =
    useSelector(getFlagsStateMap);

  const getFlagValue: ReturnType<typeof flagValueGetter> =
    useSelector(flagValueGetter);

  const refetchAllFlags = React.useCallback(() => {
    dispatch(fetchAllFeatureFlags());
  }, []);

  const toggleFlag = React.useCallback((flagKey: string) => {
    dispatch(toggleFlagLocally(flagKey));
  }, []);

  return {
    allFlags,
    flagsState,
    toggleableFlags,
    refetchAllFlags,
    toggleFlag,
    getFlagValue,
  };
};

export default useFeatureFlags;
