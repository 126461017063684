const captureAppReload = (onReloaded: () => void) => {
  if (window.performance) {
    const timeout = 10000;
    const entries = window.performance.getEntriesByType('navigation');

    // @ts-expect-error
    const reloadEntry = entries.find(e => e.type === 'reload');
    if (!reloadEntry) return;

    setTimeout(() => {
      onReloaded();
    }, timeout);
  }
};

export default captureAppReload;
