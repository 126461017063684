import EnvConfig from 'config/EnvironmentConfig';

export type EnvTargetingSymbol =
  | '*'
  | 'local'
  | '!local'
  | 'development'
  | '!development'
  | 'production'
  | '!production'
  | 'demo'
  | '!demo'
  | 'stage'
  | '!stage';

export type EnvTargeting = EnvTargetingSymbol[];

declare global {
  interface Window {
    __WR_ENV: {
      REACT_APP_API_URL: string;
      REACT_APP_NAME: string;
      REACT_APP_GIT_SHA1: string;
      [key: string]: string;
    };
  }
}

export default class Env {
  public static current = EnvConfig.environment;

  public static is = (env: string) => {
    return Env.current === env;
  };

  public static isDev = () => {
    return Env.is('development');
  };

  public static isProd = () => {
    return Env.is('production');
  };

  public static isLocal = () => {
    return Env.is('local');
  };

  public static isSupported = (targeting: EnvTargeting) => {
    return (
      !targeting.includes(`!${Env.current}` as EnvTargetingSymbol) &&
      (targeting.includes(Env.current) || targeting.includes('*'))
    );
  };

  public static isNotSupported = (targeting: EnvTargeting) => {
    return !Env.isSupported(targeting);
  };

  public static getVariable = (key: string, defaultValue = 'null') => {
    return window.__WR_ENV[key] || process.env[key] || defaultValue;
  };
}
