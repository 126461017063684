import * as R from 'ramda';
import {
  VarianceDrilldownTableItem,
  ASC,
  DESC,
  VAR_SORTING_ORDER,
  COMPARE_OPTION,
} from 'modules/drilldownTable/models/drilldownTable';

import naturalSorting from './naturalSorting';
import { calculateVariancePercentage } from './calculateVariancePercentage';

const sortForecastTable = (
  sortCriteria: string,
  sortDirection: string,
  sortVarDirectionIndex: number,
  sortVarPercentDirectionIndex: number,
  phaseKey: string,
  table: VarianceDrilldownTableItem[],
  compareOpt: string,
): VarianceDrilldownTableItem[] => {
  if (sortCriteria === 'variancePercentage') {
    const variancePhaseKey = `${phaseKey}Variance`;
    const actualPhaseKey = `${phaseKey}Actual`;
    const secondColPhaseKey = `${phaseKey}SecondCol`;
    const thirdColPhaseKey = `${phaseKey}ThirdCol`;

    const capacityPhaseKey =
      compareOpt === COMPARE_OPTION.extVsCap
        ? secondColPhaseKey
        : thirdColPhaseKey;

    const sortParams = VAR_SORTING_ORDER[sortVarPercentDirectionIndex];
    const getSortableValue = value =>
      sortParams.abs ? Math.abs(value) : value;

    if (sortParams.direction === ASC) {
      return table.sort((a, b) => {
        const aActual = a[actualPhaseKey];
        const aSecondCol = a[secondColPhaseKey];
        const aThirdCol = a[thirdColPhaseKey];
        const aVariance =
          aThirdCol === null && compareOpt === COMPARE_OPTION.extVsCap
            ? aActual - aSecondCol
            : a[variancePhaseKey];

        const bActual = b[actualPhaseKey];
        const bSecondCol = b[secondColPhaseKey];
        const bThirdCol = b[thirdColPhaseKey];
        const bVariance =
          bThirdCol === null && compareOpt === COMPARE_OPTION.extVsCap
            ? bActual - bSecondCol
            : b[variancePhaseKey];

        const aPercent = getSortableValue(
          Number(
            calculateVariancePercentage({
              variance: aVariance,
              capacity: a[capacityPhaseKey],
              withoutRound: true,
            }),
          ),
        );
        const bPercent = getSortableValue(
          Number(
            calculateVariancePercentage({
              variance: bVariance,
              capacity: b[capacityPhaseKey],
              withoutRound: true,
            }),
          ),
        );

        if (aPercent > bPercent) return 1;
        if (aPercent < bPercent) return -1;
        return 0;
      });
    }

    return table.sort((a, b) => {
      const aActual = a[actualPhaseKey];
      const aSecondCol = a[secondColPhaseKey];
      const aThirdCol = a[thirdColPhaseKey];
      const aVariance =
        aThirdCol === null && compareOpt === COMPARE_OPTION.extVsCap
          ? aActual - aSecondCol
          : a[variancePhaseKey];

      const bActual = b[actualPhaseKey];
      const bSecondCol = b[secondColPhaseKey];
      const bThirdCol = b[thirdColPhaseKey];
      const bVariance =
        bThirdCol === null && compareOpt === COMPARE_OPTION.extVsCap
          ? bActual - bSecondCol
          : b[variancePhaseKey];

      const aPercent = getSortableValue(
        Number(
          calculateVariancePercentage({
            variance: aVariance,
            capacity: a[capacityPhaseKey],
            withoutRound: true,
          }),
        ),
      );
      const bPercent = getSortableValue(
        Number(
          calculateVariancePercentage({
            variance: bVariance,
            capacity: b[capacityPhaseKey],
            withoutRound: true,
          }),
        ),
      );

      if (aPercent > bPercent) return -1;
      if (aPercent < bPercent) return 1;
      return 0;
    });
  }

  const sortParams = VAR_SORTING_ORDER[sortVarDirectionIndex];

  if (sortCriteria === 'variance' && compareOpt === COMPARE_OPTION.extVsCap) {
    const variancePhaseKey = phaseKey + 'Variance';
    const actualKey = phaseKey + 'Actual';
    const secondColKey = phaseKey + 'SecondCol';
    const thirdColKey = phaseKey + 'ThirdCol';

    const getVariance = listItem => {
      const actual = listItem[actualKey];
      const secondCol = listItem[secondColKey];
      const thirdCol = listItem[thirdColKey];
      const variance =
        thirdCol === null ? actual - secondCol : listItem[variancePhaseKey];

      if (sortParams.abs) return Math.abs(variance);

      return variance;
    };

    if (sortParams.direction === ASC) {
      return table.sort((a, b) => {
        if (getVariance(a) < getVariance(b)) {
          return -1;
        }

        if (getVariance(a) > getVariance(b)) {
          return 1;
        }

        return 0;
      });
    } else if (sortParams.direction === DESC) {
      return table.sort((a, b) => {
        if (getVariance(a) > getVariance(b)) {
          return -1;
        }

        if (getVariance(a) < getVariance(b)) {
          return 1;
        }

        return 0;
      });
    }
  }

  if (sortCriteria === 'variance') {
    const variancePhaseKey = phaseKey + 'Variance';
    const firstColKey = phaseKey + 'FirstColKey';
    const getSortableValue = listItem =>
      sortParams.abs
        ? Math.abs(listItem[variancePhaseKey])
        : listItem[variancePhaseKey];
    if (sortParams.direction === ASC) {
      return table.sort((a, b) => {
        if (a[variancePhaseKey] === null && b[variancePhaseKey] !== null) {
          return 1;
        }
        if (b[variancePhaseKey] === null && a[variancePhaseKey] !== null) {
          return -1;
        }
        if (getSortableValue(a) > getSortableValue(b)) {
          return 1;
        }
        if (getSortableValue(a) < getSortableValue(b)) {
          return -1;
        }

        return 0;
      });
    }
    return table.sort((a, b) => {
      const isAGroupNull = a[firstColKey] === 0 && a[variancePhaseKey] === 0;
      const isBGroupNull = b[firstColKey] === 0 && b[variancePhaseKey] === 0;
      if (
        (a[firstColKey] === null && b[firstColKey] !== null) ||
        (!isBGroupNull && isAGroupNull)
      ) {
        return -1;
      }
      if (
        (b[firstColKey] === null && a[firstColKey] !== null) ||
        (isBGroupNull && !isAGroupNull)
      ) {
        return 1;
      }
      if (getSortableValue(a) > getSortableValue(b)) {
        return -1;
      }
      if (getSortableValue(a) < getSortableValue(b)) {
        return 1;
      }
      return 0;
    });
  }

  const key =
    sortCriteria === 'well'
      ? sortCriteria
      : phaseKey + sortCriteria[0].toUpperCase() + sortCriteria.slice(1);
  //sort by name
  if (sortCriteria !== 'secondCol' && sortCriteria !== 'thirdCol') {
    if (sortDirection === ASC) {
      return table.sort((a, b) =>
        naturalSorting(
          R.pathOr('', [sortCriteria], a).toLowerCase(),
          R.pathOr('', [sortCriteria], b).toLowerCase(),
        ),
      );
    }
    return table.sort(
      (a, b) =>
        0 -
        naturalSorting(
          R.pathOr('', [sortCriteria], a).toLowerCase(),
          R.pathOr('', [sortCriteria], b).toLowerCase(),
        ),
    );
  }

  if (sortCriteria === 'thirdCol' && compareOpt === COMPARE_OPTION.extVsCap) {
    const actualKey = phaseKey + 'Actual';
    const sign = sortDirection === ASC ? 1 : -1;

    return table.sort((a, b) => {
      if (a[key] === null || b[key] === null) {
        if (a[key] === null && b[key] === null)
          return sign * (a[actualKey] - b[actualKey]);

        return a[key] === null ? -sign : sign;
      }

      if (a[key] > b[key]) return sign;
      if (a[key] < b[key]) return -sign;
      return 0;
    });
  }

  if (
    (sortCriteria === 'secondCol' && compareOpt === COMPARE_OPTION.extVsCap) ||
    (sortCriteria === 'thirdCol' && compareOpt === COMPARE_OPTION.actVsExt)
  ) {
    const sign = sortDirection === ASC ? 1 : -1;

    return table.sort((a, b) => {
      if (a[key] === null || b[key] === null) {
        return a[key] === null ? -sign : sign;
      }

      if (a[key] > b[key]) return sign;
      if (a[key] < b[key]) return -sign;
      return 0;
    });
  }

  if (sortCriteria === 'secondCol' && compareOpt === COMPARE_OPTION.actVsExt) {
    if (sortDirection === ASC) {
      return table.sort((a, b) => {
        if (a[key] === null && b[key] !== null) {
          return 1;
        }
        if (b[key] === null && a[key] !== null) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
    }
    return table.sort((a, b) => {
      if (a[key] === null && b[key] !== null) {
        return -1;
      }
      if (b[key] === null && a[key] !== null) {
        return 1;
      }
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
  }

  if (sortDirection === ASC) {
    return table.sort((a, b) => {
      if (a[key] > b[key]) return 1;
      if (a[key] < b[key]) return -1;
      return 0;
    });
  }

  return table.sort((a, b) => {
    if (a[key] > b[key]) return -1;
    if (a[key] < b[key]) return 1;
    return 0;
  });
};

export default sortForecastTable;
