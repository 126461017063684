import * as R from 'ramda';
import { filterActions } from 'redux-ignore';

import { getGraphqlPayload } from 'store/helpers';

import type { Action, RootState } from 'store/models';

import type { AllocIssueStatus } from './models/allocIssueStatus';
import { FETCH_ALLOC_ISSUE_STATUS } from './AllocIssueStatusActions';
import { Selector } from 'react-redux';
import { Reducer } from 'redux';

export const STATE_KEY = 'allocIssueStatus';

export type AllocIssueStatusState = {
  [id: string]: AllocIssueStatus;
};

const FETCH_ALLOC_ISSUE_STATUS_SUCCESS = `${FETCH_ALLOC_ISSUE_STATUS}_SUCCESS`;
const initialState: AllocIssueStatusState = {};

const AllocIssueStatusReducer = (
  state: AllocIssueStatusState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case FETCH_ALLOC_ISSUE_STATUS_SUCCESS: {
      const statuses = getGraphqlPayload(action);
      return R.indexBy<string, AllocIssueStatus>(R.prop('id'), statuses);
    }
    default: {
      return state;
    }
  }
};

export const getAllocIssueStatuses: Selector<
  RootState,
  {
    [id: string]: { id: string; status: string };
  }
> = R.pathOr<
  Record<string, any>,
  string,
  Record<string, any>,
  Record<string, any>
>({}, [STATE_KEY]);

export const getDefaultAllocIssueStatus = (
  _: RootState,
): AllocIssueStatusState => {
  const statuses = R.pathOr<
    Record<string, any>,
    string,
    Record<string, any>,
    Record<string, any>
  >({}, [STATE_KEY], _);

  return (
    R.values(statuses).find(status => status.status === 'Identified') || []
  );
};
export const getResolvedStatus = (
  _: RootState,
): {
  id: string;
  status: string;
} => {
  const statuses = R.pathOr<
    Record<string, any>,
    string,
    Record<string, any>,
    Record<string, any>
  >({}, [STATE_KEY], _);

  return R.values(statuses).find(status => status.status === 'Resolved');
};

export default filterActions(AllocIssueStatusReducer, [
  FETCH_ALLOC_ISSUE_STATUS_SUCCESS,
]) as Reducer<AllocIssueStatusState>;
