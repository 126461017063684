import * as R from 'ramda';
import { isIdNew } from 'helpers';
import { WellCapacityEvent } from '../CapacityChangeEventReducer';

const removeFailedCapacityEvents = (event: WellCapacityEvent) => {
  const filterFailedCapEvents = R.compose(
    R.fromPairs,
    R.filter(([key]) => !isIdNew(key)),
    R.toPairs,
  );

  return filterFailedCapEvents(event ?? ({} as WellCapacityEvent));
};

export default removeFailedCapacityEvents;
