import * as R from 'ramda';

import {
  CustomDrilldownTableItem,
  DrilldownTableParams,
  VarianceDrilldownTableItem,
  WellDrilldownTableItem,
} from '../models/drilldownTable';

type TableLike = { wellId: string };

const tableIsCustomOrEmpty = (
  table: TableLike[],
): table is CustomDrilldownTableItem[] =>
  table.length <= 0 || ('cap' in table[0] && 'act' in table[0]);

type AllTable =
  | VarianceDrilldownTableItem[]
  | WellDrilldownTableItem[]
  | CustomDrilldownTableItem[];

const filterDrilldownTable = (
  table: AllTable,
  params: DrilldownTableParams,
  numberOfRoiDays: number,
  filters,
  wells,
): any => {
  const isNotEmpty = (val, key) => !R.isEmpty(val);
  const filtersWithoutEmpty = R.pickBy(isNotEmpty, filters);
  const filteredWellIdIndexedList = R.values(wells).reduce((acc, well) => {
    const isIncluded = R.keys(filtersWithoutEmpty).every(
      filterName =>
        filters[filterName].includes(well[filterName]) ||
        (R.path([filterName, 0], filters) === '' && well[filterName] === null),
    );
    if (isIncluded) acc[well.id] = well;

    return acc;
  }, {});

  const filteredTable = (table as TableLike[]).filter(tableItem =>
    Boolean(filteredWellIdIndexedList[tableItem.wellId]),
  ) as AllTable;

  if (tableIsCustomOrEmpty(filteredTable)) return filteredTable;

  const grossNetNormalized = filteredTable.map(tableItem => {
    if (params.grossNet === 'Gross') return tableItem;
    const nriNumber = wells[tableItem.wellId]?.NRI
      ? parseFloat(wells[tableItem.wellId].NRI)
      : 0;
    const nriNormalized = nriNumber <= 1 && nriNumber >= 0 ? nriNumber : 1;

    return {
      ...tableItem,
      oilVariance: tableItem.oilVariance * nriNormalized,
      waterVariance: tableItem.waterVariance * nriNormalized,
      gasVariance: tableItem.gasVariance * nriNormalized,
      boeVariance: tableItem.boeVariance * nriNormalized,
      oilCapacity:
        tableItem.oilCapacity === null
          ? null
          : tableItem.oilCapacity * nriNormalized,
      waterCapacity:
        tableItem.waterCapacity === null
          ? null
          : tableItem.waterCapacity * nriNormalized,
      gasCapacity:
        tableItem.gasCapacity === null
          ? null
          : tableItem.gasCapacity * nriNormalized,
      boeCapacity:
        tableItem.boeCapacity === null
          ? null
          : tableItem.boeCapacity * nriNormalized,
      oilActual: tableItem.oilActual * nriNormalized,
      waterActual: tableItem.waterActual * nriNormalized,
      gasActual: tableItem.gasActual * nriNormalized,
      boeActual: tableItem.boeActual * nriNormalized,
    };
  });
  const rateVolumeNormalized = grossNetNormalized.map(tableItem => {
    if (params.rateVolume === 'Rate') return tableItem;

    return {
      ...tableItem,
      oilVariance: tableItem.oilVariance * numberOfRoiDays,
      oilCapacity:
        tableItem.oilCapacity === null
          ? null
          : tableItem.oilCapacity * numberOfRoiDays,
      oilActual: tableItem.oilActual * numberOfRoiDays,
      gasVariance: tableItem.gasVariance * numberOfRoiDays,
      gasCapacity:
        tableItem.gasCapacity === null
          ? null
          : tableItem.gasCapacity * numberOfRoiDays,
      gasActual: tableItem.gasActual * numberOfRoiDays,
      waterVariance: tableItem.waterVariance * numberOfRoiDays,
      waterCapacity:
        tableItem.waterCapacity === null
          ? null
          : tableItem.waterCapacity * numberOfRoiDays,
      waterActual: tableItem.waterActual * numberOfRoiDays,
      boeVariance: tableItem.boeVariance * numberOfRoiDays,
      boeCapacity:
        tableItem.boeCapacity === null
          ? null
          : tableItem.boeCapacity * numberOfRoiDays,
      boeActual: tableItem.boeActual * numberOfRoiDays,
    };
  });
  return rateVolumeNormalized;
};

export default filterDrilldownTable;
