import * as R from 'ramda';
import {
  VarianceDrilldownTableItem,
  ASC,
  VAR_SORTING_ORDER,
} from 'modules/drilldownTable/models/drilldownTable';

import naturalSorting from './naturalSorting';
import { calculateVariancePercentage } from './calculateVariancePercentage';

const sortTable = (
  sortCriteria: string,
  sortDirection: string,
  sortVarDirectionIndex: number,
  sortVarPercentDirectionIndex: number,
  phaseKey: string,
  table: VarianceDrilldownTableItem[],
): VarianceDrilldownTableItem[] => {
  if (sortCriteria === 'variancePercentage') {
    const variancePhaseKey = phaseKey + 'Variance';
    const capacityPhaseKey = phaseKey + 'Capacity';

    const sortParams = VAR_SORTING_ORDER[sortVarPercentDirectionIndex];
    const getSortableValue = value =>
      sortParams.abs ? Math.abs(value) : value;

    if (sortParams.direction === ASC) {
      return table.sort((a, b) => {
        const aPercent = getSortableValue(
          Number(
            calculateVariancePercentage({
              variance: a[variancePhaseKey],
              capacity: a[capacityPhaseKey],
              withoutRound: true,
            }),
          ),
        );
        const bPercent = getSortableValue(
          Number(
            calculateVariancePercentage({
              variance: b[variancePhaseKey],
              capacity: b[capacityPhaseKey],
              withoutRound: true,
            }),
          ),
        );

        if (aPercent > bPercent) return 1;
        if (aPercent < bPercent) return -1;
        return 0;
      });
    }

    return table.sort((a, b) => {
      const aPercent = getSortableValue(
        Number(
          calculateVariancePercentage({
            variance: a[variancePhaseKey],
            capacity: a[capacityPhaseKey],
            withoutRound: true,
          }),
        ),
      );
      const bPercent = getSortableValue(
        Number(
          calculateVariancePercentage({
            variance: b[variancePhaseKey],
            capacity: b[capacityPhaseKey],
            withoutRound: true,
          }),
        ),
      );

      if (aPercent > bPercent) return -1;
      if (aPercent < bPercent) return 1;
      return 0;
    });
  }

  if (sortCriteria === 'variance') {
    const variancePhaseKey = phaseKey + 'Variance';
    const sortParams = VAR_SORTING_ORDER[sortVarDirectionIndex];
    const getSortableValue = listItem =>
      sortParams.abs
        ? Math.abs(listItem[variancePhaseKey])
        : listItem[variancePhaseKey];
    if (sortParams.direction === ASC) {
      return table.sort((a, b) => {
        if (getSortableValue(a) > getSortableValue(b)) {
          return 1;
        }
        if (getSortableValue(a) < getSortableValue(b)) {
          return -1;
        }
        return 0;
      });
    }
    return table.sort((a, b) => {
      if (getSortableValue(a) > getSortableValue(b)) return -1;
      if (getSortableValue(a) < getSortableValue(b)) return 1;
      return 0;
    });
  }

  const key =
    sortCriteria === 'capacity' || sortCriteria === 'actual'
      ? phaseKey + sortCriteria[0].toUpperCase() + sortCriteria.slice(1)
      : sortCriteria;

  if (sortCriteria === 'capacity') {
    const actualKey = phaseKey + 'Actual';
    const sign = sortDirection === ASC ? 1 : -1;

    return table.sort((a, b) => {
      if (a[key] === null || b[key] === null) {
        if (a[key] === null && b[key] === null)
          return sign * (a[actualKey] - b[actualKey]);

        return a[key] === null ? -sign : sign;
      }

      if (a[key] > b[key]) return sign;
      if (a[key] < b[key]) return -sign;
      return 0;
    });
  }

  if (key === 'well') {
    if (sortDirection === ASC) {
      return table.sort((a, b) =>
        naturalSorting(
          R.pathOr('', [key], a).toLowerCase(),
          R.pathOr('', [key], b).toLowerCase(),
        ),
      );
    }
    return table.sort(
      (a, b) =>
        0 -
        naturalSorting(
          R.pathOr('', [key], a).toLowerCase(),
          R.pathOr('', [key], b).toLowerCase(),
        ),
    );
  }
  if (sortDirection === ASC) {
    return table.sort((a, b) => {
      if (a[key] > b[key]) return 1;
      if (a[key] < b[key]) return -1;
      return 0;
    });
  }

  return table.sort((a, b) => {
    if (a[key] > b[key]) return -1;
    if (a[key] < b[key]) return 1;
    return 0;
  });
};

export default sortTable;
