import type { Action } from 'store/models';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';

export const namespace = 'ROUTER';
export const REPLACE_PARAMETERS = `${namespace}/REPLACE_PARAMETERS`;
export const SET_PARAMETERS = `${namespace}/SET_PARAMETERS`;
export const REDIRECT_TO_CHART_FROM_BACKLOG = `${namespace}/REDIRECT_TO_CHART_FROM_BACKLOG`;
export const REDIRECT_TO_CHART_FROM_LOGIN = `${namespace}/REDIRECT_TO_CHART_FROM_LOGIN`;
export const REDIRECT_TO_CHART_FROM_FILTERS = `${namespace}/REDIRECT_TO_CHART_FROM_FILTERS`;
export const REDIRECT_TO_CHART_FROM_SETTINGS = `${namespace}/REDIRECT_TO_CHART_FROM_SETTINGS`;
export const UPDATE_ROUTE_AFTER_CREATING_EVENT = `${namespace}/UPDATE_ROUTE_AFTER_CREATING_EVENT`;

type redirectToChartFromBacklogAC = (payload: AllocIssue) => Action;

type routerInteractionAC = (payload: {
  pathname?: string;
  search?: { [key: string]: any };
  meta?: { [key: string]: any };
}) => Action;

export const replaceParameters: routerInteractionAC = (payload: {
  pathname?: string;
  search?: { [key: string]: any };
  meta?: { [key: string]: any };
}) => ({
  type: REPLACE_PARAMETERS,
  payload,
});

export const setParameters: routerInteractionAC = (payload: {
  pathname?: string;
  search?: { [key: string]: any };
  meta?: { [key: string]: any };
}) => ({
  type: SET_PARAMETERS,
  payload,
});

export const redirectToChartFromBacklog: redirectToChartFromBacklogAC =
  payload => ({
    type: REDIRECT_TO_CHART_FROM_BACKLOG,
    payload,
  });

export const redirectToChartFromFilters = () => ({
  type: REDIRECT_TO_CHART_FROM_FILTERS,
});

export const redirectToChartFromLogin = (payload: any) => ({
  type: REDIRECT_TO_CHART_FROM_LOGIN,
  payload,
});

export const redirectToChartFromSettings = (payload: any) => ({
  type: REDIRECT_TO_CHART_FROM_SETTINGS,
  payload,
});

type updateRouteAfterCreatingEventAC = (payload: {
  oldId: string;
  newId: string;
}) => Action;

// TODO: check out why this is only used for capacity events
export const updateRouteAfterCreatingEvent: updateRouteAfterCreatingEventAC =
  payload => ({
    type: UPDATE_ROUTE_AFTER_CREATING_EVENT,
    payload,
  });
