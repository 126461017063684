import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import { FaInfo } from 'react-icons/fa';
import styled from 'styled-components';
import { LEFT_PANEL_WIDTH } from 'modules/dashboard/models/dashboard';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import useNotesParams from 'modules/wellChart/hooks/useNotesParams';

interface EditVarianceButtonProps {
  subCategory: string;
  color: string;
  onClick: () => void;
  x: number;
  xScale: any;
}

const EditVarianceButton = ({
  subCategory,
  color,
  onClick,
  x,
  xScale,
}: EditVarianceButtonProps) => {
  // Temporary disabled
  //const [showTooltip, setShowTooltip] = React.useState(false);
  const { height: notesSectionHeight, isNotesSectionShown } =
    useNotesParams(xScale);
  return (
    <Wrapper
      x={x}
      notesSectionHeight={isNotesSectionShown ? notesSectionHeight : 0}
      id="edit-button"
      // Temporary disabled
      // onMouseEnter={() => setShowTooltip(true)}
      // onMouseLeave={() => setShowTooltip(false)}
    >
      <StyledButton
        style={{
          backgroundColor: color,
          border: '1px solid rgba(0, 0, 0, 0.15)',
          color: 'white',
          padding: '0',
        }}
        onClick={() => onClick()}
        className="variance-interactive interactive"
      >
        <FaInfo size="0.55em" />
      </StyledButton>
      {/* Temporary disabled
      {showTooltip && (
        <EditVarianceButton.TooltipContainer>
          <EditVarianceButton.Tooltip>
            <span>Variance Event</span>
            <span>{subCategory}</span>
          </EditVarianceButton.Tooltip>
        </EditVarianceButton.TooltipContainer>
      )} */}
    </Wrapper>
  );
};

const StyledButton = styled(IconButton)`
  height: 20px;
  width: 20px;
`;

EditVarianceButton.TooltipContainer = styled.div`
  position: relative;
  top: 15px;
  left: -65px;
  min-width: 150px;
  display: flex;
  justify-content: center;
`;

EditVarianceButton.Tooltip = styled.div`
  padding: 5px;
  height: auto;
  width: auto;
  min-width: 90px;
  background: white;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  display: grid;
  justify-items: center;
  z-index: 100;
`;

const Wrapper = styled.div`
  position: absolute;
  top: ${props => props.notesSectionHeight - 9}px;
  left: calc(
    ${(props: Record<string, any>) =>
        props.x - LEFT_PANEL_WIDTH - Y_AXIS_WIDTH}px - 20px
  );
  z-index: 100;
`;

export default React.memo<EditVarianceButtonProps>(EditVarianceButton);
