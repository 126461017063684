import { convertToChartDate } from 'helpers';
import type { RibbonEvent } from '../models';

const normalizeExtraInputsData = (extraInputsData: any) => {
  if (!extraInputsData) return {};
  try {
    return JSON.parse(extraInputsData);
  } catch (err) {
    return {};
  }
};

export const normalizeRibbonEventData = (event: RibbonEvent, today: Date) => {
  return {
    ...event,
    dayStart: convertToChartDate(new Date(event.dayStart)),
    dayEnd: convertToChartDate(event.dayEnd ? new Date(event.dayEnd) : today),
    extraInputsData: normalizeExtraInputsData(event.extraInputsData),
    id: event.id.toString(),
    ribbonId: event.ribbonId.toString(),
    ribbonOptionId: event.ribbonOptionId.toString(),
  };
};

const normalizeRibbonEventsData = (events: RibbonEvent[], today: Date) => {
  const normalizedData = events.reduce((acc, event) => {
    acc[event.id] = normalizeRibbonEventData(event, today);
    return acc;
  }, {});

  return normalizedData;
};

export default normalizeRibbonEventsData;
