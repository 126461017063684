import { EventNote } from 'modules/eventNotes/models';
import { Note } from '../../notes/models/notes';
import { VarianceEvent } from '../../varianceEvent/models/varianceEvent';
import { RibbonEvent } from '../../ribbon/models/index';
import { CapacityChangeEvent } from '../../capacityChangeEvent/models/capacityChangeEvent';

export const NOTES_HEIGHT = 17;
export const NOTES_DEFAULT_HEIGHT = 74;
export const NOTE_HEIGHT_WITHOUT_TEXT = 39;
export const NOTE_ONE_STRING_HEIGHT = 16;
export const MAX_TOOLTIP_WIDTH = 297;
export const MAX_TOOLTIP_HEIGHT = 576;

export type TooltipTypes = 'capacity' | 'variance' | 'external' | 'ribbon';

export interface NormalizedEvent {
  notes: Record<string, EventNote>;
  position: { x1: number; x2: number; y1: number; y2: number };
  color: string;
  type: TooltipTypes;
  id: string;
  optionId: string;
  index?: number;
  title?: string;
  subTitle?: string;
}

export interface TooltipOptionsType {
  x: number;
  y: number;
  type: TooltipTypes;
  externalNote?: Note | null;
  notes?: Record<string, EventNote> | null;
  title?: string;
  subTitle?: string;
}

export interface NormalizeEventsFunctionProps {
  normalizedEvents: NormalizedEvent[];
  eventsSorted?: VarianceEvent[] | RibbonEvent[] | CapacityChangeEvent[];
  events:
    | VarianceEvent[]
    | RibbonEvent[]
    | CapacityChangeEvent[]
    | Record<string, never>[];
  xScale: any;
  eventNotes: {
    [key: string]: Record<string, EventNote>;
  };
  type: 'variance' | 'capacity' | 'ribbon';
  titles?: {
    [categoryId: string]: {
      title: string;
      subTitle: string;
    };
  };
  optionIdPath:
    | 'varianceOptionId'
    | 'capacityChangeOptionId'
    | 'ribbonOptionId';
  dayStartPath: 'dayInit' | 'dayStart';
  dayEndPath: 'dayInit' | 'dayEnd';
  eventColors?: {
    [categoryId: string]: string;
  };
}
