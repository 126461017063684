import * as R from 'ramda';

import { Series } from '../models';
import { convertToChartDate } from '../../../helpers';

const normalizeSeries = (series: Series[]) => {
  const formatedSeries = series.map(data => ({
    ...R.omit(['wellId'], data),
    day: convertToChartDate(new Date(data.day)),
  }));
  return formatedSeries.sort((a, b) => a.day.getTime() - b.day.getTime());
};

export default normalizeSeries;
