import gql from 'graphql-tag';

import { GraphqlQueryAction, Action } from 'store/models';
import { DrilldownTableLayoutConfigOptions } from './DrilldownTableReducer';

import {
  CustomDrilldownTableItem,
  VarianceDrilldownTableItem,
  WellDrilldownTableItem,
} from './models/drilldownTable';

export const namespace = 'DRILLDOWN_TABLE';

export const FETCH_VARIANCE_DRILLDOWN_TABLE = `${namespace}/FETCH_VARIANCE_DRILLDOWN_TABLE`;
export const FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE = `${namespace}/FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE`;
export const FETCH_WELL_DRILLDOWN_TABLE = `${namespace}/FETCH_WELL_DRILLDOWN_TABLE`;
export const FETCH_FILTERED_WELL_DRILLDOWN_TABLE = `${namespace}/FETCH_FILTERED_WELL_DRILLDOWN_TABLE`;
export const FETCH_FORECAST_DRILLDOWN_TABLE = `${namespace}/FETCH_FORECAST_DRILLDOWN_TABLE`;
export const FETCH_CUSTOM_DRILLDOWN_TABLE = `${namespace}/FETCH_CUSTOM_DRILLDOWN_TABLE`;

export const FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE = `${namespace}/FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE`;

export const SET_NUMBER_OF_ROI_DAYS = `${namespace}/SET_NUMBER_OF_ROI_DAYS`;

export const INIT_DRILLDOWN_TABLE_FETCH = `${namespace}/INIT_DRILLDOWN_TABLE_FETCH`;

export const INIT_CLEAR_DRILLDOWN_TABLE = `${namespace}/INIT_CLEAR_DRILLDOWN_TABLE`;
export const CLEAR_DRILLDOWN_TABLE = `${namespace}/CLEAR_DRILLDOWN_TABLE`;
export const POPULATE_VARIANCE_DRILLDOWN_TABLE = `${namespace}/POPULATE_VARIANCE_DRILLDOWN_TABLE`;
export const POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE = `${namespace}/POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE`;
export const POPULATE_WELL_DRILLDOWN_TABLE = `${namespace}/POPULATE_WELL_DRILLDOWN_TABLE`;
export const POPULATE_FILTERED_WELL_DRILLDOWN_TABLE = `${namespace}/POPULATE_FILTERED_WELL_DRILLDOWN_TABLE`;
export const POPULATE_FORECAST_DRILLDOWN_TABLE = `${namespace}/POPULATE_FORECAST_DRILLDOWN_TABLE`;

export const SET_MAX_DRILLDOWN_TABLE_DATE = `${namespace}/SET_MAX_DRILLDOWN_TABLE_DATE`;
export const SET_MIN_DRILLDOWN_TABLE_DATE = `${namespace}/SET_MIN_DRILLDOWN_TABLE_DATE`;
export const SET_DRILLDOWN_TABLE_PHASE = `${namespace}/SET_DRILLDOWN_TABLE_PHASE`;
export const SET_DRILLDOWN_TABLE_PARAMS = `${namespace}/SET_DRILLDOWN_TABLE_PARAMS`;
export const SET_DRILLDOWN_TABLE_OPTION_COLUMNS = `${namespace}/SET_DRILLDOWN_TABLE_OPTION_COLUMNS`;
export const SET_IS_CUSTOM_LAYOUT = `${namespace}/SET_IS_CUSTOM_LAYOUT`;
export const SET_CUSTOM_LAYOUT_PARAMS = `${namespace}/SET_CUSTOM_LAYOUT_PARAMS`;
export const SET_CUSTOM_WELL_TABLE = `${namespace}/SET_CUSTOM_WELL_TABLE`;

export const TOGGLE_VARIANCE_PERCENTAGE = `${namespace}/TOGGLE_VARIANCE_PERCENTAGE`;

export const SET_SORT_DIRECTION = `${namespace}/SET_SORT_DIRECTION`;
export const SET_SORT_CRITERIA = `${namespace}/SET_SORT_CRITERIA`;
export const SET_SORT_VAR_INDEX = `${namespace}/SET_SORT_VAR_INDEX`;
export const SET_SORT_VAR_PERCENT_INDEX = `${namespace}/SET_SORT_VAR_PERCENT_INDEX`;
export const CONFIGURE_SORTING = `${namespace}/CONFIGURE_SORTING`;
export const RESET_SORTING = `${namespace}/RESET_SORTING`;

export const ADD_CURRENT_VARIANCE_OPTION = `${namespace}/ADD_CURRENT_VARIANCE_OPTION`;
export const ADD_SEVERAL_VARIANCE_OPTIONS = `${namespace}/ADD_SEVERAL_VARIANCE_OPTIONS`;
export const SET_CURRENT_VARIANCE_OPTION = `${namespace}/SET_CURRENT_VARIANCE_OPTION`;
export const RESET_CURRENT_VARIANCE_OPTION = `${namespace}/RESET_CURRENT_VARIANCE_OPTION`;
export const REMOVE_ONE_VARIANCE_OPTION = `${namespace}/REMOVE_ONE_VARIANCE_OPTION`;
export const SET_COMPARE_OPTION = `${namespace}/SET_COMPARE_OPTION`;

export const ADD_MARKED_ROW = `${namespace}/ADD_MARKED_ROW`;
export const ADD_SEVERAL_MARKED_ROWS = `${namespace}/ADD_SEVERAL_MARKED_ROWS`;
export const REMOVE_MARKED_ROW = `${namespace}/REMOVE_MARKED_ROW`;
export const RESET_MARKED_ROWS = `${namespace}/RESET_MARKED_ROWS`;
export const SET_MARKED_ROWS = `${namespace}/SET_MARKED_ROWS`;
export const MARK_ALL_ROWS = `${namespace}/MARK_ALL_ROWS`;

export const NOTIFY_DRILLDOWN_TABLE_INCONSISTENCY = `${namespace}/NOTIFY_DRILLDOWN_TABLE_INCONSISTENCY`;

export const SET_LAYOUT_CONFIGURATION = `${namespace}/SET_LAYOUT_CONFIGURATION`;

const NOTIFY_DRILLDOWN_TABLE_INCONSISTENCY_MUTATION = gql`
  mutation ($payload: NotifyDrilldownTableInconsistencyInput!) {
    notifyDrilldownTableInconsistency(data: $payload)
  }
`;

type notifyDrilldownTableInconsistencyAC = (payload: {
  origin: string;
  fullUrl: string;
  filters: Record<string, string[]>;
  urlParams: Record<string, string>;
}) => Action;

export const notifyDrilldownTableInconsistency: notifyDrilldownTableInconsistencyAC =
  (payload: {
    origin: string;
    fullUrl: string;
    filters: Record<string, string[]>;
    urlParams: Record<string, string>;
  }) => ({
    type: NOTIFY_DRILLDOWN_TABLE_INCONSISTENCY,
    payload: {
      key: 'notifyDrilldownTableInconsistency',
      graphql: {
        mutation: NOTIFY_DRILLDOWN_TABLE_INCONSISTENCY_MUTATION,
        variables: {
          payload,
        },
      },
    },
  });

const VARIANCE_DRILLDOWN_QUERY = gql`
  query ($payload: VarianceDrilldownTableInput!) {
    listVarianceDrilldownTable(data: $payload) {
      varianceTable
    }
  }
`;

type fetchDrilldownTableAC = (payload: {
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchVarianceDrilldownTable: fetchDrilldownTableAC = payload => ({
  type: FETCH_VARIANCE_DRILLDOWN_TABLE,
  payload: {
    key: 'listVarianceDrilldownTable',
    graphql: {
      query: VARIANCE_DRILLDOWN_QUERY,
      variables: { payload },
    },
  },
});

const DRILLDOWN_QUERY = gql`
  query ($payload: WellDrilldownTableInput!) {
    listWellDrilldownTable(data: $payload) {
      wellTable
    }
  }
`;

export const fetchWellDrilldownTable: fetchDrilldownTableAC = payload => ({
  type: FETCH_WELL_DRILLDOWN_TABLE,
  payload: {
    key: 'listWellDrilldownTable',
    graphql: {
      query: DRILLDOWN_QUERY,
      variables: { payload },
    },
  },
});

type fetchFilteredDrilldownTableAC = (payload: {
  minDate: Date;
  maxDate: Date;
  filters: { columnIndex: number; values: string[] }[];
}) => GraphqlQueryAction;

const FILTERED_VARIANCE_DRILLDOWN_QUERY = gql`
  query ($payload: FilteredDrilldownTableInput!) {
    listFilteredVarianceDrilldownTable(data: $payload) {
      varianceTable
    }
  }
`;

export const fetchFilteredVarianceDrilldownTable: fetchFilteredDrilldownTableAC =
  payload => ({
    type: FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE,
    payload: {
      key: 'listFilteredVarianceDrilldownTable',
      graphql: {
        query: FILTERED_VARIANCE_DRILLDOWN_QUERY,
        variables: { payload },
      },
    },
  });

const FILTERED_DRILLDOWN_QUERY = gql`
  query ($payload: FilteredDrilldownTableInput!) {
    listFilteredWellDrilldownTable(data: $payload) {
      wellTable
    }
  }
`;

export const fetchFilteredDrilldownTable: fetchFilteredDrilldownTableAC =
  payload => ({
    type: FETCH_FILTERED_WELL_DRILLDOWN_TABLE,
    payload: {
      key: 'listFilteredWellDrilldownTable',
      graphql: {
        query: FILTERED_DRILLDOWN_QUERY,
        variables: { payload },
      },
    },
  });

const FILTERED_FORECAST_DRILLDOWN_QUERY = gql`
  query ($payload: FilteredDrilldownTableInput!) {
    listFilteredForecastDrilldownTable(data: $payload) {
      forecastTable
    }
  }
`;

export const fetchFilteredForecastDrilldownTable: fetchFilteredDrilldownTableAC =
  payload => ({
    type: FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE,
    payload: {
      key: 'listFilteredForecastDrilldownTable',
      graphql: {
        query: FILTERED_FORECAST_DRILLDOWN_QUERY,
        variables: { payload },
      },
    },
  });

const FORECAST_DRILLDOWN_QUERY = gql`
  query ($payload: VarianceDrilldownTableInput!) {
    listForecastDrilldownTable(data: $payload) {
      forecastTable
    }
  }
`;

type fetchForecastDrilldownTableAC = (payload: {
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchForecastDrilldownTable: fetchForecastDrilldownTableAC =
  payload => ({
    type: FETCH_FORECAST_DRILLDOWN_TABLE,
    payload: {
      key: 'listForecastDrilldownTable',
      graphql: {
        query: FORECAST_DRILLDOWN_QUERY,
        variables: { payload },
      },
    },
  });

const CUSTOM_DRILLDOWN_QUERY = gql`
  query ($payload: WellDrilldownCustomTableInput!) {
    listWellDrilldownCustomTable(data: $payload) {
      wellCustomTable
    }
  }
`;

type fetchCustomDrilldownTableAC = (payload: {
  seriesId: string;
  minDate: Date;
  maxDate: Date;
  aggregateFn: string;
}) => GraphqlQueryAction;

export const fetchCustomDrilldownTable: fetchCustomDrilldownTableAC =
  payload => ({
    type: FETCH_CUSTOM_DRILLDOWN_TABLE,
    payload: {
      key: 'listWellDrilldownCustomTable',
      graphql: {
        query: CUSTOM_DRILLDOWN_QUERY,
        variables: { payload },
      },
    },
  });

type initDrilldownTableFetchAC = (payload: {
  minDate: Date;
  maxDate: Date;
}) => Action;

export const initDrilldownTableFetch: initDrilldownTableFetchAC = payload => ({
  type: INIT_DRILLDOWN_TABLE_FETCH,
  payload,
});

type populateWellDrilldownTableAC = (
  drilldownTable: WellDrilldownTableItem[],
) => Action;

export const populateWellDrilldownTable: populateWellDrilldownTableAC =
  payload => ({
    type: POPULATE_WELL_DRILLDOWN_TABLE,
    payload,
  });
export const populateFilteredWellDrilldownTable: populateWellDrilldownTableAC =
  payload => ({
    type: POPULATE_FILTERED_WELL_DRILLDOWN_TABLE,
    payload,
  });

type populateVarianceDrilldownTableAC = (
  drilldownTable: VarianceDrilldownTableItem[],
) => Action;

export const populateVarianceDrilldownTable: populateVarianceDrilldownTableAC =
  payload => ({
    type: POPULATE_VARIANCE_DRILLDOWN_TABLE,
    payload,
  });

export const populateFilteredVarianceDrilldownTable: populateVarianceDrilldownTableAC =
  payload => ({
    type: POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE,
    payload,
  });

export const populateForecastDrilldownTable: populateVarianceDrilldownTableAC =
  payload => ({
    type: POPULATE_FORECAST_DRILLDOWN_TABLE,
    payload,
  });

export const initClearDrilldownTable = () => ({
  type: INIT_CLEAR_DRILLDOWN_TABLE,
});

export const clearDrilldownTable = () => ({
  type: CLEAR_DRILLDOWN_TABLE,
});

type setMaxDrilldownTableDateAC = (date: Date) => Action;

export const setMaxDrilldownTableDate: setMaxDrilldownTableDateAC = date => ({
  type: SET_MAX_DRILLDOWN_TABLE_DATE,
  payload: date,
});

type setMinDrilldownTableDateAC = (date: Date) => Action;

export const setMinDrilldownTableDate: setMinDrilldownTableDateAC = date => ({
  type: SET_MIN_DRILLDOWN_TABLE_DATE,
  payload: date,
});

type setDrilldownTablePhaseAC = (phase: string) => Action;

export const setDrilldownTablePhase: setDrilldownTablePhaseAC = phase => ({
  type: SET_DRILLDOWN_TABLE_PHASE,
  payload: phase,
});

type setDrilldownTableParamsAC = (payload: {
  phase?: string;
  minDate?: Date;
  maxDate?: Date;
  grossNet?: string;
  rateVolume?: string;
  compareOption?: string;
}) => Action;

export const setDrilldownTableParams: setDrilldownTableParamsAC = payload => ({
  type: SET_DRILLDOWN_TABLE_PARAMS,
  payload,
});

type setDrilldownTableOptionColumnsAC = (payload: string) => Action;

export const setDrilldownTableOptionColumns: setDrilldownTableOptionColumnsAC =
  payload => ({
    type: SET_DRILLDOWN_TABLE_OPTION_COLUMNS,
    payload,
  });

type setIsCustomLayoutAC = (payload: boolean) => Action;

export const setIsCustomLayout: setIsCustomLayoutAC = payload => ({
  type: SET_IS_CUSTOM_LAYOUT,
  payload,
});

type setIsCustomLayoutParamsAC = (payload: {
  seriesId: string;
  aggregateFn: string;
}) => Action;

export const setIsCustomLayoutParams: setIsCustomLayoutParamsAC = payload => ({
  type: SET_CUSTOM_LAYOUT_PARAMS,
  payload,
});

type setIsCustomWellTableAC = (payload: CustomDrilldownTableItem[]) => Action;

export const setIsCustomWellTable: setIsCustomWellTableAC = payload => ({
  type: SET_CUSTOM_WELL_TABLE,
  payload,
});

type setSortDirectionAC = (payload: string) => Action;

export const setSortDirection: setSortDirectionAC = payload => ({
  type: SET_SORT_DIRECTION,
  payload,
});

type setSortCriteriaAC = (payload: string) => Action;

export const setSortCriteria: setSortCriteriaAC = payload => ({
  type: SET_SORT_CRITERIA,
  payload,
});

type setSortVarDirectionIndexAC = (payload: number) => Action;

export const setSortVarDirectionIndex: setSortVarDirectionIndexAC =
  payload => ({
    type: SET_SORT_VAR_INDEX,
    payload,
  });

export const setSortVarPercentDirectionIndex: setSortVarDirectionIndexAC =
  payload => ({
    type: SET_SORT_VAR_PERCENT_INDEX,
    payload,
  });

type configureSortingAC = (payload: {
  sortDirection: string;
  sortBy: string;
}) => Action;

export const configureSorting: configureSortingAC = payload => ({
  type: CONFIGURE_SORTING,
  payload,
});

export const resetSorting = () => ({
  type: RESET_SORTING,
});

export const toggleVariancePercentage = () => ({
  type: TOGGLE_VARIANCE_PERCENTAGE,
});

type setCurrentVarianceOptionAC = (optionId: string) => Action;

export const setCurrentVarianceOption: setCurrentVarianceOptionAC =
  optionId => ({
    type: SET_CURRENT_VARIANCE_OPTION,
    payload: { optionId },
  });

type addCurrentVarianceOptionAC = (optionId: string) => Action;

export const addCurrentVarianceOption: addCurrentVarianceOptionAC =
  optionId => ({
    type: ADD_CURRENT_VARIANCE_OPTION,
    payload: { optionId },
  });

type addSeveralVarianceOptionsAC = (optionIds: string[]) => Action;

export const addSeveralVarianceOptions: addSeveralVarianceOptionsAC =
  optionIds => ({
    type: ADD_SEVERAL_VARIANCE_OPTIONS,
    payload: { optionIds },
  });

type removeOneVarianceOptionAC = (optionId: string) => Action;

export const removeOneVarianceOption: removeOneVarianceOptionAC = optionId => ({
  type: REMOVE_ONE_VARIANCE_OPTION,
  payload: { optionId },
});

export const resetCurrentVarianceOption = () => ({
  type: RESET_CURRENT_VARIANCE_OPTION,
});

type setCompareOptionAC = (optionId: string) => Action;

export const setCompareOption: setCompareOptionAC = optionId => ({
  type: SET_COMPARE_OPTION,
  payload: { optionId },
});

type setNumberOfRoiDaysAC = (days: number) => Action;

export const setNumberOfRoiDays: setNumberOfRoiDaysAC = days => ({
  type: SET_NUMBER_OF_ROI_DAYS,
  payload: { days },
});

type addMarkedRowAC = (item: string) => Action;

export const addMarkedRow: addMarkedRowAC = payload => ({
  type: ADD_MARKED_ROW,
  payload,
});

type markAllRowsAC = () => Action;

export const markAllRows: markAllRowsAC = () => ({
  type: MARK_ALL_ROWS,
});

type addSeveralMarkedRowsAC = (items: string[]) => Action;

export const addSeveralMarkedRows: addSeveralMarkedRowsAC = payload => ({
  type: ADD_SEVERAL_MARKED_ROWS,
  payload,
});

type removeMarkedRowAC = (item: string) => Action;

export const removeMarkedRow: removeMarkedRowAC = payload => ({
  type: REMOVE_MARKED_ROW,
  payload,
});

type resetMarkedRowsAC = () => Action;

export const resetMarkedRows: resetMarkedRowsAC = () => ({
  type: RESET_MARKED_ROWS,
});

type setMarkedRowsAC = (payload: string[]) => Action;

export const setMarkedRows: setMarkedRowsAC = payload => ({
  type: SET_MARKED_ROWS,
  payload,
});

export const setLayoutConfiguration = (
  payload: DrilldownTableLayoutConfigOptions,
) => ({
  type: SET_LAYOUT_CONFIGURATION,
  payload,
});
