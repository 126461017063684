import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { Reducer } from 'redux';
import { createSelector } from 'reselect';
import type { Action, Selector } from 'store/models';

import { getGraphqlPayload } from 'store/helpers';

import {
  NormalizedSeries,
  NormalizedSeriesMapping,
  Series,
  NormalizedSensorSeriesMapping,
  NormalizedCalculatedSeriesMapping,
} from './models';
import {
  CLEAR_ALL_GROUP_SERIES,
  CLEAR_SERIES,
  FETCH_CALCULATED_SERIES_MAPPING,
  FETCH_SENSOR_SERIES,
  FETCH_SENSOR_SERIES_MAPPING,
  FETCH_SERIES_MAPPING,
  namespace,
  POPULATE_CALCULATED_SERIES,
  POPULATE_SENSOR_SERIES,
  POPULATE_WELL_SERIES,
  SET_GROUP_SERIES,
  SWITCH_SERIES_MAPPING,
} from './SeriesActions';
import { normalizeSeriesMapping } from './utils';
import normalizeSensorSeriesMapping from './utils/normalizeSensorSeriesMapping';
import { getCurrentWellId } from 'modules/ui/UIReducer';
import normalizeCalculatedSeriesMapping from './utils/normalizeCalculatedSeriesMapping';

const filterRegExp = new RegExp(`${namespace}/`);

export const STATE_KEY = 'series';

type FetchingStatus = 'loading' | 'success' | 'fail';

interface SeriesState {
  seriesMapping: NormalizedSeriesMapping;
  sensorSeriesMapping: NormalizedSensorSeriesMapping | null;
  calculatedSeriesMapping:
    | NormalizedCalculatedSeriesMapping
    | Record<string, never>;
  series:
    | { [key: string]: { data: NormalizedSeries[]; isPartialData: false } }
    | Record<string, never>;
  fetchedSensorSeries: { [key: string]: boolean }; // seems outdated
  fetchingStatuses: Record<string, FetchingStatus>;
  sensorSeries: { [key: string]: NormalizedSeries[] };
  calculatedSeries: { [key: string]: NormalizedSeries[] };
  groupSeries:
    | { subject: { item: NormalizedSeries[] } }
    | Record<string, never>;
  wasDateRangeFatched: boolean;
}

const initialState: SeriesState = {
  seriesMapping: {},
  sensorSeriesMapping: null,
  calculatedSeriesMapping: {},
  series: {},
  fetchedSensorSeries: {},
  sensorSeries: {},
  calculatedSeries: {},
  groupSeries: {},
  fetchingStatuses: {},
  wasDateRangeFatched: false,
};

const SeriesReducer = (
  state: SeriesState | undefined = initialState,
  action: Action,
): SeriesState => {
  switch (action.type) {
    case `${FETCH_SERIES_MAPPING}_SUCCESS`: {
      const listSeriesMapping = JSON.parse(
        getGraphqlPayload(action).seriesMapping,
      );

      return R.assoc(
        'seriesMapping',
        normalizeSeriesMapping(listSeriesMapping),
        state,
      );
    }
    case FETCH_SENSOR_SERIES: {
      const { seriesId } = action.payload.meta;
      const prevFetchedSeries = state.fetchedSensorSeries;
      const prevFetchingStatuses = state.fetchingStatuses;
      return {
        ...state,
        fetchedSensorSeries: { ...prevFetchedSeries, [seriesId]: true },
        fetchingStatuses: { ...prevFetchingStatuses, [seriesId]: 'loading' },
      };
    }
    case `${FETCH_SENSOR_SERIES}_SUCCESS`: {
      const { seriesId } = action.meta.previousAction.meta;
      const seriesStatus = { [seriesId]: 'success' };
      const fetchingStatuses = { ...state.fetchingStatuses, ...seriesStatus };
      return { ...state, fetchingStatuses };
    }
    case `${FETCH_SENSOR_SERIES}_FAIL`: {
      const { seriesId } = action.meta.previousAction.meta;
      const seriesStatus = { [seriesId]: 'fail' };
      const fetchingStatuses = { ...state.fetchingStatuses, ...seriesStatus };
      return { ...state, fetchingStatuses };
    }
    case `${FETCH_SENSOR_SERIES_MAPPING}_SUCCESS`: {
      const seriesMapping = JSON.parse(getGraphqlPayload(action).sensorSeries);

      return R.assoc(
        'sensorSeriesMapping',
        normalizeSensorSeriesMapping(seriesMapping),
        state,
      );
    }
    case `${FETCH_CALCULATED_SERIES_MAPPING}_SUCCESS`: {
      const calculatedMapping = getGraphqlPayload(action);
      return R.assoc(
        'calculatedSeriesMapping',
        normalizeCalculatedSeriesMapping(calculatedMapping),
        state,
      );
    }
    case SET_GROUP_SERIES: {
      const { series, groupSubject, groupName } = action.payload;
      return R.assocPath(
        ['groupSeries', groupSubject, groupName],
        series,
        state,
      );
    }
    case POPULATE_SENSOR_SERIES: {
      const { seriesId, data } = action.payload;
      return R.assocPath(['sensorSeries', seriesId], data, state);
    }
    case POPULATE_CALCULATED_SERIES: {
      const { seriesId, data } = action.payload;
      return R.assocPath(['calculatedSeries', seriesId], data, state);
    }
    case CLEAR_ALL_GROUP_SERIES: {
      if (!action.payload) {
        return R.assocPath(['groupSeries'], {}, state);
      }
      const { subject, item } = action.payload;
      const currenData = R.path(['groupSeries', subject, item], state);
      return R.assocPath(
        ['groupSeries'],
        { [subject]: { [item]: currenData } },
        state,
      );
    }
    case SWITCH_SERIES_MAPPING: {
      const { id } = action.payload;
      if (R.pathOr(false, ['selectedSeriesMapping', id], state)) {
        return R.assocPath(
          ['selectedSeriesMapping'],
          R.omit(id, state.calculatedSeriesMapping),
          state,
        );
      }
      return R.assocPath(
        ['selectedSeriesMapping'],
        R.assoc(id, true, state.calculatedSeriesMapping),
        state,
      );
    }
    case POPULATE_WELL_SERIES: {
      const { wellId, data, isPartialData } = action.payload;
      return R.assocPath(['series', wellId], { data, isPartialData }, state);
    }
    default: {
      return state;
    }
    case CLEAR_SERIES: {
      return {
        ...state,
        sensorSeries: {},
        series: {},
        fetchedSensorSeries: {},
        calculatedSeries: {},
      };
    }
  }
};

export const getSerieState = (state: any): SeriesState => state[STATE_KEY];
export const getSeriesMapping: Selector<NormalizedSeriesMapping> =
  createSelector(getSerieState, state => state.seriesMapping);

export const getSensorSeriesMapping: Selector<NormalizedSensorSeriesMapping | null> =
  createSelector(getSerieState, state => state.sensorSeriesMapping);

export const getCalculatedSeriesMapping: Selector<NormalizedCalculatedSeriesMapping | null> =
  createSelector(
    getSerieState,
    (state: SeriesState) => state.calculatedSeriesMapping,
  );

export const getCommonSeriesMapping: Selector<NormalizedSeriesMapping> =
  createSelector(
    getSeriesMapping,
    getSensorSeriesMapping,
    getCalculatedSeriesMapping,
    (seriesMapping, sensorSeriesMapping, getCalculatedSeriesMapping) => {
      if (
        R.isEmpty(seriesMapping) ||
        sensorSeriesMapping === null ||
        getCalculatedSeriesMapping === null
      )
        return {};
      const convertedSensorSeriesMapping = Object.values(
        sensorSeriesMapping,
      ).reduce((acc, n, idx) => {
        acc[n.sensorSeriesId] = {
          id: n.sensorSeriesId,
          aggregateMethod: '',
          color: n.color,
          displayName: n.name,
          order: idx + 1,
          seriesIndex: -1,
          sourceName: n.source,
          units: n.units,
        };
        return acc;
      }, {});

      const convertedCalculatedSeriesMapping = Object.values(
        getCalculatedSeriesMapping,
      ).reduce((acc, n, idx) => {
        //@ts-expect-error
        acc[n.calculatedSeriesId] = {
          //@ts-expect-error
          id: n.calculatedSeriesId,
          aggregateMethod: '',
          color: n.color,
          displayName: n.name,
          order: idx + 1,
          seriesIndex: -1,
          sourceName: n.source,
          units: n.units,
        };
        return acc;
      }, {});

      return {
        ...seriesMapping,
        ...convertedSensorSeriesMapping,
        ...convertedCalculatedSeriesMapping,
      };
    },
  );

export const getFetchingStatuses: Selector<SeriesState['fetchingStatuses']> =
  createSelector(getSerieState, (state: SeriesState) => state.fetchingStatuses);

export const getSensorSeriesIndexed: Selector<{
  [key: string]: NormalizedSeries[];
}> = createSelector(getSerieState, state => state.sensorSeries);

export const getCalculatedSeriesIndexed: Selector<{
  [key: string]: NormalizedSeries[];
}> = createSelector(getSerieState, state => state.calculatedSeries);

export const getSensorSeriesAvailableDates: Selector<{
  [key: string]: { min: Date; max: Date };
}> = createSelector(getSerieState, state => {
  const series = state.sensorSeries;

  return Object.entries(series).reduce((acc, [id, series]) => {
    if (series.length !== 0)
      acc[id] = {
        min: series[0].day,
        max: series[series.length - 1].day,
      };
    return acc;
  }, {});
});

export const getCalculatedSeriesAvailableDates: Selector<{
  [key: string]: { min: Date; max: Date };
}> = createSelector(getSerieState, state => {
  const series = state.calculatedSeries;

  return Object.entries(series).reduce((acc, [id, series]) => {
    if (series.length !== 0)
      acc[id] = {
        min: series[0].day,
        max: series[series.length - 1].day,
      };
    return acc;
  }, {});
});

export const getSensorSeriesAvailableRange: Selector<{
  min: Date;
  max: Date;
} | null> = createSelector(getSerieState, state => {
  const series = state.sensorSeries;

  const dates = Object.entries(series).reduce<{
    min: Date | null;
    max: Date | null;
  }>(
    (acc, [id, series]) => {
      if (series.length !== 0) {
        if (!acc.min || acc.min.getTime() > series[0].day.getTime())
          acc.min = series[0].day;
        if (
          !acc.max ||
          acc.max.getTime() < series[series.length - 1].day.getTime()
        )
          acc.max = series[series.length - 1].day;
      }
      return acc;
    },
    { min: null, max: null },
  );

  return dates.min === null
    ? null
    : (dates as {
        min: Date;
        max: Date;
      });
});

export const getAllSeriesByWell = createSelector(
  getSerieState,
  (_, props) => props.wellId as string,
  (
    state,
    wellId,
  ): {
    data: NormalizedSeries[];
    joined: NormalizedSeries[];
    exists: boolean;
  } => {
    const dataSeries = R.pathOr(null, ['series', wellId, 'data'], state);
    const sensorSeries = R.pathOr(null, ['sensorSeries'], state);
    const calculatedSeries = R.pathOr(null, ['calculatedSeries'], state);

    if (!dataSeries) {
      return {
        data: [],
        joined: [],
        exists: false,
      };
    }

    const flatSensorSeries = Object.values<NormalizedSeries[]>(
      sensorSeries ?? {},
    ).flat();

    const flatCalculatedSeries = Object.values<NormalizedSeries[]>(
      calculatedSeries ?? {},
    ).flat();

    const joinedSeries: Series[] = dataSeries
      .concat(flatSensorSeries)
      .concat(flatCalculatedSeries)
      .reduce((acc, n) => {
        const day = n.day.toISOString();
        if (!acc[day]) acc[day] = n;
        else acc[day] = { ...acc[day], ...n };
        return acc;
      }, {});

    const sorted = Object.values(joinedSeries).sort(
      (a, b) => a.day.getTime() - b.day.getTime(),
    );

    return {
      data: dataSeries,
      joined: sorted,
      exists: true,
    };
  },
);

export const getSeriesFetchedStatusByWell = createSelector(
  getSerieState,
  (_, props) => props.wellId as string,
  (state, wellId): Series[] =>
    R.pathOr(false, ['series', wellId, 'isPartialData'], state),
);

export const getGroupedSeries = createSelector(
  getSerieState,
  (_, props) => props,
  (state, props: { subject: string; item: string }): Series[] => {
    const series = R.pathOr(
      [],
      ['groupSeries', props.subject, props.item],
      state,
    );
    return series;
  },
);

export const getHasSeriesDataForCurrentWell: Selector<any> = createSelector(
  getSerieState,
  getCurrentWellId,
  (state, wellId) => !!(state.series[wellId] || state.sensorSeries[wellId]),
);

export default filterActions(SeriesReducer as any, action =>
  action.type.match(filterRegExp),
) as Reducer<SeriesState>;
