import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { changeVisibilityStatus } from 'modules/externalForecast/ExternalForecastActions';
import { getForecastStatus } from 'modules/externalForecast/ExternalForecastReducer';
import { getPermissions } from '../../auth/AuthReducer';

const useForecast = () => {
  const dispatch = useDispatch();

  const isVisible = useSelector(getForecastStatus);
  const allPermissions = useSelector(getPermissions);
  const hasViewForecastPermission = allPermissions.isAllowedViewForecast;

  const setVisibility = React.useCallback(
    (status: boolean) => {
      dispatch(
        changeVisibilityStatus({ status: hasViewForecastPermission && status }),
      );
    },
    [dispatch, hasViewForecastPermission],
  );

  const setVisibilityOn = React.useCallback(
    () => setVisibility(true),
    [setVisibility],
  );

  const setVisibilityOff = React.useCallback(
    () => setVisibility(false),
    [setVisibility],
  );

  const toggleVisibility = React.useCallback(
    () => setVisibility(!isVisible),
    [isVisible, setVisibility],
  );

  React.useEffect(() => {
    if (!hasViewForecastPermission) setVisibilityOff();
  }, [hasViewForecastPermission, setVisibilityOff]);

  return {
    isVisible,
    setVisibility,
    setVisibilityOn,
    setVisibilityOff,
    toggleVisibility,
    hasViewForecastPermission,
  };
};

export default useForecast;
