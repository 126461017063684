import * as React from 'react';

import useMouseDownOutside from 'hooks/useMouseDownOutside';

interface EvetnPosition {
  clientX: number;
  clientY: number;
}

type useMenuHook = (
  target: React.RefObject<HTMLElement | null>,
) => [
  boolean,
  () => void,
  () => void,
  (e: MouseEvent) => void,
  () => void,
  { clientX: number; clientY: number } | null,
  () => void,
];

const useMenu: useMenuHook = menuElementRef => {
  const [isMenuOpen, setMenu] = React.useState(false);
  const [eventPosition, setEventPosition] =
    React.useState<EvetnPosition | null>(null);
  const [menuTimer, setMenuTimer] = React.useState<any>(null);
  const resetTimer = () => clearTimeout(menuTimer);
  const openMenu = () => setMenu(true);
  const closeMenu = () => setMenu(false);
  const onMenuLeave = () => setMenuTimer(setTimeout(closeMenu, 500));
  const onMenuHover = () => {
    if (menuTimer) clearTimeout(menuTimer);
  };

  const onTargetClick = (e: MouseEvent) => {
    const { clientX, clientY } = e;
    setEventPosition({ clientX, clientY });
    openMenu();
  };

  useMouseDownOutside([menuElementRef], closeMenu);

  return [
    isMenuOpen,
    onMenuHover,
    onMenuLeave,
    onTargetClick,
    closeMenu,
    eventPosition,
    resetTimer,
  ];
};

export default useMenu;
