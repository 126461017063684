import { timeDay, TimeInterval, utcDay, utcMinute } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'store/models';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import {
  createVarianceYScale,
  isInside,
  getSeriesType,
} from 'modules/chart/utils';
import SeriesPill, {
  SeriesPillListContainer,
} from 'modules/chart/components/SeriesPill';
import YAxis from 'modules/chart/components/YAxis';
import {
  ListChartOptions,
  BAR_CHART,
  NOT_EXISTING_SENSOR_SERIES_MESSAGE,
  NOT_EXISTING_CALCULATED_SERIES_MESSAGE,
} from 'modules/chartOptions/models';
import { DataSeriesTooltipData, TooltipData } from 'modules/ui/models/ui';

import usePrevious from 'hooks/usePrevious';

import SVGSeriesTrellis from '../components/SVGSeriesTrellis';
import SVGSeriesTrellisInteraction from '../components/SVGSeriesTrellisInteraction';
import {
  Series,
  NormalizedSeriesMapping,
  SERIES_PILL_OFFSET,
  SERIES_PILL_HEIGHT,
  NormalizedSeries,
} from '../models';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';
import {
  getSensorSeriesMapping,
  getSensorSeriesAvailableDates,
  getSensorSeriesIndexed,
  getCalculatedSeriesIndexed,
  getCalculatedSeriesMapping,
  getCalculatedSeriesAvailableDates,
  getHasSeriesDataForCurrentWell,
} from '../SeriesReducer';
import checkSensorSeriesExists from '../../chartOptions/utils/checkSensorSeriesExists';
import SVGSeriesFakeTrellis from '../components/SVGSeriesFakeTrellis';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import useChartScaling from 'modules/chart/hooks/useChartScaling';
import useThrottledCallback from 'hooks/useThrottledCallback';
import { approximatedSearchByDate } from 'helpers/approximatedSearchByDate';
import LoaderIndicatorWithDelay from '../../chart/components/LoaderIndicatorWithDelay';
import SensorSeriesStatusText from './SensorSeriesStatusText';
import { getYAxisHovered } from 'modules/ui/UIReducer';

const getDataForSeriesTooltip = ({
  dataMap,
  chartOptions,
  groupOptions,
  seriesMapping,
  tooltipData,
  availableSensorSeriesDates,
  availableCalculatedSeriesDates,
}) => {
  const tooltipDate =
    R.pathOr(
      false,
      ['trellisTooltipData', 'pointerDateForSeries'],
      tooltipData,
    ) ||
    R.pathOr(false, ['trellisTooltipData', 'day'], tooltipData) ||
    R.pathOr(false, ['dataSeriesTooltipData', 'day'], tooltipData) ||
    null;

  if (!tooltipData || !tooltipDate || R.isEmpty(seriesMapping)) return [];

  const nearestSensorSeriesDates = tooltipData.trellisTooltipData
    ? (utcMinute.every(1) as TimeInterval).range(
        tooltipDate,
        utcDay.offset(tooltipDate, 1),
      )
    : (utcMinute.every(1) as TimeInterval)
        .range(
          utcDay.offset(tooltipDate, -1),
          utcMinute.offset(utcMinute.floor(tooltipDate), 1),
        )
        .reverse();

  const dataForTooltip = groupOptions.reduce((acc, seriesId) => {
    const name = seriesMapping[seriesId]?.displayName;
    const color = (chartOptions[seriesId]?.customColor ||
      chartOptions[seriesId]?.color) as string;

    let value: number | null = null;
    if (seriesId.startsWith('s') || seriesId.startsWith('c')) {
      const availableDates = seriesId.startsWith('s')
        ? availableSensorSeriesDates[seriesId]
        : availableCalculatedSeriesDates[seriesId];
      if (
        !availableDates ||
        tooltipDate.getTime() > availableDates.max.getTime()
      )
        return acc;
      const existingDate = nearestSensorSeriesDates.find(
        d => R.path([d.toISOString(), seriesId], dataMap) !== undefined,
      );
      if (!existingDate) return acc;

      value = R.pathOr(null, [existingDate.toISOString(), seriesId], dataMap);
    } else {
      value = R.pathOr(
        null,
        [
          timeDay.floor(tooltipDate).toISOString(),
          `series${seriesMapping[seriesId].seriesIndex}`,
        ],
        dataMap,
      );
    }

    acc.push({
      name,
      color,
      value,
    });
    return acc;
  }, [] as any);

  return dataForTooltip.sort((a, b) => b.value - a.value);
};

const getFormat = (sourceName: string) =>
  sourceName.toLowerCase() === 'Watercut' ? '.0%' : ',d';

const getMaxDataPoints = (
  seriesData: Series[],
  seriesMapping: NormalizedSeriesMapping,
  options: string[],
  extremeDates: { min: Date; max: Date },
  firstSeriesPillDate: Date,
) => {
  if (!seriesData || seriesData.length === 0 || R.isEmpty(seriesMapping)) {
    return { global: 0, pill: 0 };
  }

  const maxSeriesValues = options.reduce(
    (
      acc: {
        global: number;
        pill: number;
      },
      optionId: string,
    ) => {
      if (!seriesMapping[optionId]) return acc;
      const key =
        optionId.startsWith('s') || optionId.startsWith('c')
          ? optionId
          : `series${seriesMapping[optionId].seriesIndex}`;

      const minIndex = approximatedSearchByDate(
        seriesData,
        extremeDates.min,
        (date, e) => date.getTime() - e.day.getTime(),
      );
      const maxIndex = approximatedSearchByDate(
        seriesData,
        extremeDates.max,
        (date, e) => date.getTime() - e.day.getTime(),
      );
      const minPillIndex =
        approximatedSearchByDate(
          seriesData,
          firstSeriesPillDate,
          (date, e) => date.getTime() - e.day.getTime(),
        ) - minIndex;

      const slice = seriesData.slice(minIndex, maxIndex + 1);
      const matchedGlobalValues: Series | number[] = [];
      const matchedPillValues: Series | number[] = [];
      slice.forEach((el, idx) => {
        const series = el[key] ?? 0;
        if (idx >= minPillIndex) {
          matchedPillValues.push(series);
        } else {
          matchedGlobalValues.push(series);
        }
      });
      const maxPillValue = Math.max(...matchedPillValues, acc.pill);
      const maxGlobalValue = Math.max(
        ...matchedGlobalValues,
        maxPillValue,
        acc.global,
      );

      return { global: maxGlobalValue, pill: maxPillValue };
    },
    { global: 0, pill: 0 },
  );

  return {
    global: maxSeriesValues.global * 1.05 || 10,
    pill: maxSeriesValues.pill,
  };
};

interface SeriesChartProps {
  groupOptions: string[];
  chartWasDragging: boolean;
  seriesMapping: NormalizedSeriesMapping;

  currentWellId: string;
  height: number;
  isAxisDragging: boolean;
  isDragging: boolean;
  isLast: boolean;
  leftOffset: number;
  onSetTooltipData: (tooltipData: DataSeriesTooltipData | null) => void;
  series: {
    data: NormalizedSeries[];
    joined: NormalizedSeries[];
    exists: boolean;
  };
  tooltipData: TooltipData | null;
  width: number;
  chartOptions: ListChartOptions;
  today: Date;
}

const SeriesChart = ({
  groupOptions,
  chartOptions,
  chartWasDragging,
  seriesMapping,
  currentWellId,
  height,
  isAxisDragging,
  isDragging,
  isLast,
  leftOffset,
  onSetTooltipData,
  tooltipData,
  series,
  width,
  today,
}: SeriesChartProps) => {
  const sensorSeriesMapping = useSelector(getSensorSeriesMapping);
  const calculatedSeriesMapping = useSelector(getCalculatedSeriesMapping);
  const yAxisHovered = useSelector(getYAxisHovered);
  const groupOptionsWithActiveFilter = React.useMemo(() => {
    if (!sensorSeriesMapping) {
      return [];
    }

    return groupOptions.map(optionId => {
      if (/s/.test(optionId)) {
        return {
          ...chartOptions[optionId],
          exists: checkSensorSeriesExists(
            chartOptions[optionId],
            sensorSeriesMapping,
            currentWellId,
          ),
        };
      }
      if (/c/.test(optionId)) {
        return {
          ...chartOptions[optionId],
          exists: checkSensorSeriesExists(
            chartOptions[optionId],
            calculatedSeriesMapping,
            currentWellId,
          ),
        };
      }
      return chartOptions[optionId];
    });
  }, [chartOptions, groupOptions, sensorSeriesMapping]);

  const containerElem = React.useRef(null);
  const firstSeriesPillElem = React.useRef<HTMLElement | null>(null);
  const rect =
    containerElem && containerElem.current
      ? //@ts-expect-error
        containerElem.current.getBoundingClientRect()
      : {};

  const getContainerAreaCoords = React.useCallback(
    elementBoundingRect => ({
      x1: elementBoundingRect.x,
      x2: elementBoundingRect.x + elementBoundingRect.width,
      y1: elementBoundingRect.y,
      y2: elementBoundingRect.y + elementBoundingRect.height,
    }),
    [],
  );
  const noteContainerCoords = getContainerAreaCoords(rect);

  const currentPointerIsInsideChart =
    tooltipData && tooltipData.dataSeriesTooltipData
      ? isInside(
          R.pick(['clientY', 'clientX'], tooltipData.dataSeriesTooltipData),
          noteContainerCoords,
        )
      : false;

  const multipliedSeries = React.useMemo<any>(() => {
    if (R.isEmpty(seriesMapping)) return series;
    const waterCutSeries = Object.values(seriesMapping).filter(
      m => m.units === '%' && /watercut/i.test(m.sourceName),
    );

    const multiply = series =>
      series.map(s => {
        const copy = {
          ...s,
          ...waterCutSeries.reduce((acc, w) => {
            if (!s[`series${w.seriesIndex}`]) return acc;

            return {
              ...acc,
              [`series${w.seriesIndex}`]: s[`series${w.seriesIndex}`] * 100,
            };
          }, {}),
        };
        return copy;
      });

    return {
      ...series,
      joined: multiply(series.joined),
      data: multiply(series.data),
    };
  }, [series, seriesMapping]);

  const extremeDates = useSelector(getExtremeDates);

  const [initialMaxDataPoint, setInitialMaxDataPoint] = React.useState(0);
  const [yScaleOffset, setYScaleOffset] = React.useState(0);

  const [displayMinDataPoint, setDisplayMinDataPoint] = React.useState(0);
  const [displayMaxDataPoint, setDisplayMaxDataPoint] =
    React.useState(initialMaxDataPoint);
  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const [isAdjusted, setIsAdjusted] = React.useState(false);
  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    setDisplayMinDataPoint(0);
    setDisplayMaxDataPoint(initialMaxDataPoint);
  }, [setIsAdjusted, setDisplayMaxDataPoint, initialMaxDataPoint]);

  const prevMaxDataPoint = usePrevious(initialMaxDataPoint);
  const prevWellId = usePrevious(currentWellId);
  const calculateMaxDataPoint = useThrottledCallback(
    (
      extremeDates,
      series,
      seriesMapping,
      groupOptions,
      firstSeriesPillDate,
    ) => {
      const { global: globalMaxDataPoint, pill: pillMaxDataPoint } =
        getMaxDataPoints(
          series,
          seriesMapping,
          groupOptions,
          extremeDates,
          firstSeriesPillDate,
        );
      setInitialMaxDataPoint(globalMaxDataPoint);

      const tempYScale = createVarianceYScale(height, 0, globalMaxDataPoint, 0);

      if (!pillMaxDataPoint || isAdjusted) return;

      const pillMaxDataPointOffset = tempYScale(pillMaxDataPoint);

      if (pillMaxDataPointOffset < SERIES_PILL_HEIGHT) {
        setYScaleOffset(
          SERIES_PILL_HEIGHT - pillMaxDataPointOffset + SERIES_PILL_OFFSET,
        );
      }
      if (pillMaxDataPointOffset > SERIES_PILL_HEIGHT + SERIES_PILL_OFFSET) {
        setYScaleOffset(0);
      }
    },
    [isAdjusted],
    50,
  );

  const [isYAxisDragging, setIsStartYAxisDragging] = React.useState(false);

  const yScale = React.useMemo(
    () =>
      createVarianceYScale(
        height,
        displayMinDataPoint,
        displayMaxDataPoint,
        yScaleOffset,
      ),
    [height, displayMinDataPoint, displayMaxDataPoint, yScaleOffset],
  );

  React.useEffect(() => {
    if (!firstSeriesPillElem.current) {
      return;
    }
    calculateMaxDataPoint(
      extremeDates,
      multipliedSeries.joined,
      seriesMapping,
      groupOptions,
      xScale.invert(firstSeriesPillElem.current.offsetLeft),
    );
  }, [extremeDates, multipliedSeries, seriesMapping, groupOptions]);

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );
  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );

  React.useEffect(() => {
    if (
      prevMaxDataPoint !== initialMaxDataPoint &&
      prevMaxDataPoint === displayMaxDataPoint
    ) {
      setDisplayMaxDataPoint(initialMaxDataPoint);
    }
  }, [
    prevMaxDataPoint,
    initialMaxDataPoint,
    displayMaxDataPoint,
    setDisplayMaxDataPoint,
  ]);

  const orderdGroupOptions = React.useMemo(() => {
    if (groupOptions.length >= 1) {
      return [...groupOptions].reverse().sort((a, b) => {
        const typeA = chartOptions[a].chartType;
        const typeB = chartOptions[b].chartType;
        if (typeA !== typeB && typeA === BAR_CHART) {
          return -1;
        }
        if (typeA !== typeB && typeB === BAR_CHART) {
          return 1;
        }
        return 0;
      });
    }
    return groupOptions;
  }, [groupOptions, chartOptions]);

  const color = React.useMemo(() => {
    if (groupOptions.length > 1 || R.isEmpty(seriesMapping)) {
      return '#000';
    }
    return (
      chartOptions[groupOptions[0]].customColor ||
      seriesMapping[groupOptions[0]].color
    );
  }, [groupOptions, chartOptions, seriesMapping]);

  React.useEffect(() => {
    if (prevWellId !== currentWellId) resetMax();
  }, [currentWellId, prevWellId, resetMax]);

  const dataMap = React.useMemo(() => {
    return multipliedSeries.joined.reduce((acc, series) => {
      const key = Object.keys(series)[1].startsWith('series')
        ? timeDay(series.day).toISOString()
        : utcMinute(series.day).toISOString();
      acc[key] = series;
      return acc;
    }, {});
  }, [multipliedSeries]);

  const availableSensorSeriesDates = useSelector(getSensorSeriesAvailableDates);
  const availableCalculatedSeriesDates = useSelector(
    getCalculatedSeriesAvailableDates,
  );

  const dataForSeriesTooltip = getDataForSeriesTooltip({
    dataMap,
    chartOptions,
    groupOptions,
    seriesMapping,
    tooltipData,
    availableSensorSeriesDates,
    availableCalculatedSeriesDates,
  });

  const indexedSensorSeries = useSelector(getSensorSeriesIndexed);
  const indexedCalculatedSeries = useSelector(getCalculatedSeriesIndexed);

  const indexedSeries = React.useMemo(
    () => ({
      ...indexedSensorSeries,
      ...indexedCalculatedSeries,
      dataSeries: multipliedSeries.data,
    }),
    [indexedSensorSeries, multipliedSeries],
  );

  const hasSeriesDataForCurrentWell = useSelector(
    getHasSeriesDataForCurrentWell,
  );

  const { xScale, onXAxisScaling } = useChartScaling();

  const isOnlySensorSeries = React.useMemo(() => {
    return orderdGroupOptions?.every(id => id.startsWith('s'));
  }, [orderdGroupOptions]);

  const isNotExist =
    groupOptionsWithActiveFilter.length !== 0 &&
    groupOptionsWithActiveFilter.some(
      elem => elem.exists || elem.exists === undefined,
    ) === false;

  return (
    <SeriesChart.Container height={height} isLast={isLast} ref={containerElem}>
      {!hasSeriesDataForCurrentWell && <LoaderIndicatorWithDelay />}
      {!R.isEmpty(seriesMapping) && hasSeriesDataForCurrentWell && (
        <>
          <SeriesChart.MessageContainer>
            {isNotExist && (
              <span>
                {/s/.test(groupOptionsWithActiveFilter[0].id)
                  ? NOT_EXISTING_SENSOR_SERIES_MESSAGE
                  : NOT_EXISTING_CALCULATED_SERIES_MESSAGE}
              </span>
            )}
            {!isNotExist && isOnlySensorSeries && (
              <span>
                <SensorSeriesStatusText
                  groupOptions={orderdGroupOptions}
                  series={indexedSeries}
                />
              </span>
            )}
          </SeriesChart.MessageContainer>

          <SeriesChart.SVGWrapper className="trellis-chart-wrapper">
            {!isAxisDragging && !isNotExist && (
              <SVGSeriesTrellis
                chartOptions={chartOptions}
                groupOptions={orderdGroupOptions}
                height={height}
                onSetTooltipData={onSetTooltipData}
                series={indexedSeries}
                seriesMapping={seriesMapping}
                displayMaxDataPoint={displayMaxDataPoint}
                dataMap={dataMap}
                tooltipData={tooltipData}
                xScale={xScale}
                yAxisLinePos={yAxisLinePos}
                yScale={yScale}
                format={getFormat(seriesMapping[groupOptions[0]].sourceName)}
              />
            )}

            {!R.isEmpty(indexedSeries) && isAxisDragging && (
              <SVGSeriesFakeTrellis
                xScale={xScale}
                chartOptions={chartOptions}
                groupOptions={orderdGroupOptions}
                height={height}
                yScale={yScale}
                series={indexedSeries}
                seriesMapping={seriesMapping}
                resetTooltipData={() => onSetTooltipData(null)}
              />
            )}

            {!(isAxisDragging && chartWasDragging) && (
              <SVGSeriesTrellisInteraction
                height={height}
                isDragging={isDragging}
                maxDataPoint={displayMaxDataPoint}
                onXAxisScaling={onXAxisScaling}
                width={width}
              />
            )}
          </SeriesChart.SVGWrapper>

          <SeriesChart.YAxisContainer>
            <YAxis
              format={getFormat(seriesMapping[groupOptions[0]].sourceName)}
              height={height}
              color={color}
              disableZero={displayMinDataPoint >= 0}
              maxDataPoint={displayMaxDataPoint}
              isAdjusted={isAdjusted}
              hideLine={hideLine}
              minDataPoint={displayMinDataPoint}
              onStartYAxisDragging={() => setIsStartYAxisDragging(true)}
              onStopDragging={() => setIsStartYAxisDragging(false)}
              setDisplayMaxDataPoint={setDisplayMaxDataPoint}
              setDisplayMinDataPoint={setDisplayMinDataPoint}
              isDragging={isYAxisDragging}
              resetMax={resetMax}
              showLine={showLine}
              setIsAdjusted={setIsAdjusted}
              yScale={yScale}
              varianceTrellis
            />
          </SeriesChart.YAxisContainer>
          <SeriesPillListContainer ref={firstSeriesPillElem}>
            {groupOptionsWithActiveFilter &&
              groupOptionsWithActiveFilter.map(elem => (
                <SeriesPill
                  key={elem.id}
                  id={elem.id}
                  color={
                    chartOptions[elem.id]?.customColor ||
                    seriesMapping[elem.id]?.color
                  }
                  text={seriesMapping[elem.id]?.displayName}
                  isNotExist={elem.exists === false}
                  tooltipTitle={`${seriesMapping[elem.id]?.displayName}, ${
                    seriesMapping[elem.id]?.units
                  }`}
                  tooltipDescription={`${getSeriesType(elem.id)} Series`}
                />
              ))}
          </SeriesPillListContainer>
          {!yAxisHovered &&
            tooltipData &&
            (tooltipData.trellisTooltipData ||
              tooltipData.dataSeriesTooltipData) &&
            !currentPointerIsInsideChart && (
              <SecondaryInformationTooltip
                containerHeight={height}
                leftOffset={leftOffset}
                tooltipData={tooltipData}
                secondaryCavTooltipData={{}}
                seriesTooltipData={dataForSeriesTooltip}
                trellisTitle={''}
                yScale={yScale}
                today={today}
              />
            )}
        </>
      )}
    </SeriesChart.Container>
  );
};

SeriesChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

SeriesChart.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

SeriesChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

SeriesChart.MessageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
`;

export default React.memo<SeriesChartProps>(SeriesChart);
