import * as React from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import { getFilters } from 'modules/filter/FilterReducer';
import { notifyDrilldownTableInconsistency } from '../DrilldownTableActions';
import {
  getDrilldownLoadingStatus,
  getDrilldownTableParams,
  getFilteredVarianceTable,
  getWellDrilldownTable,
} from '../DrilldownTableReducer';
import { COMPARE_OPTION } from '../models/drilldownTable';

const useDrilldownInconsistencyWatcher = (): void => {
  const filters = useSelector(getFilters);
  const dispatch = useDispatch();
  const wellDrilldownTable = useSelector(getWellDrilldownTable);
  const varianceDrilldownTable = useSelector(getFilteredVarianceTable);
  const fetchingStatus = useSelector(getDrilldownLoadingStatus);
  const drilldownParams = useSelector(getDrilldownTableParams);
  const areDrilldownTablesLoading = React.useMemo(
    () =>
      fetchingStatus.isVarianceTableFetching ||
      fetchingStatus.isWellTableFetching ||
      R.isEmpty(varianceDrilldownTable) ||
      R.isEmpty(wellDrilldownTable),
    [fetchingStatus, varianceDrilldownTable, wellDrilldownTable],
  );
  const isEnabled = React.useRef(true);

  React.useEffect(() => {
    if (!areDrilldownTablesLoading) return;

    isEnabled.current = true;
  }, [areDrilldownTablesLoading]);

  React.useEffect(() => {
    if (
      drilldownParams.compareOption !== COMPARE_OPTION.actual ||
      /localhost/.test(window.location.hostname) ||
      areDrilldownTablesLoading ||
      !isEnabled.current
    )
      return;

    const capacityKey = `${drilldownParams.phase.toLowerCase()}Capacity`;
    const actualKey = `${drilldownParams.phase.toLowerCase()}Actual`;
    const wellTotal = wellDrilldownTable.reduce((acc, row) => {
      const capacity = row[capacityKey] ?? row[actualKey];
      const actual = row[actualKey];
      return acc + (actual - capacity);
    }, 0);

    const varianceKey = `${drilldownParams.phase.toLowerCase()}Variance`;
    const varianceTotal = varianceDrilldownTable.reduce((acc, tableItem) => {
      acc += tableItem[varianceKey];
      return acc;
    }, 0);

    if (wellTotal.toFixed(6) === varianceTotal.toFixed(6)) return;

    isEnabled.current = false;

    const urlParams = new URLSearchParams(window.location.search);

    const urlParamsAsObject = {};

    for (const [key, value] of urlParams.entries()) {
      urlParamsAsObject[key] = value;
    }

    const payload = {
      origin: window.location.hostname,
      filters: filters,
      urlParams: urlParamsAsObject,
      fullUrl: window.location.href,
    };

    dispatch(notifyDrilldownTableInconsistency(payload));
  }, [
    areDrilldownTablesLoading,
    varianceDrilldownTable,
    wellDrilldownTable,
    drilldownParams,
    filters,
  ]);
};

export { useDrilldownInconsistencyWatcher };
