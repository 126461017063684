import gql from 'graphql-tag';

import type { Action, GraphqlQueryAction } from 'store/models';
import { ForecastData } from './models';
import wellRequestsAbortController from 'modules/well/utils/wellRequestsAbortController';

export const namespace = 'EXTERNAL_FORECAST';
export const INIT_FETCH_EXTERNAL_FORECAST = `${namespace}/INIT_FETCH_EXTERNAL_FORECAST`;
export const FETCH_WELL_FORECAST = `${namespace}/FETCH_WELL_FORECAST`;
export const FETCH_WELL_FORECAST_DATE_RANGE = `${namespace}/FETCH_WELL_FORECAST_DATE_RANGE`;
export const FETCH_GROUP_FORECAST = `${namespace}/FETCH_GROUP_FORECAST`;
export const FETCH_NET_GROUP_FORECAST = `${namespace}/FETCH_NET_GROUP_FORECAST`;
export const FETCH_GROUP_FORECAST_DATE_RANGE = `${namespace}/FETCH_GROUP_FORECAST_DATE_RANGE`;
export const FETCH_NET_GROUP_FORECAST_DATE_RANGE = `${namespace}/FETCH_NET_GROUP_FORECAST_DATE_RANGE`;
export const CLEAR_GROUP_FORECASTS = `${namespace}/CLEAR_GROUP_FORECASTS`;
export const POPULATE_WELL_FORECAST = `${namespace}/POPULATE_WELL_FORECAST`;
export const POPULATE_GROUP_FORECAST = `${namespace}/POPULATE_GROUP_FORECAST`;
export const CHANGE_VISIBILITY_STATUS = `${namespace}/CHANGE_VISIBILITY_STATUS`;

type claerGroupForecastsAC = (paylpad?: {
  subject: string;
  item: string;
}) => Action;

export const claerGroupForecasts: claerGroupForecastsAC = payload => ({
  type: CLEAR_GROUP_FORECASTS,
  payload,
});
type changeVisibilityStatusAC = (payload: { status: boolean }) => Action;

export const changeVisibilityStatus: changeVisibilityStatusAC = payload => ({
  type: CHANGE_VISIBILITY_STATUS,
  payload,
});

const FETCH_WELL_FORECAST_QUERY = gql`
  query ($payload: ForecastInput!) {
    listWellForecast(data: $payload) {
      forecastData
    }
  }
`;
const FETCH_WELL_FORECAST_DATE_RANGE_QUERY = gql`
  query ($payload: ForecastInputDateRange!) {
    listWellForecastDateRange(data: $payload) {
      forecastData
    }
  }
`;

type fetchWellForecastDateRangeAC = ({
  wellId,
  minDate,
  maxDate,
}: {
  wellId: string;
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchWellForecastDateRange: fetchWellForecastDateRangeAC =
  payload => ({
    type: FETCH_WELL_FORECAST_DATE_RANGE,
    payload: {
      key: 'listWellForecastDateRange',
      graphql: {
        query: FETCH_WELL_FORECAST_DATE_RANGE_QUERY,
        variables: {
          payload,
        },
      },
      options: {
        getContext: wellRequestsAbortController.getContext,
      },
    },
  });

type fetchWellForecastAC = ({ wellId: string }) => GraphqlQueryAction;

export const fetchWellForecast: fetchWellForecastAC = payload => ({
  type: FETCH_WELL_FORECAST,
  payload: {
    key: 'listWellForecast',
    graphql: {
      query: FETCH_WELL_FORECAST_QUERY,
      variables: {
        payload: {
          wellId: payload,
        },
      },
    },
    options: {
      getContext: wellRequestsAbortController.getContext,
    },
  },
});

const GET_GROUP_FORECAST_QUERY = gql`
  query ($payload: GroupForecastInput!) {
    getGroupForecast(data: $payload) {
      groupForecastData
    }
  }
`;

type fetchGroupForecastAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: {
    custom?: { attributeId: string; values: string[] }[];
    default?: { columnIndex: number; values: string[] }[];
  };
}) => GraphqlQueryAction;

export const fetchGroupForecast: fetchGroupForecastAC = payload => ({
  type: FETCH_GROUP_FORECAST,
  payload: {
    key: 'getGroupForecast',
    graphql: {
      query: GET_GROUP_FORECAST_QUERY,
      variables: { payload },
    },
    options: { getContext: wellRequestsAbortController.getContext },
  },
});

const GET_NET_GROUP_FORECAST_QUERY = gql`
  query ($payload: GroupForecastInput!) {
    getNetGroupForecast(data: $payload) {
      groupForecastData
    }
  }
`;

type fetchNetGroupForecastAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: {
    custom?: { attributeId: string; values: string[] }[];
    default?: { columnIndex: number; values: string[] }[];
  };
}) => GraphqlQueryAction;

export const fetchNetGroupForecast: fetchNetGroupForecastAC = payload => ({
  type: FETCH_NET_GROUP_FORECAST,
  payload: {
    key: 'getNetGroupForecast',
    graphql: {
      query: GET_NET_GROUP_FORECAST_QUERY,
      variables: { payload },
    },
    options: { getContext: wellRequestsAbortController.getContext },
  },
});

const GET_GROUP_FORECAST_DATE_RANGE_QUERY = gql`
  query ($payload: GroupForecastDateRangeInput!) {
    getGroupForecastDateRange(data: $payload) {
      groupForecastData
    }
  }
`;

type fetchGroupForecastDateRangeAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: {
    custom?: { attributeId: string; values: string[] }[];
    default?: { columnIndex: number; values: string[] }[];
  };
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchGroupForecastDateRange: fetchGroupForecastDateRangeAC =
  payload => ({
    type: FETCH_GROUP_FORECAST_DATE_RANGE,
    payload: {
      key: 'getGroupForecastDateRange',
      graphql: {
        query: GET_GROUP_FORECAST_DATE_RANGE_QUERY,
        variables: { payload },
      },
    },
  });

const GET_NET_GROUP_FORECAST_DATE_RANGE_QUERY = gql`
  query ($payload: GroupForecastDateRangeInput!) {
    getNetGroupForecastDateRange(data: $payload) {
      groupForecastData
    }
  }
`;

type fetchNetGroupForecastDateRangeAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: {
    custom?: { attributeId: string; values: string[] }[];
    default?: { columnIndex: number; values: string[] }[];
  };
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchNetGroupForecastDateRange: fetchNetGroupForecastDateRangeAC =
  payload => ({
    type: FETCH_NET_GROUP_FORECAST_DATE_RANGE,
    payload: {
      key: 'getNetGroupForecastDateRange',
      graphql: {
        query: GET_NET_GROUP_FORECAST_DATE_RANGE_QUERY,
        variables: { payload },
      },
    },
  });

type populateGroupForecastAC = (payload: {
  groupSubject: string;
  groupName: string;
  forecastData: ForecastData;
  isNet: boolean;
}) => Action;

export const populateGroupForecast: populateGroupForecastAC = payload => ({
  type: POPULATE_GROUP_FORECAST,
  payload,
});

type populateWellForecastAC = (payload: {
  wellId: string;
  data: ForecastData;
  isPartialData: boolean;
}) => Action;

export const populateWellForecast: populateWellForecastAC = payload => ({
  type: POPULATE_WELL_FORECAST,
  payload,
});

export const initFetchExternalForecast = () => ({
  type: INIT_FETCH_EXTERNAL_FORECAST,
});
