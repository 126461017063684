import * as Sentry from '@sentry/browser';
import Env from 'helpers/Env';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import type { MetricsPluginInternals } from '../../models';

type Config = {
  appId: string;
};

const LogRocketPlugin: MetricsPluginInternals = {
  isSourceCodeLoaded: () => {
    return true;
  },

  identifyUser: user => {
    LogRocket.identify(user.id, user);
  },

  anonomizeUser: () => {
    /*
      There is no need to call LogRocket.identify for anonymous or logged out users. 
      LogRocket uses sessionStorage to persist sessions across pages for logged-out users.
      More info: https://docs.logrocket.com/reference/identify
    */
    return;
  },

  captureEvent: (event, data) => {
    LogRocket.track(event.name, Object.assign({ eventCode: event.code }, data));
  },

  captureError: (error, data) => {
    LogRocket.captureException(error.originalError, {
      tags: { location: error.location },
      extra: data,
    });
  },

  initialize: (config: Config) => {
    if (!config || !config.appId) return;

    LogRocket.init(config.appId, {
      release: Env.getVariable('REACT_APP_GIT_SHA1'),
      shouldCaptureIP: false,
      shouldDebugLog: false,
      console: {
        isEnabled: true,
      },
    });
  },
};

LogRocket.getSessionURL(sessionURL => {
  const key = 'LogRocketSessionUrl';

  /* 
    Used to add 'X-LogRocket-URL' header in API calls.
    May be used by other tools. 
  */
  window.logRocketSessionURL = sessionURL;

  /*
    Add a LogRocket session URL to a Heap user profile.
    More info: https://docs.logrocket.com/docs/heap
  */
  window.heap?.addEventProperties?.({ [key]: sessionURL });

  /*
    Add the LogRocket URL to PageView events.
    More info: https://docs.logrocket.com/docs/new-relic#new-relic-browser
  */
  window.newrelic?.setCustomAttribute?.(key, sessionURL);

  /*
    Add a LogRocket session recording URL to every Sentry exception report.
    More info: https://docs.logrocket.com/docs/sentry
  */
  Sentry.configureScope(scope => {
    scope.setExtra(key, sessionURL);
  });
});

/* 
  May work but "logrocket-react" plugin only supports react@17 
*/
setupLogRocketReact(LogRocket);

export default LogRocketPlugin;
