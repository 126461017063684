import React from 'react';

type TimingData = {
  startTime: number;
  endTime: number;
  duration: number;
};

type UseTimingParams = {
  onStart?: (startTime: number) => void;
  onStop?: (data: TimingData) => void;
};

const useTiming = (params?: UseTimingParams) => {
  const [startTime, setStartTime] = React.useState<number | null>(null);
  const [endTime, setEndTime] = React.useState<number | null>(null);

  const duration = React.useMemo(() => {
    if (startTime === null || endTime === null) return null;
    return endTime - startTime;
  }, [startTime, endTime]);

  const isTicking = React.useMemo(() => {
    return startTime !== null && endTime === null;
  }, [startTime, endTime]);

  const start = React.useCallback(() => {
    const startTime = Date.now();
    setEndTime(null);
    setStartTime(startTime);
    params?.onStart?.(startTime);
  }, []);

  const stop = React.useCallback(() => {
    if (!isTicking) return;
    setEndTime(Date.now());
  }, [isTicking]);

  const reset = React.useCallback(() => {
    setStartTime(null);
    setEndTime(null);
  }, []);

  React.useEffect(() => {
    if (duration && startTime && endTime) {
      params?.onStop?.({ duration, startTime, endTime });
    }
  }, [duration]);

  return {
    duration,
    isTicking,
    start,
    stop,
    reset,
  };
};

export default useTiming;
