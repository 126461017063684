import React from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalHotKeys } from 'react-hotkeys';
import styled from 'styled-components';

import Card from 'components/Card';
import ClickOutside from 'components/ClickOutside';
import { Tab, TabsProvider } from 'components/Tabs';

import { Menu } from 'modules/logoMenu/Menu';
import { DrilldownPanel } from 'modules/drilldownTable/containers/DrilldownPanel';
import { InboxConversation } from 'modules/inboxConversation/containers/InboxConversation';
import useLeftPanel from 'modules/ui/hooks/useLeftPanel';
import { getActivePanel } from 'modules/ui/UIReducer';
import { DRILLDOWN_PANEL } from 'modules/ui/models/ui';
import {
  enableRegionOfInterestMode,
  resetActivePanel,
} from 'modules/ui/UIActions';
import { getFilters } from 'modules/filter/FilterReducer';

import { TabsHeader } from '../components/TabsHeader';

const keyMap = {
  regionOfInterestModeOn: 'r',
};

const LeftPanels = () => {
  const dispatch = useDispatch();
  const leftPanel = useLeftPanel();
  const activePanel = useSelector(getActivePanel);
  const filters = useSelector(getFilters);
  const filtersApplied = R.pickBy(filter => !R.isEmpty(filter), filters);
  const filterQuant = R.keys(filtersApplied).length;

  const regionOfInterestModeOn = React.useCallback(() => {
    dispatch(resetActivePanel());
    dispatch(enableRegionOfInterestMode());
  }, [dispatch]);

  const resetActiveDrilldownPanel = React.useCallback(() => {
    if (activePanel && activePanel === DRILLDOWN_PANEL) {
      dispatch(resetActivePanel());
    }
  }, [activePanel, dispatch]);

  return (
    <ClickOutside
      wrapper={LeftPanels.Container}
      ignoredIds={['region-min', 'region-max']}
      ignoredClassNames={['modal-window-v2']}
      action={resetActiveDrilldownPanel}
    >
      <GlobalHotKeys
        keyMap={keyMap}
        handlers={{
          regionOfInterestModeOn,
        }}
      />

      <TabsProvider
        defaultActive={leftPanel.activeTabId || 'drilldown'}
        onTabChange={leftPanel.setActiveTabId}
      >
        {tabs => (
          <LeftPanels.TabsContainer>
            <TabsHeader tabs={tabs} appliedFilters={filterQuant} />
            <Tab tabName="Menu" id="menu">
              <Menu />
            </Tab>

            <Tab tabName="Drilldown" id="drilldown">
              <DrilldownPanel />
            </Tab>

            <Tab tabName="Notes" id="notes">
              <InboxConversation />
            </Tab>

            <Tab tabName="Wells" id="wells" />
          </LeftPanels.TabsContainer>
        )}
      </TabsProvider>
    </ClickOutside>
  );
};

LeftPanels.Container = styled(Card)`
  width: 100%;
  height: 100%;
  position: relative; ;
`;

LeftPanels.TabsContainer = styled.div`
  height: 100%;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: 1fr;
`;

LeftPanels.InternalContainer = styled.div`
  overflow: hidden;
  margin: 0 8px;
  border-bottom: ${(props: Record<string, any>) =>
    props.varianceTableHeight <= 32 ? '1px solid #c1c1c1' : ''};
`;

export default LeftPanels;
