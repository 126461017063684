import { timeFormat } from 'd3-time-format';
import { format } from 'd3-format';
import React from 'react';
import styled from 'styled-components';

import type { DataSeriesTooltipData } from 'modules/ui/models/ui';

interface InformatioinTooltipProps {
  tooltipData: DataSeriesTooltipData;
}

const DataSeriesInformation = ({ tooltipData }: InformatioinTooltipProps) => {
  const seriesData = tooltipData.data.sort((a, b) => b.value - a.value);

  return (
    <>
      <DataSeriesInformation.Date>
        {timeFormat('%m/%d/%Y %_I:%M %p')(tooltipData.day)}
      </DataSeriesInformation.Date>

      {seriesData.map((series, idx) => (
        <React.Fragment key={series.name + idx}>
          <DataSeriesInformation.SeriesName color={series.color}>
            {`${series.name}:`}
          </DataSeriesInformation.SeriesName>
          <DataSeriesInformation.Value color={series.color}>
            {format(',d')(series.value)}
          </DataSeriesInformation.Value>
        </React.Fragment>
      ))}
    </>
  );
};

DataSeriesInformation.Date = styled.span`
  grid-area: 1 / 1 / 2 / 3;
  text-align: center;
`;

DataSeriesInformation.Value = styled.span`
  text-align: right;
  color: ${props => props.color};
`;
DataSeriesInformation.SeriesName = styled.span`
  color: ${props => props.color};
`;

export default DataSeriesInformation;
