import * as React from 'react';
import styled from 'styled-components';
import {
  TooltipOptionsType,
  NOTES_DEFAULT_HEIGHT,
  NOTE_HEIGHT_WITHOUT_TEXT,
  NOTE_ONE_STRING_HEIGHT,
  MAX_TOOLTIP_HEIGHT,
  MAX_TOOLTIP_WIDTH,
} from '../models/index';
import { EventNote } from 'modules/eventNotes/models';
import { measureText } from '../../filter/utils/text';
import NotesTooltipItem from './NotesTooltipItem';
import textEllipsis from '../helpers/textEllipsis';
import { timeFormat } from 'd3-time-format';
import { useSelector } from '../../../store/models';
import { getUser, getUsersByEmailName } from '../../user/UserReducer';
import getAttachmentsTypes from '../helpers/getAttachmentsTypes';
import { faFileImage, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getNoteText } from './NotesTooltipItem';
import Portal from 'components/Portal';
import { LEFT_PANEL_WIDTH } from 'modules/dashboard/models/dashboard';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import { getToday } from '../../appConfig/AppConfigReducer';
import useDate from '../hooks/useDate';

interface NotesTooltipProps {
  tooltipOptions: TooltipOptionsType;
}

const getTooltipHeightOptions = (
  notes: Record<string, EventNote> | null | undefined,
) => {
  if (!notes) return { height: NOTES_DEFAULT_HEIGHT, endingIndex: 0 };

  const keys = Object.keys(notes);
  const result = {
    prev: NOTES_DEFAULT_HEIGHT,
    height: NOTES_DEFAULT_HEIGHT,
    endingIndex: Object.keys(notes).length,
  };
  for (let index = 0; index < keys.length; index++) {
    const note = notes[keys[index]];
    const noteTextLength = measureText(
      textEllipsis(note.noteText, 160),
      12,
      'Lato',
    );
    if (!note?.noteText?.length) {
      result.prev = NOTE_HEIGHT_WITHOUT_TEXT;
      result.height += NOTE_HEIGHT_WITHOUT_TEXT;
      continue;
    }
    if (noteTextLength > MAX_TOOLTIP_WIDTH) {
      if (
        result.height +
          Math.ceil(noteTextLength / MAX_TOOLTIP_WIDTH) *
            NOTE_ONE_STRING_HEIGHT +
          NOTE_HEIGHT_WITHOUT_TEXT >
        MAX_TOOLTIP_HEIGHT
      ) {
        if (result.height + NOTE_ONE_STRING_HEIGHT > MAX_TOOLTIP_HEIGHT) {
          result.height = result.height - result.prev + NOTE_ONE_STRING_HEIGHT;
          result.endingIndex = index - 1;
          break;
        }
        result.height += NOTE_ONE_STRING_HEIGHT;
        result.endingIndex = index;
        break;
      }
      result.prev =
        Math.ceil(noteTextLength / MAX_TOOLTIP_WIDTH) * NOTE_ONE_STRING_HEIGHT +
        NOTE_HEIGHT_WITHOUT_TEXT;
      result.height +=
        Math.ceil(noteTextLength / MAX_TOOLTIP_WIDTH) * NOTE_ONE_STRING_HEIGHT +
        NOTE_HEIGHT_WITHOUT_TEXT;
      continue;
    }
    if (
      result.height + NOTE_ONE_STRING_HEIGHT + NOTE_HEIGHT_WITHOUT_TEXT >
      MAX_TOOLTIP_HEIGHT
    ) {
      if (result.height + NOTE_ONE_STRING_HEIGHT > MAX_TOOLTIP_HEIGHT) {
        result.height = result.height - result.prev + NOTE_ONE_STRING_HEIGHT;
        result.endingIndex = index - 1;
        break;
      }
      result.height += NOTE_ONE_STRING_HEIGHT;
      result.endingIndex = index;
      break;
    }
    result.prev = NOTE_ONE_STRING_HEIGHT + NOTE_HEIGHT_WITHOUT_TEXT;
    result.height += NOTE_ONE_STRING_HEIGHT + NOTE_HEIGHT_WITHOUT_TEXT;
    continue;
  }
  return result;
};

const NotesTooltip = ({ tooltipOptions }: NotesTooltipProps) => {
  const { type, title, subTitle, notes, externalNote } = tooltipOptions;

  const usersByEmail = useSelector(getUsersByEmailName);

  const isExternal = React.useMemo(() => type === 'external', [type]);

  const tooltipHeightOptions = React.useMemo(
    () =>
      isExternal
        ? {
            height: 0,
            endingIndex: 0,
          }
        : getTooltipHeightOptions(notes),
    [notes],
  );

  const today = useSelector(getToday);
  const formatTime = timeFormat('%H:%M %p');
  const formatDate = timeFormat('%d %b');
  const externalNoteDate = externalNote?.timeStamp
    ? new Date(externalNote.timeStamp)
    : null;

  const { isToday, isYesterday } = useDate(today, externalNoteDate);

  const externalUser = useSelector(state =>
    externalNote?.userId ? getUser(state, externalNote.userId) : null,
  );

  const externalAttachmentTypes = externalNote?.attachments
    ? getAttachmentsTypes(externalNote.attachments)
    : null;

  return (
    <Portal>
      <NotesTooltip.Container
        isExternal={isExternal}
        tooltipHeight={tooltipHeightOptions.height}
        tooltipOptions={tooltipOptions}
        hasHeader={title || subTitle}
      >
        {!isExternal && (
          <NotesTooltip.Header>
            <span className="title">{title}</span>
            {!isExternal && type !== 'variance' && (
              <span className="subTitle">{subTitle}</span>
            )}
          </NotesTooltip.Header>
        )}
        <NotesTooltip.InfoContainer>
          <span>
            {type === 'variance' && 'Variance Event'}
            {type === 'capacity' && 'Capacity Event'}
            {type === 'ribbon' && 'Ribbon event'}
            {isExternal && 'External Note'}
          </span>
          {!isExternal && (
            <span>
              {notes &&
                (Object.keys(notes).length === 1
                  ? '1 Note'
                  : `${Object.keys(notes).length} Notes`)}
            </span>
          )}
        </NotesTooltip.InfoContainer>
        {isExternal ? (
          <NotesTooltip.ExternalNote>
            <div className="header">
              {externalUser && (
                <span className="name">
                  {`${externalUser.firstName} ${externalUser.lastName}`}
                  {externalAttachmentTypes && (
                    <>
                      {externalAttachmentTypes.hasPhotos && (
                        <FontAwesomeIcon
                          icon={faFileImage}
                          color="#909090"
                          className="icon"
                        />
                      )}
                      {externalAttachmentTypes.hasDocs && (
                        <FontAwesomeIcon
                          icon={faPaperclip}
                          color="#909090"
                          className="icon"
                          transform={{ rotate: 90 }}
                        />
                      )}
                    </>
                  )}
                </span>
              )}
              {externalNoteDate && (
                <span className="time">
                  {isYesterday && 'Yesterday'}
                  {isToday && formatTime(externalNoteDate)}
                  {!isToday && !isYesterday && formatDate(externalNoteDate)}
                </span>
              )}
            </div>
            <div className="content">
              {getNoteText(externalNote?.noteText || '', usersByEmail)}
            </div>
          </NotesTooltip.ExternalNote>
        ) : (
          <ul>
            {notes &&
              Object.keys(notes)
                .slice(0, tooltipHeightOptions.endingIndex)
                .map(key => {
                  const note = notes[key];

                  return <NotesTooltipItem key={key} note={note} />;
                })}
          </ul>
        )}
        {notes &&
          !isExternal &&
          Object.keys(notes).length - 1 > tooltipHeightOptions.endingIndex && (
            <NotesTooltip.Footer>
              +{Object.keys(notes).length - tooltipHeightOptions.endingIndex}{' '}
              Notes...
            </NotesTooltip.Footer>
          )}
      </NotesTooltip.Container>
    </Portal>
  );
};

NotesTooltip.Container = styled.div`
  padding: ${props => (props.hasHeader ? '6px 12px 10px' : '0 12px 10px')};
  z-index: 1500;
  position: absolute;
  left: ${props => props.tooltipOptions.x + LEFT_PANEL_WIDTH + Y_AXIS_WIDTH}px;
  top: ${props => props.tooltipOptions.y + 48}px;
  overflow: hidden;
  width: 321px;
  height: ${props =>
    props.isExternal
      ? 'fit-content'
      : `${props.tooltipHeight > 576 ? 576 : props.tooltipHeight}px`};
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.15), 0 0 12px 0 rgba(0, 0, 0, 0.07);
`;

NotesTooltip.Header = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 6px;

  .title {
    margin-right: 8px;
  }

  .subTitle {
    color: #909090;
  }
`;

NotesTooltip.InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  height: 24px;
  align-items: center;
  width: calc(100% + 24px);
  margin-left: -12px;
  background-color: #f5f5f5;
  color: #909090;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 8px;
`;

NotesTooltip.ExternalNote = styled.div`
  overflow-wrap: break-word;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 16px;
    margin-bottom: 8px;
  }

  .name {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
  }

  .time {
    color: #909090;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: right;
  }

  .content {
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  .icon {
    margin-left: 4px;
    transform: translateY(-3px);
    width: 12px;
    height: 12px;
  }
`;

NotesTooltip.Footer = styled.div`
  color: #909090;
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;

export default NotesTooltip;
