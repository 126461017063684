import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore, Action } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import LogRocket from 'logrocket';

import graphqlClient from 'graphql-middleware/client';
import graphqlMiddleware from 'graphql-middleware/middlewares/graphqlMiddleware';

import history from 'modules/router/utils/history';
import Metrics from 'modules/metrics/services/Metrics';
import { SentryPluginReduxEnhancer } from 'modules/metrics/services/plugins/SentryPlugin';

import metricsAuthMiddleware from 'modules/metrics/middleware/metricsAuthMiddleware';
import metricsEventsMiddleware from 'modules/metrics/middleware/metricsEventsMiddleware';

import loggerMiddleware from './middlewares/loggerMiddleware';
import promiseMiddleware from './middlewares/promiseMiddleware';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import Env from 'helpers/Env';

const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    Metrics.captureError({ originalError: error, location: 'saga' });
  },
});

const configureStore = (initialState: Record<string, any> = {}) => {
  const middlewares = [
    routerMiddleware(history),
    graphqlMiddleware(graphqlClient),
    thunk,
    promiseMiddleware,
    metricsAuthMiddleware,
    metricsEventsMiddleware,
    sagaMiddleware,
  ];

  if (typeof jest === 'undefined' && !Env.isProd()) {
    middlewares.push(loggerMiddleware);
  }

  if (typeof jest === 'undefined') {
    middlewares.push(LogRocket.reduxMiddleware());
  }

  const store = createStore(
    rootReducer(history),
    initialState,

    // compose is needed here because of how Sentry redux enhancer works
    // see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
    compose(applyMiddleware(...middlewares), SentryPluginReduxEnhancer),
  );
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer: {
        (state: { [x: string]: any } | undefined, action: Action<any>);
      } = require('./rootReducer').default; // eslint-disable-line
      store.replaceReducer(nextRootReducer);
    });
  }

  return { history, persistor, store };
};

export default configureStore;
