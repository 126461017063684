import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import type { Action, RootState } from 'store/models';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';

import {
  ADD_EVENT_NOTE,
  namespace,
  POPULATE_EVENT_NOTES,
  CREATE_EVENT_NOTE_REMOTELY,
  REMOVE_EVENT_NOTE_LOCALLY,
  UPDATE_EVENT_NOTE_LOCALLY,
  UPDATE_EVENT_NOTE_REMOTELY,
  REMOVE_EVENT_NOTE_ATTACHMENT,
} from './EventNotesActions';

import { EventNote, WellEventNotes } from './models';
import { Reducer } from 'redux';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'eventNotes';

export interface EventNotesState {
  [wellId: string]: WellEventNotes;
}

const initialState = {};

const EventNotesReducer = (
  state: EventNotesState = initialState,
  action: Action,
) => {
  const { payload } = action;
  switch (action.type) {
    case POPULATE_EVENT_NOTES: {
      return R.assoc(payload.wellId, payload.notes, state);
    }
    case ADD_EVENT_NOTE: {
      const {
        wellId,
        note: { eventType, eventId, id, noteTimestamp },
      } = payload;
      const oldTempNotes = R.pathOr(
        [],
        ['tempNotes', eventType, eventId],
        state,
      );
      return R.compose(
        R.assocPath([wellId, eventType, eventId, id], payload.note),
        R.assocPath(
          ['tempNotes', eventType, eventId],
          [...oldTempNotes, { localId: id, wellId, noteTimestamp }],
        ),
      )(state);
    }
    case `${CREATE_EVENT_NOTE_REMOTELY}_SUCCESS`: {
      const payload = getGraphqlPayload(action);
      const {
        payload: { id: oldId },
      } = getGraphqlPrevActionVariables(action);
      const noteTimestamp = new Date(payload.noteTimestamp);
      const tempNotes = R.pathOr(
        [],
        ['tempNotes', payload.eventType, payload.eventId],
        state,
      );
      const originNote = tempNotes.find(note => note.localId === oldId);
      if (!originNote) {
        return state;
      }
      return R.compose(
        R.dissocPath([
          originNote.wellId,
          payload.eventType,
          payload.eventId,
          originNote.localId,
        ]),
        R.assocPath(
          [originNote.wellId, payload.eventType, payload.eventId, payload.id],
          { ...payload, noteTimestamp },
        ),
      )(state);
    }

    case REMOVE_EVENT_NOTE_LOCALLY: {
      const { wellId, eventType, eventId, id } = payload;

      return R.dissocPath([wellId, eventType, eventId, id], state);
    }

    case UPDATE_EVENT_NOTE_LOCALLY: {
      const { wellId, note } = payload;
      const oldTempNotes = R.pathOr(
        [],
        ['tempNotes', note.eventType, note.eventId],
        state,
      );
      const oldAttachments = R.pathOr(
        [],
        [wellId, note.eventType, note.eventId, note.id, 'attachments'],
        state,
      );
      const updatedNote = {
        ...note,
        attachments: [...oldAttachments].concat(R.values(note.attachments)),
      };
      const oldNoteText = R.pathOr(
        '',
        [
          wellId,
          updatedNote.eventType,
          updatedNote.eventId,
          updatedNote.id,
          'noteText',
        ],
        state,
      );
      return R.compose(
        R.assocPath(
          [wellId, updatedNote.eventType, updatedNote.eventId, updatedNote.id],
          updatedNote,
        ),
        R.assocPath(
          ['tempNotes', updatedNote.eventType, updatedNote.eventId],
          [
            ...oldTempNotes,
            {
              localId: updatedNote.id,
              wellId,
              noteTimestamp: updatedNote.noteTimestamp,
              oldNoteText,
            },
          ],
        ),
      )(state);
    }
    case `${UPDATE_EVENT_NOTE_REMOTELY}_SUCCESS`: {
      const payload = getGraphqlPayload(action);

      const tempNotes = R.pathOr(
        [],
        ['tempNotes', payload.eventType, payload.eventId],
        state,
      );
      const originNote = tempNotes.find(note => note.localId - payload.id);
      if (!originNote) {
        return state;
      }
      const noteTimestamp = new Date(payload.noteTimestamp);

      return R.assocPath(
        [originNote.wellId, payload.eventType, payload.eventId, payload.id],
        { ...payload, noteTimestamp },
        state,
      );
    }
    case REMOVE_EVENT_NOTE_ATTACHMENT: {
      const { wellId, eventType, eventId, noteId, fileId } = payload;
      const oldAttacments = R.pathOr(
        [],
        [wellId, eventType, eventId, noteId, 'attachments'],
        state,
      );

      const filteredoldAttacments = oldAttacments.filter(
        file => file.id !== fileId,
      );
      return R.assocPath(
        [wellId, eventType, eventId, noteId, 'attachments'],
        filteredoldAttacments,
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getAllEventNotes = (state: RootState): EventNotesState =>
  state[STATE_KEY];

export const getWellEventNotes = createSelector(
  getAllEventNotes,
  (_: RootState, props) => props.wellId,
  (notes, wellId): WellEventNotes => {
    return notes[wellId] || {};
  },
);

export const getEventNotes = createSelector(
  getWellEventNotes,
  (_, props) => props.eventType,
  (
    wellNotes: WellEventNotes,
    eventType: string,
  ): {
    [key: string]: EventNote;
  } => {
    return R.pathOr({}, [eventType], wellNotes);
  },
);

export const getOriginNoteText = createSelector(
  getAllEventNotes,
  (_, props) => props,
  (wellNotes, props): string => {
    const { eventType, eventId, id } = props;
    const tempNotes = R.pathOr(
      [],
      ['tempNotes', eventType, eventId],
      wellNotes,
    );
    const note = tempNotes.find(note => note.localId === id);
    return R.pathOr('', ['oldNoteText'], note);
  },
);

export const getLastWellEventsNotes = createSelector(
  getAllEventNotes,
  (_, props) => props,
  (
    wellNotes,
    props: { wellId: string; eventType: string },
  ): Record<string, Record<string, EventNote>> => {
    const { eventType, wellId } = props;
    const eventsNote = R.pathOr({}, [wellId, eventType], wellNotes);
    return eventsNote;
  },
);

export default filterActions(EventNotesReducer, action =>
  action.type.match(filterRegExp),
) as Reducer<EventNotesState>;
