import { useMemo } from 'react';
import { utcDay } from 'd3-time';

export default function useDate(today: Date, date: Date | null) {
  const isToday = useMemo(() => {
    if (!date) return false;
    return today.toDateString() === date.toDateString();
  }, [today, date]);

  const isYesterday = useMemo(() => {
    if (!date) return false;
    return utcDay.offset(today, -1).toDateString() === date.toDateString();
  }, [today, date]);

  return { isToday, isYesterday };
}
