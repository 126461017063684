import { format } from 'd3-format';
import * as R from 'ramda';
import type { Well } from 'modules/well/models/well';
import { findClosest } from 'helpers';
import { LayoutOption } from 'modules/filterLayouts/models';
import { measureText } from './text';
import ArrayBoost from 'helpers/arrayBoost';

type SortedSearchedItems = {
  matched?: number[];
  value: string;
  quantity: number;
}[];
type SortResult = { value: string; quantity: number }[];

export const countFilterValues = (
  wells: Well[],
  filterName: string,
): { value: string; quantity: number }[] => {
  const quantities = wells.reduce((acc, well) => {
    const key = well[filterName] ? well[filterName] : '';
    acc[key] = acc[key] ? acc[key] + 1 : 1;

    return acc;
  }, {});

  return Object.keys(quantities).map(value => ({
    value: value === 'null' ? '' : value,
    quantity: quantities[value],
  }));
};

export const countFilterValuesOptimized = (
  wells: Well[],
  filterName: string,
): { value: string; quantity: number }[] => {
  const quantities = ArrayBoost.reduce<Well, any>(
    wells,
    (acc, well) => {
      const key = well[filterName] ? well[filterName] : '';
      acc[key] = acc[key] ? acc[key] + 1 : 1;

      return acc;
    },
    {},
  );

  return ArrayBoost.map<string, any>(Object.keys(quantities), value => ({
    value: value === 'null' ? '' : value,
    quantity: quantities[value],
  }));
};

const isNotEmpty = (val, key) => !R.isEmpty(val);

export const filterWells = (
  wells: Well[],
  filters: { [filterName: string]: string[] },
): Well[] => {
  const normalizedFilters = R.pickBy(isNotEmpty, filters);
  return wells.filter(well => {
    return Object.keys(normalizedFilters).every(filterName => {
      return normalizedFilters[filterName].some(filterValue => {
        const value = filterValue === 'null' ? null : filterValue;
        return value === ''
          ? value === well[filterName] ||
              well[filterName] === null ||
              well[filterName] === undefined
          : value === well[filterName];
      });
    });
  });
};

export const filterWellsOptimized = (
  wells: Well[],
  filters: { [filterName: string]: string[] },
): Well[] => {
  const normalizedFilters = R.pickBy(isNotEmpty, filters);
  return ArrayBoost.filter<Well>(wells, well => {
    return Object.keys(normalizedFilters).every(filterName => {
      return normalizedFilters[filterName].some(filterValue => {
        const value = filterValue === 'null' ? null : filterValue;
        return value === ''
          ? value === well[filterName] ||
              well[filterName] === null ||
              well[filterName] === undefined
          : value === well[filterName];
      });
    });
  });
};

export const getListOfFilterValues = ({
  lastHighlighted,
  index,
  highlightedIndexes,
  formattedItems,
  firstColDataKey,
}: {
  lastHighlighted: number | null;
  index: number;
  highlightedIndexes: number[];
  formattedItems: Record<string, any>[];
  firstColDataKey: string;
}): string[] => {
  const selectionFrom =
    lastHighlighted || findClosest(index, highlightedIndexes) || 0;

  return formattedItems
    .slice(Math.min(index, selectionFrom), Math.max(index, selectionFrom) + 1)
    .map(data => data[firstColDataKey]);
};

const sort = (
  arr: { value: string; quantity: number }[],
  sortBy: string,
  sortDirection: string,
): SortResult | undefined => {
  if (sortBy === 'value') {
    return arr.sort((a, b) => {
      if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
        return sortDirection === 'ASC' ? -1 : 1;
      } else if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
        return sortDirection === 'ASC' ? 1 : -1;
      }
      return 0;
    });
  } else if (sortBy === 'quantity') {
    return arr.sort((a, b) =>
      sortDirection === 'ASC' ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy],
    );
  }
};

export const getSortedSearchedItems = ({
  countedValues,
  searchWord,
  secondColDataKey,
  sortBy,
  sortDirection,
}: {
  countedValues: { value: string; quantity: number }[];
  searchWord: string;
  secondColDataKey: string;
  sortBy: string;
  sortDirection: string;
}): SortedSearchedItems => {
  if (searchWord) {
    const searchWordInLowerCase = searchWord.toLowerCase();
    const searchResult = countedValues.filter(tableItem =>
      tableItem.value.toLowerCase().includes(searchWordInLowerCase),
    );
    const normMatchedItems = searchResult.map(tableItem => ({
      ...tableItem,
      matched: [
        tableItem.value.toLowerCase().indexOf(searchWordInLowerCase),
        tableItem.value.toLowerCase().indexOf(searchWordInLowerCase) +
          searchWord.length,
      ],
    }));

    return (
      sort(normMatchedItems, sortBy, sortDirection) as SortedSearchedItems
    ).map(data =>
      R.assoc(secondColDataKey, format(',d')(data[secondColDataKey]), data),
    );
  }

  return (
    sort(countedValues, sortBy, sortDirection) as SortedSearchedItems
  ).map(data =>
    R.assoc(secondColDataKey, format(',d')(data[secondColDataKey]), data),
  );
};

export const getSortTableViewWells = ({
  wells,
  sortBy,
  sortDirection,
}: {
  wells: Well[];
  sortBy: string;
  sortDirection: string;
}): Well[] => {
  if (sortDirection === 'ASC') {
    return wells.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
  }
  return wells.sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : -1));
};

export const getInitialCountColumnWidths = (
  wells: Well[],
  options: LayoutOption[],
) =>
  options.reduce((acc, opt) => {
    const countedValues = countFilterValues(
      filterWells(wells, {}),
      opt.filterName,
    ).map(v => v.quantity);
    const max = Math.max(...countedValues);
    acc[opt.filterName] = measureText(max.toString());
    return acc;
  }, {});

export const getInitialCountColumnWidthsOptimized = (
  wells: Well[],
  options: LayoutOption[],
) =>
  options.reduce((acc, opt) => {
    const countedValues = ArrayBoost.map(
      countFilterValuesOptimized(
        filterWellsOptimized(wells, {}),
        opt.filterName,
      ),
      v => v.quantity,
    );
    const max = Math.max(...countedValues);
    acc[opt.filterName] = measureText(max.toString());
    return acc;
  }, {});
