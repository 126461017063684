import * as R from 'ramda';
import { createSelector } from 'reselect';
import { filterActions } from 'redux-ignore';

import { getGraphqlPayload } from 'store/helpers';
import type { Action, RootState, Selector } from 'store/models';
import type { UserPermissions, UserInfo } from 'modules/user/models';

import { buildAuthData } from './utils';
import {
  FETCH_AUTH_CONFIG,
  FETCH_USER_PERMISSIONS,
  LOGIN,
  LOGIN_VIA_SSO,
  LOGOUT,
  namespace,
  REGISTER,
  UPDATE_TOKEN,
  ACCEPT_POLICIES,
} from './AuthActions';
import { AuthConfig } from './models/auth';
import { Reducer } from 'redux';
import { getFlagsStateMap } from '../featureFlags/FeatureFlagsReducer';

export const STATE_KEY = 'auth';
const authRegexp = new RegExp(`${namespace}/`);

export type AuthState = {
  authConfig: AuthConfig | null;
  id: string | null;
  token: string | null;
  isAuthenticated: boolean;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  loginTime: Date | null;
  isFreshLogin?: boolean;
  isAllowedEditCapChanges: boolean;
  isAllowedEditVarEvents: boolean;
  isAllowedEditAllocIssues: boolean;
  isAllowedEditRibbonEvents: boolean;
  isAllowedViewForecast: boolean;
  isAllowedViewAllSpotfireDashboards: boolean;
  acceptedPolicies: boolean;
  tokenCreationTime: Date | null;
  userUuid: string | null;
  isAdmin: boolean;
  isUserManager: boolean;
  isAllowedViewDashboards: boolean;
};

export const initialState: AuthState = {
  authConfig: null,
  id: null,
  token: null,
  isAuthenticated: false,
  email: null,
  firstName: null,
  lastName: null,
  loginTime: null,
  isAllowedEditCapChanges: false,
  isAllowedEditVarEvents: false,
  isAllowedEditAllocIssues: false,
  isAllowedEditRibbonEvents: false,
  isAllowedViewDashboards: false,
  isAllowedViewForecast: false,
  isAllowedViewAllSpotfireDashboards: false,
  acceptedPolicies: false,
  tokenCreationTime: null,
  userUuid: null,
  isUserManager: false,
  isAdmin: false,
};

const AuthReducer = (state: AuthState = initialState, action: Action) => {
  switch (action.type) {
    case `${LOGIN}_SUCCESS`: {
      const user = getGraphqlPayload(action);
      if (!user.user || !user.token) return state;
      const authData = buildAuthData(user);
      if (authData.inactive) return initialState;
      return R.mergeDeepRight(state, authData);
    }
    case `${LOGIN_VIA_SSO}_SUCCESS`:
    case `${REGISTER}_SUCCESS`: {
      const user = getGraphqlPayload(action);
      const authData = buildAuthData(user);
      if (authData.inactive) return initialState;
      return R.mergeDeepRight(state, authData);
    }
    case `${FETCH_AUTH_CONFIG}_SUCCESS`: {
      const data = getGraphqlPayload(action);
      return R.assoc('authConfig', data, state);
    }
    case `${FETCH_USER_PERMISSIONS}_SUCCESS`: {
      const data = getGraphqlPayload(action);
      return { ...state, ...data };
    }
    case `${ACCEPT_POLICIES}_SUCCESS`: {
      const { acceptedPolicies } = getGraphqlPayload(action);
      return { ...state, acceptedPolicies };
    }
    case LOGOUT: {
      return { ...initialState, email: state.email };
    }
    case `${UPDATE_TOKEN}_SUCCESS`: {
      return R.assoc<Date, AuthState>('tokenCreationTime', new Date(), state);
    }
    default: {
      return state;
    }
  }
};

export const getAllInfo = (state: RootState) => state[STATE_KEY] as AuthState;
export const getAuthConfig = (state: RootState) =>
  state[STATE_KEY].authConfig as AuthConfig | null;
export const getId = R.path<string | null>([STATE_KEY, 'id']);
export const getAcceptedPolicies = R.path<string>([
  STATE_KEY,
  'acceptedPolicies',
]);
export const getFullName = (_: RootState) => {
  const firstName = R.path([STATE_KEY, 'firstName'], _);
  const lastName = R.path([STATE_KEY, 'lastName'], _);

  return firstName + ' ' + lastName;
};
export const getToken = R.path<string>([STATE_KEY, 'token']);
export const getIsFreshLogin = R.path<boolean>([STATE_KEY, 'isFreshLogin']);
export const getIsAuthenticated = R.path<boolean>([
  STATE_KEY,
  'isAuthenticated',
]);
export const getEmail = R.path<string>([STATE_KEY, 'email']);
export const getIsRemember = R.path<boolean>([STATE_KEY, 'isRemember']);
export const getLoginTime = R.path<string>([STATE_KEY, 'loginTime']);
export const getIsAdmin = R.path<boolean>([STATE_KEY, 'isAdmin']);
export const getIsUserManager = R.path<boolean>([STATE_KEY, 'isUserManager']);
export const getInfoForHelpRequest = createSelector(
  getAllInfo,
  info =>
    ({
      fullname: `${info.firstName} ${info.lastName}`,
      email: info.email,
    } as { fullname: string; email: string }),
);

export type AuthPermissions = ReturnType<typeof getPermissions>;
export const getPermissions = createSelector(
  getAllInfo,
  getFlagsStateMap,
  (info, flags) => ({
    isAllowedEditCapChanges: info.isAllowedEditCapChanges,
    isAllowedEditVarEvents: info.isAllowedEditVarEvents,
    isAllowedEditAllocIssues: info.isAllowedEditAllocIssues,
    isAllowedEditRibbonEvents: info.isAllowedEditRibbonEvents,
    isAllowedViewForecast: info.isAllowedViewForecast,
    isAllowedViewDashboards: flags.dashboardPermissions
      ? info.isAllowedViewDashboards
      : true,
    isAdmin: info.isAdmin,
    isUserManager: info.isUserManager,
  }),
);

export const getTokenCreationTime = R.path<Date | null>([
  STATE_KEY,
  'tokenCreationTime',
]);

export const getCurrentUserInfo: Selector<Partial<UserInfo>> = createSelector(
  getAllInfo,
  authState => {
    return {
      email: authState.email ?? undefined,
      firstName: authState.firstName ?? undefined,
      lastName: authState.lastName ?? undefined,

      isAllowedEditCapChanges: authState.isAllowedEditCapChanges,
      isAllowedEditVarEvents: authState.isAllowedEditVarEvents,
      isAllowedEditAllocIssues: authState.isAllowedEditAllocIssues,
      isAllowedEditRibbonEvents: authState.isAllowedEditRibbonEvents,
      isAllowedViewDashboards: authState.isAllowedViewDashboards,
      isAllowedViewForecast: authState.isAllowedViewForecast,
      acceptedPolicies: authState.acceptedPolicies,

      isUserManager: authState.isUserManager,
      isAdmin: authState.isAdmin,
    };
  },
);

export const getCurrentUserPermissions: Selector<UserPermissions> =
  createSelector(getCurrentUserInfo, getFlagsStateMap, (info, flags) => {
    return {
      canEditCapacityChangeEvents: Boolean(info.isAllowedEditCapChanges),
      canEditVarianceEvents: Boolean(info.isAllowedEditVarEvents),
      canEditRibbonEvents: Boolean(info.isAllowedEditRibbonEvents),
      canEditEditAlocIssueEvents: Boolean(info.isAllowedEditAllocIssues),
      canViewForecast: Boolean(info.isAllowedViewForecast),
      canViewDashboards: Boolean(
        flags.dashboardPermissions ? info.isAllowedViewDashboards : true,
      ),
      canViewSpotfireDashboards: Boolean(flags.spotfireDashboards),
      canViewHiddenSpotfireDashboards: Boolean(
        info.isAdmin || info.isAllowedViewAllSpotfireDashboards,
      ),
    };
  });

export default filterActions(AuthReducer as any, action =>
  action.type.match(authRegexp),
) as Reducer<AuthState>;
