import * as React from 'react';
import { useSelector } from 'store/models';
import styled from 'styled-components';

import GroupMenu from 'modules/drilldownTable/components/GroupMenu';
import FilterMenu from 'modules/drilldownTable/components/FilterMenu';
import type { Group } from 'modules/well/models/well';
import type { Mode } from 'modules/ui/models/ui';
import { getFlagsStateMap } from 'modules/featureFlags/FeatureFlagsReducer';

import SearchForm from 'components/SearchForm';
import WellDrilldownTableColumns from '../components/WellDrilldownTableColumns';
import {
  CommonTotalRow,
  DrilldownTable,
  DrilldownTableParams,
  VarianceDrilldownTableItem,
} from '../models/drilldownTable';
import {
  comparisonAttributes,
  drilldownAttributes,
  showHidePercentageAttributes,
} from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';
import WellDrilldownCustomTableColumns from './WellDrilldownCustomTableColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent } from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/Button';
import { toggleVariancePercentage } from '../DrilldownTableActions';
import { useDispatch } from 'react-redux';

interface WellDrilldownTableProps {
  compareOption: string;
  currentGroup: Record<string, never> | { subject: string; item: string };
  currentTable: string | null;
  currentWellId: string;
  currentDrilldownTableState: DrilldownTableParams;
  groupsByKey: { [key: string]: Group };
  groupsByOrder: Group[];
  groupMode: Mode;
  groupedTable: VarianceDrilldownTableItem[] | undefined;
  groupForecastTable: VarianceDrilldownTableItem[] | undefined;
  filterToCurrentItem: () => void;
  handleFilterGroupSelection: (group: MouseEvent) => void;
  handleGroupSelection: (group: MouseEvent) => void;
  hasNri: boolean;
  height: number;
  isTableLoading: boolean;
  isCustomTableLoading: boolean;
  onSetCompareOption: (optionKey: string) => void;
  onWellTableRowChoose: (rowData: { [key: string]: string | number }) => void;
  searchWord: string;
  setCurrentTable: (table: string) => void;
  setSearchWord: (searchWord: string) => void;
  setSorting: (sortData: { sortBy: string; sortDirection: string }) => void;
  sortCriteria: string;
  sortDirection: string;
  sortVarDirectionIndex: number;
  sortVarPercentDirectionIndex: number;
  totalRow: CommonTotalRow;
  volumeType: boolean;
  wellDrilldownTable: DrilldownTable[];
  wellForecastTable: DrilldownTable[];
  hasViewForecastPermission: boolean;
  isCustomLayout: boolean;
}

const compareTitles = {
  actual: 'Capacity vs Actual',
  extVsCap: 'RE Fcst vs Capacity',
  actVsExt: 'RE Fcst vs Actual',
};

const WellDrilldownTable = ({
  compareOption,
  currentDrilldownTableState,
  currentGroup,
  currentTable,
  currentWellId,
  filterToCurrentItem,
  groupedTable,
  groupForecastTable,
  groupMode,
  groupsByKey,
  groupsByOrder,
  handleFilterGroupSelection,
  handleGroupSelection,
  hasNri,
  height,
  isTableLoading,
  onSetCompareOption,
  isCustomTableLoading,
  onWellTableRowChoose,
  searchWord,
  setCurrentTable,
  setSearchWord,
  setSorting,
  sortCriteria,
  sortDirection,
  sortVarDirectionIndex,
  sortVarPercentDirectionIndex,
  totalRow,
  volumeType,
  wellDrilldownTable,
  wellForecastTable,
  hasViewForecastPermission,
  isCustomLayout,
}: WellDrilldownTableProps) => {
  const flagsState: ReturnType<typeof getFlagsStateMap> =
    useSelector(getFlagsStateMap);

  const dispatch = useDispatch();

  const titles = React.useMemo(
    () =>
      Object.keys(compareTitles)
        .filter(
          optionTitle => hasViewForecastPermission || optionTitle === 'actual',
        )
        .map(optionTitle => {
          return (
            <option
              key={optionTitle}
              value={optionTitle}
              disabled={optionTitle === 'net' && !hasNri}
            >
              {compareTitles[optionTitle]}
            </option>
          );
        }),
    [compareTitles, hasViewForecastPermission, hasNri],
  );

  return (
    <WellDrilldownTable.Container
      style={{ height: 5 + height + 'px' }}
      onClick={() => setCurrentTable('WellDrilldownTable')}
    >
      <WellDrilldownTable.MenuWrapper>
        {flagsState.drilldownGrouping && (
          <GroupMenu
            groupsByOrder={groupsByOrder}
            handleGroupSelection={handleGroupSelection}
            groupMode={groupMode}
          />
        )}

        {!flagsState.customDrilldown && (
          <>
            <WellDrilldownTable.SearchFormContainer>
              <SearchForm
                placeholder="Search"
                inputHandler={setSearchWord}
                value={searchWord}
              />
            </WellDrilldownTable.SearchFormContainer>
            {flagsState.drilldownCompraring && (
              <ButtonTooltip content={comparisonAttributes}>
                <WellDrilldownTable.CompareSelectContainer>
                  <select
                    id="compare"
                    name="compare"
                    value={currentDrilldownTableState.compareOption}
                    style={{
                      width: '100%',
                      cursor: 'pointer',
                      height: '26px',
                      paddingLeft: '2px',
                    }}
                    onChange={e => {
                      onSetCompareOption(e.target.value);
                      e.target.blur();
                    }}
                  >
                    {titles}
                  </select>
                </WellDrilldownTable.CompareSelectContainer>
              </ButtonTooltip>
            )}
          </>
        )}

        {flagsState.drilldownGrouping && (
          <ButtonTooltip content={drilldownAttributes}>
            <div>
              <FilterMenu
                disabled={currentGroup.subject === 'all'}
                groupsByOrder={groupsByOrder}
                handleFilterGroupSelection={handleFilterGroupSelection}
                filterToCurrentItem={filterToCurrentItem}
              />
            </div>
          </ButtonTooltip>
        )}

        {!isCustomLayout && (
          <ButtonTooltip content={showHidePercentageAttributes}>
            <Button
              width={30}
              height={26}
              onClick={() => {
                dispatch(toggleVariancePercentage());
              }}
            >
              <FontAwesomeIcon icon={faPercent} />
            </Button>
          </ButtonTooltip>
        )}

        {flagsState.customDrilldown && (
          <>
            <WellDrilldownTable.SearchFormContainer
              isCustomLayout={isCustomLayout}
            >
              <SearchForm
                placeholder="Search"
                inputHandler={setSearchWord}
                value={searchWord}
              />
            </WellDrilldownTable.SearchFormContainer>
          </>
        )}
      </WellDrilldownTable.MenuWrapper>

      {isCustomLayout ? (
        <WellDrilldownCustomTableColumns
          compareOption={compareOption}
          currentGroup={currentGroup}
          currentTable={currentTable}
          currentWellId={currentWellId}
          groupForecastTable={groupForecastTable}
          groupsByKey={groupsByKey}
          drilldownTable={wellDrilldownTable}
          groupedTable={groupedTable}
          groupMode={groupMode}
          isLoading={isTableLoading}
          isCustomTableLoading={isCustomTableLoading}
          onChoose={onWellTableRowChoose}
          searchWord={searchWord}
          setSorting={setSorting}
          sortCriteria={sortCriteria}
          sortDirection={sortDirection}
          sortVarDirectionIndex={sortVarDirectionIndex}
          totalRow={totalRow}
          tableHeight={height}
          volumeType={volumeType}
          wellForecastTable={wellForecastTable}
        />
      ) : (
        <WellDrilldownTableColumns
          compareOption={compareOption}
          currentGroup={currentGroup}
          currentTable={currentTable}
          currentWellId={currentWellId}
          groupForecastTable={groupForecastTable}
          groupsByKey={groupsByKey}
          drilldownTable={wellDrilldownTable}
          groupedTable={groupedTable}
          groupMode={groupMode}
          isLoading={isTableLoading}
          onChoose={onWellTableRowChoose}
          searchWord={searchWord}
          setSorting={setSorting}
          sortCriteria={sortCriteria}
          sortDirection={sortDirection}
          sortVarDirectionIndex={sortVarDirectionIndex}
          sortVarPercentDirectionIndex={sortVarPercentDirectionIndex}
          totalRow={totalRow}
          tableHeight={height}
          volumeType={volumeType}
          wellForecastTable={wellForecastTable}
        />
      )}
    </WellDrilldownTable.Container>
  );
};

WellDrilldownTable.Container = styled.div`
  margin-bottom: 8px;
`;

WellDrilldownTable.MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

WellDrilldownTable.SearchFormContainer = styled.div`
  width: ${props => (props.isCustomLayout ? '193px' : '163px')};

  input {
    width: 100%;
    height: 26px;
  }
`;

WellDrilldownTable.CompareSelectContainer = styled.div`
  flex: 1;
`;

WellDrilldownTable.MenuItemLabel = styled.div`
  width: 200px;
  margin-left: 10px;
  padding: 10px 0;
`;

export default WellDrilldownTable;
