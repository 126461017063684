import { ProductionPoint } from '../../production/models/production';
import { ForecastData } from '../../externalForecast/models';
import { sliceArrayByExtremeDates } from '../../../helpers/transformers/sliceArrayByExtremeDates';
import { utcDay } from 'd3-time';
import { max } from 'd3-array';
import { approximatedSearchByDate } from '../../../helpers/approximatedSearchByDate';

type CapacityData = { date: Date; capacity: number }[];

export default function getMaxDataPoints(
  valueDataset: ProductionPoint[] = [],
  productionKey: string,
  capacityDataset: CapacityData[],
  extremeDates: { min: Date; max: Date },
  forecastData: ForecastData,
  phase: string,
  firstSeriesPillDate: Date,
) {
  const productionNew = sliceArrayByExtremeDates(
    valueDataset,
    utcDay.offset(extremeDates.min, 1),
    utcDay.offset(extremeDates.max, 1),
    (date, e) => utcDay.floor(date).getTime() - utcDay.floor(e.day).getTime(),
  );

  // Production max data point calculation
  const minPillIndex = approximatedSearchByDate(
    productionNew,
    utcDay.offset(firstSeriesPillDate, 1),
    (date, e) => date.getTime() - e.day.getTime(),
  );

  const globalProductionValues: any[] = [];
  const pillProductionValues: any[] = [];

  productionNew.forEach((el, idx) => {
    const productionValue = el[productionKey] ?? 0;
    if (idx >= minPillIndex) {
      pillProductionValues.push(productionValue);
    } else {
      globalProductionValues.push(productionValue);
    }
  });

  // Forecast max data point calculation
  const forecastNew = sliceArrayByExtremeDates(
    forecastData,
    utcDay.offset(extremeDates.min, 1),
    utcDay.offset(extremeDates.max, 1),
    (date, e) => utcDay.floor(date).getTime() - utcDay.floor(e.day).getTime(),
  );

  const globalForecastValues: any[] = [];
  const pillForecastValues: any[] = [];

  forecastNew.forEach((el, idx) => {
    const forecastValue = el[phase] ?? 0;
    if (idx >= minPillIndex) {
      pillForecastValues.push(forecastValue);
    } else {
      globalForecastValues.push(forecastValue);
    }
  });

  // Capacity max data point calculation
  const sortedCapacity = [...capacityDataset].sort(
    (a, b) => a[0]?.date.getTime() || 0 - b[0]?.date.getTime() || 0,
  );

  const tempArr = ([] as { date: Date; capacity: number }[]).concat(
    ...sortedCapacity.reverse(),
  );

  const capacityNewFullData = sliceArrayByExtremeDates(
    tempArr,
    extremeDates.min,
    utcDay.offset(extremeDates.max, 1),
    (date, e) => utcDay.floor(date).getTime() - utcDay.floor(e.date).getTime(),
  );

  const minPillCapacityIndex = approximatedSearchByDate(
    capacityNewFullData,
    utcDay.offset(firstSeriesPillDate, 1),
    (date, e) => date.getTime() - e.date.getTime(),
  );

  const capacityNew = capacityNewFullData.map(_ => _.capacity);

  const globalCapacityValues: any[] = [];
  const pillCapacityValues: any[] = [];

  capacityNew.forEach((el, idx) => {
    if (idx >= minPillCapacityIndex) {
      pillCapacityValues.push(el);
    } else {
      globalCapacityValues.push(el);
    }
  });

  // Total max data points calculation
  const pillResultArr = pillProductionValues.concat(
    pillCapacityValues.concat(pillForecastValues),
  );
  const maxPillValue = max(pillResultArr) || 0;
  const globalResultArr = globalProductionValues.concat(
    globalCapacityValues.concat(globalForecastValues),
  );
  const maxGlobalResultValue = max(globalResultArr) || 0;

  return {
    global: Math.max(maxGlobalResultValue, maxPillValue) * 1.05 || 1000,
    pill: maxPillValue,
  };
}
