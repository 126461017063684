import type { Action } from 'store/models';

export const namespace = 'FILTER';
export const ADD_FILTER = `${namespace}/ADD_FILTER`;
export const ADD_SEVERAL_FILTERS = `${namespace}/ADD_SEVERAL_FILTERS`;
export const REMOVE_FILTER = `${namespace}/REMOVE_FILTER`;
export const REMOVE_FILTERS = `${namespace}/REMOVE_FILTERS`;
export const REMOVE_FILTER_COMPLETELY = `${namespace}/REMOVE_FILTER_COMPLETELY`;
export const REPLACE_FILTER = `${namespace}/REPLACE_FILTER`;
export const REPLACE_FILTER_VALUE = `${namespace}/REPLACE_FILTER_VALUE`;
export const REPLACE_FILTERS_VALUES = `${namespace}/REPLACE_FILTERS_VALUES`;
export const SET_FILTER = `${namespace}/SET_FILTER`;
export const SET_INITIAL_FILTER = `${namespace}/SET_INITIAL_FILTER`;
export const CLEAR_FILTER = `${namespace}/CLEAR_FILTER`;
export const CLEAR_ALL_FILTERS = `${namespace}/CLEAR_ALL_FILTERS`;
export const SET_INITIAL_FILTERS_ARRAY = `${namespace}/SET_INITIAL_FILTERS_ARRAY`;

type addFilterAC = (payload: {
  filterName: string;
  filterValue: string;
}) => Action;

export const addFilter: addFilterAC = payload => ({
  type: ADD_FILTER,
  payload,
});

type addSeveralFiltersAC = (payload: {
  filterName: string;
  filterValues: string[];
}) => Action;

export const addSeveralFilters: addSeveralFiltersAC = payload => ({
  type: ADD_SEVERAL_FILTERS,
  payload,
});

type removeFilterAC = (payload: {
  filterName: string;
  filterValue: string;
}) => Action;

export const removeFilter: removeFilterAC = payload => ({
  type: REMOVE_FILTER,
  payload,
});

type removeFiltersAC = (payload: { [key: string]: string[] }) => Action;

export const removeFilters: removeFiltersAC = payload => ({
  type: REMOVE_FILTERS,
  payload,
});

type removeFilterCompletelyAC = (payload: { filterName: string }) => Action;

export const removeFilterCompletely: removeFilterCompletelyAC = payload => ({
  type: REMOVE_FILTER_COMPLETELY,
  payload,
});

type replaceFilterAC = (payload: {
  oldFilterName: string;
  newFilterName: string;
}) => Action;

export const replaceFilter: replaceFilterAC = payload => ({
  type: REPLACE_FILTER,
  payload,
});

type replaceFilterValueAC = (payload: {
  filterName: string;
  oldValue: string;
  newValue: string;
}) => Action;

export const replaceFilterValue: replaceFilterValueAC = payload => ({
  type: REPLACE_FILTER_VALUE,
  payload,
});

type replaceFiltersValuesAC = (payload: {
  [key: string]: { oldValue: string; newValue: string }[];
}) => Action;

export const replaceFiltersValues: replaceFiltersValuesAC = payload => ({
  type: REPLACE_FILTERS_VALUES,
  payload,
});

type setFilterAC = (payload: {
  filterName: string;
  filterValue?: string;
}) => Action;

export const setFilter: setFilterAC = payload => ({
  type: SET_FILTER,
  payload,
});

type setInitialFilterAC = (payload: {
  filterName: string;
  filterValue?: string;
}) => Action;

export const setInitialFilter: setInitialFilterAC = payload => ({
  type: SET_INITIAL_FILTER,
  payload,
});

type clearFilterAC = (filterName: string) => Action;

export const clearFilter: clearFilterAC = payload => ({
  type: CLEAR_FILTER,
  payload,
});

export const clearAllFilters = () => ({
  type: CLEAR_ALL_FILTERS,
});

type setInitialFiltersArrayAC = (payload: string[]) => Action;

export const setInitialFiltersArray: setInitialFiltersArrayAC = payload => ({
  type: SET_INITIAL_FILTERS_ARRAY,
  payload,
});
