import * as R from 'ramda';
import { all, put, select, takeLatest, take } from 'redux-saga/effects';

import {
  getCoreSeriesOptionsToDisplay,
  getDataSeriesGroupsWithColorAndTypeToDisplay,
} from 'modules/chartOptions/ChartOptionsReducer';
import {
  getDrilldownTableParams,
  getSortCriteria,
  getSortDirection,
  getSortVarDirectionIndex,
} from 'modules/drilldownTable/DrilldownTableReducer';
import {
  CREATE_EVENT_NOTE_REMOTELY,
  UPDATE_EVENT_NOTE_LOCALLY,
  UPDATE_EVENT_NOTE_REMOTELY,
} from 'modules/eventNotes/EventNotesActions';
import { getOriginNoteText } from 'modules/eventNotes/EventNotesReducer';
import { getFilters } from 'modules/filter/FilterReducer';
import { ADD_NOTE, UPDATE_NOTE } from 'modules/notes/NotesActions';
import { getNote } from 'modules/notes/NotesReducer';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import { sharedUISettings } from 'modules/settings/models/settings';
import { getUIState, getCurrentWellId } from 'modules/ui/UIReducer';
import { getUsersByEmailName } from 'modules/user/UserReducer';
import { getGraphqlPayload } from 'store/helpers';

import {
  notifyAllInConversation,
  notifyAllMentioned,
  notifySomeMentioned,
  notifySomeMentionedInEventNote,
} from './MentionNotificationActions';
import { MENTION_REG_EXP } from './models/mentionNotification';

function* getDataForSharing(): Generator<any, any, any> {
  const extremeDates = yield select(getExtremeDates);
  const filters = yield select(getFilters);
  const sortCriteria = yield select(getSortCriteria);
  const sortDirection = yield select(getSortDirection);
  const sortVarDirectionIndex = yield select(getSortVarDirectionIndex);
  const coreSeriesOption = yield select(getCoreSeriesOptionsToDisplay);
  const drilldownTableParams = yield select(getDrilldownTableParams);
  const dataSeriesOptionsGroups = yield select(
    getDataSeriesGroupsWithColorAndTypeToDisplay,
  );
  const ui = yield select(getUIState);
  const sharedUI = R.pick(sharedUISettings, ui);
  const dataForSharing = {
    sortCriteria,
    sortDirection,
    sortVarDirectionIndex,
    drilldownTableParams,
    extremeDates,
    filters,
    ui: sharedUI,
    coreSeriesOption,
    dataSeriesOptionsGroups,
  };

  return dataForSharing;
}

function* notifyAllMentionedUsersSaga(action): Generator<any, any, any> {
  const note = getGraphqlPayload(action);
  if (MENTION_REG_EXP.test(note.noteText)) {
    const dataForSharing = yield* getDataForSharing();
    yield put(
      notifyAllMentioned({
        noteId: note.id,
        settings: JSON.stringify(dataForSharing),
        location: window.location.origin,
        tzOffset: new Date().getTimezoneOffset(),
      }),
    );
  }

  return;
}

function* notifySomeMentionedUsersSaga(action): Generator<any, any, any> {
  const { payload } = action;
  const { id } = payload.graphql.variables;
  const currentWellId = yield select(getCurrentWellId);
  const noteBeforeUpdate = yield select(getNote, currentWellId, id);
  const tagsBeforeUpdate =
    noteBeforeUpdate.noteText.match(MENTION_REG_EXP) || [];
  const successAction = yield take([
    `${UPDATE_NOTE}_SUCCESS`,
    `${UPDATE_NOTE}_FAIL`,
  ]);

  if (successAction.type === `${UPDATE_NOTE}_FAIL`) return;

  const successPayload = getGraphqlPayload(successAction);
  const tagsAfterUpdate = successPayload.noteText.match(MENTION_REG_EXP) || [];
  const newTags = R.without(tagsBeforeUpdate, tagsAfterUpdate);
  const emailNamesToNotificate = newTags.map(tag => tag.substring(1));
  const usersByEmailName = yield select(getUsersByEmailName);
  const usersToNotificateByEmailNames = R.pick(
    emailNamesToNotificate,
    usersByEmailName,
  );
  const idsToNotificate = R.values(usersToNotificateByEmailNames).map(
    user => user.id,
  );

  if (!R.isEmpty(idsToNotificate)) {
    const dataForSharing = yield* getDataForSharing();
    yield put(
      notifySomeMentioned({
        location: window.location.origin,
        noteId: successPayload.id,
        settings: JSON.stringify(dataForSharing),
        mentionedIds: idsToNotificate,
        tzOffset: new Date().getTimezoneOffset(),
      }),
    );
  }
}

function* eventNoteNotifyAllMentionedUsersSaga(
  action,
): Generator<any, any, any> {
  const note = getGraphqlPayload(action);

  const dataForSharing = yield* getDataForSharing();
  yield put(
    notifyAllInConversation({
      noteId: note.id,
      settings: JSON.stringify(dataForSharing),
      location: window.location.origin,
      tzOffset: new Date().getTimezoneOffset(),
    }),
  );

  return;
}

function* eventNoteNotifySomeMentionedUsersSaga(
  action,
): Generator<any, any, any> {
  const {
    payload: {
      note: { id, eventType, eventId },
    },
  } = action;
  const successAction = yield take([
    `${UPDATE_EVENT_NOTE_REMOTELY}_SUCCESS`,
    `${UPDATE_EVENT_NOTE_REMOTELY}_FAIL`,
  ]);
  if (successAction.type === `${UPDATE_EVENT_NOTE_REMOTELY}_FAIL`) return;
  const oldText = yield select(store =>
    getOriginNoteText(store, { id, eventId, eventType }),
  );
  const tagsBeforeUpdate = oldText.match(MENTION_REG_EXP) || [];
  const successPayload = getGraphqlPayload(successAction);
  const tagsAfterUpdate = successPayload.noteText.match(MENTION_REG_EXP) || [];
  const newTags = R.without(tagsBeforeUpdate, tagsAfterUpdate);
  const emailNamesToNotificate = newTags.map(tag => tag.substring(1));
  const usersByEmailName = yield select(getUsersByEmailName);
  const usersToNotificateByEmailNames = R.pick(
    emailNamesToNotificate,
    usersByEmailName,
  );
  const idsToNotificate = R.values(usersToNotificateByEmailNames).map(
    user => user.id,
  );

  if (!R.isEmpty(idsToNotificate)) {
    const dataForSharing = yield* getDataForSharing();
    yield put(
      notifySomeMentionedInEventNote({
        location: window.location.origin,
        noteId: successPayload.id,
        settings: JSON.stringify(dataForSharing),
        mentionedIds: idsToNotificate,
        tzOffset: new Date().getTimezoneOffset(),
      }),
    );
  }
}

function* mentionNotificationSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(`${ADD_NOTE}_SUCCESS`, notifyAllMentionedUsersSaga),
    takeLatest(UPDATE_NOTE, notifySomeMentionedUsersSaga),
    takeLatest(
      [`${CREATE_EVENT_NOTE_REMOTELY}_SUCCESS`],
      eventNoteNotifyAllMentionedUsersSaga,
    ),
    takeLatest(
      UPDATE_EVENT_NOTE_LOCALLY,
      eventNoteNotifySomeMentionedUsersSaga,
    ),
  ]);
}

export default mentionNotificationSagas;
