import { useSelector } from 'react-redux';
import { getFlagsStateMap } from '../FeatureFlagsReducer';

const useFeatureFlagsState = () => {
  const flagsState: ReturnType<typeof getFlagsStateMap> =
    useSelector(getFlagsStateMap);

  return flagsState;
};

export default useFeatureFlagsState;
