import { XYCoord } from 'dnd-core';
import * as R from 'ramda';
import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import {
  ChartOption,
  ChartOptionsGroup,
  DraggableOption,
  ITEM_TYPES,
  ItemTypes,
  ListChartOptions,
  NOT_EXISTING_SENSOR_SERIES_MESSAGE,
  NOT_EXISTING_CALCULATED_SERIES_MESSAGE,
  OPTIONS_TYPES,
} from '../models';
import ChartTypeButton from './ChartTypeButton';
import OptionTitle from './OptionTitle';
import ShowTrellisButton from './ShowTrellisButton';
import { showHideSeriesAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

interface GroupItemProps {
  color: string;
  groupId: string;
  hoveredOptionIndex: number | null;
  isCorrentGroupHover: boolean;
  index: number;
  itemType: string;
  onAddOptionToGroup: (data: {
    groupId: string;
    optionId: string;
    newOptionIndex: number;
    previousGroupId?: string;
  }) => void;
  onChartTypeMenuOpen: (data: {
    optionId: string;
    elemRect: ClientRect;
    event: MouseEvent;
  }) => void;
  onMoveOption: (
    hoveredOptionIndex: number,
    optionIndex?: number,
    groupId?: string,
  ) => void;
  onSetHoveredOptionIndex: (value: number) => void;
  onStopHovering: () => void;
  onSetMovedOption: (value: DraggableOption) => void;
  onStopOptionMoving: () => void;
  onSwitchOptionVisibility;
  option: ChartOption;
  optionsList: ListChartOptions;
  perentGroup: ChartOptionsGroup;
  onSetMovedOptionIndex: (index: number | null) => void;
  tempGroupIndex: number | null;
  groupIndex: number;
  hoveredGroupIndex: number | null;
  isShowOriginOption: boolean;
  onSetIsShowOriginOption: (value: boolean) => void;
  isGroupDragged: boolean;
  setIsAnyOptionDraging: (data: boolean) => void;
  setTooltipPosition: (data: { x: number; y: number }) => void;
  setTooltipText: (data: string) => void;
  isNotExist: boolean;
  setNewGroupIsCreated: any;
}

const GroupItem = (props: GroupItemProps) => {
  const {
    color,
    hoveredOptionIndex,
    index,
    isGroupDragged,
    isShowOriginOption,
    isCorrentGroupHover,
    onChartTypeMenuOpen,
    onSwitchOptionVisibility,
    option,
    tempGroupIndex,
    setTooltipPosition,
    setTooltipText,
    groupId,
    onAddOptionToGroup,
    onMoveOption,
    onSetHoveredOptionIndex,
    onSetIsShowOriginOption,
    onSetMovedOption,
    onSetMovedOptionIndex,
    onStopHovering,
    onStopOptionMoving,
    perentGroup,
    setIsAnyOptionDraging,
    isNotExist,
    setNewGroupIsCreated,
  } = props;
  const elementRef = React.useRef<HTMLDivElement>(null);

  const [{ isDragging }, connectDragSource] = useDrag(
    {
      type: ItemTypes.SERIES,
      item: { index, props },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => {
        return perentGroup.options.length <= 1 ? false : true;
      },
      end: () => {
        onSetMovedOptionIndex(null);
        onSetIsShowOriginOption(false);
        onStopHovering();
        const node = elementRef.current;
        if (!node) return;
        onSetMovedOption({
          optionId: '',
          groupId: '',
        });

        node.style.border = 'none';
        setIsAnyOptionDraging(false);
      },
    },
    [props],
  );

  const [, connectDropTarget] = useDrop(
    {
      accept: ItemTypes.SERIES,
      collect: monitor => ({ isOver: monitor.isOver() }),
      canDrop: (item: any, monitor) => {
        const optionGroupId = R.pathOr(
          '',
          ['props', 'perentGroup', 'groupId'],
          item,
        );

        const optionId = R.path(['props', 'option', 'id'], item);
        const optionType = R.path(['optionsList', optionId, 'type'], props);
        const isGroup =
          item.props.itemType !== ITEM_TYPES.group &&
          ((item.props.itemType === ITEM_TYPES.singleOptionGroup &&
            item.props.currentGroup.groupId !== groupId) ||
            item.props.itemType !== ITEM_TYPES.singleOptionGroup);

        if (
          isGroup &&
          optionType !== OPTIONS_TYPES.cav &&
          (perentGroup.options.length < 5 ||
            perentGroup.groupId === optionGroupId)
        ) {
          return true;
        }
        return false;
      },
      drop: (item: any, monitor) => {
        const node = elementRef.current;
        if (!node) return null;

        const hoverBoundingRect = node.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;
        const newOptionIndex = hoverClientY < hoverMiddleY ? index : 1 + index;

        if (item.props.groupId === groupId) {
          onStopOptionMoving();
          return;
        }
        if (item.props.itemType === ITEM_TYPES.available) {
          onAddOptionToGroup({
            groupId: groupId,
            optionId: item.option.id,
            newOptionIndex,
          });
        } else if (item.props.itemType === ITEM_TYPES.selected) {
          onAddOptionToGroup({
            groupId: groupId,
            optionId: item.props.option.id,
            newOptionIndex,
            previousGroupId: item.props.perentGroup.groupId,
          });
        } else if (item.props.itemType === ITEM_TYPES.singleOptionGroup) {
          onAddOptionToGroup({
            groupId: groupId,
            optionId: item.props.currentGroup.options[0],
            newOptionIndex,
            previousGroupId: item.props.currentGroup.groupId,
          });
        }
        setNewGroupIsCreated(true);
      },
      hover: (item: any, monitor) => {
        const isOver = monitor.isOver();
        const groupId = item.props.groupId;
        const isGroup =
          item.props.itemType !== ITEM_TYPES.group &&
          ((item.props.itemType === ITEM_TYPES.singleOptionGroup &&
            item.props.currentGroup.groupId !== props.groupId) ||
            item.props.itemType !== ITEM_TYPES.singleOptionGroup);
        const node = elementRef.current;
        if (!node) return null;

        const hoverIndex = index;
        const hoverBoundingRect = node.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;
        const tempOptionIndex = hoverClientY < hoverMiddleY ? index : 1 + index;

        if (isGroup) {
          const optionId =
            item.props.itemType === ITEM_TYPES.singleOptionGroup
              ? R.path(['props', 'options', 0], item)
              : R.path(['props', 'option', 'id'], item);
          const optionType = R.path(['optionsList', optionId, 'type'], props);

          if (
            isOver &&
            perentGroup.options.length <= 5 &&
            optionType !== OPTIONS_TYPES.cav &&
            groupId !== props.groupId
          ) {
            onSetHoveredOptionIndex(tempOptionIndex);
          }
        }

        const dragIndex = item.index;
        if (
          item.itemType === 'GROUP' ||
          groupId !== props.groupId ||
          R.isNil(dragIndex)
        ) {
          return;
        }

        if (option.id === item.id || dragIndex === hoverIndex) {
          return;
        }
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        onMoveOption(hoverIndex, dragIndex);
        item.index = hoverIndex;
      },
    },
    [props],
  );

  const onChartTypeClick = React.useCallback(
    (event: MouseEvent) => {
      if (elementRef && elementRef.current) {
        const elemRect = elementRef.current.getBoundingClientRect();
        onChartTypeMenuOpen({ optionId: option.id, elemRect, event });
      }
    },
    [onChartTypeMenuOpen, option],
  );

  React.useEffect(() => {
    if (!isDragging || !elementRef.current) return;

    onSetMovedOptionIndex(props.index);
    onSetMovedOption({
      optionId: option.id,
      groupId: perentGroup.groupId,
    });
    onSetIsShowOriginOption(true);
    setIsAnyOptionDraging(true);
  }, [isDragging]);

  return connectDragSource(
    connectDropTarget(
      <div>
        {hoveredOptionIndex === 0 &&
          isCorrentGroupHover &&
          index === 0 &&
          R.isNil(tempGroupIndex) &&
          !isDragging &&
          !isShowOriginOption &&
          !isGroupDragged && <GroupItem.EmptySpace isFirst />}
        <GroupItem.Container
          ref={elementRef}
          isDragging={isDragging}
          isHidden={!isShowOriginOption && isDragging}
        >
          <GroupItem.SeriesInfo
            title={
              !isNotExist
                ? undefined
                : /s/.test(option.id)
                ? NOT_EXISTING_SENSOR_SERIES_MESSAGE
                : NOT_EXISTING_CALCULATED_SERIES_MESSAGE
            }
          >
            <OptionTitle
              title={option.title}
              setTooltipPosition={setTooltipPosition}
              setTooltipText={setTooltipText}
              isNotExist={isNotExist}
            />
            <GroupItem.SeriesType>
              {/s/.test(option.id)
                ? 'Sensor'
                : /c/.test(option.id)
                ? 'Calculated'
                : 'Daily'}
            </GroupItem.SeriesType>
          </GroupItem.SeriesInfo>
          <GroupItem.ButtonWrapper>
            <ChartTypeButton
              color={color}
              onChangeChartType={onChartTypeClick}
              type={option.chartType as string}
            />
            <ButtonTooltip content={showHideSeriesAttributes}>
              <div>
                <ShowTrellisButton
                  onShowClick={() => onSwitchOptionVisibility(option.id)}
                  show={option.isShow}
                />
              </div>
            </ButtonTooltip>
          </GroupItem.ButtonWrapper>
        </GroupItem.Container>
        {hoveredOptionIndex === 1 + index &&
          isCorrentGroupHover &&
          R.isNil(tempGroupIndex) &&
          !isDragging &&
          !isShowOriginOption &&
          !isGroupDragged && <GroupItem.EmptySpace />}
      </div>,
    ),
  );
};

GroupItem.Container = styled.div`
  display: ${props => (props.isHidden ? 'none' : 'grid')};
  opacity: ${props => (props.isDragging ? 0 : 1)};
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  height: 100%;
  background-color: #efefef;
  grid-template-columns: 266px auto;
  align-items: center;
  border: none;
`;

GroupItem.ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  height: 20px;
`;

GroupItem.EmptySpace = styled.div`
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};
  border-bottom: ${props =>
    props.isFirst ? props.theme.borders.thingray : 'none'};
  height: 20px;
`;

GroupItem.SeriesInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

GroupItem.SeriesType = styled.div`
  color: #9a9a9a;
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
`;

export default GroupItem;
