import { Form, Formik } from 'formik';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import Button from 'components/Button';
import FormikInput from 'components/FormikInput';

const ChangePasswordSchema = yup.object().shape({
  prevPassword: yup
    .string()
    .required('Required')
    .min(8, 'Password should contain at least 8 characters'),
  newPassword: yup
    .string()
    .required('Required')
    .min(8, 'Password should contain at least 8 characters'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'New passwords must match')
    .required('Required')
    .min(8, 'Password should contain at least 8 characters'),
});

type ChangePasswordFormProps = {
  onSubmit: (data: { prevPassword: string; newPassword: string }) => void;
  handleClose: () => void;
};

const ChangePasswordForm = ({
  onSubmit,
  handleClose,
}: ChangePasswordFormProps) => {
  return (
    <Formik
      initialValues={{
        prevPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
      validationSchema={ChangePasswordSchema}
      onSubmit={values =>
        onSubmit(R.pick(['prevPassword', 'newPassword'], values))
      }
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ handleSubmit }) => (
        <ChangePasswordForm.Form>
          <FormikInput
            id="prevPassword"
            name="prevPassword"
            type="password"
            label="Current Password"
          />

          <FormikInput
            id="newPassword"
            name="newPassword"
            type="password"
            label="New Password"
          />

          <FormikInput
            id="confirmNewPassword"
            name="confirmNewPassword"
            type="password"
            label="Repeat New Password"
          />

          <ChangePasswordForm.Footer>
            <Button
              width={85}
              onClick={handleSubmit}
              data-spec-id="change-password-btn"
            >
              Change
            </Button>
            <Button
              width={85}
              onClick={handleClose}
              data-spec-id="change-password-cancel-btn"
            >
              Cancel
            </Button>
          </ChangePasswordForm.Footer>
        </ChangePasswordForm.Form>
      )}
    </Formik>
  );
};

ChangePasswordForm.Form = Form;

ChangePasswordForm.Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

export default ChangePasswordForm;
