import gql from 'graphql-tag';
import { Attachment } from 'modules/notes/models/notes';

import type {
  Action,
  GraphqlQueryAction,
  GraphqlMutationAction,
} from 'store/models';
import { EventNote } from './models';
import wellRequestsAbortController from 'modules/well/utils/wellRequestsAbortController';

export const namespace = 'EVENT_NOTES';
export const FETCH_EVENT_NOTES = `${namespace}/FETCH_EVENT_NOTES`;
export const POPULATE_EVENT_NOTES = `${namespace}/POPULATE_EVENT_NOTES`;
export const ADD_EVENT_NOTE = `${namespace}/ADD_EVENT_NOTE`;
export const CREATE_EVENT_NOTE_LOCALLY = `${namespace}/CREATE_EVENT_NOTE_LOCALLY`;
export const CREATE_EVENT_NOTE_REMOTELY = `${namespace}/CREATE_EVENT_NOTE_REMOTELY`;
export const REMOVE_EVENT_NOTE_LOCALLY = `${namespace}/REMOVE_EVENT_NOTE_LOCALLY`;
export const REMOVE_EVENT_NOTE_REMOTELY = `${namespace}/REMOVE_EVENT_NOTE_REMOTELY`;
export const UPDATE_EVENT_NOTE_LOCALLY = `${namespace}/UPDATE_EVENT_NOTE_LOCALLY`;
export const REMOVE_EVENT_NOTE_ATTACHMENT = `${namespace}/REMOVE_EVENT_NOTE_ATTACHMENT`;

export const UPDATE_EVENT_NOTE_REMOTELY = `${namespace}/UPDATE_EVENT_NOTE_REMOTELY`;

const FETCH_EVENT_NOTES_QUERY = gql`
  query ($wellId: ID!) {
    listWellConversationNotes(wellId: $wellId) {
      id
      eventType
      userId
      eventId
      noteTimestamp
      userOverwrite
      noteText
      isEdited
      attachments {
        id
        name
        url
        type
      }
    }
  }
`;

type fetchEventNotesAC = (wellId: string) => GraphqlQueryAction;

export const fetchEventNotes: fetchEventNotesAC = wellId => ({
  type: FETCH_EVENT_NOTES,
  payload: {
    key: 'listWellConversationNotes',
    graphql: {
      query: FETCH_EVENT_NOTES_QUERY,
      variables: { wellId },
    },
    options: {
      getContext: wellRequestsAbortController.getContext,
    },
  },
});
type removeEventNoteAttachmentAC = (payload: {
  wellId: string;
  eventType: string;
  eventId: string;
  noteId: string;
  fileId: string;
}) => Action;

export const removeEventNoteAttachment: removeEventNoteAttachmentAC =
  payload => ({
    type: REMOVE_EVENT_NOTE_ATTACHMENT,
    payload,
  });

type populateEventNotesAC = (payload: { wellId: string; notes: any }) => Action;

export const populateEventNotes: populateEventNotesAC = payload => ({
  type: POPULATE_EVENT_NOTES,
  payload,
});

type addEventNoteAC = (payload: { wellId: string; note: EventNote }) => Action;

export const addEventNote: addEventNoteAC = payload => ({
  type: ADD_EVENT_NOTE,
  payload,
});

type createEventNoteLocallyAC = (payload: {
  wellId: string;
  eventType: string;
  eventId: string;
  noteText: string;
  attachments: Attachment[];
}) => Action;

export const createEventNoteLocally: createEventNoteLocallyAC = payload => ({
  type: CREATE_EVENT_NOTE_LOCALLY,
  payload,
});

const CREATE_NOTE_MUTATION = gql`
  mutation ($payload: CreateConversationNoteInput!) {
    createConversationNote(data: $payload) {
      id
      eventType
      userId
      eventId
      noteTimestamp
      userOverwrite
      noteText
      isEdited
      attachments {
        id
        name
        url
      }
    }
  }
`;

type createEventNoteRemotelyAC = (payload: {
  id: string;
  eventType: string;
  eventId: string;
  userId: string;
  userOverwrite: string | null;
  noteTimestamp: Date;
  noteText: string;
  isEdited: boolean;
  attachmentIds: string[];
  urlSearch: string;
}) => GraphqlMutationAction;

export const createEventNoteRemotely: createEventNoteRemotelyAC = payload => ({
  type: CREATE_EVENT_NOTE_REMOTELY,
  payload: {
    key: 'createConversationNote',
    graphql: {
      mutation: CREATE_NOTE_MUTATION,
      variables: { payload },
    },
  },
});

type updateEventNoteLocallyAC = (payload: {
  wellId: string;
  note: EventNote;
}) => Action;

export const updateEventNoteLocally: updateEventNoteLocallyAC = payload => ({
  type: UPDATE_EVENT_NOTE_LOCALLY,
  payload,
});

const UPDATE_EVENT_NOTE_MUTATION = gql`
  mutation ($payload: UpdateConversationNoteInput!) {
    updateConversationNote(data: $payload) {
      id
      eventType
      userId
      eventId
      noteTimestamp
      userOverwrite
      noteText
      isEdited
      attachments {
        id
        name
        url
      }
    }
  }
`;
type updateEventNoteRemotelyAC = (payload: EventNote) => Action;

export const updateEventNoteRemotely: updateEventNoteRemotelyAC = payload => ({
  type: UPDATE_EVENT_NOTE_REMOTELY,
  payload: {
    key: 'updateConversationNote',
    graphql: {
      mutation: UPDATE_EVENT_NOTE_MUTATION,
      variables: { payload },
    },
  },
});

const REMOVE_EVENT_NOTE_MUTATION = gql`
  mutation ($id: ID!) {
    deleteConversationNote(id: $id)
  }
`;

type removeEventNoteLocallyAC = (payload: {
  eventType: string;
  eventId: string;
  id: string;
  wellId: string;
}) => Action;

export const removeEventNoteLocally: removeEventNoteLocallyAC = payload => ({
  type: REMOVE_EVENT_NOTE_LOCALLY,
  payload,
});

type removeEventNoteRemotelyAC = (payload: { id: string }) => Action;

export const removeEventNoteRemotely: removeEventNoteRemotelyAC = payload => ({
  type: REMOVE_EVENT_NOTE_REMOTELY,
  payload: {
    key: 'deleteConversationNote',
    graphql: {
      mutation: REMOVE_EVENT_NOTE_MUTATION,
      variables: payload,
    },
  },
});
