import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { WellColumnMappingItem } from 'modules/well/models/well';

import { LayoutOption } from '../models';
import DragFilterOption from './DragFilterOption';
import {
  createWellCustomAttribute,
  setDraggableColumn,
  setDropIndicatorIndex,
  setSelectedFilterOptions,
} from '../FilterLayoutsActions';
import { getDropIndicatorIndex } from '../FilterLayoutsReducer';
import { setInitialFilter } from 'modules/filter/FilterActions';
import { v4 as uuid } from 'uuid';
import Button from 'components/Button';
import { CreateModalWindow } from 'components/ModalWindow/CreateModalWindow';
import ModalWindow from 'components/ModalWindow';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';
import { addCustomFilterAttributeAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';
import { setShouldFiltersScrollRight } from '../../ui/UIActions';

interface SelectedFiltersMenuProps {
  checkIfAttributeNameAlreadyExists: (name: string) => boolean;
  columnMapingByIndex: { [id: string]: WellColumnMappingItem };
  filtersLayoutsOptions: LayoutOption[];
  onChangeOptionVisible: (optionId: string) => void;
  onRemoveOption: (optionId: string, filterName: string) => void;
  customOptionsIdsAndNames: { ids: string[]; names: string[] };
}

const SelectedFiltersMenu = ({
  checkIfAttributeNameAlreadyExists,
  columnMapingByIndex,
  filtersLayoutsOptions,
  onChangeOptionVisible,
  onRemoveOption,
  customOptionsIdsAndNames,
}: SelectedFiltersMenuProps) => {
  const dispatch = useDispatch();
  const indicatorIndex = useSelector(getDropIndicatorIndex);
  const [localFiltersLayoutsOptions, setLocalFiltersLayoutsOptions] =
    React.useState([...filtersLayoutsOptions]);
  const [isCreatingCustomFilter, setIsCreatingCustomFilter] =
    React.useState(false);
  const [attributeNameAlreadyExists, setAttributeNameAlreadyExists] =
    React.useState(false);

  const setIndicatorIndex = React.useCallback(
    (index: number | null) => {
      if (index === indicatorIndex) return;
      dispatch(setDropIndicatorIndex({ index }));
    },
    [dispatch, indicatorIndex],
  );
  const changeFiltersOptionsOrder = React.useCallback(
    (dragId, hoverId) => {
      setLocalFiltersLayoutsOptions(prev => {
        const dragIndex = prev.findIndex(o => o.id === dragId);
        const dropIndex = prev.findIndex(o => o.id === hoverId);
        [prev[dragIndex], prev[dropIndex]] = [prev[dropIndex], prev[dragIndex]];
        return [...prev];
      });
    },
    [dispatch, setLocalFiltersLayoutsOptions],
  );
  const changeFiltersOptionsOrderGlobally = React.useCallback(() => {
    dispatch(
      setSelectedFilterOptions({
        selectedOptions: localFiltersLayoutsOptions.map(o => o.id),
      }),
    );
  }, [dispatch, localFiltersLayoutsOptions]);
  const onSetDraggableColumn = React.useCallback(
    (columnName: string) => {
      dispatch(setDraggableColumn({ columnName }));
    },
    [dispatch],
  );

  const getColumnMappingFor = (
    option: LayoutOption,
  ): WellColumnMappingItem | null => {
    if (customOptionsIdsAndNames.ids.includes(option.id)) return null;

    return R.prop(option.id, columnMapingByIndex);
  };

  const createCustomAttribute = React.useCallback(
    async (value: string) => {
      if (value.trim().length === 0) return;
      if (checkIfAttributeNameAlreadyExists(value)) {
        setAttributeNameAlreadyExists(true);
        return;
      }

      setIsCreatingCustomFilter(false);
      await dispatch(setInitialFilter({ filterName: 'custom ' + value }));
      await dispatch(
        createWellCustomAttribute({
          id: uuid().toString().split('-').join('_'),
          name: value,
        }),
      );
      dispatch(setShouldFiltersScrollRight(true));
    },
    [checkIfAttributeNameAlreadyExists, dispatch, setIsCreatingCustomFilter],
  );

  React.useEffect(() => {
    setLocalFiltersLayoutsOptions([...filtersLayoutsOptions]);
  }, [filtersLayoutsOptions, setLocalFiltersLayoutsOptions]);

  return (
    <>
      <SelectedFiltersMenu.Wrapper>
        <SelectedFiltersMenu.Container
          isEmpty={
            localFiltersLayoutsOptions.length === 0 && !isCreatingCustomFilter
          }
        >
          {localFiltersLayoutsOptions.length
            ? localFiltersLayoutsOptions.map((option, i) => (
                <DragFilterOption
                  checkIfAttributeNameAlreadyExists={
                    checkIfAttributeNameAlreadyExists
                  }
                  columnMapingData={getColumnMappingFor(option)}
                  changeFiltersOptionsOrder={changeFiltersOptionsOrder}
                  changeFiltersOptionsOrderGlobally={
                    changeFiltersOptionsOrderGlobally
                  }
                  index={i}
                  fixedIndex={filtersLayoutsOptions.findIndex(
                    o => o.id === option.id,
                  )}
                  key={option.id}
                  onChangeOptionVisible={onChangeOptionVisible}
                  onRemoveOption={onRemoveOption}
                  onSetDraggableColumn={onSetDraggableColumn}
                  option={option}
                  setIndicatorIndex={setIndicatorIndex}
                  customOptionsIdsAndNames={customOptionsIdsAndNames}
                />
              ))
            : null}
        </SelectedFiltersMenu.Container>

        <ButtonTooltip content={addCustomFilterAttributeAttributes}>
          <SelectedFiltersMenu.AddButtonContainer>
            <SelectedFiltersMenu.AddButton
              onMouseDown={() => setIsCreatingCustomFilter(true)}
            >
              Add Custom Attribute
            </SelectedFiltersMenu.AddButton>
          </SelectedFiltersMenu.AddButtonContainer>
        </ButtonTooltip>
        {isCreatingCustomFilter && (
          <CreateModalWindow
            onCancel={() => setIsCreatingCustomFilter(false)}
            onCreate={createCustomAttribute}
            title={'Enter a name for this custom attribute:'}
            close={() => setIsCreatingCustomFilter(false)}
          />
        )}

        {attributeNameAlreadyExists && (
          <ModalWindow
            title="A custom attribute with this name already exists."
            controls={[
              {
                text: 'Back',
                action: () => setAttributeNameAlreadyExists(false),
                style: { width: '70px' },
              },
            ]}
            close={() => setAttributeNameAlreadyExists(false)}
          />
        )}
      </SelectedFiltersMenu.Wrapper>
    </>
  );
};

SelectedFiltersMenu.DragButton = styled.button`
  padding: 0;
  min-height: 20px;
  height: 100%;
  border: none;
  border-right: 1px solid black;
  outline: none;

  > svg {
    width: 8px;
  }
`;

SelectedFiltersMenu.Container = styled.div`
  margin: ${props => (props.isDragging ? '0' : '1px 0')};
  margin-bottom: ${props => (props.isEmpty ? '0' : '10px')};
`;

SelectedFiltersMenu.OptionWrapper = styled.div`
  border: ${props => props.theme.borders.thingray};
  background-color: #efefef;
`;

SelectedFiltersMenu.Wrapper = styled.div`
  align-self: center;
  padding: 0 10px;
`;

SelectedFiltersMenu.AddButtonContainer = styled.div``;

SelectedFiltersMenu.AddButton = styled(Button)`
  width: 100%;
`;

SelectedFiltersMenu.InactiveAddButton = styled.button`
  height: 20px;
  border-radius: 0;
  border: none;
  border-left: ${props => props.theme.borders.thingray};
  padding: 0;
  min-width: 23px;
  outline: none;
`;

SelectedFiltersMenu.BackgroundlessButton = styled(Button)`
  height: 20px;
  border-radius: 0;
  border: none;
  padding: 0;
  background: none;
  box-shadow: none;
  width: initial;
  min-width: 16px;
  outline: none;

  svg {
    width: ${({ iconWidth }) => (iconWidth ?? 20) + 'px'};
  }
`;

SelectedFiltersMenu.InputWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 25px;
  border: 1px solid;
  border-top: ${props => (props.isSingle ? props.theme.borders.thingray : '0')};
`;

SelectedFiltersMenu.RowInput = styled.input`
  outline: none;
  border: 0;
  margin: 0 5px;
  padding: 0;
  width: 100%;
`;

SelectedFiltersMenu.InputLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

SelectedFiltersMenu.ButtonsInputLayout = styled(
  SelectedFiltersMenu.InputLayout,
)`
  margin-right: 5px;
  overflow: hidden;
`;

export default SelectedFiltersMenu;
