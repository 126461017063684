import * as R from 'ramda';
import {
  spawn,
  all,
  put,
  takeLatest,
  select,
  take,
  fork,
} from 'redux-saga/effects';
import { utcMinute } from 'd3-time';

import {
  SET_CHART_WIDTH,
  SET_CURRENT_WELL_ID,
  SET_WAS_DRAGGING,
  SET_WELL_REASON,
  START_X_AXIS_DRAGGING,
  STOP_X_AXIS_DRAGGING,
} from 'modules/ui/UIActions';
import {
  CHANGE_EXTREME_DATE,
  CHANGE_EXTREME_DATES,
  CHANGE_EXTREME_DATES_AFTER_SCALING,
  UNDO_EXTREME_DATES_CHANGE,
} from 'modules/production/ProductionActions';
import { getChartWidth, getCurrentWellId } from 'modules/ui/UIReducer';
import { getColumnMapping } from 'modules/well/WellReducer';
import {
  decodeCalculatedSeries,
  decodeDataSeries,
  decodeSensorSeries,
  parseSearchParams,
} from 'modules/router/utils/router';

import {
  FETCH_GROUP_SERIES,
  setGroupSeries,
  REFETCH_SERIES,
  fetchWellSeriesDateRange,
  FETCH_SENSOR_SERIES,
  populateSensorSeries,
  FETCH_SENSOR_SERIES_MAPPING,
  INIT_FETCH_SENSOR_SERIES,
  clearSeries,
  initFetchSensorSeries,
  REFETCH_SENSOR_SERIES,
  refetchSensorSeries,
  fetchSensorSeries,
  FETCH_SERIES,
  FETCH_SERIES_DATE_RANGE,
  populateWellSeries,
  cancelPreviousSensorSeriesFetching,
  CANCEL_PREVIOUS_SENSOR_SERIES_FETCHING,
  initFetchCalculatedSeries,
  INIT_FETCH_CALCULATED_SERIES,
  FETCH_CALCULATED_SERIES,
  populateCalculatedSeries,
  FETCH_CALCULATED_SERIES_MAPPING,
  fetchCalculatedSeries,
  REFETCH_CALCULATED_SERIES,
  refetchCalculatedSeries,
  FETCH_SERIES_MAPPING,
} from './SeriesActions';
import {
  getSensorSeriesMapping,
  getAllSeriesByWell,
  getSensorSeriesAvailableRange,
  getSeriesMapping,
  getCalculatedSeriesMapping,
} from './SeriesReducer';
import { normalizeSeries } from './utils';

import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import {
  getCoreSeriesOptionsToDisplay,
  getDataSeriesGroupsToDisplay,
  getDataSeriesGroupsWithColorAndTypeToDisplay,
} from 'modules/chartOptions/ChartOptionsReducer';
import {
  normalizeSensorSeries,
  offsetDate,
} from './utils/normalizeSensorSeries';
import {
  setCoreSeriesFromRoute,
  setDataSeriesFromRoute,
} from 'modules/chartOptions/ChartOptionsActions';
import { getFlagsStateMap } from 'modules/featureFlags/FeatureFlagsReducer';
import { TOGGLE_FEATURE_FLAG_LOCALLY } from 'modules/featureFlags/FeatureFlagsActions';
import { setCurrentSeriesLayoutFromRoute } from 'modules/seriesLayouts/SeriesLayoutsActions';
import {
  getCurrentLayout,
  getCurrentSeriesLayoutData,
} from 'modules/seriesLayouts/SeriesLayoutsReducer';

import { POPULATE_PRIMARY_CHART_DATA } from 'modules/chart/ChartActions';

import { CalculatedSeries } from './models';
import { normalizeCalculatedSeries } from './utils/normaliseCalculatedSeries';
import { getIsMarkingRows } from '../drilldownTable/DrilldownTableReducer';
import { MARK_ALL_ROWS } from '../drilldownTable/DrilldownTableActions';
import { POPULATE_CAPACITY_EVENTS } from '../capacityChangeEvent/CapacityChangeEventActions';

export function* initFetchWellSeriesSaga(action): Generator<any, any, any> {
  const reason: SET_WELL_REASON = action.payload?.reason;

  if (reason === 'URL_CHANGE') return;
  const currentWellId = yield select(getCurrentWellId);
  if (!currentWellId) {
    return;
  }
  const currentWellSeries = yield select(state =>
    getAllSeriesByWell(state, { wellId: currentWellId }),
  );
  const groupToDisplay = yield select(getDataSeriesGroupsToDisplay);
  let seriesMapping = yield select(getSeriesMapping);
  if (R.isEmpty(seriesMapping) || seriesMapping === null) {
    yield take(`${FETCH_SERIES_MAPPING}_SUCCESS`);
    seriesMapping = yield select(getSeriesMapping);
    if (R.isEmpty(seriesMapping)) return;
  }
  const seriesToDisplay = groupToDisplay.reduce(
    (acc, group) =>
      acc.concat(
        group.ids
          .filter(id => !id.startsWith('s') && !id.startsWith('c'))
          .map(id => `${seriesMapping[id].seriesIndex}`),
      ),
    [],
  );

  if (R.isEmpty(currentWellSeries.data)) {
    const extremeDates = yield select(getExtremeDates);
    const searchString = window.location.search;
    const routeSearchParams = parseSearchParams(searchString);
    const dateSource = R.isNil(extremeDates) ? routeSearchParams : extremeDates;

    yield put(
      fetchWellSeriesDateRange({
        wellId: currentWellId,
        minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        maxDate: R.pathOr(new Date(), ['max'], dateSource),
        series: seriesToDisplay,
      }),
    );
  }
}

function* fetchSensorSeriesSaga(action): Generator<any, any, any> {
  yield put(cancelPreviousSensorSeriesFetching());
  const currentWellId = yield select(getCurrentWellId);

  if (!currentWellId) return;
  const { series: seriesFilterList, chartWidth } = action.payload;

  if (chartWidth <= 0) {
    const chartWidth = (yield take(SET_CHART_WIDTH)).payload;
    return yield fetchSensorSeriesSaga({
      ...action,
      payload: { ...action.payload, chartWidth },
    });
  }

  const extremeDates = yield select(getExtremeDates);
  const dateSource = R.isNil(extremeDates)
    ? parseSearchParams(window.location.search)
    : extremeDates;
  const timezoneOffset = new Date().getTimezoneOffset();
  const minDate = offsetDate(
    R.pathOr(new Date('01-01-15'), ['min'], dateSource),
    timezoneOffset,
  );
  const maxDate = offsetDate(
    R.pathOr(new Date(), ['max'], dateSource),
    timezoneOffset,
  );
  let seriesMapping = yield select(getSensorSeriesMapping);

  const layout = yield select(getCurrentLayout);
  const layoutData = yield select(getCurrentSeriesLayoutData);

  const configuration =
    layout?.configuration ?? layoutData?.configuration ?? '';

  const sensorSeriesString = configuration.match(/(?<=sensorseries=)[^&]+&??/);
  const dataSeriesString = configuration.match(/(?<=dataseries=)[^&]+&??/);

  const sensorSeries = sensorSeriesString
    ? decodeSensorSeries(sensorSeriesString[0]).map(s =>
        s.dataSeriesGroup.map(g => g.id),
      )
    : [];
  const dataSeries = dataSeriesString
    ? decodeDataSeries(dataSeriesString[0]).map(s =>
        s.dataSeriesGroup.filter(g => g.id.startsWith('s')).map(g => g.id),
      )
    : [];
  const series = sensorSeries.concat(dataSeries).flat();

  if (R.isEmpty(seriesMapping) || seriesMapping === null) {
    yield take(`${FETCH_SENSOR_SERIES_MAPPING}_SUCCESS`);
    seriesMapping = yield select(getSensorSeriesMapping);
    if (R.isEmpty(seriesMapping)) return;
  }

  const filteredSeries: string[] = (seriesFilterList ?? series).filter(
    id => !!seriesMapping[id],
  );

  const tagIds = filteredSeries.reduce<{ [key: string]: string }>((acc, id) => {
    const tagId = seriesMapping[id].tags.find(
      t => t.wellId === currentWellId,
    )?.tagId;

    if (tagId) acc[id] = tagId;
    return acc;
  }, {});

  if (Object.keys(tagIds).length === 0) return;

  const abortController = new AbortController();
  const { signal } = abortController;

  for (const [seriesId, tagId] of Object.entries(tagIds)) {
    yield put(
      fetchSensorSeries(
        {
          tagId,
          minDate: minDate,
          maxDate: maxDate,
          pointsCount: +chartWidth.toFixed(0),
          seriesId,
          wellId: currentWellId,
        },
        { fetchOptions: { signal } },
      ),
    );
  }

  if (action.type === INIT_FETCH_SENSOR_SERIES) return;

  yield spawn(function* () {
    const action: any = yield take([
      CANCEL_PREVIOUS_SENSOR_SERIES_FETCHING,
      `${FETCH_SENSOR_SERIES}_SUCCESS`,
    ]);

    if (action.type === CANCEL_PREVIOUS_SENSOR_SERIES_FETCHING) {
      abortController.abort();
    }
  });
}

function* fetchCalculatedSeriesSaga(action): Generator<any, any, any> {
  const currentWellId = yield select(getCurrentWellId);
  if (!currentWellId) return;

  const { series: seriesFilterList, chartWidth } = action.payload;

  if (chartWidth <= 0) {
    const chartWidth = (yield take(SET_CHART_WIDTH)).payload;
    return yield fetchCalculatedSeriesSaga({
      ...action,
      payload: { ...action.payload, chartWidth },
    });
  }
  const extremeDates = yield select(getExtremeDates);
  const searchString = window.location.search;
  const routeSearchParams = parseSearchParams(searchString);
  const dateSource = R.isNil(extremeDates) ? routeSearchParams : extremeDates;
  let calculatedSeriesMapping = yield select(getCalculatedSeriesMapping);

  const layout = yield select(getCurrentLayout);
  const layoutData = yield select(getCurrentSeriesLayoutData);

  const configuration =
    layout?.configuration ?? layoutData?.configuration ?? '';

  const calculatedSeriesString = configuration.match(
    /(?<=calculatedseries=)[^&]+&??/,
  );
  const dataSeriesString = configuration.match(/(?<=dataseries=)[^&]+&??/);

  const calculatedSeries = calculatedSeriesString
    ? decodeCalculatedSeries(calculatedSeriesString[0]).map(s =>
        s.dataSeriesGroup.map(g => g.id),
      )
    : [];
  const dataSeries = dataSeriesString
    ? decodeDataSeries(dataSeriesString[0]).map(s =>
        s.dataSeriesGroup.filter(g => g.id.startsWith('c')).map(g => g.id),
      )
    : [];

  const series = calculatedSeries.concat(dataSeries).flat();
  if (R.isEmpty(calculatedSeriesMapping) || calculatedSeriesMapping === null) {
    yield take(`${FETCH_CALCULATED_SERIES_MAPPING}_SUCCESS`);
    calculatedSeriesMapping = yield select(getCalculatedSeriesMapping);
    if (R.isEmpty(calculatedSeriesMapping)) return;
  }

  const filteredSeries: string[] = (seriesFilterList ?? series).filter(
    id => !!calculatedSeriesMapping[id],
  );

  const tagIds = filteredSeries.reduce<string[]>((acc, id) => {
    const tagId = calculatedSeriesMapping[id].tags.find(
      t => t.wellId === currentWellId,
    );

    if (tagId) acc.push(calculatedSeriesMapping[id].calculatedSeriesId);
    return acc;
  }, []);

  if (tagIds.length === 0) return;
  for (const id of tagIds) {
    yield put(
      fetchCalculatedSeries({
        minDate: utcMinute.floor(
          R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        ),
        maxDate: utcMinute.ceil(R.pathOr(new Date(), ['max'], dateSource)),
        pointsCount: +chartWidth.toFixed(0),
        tableName: calculatedSeriesMapping[id].tableName,
        wellId: currentWellId,
        seriesId: id,
      }),
    );
  }
}

function* initFetchSensorSeriesAfterPanningSaga(
  action,
): Generator<any, any, any> {
  let chartWidth = yield select(getChartWidth);

  if (chartWidth === 0) {
    while (true) {
      const action = yield take(SET_CHART_WIDTH);
      if (action.payload !== 0) {
        chartWidth = action.payload;
        break;
      }
    }
  }

  if (action.type === START_X_AXIS_DRAGGING) {
    const { type } = yield take([STOP_X_AXIS_DRAGGING, SET_WAS_DRAGGING]);
    if (type === STOP_X_AXIS_DRAGGING) return;
    yield take(STOP_X_AXIS_DRAGGING);

    const extremeDates = yield select(getExtremeDates);
    const timeDelta = utcMinute.count(extremeDates.min, extremeDates.max);
    const offset = timeDelta / 4;
    const availableSeriesRange = yield select(getSensorSeriesAvailableRange);

    if (
      availableSeriesRange &&
      utcMinute.count(availableSeriesRange.min, extremeDates.min) > offset &&
      utcMinute.count(extremeDates.max, availableSeriesRange.max) > offset
    ) {
      return;
    }

    return yield put(refetchSensorSeries({ chartWidth }));
  }

  yield put(
    refetchSensorSeries({
      chartWidth,
    }),
  );
}

function* initFetchCalculatedrSeriesAfterPanningSaga(
  action,
): Generator<any, any, any> {
  let chartWidth = yield select(getChartWidth);

  if (chartWidth === 0) {
    while (true) {
      const action = yield take(SET_CHART_WIDTH);
      if (action.payload !== 0) {
        chartWidth = action.payload;
        break;
      }
    }
  }

  if (action.type === START_X_AXIS_DRAGGING) {
    const { type } = yield take([STOP_X_AXIS_DRAGGING, SET_WAS_DRAGGING]);
    if (type === STOP_X_AXIS_DRAGGING) return;
    yield take(STOP_X_AXIS_DRAGGING);

    return yield put(refetchCalculatedSeries({ chartWidth }));
  }

  yield put(
    refetchCalculatedSeries({
      chartWidth,
    }),
  );
}

function* normalizeGroupSeriesSaga(action): Generator<any, any, any> {
  const { seriesChartData } = getGraphqlPayload(action);
  const { groupData } = JSON.parse(seriesChartData);
  const prevActionPayload = getGraphqlPrevActionVariables(action);
  const {
    payload: { group },
  } = prevActionPayload;
  const columnMapping = yield select(getColumnMapping);
  const subject = group
    ? columnMapping.find(column => column.columnIndex === group.columnIndex)
        .sourceName
    : 'all';
  const groupName = group ? group.name : 'all';
  const normalizedSeries = normalizeSeries(groupData);
  yield put(
    setGroupSeries({
      groupSubject: subject,
      groupName: groupName,
      series: normalizedSeries,
    }),
  );
}

function* clearSensorSeriesSaga(): Generator<any, any, any> {
  while (true) {
    const previousCurrentWellId = yield select(getCurrentWellId);
    yield take([SET_CURRENT_WELL_ID, MARK_ALL_ROWS]);
    const currentWellId = yield select(getCurrentWellId);
    if (previousCurrentWellId !== currentWellId) {
      yield put(clearSeries());
    }
    yield take(POPULATE_CAPACITY_EVENTS);
    const chartWidth = yield select(getChartWidth);
    yield put(initFetchSensorSeries({ chartWidth }));
  }
}

function* initFetchCalculatedSeriesSaga(action): Generator<any, any, any> {
  yield put(clearSeries());
  const reason: SET_WELL_REASON = action.payload.reason;
  if (reason === 'URL_CHANGE') return;

  const chartWidth = yield select(getChartWidth);
  yield put(initFetchCalculatedSeries({ chartWidth }));
}

function* normalizeSensorSeriesSaga(action): Generator<any, any, any> {
  const seriesId = action.meta.previousAction.meta.seriesId;
  const requestedWellId = action.meta.previousAction.meta.wellId;

  const wellId = yield select(getCurrentWellId);
  if (requestedWellId !== wellId) return;
  const sensorSeriesData = JSON.parse(getGraphqlPayload(action).data);

  const seriesMapping = yield select(getSensorSeriesMapping);

  const normalizedSeries = normalizeSensorSeries(
    sensorSeriesData,
    wellId,
    seriesMapping,
  );

  yield put(
    populateSensorSeries({
      seriesId,
      data: normalizedSeries,
    }),
  );
}

function* normalizeCalculatedSeriesSaga(action): Generator<any, any, any> {
  const seriesId = action.meta.previousAction.meta.seriesId;

  const requestedWellId = action.meta.previousAction.meta.wellId;
  const wellId = yield select(getCurrentWellId);
  const calculatedMapping = yield select(getCalculatedSeriesMapping);
  if (requestedWellId !== wellId) return;

  const sensorSeriesData: CalculatedSeries[] = JSON.parse(
    getGraphqlPayload(action).data,
  );

  const normalizedCalculatedSeries = normalizeCalculatedSeries(
    sensorSeriesData,
    wellId,
    calculatedMapping,
    seriesId,
  );

  if (normalizedCalculatedSeries.length) {
    yield put(
      populateCalculatedSeries({
        seriesId: seriesId,
        data: normalizedCalculatedSeries,
      }),
    );
  }
}

function* fetchSensorSeriesAfterEnablingSaga(): Generator<any, any, any> {
  while (true) {
    const prevFlagsState = yield select(getFlagsStateMap);

    const action = yield take(TOGGLE_FEATURE_FLAG_LOCALLY);
    if (action.payload !== 'sensor-series') continue;

    const newFlagsState = yield select(getFlagsStateMap);
    if (
      prevFlagsState.sensorSeries === newFlagsState.sensorSeries ||
      prevFlagsState.calculatedSeries === newFlagsState.calculatedSeries
    )
      continue;

    if (
      newFlagsState.sensorSeries !== true ||
      newFlagsState.calculatedSeries !== true
    ) {
      const layout = yield select(getCurrentLayout);
      yield put(setCurrentSeriesLayoutFromRoute(layout));
      continue;
    }

    const chartWidth = yield select(getChartWidth);
    const series = yield select(getDataSeriesGroupsWithColorAndTypeToDisplay);

    const sortedSeries = series.reduce(
      (acc, n) => {
        if (n.dataSeriesGroup.some(o => !o.id.startsWith('s')))
          acc.data.push(n);
        else acc.sensor.push(n);

        return acc;
      },
      { data: [], sensor: [] },
    );
    const coreSeries = yield select(getCoreSeriesOptionsToDisplay);

    yield put(setCoreSeriesFromRoute({ series: coreSeries.map(s => s.id) }));
    yield put(
      setDataSeriesFromRoute({
        dataSeries: sortedSeries.data,
        sensorSeries: sortedSeries.sensor,
      }),
    );
    yield put(refetchSensorSeries({ chartWidth }));
  }
}

function* normalizeWellSeriesSaga(action): Generator<any, any, any> {
  const wellId = getGraphqlPrevActionVariables(action).payload.wellId;
  const currentWellId = yield select(getCurrentWellId);
  if (currentWellId !== wellId) return;
  const { seriesChartData } = getGraphqlPayload(action);
  const { seriesData } = JSON.parse(seriesChartData);
  const series = normalizeSeries(seriesData);
  const isMarkingRows = yield select(getIsMarkingRows);

  const previousSeries = (yield select(getAllSeriesByWell, {
    wellId: currentWellId,
  })).data;

  if (!isMarkingRows && !previousSeries.length) {
    return yield put(
      populateWellSeries({
        data: series,
        isPartialData: action.type === `${FETCH_SERIES_DATE_RANGE}_SUCCESS`,
        wellId,
      }),
    );
  }

  const seriesValuesByDay: Record<string, any> = {};

  previousSeries.forEach(v => {
    seriesValuesByDay[v.day] = v;
  });

  series.forEach(v => {
    const prevValue = seriesValuesByDay[v.day] || {};
    const newValue = { ...prevValue, ...v };
    seriesValuesByDay[v.day] = newValue;
  });

  const newSeries = Object.values(seriesValuesByDay).sort(
    (a, b) => a.day.getTime() - b.day.getTime(),
  );

  if (!isMarkingRows) {
    yield put(
      populateWellSeries({
        data: newSeries,
        isPartialData: action.type === `${FETCH_SERIES_DATE_RANGE}_SUCCESS`,
        wellId,
      }),
    );
  }
}

function* normalizePartialWellSeriesSaga(action): Generator<any, any, any> {
  if (action.payload.type !== `${FETCH_SERIES_DATE_RANGE}_SUCCESS`) return;

  yield normalizeWellSeriesSaga(action.payload);
}

function* SeriesSaga(): Generator<any, any, any> {
  yield all([
    takeLatest([REFETCH_SERIES], initFetchWellSeriesSaga),
    takeLatest(
      [
        START_X_AXIS_DRAGGING,
        CHANGE_EXTREME_DATES_AFTER_SCALING,
        CHANGE_EXTREME_DATE,
        CHANGE_EXTREME_DATES,
        UNDO_EXTREME_DATES_CHANGE,
      ],
      initFetchSensorSeriesAfterPanningSaga,
    ),
    takeLatest(
      [
        START_X_AXIS_DRAGGING,
        CHANGE_EXTREME_DATES_AFTER_SCALING,
        CHANGE_EXTREME_DATE,
        CHANGE_EXTREME_DATES,
        UNDO_EXTREME_DATES_CHANGE,
      ],
      initFetchCalculatedrSeriesAfterPanningSaga,
    ),
    takeLatest(
      [INIT_FETCH_SENSOR_SERIES, REFETCH_SENSOR_SERIES],
      fetchSensorSeriesSaga,
    ),
    takeLatest(
      [INIT_FETCH_CALCULATED_SERIES, REFETCH_CALCULATED_SERIES],
      fetchCalculatedSeriesSaga,
    ),
    fork(fetchSensorSeriesAfterEnablingSaga),
    fork(clearSensorSeriesSaga),
    takeLatest(SET_CURRENT_WELL_ID, initFetchCalculatedSeriesSaga),
    //temporarily disable series for group mode
    // takeLatest(
    //   [SET_CURRENT_GROUP, REFETCH_GROUP_SERIES],
    //   initFetchGrouSeriesSaga,
    // ),
    takeLatest(`${FETCH_GROUP_SERIES}_SUCCESS`, normalizeGroupSeriesSaga),
    takeLatest([`${FETCH_SENSOR_SERIES}_SUCCESS`], normalizeSensorSeriesSaga),
    takeLatest(
      `${FETCH_CALCULATED_SERIES}_SUCCESS`,
      normalizeCalculatedSeriesSaga,
    ),
    takeLatest(`${FETCH_SERIES}_SUCCESS`, normalizeWellSeriesSaga),
    takeLatest(POPULATE_PRIMARY_CHART_DATA, normalizePartialWellSeriesSaga),
  ]);
}

export default SeriesSaga;
