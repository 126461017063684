import { Portal } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';

import useDebouncedCallback from 'hooks/useDebouncedCallback';

import {
  ButtonTooltipAttributes,
  TooltipCoords,
} from './buttonTooltipAttributes';
import useFeatureFlagsState from 'modules/featureFlags/hooks/useFeatureFlagsState';

interface ButtonTooltipProps {
  content?: ButtonTooltipAttributes;
  children: React.ReactNode;
  anchorOffsetX?: number;
  anchorOffsetY?: number;
  ignoreFeatureFlag?: boolean;
}
const ButtonTooltip = ({
  content,
  children,
  anchorOffsetX = -40,
  anchorOffsetY = 20,
  ignoreFeatureFlag = false,
}: ButtonTooltipProps) => {
  const featureFlags = useFeatureFlagsState();
  const isRenderable = ignoreFeatureFlag ? true : featureFlags.tooltips;

  const tooltipRef = React.useRef<HTMLElement>();
  const tooltipAnchorRef = React.useRef<HTMLElement | null>(null);
  const [isTooltipVisible, setTooltipVisible] = React.useState(false);
  const [tooltipCoords, setTooltipCoords] = React.useState<TooltipCoords>();
  const [boundsOffsetY, setBoundsOffsetY] = React.useState(0);
  const [boundsOffsetX, setBoundsOffsetX] = React.useState(0);

  const onMouseMove = useDebouncedCallback(
    e => {
      // currently tooltip position updates every time the mouse is moved over the target.
      // to prevent this uncomment the next line:
      // if (tooltipCoords) return;
      if (!tooltipAnchorRef.current) return;

      const x = e.clientX + anchorOffsetX;
      const y = e.clientY + anchorOffsetY;
      setTooltipCoords({ x, y });
    },
    [tooltipCoords, anchorOffsetX, anchorOffsetY],
    200,
  );

  const onMouseEnter = React.useCallback(e => {
    tooltipAnchorRef.current = e.target;
  }, []);

  const hideTooltip = React.useCallback(() => {
    setTooltipCoords(undefined);
    setTooltipVisible(false);
    setBoundsOffsetY(0);
    setBoundsOffsetX(0);
    tooltipAnchorRef.current = null;
  }, []);

  const renderChildren = () => {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          onMouseEnter: onMouseEnter,
          onMouseMove: onMouseMove,
          onMouseLeave: hideTooltip,
          onClick: e => {
            child.props.onClick?.(e);
            hideTooltip();
          },
        });
      }
    });
  };

  React.useEffect(() => {
    if (!tooltipCoords) return;
    if (!tooltipRef.current) return;

    const boundsPadding = 6;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const tooltipRight = tooltipCoords.x + tooltipRect.width;
    const tooltipBottom = tooltipCoords.y + tooltipRect.height;

    if (tooltipCoords.x < boundsPadding) {
      setBoundsOffsetX(boundsPadding - tooltipCoords.x); // out of screen from left
    } else if (tooltipRight + boundsPadding > windowWidth) {
      setBoundsOffsetX(windowWidth - (tooltipRight + boundsPadding)); // out of screen from right
    }

    if (tooltipBottom + boundsPadding > windowHeight) {
      setBoundsOffsetY(-tooltipRect.height - anchorOffsetY); // out of screen from bottom
    }

    setTooltipVisible(true);
  }, [tooltipCoords]);

  const tooltipStyle = React.useMemo(() => {
    if (!isTooltipVisible || !tooltipCoords) return {};

    const tx = tooltipCoords.x + boundsOffsetX;
    const ty = tooltipCoords.y + boundsOffsetY;
    const transform = `translate(${tx}px, ${ty}px)`;
    return { opacity: 1, transform };
  }, [isTooltipVisible, tooltipCoords]);

  if (!isRenderable || !content) {
    return <>{children}</>;
  }

  return (
    <>
      {renderChildren()}
      <Portal>
        {tooltipCoords && content.title && (
          <ButtonTooltip.Container ref={tooltipRef} style={tooltipStyle}>
            <ButtonTooltip.Header>
              <ButtonTooltip.Title
                hasShortcut={!!content.shortcut}
                hasDescription={!!content.description}
              >
                {content.title}
              </ButtonTooltip.Title>

              {content.shortcut && <content.shortcut />}
            </ButtonTooltip.Header>

            {content.description && (
              <ButtonTooltip.Description>
                {content.description}
              </ButtonTooltip.Description>
            )}
          </ButtonTooltip.Container>
        )}
      </Portal>
    </>
  );
};

ButtonTooltip.Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  max-width: 200px;
  background: white;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 8px 12px -4px rgba(0, 0, 0, 0.15),
    0 0 12px 0 rgba(0, 0, 0, 0.07);
  z-index: 1401; // TODO: move to constants, make value smaller
  transition: opacity 0.2s;
  pointer-events: none;
`;

ButtonTooltip.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

ButtonTooltip.Title = styled.div`
  margin-right: ${({ hasShortcut }) => (hasShortcut ? '10px' : '0')};
  font-weight: ${({ hasDescription }) => (hasDescription ? '700' : '400')};
  color: #484848;
  line-height: 16px;
`;

ButtonTooltip.Description = styled.div`
  line-height: 16px;
  margin-top: 5px;
  color: #484848;
`;

export default ButtonTooltip;
