import * as React from 'react';
import styled from 'styled-components';

import Logo from 'components/Logo';
import { LoginBanner } from 'modules/appConfig/models/appConfig';
import invertColor from 'helpers/invertColor';

type LayoutDecorativeProps = {
  children: React.ReactNode;
  contentTitle: string;
  contentError?: React.ReactNode;
  onLogoClick?: () => void;
  loginBanner?: LoginBanner;
};

const LayoutDecorative = ({
  children,
  contentTitle,
  contentError,
  onLogoClick,
  loginBanner,
}: LayoutDecorativeProps) => {
  return (
    <>
      {loginBanner && (
        <LayoutDecorative.Banner color={loginBanner.color}>
          <LayoutDecorative.BannerTitle>
            {loginBanner.title}
          </LayoutDecorative.BannerTitle>
          <LayoutDecorative.BannerMessage>
            {loginBanner.message}
          </LayoutDecorative.BannerMessage>
        </LayoutDecorative.Banner>
      )}

      <LayoutDecorative.Container>
        <LayoutDecorative.ContentWrapper>
          <LayoutDecorative.LogoContainer onClick={onLogoClick}>
            <Logo width={230} />
          </LayoutDecorative.LogoContainer>

          <LayoutDecorative.Content>
            {contentError && (
              <LayoutDecorative.ContentError>
                {contentError}
              </LayoutDecorative.ContentError>
            )}

            <LayoutDecorative.ContentInner>
              <LayoutDecorative.Title>{contentTitle}</LayoutDecorative.Title>

              {children}
            </LayoutDecorative.ContentInner>
          </LayoutDecorative.Content>
        </LayoutDecorative.ContentWrapper>

        <LayoutDecorative.BackgroundImageContainer>
          <LayoutDecorative.BackgroundImage src="/loginBackground.svg" />
        </LayoutDecorative.BackgroundImageContainer>
      </LayoutDecorative.Container>
    </>
  );
};

LayoutDecorative.Banner = styled.div`
  background-color: ${({ color }) => color};
  padding: 15px 25px;
  color: ${({ color }) => invertColor(color)};
`;

LayoutDecorative.BannerTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: inherit;
`;

LayoutDecorative.BannerMessage = styled.p`
  font-size: 16px;
  color: inherit;
`;

LayoutDecorative.Container = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: 498px auto;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.lightBackground};
`;

LayoutDecorative.BackgroundImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
`;

LayoutDecorative.BackgroundImage = styled.img`
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
`;

LayoutDecorative.ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 5px 32px 0;
  flex-direction: column;
  background-color: #fff;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
`;

LayoutDecorative.Content = styled.div`
  position: relative;
  display: grid;
  flex-grow: 1;
`;

LayoutDecorative.ContentInner = styled.div`
  grid-row: 6 / 14;
`;

LayoutDecorative.ContentError = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  background-color: ${({ theme }) => theme.colors.errorBackground};
  border-radius: 4px;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.errorText};
`;

LayoutDecorative.Title = styled.p`
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 40px;
  font-weight: bold;
`;

LayoutDecorative.LogoContainer = styled.div`
  cursor: pointer;
`;

export default LayoutDecorative;
