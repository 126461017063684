import * as React from 'react';
import { useSelector } from '../../../store/models';
import { getCurrentWellId } from '../../ui/UIReducer';
import styled from 'styled-components';
import { Y_AXIS_WIDTH } from '../../chart/models/chart';
import SVGNotesChart from './SVGNotesChart';
import { Dialog } from '../../ui/models/dialog';
import { TooltipOptionsType } from '../models/index';
import NotesTooltip from './NotesTooltip';
import { timeHour } from 'd3-time';
import { UnpinIcon } from 'components/Icons';
import { PinIcon } from 'components/Icons';
import useTriangleOptions from '../hooks/useTriangleOptions';
import useNotesParams from '../hooks/useNotesParams';

interface NotesSectionProps {
  xScale: any;
  notesProductionDay: Date;
  onPanelOpen: (data: Dialog) => void;
}

const NotesSection = ({
  xScale,
  notesProductionDay,
  onPanelOpen,
}: NotesSectionProps) => {
  const [tooltipOptions, setTooltipOptions] = React.useState<
    TooltipOptionsType & { isOpened: boolean }
  >({
    x: 0,
    y: 0,
    type: 'variance',
    notes: null,
    title: '',
    subTitle: '',
    isOpened: false,
  });

  const {
    events,
    height,
    notesSection: uiState,
    isNotesSectionShown: isShown,
    onToggleNotesRibbonSectionPin,
  } = useNotesParams(xScale);

  const currentWellId = useSelector(getCurrentWellId);

  const openTooltip = React.useCallback(
    (data: TooltipOptionsType) =>
      setTooltipOptions({ ...data, isOpened: true }),
    [setTooltipOptions],
  );

  const closeTooltip = React.useCallback(
    () => setTooltipOptions(prev => ({ ...prev, isOpened: false })),
    [setTooltipOptions],
  );

  const trianglesOptions = useTriangleOptions(currentWellId, xScale);

  const externalLinePosition = xScale(timeHour.offset(notesProductionDay, 12));

  return (
    <NotesSection.Container height={height} isShown={isShown}>
      <NotesSection.LeftPanel height={height}>Notes</NotesSection.LeftPanel>
      {tooltipOptions.isOpened && (
        <NotesTooltip tooltipOptions={tooltipOptions} />
      )}
      <SVGNotesChart
        externalLinePosition={externalLinePosition}
        trianglesOptions={trianglesOptions}
        closeTooltip={closeTooltip}
        openTooltip={openTooltip}
        onPanelOpen={onPanelOpen}
        events={events}
        height={height}
        xScale={xScale}
      />
      <NotesSection.Pin onClick={onToggleNotesRibbonSectionPin} height={height}>
        {uiState.isPinned ? <PinIcon /> : <UnpinIcon />}
      </NotesSection.Pin>
    </NotesSection.Container>
  );
};

NotesSection.Pin = styled.button`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background: #e7e7e7;
  border: none;
  align-items: center;
  width: 24px;
  opacity: 0;
  height: ${props => props.height - 1}px;
  transition: opacity 300ms ease;
`;

NotesSection.Container = styled.div`
  display: flex;
  border-bottom: 1px solid black;
  height: ${props => props.height}px;
  opacity: ${props => (props.isShown ? '1' : '0')};
  position: ${props => (props.isShown ? 'relative' : 'absolute')};
  transition: opacity 300ms ease;
  z-index: 100;

  &:hover {
    ${NotesSection.Pin} {
      opacity: 1;
    }
  }
`;

NotesSection.LeftPanel = styled.div`
  position: relative;
  top: 0;
  height: ${props => props.height}px;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  border-bottom: 1px solid black;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default NotesSection;
