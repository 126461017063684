import * as React from 'react';
import * as R from 'ramda';
import { CapacityVarianceContext } from './CapacityVarianceContext';
import { Capacity } from './model';
import { timeDay } from 'd3-time';
import { convertToLocalISOString, getDateWithTimezoneOffset } from 'helpers';
import { sliceArrayByExtremeDates } from 'helpers/transformers/sliceArrayByExtremeDates';
import { ProductionPoint } from '../../../production/models/production';

export const useVarianceData = () => {
  const context = React.useContext(CapacityVarianceContext);

  if (!context)
    throw new Error(
      'CapacityVarianceContext must be used within a CapacityVarianceProvider!',
    );

  const phaseKey = context.drilldownPhase.toLowerCase();

  const lastVarianceEvent = React.useMemo(() => {
    if (!context.varianceEvents.length) return null;
    const index = context.varianceEvents.findIndex(
      event => event.dayStart.getTime() < context.limitDate.getTime(),
    );

    return index === -1
      ? null
      : { index, dateEnd: context.varianceEvents[index].dayEnd };
  }, [context.varianceEvents, context.limitDate]);

  const dateToProduction = React.useMemo(
    () =>
      context.production.reduce((acc, p) => {
        acc[convertToLocalISOString(p.day)] = p;
        return acc;
      }, {} as Record<string, ProductionPoint>),
    [context.production],
  );

  const varianceData: { date: Date; variance: number }[] = React.useMemo(() => {
    if (!context.capacity[context.drilldownPhase]) return [];
    const reverseCapacityData = R.reverse([
      ...context.capacity[context.drilldownPhase],
    ]);

    const productionDates = Object.keys(dateToProduction);

    return sliceArrayByExtremeDates(
      R.flatten(reverseCapacityData),
      timeDay.offset(
        getDateWithTimezoneOffset(new Date(productionDates[0])),
        -1,
      ),
      timeDay.offset(
        getDateWithTimezoneOffset(
          new Date(productionDates[productionDates.length - 1]),
        ),
        1,
      ),
      (date, e: any) =>
        timeDay.floor(date).getTime() - timeDay.floor(e.date).getTime(),
    ).reduce((acc, cap) => {
      const prodPoint = dateToProduction[convertToLocalISOString(cap.date)];

      if (prodPoint) {
        acc.push({
          date: cap.date,
          variance: prodPoint[phaseKey] - cap.capacity,
        });
      }

      return acc;
    }, []);
  }, [dateToProduction, context.drilldownPhase, context.capacity]);

  const getAll = React.useCallback(() => varianceData, [varianceData]);

  const getVarianceFromVarianceAreas = React.useCallback(
    (varianceAreas: Capacity[][]) => {
      const varianceData = varianceAreas.map(area =>
        area
          .map(v => {
            const production =
              dateToProduction[convertToLocalISOString(v.date)];

            return {
              date: v.date,
              variance:
                production && v.capacity !== null
                  ? production[phaseKey] - v.capacity
                  : NaN,
            };
          })
          .filter(e => !Number.isNaN(e.variance)),
      );

      if (!lastVarianceEvent) return varianceData;

      const firstVarianceData = varianceData[lastVarianceEvent.index];

      if (
        timeDay.offset(lastVarianceEvent.dateEnd, +1).getTime() >=
          context.limitDate.getTime() &&
        firstVarianceData
      ) {
        const lastRealDay = firstVarianceData[firstVarianceData.length - 1];

        if (lastRealDay)
          firstVarianceData.push({
            date: timeDay.offset(lastRealDay.date, +1),
            variance: lastRealDay.variance,
          });
      }

      return varianceData;
    },
    [
      varianceData,
      dateToProduction,
      context.drilldownPhase,
      lastVarianceEvent,
      context.limitDate,
    ],
  );

  const selectors = React.useMemo(
    () => ({ getAll, getVarianceFromVarianceAreas }),
    [getAll, getVarianceFromVarianceAreas],
  );

  return selectors;
};
