import * as React from 'react';
import styled from 'styled-components';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { AppConfig } from 'modules/appConfig/models/appConfig';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import DateInput from 'components/DateInput';

import { getVarianceExtremeDates } from 'helpers';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';
import { varianceDateRangeAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';

type EventDateFormProps = {
  allocIssues: AllocIssue[];
  appConfig: AppConfig;
  disabled: boolean;
  varianceEvents: VarianceEvent[];
  varianceEventIndex: number;
  onVarianceEventUpdate: (date: Date[]) => void;
};

const EventDateForm = ({
  allocIssues,
  appConfig,
  disabled,
  varianceEvents,
  varianceEventIndex,
  onVarianceEventUpdate,
}: EventDateFormProps) => {
  const varianceEvent = React.useMemo(
    () => varianceEvents[varianceEventIndex],
    [varianceEvents, varianceEventIndex],
  );

  const onEndProcess = React.useCallback(
    date => {
      onVarianceEventUpdate([varianceEvent.dayStart, date]);
    },
    [varianceEvent.dayStart, onVarianceEventUpdate],
  );

  const onStartProcess = React.useCallback(
    date => onVarianceEventUpdate([varianceEvent.dayEnd, date]),
    [varianceEvent.dayEnd, onVarianceEventUpdate],
  );

  const minMax = React.useMemo(
    () =>
      getVarianceExtremeDates(
        allocIssues,
        varianceEvents,
        varianceEvent.id,
        appConfig.today,
        'Variance Event',
      ),
    [varianceEvents, varianceEvent, allocIssues, appConfig],
  );

  return (
    <EventDateForm.Form>
      <EventDateForm.Header>Date Range</EventDateForm.Header>
      <EventDateForm.Layout>
        <ButtonTooltip content={varianceDateRangeAttributes}>
          <EventDateForm.DateInputsContainer>
            <DateInput
              disabled={disabled}
              id="dayStart"
              name="dayStart"
              date={varianceEvent.dayStart}
              min={minMax[0]}
              max={varianceEvent.dayEnd}
              onProcess={onStartProcess}
              transparent
            />

            <EventDateForm.TextDash />

            <DateInput
              disabled={disabled}
              id="dayEnd"
              name="dayEnd"
              date={varianceEvent.dayEnd}
              min={varianceEvent.dayStart}
              max={minMax[1]}
              onProcess={onEndProcess}
              transparent
            />
          </EventDateForm.DateInputsContainer>
        </ButtonTooltip>
      </EventDateForm.Layout>
    </EventDateForm.Form>
  );
};

EventDateForm.Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

EventDateForm.Header = styled.p`
  font-size: 14px;
`;

EventDateForm.Layout = styled.div`
  width: 255px;
`;

EventDateForm.DateInputsContainer = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid #c1c1c1;
  padding: 0;
`;

EventDateForm.TextDash = styled.div`
  width: 5px;
  height: 1px;
  background-color: ${(props: Record<string, any>) =>
    props.theme.colors.primaryText};
`;

export default EventDateForm;
