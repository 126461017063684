import { createTheme } from '@material-ui/core/styles';

import { OIL, GAS, WATER } from 'modules/phase/models/phase';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#35995b',
      contrastText: '#484848',
    },
  },
  //@ts-expect-error
  themeName: 'Main Theme',
  typography: {
    fontFamily: '"Lato", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },

  spacing: {
    '0': '0',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '1rem',
    '4': '1.5rem',
    '5': '2rem',
    '6': '2.5rem',
    '7': '3rem',
    '8': '3.5rem',
    '9': '4rem',
  },

  maxContentWidth: '1240px',

  colors: {
    primary: '#35995b',
    primaryText: '#484848',
    criticalText: '#c42424',
    errorText: '#c60000',
    darkText: '#2b2928',
    darkGreenText: '#255a33',
    [OIL]: '#35995b',
    [GAS]: '#f94040',
    [WATER]: '#2477c4',
    lightBackground: '#f4fafc',
    errorBackground: 'rgba(198, 0, 0, 0.05)',
    lightgrey: '#e8e8e8',
    activeInput: '#2477c4',
    extVsCap: '#F39C12',
    actVsExt: '#8E44AD',
  },

  borders: {
    thingray: '1px solid #909090',
  },
};

export default theme;
