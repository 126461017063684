export const namespace = 'APP';

export const LOAD_APP_CONFIG_META = `${namespace}/LOAD_APP_CONFIG_META`;
export const LOAD_LEFT_PANEL_CONTENTS = `${namespace}/LOAD_LEFT_PANEL_CONTENTS`;
export const LOAD_WELL_PARTIAL = `${namespace}/LOAD_WELL_PARTIAL`;
export const LOAD_WELL_FULL = `${namespace}/LOAD_WELL_FULL`;
export const LOAD_APP_CONFIG_ADDITIONAL = `${namespace}/LOAD_APP_CONFIG_ADDITIONAL`;

export const loadAppConfigMeta = () => ({ type: LOAD_APP_CONFIG_META });
export const loadLeftPanelContents = () => ({ type: LOAD_LEFT_PANEL_CONTENTS });
export const loadWellPartial = () => ({ type: LOAD_WELL_PARTIAL });
export const loadWellFull = () => ({ type: LOAD_WELL_FULL });
export const loadAppConfigAdditional = () => ({
  type: LOAD_APP_CONFIG_ADDITIONAL,
});
