export const METRICS_AUTH_ROLES_CODES = {
  isAdmin: 0,
  isUserManager: 1,
};

export const METRICS_AUTH_PERMISSIONS_CODES = {
  isAllowedEditCapChanges: 0,
  isAllowedEditVarEvents: 1,
  isAllowedEditAllocIssues: 2,
  isAllowedEditRibbonEvents: 3,
  isAllowedViewForecast: 4,
  isAllowedViewAllSpotfireDashboards: 5,
  isAllowedViewDashboards: 6,
};

export const METRICS_EVENTS = {
  UNKNOWN: {
    code: 0,
    name: 'Unknown',
  },

  // User Actions [100-199]
  USER_LOGIN: {
    code: 100,
    name: 'Log In',
  },
  USER_LOGOUT: {
    code: 101,
    name: 'Log Out',
  },

  // UI Actions [200-299]
  UI_VIEW_LOGO_MENU: {
    code: 200,
    name: 'View Logo Menu',
  },
  UI_VIEW_ALLOCATION_ISSUE_BACKLOG: {
    code: 201,
    name: 'View Allocation Issue Backlog',
  },
  UI_VIEW_ACCOUNT_SETTINGS: {
    code: 202,
    name: 'View Account Settings',
  },
  UI_TOGGLE_SHORTCUT_PANEL: {
    code: 203,
    name: 'Toggle Shortcut Panel',
  },
  UI_TOGGLE_EXTERNAL_FORECAST: {
    code: 204,
    name: 'Toggle External Forecast',
  },
  UI_SET_CURRENT_GROUP: {
    code: 205,
    name: 'Set Current Group',
  },
  UI_SET_FILTER: {
    code: 206,
    name: 'Set Drilldown Filter',
  },
  UI_REMOVE_FILTER: {
    code: 207,
    name: 'Remove Drilldown Filter',
  },
  UI_CLEAR_FILTERS: {
    code: 208,
    name: 'Clear Drilldown Filters',
  },
  UI_VIEW_NOTES: {
    code: 209,
    name: 'View Well Notes',
  },
  UI_SET_REGION_OF_INTEREST: {
    code: 210,
    name: 'Set Region of Interest',
  },
  UI_ZOOM_IN: {
    code: 211,
    name: 'Zoom In',
  },
  UI_SET_DRILLDOWN_TABLE_PARAMS: {
    code: 212,
    name: 'Set Drilldown Table Parameters',
  },
  UI_SET_DRILLDOWN_TABLE_PHASE: {
    code: 213,
    name: 'Set Drilldown Table Phase',
  },
  UI_SET_DRILLDOWN_TABLE_DATE_MIN: {
    code: 214,
    name: 'Set Drilldown Table Min Date',
  },
  UI_SET_DRILLDOWN_TABLE_DATE_MAX: {
    code: 215,
    name: 'Set Drilldown Table Max Date',
  },
  UI_SET_DRILLDOWN_TABLE_SORT_DIRECTION: {
    code: 216,
    name: 'Set Drilldown Table Sort Direction',
  },
  UI_REFRESH_DRILLDOWN_TABLE: {
    code: 217,
    name: 'Refresh Drilldown Table',
  },
  UI_VIEW_DRILLDOWN: {
    code: 218,
    name: 'View Drilldown',
  },
  UI_VIEW_SPOTFIRE_DASHBOARDS: {
    code: 219,
    name: 'View Spotfire Dashboards',
  },
  UI_OPEN_SPOTFIRE_DASHBOARD: {
    code: 220,
    name: 'Open Spotfire Dashboard',
  },
  UI_VIEW_SPOTFIRE_DASHBOARD: {
    code: 221,
    name: 'View Spotfire Dashboard',
  },
  UI_SET_INBOX_FILTER: {
    code: 222,
    name: 'Set Inbox Filter',
  },
  UI_SET_INBOX_PAGE: {
    code: 223,
    name: 'Go to Inbox Page',
  },
  UI_DRILLDOWN_MARK_ROW: {
    code: 224,
    name: 'Mark Drilldown Row',
  },
  UI_DRILLDOWN_MARK_ROWS: {
    code: 225,
    name: 'Mark Multiple Drilldown Rows',
  },
  UI_DRILLDOWN_MARK_ALL_ROWS: {
    code: 226,
    name: 'Mark All Drilldown Rows',
  },
  UI_DRILLDOWN_UNMARK_ROW: {
    code: 227,
    name: 'Unmark Drilldown Row',
  },
  UI_REFRESH_DRILLDOWN_TABLE_WELLS_COMPLETE: {
    code: 228,
    name: 'Refresh Drilldown Table Wells Compelete',
  },
  UI_REFRESH_DRILLDOWN_TABLE_VARIANCE_COMPLETE: {
    code: 229,
    name: 'Refresh Drilldown Table Variance Compelete',
  },
  UI_SET_SPOTFIRE_OUTPUT_FILTERED_WELLS: {
    code: 230,
    name: 'Set Filtered Wells from Spotfire',
  },
  UI_SET_SPOTFIRE_OUTPUT_MARKED_WELLS: {
    code: 231,
    name: 'Set Marked Wells from Spotfire',
  },
  UI_SET_SPOTFIRE_OUTPUT_VIEW_START_DATE: {
    code: 232,
    name: 'Set View Start Date from Spotfire',
  },
  UI_SET_SPOTFIRE_OUTPUT_VIEW_END_DATE: {
    code: 233,
    name: 'Set View End Date from Spotfire',
  },

  // UI Shortcut Actions [300-399]
  UI_SHORTCUT_CREATE_CAPACITY_CHANGE_EVENT: {
    code: 300,
    name: 'Used shortcut to Create Capacity Change Event',
  },
  UI_SHORTCUT_CREATE_VARIANCE_EVENT: {
    code: 301,
    name: 'Used shortcut to Create Variance Event',
  },
  UI_SHORTCUT_CREATE_ALLOCATION_ISSUE: {
    code: 302,
    name: 'Used shortcut to Create Allocation Issue',
  },
  UI_SHORTCUT_ZOOM_ALL: {
    code: 303,
    name: 'Used shortcut to Zoom to All',
  },
  UI_SHORTCUT_VIEW_NOTES: {
    code: 304,
    name: 'Used shortcut to open Notes',
  },
  UI_SHORTCUT_OPEN_ZOOM_MENU: {
    code: 305,
    name: 'Used shortcut to open Zoom Menu',
  },
  UI_SHORTCUT_OPEN_TRELLIS_MENU: {
    code: 306,
    name: 'Used shortcut to open Trellis Menu',
  },
  UI_SHORTCUT_ZOOM_IN: {
    code: 307,
    name: 'Used shortcut to Zoom In',
  },
  UI_SHORTCUT_ZOOM_OUT: {
    code: 308,
    name: 'Used shortcut to Zoom Out',
  },
  UI_SHORTCUT_RESTORE_ZOOM: {
    code: 309,
    name: 'Used shortcut to Restore Zoom',
  },
  UI_SHORTCUT_TOGGLE_SHORTCUT_PANEL: {
    code: 310,
    name: 'Used shortcut to Show Shortcuts Panel',
  },
  UI_SHORTCUT_ZOOM_3_MONTHS: {
    code: 311,
    name: 'Used shortcut to Zoom to Prior 3 Months',
  },
  UI_SHORTCUT_ZOOM_6_MONTHS: {
    code: 312,
    name: 'Used shortcut to Zoom to Prior 6 Months',
  },
  UI_SHORTCUT_ZOOM_12_MONTHS: {
    code: 313,
    name: 'Used shortcut to Zoom to Prior 12 Months',
  },
  UI_SHORTCUT_ZOOM_24_MONTHS: {
    code: 314,
    name: 'Used shortcut to Zoom to Prior 24 Months',
  },
  UI_SHORTCUT_TOGGLE_ALL_TRELLISES: {
    code: 315,
    name: 'Used shortcut to Toggle all Trellises',
  },
  UI_SHORTCUT_TOGGLE_BOE_TRELLIS: {
    code: 316,
    name: 'Used shortcut to Toggle BOE Trellis',
  },
  UI_SHORTCUT_TOGGLE_OIL_TRELLIS: {
    code: 317,
    name: 'Used shortcut to Toggle Oil Trellis',
  },
  UI_SHORTCUT_TOGGLE_GAS_TRELLIS: {
    code: 318,
    name: 'Used shortcut to Toggle Gas Trellis',
  },
  UI_SHORTCUT_TOGGLE_GOR_TRELLIS: {
    code: 319,
    name: 'Used shortcut to Toggle GOR Trellis',
  },
  UI_SHORTCUT_TOGGLE_WATER_TRELLIS: {
    code: 320,
    name: 'Used shortcut to Toggle Water Trellis',
  },
  UI_SHORTCUT_TOGGLE_WATERCUT_TRELLIS: {
    code: 321,
    name: 'Used shortcut to Toggle Watercut Trellis',
  },
  UI_SHORTCUT_TOGGLE_GLR_TRELLIS: {
    code: 322,
    name: 'Used shortcut to Toggle GLR Trellis',
  },
  UI_SHORTCUT_TOGGLE_VARIANCE_TRELLIS: {
    code: 323,
    name: 'Used shortcut to Toggle Variance Trellis',
  },

  // Allocation Issue Actions [400-499]
  ALLOC_ISSUE_CREATE: {
    code: 400,
    name: 'Create Allocation Issue',
  },
  ALLOC_ISSUE_DELETE: {
    code: 401,
    name: 'Delete Allocation Issue',
  },
  ALLOC_ISSUE_UPDATE: {
    code: 402,
    name: 'Update Allocation Issue',
  },

  // Capacity Change Event Actions [500-599]
  CAPACITY_CHANGE_CREATE: {
    code: 500,
    name: 'Create Capacity Change Event',
  },
  CAPACITY_CHANGE_DELETE: {
    code: 501,
    name: 'Delete Capacity Change Event',
  },
  CAPACITY_CHANGE_UPDATE: {
    code: 502,
    name: 'Update Capacity Change Event',
  },

  // Note Actions [600-699]
  EVENT_NOTE_CREATE: {
    code: 600,
    name: 'Create Event Note',
  },
  EVENT_NOTE_DELETE: {
    code: 601,
    name: 'Delete Event Note',
  },
  EVENT_NOTE_UPDATE: {
    code: 602,
    name: 'Update Event Note',
  },
  WELL_NOTE_CREATE: {
    code: 603,
    name: 'Create Well Note',
  },
  WELL_NOTE_DELETE: {
    code: 604,
    name: 'Delete Well Note',
  },
  WELL_NOTE_UPDATE: {
    code: 605,
    name: 'Update Well Note',
  },
  NOTE_ATTACHMENT_UPLOAD: {
    code: 606,
    name: 'Upload Note Attachment',
  },
  NOTE_ATTACHMENT_DELETE: {
    code: 607,
    name: 'Delete Note Attachment',
  },
  NOTE_ATTACHMENT_VIEW: {
    code: 608,
    name: 'View Note Attachment Image',
  },
  MARK_NOTES_AS_READ: {
    code: 609,
    name: 'Mark Notes as Read',
  },
  MARK_NOTES_AS_UNREAD: {
    code: 610,
    name: 'Mark Notes as Unread',
  },
  ARCHIVE_NOTES: {
    code: 611,
    name: 'Archive Notes',
  },

  // Variance Event Actions [700-799]
  VARIANCE_EVENT_CREATE: {
    code: 700,
    name: 'Create Variance Event',
  },
  VARIANCE_EVENT_DELETE: {
    code: 701,
    name: 'Delete Variance Event',
  },
  VARIANCE_EVENT_UPDATE: {
    code: 702,
    name: 'Update Variance Event',
  },
  SET_VARIANCE_CATEGORY_FILTER: {
    code: 703,
    name: 'Filtered to Variance Category',
  },

  // Series Layout Actions [800-899]
  SERIES_LAYOUT_CREATE: {
    code: 800,
    name: 'Create Series Data Layout',
  },
  SERIES_LAYOUT_DELETE: {
    code: 801,
    name: 'Delete Series Data Layout',
  },
  SERIES_LAYOUT_LOAD: {
    code: 802,
    name: 'Load Series Data Layout',
  },

  // Misc Actions [9000-9999]
  WELL_LOAD_PRODUCTION: {
    code: 9000,
    name: 'Load Production Data for Well',
  },
  MEASURE_WELL_SWITCH: {
    code: 9001,
    name: 'MeasureWellSwitching',
  },
  MEASURE_FPS: {
    code: 9002,
    name: 'MeasureFPS',
  },
  MEASURE_DRILLDOWN_TABLE_LOADING: {
    code: 9003,
    name: 'MeasureDrilldownTableLoading',
  },
  MEASURE_GROUP_CHART_LOADING: {
    code: 9004,
    name: 'MeasureGroupChartLoading',
  },

  APP_REFRESHED: {
    code: 9100,
    name: 'App Refreshed',
  },
};
