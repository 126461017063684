import * as React from 'react';
import { TooltipOptionsType } from '../models/index';
import { Note } from '../../notes/models/notes';
import EventTriangle from './EventTriangle';
import { timeHour } from 'd3-time';
import NotesTooltip from './NotesTooltip';

interface SVGExternalNotesChartProps {
  xScale: any;
  notesProductionDay: Date;
  trianglesOptions: {
    position: number;
    note: Note;
  }[];
}

const SVGExternalNotesMarkers = ({
  xScale,
  trianglesOptions,
  notesProductionDay,
}: SVGExternalNotesChartProps) => {
  const [tooltipOptions, setTooltipOptions] = React.useState<
    TooltipOptionsType & { isOpened: boolean }
  >({
    x: 0,
    y: 0,
    type: 'variance',
    notes: null,
    title: '',
    subTitle: '',
    isOpened: false,
  });

  const openTooltip = React.useCallback(
    (data: TooltipOptionsType) =>
      setTooltipOptions({ ...data, isOpened: true }),
    [setTooltipOptions],
  );

  const closeTooltip = React.useCallback(
    () => setTooltipOptions(prev => ({ ...prev, isOpened: false })),
    [setTooltipOptions],
  );

  const externalLinePosition = xScale(timeHour.offset(notesProductionDay, 12));

  return (
    <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 100 }}>
      {tooltipOptions.isOpened && (
        <NotesTooltip tooltipOptions={tooltipOptions} />
      )}
      <svg
        height={8}
        width={xScale.range()[1] - 1}
        viewBox={`0 0 ${xScale.range()[1]} ${8}`}
        shapeRendering="geometricPrecision"
        style={{ position: 'relative', display: 'block' }}
        preserveAspectRatio="none"
      >
        {trianglesOptions.map(options => {
          if (externalLinePosition === options.position) return null;
          return (
            <EventTriangle
              key={options.note.id}
              position={options.position}
              note={options.note}
              openTooltip={openTooltip}
              closeTooltip={closeTooltip}
            />
          );
        })}
      </svg>
    </div>
  );
};

export default SVGExternalNotesMarkers;
