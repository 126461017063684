import React from 'react';
import { useSelector } from 'store/models';

import { Dialog } from 'modules/ui/models/dialog';
import { getRightPanelDialogs, getRightPanelDialogType } from '../UIReducer';

const useRightPanelDialogs = () => {
  const activeDialogType = useSelector(getRightPanelDialogType);
  const dialogsData: any = useSelector(getRightPanelDialogs);

  const makeDialog = React.useCallback(
    (type: Dialog['type']) => ({
      show: activeDialogType === type,
      id: dialogsData[type]?.id,
      index: dialogsData[type]?.index,
    }),
    [activeDialogType, dialogsData],
  );

  const dialogs = React.useMemo(
    () => ({
      notesDialog: makeDialog('WellNotes'),
      capacityDialog: makeDialog('CapacityChangeEvent'),
      ribbonDialog: makeDialog('RibbonEvent'),
      ribbonDetailsDialog: makeDialog('RibbonDetails'),
      allocIssueDialog: makeDialog('AllocationIssue'),
      varianceDialog: makeDialog('VarianceChangeEvent'),
    }),
    [makeDialog],
  );

  return dialogs;
};

export default useRightPanelDialogs;
