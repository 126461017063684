import React from 'react';

import Modal from 'components/ModalWindowV2';

import ChangePasswordForm from '../forms/ChangePassForm';

type ChangePasswordWindowProps = {
  changePasswordRequest: (data: {
    prevPassword: string;
    newPassword: string;
  }) => any;
  open: boolean;
  handleClose: () => void;
};

const ChangePasswordWindow = ({
  changePasswordRequest,
  open,
  handleClose,
}: ChangePasswordWindowProps) => {
  const onSubmit = React.useCallback(
    (values: { prevPassword: string; newPassword: string }) => {
      changePasswordRequest(values).then((res, err) => {
        if (res.type.includes('SUCCESS')) {
          handleClose();
        }
      });
    },
    [changePasswordRequest, handleClose],
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      title="Change Password"
      specId="change-password-modal"
    >
      <ChangePasswordForm handleClose={handleClose} onSubmit={onSubmit} />
    </Modal>
  );
};

export default ChangePasswordWindow;
