import Env from 'helpers/Env';
import type { MetricsPlugin, MetricsCaptureTargeting } from '../models';

class MetricsPluginPermissions {
  private plugin: MetricsPlugin;
  private capture: MetricsCaptureTargeting;

  constructor(plugin: MetricsPlugin) {
    this.plugin = plugin;
    this.capture = plugin.props.capture;
  }

  canBeUsed = () => {
    return (
      Env.isSupported(this.plugin.props.env) && this.plugin.isSourceCodeLoaded()
    );
  };

  canCaptureAll = () => {
    return this.canBeUsed() && this.capture.includes('*');
  };

  canCaptureAuth = () => {
    return (
      this.canBeUsed() &&
      !this.capture.includes('!auth') &&
      (this.capture.includes('auth') || this.canCaptureAll())
    );
  };

  canCaptureEvent = () => {
    return (
      this.canBeUsed() &&
      !this.capture.includes('!event') &&
      (this.capture.includes('event') || this.canCaptureAll())
    );
  };

  canCaptureError = () => {
    return (
      this.canBeUsed() &&
      !this.capture.includes('!error') &&
      (this.capture.includes('error') || this.canCaptureAll())
    );
  };
}

export default MetricsPluginPermissions;
