import { useMemo } from 'react';
import { utcHour } from 'd3-time';
import { Note } from '../../notes/models/notes';
import { useSelector } from '../../../store/models';
import { getWellNotesByDay } from '../../notes/NotesReducer';

const useTriangleOptions = (wellId, xScale) => {
  const wellNotes = useSelector(store => getWellNotesByDay(store, wellId));

  const wellDaysSorted = useMemo(() => {
    return Object.keys(wellNotes).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    );
  }, [wellNotes]);

  const triangles = useMemo(() => {
    return wellDaysSorted.reduce((acc, date, i, sourceArr) => {
      const position = Math.round(xScale(utcHour.offset(new Date(date), 12)));
      const prevPosition = acc[acc.length - 1]?.position ?? -7;
      const isLast = i === sourceArr.length - 1;
      if (position >= 0 && (position - prevPosition > 7 || isLast)) {
        acc.push({ position, note: wellNotes[date][0] });
      }
      return acc;
    }, [] as { position: number; note: Note }[]);
  }, [wellDaysSorted, xScale]);

  return triangles;
};

export default useTriangleOptions;
