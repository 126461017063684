import { LoomVideo } from '@loomhq/record-sdk';
import React from 'react';
import { useDispatch } from 'react-redux';
import { LoomContext } from './LoomContext';
import { startRecordingVideo, stopRecordingVideo } from 'modules/ui/UIActions';

interface LoomRecorderProps {
  button: HTMLElement | null;
  onInsert?: (video: LoomVideo) => void;
}

export const useLoomRecorder = ({ button, onInsert }: LoomRecorderProps) => {
  const dispatch = useDispatch();
  const context = React.useContext(LoomContext);
  const [isRecording, setIsRecording] = React.useState(false);

  if (!context)
    throw new Error(
      'LoomRecorder should be used within a LoomRecorderProvider!',
    );

  React.useEffect(() => {
    if (isRecording) dispatch(startRecordingVideo());
    else dispatch(stopRecordingVideo());
  }, [isRecording]);

  React.useEffect(() => {
    if (!context.configureButton || !button) return;

    const sdkButton = context.configureButton({
      element: button,
    });

    sdkButton.on('insert-click', async video => {
      onInsert?.call(null, video);
    });

    sdkButton.on('recording-start', () => {
      setIsRecording(true);
    });

    sdkButton.on('start', () => {
      setIsRecording(true);
    });

    sdkButton.on('complete', () => {
      setIsRecording(false);
    });

    sdkButton.on('cancel', () => {
      setIsRecording(false);
    });
  }, [button, context.configureButton]);

  return React.useMemo(
    () => ({
      isSetup: context.isSetup,
      isRecording,
    }),
    [context.isSetup, isRecording],
  );
};
