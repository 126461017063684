import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { scaleTime } from 'd3-scale';

import useDebouncedCallback from 'hooks/useDebouncedCallback';
import {
  changeExtremeDatesAfterScaling,
  changeExtremeDatesByDragging,
} from 'modules/production/ProductionActions';
import { getExtremeDates } from 'modules/production/ProductionReducer';

import { handleXAxisScaling } from '../utils';
import useChartUIData from './useChartUIData';
import { timeDay, timeHour, timeMillisecond, timeMinute } from 'd3-time';

interface Props {
  onXAxisScaled?: (chartWidth: number) => void;
}

const useChartScaling = ({ onXAxisScaled }: Props = {}) => {
  const dispatch = useDispatch();
  const extremeDates = useSelector(getExtremeDates);
  const { chartWidth } = useChartUIData();

  const timeInterval = React.useMemo(() => {
    const delta = timeMillisecond.count(extremeDates.min, extremeDates.max);

    if (delta > 10 * 24 * 3600 * 1000) return timeDay;
    else if (delta > 24 * 3600 * 1000) return timeHour;
    return timeMinute;
  }, [extremeDates]);

  const panInterval = React.useMemo(() => {
    const delta = timeMillisecond.count(extremeDates.min, extremeDates.max);

    if (delta > 10 * 24 * 3600 * 1000) return timeDay;
    else if (delta > 24 * 3600 * 1000) return timeHour;
    else if (delta > 30 * 60 * 1000) return timeMinute;
    return timeMillisecond;
  }, [extremeDates]);

  const xScale = React.useMemo(() => {
    const range = [0, chartWidth];
    const domain = [extremeDates.min, extremeDates.max];
    return scaleTime().range(range).domain(domain);
  }, [chartWidth, extremeDates]);

  const updateUrl = useDebouncedCallback(
    () => dispatch(changeExtremeDatesAfterScaling()),
    [dispatch],
    500,
  );

  const scaleExtremeDates = React.useCallback(
    dates => dispatch(changeExtremeDatesByDragging(dates)),
    [dispatch],
  );

  const onXAxisScaling = React.useCallback(
    (e, svgEl) => {
      handleXAxisScaling(
        e,
        svgEl,
        extremeDates,
        scaleExtremeDates,
        xScale,
        timeInterval,
      );
      updateUrl();
      onXAxisScaled?.call(null, chartWidth);
    },
    [
      xScale,
      scaleExtremeDates,
      extremeDates,
      dispatch,
      chartWidth,
      onXAxisScaled,
      timeInterval,
    ],
  );

  return React.useMemo(
    () => ({
      xScale,
      timeInterval,
      panInterval,
      onXAxisScaling,
    }),
    [xScale, timeInterval, panInterval, onXAxisScaling],
  );
};

export default useChartScaling;
