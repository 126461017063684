import { max } from 'd3-array';
import { timeDay, utcDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'store/models';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import RegionOfInterest from 'modules/chart/components/RegionOfInterest';
import SeriesPill from 'modules/chart/components/SeriesPill';
import YAxis from 'modules/chart/components/YAxis';
import {
  createNormalYScale,
  getCoreSeriesTitle,
  getCoreSeriesDetailedTitle,
  createVarianceYScale,
} from 'modules/chart/utils';
import { ForecastData } from 'modules/externalForecast/models';
import type { GroupChartPoint } from 'modules/groupChart/models/groupChart';
import type { TooltipData, TrellisTooltipData } from 'modules/ui/models/ui';

import usePrevious from 'hooks/usePrevious';

import SVGGroupTrellis from './SVGGroupTrellis';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';
import { getLocalAppSettings } from 'modules/appConfig/AppConfigReducer';
import { sliceArrayByExtremeDates } from 'helpers/transformers/sliceArrayByExtremeDates';
import { approximatedSearchByDate } from '../../../helpers/approximatedSearchByDate';
import { SERIES_PILL_HEIGHT, SERIES_PILL_OFFSET } from '../../series/models';
import useForecast from 'modules/externalForecast/hooks/useForecast';
import { getYAxisHovered } from 'modules/ui/UIReducer';

const getMaxDataPoints = (
  groupChartData: GroupChartPoint[] = [],
  extremeDates,
  forecastData: ForecastData,
  phase: string,
  firstSeriesPillDate: Date,
): { global: number; pill: number } => {
  const inTimeRange = sliceArrayByExtremeDates(
    groupChartData,
    utcDay.offset(extremeDates.min, 1),
    utcDay.offset(extremeDates.max, 1),
    (date, e) => utcDay.floor(date).getTime() - utcDay.floor(e.day).getTime(),
  );
  const minPillIndex = approximatedSearchByDate(
    inTimeRange,
    firstSeriesPillDate,
    (date, e) => date.getTime() - e.day.getTime(),
  );

  const globalChartValues: any[] = [];
  const pillChartValues: any[] = [];

  inTimeRange.forEach((el, idx) => {
    if (idx >= minPillIndex) {
      pillChartValues.push(el);
    } else {
      globalChartValues.push(el);
    }
  });

  const forecastDataNew = sliceArrayByExtremeDates(
    forecastData,
    utcDay.offset(extremeDates.min, 1),
    utcDay.offset(extremeDates.max, 1),
    (date, e) => utcDay.floor(date).getTime() - utcDay.floor(e.day).getTime(),
  );

  const globalForecastValues: any[] = [];
  const pillForecastValues: any[] = [];

  forecastDataNew.forEach((el, idx) => {
    const value = el[phase];
    if (idx >= minPillIndex) {
      pillForecastValues.push(value);
    } else {
      globalForecastValues.push(value);
    }
  });

  const maxForecastPillValue = R.isEmpty(pillForecastValues)
    ? 0
    : max(pillForecastValues);
  const maxForecastGlobalValue = Math.max(
    R.isEmpty(globalForecastValues) ? 0 : max(globalForecastValues),
    maxForecastPillValue,
  );
  const maxChartPillValue = R.isEmpty(pillChartValues)
    ? 0
    : (max(pillChartValues, d => Math.max(d.production, d.capacity)) as number);
  const maxChartGlobalValue = Math.max(
    R.isEmpty(globalChartValues)
      ? 0
      : (max(globalChartValues, d =>
          Math.max(d.production, d.capacity),
        ) as number),
    maxChartPillValue,
  );

  return {
    global:
      Math.max(maxForecastGlobalValue, maxChartGlobalValue) * 1.05 || 1000,
    pill: Math.max(maxForecastPillValue, maxChartPillValue),
  };
};

interface DivGroupChartProps {
  seriesId: string;
  changeMinRoiDate: (minDate: Date) => void;
  changeMaxRoiDate: (minDate: Date) => void;
  localRoiDates: { maxDate: Date; minDate: Date };
  endRoiDragging: () => void;
  currentGroup: Record<string, never> | { subject: string; item: string };
  displaysRegionOfInterest: boolean;
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
    compareOption: string;
  };
  extremeDates: { min: Date; max: Date };
  finishDrag: () => void;
  groupChartData: GroupChartPoint[];
  groupForecastData: ForecastData;
  format: string;
  height: number;
  isAxisDragging: boolean;
  isDisplayingForecast: boolean;
  isDragging: boolean;
  leftOffset: number;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  position: number;
  regionOfInterest: boolean;
  regionOfInterestMode: boolean;
  startDrag: () => void;
  today: Date;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  width: number;
  xScale: any;
  isLast: boolean;
}

const DivGroupChart = ({
  seriesId,
  changeMinRoiDate,
  changeMaxRoiDate,
  localRoiDates,
  endRoiDragging,
  currentGroup,
  displaysRegionOfInterest,
  drilldownTableParams,
  extremeDates,
  format,
  finishDrag,
  groupChartData,
  groupForecastData,
  height,
  isAxisDragging,
  isDisplayingForecast,
  isDragging,
  leftOffset,
  onSetTooltipData,
  onXAxisScaling,
  position,
  regionOfInterest,
  regionOfInterestMode,
  startDrag,
  today,
  tooltipData,
  trellisTitle,
  width,
  xScale,
  isLast,
}: DivGroupChartProps) => {
  const yAxisHovered = useSelector(getYAxisHovered);

  const productionSlice = React.useMemo(() => {
    const index = groupChartData.findIndex(
      d => d.day.getTime() >= today.getTime(),
    );

    if (index === -1) return groupChartData;

    const productionAndCapacity = groupChartData.slice(0, index);
    const capacity = groupChartData
      .slice(index)
      .map(c => ({ ...c, production: 0 }));

    return productionAndCapacity.concat(capacity);
  }, [groupChartData]);

  const [displayMinDataPoint, setDisplayMinDataPoint] = React.useState(0);
  const [displayMaxDataPoint, setDisplayMaxDataPoint] = React.useState(0);
  const [initialPillMaxDataPoint, setInitialPillMaxDataPoint] =
    React.useState(0);
  const [isPillIntersecting, setIsPillIntersecting] = React.useState(false);
  const [yScaleOffset, setYScaleOffset] = React.useState(0);
  const [isAdjusted, setIsAdjusted] = React.useState(false);
  const [maxDataPoint, setMaxDataPoint] = React.useState(0);
  const firstSeriesPillElem = React.useRef<HTMLElement | null>(null);

  const yScale = React.useMemo(
    () =>
      createVarianceYScale(
        height,
        displayMinDataPoint,
        displayMaxDataPoint,
        yScaleOffset,
      ),
    [displayMaxDataPoint, displayMinDataPoint, height, isPillIntersecting],
  );
  const initialYScale = React.useMemo(
    () => createNormalYScale(height, maxDataPoint, yScaleOffset),
    [maxDataPoint, height, isPillIntersecting],
  );

  const { isVisible: isForecastVisible } = useForecast();

  React.useEffect(() => {
    const { global: globalMaxDataPoint, pill: pillMaxDataPoint } =
      getMaxDataPoints(
        productionSlice,
        extremeDates,
        isForecastVisible ? groupForecastData : [],
        trellisTitle === 'Total Liquid'
          ? 'total_liquid'
          : trellisTitle.toLocaleLowerCase(),
        xScale.invert(firstSeriesPillElem?.current?.offsetLeft),
      );
    setInitialPillMaxDataPoint(pillMaxDataPoint);

    setMaxDataPoint(globalMaxDataPoint);
  }, [
    productionSlice,
    extremeDates,
    groupForecastData,
    trellisTitle,
    setMaxDataPoint,
    isForecastVisible,
  ]);

  React.useEffect(() => {
    if (!initialPillMaxDataPoint || isAdjusted) return;

    const pillMaxDataPointOffset = initialYScale(initialPillMaxDataPoint);
    if (pillMaxDataPointOffset < SERIES_PILL_HEIGHT) {
      setIsPillIntersecting(true);
      setYScaleOffset(
        SERIES_PILL_HEIGHT - pillMaxDataPointOffset + SERIES_PILL_OFFSET,
      );
    }
    if (pillMaxDataPointOffset > SERIES_PILL_HEIGHT + SERIES_PILL_OFFSET) {
      setIsPillIntersecting(false);
      setYScaleOffset(0);
    }
  }, [initialPillMaxDataPoint, initialYScale, isAdjusted]);

  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    setDisplayMinDataPoint(0);
    setDisplayMaxDataPoint(maxDataPoint);
  }, [setIsAdjusted, setDisplayMaxDataPoint, maxDataPoint]);

  const prevMaxDataPoint = usePrevious(maxDataPoint);
  const prevGroup = usePrevious(currentGroup);

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );
  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );

  React.useEffect(() => {
    if (prevMaxDataPoint !== maxDataPoint || !displayMaxDataPoint) {
      setDisplayMaxDataPoint(maxDataPoint);
    }
  }, [prevMaxDataPoint, maxDataPoint, displayMaxDataPoint]);

  React.useEffect(() => {
    if (
      prevGroup &&
      JSON.stringify(prevGroup) !== JSON.stringify(currentGroup)
    ) {
      resetMax();
    }
  }, [currentGroup, prevGroup, resetMax]);

  const dataMap = React.useMemo(() => {
    const phase = trellisTitle.toLowerCase();
    const tempDate = groupChartData.reduce((acc, dataPoint) => {
      const key = timeDay(dataPoint.day).toISOString();
      acc[key] = {
        day: dataPoint.day,
        production:
          today.getTime() <= dataPoint.day.getTime()
            ? null
            : dataPoint.production,
        capacity: dataPoint.capacity,
      };

      return acc;
    }, {});
    if (!R.isEmpty(groupForecastData)) {
      groupForecastData?.reduce((acc, dataPoint) => {
        const key = dataPoint.day.toISOString();
        if (acc[key]) {
          acc[key] = {
            ...acc[key],
            forecast: dataPoint[phase],
          };
          return acc;
        }
        acc[key] = {
          day: dataPoint.day,
          production: 0,
          capacity: 0,
          forecast: dataPoint[phase],
        };

        return acc;
      }, tempDate);
    }

    return tempDate;
  }, [groupChartData, groupForecastData, trellisTitle, today]);

  const tooltipDate =
    R.pathOr(false, ['trellisTooltipData', 'day'], tooltipData) || null;
  const groupTooltipData = tooltipDate
    ? R.path([tooltipDate.toISOString()], dataMap)
    : null;

  const localSettings = useSelector(getLocalAppSettings);

  return (
    <>
      <DivGroupChart.Container height={height} isLast={isLast}>
        <DivGroupChart.SVGWrapper>
          <SVGGroupTrellis
            dataMap={dataMap}
            groupChartData={productionSlice}
            groupForecastData={groupForecastData}
            height={height}
            isAxisDragging={isAxisDragging}
            isDisplayingForecast={isDisplayingForecast}
            maxDataPoint={displayMaxDataPoint}
            onXAxisScaling={onXAxisScaling}
            onSetTooltipData={onSetTooltipData}
            regionOfInterestMode={regionOfInterestMode}
            today={today}
            tooltipData={tooltipData}
            trellisTitle={trellisTitle}
            xScale={xScale}
            yScale={yScale}
            yAxisLinePos={yAxisLinePos}
            minSummedCapacityDate={localSettings.minSummedCapacityDateSetting}
            format={format}
          />
          {displaysRegionOfInterest &&
            regionOfInterest &&
            drilldownTableParams &&
            drilldownTableParams.phase === trellisTitle &&
            !regionOfInterestMode && (
              <RegionOfInterest
                changeMinRoiDate={changeMinRoiDate}
                changeMaxRoiDate={changeMaxRoiDate}
                endRoiDragging={endRoiDragging}
                leftOffset={leftOffset}
                maxDate={localRoiDates.maxDate}
                minDate={localRoiDates.minDate}
                xScale={xScale}
                startDrag={startDrag}
                finishDrag={finishDrag}
                height={height}
                width={width}
                position={height * position}
              />
            )}
        </DivGroupChart.SVGWrapper>
        <DivGroupChart.YAxisContainer>
          <YAxis
            format={format}
            height={height}
            hideLine={hideLine}
            isAdjusted={isAdjusted}
            isDragging={false}
            isXAxisDragging={false}
            maxDataPoint={displayMaxDataPoint}
            setDisplayMaxDataPoint={setDisplayMaxDataPoint}
            resetMax={resetMax}
            setIsAdjusted={setIsAdjusted}
            showLine={showLine}
            yScale={yScale}
          />
        </DivGroupChart.YAxisContainer>
        <SeriesPill
          ref={firstSeriesPillElem}
          text={getCoreSeriesTitle(seriesId)}
          tooltipTitle={getCoreSeriesDetailedTitle(
            seriesId,
            drilldownTableParams.grossNet,
          )}
          tooltipDescription="Core Series"
        />
        {!yAxisHovered &&
          tooltipData &&
          !isAxisDragging &&
          (tooltipData.trellisTooltipData ||
            tooltipData.ribbonTooltipData ||
            tooltipData.dataSeriesTooltipData) &&
          tooltipData.trellisTooltipData?.trellis !== trellisTitle && (
            <SecondaryInformationTooltip
              containerHeight={height}
              isDisplayingForecast={isDisplayingForecast}
              leftOffset={leftOffset}
              tooltipData={tooltipData}
              secondaryCavTooltipData={groupTooltipData}
              trellisTitle={trellisTitle}
              yScale={yScale}
              today={today}
            />
          )}
      </DivGroupChart.Container>
    </>
  );
};

DivGroupChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

DivGroupChart.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

DivGroupChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

export default React.memo<DivGroupChartProps>(DivGroupChart);
