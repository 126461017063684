import { TimeInterval, timeDay } from 'd3-time';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';
import convertToLocalISOString from '../../../helpers/convertToLocalISOString';

const createVarianceDataMap = (
  varianceData: { date: Date; variance: number }[],
  varianceEvents: VarianceEvent[],
  allocIssues: AllocIssue[],
) => {
  //@ts-expect-error
  const varianceEntities = varianceEvents.concat(allocIssues);
  const varianceCatIndexedByDay = varianceEntities.reduce((acc, entity) => {
    //@ts-expect-error
    const dayStart = entity.dayStart || entity.dateStart;
    //@ts-expect-error
    const dayEnd = entity.dayEnd || entity.dateEnd;

    const interval = timeDay.every(1) as TimeInterval;
    const timeRange = interval.range(dayStart, timeDay.offset(dayEnd));
    timeRange.forEach(
      date =>
        (acc[convertToLocalISOString(date)] =
          entity.varianceOptionId || 'allocIssue'),
    );

    return acc;
  }, {});

  const varianceIndexedByDay = varianceData.reduce((acc, dataPoint) => {
    const dateString = convertToLocalISOString(dataPoint.date);
    acc[dateString] = dataPoint;

    return acc;
  }, {});

  return Object.keys(varianceIndexedByDay).reduce((acc, dateString) => {
    acc[dateString] = {
      variance: varianceIndexedByDay[dateString].variance,
      day: varianceIndexedByDay[dateString].date,
      capacity: 0,
      production: 0,
    };
    acc[dateString].varianceOptionId = varianceCatIndexedByDay[dateString];

    return acc;
  }, {});
};
export default createVarianceDataMap;
