import React from 'react';
import ReactDOM from 'react-dom/client';
import Metrics, {
  sentryPlugin,
  heapPlugin,
  newRelicPlugin,
} from 'modules/metrics/services/Metrics';
import captureAppReload from 'helpers/captureAppReload';
import App from './App';

// import { unregister } from './registerServiceWorker';

// Unregister service worker
// unregister();

sentryPlugin.initialize();
heapPlugin.initialize();
newRelicPlugin.initialize();

captureAppReload(() => {
  Metrics.captureEvent('APP_REFRESHED');
});

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(<App />);
