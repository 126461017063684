import { utcDay, TimeInterval } from 'd3-time';

import {
  OIL,
  GAS,
  WATER,
  GOR,
  GLR,
  BOE,
  WATERCUT,
  TOTAL_LIQUID,
} from 'modules/phase/models/phase';

import {
  countBoeValue,
  countGorValue,
  countWatercutValue,
  countGlrValue,
  countTotalLiquidValue,
} from 'modules/production/utils';

import type { GroupChart, GroupChartPoint } from '../models/groupChart';
import { convertToChartDate } from '../../../helpers';
interface EmptyData {
  production: number;
  capacity: number;
  variance: {
    null: number;
  };
  day: Date;
}
interface GroupData {
  day: Date;
  oilProduction: number;
  gasProduction: number;
  waterProduction: number;
  oilCapacity: number;
  gasCapacity: number;
  waterCapacity: number;
}

interface ChartData {
  groupData: GroupData[];
}

const phasesList = [OIL, GAS, WATER, BOE, GOR, GLR, WATERCUT, TOTAL_LIQUID];

const normalizeGroupChartData = (
  chartData: ChartData,
  extremeDates: { min: Date; max: Date },
): GroupChart => {
  const { groupData } = chartData;
  const groupDataIndexedByDay: {
    [key: string]: GroupChartPoint;
  } = groupData.reduce((acc, datum: GroupData) => {
    const {
      day: prodDay,
      oilProduction,
      oilCapacity,
      gasProduction,
      gasCapacity,
      waterProduction,
      waterCapacity,
    } = datum;

    const dataPoint = {
      day: new Date(prodDay),
      [OIL]: {
        production: oilProduction,
        capacity: oilCapacity,
        variance: {},
      },
      [GAS]: {
        production: gasProduction,
        capacity: gasCapacity,
        variance: {},
      },
      [WATER]: {
        production: waterProduction,
        capacity: waterCapacity,
        variance: {},
      },
    };
    acc[prodDay.toString()] = dataPoint;
    return acc;
  }, {});

  const sorted = Object.values(groupDataIndexedByDay).sort(
    (a: GroupChartPoint, b: GroupChartPoint) =>
      a.day.getTime() - b.day.getTime(),
  );
  const interval = utcDay.every(1) as TimeInterval;
  const intervalEnd = sorted[0] ? sorted[0].day : extremeDates.max;
  const range = interval.range(extremeDates.min, intervalEnd);
  const emptyDays = range.map(date => {
    const emptyData = phasesList.reduce((acc, phase) => {
      acc[phase] = {
        production: 0,
        capacity: 0,
        variance: {
          null: 0,
        },
      };

      return acc;
    }, {} as EmptyData);

    emptyData.day = date;
    return emptyData;
  });

  const withIndicators = sorted.map(groupChartDataPoint => {
    const boeProduction = countBoeValue(
      groupChartDataPoint[OIL].production,
      groupChartDataPoint[GAS].production,
    );
    const boeCapacity = countBoeValue(
      groupChartDataPoint[OIL].capacity,
      groupChartDataPoint[GAS].capacity,
    );

    const gorProduction = countGorValue(
      groupChartDataPoint[OIL].production,
      groupChartDataPoint[GAS].production,
    );
    const gorCapacity = countGorValue(
      groupChartDataPoint[OIL].capacity,
      groupChartDataPoint[GAS].capacity,
    );

    const watercutProduction = countWatercutValue(
      groupChartDataPoint[OIL].production,
      groupChartDataPoint[WATER].production,
    );
    const watercutCapacity = countWatercutValue(
      groupChartDataPoint[OIL].capacity,
      groupChartDataPoint[WATER].capacity,
    );

    const glrProduction = countGlrValue(
      groupChartDataPoint[OIL].production,
      groupChartDataPoint[GAS].production,
      groupChartDataPoint[WATER].production,
    );
    const glrCapacity = countGlrValue(
      groupChartDataPoint[OIL].capacity,
      groupChartDataPoint[GAS].capacity,
      groupChartDataPoint[WATER].capacity,
    );
    const totalLiquidProduction = countTotalLiquidValue(
      groupChartDataPoint[OIL].production,
      groupChartDataPoint[WATER].production,
    );
    const totalLiquidCapacity = countTotalLiquidValue(
      groupChartDataPoint[OIL].capacity,
      groupChartDataPoint[WATER].capacity,
    );

    return {
      ...groupChartDataPoint,
      [BOE]: {
        production: boeProduction,
        capacity: boeCapacity,
      },
      [GOR]: {
        production: gorProduction,
        capacity: gorCapacity,
      },
      [WATERCUT]: {
        production: watercutProduction,
        capacity: watercutCapacity,
      },
      [GLR]: {
        production: glrProduction,
        capacity: glrCapacity,
      },
      [TOTAL_LIQUID]: {
        production: totalLiquidProduction,
        capacity: totalLiquidCapacity,
      },
    };
  });

  const consolidatedData = emptyDays.concat(withIndicators as any);

  const dataByPhase: GroupChart = phasesList.reduce((acc, phase) => {
    const phaseData = consolidatedData.map(dataPoint => ({
      day: convertToChartDate(dataPoint.day),
      ...dataPoint[phase],
    }));
    acc[phase] = phaseData;

    return acc;
  }, {});

  return dataByPhase;
};

export default normalizeGroupChartData;
