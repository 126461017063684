import * as R from 'ramda';
import { convertToChartDate } from 'helpers';

const normalizeVarianceEvents = (rawVarianceEvents: Record<string, any>[]) =>
  rawVarianceEvents.reduce((acc, entry) => {
    const newEntry = R.compose(
      R.assoc('dayStart', convertToChartDate(new Date(entry.dayStart))),
      R.assoc('dayEnd', convertToChartDate(new Date(entry.dayEnd))),
      R.assoc('extraInputsData', JSON.parse(entry.extraInputsData)),
    )(entry);
    acc[entry.id] = newEntry;

    return acc;
  }, {});

export default normalizeVarianceEvents;
