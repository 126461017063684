import { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import { Reducer } from 'react';
import { IAction, PhaseToCapacity } from '../model';
import * as R from 'ramda';
import { isIdNew } from 'helpers';

export type IState = {
  capacity: PhaseToCapacity;
  indicatorsCapacity: PhaseToCapacity;
  capacityEvents: CapacityChangeEvent[];
  idHistory: any;
};

export const initialState: IState = {
  capacity: {},
  indicatorsCapacity: {},
  capacityEvents: [],
  idHistory: {},
};

const normalizeEventId = (eventId: string, history: any) => {
  if (!isIdNew(eventId) || !history[eventId]) {
    return eventId;
  }
  return history[eventId].newId;
};

export const CapacityVarianceReducer: Reducer<IState, IAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'SET_CAPACITY_FOR': {
      const { capacity, eventId, phase } = action.payload;
      const eventIndex = state.capacityEvents.findIndex(e => e.id === eventId);
      const phaseCapacity = [...state.capacity[phase]];
      phaseCapacity.splice(eventIndex, 1, capacity);

      return R.assocPath(['capacity', phase], phaseCapacity, state);
    }
    case 'SET_CAPACITY':
      return R.assoc('capacity', action.payload, state);
    case 'SET_INDICATORS_CAPACITY':
      return R.assoc('indicatorsCapacity', action.payload, state);
    case 'SET_CAPACITY_EVENTS':
      return R.assoc('capacityEvents', action.payload, state);
    case 'UPDATE_CAPACITY_EVENT_DECLINE': {
      const { decline, eventId, phase } = action.payload;
      const phasePrefix = phase.toLowerCase();
      const newCapacityEvents = state.capacityEvents.map(e =>
        e.id === normalizeEventId(eventId, state.idHistory)
          ? { ...e, [`${phasePrefix}DeclineInitDailyNom`]: decline }
          : e,
      );

      return R.assoc('capacityEvents', newCapacityEvents, state);
    }
    case 'UPDATE_CAPACITY_EVENT_B_FACTOR': {
      const { bFactor, eventId, phase } = action.payload;
      const phasePrefix = phase.toLowerCase();
      const newCapacityEvents = state.capacityEvents.map(e =>
        e.id === normalizeEventId(eventId, state.idHistory)
          ? { ...e, [`${phasePrefix}BFactor`]: bFactor }
          : e,
      );

      return R.assoc('capacityEvents', newCapacityEvents, state);
    }
    case 'UPDATE_CAPACITY_EVENT_INITIAL_RATE': {
      const { rate, eventId, phase } = action.payload;
      const phasePrefix = phase.toLowerCase();
      const newCapacityEvents = state.capacityEvents.map(e =>
        e.id === normalizeEventId(eventId, state.idHistory)
          ? { ...e, [`${phasePrefix}RateInit`]: rate }
          : e,
      );

      return R.assoc('capacityEvents', newCapacityEvents, state);
    }
    case 'SET_ID_HISTORY':
      return R.assoc('idHistory', action.payload, state);
    default:
      return state;
  }
};
