import * as React from 'react';
import styled from 'styled-components';

import type { User } from 'modules/user/models/user';

import useMouseDownOutside from 'hooks/useMouseDownOutside';

import { CloseIcon } from 'components/Icons';
import Button from 'components/Button';
import Card from 'components/Card';
import usePrevious from 'hooks/usePrevious';

const TAB_KEY_CODE = 9;
const UP_KEYCODE = 38;
const DOWN_KEYCODE = 40;
const WINDOW_SIZES = {
  width: 300,
  height: 200,
};
const USER_ITEM_HEIGHT = 24;

interface MentionWindowProps {
  mentionInput: string;
  usersByEmailName: { [emailName: string]: User };
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleMentionChoose: (mention: string) => Promise<any>;
  isShowOnRight?: boolean;
}

const MentionWindow = ({
  mentionInput,
  usersByEmailName,
  anchorEl,
  handleClose,
  handleMentionChoose,
  isShowOnRight,
}: MentionWindowProps) => {
  const [highlightedIndex, setHighlightedIndex] = React.useState(0);

  const windowEl = React.useRef(null);
  const scrollableElem = React.useRef<HTMLElement>(null);
  const highlightedElem = React.useRef<HTMLElement>(null);

  useMouseDownOutside([windowEl], handleClose);

  const prevMentionInput = usePrevious(mentionInput);
  React.useEffect(() => {
    const effect = async () => {
      if (prevMentionInput !== mentionInput && !highlightedElem.current) {
        await setHighlightedIndex(0);
        const hasHighlighted = !!(
          scrollableElem.current && highlightedElem.current
        );
        if (hasHighlighted) {
          highlightedElem.current &&
            (highlightedElem.current as HTMLElement).scrollIntoView({
              behavior: 'smooth',
            });
        }
      }
    };
    effect();
  }, [mentionInput, prevMentionInput]);

  const searchedNameString = mentionInput.substring(1).toLowerCase();

  const windowPositioning = React.useMemo(() => {
    if (!anchorEl) return { left: 0, top: 0 };
    const maxValues = {
      left: -WINDOW_SIZES.width + 10,
      top: window.innerHeight - WINDOW_SIZES.height,
    };
    const anchorRect = anchorEl.getBoundingClientRect();
    if (isShowOnRight) {
      const offsetLeft = Math.max(
        anchorRect.right / 2 - WINDOW_SIZES.width / 2,
        maxValues.left,
      );
      return { left: offsetLeft, top: anchorRect.top - 220 };
    }

    const offsetLeft = Math.min(
      anchorRect.left - WINDOW_SIZES.width,
      maxValues.left,
    );
    const offsetTop = Math.min(anchorRect.bottom, maxValues.top);

    return { left: offsetLeft, top: offsetTop };
  }, [anchorEl, isShowOnRight]);

  const filteredSortedEmailNames = React.useMemo(() => {
    const emailNames = Object.entries(usersByEmailName)
      .filter(([_, u]) => u.taggable)
      .map(([name]) => name);
    const filtered = emailNames.filter(
      name =>
        name.toLowerCase().startsWith(searchedNameString) ||
        usersByEmailName[name].firstName
          .toLowerCase()
          .startsWith(searchedNameString) ||
        usersByEmailName[name].lastName
          .toLowerCase()
          .startsWith(searchedNameString),
    );
    const sorted = filtered.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;

      return 0;
    });

    return sorted;
  }, [usersByEmailName, searchedNameString]);

  const handleKeyPress = React.useCallback(
    (e: KeyboardEvent) => {
      const hasHighlighted = !!(
        scrollableElem.current && highlightedElem.current
      );
      if (e.keyCode === TAB_KEY_CODE) {
        e.preventDefault();
        if (Boolean(anchorEl) && filteredSortedEmailNames[0]) {
          handleMentionChoose(filteredSortedEmailNames[highlightedIndex]);
        }
      } else if (e.keyCode === UP_KEYCODE && Boolean(anchorEl)) {
        e.preventDefault();
        setHighlightedIndex(Math.max(0, highlightedIndex - 1));
        if (hasHighlighted) {
          highlightedElem.current &&
            scrollableElem.current.scrollTo({
              left: 0,
              top: (highlightedIndex - 1) * USER_ITEM_HEIGHT,
              behavior: 'smooth',
            });
        }
      } else if (e.keyCode === DOWN_KEYCODE && Boolean(anchorEl)) {
        e.preventDefault();
        setHighlightedIndex(
          Math.min(filteredSortedEmailNames.length - 1, highlightedIndex + 1),
        );
        if (hasHighlighted) {
          highlightedElem.current &&
            scrollableElem.current.scrollTo({
              left: 0,
              top: (highlightedIndex + 1) * USER_ITEM_HEIGHT,
              behavior: 'smooth',
            });
        }
      }
    },
    [filteredSortedEmailNames, anchorEl, handleMentionChoose, highlightedIndex],
  );

  const onMentionClick = React.useCallback(
    (e, emailName) => {
      e.preventDefault();
      handleMentionChoose(emailName);
    },
    [handleMentionChoose],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  return (
    <MentionWindow.Container
      windowPositioning={windowPositioning}
      id="mention-window"
    >
      <MentionWindow.Card ref={windowEl}>
        <MentionWindow.Header>
          <MentionWindow.Title>Mention</MentionWindow.Title>
          <MentionWindow.CloseButton
            transparent
            onClick={handleClose}
            onMouseDown={e => e.preventDefault()}
          >
            <CloseIcon />
          </MentionWindow.CloseButton>
        </MentionWindow.Header>
        <MentionWindow.UsersList ref={scrollableElem}>
          {filteredSortedEmailNames.map((emailName, i) => (
            <MentionWindow.UserItem
              key={emailName}
              onClick={e => onMentionClick(e, emailName)}
              isHighlighted={i === highlightedIndex}
              onMouseEnter={() => setHighlightedIndex(i)}
              onMouseDown={e => e.preventDefault()}
              ref={i === highlightedIndex ? highlightedElem : null}
            >
              {`${usersByEmailName[emailName].firstName}
               ${usersByEmailName[emailName].lastName} (${emailName})`}
            </MentionWindow.UserItem>
          ))}
        </MentionWindow.UsersList>
      </MentionWindow.Card>
    </MentionWindow.Container>
  );
};

MentionWindow.Container = styled.div`
  width: ${WINDOW_SIZES.width}px;
  height: ${WINDOW_SIZES.height}px;
  background: white;
  position: fixed;
  top: ${(props: Record<string, any>) => props.windowPositioning.top}px;
  left: ${(props: Record<string, any>) => props.windowPositioning.left}px;
  z-index: 500;
`;

MentionWindow.Card = styled(Card)`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0 0 0 10px;
`;

MentionWindow.UsersList = styled.ul`
  margin: 0;
  height: calc(100% - 51px);
  overflow: scroll;
`;

MentionWindow.UserItem = styled.li`
  padding: 5px 10px;
  background-color: ${(props: Record<string, any>) =>
    props.isHighlighted ? props.theme.colors.lightgrey : 'transparent'};
  cursor: pointer;
`;

MentionWindow.Header = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid
    ${(props: Record<string, any>) => props.theme.colors.lightgrey};
  position: relative;
`;

MentionWindow.Title = styled.h5`
  font-size: 1.2em;
  font-weight: 400;
  color: ${(props: Record<string, any>) => props.theme.colors.primaryText};
  margin: 0;
  padding: 0;
`;

MentionWindow.CloseButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export default MentionWindow;
