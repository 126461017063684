import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect'; //eslint-disable-line
import { calculateWellTotalColumn } from 'modules/drilldownTable/utils/calculateWellTotalColumn';
import { Reducer } from 'redux';

import type { Action, RootState, Selector } from 'store/models';
import {
  getLocalAppSettings,
  getToday,
} from 'modules/appConfig/AppConfigReducer';
import { LOGOUT } from 'modules/auth/AuthActions';
import {
  getCurrentGroup,
  getCurrentWellId,
  getGroupMode,
} from 'modules/ui/UIReducer';
import {
  getAllWells,
  getColumnMapping,
  getWellsWithCustomValuesAsObject,
} from 'modules/well/WellReducer';

import {
  ADD_CURRENT_VARIANCE_OPTION,
  ADD_MARKED_ROW,
  ADD_SEVERAL_MARKED_ROWS,
  ADD_SEVERAL_VARIANCE_OPTIONS,
  CLEAR_DRILLDOWN_TABLE,
  FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE,
  FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE,
  FETCH_FILTERED_WELL_DRILLDOWN_TABLE,
  FETCH_FORECAST_DRILLDOWN_TABLE,
  FETCH_VARIANCE_DRILLDOWN_TABLE,
  FETCH_WELL_DRILLDOWN_TABLE,
  INIT_DRILLDOWN_TABLE_FETCH,
  namespace,
  POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE,
  POPULATE_FILTERED_WELL_DRILLDOWN_TABLE,
  POPULATE_FORECAST_DRILLDOWN_TABLE,
  POPULATE_VARIANCE_DRILLDOWN_TABLE,
  POPULATE_WELL_DRILLDOWN_TABLE,
  REMOVE_MARKED_ROW,
  REMOVE_ONE_VARIANCE_OPTION,
  RESET_CURRENT_VARIANCE_OPTION,
  RESET_MARKED_ROWS,
  RESET_SORTING,
  SET_CURRENT_VARIANCE_OPTION,
  SET_CUSTOM_LAYOUT_PARAMS,
  SET_CUSTOM_WELL_TABLE,
  SET_DRILLDOWN_TABLE_OPTION_COLUMNS,
  SET_DRILLDOWN_TABLE_PARAMS,
  SET_DRILLDOWN_TABLE_PHASE,
  SET_IS_CUSTOM_LAYOUT,
  SET_LAYOUT_CONFIGURATION,
  SET_MARKED_ROWS,
  SET_MAX_DRILLDOWN_TABLE_DATE,
  SET_MIN_DRILLDOWN_TABLE_DATE,
  SET_NUMBER_OF_ROI_DAYS,
  SET_SORT_CRITERIA,
  SET_SORT_DIRECTION,
  SET_SORT_VAR_INDEX,
  SET_SORT_VAR_PERCENT_INDEX,
  TOGGLE_VARIANCE_PERCENTAGE,
} from './DrilldownTableActions';
import {
  ASC,
  COMPARE_OPTION,
  CustomDrilldownTableItem,
  DrilldownTable,
  DrilldownTableParams,
  GroupDrilldownTable,
  VarianceDrilldownTableItem,
  VarianceTableItem,
  WellDrilldownTableItem,
} from './models/drilldownTable';
import {
  createGroupedTable,
  createForecastGroupedTable,
  getDefaulDrilldownDates,
  filterDrilldownTable,
  sortForecastTable,
  sortTable,
} from './utils';
import { getFlagsStateMap } from '../featureFlags/FeatureFlagsReducer';
import naturalSorting from './utils/naturalSorting';
import createGroupedCustomWellTable from './utils/createGroupedCustomWellTable';
import { FETCH_CUSTOM_DRILLDOWN_TABLE } from './DrilldownTableActions';
import { calculateVariancePercentage } from './utils/calculateVariancePercentage';

export type DrilldownTableLayoutConfigOptions = 'standard' | 'custom';

const filterRegExp = new RegExp(`${namespace}/|${LOGOUT}`);

export const STATE_KEY = 'drilldownTable';

export type DrilldownTableState = {
  currentVarianceOptions: string[];
  currentMarkedRows: string[];
  fethcingStatus: {
    isVarianceTableFetching: boolean;
    isForecastFetching: boolean;
    isWellTableFetching: boolean;
    isCustomWellFetching: boolean;
  };
  params: DrilldownTableParams;
  optionColumns: string[];
  sortDirection: string;
  sortCriteria: string;
  sortVarDirectionIndex: number;
  sortVarPercentDirectionIndex: number;
  varianceTable: VarianceDrilldownTableItem[];
  wellTable: WellDrilldownTableItem[];
  numberOfRoiDays: number;
  fetchingDates: {
    minDate: Date | null;
    maxDate: Date | null;
  };
  isCustomLayout: boolean;
  customLayoutParams: {
    seriesId: string;
    aggregateFn: string;
  } | null;
  customWellTable: CustomDrilldownTableItem[];
  showVariancePercentage: boolean;
  layoutConfiguration: DrilldownTableLayoutConfigOptions;
};

const initialState: DrilldownTableState = {
  currentVarianceOptions: [],
  currentMarkedRows: [],
  params: {} as DrilldownTableParams,
  optionColumns: [],
  sortDirection: ASC,
  sortCriteria: 'variance',
  sortVarDirectionIndex: 0,
  sortVarPercentDirectionIndex: 0,
  fethcingStatus: {
    isVarianceTableFetching: false,
    isForecastFetching: false,
    isWellTableFetching: false,
    isCustomWellFetching: false,
  },
  fetchingDates: {
    minDate: null,
    maxDate: null,
  },
  varianceTable: [],
  wellTable: [],
  numberOfRoiDays: 0,
  isCustomLayout: false,
  customLayoutParams: null,
  customWellTable: [],
  showVariancePercentage: false,
  layoutConfiguration: 'standard',
};

const DrilldownTableReducer = (
  state: DrilldownTableState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE:
    case FETCH_VARIANCE_DRILLDOWN_TABLE: {
      return R.assocPath<boolean, DrilldownTableState>(
        ['fethcingStatus', 'isVarianceTableFetching'],
        true,
        state,
      );
    }
    case INIT_DRILLDOWN_TABLE_FETCH: {
      const {
        payload: { minDate, maxDate },
      } = action;
      const stateMinDate = state.fetchingDates.minDate;
      const stateMaxDate = state.fetchingDates.maxDate;
      const dates = { minDate: stateMinDate, maxDate: stateMaxDate };
      if (
        minDate !== state.fetchingDates.minDate ||
        maxDate !== state.fetchingDates.maxDate
      ) {
        dates.minDate = minDate;
        dates.maxDate = maxDate;
      }

      return R.compose(
        R.assocPath(['fethcingStatus', 'isVarianceTableFetching'], true),
        R.assocPath(['fethcingStatus', 'isWellTableFetching'], true),
        R.assoc('fetchingDates', dates),
      )(state);
    }
    case FETCH_FILTERED_WELL_DRILLDOWN_TABLE:
    case FETCH_WELL_DRILLDOWN_TABLE: {
      const {
        graphql: {
          variables: {
            payload: { minDate, maxDate },
          },
        },
      } = action.payload;
      const stateMinDate = state.fetchingDates.minDate;
      const stateMaxDate = state.fetchingDates.maxDate;
      const dates = { minDate: stateMinDate, maxDate: stateMaxDate };
      if (
        minDate !== state.fetchingDates.minDate ||
        maxDate !== state.fetchingDates.maxDate
      ) {
        dates.minDate = minDate;
        dates.maxDate = maxDate;
      }

      return R.compose(
        R.assocPath(['fethcingStatus', 'isWellTableFetching'], true),
        R.assoc('fetchingDates', dates),
      )(state);
    }
    case FETCH_CUSTOM_DRILLDOWN_TABLE: {
      return R.compose(
        R.assocPath(['fethcingStatus', 'isCustomWellFetching'], true),
      )(state);
    }
    case FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE:
    case FETCH_FORECAST_DRILLDOWN_TABLE: {
      return R.assocPath<boolean, DrilldownTableState>(
        ['fethcingStatus', 'isForecastFetching'],
        true,
        state,
      );
    }
    case POPULATE_VARIANCE_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isVarianceTableFetching'], false),
        R.assoc('varianceTable', drilldownTable),
      )(state);
    }
    case POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isVarianceTableFetching'], false),
        R.assoc('filteredVarianceTable', drilldownTable),
      )(state);
    }
    case POPULATE_WELL_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isWellTableFetching'], false),
        R.assoc('wellTable', drilldownTable),
      )(state);
    }
    case POPULATE_FILTERED_WELL_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isWellTableFetching'], false),
        R.assoc('filteredWellTable', drilldownTable),
      )(state);
    }
    case POPULATE_FORECAST_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isForecastFetching'], false),
        R.assoc('forecastTable', drilldownTable),
      )(state);
    }
    case LOGOUT: {
      return R.compose(
        R.assoc<VarianceDrilldownTableItem[], DrilldownTableState>(
          'varianceTable',
          [],
        ),
        R.assoc<GroupDrilldownTable[], DrilldownTableState>('groupedTable', []),
        R.assoc('fethcingStatus', initialState.fethcingStatus),
      )(state);
    }
    case CLEAR_DRILLDOWN_TABLE: {
      return R.compose(
        R.assoc<VarianceDrilldownTableItem[], DrilldownTableState>(
          'varianceTable',
          [],
        ),
        R.assoc<GroupDrilldownTable[], DrilldownTableState>('groupedTable', []),
        R.assoc('forecastTable', []),
        R.assoc('wellTable', []),
        R.assoc('filteredWellTable', []),
        R.assoc('filteredVarianceTable', []),
        R.assoc('filteredForecastTable', []),
      )(state);
    }
    case SET_MIN_DRILLDOWN_TABLE_DATE: {
      return R.assocPath<string, DrilldownTableState, Date>(
        ['params', 'minDate'],
        action.payload,
        state,
      );
    }
    case SET_MAX_DRILLDOWN_TABLE_DATE: {
      return R.assocPath<string, DrilldownTableState, Date>(
        ['params', 'maxDate'],
        action.payload,
        state,
      );
    }
    case SET_DRILLDOWN_TABLE_PHASE: {
      return R.assocPath<string, DrilldownTableState, string>(
        ['params', 'phase'],
        action.payload,
        state,
      );
    }
    case SET_DRILLDOWN_TABLE_PARAMS: {
      const newParams = { ...state.params, ...action.payload };
      return R.assoc<DrilldownTableParams, DrilldownTableState, string>(
        'params',
        newParams,
        state,
      );
    }
    case SET_DRILLDOWN_TABLE_OPTION_COLUMNS: {
      let newOptions;
      if (state.optionColumns.includes(action.payload)) {
        newOptions = state.optionColumns.filter(
          option => option !== action.payload,
        );
      } else {
        newOptions = action.payload
          ? [...state.optionColumns, action.payload]
          : state.optionColumns;
      }

      return { ...state, optionColumns: newOptions };
    }
    case SET_SORT_CRITERIA: {
      return R.assoc<string, DrilldownTableState, string>(
        'sortCriteria',
        action.payload,
        state,
      );
    }
    case SET_SORT_DIRECTION: {
      return R.assoc<string, DrilldownTableState, string>(
        'sortDirection',
        action.payload,
        state,
      );
    }
    case SET_SORT_VAR_INDEX: {
      return R.assoc<string, DrilldownTableState, string>(
        'sortVarDirectionIndex',
        action.payload,
        state,
      );
    }
    case SET_SORT_VAR_PERCENT_INDEX: {
      return R.assoc<string, DrilldownTableState, string>(
        'sortVarPercentDirectionIndex',
        action.payload,
        state,
      );
    }
    case SET_IS_CUSTOM_LAYOUT: {
      if (!action.payload) {
        return R.compose(
          R.assoc('isCustomLayout', action.payload),
          R.assoc('customLayoutParams', null),
          R.assoc('customWellTable', []),
        )(state);
      }
      return R.assoc('isCustomLayout', action.payload, state);
    }
    case SET_CUSTOM_LAYOUT_PARAMS: {
      return R.assoc('customLayoutParams', action.payload, state);
    }
    case SET_CUSTOM_WELL_TABLE: {
      return R.compose(
        R.assoc('customWellTable', action.payload),
        R.assocPath(['fethcingStatus', 'isCustomWellFetching'], false),
      )(state);
    }

    case RESET_SORTING: {
      return R.compose(
        R.assoc<string, any, DrilldownTableState>('sortCriteria', 'variance'),
        R.assoc('sortDirection', ASC),
        R.assoc('sortVarDirectionIndex', 0),
      )(state);
    }

    case TOGGLE_VARIANCE_PERCENTAGE: {
      return {
        ...state,
        showVariancePercentage: !state.showVariancePercentage,
      };
    }

    case SET_MARKED_ROWS: {
      return R.assoc('currentMarkedRows', action.payload, state);
    }
    case ADD_MARKED_ROW: {
      return R.assoc(
        'currentMarkedRows',
        [...state.currentMarkedRows, action.payload],
        state,
      );
    }
    case ADD_SEVERAL_MARKED_ROWS: {
      return R.assoc(
        'currentMarkedRows',
        [...state.currentMarkedRows, ...action.payload],
        state,
      );
    }
    case RESET_MARKED_ROWS: {
      return R.assoc(
        'currentMarkedRows',
        state.currentMarkedRows.length > 0
          ? state.currentMarkedRows.slice(0, 1)
          : [],
        state,
      );
    }
    case REMOVE_MARKED_ROW: {
      return R.assoc(
        'currentMarkedRows',
        state.currentMarkedRows.filter(w => w !== action.payload),
        state,
      );
    }
    case SET_CURRENT_VARIANCE_OPTION: {
      const { optionId } = action.payload;

      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        [optionId],
        state,
      );
    }
    case RESET_CURRENT_VARIANCE_OPTION: {
      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        [],
        state,
      );
    }
    case ADD_CURRENT_VARIANCE_OPTION: {
      const newVarOption = action.payload.optionId;
      const prevOptions = state.currentVarianceOptions;

      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        [...prevOptions, newVarOption],
        state,
      );
    }
    case ADD_SEVERAL_VARIANCE_OPTIONS: {
      const newVarOptions = action.payload.optionIds;
      const prevOptions = state.currentVarianceOptions;

      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        [...prevOptions, ...newVarOptions],
        state,
      );
    }
    case REMOVE_ONE_VARIANCE_OPTION: {
      const varianceOptionIdToRemove = action.payload.optionId;
      const newOptions = state.currentVarianceOptions.filter(
        optionId => optionId !== varianceOptionIdToRemove,
      );

      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        newOptions,
        state,
      );
    }
    case SET_NUMBER_OF_ROI_DAYS: {
      return R.assoc('numberOfRoiDays', action.payload.days, state);
    }
    case SET_LAYOUT_CONFIGURATION: {
      return R.assoc('layoutConfiguration', action.payload, state);
    }
    default: {
      return state;
    }
  }
};

export const getDrilldownTableState = (state: RootState): DrilldownTableState =>
  state[STATE_KEY];

export const getCurrentLayoutConfiguration: Selector<DrilldownTableLayoutConfigOptions> =
  createSelector(getDrilldownTableState, state => state.layoutConfiguration);

export const getIsCustomLayout: Selector<boolean> = createSelector(
  getDrilldownTableState,
  drilldowntableState => drilldowntableState.isCustomLayout,
);

export const getIsCustomLayoutParams: Selector<{
  seriesId: string;
  aggregateFn: string;
} | null> = createSelector(
  getDrilldownTableState,
  drilldowntableState => drilldowntableState.customLayoutParams,
);

export const getCustomWellTable: Selector<CustomDrilldownTableItem[] | null> =
  createSelector(
    getDrilldownTableState,
    drilldowntableState => drilldowntableState.customWellTable,
  );

export const getRawVarianceDrilldownTable = (
  state: RootState,
): VarianceDrilldownTableItem[] => state[STATE_KEY].varianceTable || [];

export const getRawFilteredVarianceDrilldownTable = (
  state: DrilldownTableState,
): VarianceDrilldownTableItem[] => state[STATE_KEY].filteredVarianceTable || [];

export const getRawCommonVarianceDrilldownTable = createSelector(
  getRawFilteredVarianceDrilldownTable,
  getRawVarianceDrilldownTable,
  (filtered, full): VarianceDrilldownTableItem[] =>
    R.isEmpty(full) ? filtered : full,
);

export const getRawWellDrilldownTable = (
  state: DrilldownTableState,
): VarianceDrilldownTableItem[] => state[STATE_KEY].wellTable || [];

export const getRawFilteredWellDrilldownTable = (
  state: DrilldownTableState,
): VarianceDrilldownTableItem[] => state[STATE_KEY].filteredWellTable || [];

export const getRawCommonWellDrilldownTable = createSelector(
  getRawFilteredWellDrilldownTable,
  getRawWellDrilldownTable,
  (filtered, full): VarianceDrilldownTableItem[] =>
    R.isEmpty(full) ? filtered : full,
);

export const getRawForecastDrilldownTable = (
  state: DrilldownTableState,
): VarianceDrilldownTableItem[] => state[STATE_KEY].forecastTable || [];

export const getCurrentVarianceOptions = (state: RootState): string[] =>
  state[STATE_KEY].currentVarianceOptions;

export const getDrilldownTableParams: Selector<DrilldownTableParams> =
  createSelector(
    getDrilldownTableState,
    getColumnMapping,
    (drilldownTableState, columnMapping) => {
      const { params } = drilldownTableState;
      const hasNri = columnMapping.some(item => item.wiserockBinding === 'NRI');
      const defaultDates = getDefaulDrilldownDates();
      const minDate = new Date(
        R.defaultTo(defaultDates.minDate, params.minDate),
      );
      const maxDate = new Date(
        R.defaultTo(defaultDates.maxDate, params.maxDate),
      );
      const compareOption = params.compareOption || COMPARE_OPTION.actual;
      const grossNet =
        hasNri && R.pathOr(false, ['params', 'grossNet'], drilldownTableState)
          ? params.grossNet
          : 'Gross';
      return !params || R.isEmpty(params)
        ? initialState.params
        : {
            minDate,
            maxDate,
            phase: params.phase || 'Oil',
            grossNet,
            rateVolume: params.rateVolume || 'Rate',
            compareOption,
          };
    },
  );

export const getActualGrossNet: Selector<string> = createSelector(
  getDrilldownTableState,
  drilldownTableState =>
    !drilldownTableState.params || R.isEmpty(drilldownTableState.params)
      ? ''
      : drilldownTableState.params.grossNet,
);

export const getGrossNet: Selector<string> = createSelector(
  getActualGrossNet,
  getColumnMapping,
  (grossNet, columnMapping) => {
    if (
      R.isEmpty(columnMapping) ||
      columnMapping.some(item => item.wiserockBinding === 'NRI')
    ) {
      return grossNet;
    }
    return 'Gross';
  },
);

export const getNumberOfRoiDays = (state: RootState): number =>
  state[STATE_KEY].numberOfRoiDays;

export const getFilteredVarianceTable = createSelector(
  getRawCommonVarianceDrilldownTable,
  getDrilldownTableParams,
  getNumberOfRoiDays,
  state => state.filter || {},
  getWellsWithCustomValuesAsObject,
  (
    varianceTable,
    params,
    numberOfRoiDays,
    filters,
    wells,
  ): VarianceDrilldownTableItem[] => {
    return filterDrilldownTable(
      varianceTable,
      params,
      numberOfRoiDays,
      filters,
      wells,
    );
  },
);

export const getFilteredWellTable = createSelector(
  getRawCommonWellDrilldownTable,
  getDrilldownTableParams,
  getNumberOfRoiDays,
  (state: RootState) => state.filter || {},
  getWellsWithCustomValuesAsObject,
  (
    wellTabel,
    params,
    numberOfRoiDays,
    filters,
    wells,
  ): WellDrilldownTableItem[] => {
    return filterDrilldownTable(
      wellTabel,
      params,
      numberOfRoiDays,
      filters,
      wells,
    );
  },
);

export const getWellDrilldownTable = createSelector(
  getRawCommonVarianceDrilldownTable,
  getRawCommonWellDrilldownTable,
  getDrilldownTableParams,
  getCurrentVarianceOptions,
  getNumberOfRoiDays,
  (state: RootState) => state.filter || {},
  getWellsWithCustomValuesAsObject,
  (
    varianceTable,
    wellTabel,
    params,
    currentVarianceOptions,
    numberOfRoiDays,
    filters,
    wells,
  ): VarianceDrilldownTableItem[] => {
    const filteredWellTable = filterDrilldownTable(
      wellTabel,
      params,
      numberOfRoiDays,
      filters,
      wells,
    );

    const wellTablewithoutEmptyWells = (
      filteredWellTable as WellDrilldownTableItem[]
    ).reduce((acc, item) => {
      if (!item.wellId) return acc;
      if (!acc[item.wellId]) {
        acc[item.wellId] = item;
        return acc;
      }
      acc[item.wellId] = {
        ...acc[item.wellId],
        oilVariance: acc[item.wellId].oilVariance + item.oilVariance,
        gasVariance: acc[item.wellId].gasVariance + item.gasVariance,
        waterVariance: acc[item.wellId].waterVariance + item.waterVariance,
        boeVariance: acc[item.wellId].boeVariance + item.boeVariance,
        oilActual: acc[item.wellId].oilActual + item.oilActual,
        gasActual: acc[item.wellId].gasActual + item.gasActual,
        waterActual: acc[item.wellId].waterActual + item.waterActual,
        boeActual: acc[item.wellId].boeActual + item.boeActual,
        oilCapacity: acc[item.wellId].oilCapacity + item.oilCapacity,
        gasCapacity: acc[item.wellId].gasCapacity + item.gasCapacity,
        waterCapacity: acc[item.wellId].waterCapacity + item.waterCapacity,
        boeCapacity: acc[item.wellId].boeCapacity + item.boeCapacity,
      };
      return acc;
    }, {});
    if (R.isEmpty(currentVarianceOptions)) {
      return R.values(wellTablewithoutEmptyWells);
    }
    const filteredVarianceTable = filterDrilldownTable(
      varianceTable,
      params,
      numberOfRoiDays,
      filters,
      wells,
    );
    const filteredTable = filteredVarianceTable.filter(item =>
      currentVarianceOptions.includes(item.varianceOptionId),
    );

    const varianceTablewithoutEmptyWells = (
      filteredTable as VarianceDrilldownTableItem[]
    ).reduce((acc, item) => {
      if (!item.wellId || !wellTablewithoutEmptyWells[item.wellId]) return acc;
      if (!acc[item.wellId]) {
        acc[item.wellId] = {
          ...wellTablewithoutEmptyWells[item.wellId],
          oilVariance: item.oilVariance,
          gasVariance: item.gasVariance,
          waterVariance: item.waterVariance,
          boeVariance: item.boeVariance,
        };

        return acc;
      }
      acc[item.wellId] = {
        ...acc[item.wellId],
        oilVariance: acc[item.wellId].oilVariance + item.oilVariance,
        gasVariance: acc[item.wellId].gasVariance + item.gasVariance,
        waterVariance: acc[item.wellId].waterVariance + item.waterVariance,
        boeVariance: acc[item.wellId].boeVariance + item.boeVariance,
        oilActual: wellTablewithoutEmptyWells[item.wellId].oilActual,
        gasActual: wellTablewithoutEmptyWells[item.wellId].gasActual,
        waterActual: wellTablewithoutEmptyWells[item.wellId].waterActual,
        boeActual: wellTablewithoutEmptyWells[item.wellId].boeActual,
        oilCapacity: wellTablewithoutEmptyWells[item.wellId].oilCapacity,
        gasCapacity: wellTablewithoutEmptyWells[item.wellId].gasCapacity,
        waterCapacity: wellTablewithoutEmptyWells[item.wellId].waterCapacity,
        boeCapacity: wellTablewithoutEmptyWells[item.wellId].boeCapacity,
      };
      return acc;
    }, {});

    return R.values(varianceTablewithoutEmptyWells);
  },
);

export const getDrilldowTablePhase: Selector<string> = createSelector(
  getDrilldownTableParams,
  params => params.phase,
);

export const getSortCriteria: Selector<string> = createSelector(
  getDrilldownTableState,
  drilldowntableState => drilldowntableState.sortCriteria || 'variance',
);

export const getSortDirection: Selector<string> = createSelector(
  getDrilldownTableState,
  drilldowntableState => drilldowntableState.sortDirection || 'ASC',
);

export const getSortVarDirectionIndex: Selector<number> = createSelector(
  getDrilldownTableState,
  drilldowntableState => drilldowntableState.sortVarDirectionIndex || 0,
);
export const getSortVarPercentDirectionIndex: Selector<number> = createSelector(
  getDrilldownTableState,
  drilldowntableState => drilldowntableState.sortVarPercentDirectionIndex || 0,
);

export const getFullGroupedWellTable = createCachedSelector(
  getRawWellDrilldownTable,
  (_: RootState, props) => props.subject,
  (
    table: VarianceDrilldownTableItem[],
    groupingSubject,
  ): GroupDrilldownTable[] => createGroupedTable(table, groupingSubject),
)((state, props) => props.subject || 'well');

export const getGroupedCustomWellTable = createSelector(
  [getCustomWellTable, getGroupMode],
  (table: CustomDrilldownTableItem[] | null, groupMode) =>
    createGroupedCustomWellTable(table, groupMode.subject),
);

export const getSortedGroupedCustomTable = createSelector(
  [getGroupedCustomWellTable, getSortCriteria, getSortDirection],
  (customWellTable, sortCriteria, sortDirection) => {
    if (!sortCriteria) return customWellTable;

    const sortCriterias = ['value', 'cap', 'act', 'var'];

    if (sortCriterias.includes(sortCriteria)) {
      if (sortDirection === ASC) {
        return [...(customWellTable ? customWellTable : [])].sort((a, b) => {
          if (a[sortCriteria] > b[sortCriteria]) return 1;
          if (a[sortCriteria] < b[sortCriteria]) return -1;
          return 0;
        });
      }
      return [...(customWellTable ? customWellTable : [])].sort((a, b) => {
        if (a[sortCriteria] < b[sortCriteria]) return 1;
        if (a[sortCriteria] > b[sortCriteria]) return -1;
        return 0;
      });
    }
    if (sortDirection === ASC) {
      return [...(customWellTable ? customWellTable : [])].sort((a, b) =>
        naturalSorting(
          a[sortCriteria]?.toLowerCase() || '',
          b[sortCriteria]?.toLowerCase() || '',
        ),
      );
    }
    return [...(customWellTable ? customWellTable : [])].sort(
      (a, b) =>
        0 -
        naturalSorting(
          a[sortCriteria]?.toLowerCase() || '',
          b[sortCriteria]?.toLowerCase() || '',
        ),
    );
  },
);

export const getGroupedWellTable = createCachedSelector(
  getWellDrilldownTable,
  (_: RootState, props) => props.subject,
  (
    table: VarianceDrilldownTableItem[],
    groupingSubject,
  ): GroupDrilldownTable[] => createGroupedTable(table, groupingSubject),
)((state, props) => props.subject || 'well');

export const getTotalRowsCount = createSelector(
  getGroupMode,
  getFullGroupedWellTable,
  getRawWellDrilldownTable,
  (groupMode, groupedTable, wellTable): number => {
    return groupMode.isOn ? groupedTable.length : wellTable.length;
  },
);

export const getSortedWellDrilldownTable = createSelector(
  [
    getSortCriteria,
    getSortDirection,
    getSortVarDirectionIndex,
    getSortVarPercentDirectionIndex,
    getDrilldownTableParams,
    getWellDrilldownTable,
  ],
  (
    sortCriteria: string,
    sortDirection: string,
    sortVarDirectionIndex: number,
    sortVarPercentDirectionIndex: number,
    drilldownTableParams: DrilldownTableParams,
    drilldownTableFiltered: VarianceDrilldownTableItem[],
  ): DrilldownTable[] => {
    if (R.isEmpty(drilldownTableParams)) return [];
    const phase = drilldownTableParams.phase.toLowerCase();
    const sorted = sortTable(
      sortCriteria,
      sortDirection,
      sortVarDirectionIndex,
      sortVarPercentDirectionIndex,
      phase,
      drilldownTableFiltered,
    );

    const table = sorted.map(tableItem => ({
      well: tableItem.well,
      wellId: tableItem.wellId,
      variancePercentage: calculateVariancePercentage({
        variance: tableItem[`${phase}Variance`],
        capacity: tableItem[`${phase}Capacity`],
      }),
      variance: tableItem[`${phase}Variance`],
      actual: tableItem[`${phase}Actual`],
      capacity: tableItem[`${phase}Capacity`],
    }));

    return table;
  },
);
export const getSortedGroupedTable = createCachedSelector(
  [
    getSortCriteria,
    getSortDirection,
    getSortVarDirectionIndex,
    getSortVarPercentDirectionIndex,
    getDrilldownTableParams,
    getGroupedWellTable,
  ],
  (
    sortCriteria,
    sortDirection,
    sortVarDirectionIndex,
    sortVarPercentDirectionIndex,
    drilldownTableParams,
    groupedTable,
  ): VarianceDrilldownTableItem[] | undefined => {
    if (R.isEmpty(drilldownTableParams)) return;
    const phase = drilldownTableParams.phase.toLowerCase();
    const sorted = sortTable(
      sortCriteria,
      sortDirection,
      sortVarDirectionIndex,
      sortVarPercentDirectionIndex,
      phase,
      groupedTable as any,
    );
    const table = sorted.map(tableItem => {
      const itemWithoutPhases = R.omit(
        ['oil', 'gas', 'water', 'boe'],
        tableItem,
      );
      return {
        ...itemWithoutPhases,
        variance: tableItem[`${phase}Variance`],
        actual: tableItem[`${phase}Actual`],
        capacity: tableItem[`${phase}Capacity`],
        variancePercentage: calculateVariancePercentage({
          capacity: tableItem[`${phase}Capacity`],
          variance: tableItem[`${phase}Variance`],
        }),
      };
    });

    return table as VarianceDrilldownTableItem[];
  },
)((state, props) => props.subject || 'well');

export const getShowVariancePercentage = (state: RootState): boolean =>
  state[STATE_KEY].showVariancePercentage;

export const getCurrentMarkedRows = (state: RootState): string[] =>
  state[STATE_KEY].currentMarkedRows;

export const getCurrentWellNamesgetCurrentWellNames = createSelector(
  getCurrentMarkedRows,
  getAllWells,
  (currentWellIds, wells) => {
    return currentWellIds.map(id => wells[id]?.LEASE).filter(w => !!w);
  },
);
export const getIsMarkingRows = (state: RootState) =>
  state[STATE_KEY].currentMarkedRows.length > 1;

export const getVarianceDrilldownTable = createSelector(
  getRawCommonVarianceDrilldownTable,
  getDrilldownTableParams,
  getNumberOfRoiDays,
  (state: RootState) => state.filter || {},
  getWellsWithCustomValuesAsObject,
  getFlagsStateMap,
  getCurrentMarkedRows,
  getGroupMode,
  (
    table,
    params,
    numberOfRoiDays,
    filters,
    wells,
    { drilldownMarking, varianceDrilldownMarkingFilter },
    currentMarkedRows,
    groupMode,
  ): VarianceDrilldownTableItem[] => {
    const filterByWells =
      !groupMode.isOn && varianceDrilldownMarkingFilter
        ? Object.values(wells).reduce((acc, n) => {
            if (!drilldownMarking || currentMarkedRows.includes(n.LEASE))
              acc[n.id] = n;
            return acc;
          }, {})
        : wells;

    const grouppedFilter =
      groupMode.isOn && groupMode.subject !== 'all'
        ? {
            [groupMode.subject || '']: currentMarkedRows,
          }
        : {};

    const filteredTable: VarianceDrilldownTableItem[] =
      filterDrilldownTable(
        table,
        params,
        numberOfRoiDays,
        { ...filters, ...grouppedFilter },
        filterByWells,
      ) || [];

    const groupedByVarianceOption = filteredTable.reduce((acc, item) => {
      if (!acc[item.varianceOptionId]) {
        acc[item.varianceOptionId] = item;
        return acc;
      }
      acc[item.varianceOptionId] = {
        ...acc[item.varianceOptionId],
        oilVariance: acc[item.varianceOptionId].oilVariance + item.oilVariance,
        gasVariance: acc[item.varianceOptionId].gasVariance + item.gasVariance,
        waterVariance:
          acc[item.varianceOptionId].waterVariance + item.waterVariance,
        boeVariance: acc[item.varianceOptionId].boeVariance + item.boeVariance,
        oilActual: acc[item.varianceOptionId].oilActual + item.oilActual,
        gasActual: acc[item.varianceOptionId].gasActual + item.gasActual,
        waterActual: acc[item.varianceOptionId].waterActual + item.waterActual,
        boeActual: acc[item.varianceOptionId].boeActual + item.boeActual,
        oilCapacity: acc[item.varianceOptionId].oilCapacity + item.oilCapacity,
        gasCapacity: acc[item.varianceOptionId].gasCapacity + item.gasCapacity,
        waterCapacity:
          acc[item.varianceOptionId].waterCapacity + item.waterCapacity,
        boeCapacity: acc[item.varianceOptionId].boeCapacity + item.boeCapacity,
      };

      return acc;
    }, {});

    return R.values(groupedByVarianceOption);
  },
);

export const getSortedVarianceDrilldownTable = createSelector(
  getVarianceDrilldownTable,
  getDrilldownTableParams,
  getLocalAppSettings,
  (
    varianceDrilldownTable,
    drilldownTableParams,
    appSettings,
  ): VarianceTableItem[] => {
    if (R.isEmpty(drilldownTableParams)) return [];
    const phase = drilldownTableParams.phase.toLowerCase();

    const totalVariance = varianceDrilldownTable.reduce(
      (acc, n) => (acc += n[`${phase}Variance`]),
      0,
    );

    const withVariance: VarianceTableItem[] = varianceDrilldownTable.map(
      tableItem => {
        const percent = parseFloat(
          ((tableItem[`${phase}Variance`] / totalVariance || 0) * 100).toFixed(
            1,
          ),
        );
        return {
          varianceOptionId: tableItem.varianceOptionId,
          subCause: tableItem.subCause,
          planType: tableItem.planType,
          color: tableItem.color,
          percent: (percent === 0 ? 0 : percent).toFixed(1),
          currency:
            phase === 'boe'
              ? tableItem[`gasVariance`] * appSettings.gasPriceAssumption +
                tableItem[`oilVariance`] * appSettings.oilPriceAssumption
              : tableItem[`${phase}Variance`] *
                (appSettings[`${phase}PriceAssumption`] ?? 0),
          variance: tableItem[`${phase}Variance`],
          actual: tableItem[`${phase}Actual`],
          capacity: tableItem[`${phase}Capacity`],
        };
      },
    );

    return withVariance.sort((a, b) => a.variance - b.variance);
  },
);

export const getDrilldownLoadingStatus = (
  state: RootState,
): {
  isVarianceTableFetching: boolean;
  isForecastFetching: boolean;
  isWellTableFetching: boolean;
  isCustomWellFetching: boolean;
} => state[STATE_KEY].fethcingStatus;

export const getWellProductionStartDate = createSelector(
  getRawCommonVarianceDrilldownTable,
  getCurrentWellId,
  getToday,
  (wells, currentWellId, defaultDate): Date => {
    const currentWell = wells.find(well => well.wellId === currentWellId);
    return R.pathOr(defaultDate, ['startProdDate'], currentWell);
  },
);

export const getGroupProductionStartDate = createSelector(
  getRawCommonWellDrilldownTable,
  getDrilldownTableParams,
  getNumberOfRoiDays,
  (state: RootState) => state.filter || {},
  getWellsWithCustomValuesAsObject,
  getCurrentGroup,
  getToday,
  (
    table,
    params,
    numberOfRoiDays,
    filters,
    wells,
    { subject, item },
    defaultDate,
  ): Date => {
    const filterTable =
      filterDrilldownTable(table, params, numberOfRoiDays, filters, wells) ||
      [];

    if (subject === 'all') {
      const startProdDate = filterTable.reduce(
        (min, well: VarianceDrilldownTableItem) => {
          const tempDate = new Date(
            well.startProdDate ? well.startProdDate : defaultDate,
          );
          return min > tempDate ? tempDate : min;
        },
        defaultDate,
      );
      return R.defaultTo(defaultDate, startProdDate);
    }
    const groupedWells = R.groupBy(R.prop(subject), filterTable);
    const currentGroup = R.pathOr([], [item], groupedWells);
    const startProdDate = currentGroup.reduce((min, well) => {
      const tempDate = new Date(
        well.startProdDate ? well.startProdDate : defaultDate,
      );
      return min > tempDate ? tempDate : min;
    }, defaultDate);
    return R.defaultTo(defaultDate, startProdDate);
  },
);

export const getSortedCustomWellTable: Selector<
  CustomDrilldownTableItem[] | null | undefined
> = createSelector(
  getCustomWellTable,
  getSortCriteria,
  getSortDirection,
  getDrilldownTableParams,
  (state: RootState) => state.filter || {},
  getWellsWithCustomValuesAsObject,
  getNumberOfRoiDays,
  (
    table: CustomDrilldownTableItem[] | null,
    sortCriteria: string,
    sortDirection: string,
    params,
    filters,
    wells,
    roi,
  ) => {
    const customWellTable = filterDrilldownTable(
      table ?? [],
      params,
      roi,
      filters,
      wells,
    );
    if (sortCriteria !== 'well') {
      if (sortDirection === ASC) {
        return [...(customWellTable ? customWellTable : [])].sort((a, b) => {
          if (a[sortCriteria] > b[sortCriteria]) return 1;
          if (a[sortCriteria] < b[sortCriteria]) return -1;
          return 0;
        });
      }
      return [...(customWellTable ? customWellTable : [])].sort((a, b) => {
        if (a[sortCriteria] < b[sortCriteria]) return 1;
        if (a[sortCriteria] > b[sortCriteria]) return -1;
        return 0;
      });
    }
    if (sortCriteria === 'well') {
      if (sortDirection === ASC) {
        return [...(customWellTable ? customWellTable : [])].sort((a, b) =>
          naturalSorting(a.well.toLowerCase(), b.well.toLowerCase()),
        );
      }
      return [...(customWellTable ? customWellTable : [])].sort(
        (a, b) =>
          0 - naturalSorting(a.well.toLowerCase(), b.well.toLowerCase()),
      );
    }
    return customWellTable;
  },
);

const getFiltredTable = (filters, table, wells) => {
  const isNotEmpty = (val, key) => !R.isEmpty(val);
  const filtersWithoutEmpty = R.pickBy(isNotEmpty, filters);

  const filteredWellIdIndexedList = R.values(wells).reduce((acc, well) => {
    const isIncluded = R.keys(filtersWithoutEmpty).every(
      filterName =>
        filters[filterName].includes(well[filterName]) ||
        (R.path([filterName, 0], filters) === '' && well[filterName] === null),
    );
    if (isIncluded) acc[well.id] = well;

    return acc;
  }, {});
  const filteredTable = table.filter(tableItem =>
    Boolean(filteredWellIdIndexedList[tableItem.wellId]),
  );
  return filteredTable;
};

export const getFilteredForecastDrilldownTableItems = createSelector(
  getRawCommonWellDrilldownTable,
  getDrilldownTableParams,
  getNumberOfRoiDays,
  (state: RootState) => state.filter || {},
  getWellsWithCustomValuesAsObject,
  (
    table,
    params,
    numberOfRoiDays,
    filters,
    wells,
  ): VarianceDrilldownTableItem[] => {
    const filteredTable = getFiltredTable(filters, table, wells);
    const isCapacityVsExternal = params.compareOption === 'extVsCap';

    const grossNetNormalized = filteredTable.map(tableItem => {
      if (params.grossNet === 'Gross') {
        const {
          oilCapVsExtVariance,
          gasCapVsExtVariance,
          waterCapVsExtVariance,
          boeCapVsExtVariance,
          oilActVsExtVariance,
          gasActVsExtVariance,
          waterActVsExtVariance,
          boeActVsExtVariance,
          oilCapacity,
          waterCapacity,
          gasCapacity,
          boeCapacity,
          oilForecast,
          waterForecast,
          gasForecast,
          boeForecast,
          oilActual,
          waterActual,
          gasActual,
          boeActual,
        } = tableItem;

        const oilVariance = isCapacityVsExternal
          ? oilCapVsExtVariance
          : oilActVsExtVariance;
        const gasVariance = isCapacityVsExternal
          ? gasCapVsExtVariance
          : gasActVsExtVariance;
        const waterVariance = isCapacityVsExternal
          ? waterCapVsExtVariance
          : waterActVsExtVariance;
        const boeVariance = isCapacityVsExternal
          ? boeCapVsExtVariance
          : boeActVsExtVariance;
        const oilSecondCol = isCapacityVsExternal ? oilForecast : oilActual;
        const gasSecondCol = isCapacityVsExternal ? gasForecast : gasActual;
        const waterSecondCol = isCapacityVsExternal
          ? waterForecast
          : waterActual;
        const boeSecondCol = isCapacityVsExternal ? boeForecast : boeActual;
        const oilThirdCol = isCapacityVsExternal ? oilCapacity : oilForecast;
        const gasThirdCol = isCapacityVsExternal ? gasCapacity : gasForecast;
        const waterThirdCol = isCapacityVsExternal
          ? waterCapacity
          : waterForecast;
        const boeThirdCol = isCapacityVsExternal ? boeCapacity : boeForecast;

        return {
          ...tableItem,
          oilVariance,
          waterVariance,
          gasVariance,
          boeVariance,
          oilThirdCol,
          waterThirdCol,
          gasThirdCol,
          boeThirdCol,
          oilSecondCol,
          waterSecondCol,
          gasSecondCol,
          boeSecondCol,
        };
      }

      const nriNumber = wells[tableItem.wellId]?.NRI
        ? parseFloat(wells[tableItem.wellId].NRI)
        : 1;

      const nriNormalized = nriNumber <= 1 && nriNumber >= 0 ? nriNumber : 1;

      const {
        oilCapVsExtVariance,
        gasCapVsExtVariance,
        waterCapVsExtVariance,
        boeCapVsExtVariance,
        oilActVsExtVariance,
        gasActVsExtVariance,
        waterActVsExtVariance,
        boeActVsExtVariance,
        oilCapacity,
        waterCapacity,
        gasCapacity,
        boeCapacity,
        oilForecast,
        waterForecast,
        gasForecast,
        boeForecast,
        oilActual,
        waterActual,
        gasActual,
        boeActual,
      } = tableItem;

      const oilVariance = isCapacityVsExternal
        ? oilCapVsExtVariance
        : oilActVsExtVariance;
      const gasVariance = isCapacityVsExternal
        ? gasCapVsExtVariance
        : gasActVsExtVariance;
      const waterVariance = isCapacityVsExternal
        ? waterCapVsExtVariance
        : waterActVsExtVariance;
      const boeVariance = isCapacityVsExternal
        ? boeCapVsExtVariance
        : boeActVsExtVariance;
      const oilSecondCol = isCapacityVsExternal ? oilForecast : oilActual;
      const gasSecondCol = isCapacityVsExternal ? gasForecast : gasActual;
      const waterSecondCol = isCapacityVsExternal ? waterForecast : waterActual;
      const boeSecondCol = isCapacityVsExternal ? boeForecast : boeActual;
      const oilThirdCol = isCapacityVsExternal ? oilCapacity : oilForecast;
      const gasThirdCol = isCapacityVsExternal ? gasCapacity : gasForecast;
      const waterThirdCol = isCapacityVsExternal
        ? waterCapacity
        : waterForecast;
      const boeThirdCol = isCapacityVsExternal ? boeCapacity : boeForecast;

      return {
        ...tableItem,
        oilVariance: R.isNil(oilVariance)
          ? oilVariance
          : oilVariance * nriNormalized,
        waterVariance: R.isNil(waterVariance)
          ? waterVariance
          : waterVariance * nriNormalized,
        gasVariance: R.isNil(gasVariance)
          ? gasVariance
          : gasVariance * nriNormalized,
        boeVariance: R.isNil(boeVariance)
          ? boeVariance
          : boeVariance * nriNormalized,
        oilThirdCol: R.isNil(oilThirdCol)
          ? oilThirdCol
          : oilThirdCol * nriNormalized,
        waterThirdCol: R.isNil(waterThirdCol)
          ? waterThirdCol
          : waterThirdCol * nriNormalized,
        gasThirdCol: R.isNil(gasThirdCol)
          ? gasThirdCol
          : gasThirdCol * nriNormalized,
        boeThirdCol: R.isNil(boeThirdCol)
          ? boeThirdCol
          : boeThirdCol * nriNormalized,
        oilSecondCol: R.isNil(oilSecondCol)
          ? oilSecondCol
          : oilSecondCol * nriNormalized,
        waterSecondCol: R.isNil(waterSecondCol)
          ? waterSecondCol
          : waterSecondCol * nriNormalized,
        gasSecondCol: R.isNil(gasSecondCol)
          ? gasSecondCol
          : gasSecondCol * nriNormalized,
        boeSecondCol: R.isNil(boeSecondCol)
          ? boeSecondCol
          : boeSecondCol * nriNormalized,
      };
    });
    const rateVolumeNormalized = grossNetNormalized.map(tableItem => {
      if (params.rateVolume === 'Rate') return tableItem;
      const {
        oilActual,
        waterActual,
        gasActual,
        boeActual,
        oilVariance,
        waterVariance,
        gasVariance,
        boeVariance,
        oilThirdCol,
        waterThirdCol,
        gasThirdCol,
        boeThirdCol,
        oilSecondCol,
        waterSecondCol,
        gasSecondCol,
        boeSecondCol,
      } = tableItem;
      return {
        ...tableItem,
        oilActual: oilActual * numberOfRoiDays,
        waterActual: waterActual * numberOfRoiDays,
        gasActual: gasActual * numberOfRoiDays,
        boeActual: boeActual * numberOfRoiDays,
        oilVariance: R.isNil(oilVariance)
          ? oilVariance
          : oilVariance * numberOfRoiDays,
        waterVariance: R.isNil(waterVariance)
          ? waterVariance
          : waterVariance * numberOfRoiDays,
        gasVariance: R.isNil(gasVariance)
          ? gasVariance
          : gasVariance * numberOfRoiDays,
        boeVariance: R.isNil(boeVariance)
          ? boeVariance
          : boeVariance * numberOfRoiDays,
        oilThirdCol: R.isNil(oilThirdCol)
          ? oilThirdCol
          : oilThirdCol * numberOfRoiDays,
        waterThirdCol: R.isNil(waterThirdCol)
          ? waterThirdCol
          : waterThirdCol * numberOfRoiDays,
        gasThirdCol: R.isNil(gasThirdCol)
          ? gasThirdCol
          : gasThirdCol * numberOfRoiDays,
        boeThirdCol: R.isNil(boeThirdCol)
          ? boeThirdCol
          : boeThirdCol * numberOfRoiDays,
        oilSecondCol: R.isNil(oilSecondCol)
          ? oilSecondCol
          : oilSecondCol * numberOfRoiDays,
        waterSecondCol: R.isNil(waterSecondCol)
          ? waterSecondCol
          : waterSecondCol * numberOfRoiDays,
        gasSecondCol: R.isNil(gasSecondCol)
          ? gasSecondCol
          : gasSecondCol * numberOfRoiDays,
        boeSecondCol: R.isNil(boeSecondCol)
          ? boeSecondCol
          : boeSecondCol * numberOfRoiDays,
      };
    });
    return rateVolumeNormalized;
  },
);

export const getSortedWellForecastDrilldownTable = createSelector(
  [
    getSortCriteria,
    getSortDirection,
    getSortVarDirectionIndex,
    getSortVarPercentDirectionIndex,
    getDrilldownTableParams,
    getFilteredForecastDrilldownTableItems,
    getNumberOfRoiDays,
  ],
  (
    sortCriteria,
    sortDirection,
    sortVarDirectionIndex,
    sortVarPercentDirectionIndex,
    drilldownTableParams,
    drilldownTableFiltered,
    numberOfRoiDays,
  ): DrilldownTable[] => {
    if (R.isEmpty(drilldownTableParams)) return [];
    const phase = drilldownTableParams.phase.toLowerCase();

    const sorted = sortForecastTable(
      sortCriteria,
      sortDirection,
      sortVarDirectionIndex,
      sortVarPercentDirectionIndex,
      phase,
      R.clone(drilldownTableFiltered),
      drilldownTableParams.compareOption,
    );

    const table = sorted.map(tableItem => {
      const actual = tableItem[`${phase}Actual`];
      const secondCol = tableItem[`${phase}SecondCol`];
      const thirdCol = tableItem[`${phase}ThirdCol`];
      const variance =
        thirdCol === null &&
        drilldownTableParams.compareOption === COMPARE_OPTION.extVsCap
          ? actual - secondCol
          : tableItem[`${phase}Variance`];
      const variancePercentage = calculateVariancePercentage({
        capacity:
          drilldownTableParams.compareOption === COMPARE_OPTION.extVsCap
            ? secondCol
            : thirdCol,
        variance,
      });

      return {
        well: tableItem.well,
        wellId: tableItem.wellId,
        actual,
        variance,
        secondCol,
        thirdCol,
        variancePercentage,
      };
    });

    return table;
  },
);

export const getGroupedWellForecastTable = createCachedSelector(
  getFilteredForecastDrilldownTableItems,
  getDrilldownTableParams,
  (_: RootState, props) => props.subject,
  (
    table: VarianceDrilldownTableItem[],
    params,
    groupingSubject,
  ): GroupDrilldownTable[] =>
    createForecastGroupedTable(table, params.compareOption, groupingSubject),
)((state, props) => props.subject || 'well');

export const getSortedForecastGroupedTable = createCachedSelector(
  [
    getSortCriteria,
    getSortDirection,
    getSortVarDirectionIndex,
    getSortVarPercentDirectionIndex,
    getDrilldownTableParams,
    getGroupedWellForecastTable,
  ],
  (
    sortCriteria,
    sortDirection,
    sortVarDirectionIndex,
    sortVarPercentDirectionIndex,
    drilldownTableParams,
    groupedTable,
  ) => {
    if (R.isEmpty(drilldownTableParams)) return;
    const phase = drilldownTableParams.phase.toLowerCase();
    const sorted = sortForecastTable(
      sortCriteria,
      sortDirection,
      sortVarDirectionIndex,
      sortVarPercentDirectionIndex,
      phase,
      R.clone(groupedTable) as any,
      drilldownTableParams.compareOption,
    );
    const table = sorted.map(tableItem => {
      const itemWithoutPhases = R.omit(
        ['oil', 'gas', 'water', 'boe'],
        tableItem,
      );
      return {
        ...itemWithoutPhases,
        variance: tableItem[`${phase}Variance`],
        secondCol: tableItem[`${[phase]}SecondCol`],
        thirdCol: tableItem[`${phase}ThirdCol`],
        variancePercentage: calculateVariancePercentage({
          capacity: tableItem[`${phase}ThirdCol`],
          variance: tableItem[`${phase}Variance`],
        }),
      };
    });

    return table as VarianceDrilldownTableItem[];
  },
)((state, props) => props.subject || 'well');

export const getFetchingDates: Selector<{
  minDate: Date | null;
  maxDate: Date | null;
}> = createSelector(getDrilldownTableState, state => state.fetchingDates);

export const getDrilldownTableTotalRow = createSelector(
  getWellDrilldownTable,
  getFilteredWellTable,
  getCurrentMarkedRows,
  getGroupMode,
  getIsMarkingRows,
  getFilteredForecastDrilldownTableItems,
  getDrilldownTableParams,
  getCurrentVarianceOptions,
  getFlagsStateMap,
  (
    varianceTable,
    wellTabel,
    markedRows,
    groupMode,
    isMarkingRows,
    forecastTable,
    params,
    currentVarianceOptions,
    featureFlags,
  ): {
    secondCol: number;
    thirdCol: number;
    variance: number;
    variancePercentage: number;
  } => {
    return calculateWellTotalColumn(
      varianceTable,
      wellTabel,
      markedRows,
      groupMode,
      featureFlags.varianceDrilldownMarkingFilter,
      forecastTable,
      params,
      currentVarianceOptions,
    );
  },
);

export const getCustomTotalRow = createSelector(
  getSortedCustomWellTable,
  table => {
    return table
      ? table.reduce(
          (acc, item) => {
            return {
              act: acc.act + item.act,
              cap: acc.cap + item.cap,
              var: acc.var + item.var,
              value: acc.value + item.value,
            };
          },
          { act: 0, cap: 0, var: 0, value: 0 },
        )
      : { act: 0, cap: 0, var: 0, value: 0 };
  },
);

export const getRegionOfInterestData = createSelector(
  getDrilldownTableParams,
  getSortCriteria,
  (drilldown, sortCriteria) => {
    return {
      startDate: drilldown.minDate,
      endDate: drilldown.maxDate,
      compare: drilldown.compareOption,
      grossOrNet: drilldown.grossNet,
      phase: drilldown.phase,
      rateOrVolume: drilldown.rateVolume,
      sortBy: sortCriteria,
    };
  },
);

export default filterActions(DrilldownTableReducer as any, action =>
  action.type.match(filterRegExp),
) as Reducer<DrilldownTableState>;
