import {
  NormalizedCalculatedSeriesMapping,
  NormalizedSensorSeriesMapping,
} from 'modules/series/models';
import { ChartOption } from '../models';

const checkSensorSeriesExists = (
  option: ChartOption,
  seriesMapping:
    | (NormalizedSensorSeriesMapping | null)
    | (NormalizedCalculatedSeriesMapping | null),
  wellId: string,
) => {
  if (!seriesMapping) return;
  const series = seriesMapping[option.id];
  if (!series) return false;

  return series.tags.some(t => t.wellId === wellId);
};

export default checkSensorSeriesExists;
