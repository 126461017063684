import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import { OIL } from 'modules/phase/models/phase';

const SPACE_RE = /\s/g;

const getRowTextMap = (rowData, titleName) => {
  if (!rowData.matched || (rowData.matched && R.isEmpty(rowData.matched)))
    return {
      beforeUnderlined: rowData[titleName],
      underlined: '',
      afterUnderlined: '',
    };
  const { matched } = rowData;
  const title = rowData[titleName];
  const beforeUnderlined = title
    .substring(0, matched[0])
    .replace(SPACE_RE, String.fromCharCode(160));
  const underlined = title
    .substring(matched[0], matched[1])
    .replace(SPACE_RE, String.fromCharCode(160));
  const afterUnderlined = title
    .substring(matched[1])
    .replace(SPACE_RE, String.fromCharCode(160));
  return { beforeUnderlined, underlined, afterUnderlined };
};

interface WellTableRowProps {
  className: string;
  columns: React.ReactNode;
  compareOption: string;
  bgColor?: string;
  format: (format: number) => string;
  index: number;
  isTotalRow: boolean;
  onRowClick: (row: {
    event: React.MouseEvent<HTMLDivElement>;
    index: number;
    rowData: Record<string, any>;
  }) => void;
  rowData: Record<string, any>;
  style: { [prop: string]: string };
  firstColDataKey: string;
  secondColDataKey: string;
  thirdColDataKey: string;
  fourthColDataKey: string;
  isCoreSeries: boolean;
  hasColorEffects?: boolean;
  isHighlighted?: boolean;
  maxColumnWidth: {
    secondColWidth: number;
    thirdColWidth: number;
    fourthColWidth: number;
  };
}

const WellCustomTableRow = ({
  className,
  index,
  isHighlighted,
  onRowClick,
  rowData,
  style,
  format,
  firstColDataKey,
  hasColorEffects,
  secondColDataKey,
  thirdColDataKey,
  fourthColDataKey,
  isCoreSeries,
  maxColumnWidth,
}: WellTableRowProps) => {
  const valueRowText = getRowTextMap(rowData, firstColDataKey);

  const rowStyle = isHighlighted
    ? style
    : { ...style, backgroundColor: rowData.color || 'transparent' };

  return (
    <div
      aria-rowindex={index}
      aria-label="row"
      tabIndex={0}
      className={className}
      role="row"
      style={rowStyle}
      onClick={event => onRowClick?.call(null, { event, index, rowData })}
    >
      <WellCustomTableRow.FirstCol
        aria-colindex="1"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={rowData[firstColDataKey]}
      >
        {
          <>
            <span>{valueRowText.beforeUnderlined}</span>
            <WellCustomTableRow.SearchResult>
              {valueRowText.underlined}
            </WellCustomTableRow.SearchResult>
            <span>{valueRowText.afterUnderlined}</span>
          </>
        }
      </WellCustomTableRow.FirstCol>

      <WellCustomTableRow.MiddleCol
        aria-colindex="2"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={rowData[secondColDataKey]}
        hasColorEffects={hasColorEffects}
        isHighlighted={isHighlighted}
        positive={rowData[secondColDataKey] > 0}
        width={maxColumnWidth.secondColWidth}
      >
        {format(rowData[secondColDataKey])}
      </WellCustomTableRow.MiddleCol>
      {isCoreSeries && (
        <>
          <WellCustomTableRow.MiddleCol
            aria-colindex="2"
            className="ReactVirtualized__Table__rowColumn"
            role="gridcell"
            title={rowData[thirdColDataKey]}
            hasColorEffects={hasColorEffects}
            isHighlighted={isHighlighted}
            positive={rowData[thirdColDataKey] > 0}
            width={maxColumnWidth.thirdColWidth}
          >
            {format(rowData[thirdColDataKey])}
          </WellCustomTableRow.MiddleCol>
          <WellCustomTableRow.FourthCol
            aria-colindex="2"
            className="ReactVirtualized__Table__rowColumn"
            role="gridcell"
            title={rowData[fourthColDataKey]}
            hasColorEffects={
              hasColorEffects &&
              Math.round(10 * rowData[fourthColDataKey]) !== 0 &&
              rowData[fourthColDataKey] !== 'N/A'
            }
            isHighlighted={isHighlighted}
            positive={Math.round(10 * rowData[fourthColDataKey]) > 0}
            width={maxColumnWidth.fourthColWidth}
          >
            {format(rowData[fourthColDataKey])}
          </WellCustomTableRow.FourthCol>
        </>
      )}
    </div>
  );
};

export default WellCustomTableRow;

WellCustomTableRow.Col = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 12px;
`;

WellCustomTableRow.FirstCol = styled(WellCustomTableRow.Col)`
  flex: 1 1 200px;
  color: black;
`;

WellCustomTableRow.MiddleCol = styled(WellCustomTableRow.Col)`
  flex: 0 0 ${props => props.width}px;
  color: ${(props: Record<string, any>) => props.theme.colors.primaryText};
  box-sizing: content-box;
`;

WellCustomTableRow.FourthCol = styled(WellCustomTableRow.Col)`
  flex: 0 0 ${(props: Record<string, any>) => props.width}px;
  box-sizing: content-box;
  color: ${(props: Record<string, any>) =>
    props.hasColorEffects
      ? props.positive
        ? props.theme.colors[OIL]
        : props.theme.colors.criticalText
      : 'inherit'};
  font-weight: ${(props: Record<string, any>) =>
    props.hasColorEffects ? (props.positive ? 'normal' : 'bold') : 'normal'};
`;

WellCustomTableRow.SearchResult = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

WellCustomTableRow.Span = styled.span`
  white-space: pre;
`;
