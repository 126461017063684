import { isSupported, SDKResult, setup } from '@loomhq/record-sdk';
import ClickOutside from 'components/ClickOutside';
import Portal from 'components/Portal';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import React from 'react';
import { LoomContext } from './LoomContext';
import { loomStyles } from './loomStyles';
import { getEmbedLoom } from './utils';
import { useSelector } from 'store/models';

interface LoomProviderProps {
  children: React.ReactNode;
}

export const LoomProvider = ({ children }: LoomProviderProps) => {
  const [visualizedLoom, setVisualizedLoom] = React.useState<string | null>(
    null,
  );
  const [loomHTML, setLoomHTML] = React.useState<string | null>(null);
  const jws = useSelector(getAppConfig).loomJWS;
  const [sdkResult, setSdkResult] = React.useState<SDKResult | undefined>(
    undefined,
  );
  const mutationObserver = React.useRef<MutationObserver | null>(null);

  const setupLoom = React.useCallback(async () => {
    if (!jws) return;
    setSdkResult(undefined);

    const { supported } = await isSupported();

    if (!supported) return;

    const sdkResult = await setup({
      jws,
    });

    setSdkResult(sdkResult);
  }, [jws]);

  React.useEffect(() => {
    if (!sdkResult) return;

    const listener = (_, observer) => {
      const shadowRoot = document.querySelector(
        '#loom-sdk-record-overlay-shadow-root-id',
      )?.shadowRoot;

      if (!shadowRoot) return;
      observer.disconnect();

      const styles = document.createElement('style');
      styles.id = 'custom-loom-styles';
      styles.innerHTML = loomStyles;
      shadowRoot?.prepend(styles);
    };

    mutationObserver.current = new MutationObserver(listener);
    mutationObserver.current.observe(document.body, {
      childList: true,
      subtree: false,
    });

    return () => {
      mutationObserver.current?.disconnect();
    };
  }, [sdkResult]);

  const getInlineLoomFor = React.useCallback(
    async (url: string, options: { width?: number; height?: number } = {}) => {
      const html = await getEmbedLoom(url, options);

      return html;
    },
    [],
  );

  const loadEmbedLoom = React.useCallback(async (url: string) => {
    const html = await getInlineLoomFor(url, {
      width: window.innerWidth / 1.1,
      height: window.innerHeight / 1.4,
    });

    setLoomHTML(html);
  }, []);

  React.useEffect(() => {
    setupLoom();
  }, [setupLoom]);

  React.useEffect(() => {
    if (!visualizedLoom) return setLoomHTML(null);

    loadEmbedLoom(visualizedLoom);
  }, [visualizedLoom]);

  return (
    <LoomContext.Provider
      value={{
        isSetup: !!sdkResult,
        configureButton: sdkResult?.configureButton,
        visualizeLoom: (url: string) => setVisualizedLoom(url),
        hideLoom: () => setVisualizedLoom(null),
        getInlineLoomFor,
      }}
    >
      {children}
      {loomHTML && (
        <Portal>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0,0,0,0.75)',
              zIndex: 9999,
            }}
          >
            <ClickOutside action={() => setVisualizedLoom(null)}>
              <div dangerouslySetInnerHTML={{ __html: loomHTML }}></div>
            </ClickOutside>
          </div>
        </Portal>
      )}
    </LoomContext.Provider>
  );
};
