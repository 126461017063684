import * as React from 'react';
import * as R from 'ramda';
import { format as d3Format } from 'd3-format';

import useComponentSize from 'hooks/useComponentSize';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import MainBarsGroup from 'modules/chart/components/MainBarsGroup';
import SelectedBar from 'modules/chart/components/SelectedBar';
import { getTrellisGeneralClipPathId, getTrellisId } from 'modules/chart/utils';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';
import type {
  IdDialog,
  IdIndexDialog,
  TooltipData,
  TrellisTooltipData,
} from 'modules/ui/models/ui';

import AllocIssueArea from './allocIssue/AllocIssueArea';
import VarianceAllocArea from './allocIssue/VarianceAllocArea';
import EventVarianceArea from './varianceEvent/EventVarianceArea';
import { useCapacityData, useVarianceData } from '../context/capacityVariance';
import { useSelector } from 'store/models';
import { getYAxisHovered } from 'modules/ui/UIReducer';
import { measureText } from 'modules/filter/utils/text';
interface SVGVarianceTrellisProps {
  allocIssues: AllocIssue[];
  allocIssueDialog?: IdIndexDialog;
  allocIssuesVisibility: boolean;
  eventColors: { [key: string]: string };
  height: number;
  highlightedAllocIssue?: IdDialog;
  highlightedAllocIssueDivider?: IdDialog;
  isAxisDragging: boolean;
  isPossibleEditAlloc: boolean;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  showBarHoverEffect: boolean;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  varianceEvents: VarianceEvent[];
  xScale: any;
  yScale: any;
  yAxisLinePos: number | null;
  format: any;
}

const SVGVarianceTrellis = ({
  allocIssues,
  allocIssueDialog,
  allocIssuesVisibility,
  eventColors,
  height,
  highlightedAllocIssue,
  highlightedAllocIssueDivider,
  isAxisDragging,
  isPossibleEditAlloc,
  onSetTooltipData,
  showBarHoverEffect,
  tooltipData,
  trellisTitle,
  varianceEvents,
  xScale,
  yScale,
  yAxisLinePos,
  format,
}: SVGVarianceTrellisProps) => {
  const yAxisHovered = useSelector(getYAxisHovered);

  const svgEl: React.RefObject<HTMLElement | null> = React.useRef(null);
  const svgBoundingRect = useComponentSize(svgEl);

  const capacityData = useCapacityData();
  const varianceData = useVarianceData();
  const varianceAreasWithCapacity = React.useMemo(
    () =>
      capacityData.getVarianceAreas({
        removeLastDays: true,
      }),
    [capacityData.getVarianceAreas],
  );

  const varianceAreas = React.useMemo(
    () => varianceData.getVarianceFromVarianceAreas(varianceAreasWithCapacity),
    [varianceData.getVarianceFromVarianceAreas],
  );

  const variance = React.useMemo(
    () => varianceData.getAll(),
    [varianceData.getAll],
  );

  const cursorRate = React.useMemo(
    () => d3Format(format)(yScale.invert(yAxisLinePos)),
    [yAxisLinePos, format, yScale],
  );

  const backgroundTextWidth = React.useMemo(
    () => measureText(cursorRate, 12) + 3,
    [cursorRate],
  );

  return (
    <svg
      //@ts-expect-error
      ref={svgEl}
      className="varianceTrellis_chart"
      height="100%"
      id={getTrellisId(trellisTitle)}
      preserveAspectRatio="none"
      viewBox={`0 0 ${xScale.range()[1]} ${height}`}
      width={xScale.range()[1] - 2}
      shapeRendering="geometricPrecision"
      style={{ cursor: isAxisDragging ? 'grabbing' : 'auto' }}
    >
      <defs>
        <clipPath id={getTrellisGeneralClipPathId(trellisTitle)}>
          <rect height={height} width={xScale.range()[1] - 1} />
        </clipPath>
      </defs>
      {allocIssues &&
        allocIssuesVisibility &&
        !R.isEmpty(allocIssues) &&
        allocIssues.map((allocIssue, i) => (
          <AllocIssueArea
            key={`allocIssueArea_${allocIssue.id}`}
            allocIssue={allocIssue}
            height={height}
            xScale={xScale}
            isHighlighted={
              (!!highlightedAllocIssue &&
                highlightedAllocIssue.show &&
                highlightedAllocIssue.id === allocIssue.id) ||
              (!!highlightedAllocIssueDivider &&
                highlightedAllocIssueDivider.show &&
                highlightedAllocIssueDivider.id === allocIssue.id) ||
              (!!allocIssueDialog &&
                allocIssueDialog.show &&
                allocIssueDialog.id === allocIssue.id)
            }
            isEditable={
              isPossibleEditAlloc &&
              !!allocIssueDialog &&
              allocIssueDialog.show &&
              allocIssueDialog.id === allocIssue.id
            }
          />
        ))}
      <MainBarsGroup
        data={variance}
        xScale={xScale}
        yScale={yScale}
        purpose="variance"
        type="well"
      />

      {allocIssues &&
        allocIssuesVisibility &&
        !R.isEmpty(allocIssues) &&
        allocIssues.map((allocIssue, i) => (
          <VarianceAllocArea
            key={`varianceIssueArea_${allocIssue.id}`}
            allocIssue={allocIssue}
            height={height}
            varianceData={variance}
            xScale={xScale}
            yScale={yScale}
          />
        ))}

      {!R.isEmpty(varianceAreas) &&
        varianceEvents.map((varianceEvent, idx) => (
          <EventVarianceArea
            color={eventColors[varianceEvent.varianceOptionId]}
            key={varianceEvent.id}
            varianceArea={varianceAreas[idx]}
            yScale={yScale}
            xScale={xScale}
          />
        ))}

      {svgEl.current && showBarHoverEffect && !yAxisHovered && (
        <SelectedBar
          allocIssues={allocIssues}
          onSetTooltipData={onSetTooltipData}
          varianceData={variance}
          svgBoundingRect={svgBoundingRect}
          tooltipData={tooltipData}
          trellisTitle={trellisTitle}
          varianceEvents={varianceEvents}
          xScale={xScale}
          yScale={yScale}
          isDragging={isAxisDragging}
        />
      )}
      <line
        stroke="#000000"
        strokeWidth={1}
        x1={0}
        x2={xScale.range()[1]}
        y1={yScale(0)}
        y2={yScale(0)}
      />
      {yAxisLinePos && (
        <g>
          <g>
            <rect
              x={4}
              y={yAxisLinePos - 16}
              width={backgroundTextWidth}
              height={16}
              fill="#ffffff"
            />
            <text
              fontSize={12}
              style={{
                userSelect: 'none',
              }}
              textAnchor="start"
              x={5}
              y={yAxisLinePos - 4}
              fill="#c42424"
            >
              {cursorRate}
            </text>
          </g>
          <line
            stroke="#c42424"
            strokeWidth={1}
            x1={0}
            x2={xScale.range()[1]}
            y1={yAxisLinePos}
            y2={yAxisLinePos}
            vectorEffect="non-scaling-stroke"
          />
        </g>
      )}
    </svg>
  );
};
export default React.memo<SVGVarianceTrellisProps>(SVGVarianceTrellis);
