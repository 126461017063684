import { timeDay } from 'd3-time';
import { RibbonEvent } from 'modules/ribbon/models';
import { CapacityChangeEvent } from '../../capacityChangeEvent/models/capacityChangeEvent';
import { VarianceEvent } from '../../varianceEvent/models/varianceEvent';
import { NOTES_HEIGHT, NormalizeEventsFunctionProps } from '../models/index';

const normalizeEvents = (data: NormalizeEventsFunctionProps) => {
  const {
    normalizedEvents,
    eventsSorted,
    events,
    xScale,
    eventNotes,
    type,
    titles,
    optionIdPath,
    dayStartPath,
    dayEndPath,
    eventColors,
  } = data;

  const isCapacity = type === 'capacity';

  events.forEach(
    (
      event:
        | VarianceEvent
        | RibbonEvent
        | CapacityChangeEvent
        | Record<string, never>,
    ) => {
      if (!event) return;
      const startDayDate = new Date(event[dayStartPath]);
      const endDayDate = new Date(event[dayEndPath]);
      const x1 = xScale(timeDay.round(startDayDate));
      const x2 = isCapacity
        ? x1 + 50
        : xScale(timeDay.round(timeDay.offset(endDayDate, 1)));
      const y1 = 1;
      const y2 = NOTES_HEIGHT;
      const position = { x1, x2, y1, y2 };
      let shouldSearchSamePlace = true;
      while (shouldSearchSamePlace) {
        const samePlace = normalizedEvents.find(
          item =>
            ((position.x1 >= item.position.x1 &&
              position.x1 <= item.position.x2) ||
              (position.x2 >= item.position.x1 &&
                position.x2 <= item.position.x2)) &&
            position.y1 === item.position.y1 &&
            position.y2 === item.position.y2,
        );
        if (!samePlace) {
          shouldSearchSamePlace = false;
          continue;
        }
        position.y1 += NOTES_HEIGHT;
        position.y2 += NOTES_HEIGHT;
      }
      if (type !== 'ribbon') {
        normalizedEvents.push({
          notes: eventNotes[event.id],
          position,
          color:
            isCapacity || !eventColors
              ? '#222222'
              : eventColors[event[optionIdPath]],
          type,
          id: event.id,
          optionId: event[optionIdPath],
          index: eventsSorted
            ? eventsSorted.findIndex(item => item.id === event.id)
            : 0,
          title: titles
            ? type === 'variance'
              ? titles[event[optionIdPath]]?.subTitle
              : titles[event[optionIdPath]]?.title || ''
            : '',
          subTitle: titles ? titles[event[optionIdPath]]?.subTitle || '' : '',
        });
        return;
      }
      normalizedEvents.push({
        notes: eventNotes[event.id],
        position,
        color: eventColors ? eventColors[event[optionIdPath]] : '#222222',
        type,
        id: event.id,
        optionId: event[optionIdPath],
      });
    },
  );

  return normalizedEvents;
};

export default normalizeEvents;
