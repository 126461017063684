import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import type { Action, Selector } from 'store/models';

import {
  namespace,
  FETCH_BASE_SERIES_LAYOUTS,
  FETCH_USER_SERIES_LAYOUTS,
  SET_CURRENT_SERIES_LAYOUT,
  SET_CURRENT_CONFIGURATION,
  SET_USER_LAYOUTS,
  CREATE_SERIES_LAYOUT_REMOTELY,
  RENAME_SERIES_LAYOUT_REMOTELY,
  REMOVE_SERIES_LAYOUT_REMOTELY,
  UPDATE_SERIES_LAYOUT_REMOTELY,
} from './SeriesLayoutsActions';

import {
  SeriesLayoutOption,
  ListSeriesLayouts,
  CurrentLayout,
} from './models/seriesLayouts';
import { Reducer } from 'redux';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'seriesLayouts';

interface SeriesLayoutsState {
  baseLayouts: ListSeriesLayouts | Record<string, never>;
  userLayouts: ListSeriesLayouts | Record<string, never>;
  currentLayout: CurrentLayout | Record<string, never>;
}

const initialState = {
  baseLayouts: {},
  userLayouts: {},
  currentLayout: {},
};

const seriesLayoutsReducer = (
  state: SeriesLayoutsState = initialState,
  action: Action,
) => {
  const { payload } = action;
  switch (action.type) {
    case `${FETCH_BASE_SERIES_LAYOUTS}_SUCCESS`: {
      const baseLayouts = getGraphqlPayload(action);
      const baseLayoutsObject = baseLayouts.reduce((acc, layout) => {
        layout.configuration = layout.configuration
          .split('--')
          .filter(Boolean)
          .join('--');
        acc[layout.id] = layout;
        return acc;
      }, {});
      return R.assocPath(['baseLayouts'], baseLayoutsObject, state);
    }
    case SET_USER_LAYOUTS: {
      return R.assocPath(['userLayouts'], payload, state);
    }
    case `${FETCH_USER_SERIES_LAYOUTS}_SUCCESS`: {
      const userLayouts = getGraphqlPayload(action);
      const userLayoutsObject = userLayouts.reduce((acc, layout) => {
        layout.configuration = layout.configuration
          .split('--')
          .filter(Boolean)
          .join('--');
        acc[layout.id] = layout;
        return acc;
      }, {});
      return R.assocPath(['userLayouts'], userLayoutsObject, state);
    }
    case SET_CURRENT_SERIES_LAYOUT: {
      const { id, userId, configuration } = payload;
      const type = userId === '-1' ? 'base' : 'user';
      const currentSeriesLayout = {
        configuration,
        id,
        type,
      };
      return R.assocPath(['currentLayout'], currentSeriesLayout, state);
    }
    case SET_CURRENT_CONFIGURATION: {
      const { configuration } = payload;
      const allLayouts = R.concat(
        R.values(state.baseLayouts),
        R.values(state.userLayouts),
      );
      const layoutWithEqualConfigurationFromUrl = allLayouts.find(
        layout => layout.configuration === configuration,
      );

      const layoutEqualCashedId = allLayouts.find(
        layout => layout.id === state.currentLayout.id,
      );

      const currentLayout = {
        id:
          state.currentLayout.id !== undefined
            ? state.currentLayout.id
            : layoutWithEqualConfigurationFromUrl
            ? layoutWithEqualConfigurationFromUrl.id
            : undefined,
        configuration,
        type:
          layoutEqualCashedId && layoutEqualCashedId.userId === '-1'
            ? 'base'
            : 'user',
        unsaved:
          layoutEqualCashedId !== undefined &&
          layoutEqualCashedId.configuration !== configuration,
      };
      return R.assocPath(['currentLayout'], currentLayout, state);
    }
    case `${UPDATE_SERIES_LAYOUT_REMOTELY}_SUCCESS`: {
      const layout = getGraphqlPayload(action);
      if (
        R.pathOr('', ['currentLayout', 'configuration'], state) ===
        R.pathOr('', ['configuration'], layout)
      ) {
        return R.compose(
          R.assocPath(['userLayouts', layout.id], layout),
          R.assocPath(['currentLayout'], { ...layout, unsaved: false }),
        )(state);
      }
      return R.assocPath(['userLayouts', layout.id], layout, state);
    }
    case CREATE_SERIES_LAYOUT_REMOTELY: {
      const layout = action.payload.graphql.variables.payload;

      return R.assocPath(['userLayouts', layout.id], layout, state);
    }
    case `${CREATE_SERIES_LAYOUT_REMOTELY}_SUCCESS`: {
      const newLayout = getGraphqlPayload(action);
      const {
        payload: { id: oldId },
      } = getGraphqlPrevActionVariables(action);

      const newCurrentLayout = {
        configuration: state.currentLayout.configuration,
        id: newLayout.id,
        type: 'user',
        unsaved: false,
      };

      return R.assocPath(
        ['userLayouts', newLayout.id],
        newLayout,
        R.assocPath(
          ['currentLayout'],
          newCurrentLayout,
          R.dissocPath(['userLayouts', oldId], state),
        ),
      );
    }
    case RENAME_SERIES_LAYOUT_REMOTELY: {
      const { id, name } = action.payload.graphql.variables.payload;
      return R.assocPath(['userLayouts', id, 'name'], name, state);
    }
    case REMOVE_SERIES_LAYOUT_REMOTELY: {
      const { id } = action.payload.graphql.variables;
      if (id === state.currentLayout.id) {
        return R.compose(
          R.dissocPath(['userLayouts', id]),
          R.assocPath(['currentLayout', 'id'], ''),
        )(state);
      }
      return R.dissocPath(['userLayouts', id], state);
    }
    case `${REMOVE_SERIES_LAYOUT_REMOTELY}_SUCCESS`: {
      const userLayouts = getGraphqlPayload(action);
      const userLayoutsObject = userLayouts.reduce((acc, layout) => {
        acc[layout.id] = layout;
        return acc;
      }, {});
      return R.assocPath(['userLayouts'], userLayoutsObject, state);
    }
    default:
      return state;
  }
};

export const getState = (state: any): SeriesLayoutsState => state[STATE_KEY];

export const getBaseSeriesLayouts: Selector<ListSeriesLayouts> = createSelector(
  getState,
  (state): ListSeriesLayouts => state.baseLayouts,
);

export const getUserSeriesLayouts: Selector<ListSeriesLayouts> = createSelector(
  getState,
  (state): ListSeriesLayouts => state.userLayouts,
);

export const getAllLayouts = createSelector(getState, state =>
  R.concat(R.values(state.baseLayouts), R.values(state.userLayouts)),
);

export const getBaseSeriesLayoutsArray: Selector<SeriesLayoutOption[]> =
  createSelector(getState, state =>
    R.values(state.baseLayouts).sort((a, b) => (a.order > b.order ? 1 : -1)),
  );

export const getUserSeriesLayoutsArray: Selector<SeriesLayoutOption[]> =
  createSelector(getState, state =>
    R.values(state.userLayouts).sort((a, b) => (a.order > b.order ? 1 : -1)),
  );

export const getCurrentLayout: Selector<CurrentLayout | Record<string, never>> =
  createSelector(
    getState,
    (state): CurrentLayout | Record<string, never> => state.currentLayout,
  );

export const getCurrentSeriesLayoutData: Selector<SeriesLayoutOption> =
  createSelector(
    getState,
    getCurrentLayout,
    (state, currentLayout): SeriesLayoutOption => {
      if (!R.pathOr({}, ['currentLayout', 'id'], state)) {
        return state.baseLayouts[0];
      }
      const allLayouts = R.concat(
        R.values(state.baseLayouts),
        R.values(state.userLayouts),
      );
      const { id } = currentLayout;

      const foundLayout = allLayouts.find(layout => layout.id === id);

      return foundLayout ? foundLayout : {};
    },
  );

export const getCurrentLayoutConfiguration: Selector<string> = createSelector(
  getCurrentLayout,
  getCurrentSeriesLayoutData,
  (layout, layoutData): string =>
    layout?.configuration ?? layoutData?.configuration ?? '',
);

export default filterActions(seriesLayoutsReducer as any, action =>
  action.type.match(filterRegExp),
) as Reducer<SeriesLayoutsState>;
