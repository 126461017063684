import * as Sentry from '@sentry/browser';
import { createReduxEnhancer } from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';
import * as R from 'ramda';

import Env from 'helpers/Env';
import { RootState } from 'store/models';
import { MetricsPluginInternals } from '../../models';

const SentryPlugin: MetricsPluginInternals = {
  isSourceCodeLoaded: () => {
    return true;
  },

  identifyUser: user => {
    Sentry.setUser(user);
  },

  anonomizeUser: () => {
    Sentry.setUser(null);
  },

  captureEvent: (event, data) => {
    Sentry.captureEvent(
      { event_id: event.name },
      { data: Object.assign({ eventCode: event.code }, data) },
    );
  },

  captureError: (error, data) => {
    Sentry.captureException(error.originalError, {
      tags: { location: error.location },
      extra: data,
    });
  },

  initialize: () => {
    const sentryConfig = {
      dsn: 'https://7597cb20d7b14a9298d29ff02c8ac5e4@sentry.io/1328746',
      environment: Env.current,
      beforeBreadcrumb: (breadcrumb: any) => {
        return breadcrumb.category === 'console' ? null : breadcrumb;
      },
      integrations: [new SentryRRWeb()],
      normalizeDepth: 10,
    };

    Sentry.init(sentryConfig);
  },
};

export const SentryPluginReduxEnhancer = createReduxEnhancer({
  stateTransformer: (state: RootState) => {
    return R.assoc('auth', R.dissoc('token', state.auth), state);
  },
});

export default SentryPlugin;
