import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Dialog, AbstractDialog } from 'modules/ui/models/dialog';
import { UIState } from 'modules/ui/models/ui';
import {
  getIsRightPanelExpanded,
  getRightPanelDialog,
  getRightPanelTemplates,
} from 'modules/ui/UIReducer';
import {
  setRightPanelDialog,
  unsetRightPanelDialog,
  expandRightPanel,
  collapseRightPanel,
  setRightPanelTemplate,
} from 'modules/ui/UIActions';

const RIGHT_PANEL_DEFAULT_WIDTH = 388;
const RIGHT_PANEL_WIDTHS: Record<Dialog['type'], number> = {
  AllocationIssue: 388,
  CapacityChangeEvent: 388,
  ChartOptions: 388,
  Legend: 388,
  RequestHelp: 450,
  RibbonDetails: 388,
  RibbonEvent: 388,
  Shortcuts: 388,
  VarianceChangeEvent: 388,
  WellNotes: 388,
  WellInfo: 388,
};

const useRightPanel = () => {
  const dispatch = useDispatch();

  const isExpanded: boolean = useSelector(getIsRightPanelExpanded);
  const dialog: Dialog | null = useSelector(getRightPanelDialog);
  const templates: UIState['rightPanelTemplates'] = useSelector(
    getRightPanelTemplates,
  );

  const dialogData = dialog?.data as AbstractDialog['data'];
  const dialogId = dialogData?.id ?? 'unknown';
  const dialogIndex = dialogData?.index ?? -1;

  const capacityTemplate = React.useMemo(() => templates.capacity, [templates]);
  const varianceTemplate = React.useMemo(() => templates.variance, [templates]);
  const ribbonTemplate = React.useMemo(() => templates.ribbon, [templates]);
  const width = React.useMemo(
    () => RIGHT_PANEL_WIDTHS[String(dialog?.type)] || RIGHT_PANEL_DEFAULT_WIDTH,
    [dialog?.type],
  );

  const expand = React.useCallback(
    () => dispatch(expandRightPanel()),
    [dispatch],
  );

  const collapse = React.useCallback(
    () => dispatch(collapseRightPanel()),
    [dispatch],
  );

  const isDialogOfType = React.useCallback(
    (type: Dialog['type']) => dialog?.type === type,
    [dialog?.type],
  );

  const setDialog = React.useCallback(
    (dialog: Dialog) => dispatch(setRightPanelDialog(dialog)),
    [dispatch],
  );

  const unsetDialog = React.useCallback(
    () => (isExpanded ? dispatch(unsetRightPanelDialog()) : collapse()),
    [dispatch, isExpanded, collapse],
  );

  const unsetDialogOfType = React.useCallback(
    (type: Dialog['type']) => isDialogOfType(type) && unsetDialog(),
    [setDialog, unsetDialog, isDialogOfType],
  );

  const toggleDialog = React.useCallback(
    (dialog: Dialog) =>
      isDialogOfType(dialog.type) ? unsetDialog() : setDialog(dialog),
    [setDialog, unsetDialog, isDialogOfType],
  );

  const setTemplate = React.useCallback(
    (name: 'ribbon' | 'variance' | 'capacity', template: string[]) => {
      dispatch(setRightPanelTemplate({ name, data: template }));
    },
    [dispatch],
  );

  return {
    dialog,
    dialogId,
    dialogIndex,
    width,
    isExpanded,
    isDialogOfType,
    setDialog,
    unsetDialog,
    unsetDialogOfType,
    toggleDialog,
    expand,
    collapse,
    setTemplate,
    capacityTemplate,
    varianceTemplate,
    ribbonTemplate,
  };
};

export default useRightPanel;
