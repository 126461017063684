const copyDataToClipboard = data => {
  const textField = document.createElement('textarea');
  textField.value = data;
  if (document.body) {
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }
};

export default copyDataToClipboard;
