import { AuthState } from 'modules/auth/AuthReducer';
import { METRICS_AUTH_ROLES_CODES } from '../constants';

export const getAuthRolesString = (auth: AuthState) => {
  const res: number[] = [];

  Object.keys(auth).forEach(key => {
    const isAuthorized = auth[key];
    const code = METRICS_AUTH_ROLES_CODES[key];
    if (isAuthorized && code !== undefined) res.push(code);
  });

  return res.sort((a, b) => a - b).join(',');
};
