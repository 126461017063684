import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import copyDataToClipboard from 'helpers/copyDataToClipboard';
import * as React from 'react';
import styled from 'styled-components';

const generateTSVString = (data, dataKeys) => {
  const headersLine = dataKeys.map(key => key.displayName).join('\t');
  const rows = data
    .map(dataRow => {
      return dataKeys
        .map(dataKey => {
          return dataRow[dataKey.filterName];
        })
        .join('\t');
    })
    .join('\r\n');
  const result = headersLine + '\r\n' + rows;
  return result;
};

interface ContextMenuProps {
  closeMenu: () => void;
  contextPosition: { clientX: number; clientY: number } | null;
  data: any;
  dataKeys: any[];
  onMenuHover: () => void;
  onMenuLeave: () => void;
  copyToClipboardSelected: () => void;
}

const ContextMenu = (
  {
    closeMenu,
    contextPosition,
    data,
    dataKeys,
    onMenuHover,
    onMenuLeave,
    copyToClipboardSelected,
  }: ContextMenuProps,
  ref,
) => {
  const copyString = generateTSVString(data, dataKeys);
  const copyToClipboardAll = () => {
    copyDataToClipboard(copyString);
    closeMenu();
  };

  return (
    <ContextMenu.ContextMenuWrapper
      ref={ref}
      onMouseLeave={onMenuLeave}
      onMouseEnter={onMenuHover}
      x={contextPosition ? contextPosition.clientX : 0}
      y={contextPosition ? contextPosition.clientY : 0}
    >
      <ContextMenu.StyledPaper>
        <ContextMenu.MenuList>
          <ContextMenu.MenuItem onClick={copyToClipboardSelected}>
            Copy Selected Table Contents to Clipboard
          </ContextMenu.MenuItem>
          <ContextMenu.MenuItem onClick={copyToClipboardAll}>
            Copy All Table Contents to Clipboard
          </ContextMenu.MenuItem>
        </ContextMenu.MenuList>
      </ContextMenu.StyledPaper>
    </ContextMenu.ContextMenuWrapper>
  );
};

ContextMenu.ContextMenuWrapper = styled.div`
  position: fixed;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  pointer-events: auto;
  z-index: 999;
`;

ContextMenu.StyledPaper = styled(Paper)`
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.5) !important;
`;

ContextMenu.MenuList = styled(MenuList)``;

ContextMenu.MenuItem = styled(MenuItem)`
  min-width: 175px;
  &:hover {
    background-color: #e7e7e7 !important;
  }
`;

export default React.forwardRef<HTMLElement, ContextMenuProps>(ContextMenu);
