import { useSelector as reduxUseSelector } from 'react-redux';
import { Selector as ReduxSelector } from 'reselect';
import store from './';

export type AC<Payload = undefined> = Payload extends undefined
  ? () => Action
  : (payload: Payload) => Action;

export type RootState = ReturnType<typeof store.getState>;

export type ActionContext = {
  fetchOptions?: {
    signal?: AbortSignal;
  };
};

export type Action = {
  error?: any;
  meta?: any;
  payload?: any;
  type: string;
};

export type AxiosAction = Action & {
  payload: {
    request: {
      method: string;
      url: string;
      payload?: any;
    };
  };
};

export type Dispatch = (action: Action) => any;

export type GraphqlQueryAction = Action & {
  payload: {
    graphql: {
      query: any;
      variables?: any;
    };
    context?: ActionContext;
  };
};

export type GraphqlMutationAction = Action & {
  payload: {
    graphql: {
      mutation: any;
      variables?: any;
    };
    context?: ActionContext;
  };
};

export type Selector<T> = ReduxSelector<RootState, T>;

export const useSelector = <T>(
  selector: (state) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => reduxUseSelector<RootState, T>(selector, equalityFn);
