import * as R from 'ramda';
import * as React from 'react';

import { NormalizedSeriesMapping, NormalizedSeries } from '../models';
import SeriesBars from './SeriesBars';
import SeriesStepsLine from './SeriesStepsLine';
import { BAR_CHART, ListChartOptions } from 'modules/chartOptions/models';
import { scaleTime } from 'd3-scale';
import { timeDay, timeMinute } from 'd3-time';

interface SVGSeriesFakeTrellisProps {
  chartOptions: ListChartOptions;
  groupOptions: string[];
  height: number;
  series: { [key: string]: NormalizedSeries[] };
  seriesMapping: NormalizedSeriesMapping;
  xScale: any;
  yScale: any;
  resetTooltipData: () => void;
}

const SVGSeriesFakeTrellis = ({
  chartOptions,
  groupOptions,
  height,
  series,
  seriesMapping,
  xScale,
  yScale,
  resetTooltipData,
}: SVGSeriesFakeTrellisProps) => {
  const hasSeries = series && !R.isEmpty(series);
  const timeInterval = React.useMemo(() => {
    const intervals = groupOptions.map(id => {
      const isSensorOrCalculated = id.startsWith('s') || id.startsWith('c');
      const data = isSensorOrCalculated ? series[id] : series['dataSeries'];
      if (!data || !data[0]) return null;

      const to = data[data.length - 1].day;
      const timeOffset = isSensorOrCalculated
        ? timeMinute.offset
        : timeDay.offset;

      return { from: data[0].day, to: timeOffset(to, 1) };
    });

    const interval = intervals.reduce<{ from: Date | null; to: Date | null }>(
      (acc, n) => {
        if (!n) return acc;
        if (!acc.from || acc.from.getTime() > n.from.getTime())
          acc.from = n.from;
        if (!acc.to || acc.to.getTime() < n.to.getTime()) acc.to = n.to;

        return acc;
      },
      { from: null, to: null },
    );

    return interval.from && interval.to
      ? (interval as { from: Date; to: Date })
      : null;
  }, [groupOptions, series]);

  const originalChartWidth = React.useMemo(() => {
    const range = xScale.range();

    return range[1] - range[0];
  }, [xScale]);

  const originalChartInterval = React.useMemo(() => {
    const domain = xScale.domain();

    return timeMinute.count(domain[0], domain[1]);
  }, [xScale]);

  const xScaleFake = React.useMemo(() => {
    if (!timeInterval) return null;
    const extremeMin = timeInterval.from;
    const extremeMax = timeInterval.to;
    const fakeChartInterval = timeMinute.count(extremeMin, extremeMax);
    const fakeChartWidth =
      (originalChartWidth * fakeChartInterval) / originalChartInterval;

    return scaleTime()
      .domain([extremeMin, extremeMax])
      .range([0, fakeChartWidth]);
  }, [originalChartWidth, originalChartInterval, timeInterval]);

  const chartWidth = React.useMemo(() => {
    if (!xScaleFake) return 0;
    const range = xScaleFake.range();

    return range[1] - range[0];
  }, [xScaleFake]);

  const left = React.useMemo(() => {
    if (!xScaleFake) return 0;

    return -xScaleFake(xScale.domain()[0]);
  }, [xScaleFake, xScale]);

  React.useEffect(() => {
    resetTooltipData();
  }, []);

  if (!timeInterval) return null;

  return (
    <svg
      className="trellis_chart series"
      height="100%"
      preserveAspectRatio="none"
      viewBox={`0 0 ${chartWidth} ${height}`}
      width={chartWidth}
      style={{
        left,
        cursor: 'grabbing',
      }}
      shapeRendering="geometricPrecision"
    >
      {hasSeries &&
        groupOptions.map(id =>
          chartOptions[id].chartType === BAR_CHART ? (
            <SeriesBars
              key={id}
              data={
                id.startsWith('s') || id.startsWith('c')
                  ? series[id] ?? []
                  : series['dataSeries']
              }
              currentSeriesMapping={seriesMapping[id]}
              customColor={chartOptions[id].customColor}
              xScale={xScaleFake}
              yScale={yScale}
            />
          ) : (
            <SeriesStepsLine
              key={id}
              data={
                id.startsWith('s') || id.startsWith('c')
                  ? series[id] ?? []
                  : series['dataSeries']
              }
              currentSeriesMapping={seriesMapping[id]}
              customColor={chartOptions[id].customColor}
              xScale={xScaleFake}
              yScale={yScale}
            />
          ),
        )}
    </svg>
  );
};

export default React.memo<SVGSeriesFakeTrellisProps>(SVGSeriesFakeTrellis);
