import * as Shortcuts from 'components/Shortcuts';

export type ButtonTooltipAttributes = {
  title: string;
  shortcut?: () => JSX.Element;
  description?: string;
};

export type TooltipCoords = {
  x: number;
  y: number;
};

export const initialTooltipContent: ButtonTooltipAttributes = {
  title: '',
};

export const layoutConfigurationAttributes: ButtonTooltipAttributes = {
  title: 'Layout Configuration',
  description: 'Open Layout Configuration panel.',
};

export const savedLayoutsAttributes: ButtonTooltipAttributes = {
  title: 'Saved Layouts',
  description: 'Select a saved production chart layout.',
};

export const eventsAttributes: ButtonTooltipAttributes = {
  title: 'Events',
  description: 'Add a new well event.',
};

export const capacityChangeAttributes: ButtonTooltipAttributes = {
  title: 'Capacity Change',
  shortcut: Shortcuts.KeyC,
  description: 'Add a Capacity Change.',
};

export const varianceEventAttributes: ButtonTooltipAttributes = {
  title: 'Variance Event',
  shortcut: Shortcuts.KeyV,
  description: 'Add a Variance Event.',
};

export const wellInformationAttributes: ButtonTooltipAttributes = {
  title: 'Well Information',
  description: 'Open Well information panel.',
};

export const questionsAttributes: ButtonTooltipAttributes = {
  title: 'Question',
  shortcut: Shortcuts.ForwardSlash,
  description: 'Open the Keyboard Shortcuts panel.',
};

export const sortItemsAttributes: ButtonTooltipAttributes = {
  title: 'Sort Items',
};

export const SearchAttributes: ButtonTooltipAttributes = {
  title: 'Search',
  description: 'Search for text.',
};

export const hideFiltersAttributes: ButtonTooltipAttributes = {
  title: 'Hide Filters',
  description: 'Hide the attribute filters pane.',
};

export const showFiltersAttributes: ButtonTooltipAttributes = {
  title: 'Show Filters',
  description: 'Show the attributes pane.',
};

export const doneButtonAttributes: ButtonTooltipAttributes = {
  title: 'Done',
  description: 'Close the Well Attributes page.',
};

export const closeButtonAttributes: ButtonTooltipAttributes = {
  title: 'Close',
  description: 'Close the Well Attributes page.',
};

export const clearFilterAttributes: ButtonTooltipAttributes = {
  title: 'Clear Filter',
};

export const clearAllFiltersAttributes: ButtonTooltipAttributes = {
  title: 'Clear All',
  description: 'Clear all filters.',
};

export const groupingAttributes: ButtonTooltipAttributes = {
  title: 'Grouping',
  description: 'Change how wells are grouped.',
};

export const ROIAttributes: ButtonTooltipAttributes = {
  title: 'Region of Interest',
  shortcut: Shortcuts.KeyR,
  description: 'Select the start and end date for variance calculations.',
};

export const comparisonAttributes: ButtonTooltipAttributes = {
  title: 'Comparison',
  description: 'Choose which production types to compare.',
};

export const drilldownAttributes: ButtonTooltipAttributes = {
  title: 'Drilldown',
  description: 'Filter to marked items.',
};

export const refreshAttributes: ButtonTooltipAttributes = {
  title: 'Refresh',
  description: 'Refresh the Drilldown Table.',
};

export const volumeSettingsAttributes: ButtonTooltipAttributes = {
  title: 'Volume Settings',
  description: 'Choose which production volumes to display.',
};

export const grossNetAttributes: ButtonTooltipAttributes = {
  title: 'Gross/Net',
  description: 'Choose gross or net volumes.',
};

export const phaseAttributes: ButtonTooltipAttributes = {
  title: 'Phase',
  description: 'Choose the desired fluid phase.',
};

export const RateVolumeAttributes: ButtonTooltipAttributes = {
  title: 'Rate/Volume',
  description: 'Display production on a rate or volume basis',
};

export const drilldownTabAttributes: ButtonTooltipAttributes = {
  title: 'Drilldown',
  description: 'Open Drilldown panel.',
};

export const showHidePercentageAttributes: ButtonTooltipAttributes = {
  title: 'Show/Hide Percentage',
  description: 'Display variance on percent basis.',
};

export const showHideDollarsAttributes: ButtonTooltipAttributes = {
  title: 'Show/Hide Dollars',
  description: 'Display variance on a dollar value basis.',
};

export const REForecastAttributes: ButtonTooltipAttributes = {
  title: 'RE Forecast',
  description: 'Show/Hide RE Forecast.',
};

export const addSeriesAttributes: ButtonTooltipAttributes = {
  title: 'Add Series',
  description: 'Add series to layout.',
};

export const removeSeriesAttributes: ButtonTooltipAttributes = {
  title: 'Remove Series',
  description: 'Remove series from layout.',
};

export const showHideSeriesAttributes: ButtonTooltipAttributes = {
  title: 'Show/Hide',
  description: 'Show/Hide series.',
};

export const saveCurrentLayoutAttributes: ButtonTooltipAttributes = {
  title: 'Save',
  description: 'Save current layout.',
};

export const showHideRibbonsAttributes: ButtonTooltipAttributes = {
  title: 'Show/Hide',
  description: 'Show/Hide ribbon.',
};

export const closeLayoutConfigurationAttributes: ButtonTooltipAttributes = {
  title: 'Close',
  description: 'Close the Layout Configuration panel.',
};

export const notesAttributes: ButtonTooltipAttributes = {
  title: 'Notes',
  description: 'Open Notes panel.',
};

export const refreshNoteAttributes: ButtonTooltipAttributes = {
  title: 'Refresh',
  description: 'Refresh available notes.',
};

export const selectMailboxAttributes: ButtonTooltipAttributes = {
  title: 'Select',
  description: 'Select mailbox.',
};

export const selectAllNotesAttributes: ButtonTooltipAttributes = {
  title: 'Select All',
  description: 'Select all items.',
};

export const selectNoteAttributes: ButtonTooltipAttributes = {
  title: 'Select',
  description: 'Select item.',
};

export const selectPageNumberAttributes: ButtonTooltipAttributes = {
  title: 'Page Number',
  description: 'Select page number.',
};

export const nextPageAttributes: ButtonTooltipAttributes = {
  title: 'Next Page',
  description: 'Go to next page.',
};

export const previousPageAttributes: ButtonTooltipAttributes = {
  title: 'Previous Page',
  description: 'Go to previous page.',
};

export const moveToInboxAttributes: ButtonTooltipAttributes = {
  title: 'Move to Inbox',
  description: 'Move selected items to inbox.',
};

export const markAsReadAttributes: ButtonTooltipAttributes = {
  title: 'Mark as Read',
  description: 'Mark selected items as read.',
};

export const markAsUnreadAttributes: ButtonTooltipAttributes = {
  title: 'Mark as Unread',
  description: 'Mark selected items as unread.',
};

export const markAsReadAItemAttributes: ButtonTooltipAttributes = {
  title: 'Mark as Read',
  description: 'Mark item as read.',
};

export const markAsUnreadAItemAttributes: ButtonTooltipAttributes = {
  title: 'Mark as Unread',
  description: 'Mark item as unread.',
};

export const moveToInboxItemAttributes: ButtonTooltipAttributes = {
  title: 'Move to Inbox',
  description: 'Move item to inbox.',
};

export const zoomButtonAttributes: ButtonTooltipAttributes = {
  title: 'Zoom Settings',
  description: 'Open zoom settings menu.',
};

export const startZoomAttributes: ButtonTooltipAttributes = {
  title: 'Start Date',
  description: 'Select start date for production chart zoom.',
};

export const endZoomAttributes: ButtonTooltipAttributes = {
  title: 'End Date',
  description: 'Select end date for production chart zoom.',
};

export const zoomInAttributes: ButtonTooltipAttributes = {
  title: 'Zoom In',
  shortcut: Shortcuts.KeyI,
  description: 'Select start and end date for production chart zoom.',
};

export const zoomOutAttributes: ButtonTooltipAttributes = {
  title: 'Zoom Out',
  shortcut: Shortcuts.KeyO,
  description: 'Double current zoom date range.',
};

export const zoomPreviousAttributes: ButtonTooltipAttributes = {
  title: 'Zoom Previous',
  shortcut: Shortcuts.KeyP,
  description: 'Return to previous zoom date range.',
};

export const showAllAttributes: ButtonTooltipAttributes = {
  title: 'Show All',
  shortcut: Shortcuts.KeyS,
  description: 'Zoom to show all production history.',
};

export const threeMonthAttributes: ButtonTooltipAttributes = {
  title: '3 Months',
  shortcut: Shortcuts.KeyZThenNum1,
  description: 'Zoom to prior 3 months.',
};

export const sixMonthAttributes: ButtonTooltipAttributes = {
  title: '6 Months',
  shortcut: Shortcuts.KeyZThenNum2,
  description: 'Zoom to prior 6 months.',
};

export const twelveMonthAttributes: ButtonTooltipAttributes = {
  title: '12 Months',
  shortcut: Shortcuts.KeyZThenNum3,
  description: 'Zoom to prior 12 months.',
};

export const twentyFourMonthAttributes: ButtonTooltipAttributes = {
  title: '24 Months',
  shortcut: Shortcuts.KeyZThenNum4,
  description: 'Zoom to prior 24 months.',
};

export const openWellNotesAttributes: ButtonTooltipAttributes = {
  title: 'Well Notes',
  shortcut: Shortcuts.KeyN,
  description: 'Open Well Notes panel.',
};

export const closeWellNotesAttributes: ButtonTooltipAttributes = {
  title: 'Close Panel',
  shortcut: Shortcuts.Esc,
  description: 'Close Well Notes panel.',
};

export const nextWellNoteAttributes: ButtonTooltipAttributes = {
  title: 'Next',
  description: 'Go to next note.',
};

export const previousWellNoteAttributes: ButtonTooltipAttributes = {
  title: 'Previous',
  description: 'Go to previous note.',
};

export const noteDateAttributes: ButtonTooltipAttributes = {
  title: 'Note Date',
  description: 'Select note date.',
};

export const addNoteAttributes: ButtonTooltipAttributes = {
  title: 'Add Note',
  description: 'Add a new well note.',
};

export const editNoteAttributes: ButtonTooltipAttributes = {
  title: 'Edit Note',
  description: 'Edit well note.',
};

// const discardNoteAttributes: ButtonTooltipAttributes = {
//   title: 'Edit Note',
//   description: 'Edit well note.',
// };

export const addNoteAttachmentAttributes: ButtonTooltipAttributes = {
  title: 'Add Attachment',
  description: 'Add a new attachment.',
};

export const addFilterAttributeAttributes: ButtonTooltipAttributes = {
  title: 'Add Attribute',
  description: 'Add attribute to layout.',
};

export const removeFilterAttributeAttributes: ButtonTooltipAttributes = {
  title: 'Remove Attribute',
  description: 'Remove attribute from layout.',
};

export const showHideFilterAttributeAttributes: ButtonTooltipAttributes = {
  title: 'Show/Hide',
  description: 'Show/Hide attribute.',
};

export const selectFilterSavedLayoutsAttributes: ButtonTooltipAttributes = {
  title: 'Saved Layouts',
  description: 'Select a saved well attributes layout.',
};

export const addCustomFilterAttributeAttributes: ButtonTooltipAttributes = {
  title: 'Add Custom Attribute',
  description:
    'Add a custom attribute that can be modified in the one line table.',
};

export const hideLegendAttributes: ButtonTooltipAttributes = {
  title: 'Hide Legend',
};

export const showLegendAttributes: ButtonTooltipAttributes = {
  title: 'Show Legend',
};

export const openCapacityChangeDetailsAttributes: ButtonTooltipAttributes = {
  title: 'Details',
  description: 'Open the Capacity Change Details panel.',
};

export const openVarianceEventDetailsAttributes: ButtonTooltipAttributes = {
  title: 'Details',
  description: 'Open the Variance Event Details panel.',
};

export const closeCapacityChangeEventAttributes: ButtonTooltipAttributes = {
  title: 'Close',
  description: 'Close the Capacity Change Details panel.',
};

export const startCapacityDateAttributes: ButtonTooltipAttributes = {
  title: 'Start Date',
  description: 'Select start date for the Capacity Change.',
};

export const categoryOfCapacityEventAttributes: ButtonTooltipAttributes = {
  title: 'Category',
  description: 'Select event parent category.',
};

export const subCategoryOfCapacityEventAttributes: ButtonTooltipAttributes = {
  title: 'Sub-Category',
  description: 'Select event sub-category.',
};

export const plannedCapacityEventAttributes: ButtonTooltipAttributes = {
  title: 'Planned?',
  description: 'Select event planned/unplanned status.',
};

export const descriptionOfEventAttributes: ButtonTooltipAttributes = {
  title: 'Description',
  description: 'Provide an event description.',
};

export const nextEventAttributes: ButtonTooltipAttributes = {
  title: 'Next Event',
};

export const previousAttributes: ButtonTooltipAttributes = {
  title: 'Previous Event',
};

export const deleteEventAttributes: ButtonTooltipAttributes = {
  title: 'Delete Event',
  description: 'Delete the event.',
};

export const closeVarianceEventAttributes: ButtonTooltipAttributes = {
  title: 'Close',
  description: 'Close the Variance Event Details panel.',
};

export const varianceDateRangeAttributes: ButtonTooltipAttributes = {
  title: 'Date Range',
  description: 'Select event start start and end date.',
};

export const addConversationAttachmentAttributes: ButtonTooltipAttributes = {
  title: 'Attachment',
  description: 'Add an attachment.',
};

export const saveNoteAttributes: ButtonTooltipAttributes = {
  title: 'Save',
  description: 'Save note.',
};

export const cancelCapacityAttributes: ButtonTooltipAttributes = {
  title: 'Cancel',
  description: 'Close event without saving changes.',
};

export const varianceCategoryNameAttributes: ButtonTooltipAttributes = {
  title: 'Category Name',
  description: 'Select category to filter items in Drilldown Table.',
};
