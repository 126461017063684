import * as React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from 'components/Button';
import { ArrowIcon, CloseIcon } from 'components/Icons';
import {
  ButtonTooltipAttributes,
  initialTooltipContent,
} from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

type RightPanelProps = {
  children: React.ReactNode;
  isShown: boolean;
  isLoading?: boolean;
  isShownLeftArrow?: boolean;
  isShownRightArrow?: boolean;
  handleKeyDown?: (e: KeyboardEvent) => void;
  onLeftArrowClick?: () => void;
  onRightArrowClick?: () => void;
  onDialogClose: () => void;
  title: string;
  titleForCloseButton?: ButtonTooltipAttributes;
  titleForRightArrow?: ButtonTooltipAttributes;
  titleForLeftArrow?: ButtonTooltipAttributes;
};

const RightPanel = ({
  children,
  isShown,
  isLoading,
  isShownLeftArrow,
  isShownRightArrow,
  handleKeyDown,
  onDialogClose,
  onLeftArrowClick,
  onRightArrowClick,
  title,
  titleForCloseButton,
  titleForRightArrow,
  titleForLeftArrow,
}: RightPanelProps) => {
  const shownCloseButton =
    isShown &&
    (window.location.pathname.includes('/dashboard') ||
      window.location.pathname.includes('/filter'));

  React.useEffect(() => {
    const keydownHandler = e => handleKeyDown?.(e);
    document.addEventListener('keydown', keydownHandler);
    return () => document.removeEventListener('keydown', keydownHandler);
  }, [handleKeyDown]);

  return (
    <RightPanel.Card>
      <RightPanel.Header>
        <RightPanel.TitleWrapper>
          <RightPanel.Title>{title}</RightPanel.Title>
          <RightPanel.ArrowContainer>
            <RightPanel.ButtonWrapper>
              {isShownLeftArrow && onLeftArrowClick ? (
                <ButtonTooltip
                  content={
                    titleForLeftArrow
                      ? titleForLeftArrow
                      : initialTooltipContent
                  }
                >
                  <Button transparent onClick={onLeftArrowClick}>
                    <ArrowIcon />
                  </Button>
                </ButtonTooltip>
              ) : null}
            </RightPanel.ButtonWrapper>
            <RightPanel.ButtonWrapper>
              {isShownRightArrow && onRightArrowClick ? (
                <ButtonTooltip
                  content={
                    titleForRightArrow
                      ? titleForRightArrow
                      : initialTooltipContent
                  }
                >
                  <RightPanel.RightArrow>
                    <Button
                      transparent
                      onClick={() => {
                        onRightArrowClick();
                      }}
                    >
                      <ArrowIcon />
                    </Button>
                  </RightPanel.RightArrow>
                </ButtonTooltip>
              ) : null}
            </RightPanel.ButtonWrapper>
          </RightPanel.ArrowContainer>
        </RightPanel.TitleWrapper>

        {shownCloseButton && (
          <ButtonTooltip
            content={
              titleForCloseButton ? titleForCloseButton : initialTooltipContent
            }
          >
            <RightPanel.CloseButton
              transparent
              onClick={onDialogClose}
              width={25}
            >
              <CloseIcon />
            </RightPanel.CloseButton>
          </ButtonTooltip>
        )}
      </RightPanel.Header>
      <RightPanel.Content>
        {isLoading && (
          <RightPanel.LoadingIndicator>
            <CircularProgress color="inherit" size={21} />
          </RightPanel.LoadingIndicator>
        )}

        {!isLoading && children}
      </RightPanel.Content>
    </RightPanel.Card>
  );
};

RightPanel.Card = styled.div`
  background: white;
  height: 100%;
  overflow-x: hidden;
`;

RightPanel.Header = styled.div`
  height: 47px;
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
  padding: 15px 10px;
  font-size: 16px;
  border-bottom: 1px solid #000;
  font-weight: bold;
  align-items: center;
`;

RightPanel.Content = styled.div`
  height: calc(100% - 47px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

RightPanel.LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
`;

RightPanel.TitleWrapper = styled.div`
  display: flex;
  align-content: center;
`;

RightPanel.ArrowContainer = styled.div`
  display: flex;
`;

RightPanel.CloseButton = styled(Button)`
  justify-self: end;
`;

RightPanel.RightArrow = styled.div`
  transform: rotate(180deg);
  > button {
    padding: 0;
    width: 18px;
  }
`;

RightPanel.ButtonWrapper = styled.div`
  width: 20px;
  > button {
    padding: 0;
    width: 18px;
  }
`;

RightPanel.Title = styled.div`
  display: flex;
  align-items: center;
`;

export default React.memo<RightPanelProps>(RightPanel);
