import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';

type BaseOption = { id: string; text: string };

type Props<T extends BaseOption> = {
  onSelectOption: (name: T['id']) => void;
  options: T[] | Readonly<T[]>;
  isOpen: boolean;
  onToggleOpen: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export function DrillDownDropDown<T extends BaseOption>({
  onSelectOption: onSelect,
  options,
  onToggleOpen,
  isOpen,
}: Props<T>) {
  const onSelectOption = (name: string) => {
    onToggleOpen();
    onSelect(name);
  };

  const onDropdownOpen = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    onToggleOpen();
  };

  return (
    <>
      <RiArrowDropDownLine
        onClick={onDropdownOpen}
        style={{
          width: 30,
          height: 30,
          cursor: 'pointer',
          position: 'absolute',
          left: 0,
        }}
        fill="#484848"
      />
      {isOpen && (
        <DrillDownDropDown.Paper variant="outlined">
          {options.map(opt => (
            <DrillDownDropDown.LayoutOption
              onClick={() => onSelectOption(opt.id)}
            >
              {opt.text}
            </DrillDownDropDown.LayoutOption>
          ))}
        </DrillDownDropDown.Paper>
      )}
    </>
  );
}

DrillDownDropDown.Paper = styled(Paper)`
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 100%;
  height: auto;
`;

DrillDownDropDown.LayoutOption = styled.div`
  padding: 10px 5px;
  background-color: #fff;
  font-size: 14px;
  color: #484848;
  border: 1px solid #c1c1c1;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;
