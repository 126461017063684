import { capitalize } from 'helpers/String';
import type { GroupsMap, Group } from 'modules/well/models/well';

const mapGroupKeyToGroupTitle = (groupKey: Group['key']) => {
  return groupKey.split('_').map(capitalize).join(' ');
};

const getGroupTitleByGroupKey = (
  groupKey: Group['key'],
  groupsMap: GroupsMap,
) => {
  return groupsMap[groupKey]?.title ?? mapGroupKeyToGroupTitle(groupKey);
};

export default getGroupTitleByGroupKey;
