import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import type { Action } from 'store/models';
import { getGraphqlPayload } from 'store/helpers';

import { FeatureFlag, FeatureFlagValue } from './models';
import { getFlagValue } from './utils';
import {
  FETCH_ALL_FEATURE_FLAGS_SUCCESS,
  FETCH_ALL_FEATURE_FLAGS_ANON_SUCCESS,
  TOGGLE_FEATURE_FLAG_LOCALLY,
} from './FeatureFlagsActions';

export const STATE_KEY = 'featureFlags';

export interface FeatureFlagsState {
  allFlags: FeatureFlag[];
  allAnonFlags: FeatureFlag[];
  localValues: Record<string, FeatureFlagValue>;
}

export type FlagsMap = ReturnType<typeof getFlagsStateMap>;

const initialState: FeatureFlagsState = {
  allFlags: [],
  allAnonFlags: [],
  localValues: {},
};

const FeatureFlagsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_ALL_FEATURE_FLAGS_SUCCESS: {
      const allFlags = getGraphqlPayload(action);
      return { ...state, allFlags };
    }
    case FETCH_ALL_FEATURE_FLAGS_ANON_SUCCESS: {
      const allAnonFlags = getGraphqlPayload(action);
      return { ...state, allAnonFlags };
    }
    case TOGGLE_FEATURE_FLAG_LOCALLY: {
      const flagKey = action.payload;
      const oldValue = getFlagValue(flagKey, state.allFlags, state.localValues);
      const flagValue = !oldValue;
      const localValues = { ...state.localValues, [flagKey]: flagValue };
      return { ...state, localValues };
    }
    default: {
      return state;
    }
  }
};

export const getFeatureFlagsState = (state: Record<string, any>) =>
  state[STATE_KEY] as FeatureFlagsState;

export const getAllFeatureFlags = createSelector(
  getFeatureFlagsState,
  state => state.allFlags,
);

export const getAllAnonFeatureFlags = createSelector(
  getFeatureFlagsState,
  state => state.allAnonFlags,
);

export const getToogleableFeatureFlags = createSelector(
  getAllFeatureFlags,
  allFlags => allFlags.filter(flag => flag.toggleable),
);

export const getLocalFeatureFlagsValues = createSelector(
  getFeatureFlagsState,
  state => state.localValues,
);

export const flagValueGetter = createSelector(
  getAllFeatureFlags,
  getLocalFeatureFlagsValues,
  (allFlags, localValues) => (flagKey: string) =>
    getFlagValue(flagKey, allFlags, localValues),
);

export const getFlagsStateMap = createSelector(
  flagValueGetter,
  getFlagValue => {
    return {
      allocationIssues: getFlagValue('allocation-issues'),
      conversationsPolling: getFlagValue('conversations-polling'),
      drilldownMarking: getFlagValue('drilldown-marking'),
      drilldownGrouping: getFlagValue('drilldown-grouping'),
      drilldownCompraring: getFlagValue('drilldown-comparing'),
      generateRaster: getFlagValue('generate-raster'),
      sensorSeries: getFlagValue('sensor-series'),
      spotfireDashboards: getFlagValue('spotfire-dashboards'),
      varianceDrilldownMarkingFilter: getFlagValue(
        'variance-drilldown-marking-filter',
      ),
      recordVideo: getFlagValue('record-video'),
      dashboardPermissions: getFlagValue('dashboard-permissions'),
      internalLogin: getFlagValue('internal-login'),
      logChartRenderTime: getFlagValue('log-chart-render-time'),
      tooltips: getFlagValue('tooltips'),
      customDrilldown: getFlagValue('custom-drilldown-layouts'),
      calculatedSeries: getFlagValue('calculated-series'),
      mustAcceptPolicies: getFlagValue('must-accept-policies'),
      hideWellNoteCreation: getFlagValue('hide-well-note-creation'),
      notesRibbonSection: getFlagValue('notes-ribbon-section'),
      showAllSpotfireDashboards: getFlagValue('show-all-dashboards'),
    };
  },
);

export const getAnonFlagsStateMap = createSelector(
  getAllAnonFeatureFlags,
  anonFlags => {
    return {
      internalLogin: getFlagValue('internal-login', anonFlags),
    };
  },
);

export default filterActions(FeatureFlagsReducer as any, [
  FETCH_ALL_FEATURE_FLAGS_SUCCESS,
  FETCH_ALL_FEATURE_FLAGS_ANON_SUCCESS,
  TOGGLE_FEATURE_FLAG_LOCALLY,
]);
