import { scaleUtc } from 'd3-scale';
import { utcDay } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';
import { throttle } from 'throttle-debounce';

import {
  getMinDate,
  getMaxDate,
  getVarianceExtremeDates,
  isIdNew,
} from 'helpers';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

interface OverlayAddAllocIssueProps {
  additionModeOff: () => void;
  allocIssues: AllocIssue[];
  createLocalAllocIssue: (date: Date) => void;
  deleteLocalAllocIssue: (id: string) => void;
  extremeDates: { [key: string]: Date };
  height: number;
  leftOffset: number;
  onAllocIssueCreate: (localAllocIssue: AllocIssue) => void;
  today: Date;
  varianceEvents: VarianceEvent[];
  updateLocalAllocIssue: ({
    updatedIssue: AllocIssue,
    data: Object,
    interactive: boolean,
  }) => void;
  width: number;
}

const OverlayAddAllocIssue = (
  {
    additionModeOff,
    allocIssues,
    createLocalAllocIssue,
    deleteLocalAllocIssue,
    extremeDates,
    height,
    leftOffset,
    onAllocIssueCreate,
    today,
    varianceEvents,
    updateLocalAllocIssue,
    width,
  }: OverlayAddAllocIssueProps,
  ref,
) => {
  const localIssue = allocIssues.find(
    issue => isIdNew(issue.id) && !issue.syncing,
  );
  const existedRanges = React.useMemo(
    () =>
      [...allocIssues, ...varianceEvents].map(varianceEntity => ({
        dateStart:
          (varianceEntity as AllocIssue).dateStart ||
          (varianceEntity as VarianceEvent).dayStart,
        dateEnd:
          (varianceEntity as AllocIssue).dateEnd ||
          (varianceEntity as VarianceEvent).dayEnd,
        id: varianceEntity.id,
      })),
    [varianceEvents, allocIssues],
  );

  const xScale = React.useMemo(
    () =>
      scaleUtc().range([0, width]).domain([extremeDates.min, extremeDates.max]),
    [extremeDates, width],
  );

  const [isCreating, setCreatingStatus] = React.useState(false);
  const [lineXPosition, setLineXPosition] = React.useState(0);
  const [fixedDateOfAllocIssue, setFixedDateOfAllocIssue] = React.useState(
    extremeDates.min,
  );

  const startCreatingLineMove = () => setCreatingStatus(true);
  const finishCreatingLineMove = () => setCreatingStatus(false);
  const moveLine = React.useCallback(
    e => setLineXPosition(e.clientX - leftOffset - Y_AXIS_WIDTH - 10),
    [leftOffset],
  );
  const hideLine = () => setLineXPosition(0);

  const initAllocIssueCreating = React.useCallback(
    (date: Date) => {
      setFixedDateOfAllocIssue(date);
      startCreatingLineMove();
      createLocalAllocIssue(date);
    },
    [createLocalAllocIssue],
  );

  const updateAllocIssueOnMove = React.useCallback(
    (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
      if (!localIssue) return;
      const extremeAllocDates = getVarianceExtremeDates(
        allocIssues,
        varianceEvents,
        localIssue.id,
        today,
        'Allocation Issue',
      );
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const pointerInverted = xScale.invert(pointerPosition);
      const dateOfCursor =
        fixedDateOfAllocIssue > pointerInverted
          ? utcDay.round(pointerInverted)
          : utcDay.round(utcDay.offset(pointerInverted, -1));
      if (
        dateOfCursor < extremeAllocDates[0] ||
        dateOfCursor > extremeAllocDates[1]
      )
        return;

      const adjustedDate =
        fixedDateOfAllocIssue && fixedDateOfAllocIssue > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeAllocDates[0])
          : getMinDate(dateOfCursor, extremeAllocDates[1]);

      const newDay = utcDay.round(adjustedDate);
      if (localIssue) {
        const updateData = {
          dateStart: getMinDate(fixedDateOfAllocIssue, newDay),
          dateEnd: getMaxDate(fixedDateOfAllocIssue, newDay),
        };

        updateLocalAllocIssue({
          updatedIssue: localIssue,
          data: updateData,
          interactive: true,
        });
      }
    },
    [
      allocIssues,
      fixedDateOfAllocIssue,
      leftOffset,
      localIssue,
      today,
      updateLocalAllocIssue,
      varianceEvents,
      xScale,
    ],
  );

  const finishAllocIssueCreating = React.useCallback(async () => {
    if (!fixedDateOfAllocIssue || !localIssue) return;
    await finishCreatingLineMove();
    onAllocIssueCreate(localIssue);
    setFixedDateOfAllocIssue(extremeDates.min);
    additionModeOff();
  }, [
    additionModeOff,
    extremeDates.min,
    fixedDateOfAllocIssue,
    localIssue,
    onAllocIssueCreate,
  ]);

  const handleMouseClick = React.useCallback(
    (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
      if (!isCreating) {
        const date = utcDay.floor(
          xScale.invert(e.clientX - leftOffset - Y_AXIS_WIDTH - 10),
        );
        if (
          allocIssues.some(
            issue => date >= issue.dateStart && date <= issue.dateEnd,
          ) ||
          date >= today
        )
          return;
        initAllocIssueCreating(date);
      } else {
        finishAllocIssueCreating();
      }
    },
    [
      today,
      leftOffset,
      allocIssues,
      finishAllocIssueCreating,
      initAllocIssueCreating,
      isCreating,
      xScale,
    ],
  );

  const refValue = React.useRef({ isCreating, id: '' });
  React.useEffect(() => {
    refValue.current = { isCreating, id: localIssue ? localIssue.id : '' };
  }, [localIssue, isCreating]);

  const unmountHandler = React.useCallback(() => {
    if (refValue.current && refValue.current.id) {
      deleteLocalAllocIssue(refValue.current.id);
    }
  }, [deleteLocalAllocIssue]);

  React.useEffect(() => {
    return () => {
      unmountHandler();
    };
  }, [unmountHandler]);

  return (
    <OverlayAddAllocIssue.Container
      height={height}
      className="alloc-interqctive interactive"
    >
      <svg
        ref={ref}
        height={height}
        onMouseMove={e => throttle(300, false, moveLine(e))}
        onMouseOver={e => moveLine(e)}
        preserveAspectRatio="none"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
      >
        <g
          onClick={e => handleMouseClick(e)}
          onMouseMove={isCreating ? e => updateAllocIssueOnMove(e) : undefined}
        >
          <rect
            fill={process.env.DEBUG ? 'rgba(150, 0, 150, 0.5)' : 'transparent'}
            height={height}
            width={width}
            x={0}
            y={0}
          />

          {existedRanges.map((range, i) => {
            const startPos = xScale(range.dateStart);
            const endPos = xScale(utcDay.offset(range.dateEnd, 1));

            return (
              <polygon
                fill={
                  process.env.DEBUG ? 'rgba(150, 0, 150, 0.7)' : 'transparent'
                }
                key={`allocIssueOverlay_${range.id}`}
                onMouseEnter={() => hideLine()}
                onMouseMove={e => {
                  if (!isCreating) e.stopPropagation();
                  hideLine();
                }}
                onClick={e => {
                  if (!isCreating) e.stopPropagation();
                }}
                points={`${startPos},0 ${endPos},0 ${endPos},${height} ${startPos}, ${height}`}
              />
            );
          })}

          <polygon
            fill={process.env.DEBUG ? 'rgba(50, 50, 50, 0.7)' : 'transparent'}
            onMouseEnter={() => hideLine()}
            onMouseMove={e => {
              if (!isCreating) e.stopPropagation();
              hideLine();
            }}
            onClick={e => {
              if (!isCreating) e.stopPropagation();
            }}
            points={`${xScale(today)},0 ${xScale(extremeDates.max)},0 ${xScale(
              extremeDates.max,
            )},${height} ${xScale(today)}, ${height}`}
          />

          <line
            stroke={isCreating ? 'transparent' : 'black'}
            strokeDasharray="2"
            strokeWidth="1"
            x1={0}
            x2={0}
            y1={0}
            y2={height}
            transform={`translate(${lineXPosition}, 0)`}
          />
        </g>
      </svg>
    </OverlayAddAllocIssue.Container>
  );
};

OverlayAddAllocIssue.Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 75;
`;

export default React.memo<OverlayAddAllocIssueProps & { ref: any }>(
  React.forwardRef<Element, OverlayAddAllocIssueProps>(OverlayAddAllocIssue),
);
