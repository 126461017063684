import type { User } from 'modules/user/models/user';
import { FlagsMap } from 'modules/featureFlags/FeatureFlagsReducer';
import { AuthPermissions } from 'modules/auth/AuthReducer';

const getPermissions = (flags: FlagsMap, permissions: AuthPermissions) => {
  return [
    permissions.isAdmin && {
      key: 'isAdmin',
      label: 'Administrator',
    },
    {
      key: 'isUserManager',
      label: 'User Management',
    },
    {
      key: 'isAllowedEditCapChanges',
      label: 'Edit Capacity Change Events',
    },
    {
      key: 'isAllowedEditVarEvents',
      label: 'Edit Variance Events',
    },
    {
      key: 'isAllowedEditRibbonEvents',
      label: 'Edit Ribbon Events',
    },
    flags.allocationIssues && {
      key: 'isAllowedEditAllocIssues',
      label: 'Edit Allocation Issues',
    },
    {
      key: 'isAllowedViewForecast',
      label: 'View External Forecast',
    },
    flags.dashboardPermissions && {
      key: 'isAllowedViewDashboards',
      label: 'View Dashboards',
    },
    {
      key: 'isAllowedViewAllSpotfireDashboards',
      label: 'View Dev Dashboards',
    },
    {
      key: 'taggable',
      label: 'Taggable',
    },
  ].filter(Boolean) as { key: string; label: string }[];
};

const getHeaderColumnsOld = (flags: FlagsMap) => {
  return [
    {
      label: 'First Name',
      isSortable: true,
      sortKey: 'firstName',
      width: 192,
      alignCenter: false,
    },
    {
      label: 'Last Name',
      isSortable: true,
      sortKey: 'lastName',
      width: 192,
      alignCenter: false,
    },
    {
      label: 'Active',
      isSortable: true,
      sortKey: 'inactive',
      width: 80,
      alignCenter: false,
    },
    {
      label: 'Permissions',
      sortKey: 'permissions',
      width: 560,
      alignCenter: false,
    },
    { width: 150 },
  ];
};

const getHeaderColumnsNew = (flags: FlagsMap, permissions: AuthPermissions) => {
  return [
    {
      label: 'First Name',
      csvLabel: 'first_name',
      isSortable: true,
      sortKey: 'firstName',
      width: 192,
    },
    {
      label: 'Last Name',
      csvLabel: 'last_name',
      isSortable: true,
      sortKey: 'lastName',
      width: 192,
    },
    {
      label: 'Email',
      csvLabel: 'email',
      isSortable: true,
      sortKey: 'email',
      width: 192,
    },
    permissions.isAdmin && {
      label: 'Admin',
      csvLabel: 'is_admin',
      isSortable: false,
      sortKey: 'admin',
      width: 80,
      alignCenter: true,
    },
    {
      label: 'User Mgmt.',
      csvLabel: 'user_management',
      isSortable: false,
      sortKey: 'userMgmt',
      width: 80,
      alignCenter: true,
    },
    {
      label: 'Capacity Change',
      csvLabel: 'capacity_change_event',
      isSortable: false,
      sortKey: 'capacityChange',
      width: 80,
      alignCenter: true,
    },
    {
      label: 'Variance Event',
      csvLabel: 'variance_event',
      isSortable: false,
      sortKey: 'varianceEvent',
      width: 80,
      alignCenter: true,
    },
    {
      label: 'Ribbon Event',
      csvLabel: 'ribbon_event',
      isSortable: false,
      sortKey: 'ribbonEvent',
      width: 80,
      alignCenter: true,
    },
    flags.allocationIssues && {
      label: 'Alloc. Event',
      csvLabel: 'alloc_issue_event',
      isSortable: false,
      sortKey: 'allocEvent',
      width: 80,
      alignCenter: true,
    },
    {
      label: 'External Forecast',
      csvLabel: 'external_forecast',
      isSortable: false,
      sortKey: 'viewForecast',
      width: 80,
      alignCenter: true,
    },
    {
      label: 'Dash boards',
      csvLabel: 'dashboards',
      isSortable: false,
      sortKey: 'dashboardPermissions',
      width: 80,
      alignCenter: true,
    },
    {
      label: 'Dev Dash boards',
      csvLabel: 'dev_dashboards',
      isSortable: false,
      sortKey: 'dashboardPermissions',
      width: 110,
      alignCenter: true,
    },
    {
      label: 'Taggable',
      csvLabel: 'taggable',
      isSortable: false,
      sortKey: 'taggable',
      width: 80,
      alignCenter: true,
    },
    {
      label: 'Active User',
      csvLabel: 'is_active',
      isSortable: false,
      sortKey: 'inactive',
      width: 80,
      alignCenter: true,
    },
    { width: 150 },
  ].filter(Boolean);
};

const getHeaderColumns = (flags: FlagsMap, permissions: AuthPermissions) => {
  return flags.dashboardPermissions
    ? getHeaderColumnsNew(flags, permissions)
    : getHeaderColumnsOld(flags);
};

export const getUserTableConfiguration = (
  flags: FlagsMap,
  permissions: AuthPermissions,
) => {
  return {
    permissions: getPermissions(flags, permissions),
    columns: getHeaderColumns(flags, permissions),
  };
};

export const getUsersCSVHeader = (
  flags: FlagsMap,
  permissions: AuthPermissions,
) => {
  return (
    getHeaderColumnsNew(flags, permissions)
      .map(col => col && col?.csvLabel)
      .filter(label => Boolean(label))
      .join(',') + '\n'
  );
};

export const getUserCSV = (
  user: User,
  flags: FlagsMap,
  permissions: AuthPermissions,
) => {
  const parseBoolean = (v?: any) => (v ? 'true' : 'false');

  const userCSVSrc = [
    user.firstName,
    user.lastName,
    user.email,
    permissions.isAdmin && parseBoolean(user.isAdmin),
    parseBoolean(user.isUserManager),
    parseBoolean(user.isAllowedEditCapChanges),
    parseBoolean(user.isAllowedEditVarEvents),
    parseBoolean(user.isAllowedEditRibbonEvents),
    flags.allocationIssues && parseBoolean(user.isAllowedEditAllocIssues),
    parseBoolean(user.isAllowedViewForecast),
    parseBoolean(user.isAllowedViewDashboards),
    parseBoolean(user.isAllowedViewAllSpotfireDashboards),
    parseBoolean(user.taggable),
    parseBoolean(!user.inactive),
  ].filter(Boolean);

  return userCSVSrc.join(',');
};
