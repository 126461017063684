import {
  all,
  call,
  put,
  take,
  takeLatest,
  select,
  race,
  PutEffect,
  delay,
  fork,
} from 'redux-saga/effects';
import { timeDay } from 'd3-time';
import DB from 'database';
import Metrics from 'modules/metrics/services/Metrics';
import {
  fetchWellForecast,
  FETCH_WELL_FORECAST_DATE_RANGE,
} from 'modules/externalForecast/ExternalForecastActions';
import {
  fetchWellProduction,
  FETCH_WELL_PRODUCTION_DATE_RANGE,
} from 'modules/production/ProductionActions';
import {
  fetchWellSeries,
  FETCH_SERIES_DATE_RANGE,
} from 'modules/series/SeriesActions';
import { getCurrentWellId } from 'modules/ui/UIReducer';

import {
  initFetchSecondaryChartData,
  populatePrimaryChartData,
} from './ChartActions';
import { SET_CURRENT_WELL_ID, setCurrentWellId } from 'modules/ui/UIActions';
import { FETCH_WELL_CAPACITY } from 'modules/capacityChangeEvent/CapacityChangeEventActions';
import { FETCH_VARIANCE_EVENTS } from 'modules/varianceEvent/VarianceEventActions';
import { FETCH_WELL_RIBBON_EVENTS } from 'modules/ribbon/RibbonActions';
import { getHasForecastDataForCurrentWell } from 'modules/externalForecast/ExternalForecastReducer';
import { getHasCapacityDataForCurrentWell } from 'modules/capacityChangeEvent/CapacityChangeEventReducer';
import { getHasVarianceDataForCurrentWell } from 'modules/varianceEvent/VarianceEventReducer';
import { getHasRibbonsDataForCurrentWell } from 'modules/ribbon/RibbonReducer';
import { getHasSeriesDataForCurrentWell } from 'modules/series/SeriesReducer';
import { getFlagsStateMap } from '../featureFlags/FeatureFlagsReducer';
import { getWellName } from '../well/WellReducer';
import { getGraphqlPrevActionVariables } from 'store/helpers';
import { getIdsDailyDataSeriesToDisplay } from 'modules/chartOptions/ChartOptionsReducer';
import { fetchExternalForecastSaga } from '../externalForecast/ExternalForecastSagas';
import { fetchCapacityForCurrentWellSaga } from '../capacityChangeEvent/CapacityChangeEventSagas';
import { fetchWellVarianceEventsSaga } from '../varianceEvent/VarianceEventSagas';
import { initFetchWellSeriesSaga } from '../series/SeriesSagas';
import {
  ADD_OPTIONS_GROUP,
  SET_NEW_GROUP_ORDER,
} from '../chartOptions/ChartOptionsActions';
import * as R from 'ramda';
import { FETCH_APP_CONFIG } from '../appConfig/AppConfigActions';
import { getExtremeDates } from 'modules/production/ProductionReducer';

function* fetchWellSeriesSaga(): Generator<any, any, any> {
  while (true) {
    const previousSeriesIndexes = yield select(getIdsDailyDataSeriesToDisplay);
    yield take([ADD_OPTIONS_GROUP, SET_NEW_GROUP_ORDER]);
    const newIndexes = yield select(getIdsDailyDataSeriesToDisplay);
    const seriesIndexes = R.without(previousSeriesIndexes, newIndexes);
    if (!seriesIndexes.length) continue;
    const wellId = yield select(getCurrentWellId);
    yield put(fetchWellSeries({ wellId, seriesIndexes }));
  }
}

function* initAllDataFetchSaga(): Generator<any, any, any> {
  const currentWellId = yield select(getCurrentWellId);
  const productionExists = yield call(
    DB.checkWellProductionExists,
    currentWellId,
  );

  yield all([
    call(fetchExternalForecastSaga, setCurrentWellId(currentWellId)),
    call(fetchCapacityForCurrentWellSaga, setCurrentWellId(currentWellId)),
    call(fetchWellVarianceEventsSaga, setCurrentWellId(currentWellId)),
    call(initFetchWellSeriesSaga, setCurrentWellId(currentWellId)),
  ]);

  const result = yield race({
    forecast: take(FETCH_WELL_FORECAST_DATE_RANGE),
    production: take(FETCH_WELL_PRODUCTION_DATE_RANGE),
    series: take(FETCH_SERIES_DATE_RANGE),
  });

  const action: any = Object.values(result)[0];
  const wellId = action.payload.graphql.variables.payload.wellId;

  const fetchStatus = {
    [`${FETCH_WELL_FORECAST_DATE_RANGE}_SUCCESS`]: false,
    [`${FETCH_WELL_PRODUCTION_DATE_RANGE}_SUCCESS`]: productionExists,
    [`${FETCH_SERIES_DATE_RANGE}_SUCCESS`]: false,
    [`${FETCH_WELL_CAPACITY}_SUCCESS`]: false,
    [`${FETCH_VARIANCE_EVENTS}_SUCCESS`]: false,
    wellId,
  };

  const postEffects: PutEffect[] = [];
  while (!Object.values(fetchStatus).every(v => !!v)) {
    const action = yield take([
      `${FETCH_WELL_FORECAST_DATE_RANGE}_SUCCESS`,
      `${FETCH_WELL_PRODUCTION_DATE_RANGE}_SUCCESS`,
      `${FETCH_SERIES_DATE_RANGE}_SUCCESS`,
      `${FETCH_WELL_CAPACITY}_SUCCESS`,
      `${FETCH_VARIANCE_EVENTS}_SUCCESS`,
      `${FETCH_WELL_RIBBON_EVENTS}_SUCCESS`,
    ]);

    const previouslyPayloadForCurrentAction =
      getGraphqlPrevActionVariables(action);
    const requestWellId = previouslyPayloadForCurrentAction.payload
      ? previouslyPayloadForCurrentAction.payload.wellId
      : previouslyPayloadForCurrentAction.wellId;

    if (requestWellId === currentWellId) {
      fetchStatus[action.type] = true;
      postEffects.push(put(populatePrimaryChartData(action)));
    }
  }
  if (currentWellId !== fetchStatus.wellId) {
    return;
  }

  const seriesIndexes = yield select(getIdsDailyDataSeriesToDisplay);

  const effects = [
    put(initFetchSecondaryChartData()),
    put(fetchWellForecast(fetchStatus.wellId)),
  ];

  if (!productionExists)
    effects.push(
      put(
        fetchWellProduction({
          wellId: fetchStatus.wellId,
        }),
      ),
    );
  if (currentWellId === fetchStatus.wellId) {
    yield all(effects);
    yield delay(0);
  }

  yield all(postEffects);
  yield delay(0);
  yield put(fetchWellSeries({ wellId: fetchStatus.wellId, seriesIndexes }));
}

function* trackWellSwitchingSaga(action): Generator<any, any, any> {
  const startTime = performance.now();
  const wellId = action.payload.id;

  const hasForecast = yield select(getHasForecastDataForCurrentWell);
  const hasProduction = yield call(DB.checkWellProductionExists, wellId);
  const hasSeries = yield select(getHasSeriesDataForCurrentWell);
  const hasCapacity = yield select(getHasCapacityDataForCurrentWell);
  const hasVariance = yield select(getHasVarianceDataForCurrentWell);
  const hasRibbons = yield select(getHasRibbonsDataForCurrentWell);
  const extremeDates = yield select(getExtremeDates);

  const numberOfDaysInZoomRange = !R.isEmpty(extremeDates)
    ? timeDay.count(extremeDates.min, extremeDates.max)
    : null;

  const actionStatus = {
    [`${FETCH_WELL_FORECAST_DATE_RANGE}_SUCCESS`]: {
      name: 'forecast',
      fecthed: hasForecast,
      duration: hasForecast ? 0 : -1,
    },
    [`${FETCH_WELL_PRODUCTION_DATE_RANGE}_SUCCESS`]: {
      name: 'production',
      fecthed: hasProduction,
      duration: hasProduction ? 0 : -1,
    },
    [`${FETCH_SERIES_DATE_RANGE}_SUCCESS`]: {
      name: 'series',
      fecthed: hasSeries,
      duration: hasSeries ? 0 : -1,
    },
    [`${FETCH_WELL_CAPACITY}_SUCCESS`]: {
      name: 'capacityChangeEvents',
      fecthed: hasCapacity,
      duration: hasCapacity ? 0 : -1,
    },
    [`${FETCH_VARIANCE_EVENTS}_SUCCESS`]: {
      name: 'varianceEvents',
      fecthed: hasVariance,
      duration: hasVariance ? 0 : -1,
    },
    [`${FETCH_WELL_RIBBON_EVENTS}_SUCCESS`]: {
      name: 'ribbonEvents',
      fecthed: hasRibbons,
      duration: hasRibbons ? 0 : -1,
    },
  };

  const waitFor = Object.keys(actionStatus).filter(
    key => !actionStatus[key].fecthed,
  );

  yield fork(function* () {
    while (!Object.values(actionStatus).every(v => v.fecthed)) {
      const action: any = yield take([...waitFor, SET_CURRENT_WELL_ID]);
      const duration = performance.now() - startTime;

      if (action.type === SET_CURRENT_WELL_ID) return;

      actionStatus[action.type].fecthed = true;
      actionStatus[action.type].duration = duration;
    }

    const timings = Object.values(actionStatus).reduce((acc, s) => {
      acc[s.name] = s.duration;
      return acc;
    }, {});

    const duration = performance.now() - startTime;

    const { logChartRenderTime } = yield select(getFlagsStateMap);

    if (logChartRenderTime) {
      const currentWellName = yield select(getWellName, wellId);
      //eslint-disable-next-line no-console
      console.log(`${currentWellName} -`, duration.toFixed(1) + 'ms');
    }

    Metrics.captureEvent('MEASURE_WELL_SWITCH', {
      duration: Math.round(duration),
      wellId,
      days: numberOfDaysInZoomRange ?? 'unknown',
      ...timings,
    });
  });
}

function* chartSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(`${FETCH_APP_CONFIG}_SUCCESS`, fetchWellSeriesSaga),
    takeLatest(SET_CURRENT_WELL_ID, initAllDataFetchSaga),
    takeLatest(SET_CURRENT_WELL_ID, trackWellSwitchingSaga),
  ]);
}

export default chartSagas;
