import * as R from 'ramda';
import { Reducer } from 'redux';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import type { Action, Selector } from 'store/models';
import {
  CHANGE_OPTION_VISIBLE,
  CLEAR_SELECTED_FILTER_OPTIONS,
  CREATE_FILTERS_LAYOUT,
  CREATE_WELL_CUSTOM_ATTRIBUTE,
  CREATE_WELL_CUSTOM_ATTRIBUTE_VALUES,
  FETCH_FILTERS_LAYOUTS,
  FETCH_WELL_CUSTOM_ATTRIBUTES_VALUES,
  namespace,
  REMOVE_FILTERS_LAYOUT,
  REMOVE_FILTER_OPTION,
  REMOVE_WELL_CUSTOM_ATTRIBUTE,
  REMOVE_WELL_CUSTOM_ATTRIBUTE_VALUES,
  RENAME_FILTERS_LAYOUT,
  SELECT_FILTER_OPTION,
  SET_CURRENT_CONFIGURATION,
  SET_CURRENT_FILTERS_LAYOUT,
  SET_CUSTOM_FILTER_OPTIONS,
  SET_DRAGGABLE_COLUMN,
  SET_DROP_INDICATOR_INDEX,
  SET_FILTERS_LAYOUTS,
  SET_FILTER_OPTIONS,
  SET_SELECTED_FILTER_OPTIONS,
  UPDATE_FILTERS_LAYOUT,
  UPDATE_WELL_CUSTOM_ATTRIBUTE,
  UPDATE_WELL_CUSTOM_ATTRIBUTE_VALUES,
} from './FilterLayoutsActions';
import {
  currentFilterLayout,
  CustomAttributeValue,
  FilterLayoutOptions,
  FiltersLayouts,
  LayoutOption,
  ListFiltersLayouts,
} from './models';

interface FilterLayoutsState {
  options: FilterLayoutOptions | Record<string, never>;
  customOptions: FilterLayoutOptions;
  customValues: CustomAttributeValue[];
  selectedOptions: string[];
  filtersLayouts: ListFiltersLayouts | Record<string, never>;
  currentLayout: currentFilterLayout | Record<string, never>;
  draggableColumn: string;
  dropIndicatorIndex: number | null;
}

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'filtersLayouts';

const initialState: FilterLayoutsState = {
  options: {},
  customOptions: {},
  customValues: [],
  selectedOptions: [],
  filtersLayouts: {},
  currentLayout: {},
  draggableColumn: '',
  dropIndicatorIndex: null,
};

const FiltersLayoutsReducer = (
  state: FilterLayoutsState = initialState,
  action: Action,
) => {
  const { payload } = action;
  switch (action.type) {
    case SET_DRAGGABLE_COLUMN: {
      const { columnName } = payload;
      return R.assoc('draggableColumn', columnName, state);
    }
    case SET_DROP_INDICATOR_INDEX: {
      return R.assoc('dropIndicatorIndex', action.payload.index, state);
    }
    case SET_FILTER_OPTIONS: {
      const { options } = payload;
      return R.assoc('options', options, state);
    }
    case SET_SELECTED_FILTER_OPTIONS: {
      const { selectedOptions } = payload;
      return R.assoc('selectedOptions', selectedOptions, state);
    }
    case CLEAR_SELECTED_FILTER_OPTIONS: {
      return R.assoc('selectedOptions', [], state);
    }
    case SET_CUSTOM_FILTER_OPTIONS: {
      const { options } = payload;
      return R.assoc('customOptions', options, state);
    }
    case SELECT_FILTER_OPTION: {
      const { optionId } = payload;

      const customOptionsIds = Object.keys(state.customOptions);

      if (customOptionsIds.includes(optionId)) {
        return R.compose(
          R.assocPath(['customOptions', optionId, 'isSelected'], true),
          R.assocPath(['customOptions', optionId, 'isShown'], true),
          R.assocPath(
            ['selectedOptions'],
            [...state.selectedOptions, optionId],
          ),
        )(state);
      }

      return R.compose(
        R.assocPath(['options', optionId, 'isSelected'], true),
        R.assocPath(['options', optionId, 'isShown'], true),
        R.assocPath(['selectedOptions'], [...state.selectedOptions, optionId]),
      )(state);
    }
    case REMOVE_FILTER_OPTION: {
      const { optionId } = payload;
      const customOptionsIds = Object.keys(state.customOptions);

      if (customOptionsIds.includes(optionId)) {
        return R.compose(
          R.assocPath(['customOptions', optionId, 'isSelected'], false),
          R.assocPath(['customOptions', optionId, 'isShown'], false),
          R.assocPath(
            ['selectedOptions'],
            state.selectedOptions.filter(opt => opt !== optionId),
          ),
        )(state);
      }

      return R.compose(
        R.assocPath(['options', optionId, 'isSelected'], false),
        R.assocPath(['options', optionId, 'isShown'], false),
        R.assocPath(
          ['selectedOptions'],
          state.selectedOptions.filter(opt => opt !== optionId),
        ),
      )(state);
    }
    case CHANGE_OPTION_VISIBLE: {
      const { optionId } = payload;
      const customOptionsIds = Object.keys(state.customOptions);

      const pathToOptions = customOptionsIds.includes(optionId)
        ? 'customOptions'
        : 'options';
      const isShownNow = R.pathOr(
        false,
        [pathToOptions, optionId, 'isShown'],
        state,
      );
      return R.assocPath(
        [pathToOptions, optionId, 'isShown'],
        !isShownNow,
        state,
      );
    }

    case SET_CURRENT_CONFIGURATION: {
      const { configuration } = payload;

      const indexOfFetchedLayout: string | undefined = Object.keys(
        state.filtersLayouts,
      ).find(layout => layout === state.currentLayout.id);

      const fetchedLayout = indexOfFetchedLayout
        ? state.filtersLayouts[indexOfFetchedLayout]
        : undefined;

      const currentLayout = {
        id: state.currentLayout.id,
        configuration,
        type: fetchedLayout && fetchedLayout.userId === '-1' ? 'base' : 'user',
        unsaved: fetchedLayout && fetchedLayout.configuration !== configuration,
      };
      return R.assocPath(['currentLayout'], currentLayout, state);
    }
    case `${FETCH_FILTERS_LAYOUTS}_SUCCESS`: {
      const layouts = getGraphqlPayload(action);
      const layoutsObj = layouts.reduce((acc, layout) => {
        acc[layout.id] = layout;
        return acc;
      }, {});
      return R.assocPath(['filtersLayouts'], layoutsObj, state);
    }
    case SET_CURRENT_FILTERS_LAYOUT: {
      const { id, userId, configuration } = payload;
      const type = userId === '-1' ? 'base' : 'user';
      const currentFiltersLayout = {
        id: id,
        type,
        configuration,
      };
      return R.assocPath(['currentLayout'], currentFiltersLayout, state);
    }
    case CREATE_FILTERS_LAYOUT: {
      const layout = action.payload.graphql.variables.payload;

      return R.assocPath(
        ['filtersLayouts'],
        { ...state.filtersLayouts, [layout.id]: layout },
        state,
      );
    }
    case `${UPDATE_FILTERS_LAYOUT}_SUCCESS`: {
      const layout = getGraphqlPayload(action);
      if (
        R.pathOr('', ['currentLayout', 'configuration'], state) ===
        R.pathOr('', ['configuration'], layout)
      ) {
        return R.compose(
          R.assocPath(['filtersLayouts', layout.id], layout),
          R.assocPath(['currentLayout'], { ...layout, unsaved: false }),
        )(state);
      }
      return R.assocPath(['filtersLayouts', layout.id], layout, state);
    }
    case `${CREATE_FILTERS_LAYOUT}_SUCCESS`: {
      const newLayout = getGraphqlPayload(action);
      const {
        payload: { id: oldId },
      } = getGraphqlPrevActionVariables(action);
      return R.compose(
        R.dissocPath(['filtersLayouts', oldId]),
        R.assocPath(['filtersLayouts', newLayout.id], newLayout),
        R.assocPath(['currentLayout', 'id'], newLayout.id),
        R.assocPath(['currentLayout', 'unsaved'], false),
      )(state);
    }
    case UPDATE_WELL_CUSTOM_ATTRIBUTE: {
      const { id, name } = action.payload.graphql.variables.payload;
      return R.compose(
        R.assocPath(['customOptions', 'custom' + id, 'displayName'], name),
        R.assocPath(
          ['customOptions', 'custom' + id, 'filterName'],
          'custom ' + name,
        ),
      )(state);
    }
    case CREATE_WELL_CUSTOM_ATTRIBUTE: {
      const { id, name } = action.payload.graphql.variables.payload;

      const newCustomAttribute = {
        id: 'custom' + id,
        displayName: name,
        filterName: `custom ${name}`,
        order: 0,
        isShown: true,
        isSelected: true,
      };
      const orderedCustomOptions = [newCustomAttribute]
        .concat(R.values(state.customOptions))
        .map((o, idx) => ({ ...o, order: idx }));
      const options = orderedCustomOptions.reduce((acc, o) => {
        acc[o.id] = o;
        return acc;
      }, {});
      const selectedOptions = state.selectedOptions.concat([
        newCustomAttribute.id,
      ]);
      const shouldAppendDelimiter =
        state.currentLayout.configuration.length > 0;
      const newLayoutConfiguration =
        state.currentLayout.configuration +
        (shouldAppendDelimiter ? '-' : '') +
        newCustomAttribute.id;

      return R.compose(
        R.assocPath(['customOptions'], options),
        R.assocPath(['selectedOptions'], selectedOptions),
        R.assocPath(['currentLayout'], {
          id: '',
          configuration: newLayoutConfiguration,
          type: state.currentLayout.type,
        }),
      )(state);
    }
    case `${CREATE_WELL_CUSTOM_ATTRIBUTE}_SUCCESS`: {
      const newAttribute = getGraphqlPayload(action);
      const oldId = 'custom' + getGraphqlPrevActionVariables(action).payload.id;
      const attributeToPut = {
        ...state.customOptions[oldId],
        id: 'custom' + newAttribute.id,
      };
      const newLayoutConfiguration = state.currentLayout.configuration.replace(
        oldId,
        attributeToPut.id,
      );
      const newSelectedOptions = state.selectedOptions.map(id =>
        id === oldId ? attributeToPut.id : id,
      );

      return R.compose(
        R.dissocPath(['customOptions', oldId]),
        R.assocPath(['customOptions', attributeToPut.id], attributeToPut),
        R.assocPath(['selectedOptions'], newSelectedOptions),
        R.assocPath(['currentLayout', 'configuration'], newLayoutConfiguration),
      )(state);
    }
    case `${REMOVE_WELL_CUSTOM_ATTRIBUTE}`: {
      const id = 'custom' + action.payload.graphql.variables.id;
      const newLayoutConfiguration = state.currentLayout.configuration
        .split('-')
        .filter(eId => eId !== id)
        .join('-');
      const newSelectedOptions = state.selectedOptions.filter(o => o !== id);
      const newLayouts = R.values(state.filtersLayouts)
        .map(fl => {
          const newConfiguration = fl.configuration
            .split('-')
            .filter(o => o !== id)
            .join('-');
          return { ...fl, configuration: newConfiguration };
        })
        .filter(fl => fl.configuration !== '');
      const layoutToSet = newLayouts.find(
        fl => fl.configuration === newLayoutConfiguration,
      ) ||
        newLayouts[0] || { id: '', type: 'user', configuration: '' };
      const newLayoutsList = newLayouts.reduce((acc, l) => {
        acc[l.id] = l;
        return acc;
      }, {});

      const newCustomValues = state.customValues.filter(
        v =>
          v.wellCustomAttributeId.toString() !==
          action.payload.graphql.variables.id.toString(),
      );

      return R.compose(
        R.dissocPath(['customOptions', id]),
        R.assocPath(['selectedOptions'], newSelectedOptions),
        R.assocPath(['filtersLayouts'], newLayoutsList),
        R.assocPath(['currentLayout'], layoutToSet),
        R.assocPath(['customValues'], newCustomValues),
      )(state);
    }
    case `${FETCH_WELL_CUSTOM_ATTRIBUTES_VALUES}_SUCCESS`: {
      const values = getGraphqlPayload(action);
      return R.assoc('customValues', values, state);
    }
    case REMOVE_WELL_CUSTOM_ATTRIBUTE_VALUES: {
      const helpfulData = action.payload.graphql.variables.payload.map(
        d => `${d.wellId}${d.wellCustomAttributeId}`,
      );
      const values = state.customValues.filter(
        v => !helpfulData.includes(`${v.wellId}${v.wellCustomAttributeId}`),
      );
      return R.assoc('customValues', values, state);
    }
    case CREATE_WELL_CUSTOM_ATTRIBUTE_VALUES: {
      const data = action.payload.graphql.variables.payload;
      const values = state.customValues.concat(data);
      return R.assoc('customValues', values, state);
    }
    case UPDATE_WELL_CUSTOM_ATTRIBUTE_VALUES: {
      const data = action.payload.graphql.variables.payload;
      const helpfulData = data.map(
        d => `${d.wellId}${d.wellCustomAttributeId}`,
      );
      const valuesWithoutUpdated = state.customValues.filter(
        v => !helpfulData.includes(`${v.wellId}${v.wellCustomAttributeId}`),
      );
      const valuesToInsert = valuesWithoutUpdated.concat(data);
      return R.assoc('customValues', valuesToInsert, state);
    }
    case RENAME_FILTERS_LAYOUT: {
      const { id, name } = action.payload.graphql.variables.payload;
      return R.assocPath(['filtersLayouts', id, 'name'], name, state);
    }
    case REMOVE_FILTERS_LAYOUT: {
      const { id } = action.payload.graphql.variables;
      if (id === state.currentLayout.id) {
        return R.compose(
          R.dissocPath(['filtersLayouts', id]),
          R.assocPath(['currentLayout', 'id'], ''),
        )(state);
      }
      return R.dissocPath(['filtersLayouts', id], state);
    }
    case `${REMOVE_FILTERS_LAYOUT}_SUCCESS`: {
      const userLayouts = getGraphqlPayload(action);
      const stateClone = R.clone(state);
      userLayouts.forEach(
        layout => (stateClone.filtersLayouts[layout.id] = layout),
      );
      return stateClone;
    }
    case SET_FILTERS_LAYOUTS: {
      return R.assocPath(['filtersLayouts'], payload, state);
    }
    default:
      return state;
  }
};

export const getState = (state: any): FilterLayoutsState => state[STATE_KEY];

export const getFiltersOptions: Selector<FilterLayoutOptions> = createSelector(
  getState,
  (state): FilterLayoutOptions => state.options,
);

export const getCustomOptions: Selector<FilterLayoutOptions> = createSelector(
  getState,
  (state): FilterLayoutOptions => state.customOptions,
);

export const getCustomFilterLayoutsOptionsIdsAndNames: Selector<{
  ids: string[];
  names: string[];
}> = createSelector(
  getCustomOptions,
  (
    customOptions: FilterLayoutOptions,
  ): {
    ids: string[];
    names: string[];
  } => ({
    ids: Object.keys(customOptions),
    names: Object.keys(customOptions).map(key => customOptions[key].filterName),
  }),
);

export const getCustomOptionsList: Selector<LayoutOption[]> = createSelector(
  getState,
  (state): LayoutOption[] => R.values(state.customOptions),
);

export const getDraggableColumn: Selector<string> = createSelector(
  getState,
  (state): string => state.draggableColumn,
);

export const getDropIndicatorIndex: Selector<number | null> = createSelector(
  getState,
  (state): number | null => state.dropIndicatorIndex,
);

export const getFiltersLayoutsOptionsArray: Selector<LayoutOption[]> =
  createSelector(getState, (state): LayoutOption[] =>
    R.values(state.options).sort((a, b) => (a.order > b.order ? 1 : -1)),
  );

export const getCustomSelectedFiltersLayoutsOptions: Selector<FilterLayoutOptions> =
  createSelector(getState, (state: FilterLayoutsState): FilterLayoutOptions => {
    return R.values(state.customOptions)
      .filter(
        o =>
          Object.keys(state.customOptions).includes(o.id) &&
          o.id.includes('custom'),
      )
      .reduce((acc, o) => {
        acc[o.id] = o;
        return acc;
      }, {});
  });

export const getCustomAvailableFiltersLayoutsOptionsArray: Selector<
  LayoutOption[]
> = createSelector(getState, (state): LayoutOption[] =>
  R.values(state.customOptions)
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .filter(f => !f.isSelected),
);

export const getAvailableFiltersLayoutsOptionsArray: Selector<LayoutOption[]> =
  createSelector(getState, (state): LayoutOption[] =>
    R.values(state.options)
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .filter(f => !f.isSelected),
  );

export const getSelectedFiltersLayoutsOptionsArray: Selector<LayoutOption[]> =
  createSelector(getState, (state): LayoutOption[] =>
    state.selectedOptions.reduce<LayoutOption[]>((acc, id) => {
      const option = Object.keys(state.customOptions).includes(id)
        ? state.customOptions[id]
        : state.options[id];

      if (option) acc.push(option);

      return acc;
    }, []),
  );

export const getCustomSelectedFiltersLayoutsOptionsArray: Selector<
  LayoutOption[]
> = createSelector(
  getSelectedFiltersLayoutsOptionsArray,
  getCustomFilterLayoutsOptionsIdsAndNames,
  (
    options: LayoutOption[],
    customOptionsIdsAndNames: { ids: string[]; names: string[] },
  ): LayoutOption[] =>
    options.filter(o => customOptionsIdsAndNames.ids.includes(o.id)),
);

export const getLayoutOption: Selector<string[]> = createSelector(
  getState,
  (state): string[] => [...state.selectedOptions],
);

export const getSelectedFiltersLayoutsOptionsToDisplay: Selector<
  LayoutOption[]
> = createSelector(
  getLayoutOption,
  getFiltersOptions,
  getCustomOptions,
  getCustomFilterLayoutsOptionsIdsAndNames,
  (
    selectedOptions: string[],
    options: FilterLayoutOptions,
    customOptions: FilterLayoutOptions,
    customOptionsIdsAndNames: { ids: string[]; names: string[] },
  ): LayoutOption[] =>
    selectedOptions
      .reduce<LayoutOption[]>((acc, id) => {
        if (customOptionsIdsAndNames.ids.includes(id)) {
          if (customOptions[id]) acc.push(customOptions[id]);
        } else {
          if (options[id]) acc.push(options[id]);
        }
        return acc;
      }, [])
      .filter(f => f.isShown),
);

export const getFiltersLayouts: Selector<ListFiltersLayouts> = createSelector(
  getState,
  (state): ListFiltersLayouts => state.filtersLayouts,
);

export const getCurrentLayout: Selector<
  currentFilterLayout | Record<string, never>
> = createSelector(
  getState,
  (state): currentFilterLayout | Record<string, never> => ({
    ...state.currentLayout,
  }),
);

export const getUserLayouts: Selector<FiltersLayouts[]> = createSelector(
  getState,
  (state): FiltersLayouts[] =>
    R.values(state.filtersLayouts)
      .filter(layout => parseInt(layout.userId) !== -1)
      .sort((a, b) => (a.order > b.order ? 1 : -1)),
);

export const getBaseLayouts: Selector<FiltersLayouts[]> = createSelector(
  getState,
  (state): FiltersLayouts[] =>
    R.values(state.filtersLayouts)
      .filter(layout => parseInt(layout.userId) === -1)
      .sort((a, b) => (a.order > b.order ? 1 : -1)),
);

export const getSelectedOptions: Selector<string[]> = createSelector(
  getState,
  (state: FilterLayoutsState): string[] => [...state.selectedOptions],
);

export const getCurrentFiltersLayoutData: Selector<FiltersLayouts> =
  createSelector(getState, (state): FiltersLayouts => {
    if (!R.pathOr({}, ['currentLayout', 'id'], state)) {
      return state.filtersLayouts[0];
    }
    const { id } = state.currentLayout;
    return R.pathOr({}, ['filtersLayouts', id], state);
  });

export const getWellCustomAttributesValues: Selector<CustomAttributeValue[]> =
  createSelector(getState, (state): CustomAttributeValue[] => [
    ...state.customValues,
  ]);

export default filterActions(FiltersLayoutsReducer as any, action =>
  action.type.match(filterRegExp),
) as Reducer<FilterLayoutsState>;
