import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import SeriesPill from 'modules/chart/components/SeriesPill';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import {
  createVarianceDataMapForSelectedBar,
  createVarianceYScale,
  getCoreSeriesDetailedTitle,
  getCoreSeriesTitle,
} from 'modules/chart/utils';

import RegionOfInterest from 'modules/chart/components/RegionOfInterest';
import YAxis from 'modules/chart/components/YAxis';
import type {
  IdDialog,
  IdIndexDialog,
  TooltipData,
  TrellisTooltipData,
} from 'modules/ui/models/ui';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import SVGVarianceInteraction from './SVGVarianceInteraction';
import SVGVarianceTrellis from './SVGVarianceTrellis';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';
import { useCapacityData, useVarianceData } from '../context/capacityVariance';
import { SERIES_PILL_HEIGHT, SERIES_PILL_OFFSET } from '../../series/models';
import { convertToLocalISOString } from '../../../helpers';
import { useSelector } from 'store/models';
import { getYAxisHovered } from 'modules/ui/UIReducer';

interface DivVarianceChartProps {
  seriesId: string;
  allocIssueDialog: IdIndexDialog;
  allWellAllocIssues: AllocIssue[];
  allocIssuesVisibility: boolean;
  capacity: CapacityChangeEvent[];
  capacityDialog: { show: boolean; index: number };
  changeMinRoiDate: (minDate: Date) => void;
  changeMaxRoiDate: (minDate: Date) => void;
  localRoiDates: { maxDate: Date; minDate: Date };
  endRoiDragging: () => void;
  chartWasDragging: boolean;
  displaysRegionOfInterest: boolean;
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
    compareOption: string;
  };
  eventColors: { [key: string]: string };
  finishDrag: () => void;
  format: string;
  hasCapacityChanges: boolean;
  height: number;
  highlightedAllocIssue: IdDialog;
  highlightedAllocIssueDivider: IdDialog;
  isDragging: boolean;
  isPossibleEditAlloc: boolean;
  isPossibleEditCapacity: boolean;
  isPossibleEditVariance: boolean;
  isXAxisDragging: boolean;
  isYAxisDragging: boolean;
  leftOffset: number;
  startDrag: () => void;
  onAllocIssueDialogOpen: ({ index: number, id: string }) => void;
  onAllocIssueDividerHover: (eventId: string) => void;
  onAllocIssueUpdate: (allocData: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  onCapacityDialogClose: () => void;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onCapacityDividerHover: (eventId: number) => void;
  onDayInitChange: ({ capacityEventId: string, newDayInit: Date }) => void;
  onEventDividerHover: (eventId: number) => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  onHighlightAllocIssueDividerOff: () => void;
  onHighlightCapacityDividerOff: () => void;
  onHighlightEventDividerOff: () => void;
  onLocalAllocIssueUpdate: (allocData: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  onStartYAxisDragging: () => void;
  onStopDragging: () => void;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  onVarianceEventUpdate: (varianceData: {
    dates: Date[];
    varianceEventId: string;
  }) => void;
  position: number;
  regionOfInterest: boolean;
  showBarHoverEffect: boolean;
  today: Date;
  tooltipData: TooltipData | null;
  varianceDialog: {
    show: boolean;
    index: number;
  };
  varianceEvents: VarianceEvent[];
  width: number;
  xScale: any;
  isLast: boolean;
}

const DivVarianceChart = ({
  seriesId,
  allWellAllocIssues,
  allocIssueDialog,
  allocIssuesVisibility,
  capacity: capacityEvents,
  capacityDialog,
  changeMinRoiDate,
  changeMaxRoiDate,
  localRoiDates,
  endRoiDragging,
  chartWasDragging,
  displaysRegionOfInterest,
  drilldownTableParams,
  eventColors,
  finishDrag,
  format,
  hasCapacityChanges,
  height,
  highlightedAllocIssue,
  highlightedAllocIssueDivider,
  isDragging,
  isPossibleEditCapacity,
  isPossibleEditVariance,
  isPossibleEditAlloc,
  isXAxisDragging,
  isYAxisDragging,
  leftOffset,
  // pillText,
  startDrag,
  tooltipData,
  onAllocIssueDialogOpen,
  onAllocIssueDividerHover,
  onAllocIssueUpdate,
  onDayInitChange,
  onCapacityDividerHover,
  onCapacityDialogClose,
  onCapacityDialogOpen,
  onEventDividerHover,
  onXAxisScaling,
  onHighlightAllocIssueDividerOff,
  onHighlightCapacityDividerOff,
  onHighlightEventDividerOff,
  onLocalAllocIssueUpdate,
  onSetTooltipData,
  onStartYAxisDragging,
  onStopDragging,
  onVarianceDialogOpen,
  onVarianceEventUpdate,
  position,
  regionOfInterest,
  showBarHoverEffect,
  today,
  varianceDialog,
  varianceEvents,
  width,
  xScale,
  isLast,
}: DivVarianceChartProps) => {
  const productionKey = drilldownTableParams.phase.toLowerCase();
  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const yAxisHovered = useSelector(getYAxisHovered);

  const capacity = useCapacityData();
  const variance = useVarianceData();
  const varianceData = React.useMemo(
    () => variance.getAll(),
    [variance.getAll],
  );
  const varianceAreasWithCapacity = React.useMemo(
    () => capacity.getVarianceAreas({ removeLastDays: true }),
    [capacity.getVarianceAreas],
  );
  const varianceAreas = React.useMemo(
    () => variance.getVarianceFromVarianceAreas(varianceAreasWithCapacity),
    [variance.getVarianceFromVarianceAreas],
  );

  const firstSeriesPillElem = React.useRef<HTMLElement | null>(null);
  const [yScaleOffset, setYScaleOffset] = React.useState(0);
  const [displayMinDataPoint, setDisplayMinDataPoint] = React.useState(0);
  const [displayMaxDataPoint, setDisplayMaxDataPoint] = React.useState(0);
  const [isAdjusted, setIsAdjusted] = React.useState(false);

  const extremePoints = React.useMemo(() => {
    if (R.isEmpty(varianceData)) {
      const min = { date: undefined, variance: -1 };
      const max = { date: undefined, variance: 1 };
      return { min, max };
    }

    const varianceSource = varianceData
      .concat(varianceAreas.flat())
      .sort((a, b) => a.variance - b.variance);

    const min = { ...varianceSource[0] };
    const max = { ...varianceSource[varianceSource.length - 1] };
    min.variance = Math.min(-1, min.variance);
    max.variance = Math.max(1, max.variance);

    return { min, max };
  }, [varianceData, varianceAreas]);

  const yScale = React.useMemo(() => {
    return createVarianceYScale(
      height,
      displayMinDataPoint,
      displayMaxDataPoint,
      yScaleOffset,
    );
  }, [height, displayMinDataPoint, displayMaxDataPoint, yScaleOffset]);

  const resetDisplayPoints = React.useCallback(() => {
    const { min, max } = extremePoints;
    const valuePerPx = (max.variance + Math.abs(min.variance)) / height;
    const padding = 10 * valuePerPx;

    setDisplayMinDataPoint(min.variance - padding);
    setDisplayMaxDataPoint(max.variance + padding);
  }, [extremePoints.min.variance, extremePoints.max.variance]);

  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    resetDisplayPoints();
  }, [resetDisplayPoints]);

  React.useEffect(() => {
    if (!isAdjusted) resetDisplayPoints();
  }, [isAdjusted, resetDisplayPoints]);

  React.useEffect(() => {
    const pillLeftOffset = firstSeriesPillElem?.current?.offsetLeft;
    const maxDate = extremePoints.max.date;

    if (!isAdjusted && maxDate && maxDate >= xScale.invert(pillLeftOffset)) {
      setYScaleOffset(SERIES_PILL_HEIGHT + SERIES_PILL_OFFSET - 10);
    } else {
      setYScaleOffset(0);
    }
  }, [isAdjusted, extremePoints]);

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );

  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );

  const dateMap = React.useMemo(
    () =>
      createVarianceDataMapForSelectedBar(
        varianceData,
        varianceEvents,
        allWellAllocIssues,
      ),
    [varianceData, varianceEvents, allWellAllocIssues],
  );

  const tooltipDate =
    R.pathOr(false, ['trellisTooltipData', 'day'], tooltipData) ||
    R.pathOr(false, ['dataSeriesTooltipData', 'day'], tooltipData) ||
    null;

  const varianceTooltipData = tooltipDate
    ? R.path([convertToLocalISOString(new Date(tooltipDate))], dateMap)
    : null;

  return (
    <>
      <DivVarianceChart.Container height={height} isLast={isLast}>
        <DivVarianceChart.SVGWrapper className="trellis-chart-wrapper">
          <SVGVarianceTrellis
            allocIssues={allWellAllocIssues}
            allocIssueDialog={allocIssueDialog}
            allocIssuesVisibility={allocIssuesVisibility}
            eventColors={eventColors}
            height={height}
            highlightedAllocIssue={highlightedAllocIssue}
            highlightedAllocIssueDivider={highlightedAllocIssueDivider}
            isPossibleEditAlloc={isPossibleEditAlloc}
            isAxisDragging={isXAxisDragging || isYAxisDragging}
            onSetTooltipData={onSetTooltipData}
            showBarHoverEffect={showBarHoverEffect}
            tooltipData={tooltipData}
            trellisTitle={seriesId}
            varianceEvents={varianceEvents}
            xScale={xScale}
            yAxisLinePos={yAxisLinePos}
            yScale={yScale}
            format={format}
          />
          {!(isXAxisDragging && chartWasDragging) &&
            !isYAxisDragging &&
            !!displayMaxDataPoint && (
              <SVGVarianceInteraction
                allocIssueDialog={allocIssueDialog}
                allWellAllocIssues={allWellAllocIssues}
                allocIssuesVisibility={allocIssuesVisibility}
                capacity={capacityEvents}
                capacityDialog={capacityDialog}
                finishDrag={finishDrag}
                height={height}
                leftOffset={leftOffset}
                isAxisDragging={isXAxisDragging}
                isDragging={isDragging}
                isPossibleEditAlloc={isPossibleEditAlloc}
                isPossibleEditCapacity={isPossibleEditCapacity}
                isPossibleEditVariance={isPossibleEditVariance}
                onAllocIssueDialogOpen={onAllocIssueDialogOpen}
                onAllocIssueDividerHover={onAllocIssueDividerHover}
                onAllocIssueUpdate={onAllocIssueUpdate}
                onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
                onCapacityDialogOpen={onCapacityDialogOpen}
                onCapacityDividerHover={onCapacityDividerHover}
                onDayInitChange={onDayInitChange}
                onEventDividerHover={onEventDividerHover}
                onXAxisScaling={onXAxisScaling}
                onHighlightAllocIssueDividerOff={
                  onHighlightAllocIssueDividerOff
                }
                onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
                onHighlightEventDividerOff={onHighlightEventDividerOff}
                onVarianceDialogOpen={onVarianceDialogOpen}
                onVarianceEventUpdate={onVarianceEventUpdate}
                startDrag={startDrag}
                today={today}
                trellisTitle={productionKey}
                varianceData={varianceData}
                varianceDialog={varianceDialog}
                varianceEvents={varianceEvents}
                width={width}
                xScale={xScale}
                yScale={yScale}
              />
            )}
          {displaysRegionOfInterest && regionOfInterest && localRoiDates && (
            <RegionOfInterest
              changeMinRoiDate={changeMinRoiDate}
              changeMaxRoiDate={changeMaxRoiDate}
              endRoiDragging={endRoiDragging}
              hasCapacityChanges={hasCapacityChanges}
              leftOffset={leftOffset}
              maxDate={localRoiDates.maxDate}
              minDate={localRoiDates.minDate}
              xScale={xScale}
              startDrag={startDrag}
              finishDrag={finishDrag}
              height={height}
              width={width}
              position={height * position}
              onCapacityDialogClose={onCapacityDialogClose}
            />
          )}
        </DivVarianceChart.SVGWrapper>
        <DivVarianceChart.YAxisContainer>
          <YAxis
            format={format}
            height={height}
            hideLine={hideLine}
            maxDataPoint={displayMaxDataPoint}
            minDataPoint={displayMinDataPoint}
            onStartYAxisDragging={onStartYAxisDragging}
            onStopDragging={onStopDragging}
            setDisplayMaxDataPoint={setDisplayMaxDataPoint}
            setDisplayMinDataPoint={setDisplayMinDataPoint}
            isDragging={isYAxisDragging}
            isXAxisDragging={isXAxisDragging}
            resetMax={resetMax}
            isAdjusted={isAdjusted}
            setIsAdjusted={setIsAdjusted}
            showLine={showLine}
            varianceTrellis
            yScale={yScale}
          />
        </DivVarianceChart.YAxisContainer>
        <SeriesPill
          ref={firstSeriesPillElem}
          text={getCoreSeriesTitle(drilldownTableParams.phase, true)}
          tooltipTitle={getCoreSeriesDetailedTitle(
            drilldownTableParams.phase,
            drilldownTableParams.grossNet,
            true,
          )}
          tooltipDescription="Core Series"
        />
        {!yAxisHovered &&
          tooltipData &&
          !isXAxisDragging &&
          (tooltipData.trellisTooltipData ||
            tooltipData.ribbonTooltipData ||
            tooltipData.dataSeriesTooltipData) &&
          tooltipData.trellisTooltipData?.trellis !== seriesId && (
            <SecondaryInformationTooltip
              containerHeight={height}
              secondaryCavTooltipData={{}}
              leftOffset={leftOffset}
              tooltipData={tooltipData}
              trellisTitle={seriesId}
              varianceTooltipData={varianceTooltipData}
              yScale={yScale}
              today={today}
            />
          )}
      </DivVarianceChart.Container>
    </>
  );
};

DivVarianceChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

DivVarianceChart.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

DivVarianceChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

export default React.memo<DivVarianceChartProps>(DivVarianceChart);
