import { timeDay, utcMillisecond } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import { isInside, getDataForTooltip } from 'modules/chart/utils';
import { ForecastData } from 'modules/externalForecast/models';
import type { ProductionPoint } from 'modules/production/models/production';
import type { TooltipData, TrellisTooltipData } from 'modules/ui/models/ui';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import {
  createDataMapForSelectedBar,
  createVarianceDataMapForSelectedBar,
} from '../utils';
import convertToLocalISOString from '../../../helpers/convertToLocalISOString';

interface SelectedBarProps {
  allocIssues: AllocIssue[];
  capacityData?: Array<{ date: Date; capacity: number }>;
  forecastData?: ForecastData;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  production?: ProductionPoint[];
  productionKey?: string;
  svgBoundingRect: Record<string, any>;
  tooltipData: TooltipData | null;
  varianceData?: { date: Date; variance: number }[];
  trellisTitle: string;
  varianceEvents: VarianceEvent[];
  xScale: any;
  yScale: any;
  isDragging?: boolean;
}

const SelectedBar = ({
  allocIssues,
  capacityData,
  forecastData,
  onSetTooltipData,
  production,
  productionKey,
  svgBoundingRect,
  tooltipData,
  trellisTitle,
  varianceEvents,
  varianceData,
  xScale,
  yScale,
  isDragging,
}: SelectedBarProps) => {
  const trellisTooltipData = R.pathOr(
    null,
    ['trellisTooltipData'],
    tooltipData,
  );
  const dataSeriesTooltipData = R.pathOr(
    null,
    ['dataSeriesTooltipData'],
    tooltipData,
  );

  const chartAreaCoords = React.useMemo(
    () => ({
      x1: svgBoundingRect.x,
      x2: svgBoundingRect.x + svgBoundingRect.width,
      y1: 55,
      y2: window.innerHeight - 35,
    }),
    [svgBoundingRect],
  );

  const trellisAreaCoords = React.useMemo(
    () => ({
      x1: svgBoundingRect.x,
      x2: svgBoundingRect.x + svgBoundingRect.width,
      y1: svgBoundingRect.y,
      y2: svgBoundingRect.y + svgBoundingRect.height,
    }),
    [svgBoundingRect],
  );

  const dataMap = React.useMemo(() => {
    if (varianceData) {
      //create dataMap for variance trellis
      return createVarianceDataMapForSelectedBar(
        varianceData,
        varianceEvents,
        allocIssues,
      );
    } else if (capacityData) {
      //create dataMap for usual trellis
      return createDataMapForSelectedBar(
        //@ts-expect-error
        production,
        productionKey,
        capacityData.slice(0, capacityData.length),
        varianceEvents,
        allocIssues,
        forecastData,
      );
    }
    return {};
  }, [
    allocIssues,
    forecastData,
    production,
    productionKey,
    capacityData,
    varianceEvents,
    varianceData,
  ]);

  const tooltipDate = React.useMemo(() => {
    return (
      R.pathOr(false, ['day'], trellisTooltipData) ||
      R.pathOr(false, ['day'], dataSeriesTooltipData) ||
      null
    );
  }, [trellisTooltipData, dataSeriesTooltipData]);
  const barData = React.useMemo(() => {
    return tooltipDate
      ? dataMap[convertToLocalISOString(timeDay.floor(tooltipDate))]
      : null;
  }, [tooltipDate, dataMap]);

  const selectedBarHeight = React.useMemo(() => {
    if (barData) {
      const range = yScale.range();
      const domain = yScale.domain();
      const coef =
        (range[0] - range[1]) / (Math.abs(domain[0]) + Math.abs(domain[1]));

      return (
        Math.max(
          barData.production,
          barData.capacity,
          Math.abs(barData.variance),
        ) * coef
      );
    }
    return 0;
  }, [yScale, barData]);
  const selectedBarX = React.useMemo(
    () => xScale(timeDay.floor(tooltipDate)),
    [xScale, tooltipDate],
  );
  const selectedBarY = React.useMemo(
    () =>
      barData
        ? yScale(
            Math.max(barData.production, barData.capacity, barData.variance),
          )
        : yScale(0),
    [yScale, barData],
  );

  const barWidth = React.useMemo(() => {
    const delta =
      utcMillisecond.count(xScale.domain()[0], xScale.domain()[1]) /
      (24 * 3600 * 1000);

    return xScale.range()[1] / delta;
  }, [xScale]);

  const mouseMoveListener = React.useCallback(
    (e: MouseEvent) => {
      if (isDragging) return;
      const { clientX, clientY } = e;
      const currentPointerPosition = {
        clientX,
        clientY,
      };
      const currentPointerIsInsideTrellis = isInside(
        currentPointerPosition,
        trellisAreaCoords,
      );
      if (!currentPointerIsInsideTrellis) return;

      const value = yScale.invert(
        currentPointerPosition.clientY - trellisAreaCoords.y1,
      );

      const pointerDateForSeries = xScale.invert(
        currentPointerPosition.clientX - chartAreaCoords.x1,
      );

      const pointerDate = timeDay.floor(pointerDateForSeries);

      const pointerData = {
        ...dataMap[convertToLocalISOString(pointerDate)],
        clientX,
        clientY,
        pointerDateForSeries,
        trellis: trellisTitle,
      };
      const dataForTooltip = getDataForTooltip(
        value,
        pointerData,
        varianceData,
      );
      onSetTooltipData(dataForTooltip);
    },
    [
      chartAreaCoords,
      dataMap,
      onSetTooltipData,
      trellisAreaCoords,
      trellisTitle,
      varianceData,
      xScale,
      yScale,
      tooltipData,
      isDragging,
    ],
  );
  const mouseLeaveListener = React.useCallback(
    () => onSetTooltipData(null),
    [onSetTooltipData],
  );

  React.useEffect(() => {
    const trellisesWrappers: NodeListOf<Element> =
      document.querySelectorAll('.trellises-wrapper');
    const trellisesWrappersArray = Array.from(trellisesWrappers);

    trellisesWrappersArray.forEach(elem => {
      elem.addEventListener('mousemove', mouseMoveListener as EventListener);
      elem.addEventListener('mouseleave', mouseLeaveListener);
    });
    return () =>
      trellisesWrappersArray.forEach(elem => {
        elem.removeEventListener(
          'mousemove',
          mouseMoveListener as EventListener,
        );
        elem.removeEventListener('mouseleave', mouseLeaveListener);
      });
  }, [mouseMoveListener, mouseLeaveListener]);

  return (
    <>
      {tooltipDate && (
        <>
          <rect
            width={barWidth + 6}
            height={selectedBarHeight + 6}
            x={selectedBarX - 3}
            y={selectedBarY - 3}
            strokeWidth="1"
            stroke="black"
            fill="transparent"
            pointerEvents="none"
          />
        </>
      )}
    </>
  );
};

export default SelectedBar;
