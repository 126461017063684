import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector, OutputParametricSelector, Selector } from 'reselect';

import type { Action, RootState } from 'store/models';
import { getGraphqlPayload } from 'store/helpers';
import { getMinDate, getMaxDate, isIdNew } from 'helpers';

import type { VarianceEvent } from './models/varianceEvent';
import { normalizeVarianceEvents } from './utils';
import {
  CREATE_REMOTE_VARIANCE_EVENT,
  DELETE_VARIANCE_EVENT_LOCALLY,
  namespace,
  POPULATE_VARIANCE_EVENTS,
  POPULATE_VARIANCE_EVENTS_AFTER_CREATING,
  SYNC_VARIANCE_EVENT_LOCALLY,
  UPDATE_REMOTE_VARIANCE_EVENT,
  UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY,
  UPDATE_VARIANCE_EVENT_DATES_LOCALLY,
  UPDATE_VARIANCE_EVENT_DESCRIPTION_LOCALLY,
  UPDATE_VARIANCE_EVENT_NOTES_LOCALLY,
  UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY,
} from './VarianceEventActions';
import { getCurrentWellId } from '../ui/UIReducer';
import { Reducer } from 'redux';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'varianceEvent';

type VarianceEventState = {
  [wellId: string]: {
    [id: string]: VarianceEvent;
  };
};

const initialState = {};

const VarianceEventReducer = (
  state: VarianceEventState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case POPULATE_VARIANCE_EVENTS: {
      const { wellId, events } = action.payload;

      return R.assoc<{ [id: string]: VarianceEvent }, VarianceEventState>(
        wellId,
        events,
        state,
      );
    }
    case UPDATE_VARIANCE_EVENT_DESCRIPTION_LOCALLY: {
      const { wellId, id, description } = action.payload;

      return R.assocPath<string, VarianceEventState, string>(
        [wellId, id, 'description'],
        description,
        state,
      );
    }
    case UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY: {
      const { wellId, varianceEventId, varianceOptionId } = action.payload;

      return R.assocPath<string, VarianceEventState, string>(
        [wellId, varianceEventId, 'varianceOptionId'],
        varianceOptionId,
        state,
      );
    }
    case UPDATE_VARIANCE_EVENT_DATES_LOCALLY: {
      const { wellId, varianceEventId, dates } = action.payload;
      const dayStart = getMinDate(...(dates as [Date, Date]));
      const dayEnd = getMaxDate(...(dates as [Date, Date]));

      if (!state[wellId][varianceEventId]) return state;

      return R.compose(
        R.assocPath<string, VarianceEventState, Date>(
          [wellId, varianceEventId, 'dayStart'],
          dayStart,
        ),
        R.assocPath([wellId, varianceEventId, 'dayEnd'], dayEnd),
      )(state);
    }
    case UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY: {
      const { wellId, varianceEventId, extraInputsData } = action.payload;

      return R.assocPath<string, VarianceEventState, Record<string, any>>(
        [wellId, varianceEventId, 'extraInputsData'],
        extraInputsData,
        state,
      );
    }
    case UPDATE_VARIANCE_EVENT_NOTES_LOCALLY: {
      const { wellId, varianceEventId, notes } = action.payload;

      return R.assocPath<string, VarianceEventState, Record<string, any>>(
        [wellId, varianceEventId, 'notes'],
        notes,
        state,
      );
    }
    case POPULATE_VARIANCE_EVENTS_AFTER_CREATING: {
      const { wellId, newVarianceEvents } = action.payload;

      return R.assoc<{ [id: string]: VarianceEvent }, VarianceEventState>(
        wellId,
        newVarianceEvents,
        state,
      );
    }
    case DELETE_VARIANCE_EVENT_LOCALLY: {
      const { wellId, varianceEventId } = action.payload;

      return R.dissocPath<string, Record<string, any>>(
        [wellId, varianceEventId],
        state,
      );
    }
    case CREATE_REMOTE_VARIANCE_EVENT: {
      const { wellId, dayStart, dayEnd } =
        action.payload.graphql.variables.payload;
      const wellVarEvents = state[wellId] || {};

      const newWellVarEvents = Object.keys(wellVarEvents).reduce(
        (acc, capId) => {
          if (
            isIdNew(capId) &&
            wellVarEvents[capId].dayStart.getTime() === dayStart.getTime() &&
            wellVarEvents[capId].dayEnd.getTime() === dayEnd.getTime()
          ) {
            acc[capId] = { ...wellVarEvents[capId], syncing: true };
          } else {
            acc[capId] = wellVarEvents[capId];
          }

          return acc;
        },
        {},
      );

      return R.assoc(wellId, newWellVarEvents, state);
    }

    case `${UPDATE_REMOTE_VARIANCE_EVENT}_SUCCESS`: {
      const { varianceEvent, code } = getGraphqlPayload(action);

      if (code !== 409) return state;

      const normalizedEvent = normalizeVarianceEvents([varianceEvent])[
        varianceEvent.id
      ];
      const wellVarEvents = state[varianceEvent.wellId] || {};

      const newWellVarEvents = Object.keys(wellVarEvents).reduce(
        (acc, capId) => {
          if (capId === normalizedEvent.id) {
            acc[normalizedEvent.id] = normalizedEvent;
          } else {
            acc[capId] = wellVarEvents[capId];
          }

          return acc;
        },
        {},
      );

      return R.assoc<{ [id: string]: VarianceEvent }, VarianceEventState>(
        varianceEvent.wellId,
        newWellVarEvents,
        state,
      );
    }

    case SYNC_VARIANCE_EVENT_LOCALLY: {
      const { varianceEvent: newVarianceEvent, code } = action.payload;

      const normalizedEvents = normalizeVarianceEvents([newVarianceEvent]);
      const normalizedEvent = normalizedEvents[+newVarianceEvent.id];

      const wellVarEvents = state[newVarianceEvent.wellId] || {};

      const newWellVarEvents = Object.keys(wellVarEvents).reduce(
        (acc, capId) => {
          if (isIdNew(capId) && code === 409) return acc;

          if (isIdNew(capId)) {
            acc[normalizedEvent.id] = {
              ...wellVarEvents[capId],
              id: normalizedEvent.id,
            };
          } else {
            acc[capId] = wellVarEvents[capId];
          }

          return acc;
        },
        {},
      );

      return R.assoc<{ [id: string]: VarianceEvent }, VarianceEventState>(
        newVarianceEvent.wellId,
        newWellVarEvents,
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getVarianceEvents: Selector<
  RootState,
  VarianceEventState
> = state => state[STATE_KEY] || {};

export const getWellVarianceEventsIndexedById = createSelector(
  getVarianceEvents,
  (_: RootState, { wellId }) => wellId,
  (
    variance,
    wellId,
  ):
    | {
        [id: string]: VarianceEvent;
      }
    | Record<string, never> => {
    if (!R.isNil(wellId)) return variance[wellId] || {};
    return {};
  },
);

export const getWellVarianceEventsSortedByDate = createSelector(
  getWellVarianceEventsIndexedById,
  (wellVariance): VarianceEvent[] =>
    R.values(wellVariance)
      .sort((a, b) => a.dayStart - b.dayStart)
      .reverse(),
);

export const getVarianceEvent: OutputParametricSelector<
  RootState,
  { wellId: string; varianceEventId: string },
  VarianceEvent | Record<string, never>,
  any
> = createSelector(
  getWellVarianceEventsIndexedById,
  (_, { varianceEventId }: { wellId: string; varianceEventId: string }) =>
    varianceEventId,
  (wellVarianceEventsById, varianceEventId): VarianceEvent =>
    wellVarianceEventsById[varianceEventId] || {},
);

export const getWellVarianceEventsByIds: OutputParametricSelector<
  RootState,
  { wellId: string; varianceEventIds: string[] },
  VarianceEvent[] | Record<string, never>[],
  any
> = createSelector(
  getWellVarianceEventsIndexedById,
  (_, { varianceEventIds }: { wellId: string; varianceEventIds: string[] }) =>
    varianceEventIds,
  (wellVarianceEventsById, varianceEventIds): VarianceEvent[] =>
    varianceEventIds.map(
      varianceEventId => wellVarianceEventsById[varianceEventId] || null,
    ),
);

export const getHasVarianceDataForCurrentWell: Selector<RootState, boolean> =
  createSelector(
    getCurrentWellId,
    getVarianceEvents,
    (wellId, varianceEvents) => !!varianceEvents[wellId],
  );

export default filterActions(VarianceEventReducer, action =>
  action.type.match(filterRegExp),
) as Reducer<VarianceEventState>;
