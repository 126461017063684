import { AUTHENTICATED } from 'modules/app/AppActions';
import { fetchAppConfig } from 'modules/appConfig/AppConfigActions';
import { fetchAllFeatureFlags } from 'modules/featureFlags/FeatureFlagsActions';
import {
  fetchAllLatestEventNotes,
  fetchLatestUnreadNotes,
} from 'modules/inboxConversation/InboxConversationActions';
import { fetchPhases } from 'modules/phase/PhaseActions';
import { fetchAllUsers } from 'modules/user/UserActions';
import { fetchWellColumnMapping, fetchWells } from 'modules/well/WellActions';
import { fetchAllocIssueStatus } from 'modules/allocIssueStatus/AllocIssueStatusActions';
import {
  fetchRibbonOptions,
  fetchRibbons,
  initFetchRibbonEvents,
} from 'modules/ribbon/RibbonActions';
import {
  fetchCapacityCategories,
  fetchVarianceCategories,
} from 'modules/category/CategoryActions';
import { getColumnMapping } from 'modules/well/WellReducer';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  loadAppConfigAdditional,
  loadAppConfigMeta,
  loadLeftPanelContents,
  loadWellFull,
  loadWellPartial,
  LOAD_APP_CONFIG_ADDITIONAL,
  LOAD_APP_CONFIG_META,
  LOAD_LEFT_PANEL_CONTENTS,
  LOAD_WELL_FULL,
  LOAD_WELL_PARTIAL,
} from './appLoaderActions';
import { fetchAllocIssueCause } from 'modules/allocIssueCause/AllocIssueCauseActions';
import {
  fetchCalculatedSeriesMapping,
  fetchSensorSeriesMapping,
  fetchSeriesMapping,
  initFetchWellSeries,
} from 'modules/series/SeriesActions';
import {
  fetchBaseSeriesLayouts,
  fetchUserSeriesLayouts,
} from 'modules/seriesLayouts/SeriesLayoutsActions';
import {
  fetchFiltersLayouts,
  fetchWellCustomAttributes,
  fetchWellCustomAttributesValues,
} from 'modules/filterLayouts/FilterLayoutsActions';
import { getPageConfiguration } from 'modules/inboxConversation/InboxConversationReducer';
import { getId } from 'modules/auth/AuthReducer';
import { initFetchProduction } from 'modules/production/ProductionActions';
import { initFetchExternalForecast } from 'modules/externalForecast/ExternalForecastActions';
import { initFetchVarianceEvents } from 'modules/varianceEvent/VarianceEventActions';
import { initFetchCapacityEvents } from 'modules/capacityChangeEvent/CapacityChangeEventActions';
import {
  ENABLE_GROUP_MODE,
  SET_CURRENT_GROUP,
  SET_CURRENT_WELL_ID,
  SET_WELL_REASON,
} from 'modules/ui/UIActions';
import { getCurrentWellId, getGroupMode } from 'modules/ui/UIReducer';
import { fetchNotes } from 'modules/notes/NotesActions';
import { fetchEventNotes } from 'modules/eventNotes/EventNotesActions';

function* loadAppConfigInitialSaga(): Generator<any, any, any> {
  const actions = yield all([
    put(fetchAppConfig()),
    put(fetchAllFeatureFlags()),
    put(fetchAllUsers()),
    put(fetchWellColumnMapping()),
    put(fetchSeriesMapping()),
    put(fetchCalculatedSeriesMapping()),
  ]);

  yield call(async () => await Promise.all(actions));

  yield put(loadLeftPanelContents());
}

function* loadLeftPanelContentsSaga(): Generator<any, any, any> {
  const columnMapping = yield select(getColumnMapping);
  const columnsIndices = columnMapping.map(column => column.columnIndex);

  yield all([put(fetchWells(columnsIndices)), put(fetchLatestUnreadNotes())]);

  yield put(loadAppConfigMeta());
}

function* loadAppConfigMetaSaga(): Generator<any, any, any> {
  yield all([
    put(fetchPhases()),
    put(fetchVarianceCategories()),
    put(fetchCapacityCategories()),
    put(fetchRibbonOptions()),
    put(fetchRibbons()),
    put(fetchAllocIssueCause()),
    put(fetchAllocIssueStatus()),
  ]);

  const wellId = yield select(getCurrentWellId);
  const groupMode = yield select(getGroupMode);

  if (groupMode.isOn) return yield put(loadAppConfigAdditional());

  if (!wellId) {
    const action = yield take([
      SET_CURRENT_WELL_ID,
      ENABLE_GROUP_MODE,
      SET_CURRENT_GROUP,
    ]);

    if (
      action.type !== SET_CURRENT_WELL_ID ||
      (action.payload.reason as SET_WELL_REASON) !== 'URL_CHANGE'
    ) {
      return yield put(loadAppConfigAdditional());
    }
  }

  yield put(loadWellPartial());
}

function* loadWellPartialSaga(): Generator<any, any, any> {
  const actions = yield all([
    put(initFetchWellSeries()),
    put(initFetchProduction()),
    put(initFetchExternalForecast()),
    put(initFetchVarianceEvents()),
    put(initFetchCapacityEvents()),
    put(initFetchRibbonEvents()),
  ]);

  yield put(loadAppConfigAdditional());

  yield call(async () => await Promise.all(actions));

  yield put(loadWellFull());
}

function* loadWellFullSaga(): Generator<any, any, any> {
  const wellId = yield select(getCurrentWellId);

  yield all([put(fetchNotes(wellId)), put(fetchEventNotes(wellId))]);
}

function* loadAppConfigAdditionalSaga(): Generator<any, any, any> {
  const pageConfiguration = yield select(getPageConfiguration);
  const userId = yield select(getId);

  yield all([
    put(fetchBaseSeriesLayouts()),
    put(fetchUserSeriesLayouts(userId)),
    put(fetchFiltersLayouts(userId)),
    put(fetchWellCustomAttributes()),
    put(fetchWellCustomAttributesValues()),
    put(fetchAllLatestEventNotes(pageConfiguration)),
    put(fetchSensorSeriesMapping()),
  ]);
}

function* appLoaderSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(AUTHENTICATED, loadAppConfigInitialSaga),
    takeLatest(LOAD_LEFT_PANEL_CONTENTS, loadLeftPanelContentsSaga),
    takeLatest(LOAD_APP_CONFIG_META, loadAppConfigMetaSaga),
    takeLatest(LOAD_WELL_PARTIAL, loadWellPartialSaga),
    takeLatest(LOAD_WELL_FULL, loadWellFullSaga),
    takeLatest(LOAD_APP_CONFIG_ADDITIONAL, loadAppConfigAdditionalSaga),
  ]);
}

export default appLoaderSagas;
