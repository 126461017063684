import { utcMinute } from 'd3-time';
import { CalculatedSeries, CalculatedSeriesMapping } from '../models';

export const normalizeCalculatedSeries = (
  data: CalculatedSeries[],
  wellId: string,
  calculatedMapping: CalculatedSeriesMapping,
  seriesId: string,
): any => {
  return data.reduce((acc, d) => {
    const mapping = Object.values(calculatedMapping).find(m => {
      return m.tags.some(
        t =>
          t.wellId === d.well_id &&
          t.wellId === wellId &&
          m.calculatedSeriesId === seriesId,
      );
    });

    if (!mapping) return acc;

    acc.push({
      day: utcMinute.round(new Date(d.timestamp_utc)),
      [mapping.calculatedSeriesId]: d.value,
    });

    return acc;
  }, [] as any);
};
