import { Field, Form, Formik } from 'formik';
import * as R from 'ramda';
import React from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import AlertWindow from 'components/AlertWindow';
import { getId } from 'modules/auth/AuthReducer';
import { isInside } from 'modules/chart/utils';
import LayoutDialogWindow from 'modules/seriesLayouts/components/LayoutDialogWindow';
import LayaoutDragOption from 'modules/seriesLayouts/components/LayaoutDragOption';

import { FiltersLayouts } from '../models';
import {
  createFiltersLayout,
  removeFiltersLayout,
  renameFiltersLayout,
  reorderFiltersLayouts,
  reorderFiltersLayoutsLocally,
  updateFiltersLayout,
} from '../FilterLayoutsActions';
import useFiltersLayouts from '../hooks/useFiltersLayouts';
import LayoutDropdownButton from '../../../components/LayoutDropdownButton';
import { getFiltersLayouts } from '../FilterLayoutsReducer';
import { selectFilterSavedLayoutsAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';
import { compareLayoutString } from 'modules/seriesLayouts/utils';

const getOverwriteLayoutText = (name: string) =>
  `Are you sure you want to overwrite the "${name}" layout?`;

const FiltersLayoutsSelect = () => {
  const dispatch = useDispatch();
  const userId: string = useSelector(getId);
  const filterLayout = useSelector(getFiltersLayouts);

  const dropDownListRef = React.useRef<HTMLDivElement>(null);
  const dropDownHeaderRef = React.useRef<HTMLDivElement>(null);
  const [isOpenSaveDialogWindow, setIsOpenSaveDialogWindow] =
    React.useState(false);
  const [isOpenDropDownList, setIsOpenDropDownList] = React.useState(false);
  const [hoveredOption, setHoveredOption] = React.useState<string>('');
  const [idLayoutToRename, setIdLayoutToRename] = React.useState('');
  const [idLayoutToRemove, setIdLayoutToRemove] = React.useState('');

  const {
    baseFilterLayouts,
    userFilterLayouts,
    filtersLayoutData,
    currentFilterLayout,
    setCurrentFilterLayout,
  } = useFiltersLayouts();

  const layoutToRename = React.useMemo(
    () => userFilterLayouts.find(l => l.id === idLayoutToRename),
    [userFilterLayouts, idLayoutToRename],
  );

  const toggleDropDownList = React.useCallback(
    () => setIsOpenDropDownList(!isOpenDropDownList),
    [isOpenDropDownList, setIsOpenDropDownList],
  );

  const openSaveDialogWindow = React.useCallback(
    () => setIsOpenSaveDialogWindow(true),
    [setIsOpenSaveDialogWindow],
  );
  const closeSaveDialogWindow = React.useCallback(
    () => setIsOpenSaveDialogWindow(false),
    [setIsOpenSaveDialogWindow],
  );

  const setLayout = React.useCallback(
    (setFieldValue, layout) => {
      setFieldValue('layaout', layout);
      setCurrentFilterLayout(layout);
      setIsOpenDropDownList(false);
    },
    [setIsOpenDropDownList],
  );

  const onSubmitSaveLayout = React.useCallback(
    (name: string) => {
      const newLayout: FiltersLayouts = {
        id: uuid(),
        userId,
        name,
        configuration: currentFilterLayout.configuration,
        order: userFilterLayouts.length + 1,
      };
      dispatch(createFiltersLayout(newLayout));
      closeSaveDialogWindow();
    },
    [
      currentFilterLayout.configuration,
      userFilterLayouts.length,
      dispatch,
      closeSaveDialogWindow,
      userId,
    ],
  );

  const onSubmitRenameLayout = React.useCallback(
    (id: string, name: string) => {
      dispatch(renameFiltersLayout({ id, name }));
      setIdLayoutToRename('');
    },
    [dispatch],
  );

  const onSubmitDeleteLayout = React.useCallback(
    id => {
      dispatch(removeFiltersLayout({ id }));
    },
    [dispatch],
  );

  const changeLayoutOrder = React.useCallback(
    (dragId, hoverId) => {
      dispatch(reorderFiltersLayoutsLocally({ dragId, hoverId }));
    },
    [dispatch],
  );

  const reorderUsersLayoutRemotly = React.useCallback(
    () => dispatch(reorderFiltersLayouts({ data: userFilterLayouts })),
    [dispatch, userFilterLayouts],
  );

  const getContainerAreaCoords = React.useCallback(
    elementBoundingRect => ({
      x1: elementBoundingRect.x,
      x2: elementBoundingRect.x + elementBoundingRect.width,
      y1: elementBoundingRect.y,
      y2: elementBoundingRect.y + elementBoundingRect.height,
    }),
    [],
  );

  const onMouseClickBlurHandler = React.useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const currentPointerPosition = {
        clientX,
        clientY,
      };
      if (
        dropDownListRef.current &&
        dropDownHeaderRef.current &&
        !isOpenSaveDialogWindow &&
        isOpenDropDownList &&
        !idLayoutToRename &&
        !idLayoutToRemove
      ) {
        const dropDownListCoords = getContainerAreaCoords(
          dropDownListRef.current.getBoundingClientRect(),
        );

        const dropDownHeaderCoords = getContainerAreaCoords(
          dropDownHeaderRef.current.getBoundingClientRect(),
        );
        const currentPointerIsInsideList = isInside(
          currentPointerPosition,
          dropDownListCoords,
        );

        const currentPointerIsInsideHeader = isInside(
          currentPointerPosition,
          dropDownHeaderCoords,
        );
        if (!currentPointerIsInsideList && !currentPointerIsInsideHeader) {
          toggleDropDownList();
        }
      }
    },
    [
      setIsOpenDropDownList,
      isOpenSaveDialogWindow,
      isOpenDropDownList,
      idLayoutToRename,
      getContainerAreaCoords,
      idLayoutToRemove,
    ],
  );

  const [existedLayout, setExistedLayout] = React.useState<any>(false);
  const [isOpenReplaceDialog, setIsOpenReplaceDialog] = React.useState(false);
  const [isOpenOverwriteDialog, setIsOpenOverwriteDialog] =
    React.useState(false);

  const onCreateLayout = React.useCallback(
    (name: string) => {
      const alreadyExistedLayout = userFilterLayouts.find(
        layout => layout.name === name.trim(),
      );
      if (alreadyExistedLayout) {
        setIsOpenReplaceDialog(true);
        setExistedLayout(alreadyExistedLayout);
      } else {
        onSubmitSaveLayout(name);
      }
    },
    [onSubmitSaveLayout, userFilterLayouts],
  );

  const onRenameLayout = React.useCallback(
    (id: string, name: string) => {
      const alreadyExistedLayout = userFilterLayouts.find(
        layout => layout.name === name,
      );
      if (alreadyExistedLayout) {
        setIsOpenReplaceDialog(true);
        setExistedLayout(alreadyExistedLayout);
      } else {
        onSubmitRenameLayout(id, name);
      }
    },
    [onSubmitRenameLayout, userFilterLayouts],
  );
  const onRewriteLayout = React.useCallback(() => {
    const matchKey = Object.keys(filterLayout).find(
      layoutId => layoutId === currentFilterLayout.id,
    );

    const currentLayout = matchKey ? filterLayout[matchKey] : null;

    if (currentLayout) {
      dispatch(
        updateFiltersLayout(
          R.assoc(
            'configuration',
            currentFilterLayout.configuration,
            currentLayout,
          ),
        ),
      );
    }
  }, [currentFilterLayout, filterLayout]);

  const onReplaceLayout = React.useCallback(() => {
    const layoutWithConfiguration = idLayoutToRename.length
      ? layoutToRename
      : currentFilterLayout;
    if (layoutWithConfiguration) {
      dispatch(
        updateFiltersLayout(
          R.assoc(
            'configuration',
            layoutWithConfiguration.configuration,
            existedLayout,
          ),
        ),
      );
    }

    if (idLayoutToRename.length && layoutWithConfiguration) {
      setIdLayoutToRename('');
      dispatch(removeFiltersLayout({ id: layoutWithConfiguration.id }));
    }

    setIsOpenSaveDialogWindow(false);
  }, [
    setIsOpenReplaceDialog,
    idLayoutToRename,
    layoutToRename,
    existedLayout,
    currentFilterLayout,
  ]);

  const onCloseReplaceDialog = React.useCallback(() => {
    setIsOpenReplaceDialog(false);
  }, [setIsOpenReplaceDialog]);

  React.useEffect(() => {
    const dashboardWrapper = document.querySelector('#filterPageRoot');

    dashboardWrapper?.addEventListener(
      'mousedown',
      onMouseClickBlurHandler as EventListener,
    );
    return () =>
      dashboardWrapper?.removeEventListener(
        'mousedown',
        onMouseClickBlurHandler as EventListener,
      );
  }, [onMouseClickBlurHandler]);

  return (
    <>
      <FiltersLayoutsSelect.Wrapper>
        {/* @ts-expect-error */}
        <Formik
          enableReinitialize
          initialValues={{
            layaout: filtersLayoutData || baseFilterLayouts[0],
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Field name="layaout" id="layaout">
                {({ field }) => (
                  <FiltersLayoutsSelect.DropDownContainer>
                    <FiltersLayoutsSelect.DropDownHeaderContainer>
                      <ButtonTooltip
                        content={selectFilterSavedLayoutsAttributes}
                      >
                        <FiltersLayoutsSelect.DropDownHeader
                          ref={dropDownHeaderRef}
                          isUnsaved={
                            currentFilterLayout.unsaved &&
                            currentFilterLayout.type !== 'base'
                          }
                          onClick={toggleDropDownList}
                        >
                          <FiltersLayoutsSelect.DropDownHeaderTitle>
                            {!currentFilterLayout.id ||
                            (currentFilterLayout.type === 'base' &&
                              !compareLayoutString(
                                currentFilterLayout.configuration,
                                filtersLayoutData.configuration,
                              ))
                              ? 'Unsaved Layout'
                              : filtersLayoutData.name || field.value.name}
                          </FiltersLayoutsSelect.DropDownHeaderTitle>

                          {currentFilterLayout.unsaved &&
                            currentFilterLayout.type !== 'base' && (
                              <FiltersLayoutsSelect.UnsavedIndicator>
                                Unsaved
                              </FiltersLayoutsSelect.UnsavedIndicator>
                            )}
                          <FiltersLayoutsSelect.DropDownArrowIcon />
                        </FiltersLayoutsSelect.DropDownHeader>
                      </ButtonTooltip>

                      <LayoutDropdownButton
                        hasSaveOption={
                          !!(
                            currentFilterLayout.id &&
                            currentFilterLayout.unsaved &&
                            currentFilterLayout.type !== 'base' &&
                            filtersLayoutData.name
                          )
                        }
                        onSaveLayout={() => {
                          setIsOpenOverwriteDialog(true);
                        }}
                        onSaveLayoutAsNewLayout={openSaveDialogWindow}
                      />
                    </FiltersLayoutsSelect.DropDownHeaderContainer>
                    {isOpenDropDownList && (
                      <FiltersLayoutsSelect.DropDownListContainer
                        ref={dropDownListRef}
                      >
                        <FiltersLayoutsSelect.DropDownList>
                          {baseFilterLayouts.length ? (
                            <>
                              <FiltersLayoutsSelect.ListGroupLabel>
                                Shared Layouts:
                              </FiltersLayoutsSelect.ListGroupLabel>
                              {baseFilterLayouts.map(layout => (
                                <FiltersLayoutsSelect.ListItem
                                  onClick={() => {
                                    setLayout(setFieldValue, layout);
                                  }}
                                  key={Math.random() + layout.id}
                                >
                                  <div />
                                  <span>{layout.name}</span>
                                </FiltersLayoutsSelect.ListItem>
                              ))}
                            </>
                          ) : null}
                          {userFilterLayouts.length ? (
                            <>
                              <FiltersLayoutsSelect.ListGroupLabel>
                                Your Layouts:
                              </FiltersLayoutsSelect.ListGroupLabel>
                              {userFilterLayouts.map((layout, index) => (
                                <LayaoutDragOption
                                  reorderUsersLayoutRemotly={
                                    reorderUsersLayoutRemotly
                                  }
                                  key={layout.id + layout.configuration}
                                  setLayout={setLayout}
                                  setFieldValue={setFieldValue}
                                  layout={layout}
                                  setHoveredOption={setHoveredOption}
                                  hoveredOption={hoveredOption}
                                  changeLayoutOrder={changeLayoutOrder}
                                  index={index}
                                  setIdLayoutToRename={setIdLayoutToRename}
                                  setIdLayoutToRemove={setIdLayoutToRemove}
                                />
                              ))}
                            </>
                          ) : null}
                          {!baseFilterLayouts.length &&
                          !userFilterLayouts.length ? (
                            <FiltersLayoutsSelect.NoLayoutsMessage>
                              No saved layouts
                            </FiltersLayoutsSelect.NoLayoutsMessage>
                          ) : null}
                        </FiltersLayoutsSelect.DropDownList>
                      </FiltersLayoutsSelect.DropDownListContainer>
                    )}
                  </FiltersLayoutsSelect.DropDownContainer>
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </FiltersLayoutsSelect.Wrapper>
      {idLayoutToRename && (
        <LayoutDialogWindow
          action="edit"
          onClose={() => setIdLayoutToRename('')}
          onSubmit={onRenameLayout}
          oldName={
            userFilterLayouts?.find(i => i.id === idLayoutToRename)?.name
          }
          layoutId={idLayoutToRename}
        />
      )}
      {isOpenSaveDialogWindow && (
        <LayoutDialogWindow
          action="save"
          onClose={closeSaveDialogWindow}
          onSubmit={onCreateLayout}
        />
      )}
      {idLayoutToRemove && (
        <AlertWindow
          handleClose={() => setIdLayoutToRemove('')}
          onDelete={() => onSubmitDeleteLayout(idLayoutToRemove)}
          layoutName={
            userFilterLayouts?.find(i => i.id === idLayoutToRemove)?.name
          }
        />
      )}
      {isOpenReplaceDialog && (
        <AlertWindow
          actionButton={'Overwrite'}
          handleClose={onCloseReplaceDialog}
          onDelete={onReplaceLayout}
          text={getOverwriteLayoutText(R.propOr('', 'name', existedLayout))}
        />
      )}
      {isOpenOverwriteDialog && (
        <AlertWindow
          actionButton={'Overwrite'}
          handleClose={() => {
            setIsOpenOverwriteDialog(false);
          }}
          onDelete={onRewriteLayout}
          text={getOverwriteLayoutText(R.propOr('', 'name', filtersLayoutData))}
        />
      )}
    </>
  );
};

FiltersLayoutsSelect.Wrapper = styled.div``;

FiltersLayoutsSelect.DropDownContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

FiltersLayoutsSelect.DropDownHeaderContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: auto 48px;
`;

FiltersLayoutsSelect.DropDownHeader = styled.div`
  display: grid;
  grid-template-columns: ${({ isUnsaved }) =>
    isUnsaved ? 'auto min-content 25px' : 'auto 25px'};

  padding-top: auto;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  border: 1px solid #c1c1c1;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  font-family: 'Lato', sans-serif;
  color: #484848;

  :hover {
    cursor: pointer;
  }
`;

FiltersLayoutsSelect.UnsavedIndicator = styled.div`
  color: #909090;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

FiltersLayoutsSelect.DropDownHeaderTitle = styled.span`
  padding-left: 5px;
  padding-top: 7px;
`;

FiltersLayoutsSelect.DropDownListContainer = styled.div`
  position: absolute;
  z-index: 54;
  width: 294px;
`;

FiltersLayoutsSelect.DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  font-weight: 500;
  padding-bottom: 5px;
`;

FiltersLayoutsSelect.ListGroupLabel = styled.div`
  font-weight: bold;
  padding: 5px;
`;

FiltersLayoutsSelect.ListItem = styled.li`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  grid-template-columns: 8px auto;
  align-items: center;
  gap: 5px;
  list-style: none;
  padding: 3px 0 4px 9.5px;

  :hover {
    background: #efefef;
    cursor: pointer;
  }
`;

FiltersLayoutsSelect.DragIconWrapper = styled.div`
  align-self: center;
  cursor: ns-resize;
`;

FiltersLayoutsSelect.DropDownArrowIcon = styled(RiArrowDropDownLine)`
  font-size: 30px;
`;

FiltersLayoutsSelect.NoLayoutsMessage = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  color: #484848;
`;

export default FiltersLayoutsSelect;
