import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

import paths from 'modules/router/utils/paths';
import { getFormattedLocalAppSettings } from 'modules/appConfig/AppConfigReducer';
import { changeMyPassword, logout } from 'modules/auth/AuthActions';
import {
  getFullName,
  getIsAuthenticated,
  getPermissions,
} from 'modules/auth/AuthReducer';

import DevSubMenu from 'components/DevSubMenu';
import ChangePasswordModal from 'modules/user/components/ChangePassWindow';
import AccountModal from 'modules/user/components/AccountWindow';
import useMouseDownOutside from 'hooks/useMouseDownOutside';
import { AppSettingsModal } from 'modules/appSettings/components/AppSettingsModal';
import useFeatureFlags from 'modules/featureFlags/hooks/useFeatureFlags';

export const Menu = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(getIsAuthenticated);
  const { isAdmin, isUserManager, isAllowedViewDashboards } =
    useSelector(getPermissions);
  const currentUserName = useSelector(getFullName);
  const appSettings = useSelector(getFormattedLocalAppSettings);
  const featureFlags = useFeatureFlags();
  const flagsState = featureFlags.flagsState;

  const featureFlagsItemElem = React.useRef<HTMLLIElement>(null);
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  const [isOpenDevFeatures, setIsOpenDevFeatures] = React.useState(false);
  const [isOpenAppSettings, setIsOpenAppSettings] = React.useState(false);
  const [isAccountOpen, setAccountOpen] = React.useState(false);
  const [isChangePasswordOpen, setChangePasswordOpen] = React.useState(false);
  const [featureFlagsItemRect, setFeatureFlagsItemRect] =
    React.useState<DOMRect>();

  const onLogout = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const openDevMenu = React.useCallback(() => {
    const rect = featureFlagsItemElem.current?.getBoundingClientRect();
    setFeatureFlagsItemRect(rect);
    setIsOpenDevFeatures(true);
  }, [featureFlagsItemElem.current]);

  const closeDevMenu = React.useCallback(() => {
    setIsOpenDevFeatures(false);
  }, []);

  const openAppSettings = React.useCallback(() => {
    setIsOpenAppSettings(true);
  }, []);

  const closeAppSettings = React.useCallback(() => {
    setIsOpenAppSettings(false);
  }, []);

  const changePasswordRequest = React.useCallback(
    values => dispatch(changeMyPassword(values)),
    [dispatch],
  );

  useMouseDownOutside([menuRef], closeDevMenu);

  return isAuthenticated ? (
    <Menu.Container ref={menuRef}>
      {currentUserName && <Menu.Username>{currentUserName}</Menu.Username>}

      {flagsState.allocationIssues && (
        <>
          <Menu.Section>
            <Menu.Item>
              <Link to={paths.BACKLOG()}>Allocation Issue Backlog</Link>
            </Menu.Item>
          </Menu.Section>

          <Menu.Divider />
        </>
      )}

      <Menu.Section>
        <Menu.Item onClick={() => setAccountOpen(true)}>My Account</Menu.Item>

        <Menu.Item onClick={() => setChangePasswordOpen(true)}>
          Change Password
        </Menu.Item>

        <Menu.Item onClick={openAppSettings}>App Settings</Menu.Item>

        <Menu.Item onClick={onLogout}>Log Out</Menu.Item>
      </Menu.Section>

      {(isAdmin || isUserManager || flagsState.spotfireDashboards) && (
        <>
          <Menu.Divider />

          <Menu.Section>
            {(isAdmin || isUserManager) && (
              <Menu.Item>
                <Link to={paths.USER_MANAGEMENT()}>User Management</Link>
              </Menu.Item>
            )}

            {flagsState.spotfireDashboards && isAllowedViewDashboards && (
              <Menu.Item>
                <Link to={paths.SPOTFIRE_DASHBOARDS()}>Dashboards</Link>
              </Menu.Item>
            )}

            {isAdmin && (
              <Menu.Item onClick={openDevMenu} ref={featureFlagsItemElem}>
                Feature Flags
              </Menu.Item>
            )}
          </Menu.Section>
        </>
      )}

      {isOpenDevFeatures && featureFlagsItemRect && (
        <DevSubMenu
          featureFlags={featureFlags.toggleableFlags}
          getFeatureFlagValue={featureFlags.getFlagValue}
          toggleFeatureFlag={featureFlags.toggleFlag}
          anchor={featureFlagsItemRect}
          onClose={closeDevMenu}
        />
      )}

      <ChangePasswordModal
        open={isChangePasswordOpen}
        handleClose={() => setChangePasswordOpen(false)}
        changePasswordRequest={changePasswordRequest}
      />

      <AccountModal
        open={isAccountOpen}
        handleClose={() => setAccountOpen(false)}
      />

      <AppSettingsModal
        isOpen={isOpenAppSettings}
        close={closeAppSettings}
        appSettings={appSettings}
      />
    </Menu.Container>
  ) : null;
};

Menu.Container = styled.div``;

Menu.Username = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin: 21px 0 21px 16px;
`;

Menu.Section = styled.div``;

Menu.Item = styled(MenuItem)`
  font-size: 14px;
  padding: 0 16px;
  height: 32px;
  > * {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  a {
    display: flex;
    align-items: center;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

Menu.Divider = styled.div`
  height: 1px;
  background-color: #e7e7e7;
  margin: 8px 16px;
`;
