import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';

export const getValidCellValue = (value: any) => {
  if (value === 0) {
    return 0;
  }
  if (!value) {
    return 'No data';
  }
  return value;
};

export const excelCellStyle = {
  t: 's',
  s: { alignment: { vertical: 'center', horizontal: 'right' } },
};

export const excelCellsAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const excelHeaderStyle = {
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  font: {
    bold: true,
  },
};

export const calculateVariance = ({
  compareOption,
  production,
  capacity,
  forecast,
}: {
  compareOption: string;
  production?: any;
  capacity?: any;
  forecast?: any;
}) => {
  const checkedProduction = getValidCellValue(production);
  const checkedCapacity = getValidCellValue(capacity);
  const checkedForecast = getValidCellValue(forecast);

  if (compareOption === COMPARE_OPTION.actVsExt) {
    if (checkedProduction === 'No data' || checkedForecast === 'No data')
      return 'No data';
    return checkedProduction - checkedForecast;
  }

  if (compareOption === COMPARE_OPTION.extVsCap) {
    if (checkedCapacity === 'No data' || checkedForecast === 'No data')
      return 'No data';
    return checkedCapacity - checkedForecast;
  }

  if (checkedProduction === 'No data' || checkedCapacity === 'No data')
    return 'No data';
  return checkedProduction - checkedCapacity;
};
