import * as React from 'react';
import { useSelector } from 'react-redux';

import useVisibleSeries from '../hooks/useVisibleSeries';
import { NormalizedSeries } from '../models';
import { getFetchingStatuses } from '../SeriesReducer';

interface SensorSeriesStatusTextProps {
  groupOptions: string[];
  series: { [key: string]: NormalizedSeries[] };
}

const SensorSeriesStatusText = ({
  groupOptions,
  series,
}: SensorSeriesStatusTextProps) => {
  const sensorFetchingStatuses = useSelector(getFetchingStatuses);
  const visibleSeries = useVisibleSeries({ groupOptions, series });

  const sensorSeriesStatus = React.useMemo(() => {
    if (groupOptions.every(id => sensorFetchingStatuses[id] === 'loading')) {
      return 'Loading...';
    }
    if (groupOptions.every(id => sensorFetchingStatuses[id] === 'fail')) {
      return 'Failed to load';
    }
    if (groupOptions.every(id => sensorFetchingStatuses[id] === 'success')) {
      return 'No Sensor Data within zoom range';
    }
    return null;
  }, [groupOptions, sensorFetchingStatuses]);

  if (!visibleSeries.isEmpty) return null;
  if (!sensorSeriesStatus) return null;

  return <>{sensorSeriesStatus}</>;
};

export default SensorSeriesStatusText;
