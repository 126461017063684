import * as React from 'react';
import styled from 'styled-components';
import { SortDirection } from 'react-virtualized';

import { SortIndicator } from 'components/Icons';

interface WellHeaderRowProps {
  className: string;
  firstColDataKey: string;
  firstColTitle: string;
  groups: { [groupKey: string]: Record<string, any> };
  onClick: (data: { sortBy: string; sortDirection: string }) => void;
  secondColDataKey: string;
  secondColTitle: string;
  thirdColDataKey: string;
  thirdColTitle: string;
  fourthColDataKey: string;
  fourthColTitle: string;
  isCoreSeries: boolean;
  sortBy: string;
  sortDirection: string;
  style: { [prop: string]: string };
  varianceSortingParams: { abs: boolean; direction: string };
  markAllRows: () => void;
  displayClearButton: boolean;
  maxColumnWidth: {
    secondColWidth: number;
    thirdColWidth: number;
    fourthColWidth: number;
  };
}

const WellCustomHeaderRow = ({
  className,
  firstColDataKey,
  firstColTitle,
  secondColDataKey,
  secondColTitle,
  thirdColDataKey,
  thirdColTitle,
  fourthColDataKey,
  fourthColTitle,
  isCoreSeries,
  onClick,
  sortBy,
  sortDirection,
  style,
  markAllRows,
  maxColumnWidth,
  displayClearButton,
}: WellHeaderRowProps) => (
  <div className={className} role="row" style={style}>
    <WellCustomHeaderRow.FirstCol
      onClick={() =>
        onClick({
          sortBy: firstColDataKey,
          sortDirection:
            sortDirection === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC,
        })
      }
    >
      <WellCustomHeaderRow.FirstColName>
        {firstColTitle}
        {sortBy === firstColDataKey && (
          <SortIndicator sortDirection={sortDirection} />
        )}
      </WellCustomHeaderRow.FirstColName>

      {displayClearButton && (
        <WellCustomHeaderRow.ClearButton
          onClick={e => {
            e.stopPropagation();
            markAllRows();
          }}
        >
          Clear Selection
        </WellCustomHeaderRow.ClearButton>
      )}
    </WellCustomHeaderRow.FirstCol>

    <WellCustomHeaderRow.Col
      withIcon={sortBy === secondColDataKey}
      onClick={() =>
        onClick({
          sortBy: secondColDataKey,
          sortDirection:
            sortDirection === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC,
        })
      }
      width={maxColumnWidth.secondColWidth}
    >
      <WellCustomHeaderRow.ColTextContainer
        width={maxColumnWidth.secondColWidth}
      >
        {secondColTitle}
        {sortBy === secondColDataKey && (
          <WellCustomHeaderRow.SortIndicatorWrapper>
            <SortIndicator sortDirection={sortDirection} />
          </WellCustomHeaderRow.SortIndicatorWrapper>
        )}
      </WellCustomHeaderRow.ColTextContainer>
    </WellCustomHeaderRow.Col>
    {isCoreSeries && (
      <>
        <WellCustomHeaderRow.Col
          withIcon={sortBy === thirdColDataKey}
          onClick={() =>
            onClick({
              sortBy: thirdColDataKey,
              sortDirection:
                sortDirection === SortDirection.ASC
                  ? SortDirection.DESC
                  : SortDirection.ASC,
            })
          }
          width={maxColumnWidth.thirdColWidth}
        >
          <WellCustomHeaderRow.ColTextContainer>
            {thirdColTitle}
            {sortBy === thirdColDataKey && (
              <WellCustomHeaderRow.SortIndicatorWrapper>
                <SortIndicator sortDirection={sortDirection} />
              </WellCustomHeaderRow.SortIndicatorWrapper>
            )}
          </WellCustomHeaderRow.ColTextContainer>
        </WellCustomHeaderRow.Col>
        <WellCustomHeaderRow.Col
          withIcon={sortBy === fourthColDataKey}
          onClick={() =>
            onClick({
              sortBy: fourthColDataKey,
              sortDirection:
                sortDirection === SortDirection.ASC
                  ? SortDirection.DESC
                  : SortDirection.ASC,
            })
          }
          width={maxColumnWidth.fourthColWidth}
        >
          <WellCustomHeaderRow.ColTextContainer>
            {fourthColTitle}
            {sortBy === fourthColDataKey && (
              <WellCustomHeaderRow.SortIndicatorWrapper>
                <SortIndicator sortDirection={sortDirection} />
              </WellCustomHeaderRow.SortIndicatorWrapper>
            )}
          </WellCustomHeaderRow.ColTextContainer>
        </WellCustomHeaderRow.Col>
      </>
    )}
  </div>
);

WellCustomHeaderRow.CommonCol = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-transform: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-left: 1px solid #c1c1c1;
  padding-right: 2px;
  cursor: pointer;
  line-height: 1.3em;
`;

WellCustomHeaderRow.Col = styled(WellCustomHeaderRow.CommonCol)`
  flex: 0 0 ${(props: Record<string, any>) => props.width + 1}px;
  box-sizing: content-box;
  position: relative;
`;

WellCustomHeaderRow.FourthCol = styled(WellCustomHeaderRow.CommonCol)`
  flex: 0 0 ${(props: Record<string, any>) => props.width + 1}px;
  box-sizing: content-box;
  position: relative;
`;

WellCustomHeaderRow.FirstCol = styled(WellCustomHeaderRow.CommonCol)`
  padding-left: 5px;
  justify-content: space-between;
  border-left: none;
  flex: 1 1 200px;
`;

WellCustomHeaderRow.ColTextContainer = styled.div`
  width: ${props => (props.width && props.width > 60 ? '100%' : '40px')};
  display: grid;
  grid-template-columns: ${props =>
    props.width && props.width > 60
      ? `${(props.width * 3) / 4}px ${props.width / 4}px`
      : '30px 10px'};
  justify-items: end;
`;

WellCustomHeaderRow.SortIndicatorWrapper = styled.div`
  position: relative;
  left: 5px;
  height: 5px;
`;

WellCustomHeaderRow.FirstColName = styled.div`
  display: flex;
`;

WellCustomHeaderRow.ClearButton = styled.span`
  color: rgba(0, 0, 0, 0.8);
  margin-right: 6px;
`;

export default WellCustomHeaderRow;
