import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { CloseIcon } from 'components/Icons';
import * as Shortcuts from 'components/Shortcuts';
import { useNonInputKeydown } from 'hooks/useKeydown';
import useRightPanel from 'modules/ui/hooks/useRightPanel';

type ShortcutPanelProps = {
  onShortcutPanelClose: () => void;
  onRequestHelpPanelOpen: () => void;
};

const ShortcutPanel = ({
  onShortcutPanelClose,
  onRequestHelpPanelOpen,
}: ShortcutPanelProps) => {
  const rightPanel = useRightPanel();
  const shownCloseButton = window.location.pathname.includes('/dashboard');

  useNonInputKeydown(
    ({ keyName }) => {
      if (!rightPanel.isDialogOfType('Shortcuts')) return;
      if (keyName === 'ESCAPE') return onShortcutPanelClose();
    },
    [onShortcutPanelClose, rightPanel.isDialogOfType],
  );

  return (
    <ShortcutPanel.Card>
      <ShortcutPanel.Header>
        <ShortcutPanel.TitleWrapper>
          <ShortcutPanel.Title>Keyboard Shortcuts</ShortcutPanel.Title>
        </ShortcutPanel.TitleWrapper>
        <ShortcutPanel.RequestHelpButton
          onClick={onRequestHelpPanelOpen}
          width={110}
        >
          Request Help
        </ShortcutPanel.RequestHelpButton>
        {shownCloseButton && (
          <ShortcutPanel.CloseButton
            transparent
            onClick={onShortcutPanelClose}
            width={25}
          >
            <CloseIcon />
          </ShortcutPanel.CloseButton>
        )}
      </ShortcutPanel.Header>
      <ShortcutPanel.PanelContent>
        <ShortcutPanel.Group>
          <ShortcutPanel.GroupTitle>General</ShortcutPanel.GroupTitle>
          <ShortcutPanel.GroupItem>
            Open/Close Shortcuts Panel <Shortcuts.ForwardSlash />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Move Between Wells in Drilldown Table <Shortcuts.KeyUpOrKeyDown />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Set Drilldown Region of Interest <Shortcuts.KeyR />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Begin Adding New Capacity Change <Shortcuts.KeyC />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Begin Adding New Variance Event <Shortcuts.KeyV />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Extend last Variance End Date to Today <Shortcuts.KeyE />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Open the Notes Panel <Shortcuts.KeyN />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Prior/Next Note (w/ Notes Panel Open)
            <Shortcuts.KeyLeftOrKeyRight />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Delete Active Capacity/Variance/Ribbon Event <Shortcuts.Del />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Confirm Delete in Pop-Up Dialog Box <Shortcuts.Enter />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Adjust Capacity Line B-Factor w/ Mouse <Shortcuts.KeyBAndDrag />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Select Multiple Separate Table Rows <Shortcuts.CtrlAndClick />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Select Multiple Adjacent Table Rows <Shortcuts.ShiftAndClick />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Prior/Next Image (w/ Image Details Open)
            <Shortcuts.KeyLeftOrKeyRight />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Close right panel <Shortcuts.Esc />
          </ShortcutPanel.GroupItem>
        </ShortcutPanel.Group>

        <ShortcutPanel.Group>
          <ShortcutPanel.GroupTitle>Zoom</ShortcutPanel.GroupTitle>
          <ShortcutPanel.GroupItem>
            Zoom In or Out on Production Chart <Shortcuts.Scroll />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Zoom to Show All Production History <Shortcuts.KeyS />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Zoom In <Shortcuts.KeyI />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Zoom Out <Shortcuts.KeyO />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Zoom Previous <Shortcuts.KeyP />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Zoom to Prior 3 Months <Shortcuts.KeyZThenNum1 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Zoom to Prior 6 Months <Shortcuts.KeyZThenNum2 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Zoom to Prior 12 Months <Shortcuts.KeyZThenNum3 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Zoom to Prior 24 Months <Shortcuts.KeyZThenNum4 />
          </ShortcutPanel.GroupItem>
        </ShortcutPanel.Group>

        <ShortcutPanel.Group>
          <ShortcutPanel.GroupTitle>Trellises</ShortcutPanel.GroupTitle>
          <ShortcutPanel.GroupItem>
            Toggle BOE Trellis <Shortcuts.Num1 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Toggle Oil Trellis <Shortcuts.Num2 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Toggle Gas Trellis <Shortcuts.Num3 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Toggle GOR Trellis <Shortcuts.Num4 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Toggle Water Trellis <Shortcuts.Num5 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Toggle Watercut Trellis <Shortcuts.Num6 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Toggle GLR Trellis <Shortcuts.Num7 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Toggle Total Liquid Trellis <Shortcuts.Num8 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Toggle Variance Trellis <Shortcuts.Num9 />
          </ShortcutPanel.GroupItem>
          <ShortcutPanel.GroupItem>
            Toggle All Trellises <Shortcuts.Num0 />
          </ShortcutPanel.GroupItem>
        </ShortcutPanel.Group>
      </ShortcutPanel.PanelContent>
    </ShortcutPanel.Card>
  );
};

ShortcutPanel.Card = styled.div`
  background: white;
  height: 100%;
  overflow-x: hidden;
`;

ShortcutPanel.PanelContent = styled.div`
  padding: 8px 16px;
`;

ShortcutPanel.Header = styled.div`
  height: 47px;
  display: grid;
  grid-template-columns: auto auto 25px;
  align-content: center;
  padding: 15px 16px;
  font-size: 16px;
  border-bottom: 1px solid #000;
  font-weight: bold;
  align-items: center;
`;

ShortcutPanel.TitleWrapper = styled.div`
  display: flex;
  align-content: center;
`;

ShortcutPanel.CloseButton = styled(Button)`
  justify-self: end;
`;

ShortcutPanel.Title = styled.div`
  display: flex;
  align-items: center;
`;

ShortcutPanel.RequestHelpButton = styled(Button)`
  justify-self: end;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

ShortcutPanel.Group = styled.div`
  padding: 8px 0;
`;

ShortcutPanel.GroupTitle = styled.h4`
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 8px;
`;

ShortcutPanel.GroupItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 6px 0;
  gap: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }
`;

export default React.memo<ShortcutPanelProps>(ShortcutPanel);
