import * as React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import {
  COMPARE_OPTION,
  DrilldownTableParams,
} from '../../models/drilldownTable';
import { PlusIcon } from 'components/Icons';
import { DefaultLayoutConfiguration } from './DefaultLayoutConfiguration';
import { CustomLayoutConfiguration } from './CustomLayoutConfiguration';
import { getFlagsStateMap } from '../../../featureFlags/FeatureFlagsReducer';
import { useSelector } from '../../../../store/models';
import {
  NormalizedSeriesMapping,
  NormalizedCoreSeriesMapping,
} from 'modules/series/models';

interface WellDrilldownSelectMenuProps {
  currentDrilldownTableState: DrilldownTableParams;
  onSetCompareOption: (optionKey: string) => void;
  setDrilldownTableOption: (option: string) => void;
  setGrossNet: (grossNet: string) => void;
  setRateVolume: (rate: string) => void;
  setOpenMenu: (isOpen: boolean) => void;
  isCustomLayout: boolean;
  setIsCustomLayout: (isCustomLayout: boolean) => void;
  setIsCustomLayoutLocally: (isCustomLayout: boolean) => void;
  seriesMapping: NormalizedSeriesMapping;
  coreSeriesMapping: NormalizedCoreSeriesMapping;
  setCustomLayoutParams: (params: {
    seriesId: string;
    aggregateFn: string;
  }) => void;
  customLayoutsParams: {
    seriesId: string;
    aggregateFn: string;
  } | null;
}

export const WellDrilldownSelectMenu = ({
  currentDrilldownTableState,
  setOpenMenu,
  onSetCompareOption,
  setDrilldownTableOption,
  setGrossNet,
  setRateVolume,
  isCustomLayout,
  setIsCustomLayout,
  setIsCustomLayoutLocally,
  seriesMapping,
  coreSeriesMapping,
  setCustomLayoutParams,
  customLayoutsParams,
}: WellDrilldownSelectMenuProps) => {
  const [compareOption, setCompareOption] = React.useState<string>(
    currentDrilldownTableState.compareOption,
  );
  const [phase, setPhaseLocally] = React.useState<string>(
    currentDrilldownTableState.phase,
  );
  const [grossNet, setGrossNetLocally] = React.useState<string>(
    currentDrilldownTableState.grossNet,
  );
  const [rateVolume, setRateVolumeLocally] = React.useState<string>(
    currentDrilldownTableState.rateVolume,
  );

  const [selectedSeries, setSelectedSeries] = React.useState<string>(
    customLayoutsParams?.seriesId ||
      coreSeriesMapping[Object.keys(coreSeriesMapping)[0]].id,
  );

  const [aggregateFn, setAggregateFn] = React.useState<string>(
    customLayoutsParams?.aggregateFn || 'avg',
  );

  const flagsState: ReturnType<typeof getFlagsStateMap> =
    useSelector(getFlagsStateMap);

  const applyParameters = React.useCallback(() => {
    setOpenMenu(false);
    setIsCustomLayout(isCustomLayout);
    if (isCustomLayout) {
      setCustomLayoutParams({ seriesId: selectedSeries, aggregateFn });
    } else {
      onSetCompareOption(compareOption);
      setDrilldownTableOption(phase);
      setGrossNet(grossNet);
      setRateVolume(rateVolume);
    }
  }, [
    compareOption,
    phase,
    grossNet,
    rateVolume,
    onSetCompareOption,
    setDrilldownTableOption,
    setGrossNet,
    setRateVolume,
    setOpenMenu,
    isCustomLayout,
    selectedSeries,
    aggregateFn,
  ]);

  return (
    <WellDrilldownSelectMenu.MenuContainer>
      <WellDrilldownSelectMenu.SelectLayout>
        <WellDrilldownSelectMenu.MenuTitle>
          Select Layout
        </WellDrilldownSelectMenu.MenuTitle>
        <WellDrilldownSelectMenu.LayoutsContainer selected={!isCustomLayout}>
          <WellDrilldownSelectMenu.Layout
            selected={
              compareOption === COMPARE_OPTION.actual && !isCustomLayout
            }
            onClick={() => {
              setIsCustomLayoutLocally(false);
              setCompareOption(COMPARE_OPTION.actual);
            }}
          >
            Capacity vs Actual
          </WellDrilldownSelectMenu.Layout>
          <WellDrilldownSelectMenu.Layout
            selected={
              compareOption === COMPARE_OPTION.extVsCap && !isCustomLayout
            }
            onClick={() => {
              setIsCustomLayoutLocally(false);
              setCompareOption(COMPARE_OPTION.extVsCap);
            }}
          >
            RE Fcst vs Capacity
          </WellDrilldownSelectMenu.Layout>
          <WellDrilldownSelectMenu.Layout
            selected={
              compareOption === COMPARE_OPTION.actVsExt && !isCustomLayout
            }
            onClick={() => {
              setIsCustomLayoutLocally(false);
              setCompareOption(COMPARE_OPTION.actVsExt);
            }}
          >
            RE Fcst vs Actual
          </WellDrilldownSelectMenu.Layout>
        </WellDrilldownSelectMenu.LayoutsContainer>
        {flagsState.customDrilldown && (
          <WellDrilldownSelectMenu.LayoutsContainer selected={isCustomLayout}>
            <WellDrilldownSelectMenu.Layout
              selected={isCustomLayout}
              onClick={() => {
                setIsCustomLayoutLocally(true);
              }}
            >
              <PlusIcon />
              <span className="title">Custom</span>
            </WellDrilldownSelectMenu.Layout>
          </WellDrilldownSelectMenu.LayoutsContainer>
        )}
      </WellDrilldownSelectMenu.SelectLayout>

      {isCustomLayout ? (
        <CustomLayoutConfiguration
          seriesMapping={seriesMapping}
          coreSeriesMapping={coreSeriesMapping}
          setSelectedSeries={setSelectedSeries}
          setAggregateFn={setAggregateFn}
          aggregateFn={aggregateFn}
          selectedSeries={selectedSeries}
        />
      ) : (
        <DefaultLayoutConfiguration
          phase={phase}
          grossNet={grossNet}
          rateVolume={rateVolume}
          setPhaseLocally={setPhaseLocally}
          setGrossNetLocally={setGrossNetLocally}
          setRateVolumeLocally={setRateVolumeLocally}
        />
      )}

      <WellDrilldownSelectMenu.SavePanel>
        <WellDrilldownSelectMenu.ApplyButtons
          onClick={() => setOpenMenu(false)}
        >
          Cancel
        </WellDrilldownSelectMenu.ApplyButtons>
        <WellDrilldownSelectMenu.ApplyButtons onClick={applyParameters}>
          Apply
        </WellDrilldownSelectMenu.ApplyButtons>
      </WellDrilldownSelectMenu.SavePanel>
    </WellDrilldownSelectMenu.MenuContainer>
  );
};

WellDrilldownSelectMenu.MenuContainer = styled.div`
  width: 464px;
  height: 328px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto 60px;
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.15), 0 0 12px 0 rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 26px;
  z-index: 980;
`;

WellDrilldownSelectMenu.MenuTitle = styled.div`
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 14px 24px;
`;

WellDrilldownSelectMenu.SelectLayout = styled.div`
  height: 100%;
  z-index: 982;
`;

WellDrilldownSelectMenu.LayoutsContainer = styled.div`
  border-top: 1px solid #c1c1c1;
  border-left: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  border-radius: 4px 0 0 4px;
  ${({ selected }) => (!selected ? 'border-color: transparent;' : 'left: 1px;')}
  margin-left: 7px;
  position: relative;
  background-color: #fff;
  padding: 8px;
`;

WellDrilldownSelectMenu.Layout = styled.div`
  font-size: 14px;
  height: 25px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  ${({ selected }) => selected && 'background-color: #e1e1e1;'}

  .title {
    margin-left: 4px;
  }
`;

WellDrilldownSelectMenu.ApplyButtons = styled(Button)`
  width: 86px;
  height: 30px;
`;

WellDrilldownSelectMenu.SavePanel = styled.div`
  height: 54px;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #c1c1c1;
`;
