import gql from 'graphql-tag';

import {
  Action,
  GraphqlQueryAction,
  GraphqlMutationAction,
} from 'store/models';
import { FilterLayoutOptions, FiltersLayouts } from './models';

export const namespace = 'FILTERS_LAYOUTS';

export const CHANGE_OPTIONS_COLUMNS_ORDER = `${namespace}/CHANGE_OPTIONS_COLUMNS_ORDER`;
export const CHANGE_OPTIONS_ORDER = `${namespace}/CHANGE_OPTIONS_ORDER`;
export const CHANGE_OPTION_VISIBLE = `${namespace}/CHANGE_OPTION_VISIBLE`;
export const CREATE_FILTERS_LAYOUT = `${namespace}/CREATE_FILTERS_LAYOUT`;
export const CREATE_WELL_CUSTOM_ATTRIBUTE = `${namespace}/CREATE_WELL_CUSTOM_ATTRIBUTE`;
export const CREATE_WELL_CUSTOM_ATTRIBUTE_VALUES = `${namespace}/CREATE_WELL_CUSTOM_ATTRIBUTE_VALUES`;
export const FETCH_FILTERS_LAYOUTS = `${namespace}/FETCH_FILTERS_LAYOUTS`;
export const FETCH_WELL_CUSTOM_ATTRIBUTES = `${namespace}/FETCH_WELL_CUSTOM_ATTRIBUTES`;
export const FETCH_WELL_CUSTOM_ATTRIBUTES_VALUES = `${namespace}/FETCH_WELL_CUSTOM_ATTRIBUTES_VALUES`;
export const SELECT_FILTER_OPTION = `${namespace}/SELECT_FILTER_OPTION`;
export const SET_CURRENT_CONFIGURATION = `${namespace}/SET_CURRENT_CONFIGURATION`;
export const SET_CURRENT_FILTERS_LAYOUT = `${namespace}/SET_CURRENT_FILTERS_LAYOUT`;
export const SET_FILTERS_LAYOUTS = `${namespace}/SET_FILTERS_LAYOUTS`;
export const SET_FILTER_OPTIONS = `${namespace}/SET_FILTER_OPTIONS`;
export const SET_CUSTOM_FILTER_OPTIONS = `${namespace}/SET_CUSTOM_FILTER_OPTIONS`;
export const SET_DRAGGABLE_COLUMN = `${namespace}/SET_DRAGGABLE_COLUMN`;
export const SET_DROP_INDICATOR_INDEX = `${namespace}/SET_DROP_INDICATOR_INDEX`;
export const SET_SELECTED_FILTER_OPTIONS = `${namespace}/SET_SELECTED_FILTER_OPTIONS`;
export const CLEAR_SELECTED_FILTER_OPTIONS = `${namespace}/CLEAR_SELECTED_FILTER_OPTIONS`;
export const SET_WELL_CUSTOM_ATTRIBUTES = `${namespace}/SET_WELL_CUSTOM_ATTRIBUTES`;
export const REMOVE_FILTER_OPTION = `${namespace}/REMOVE_FILTER_OPTION`;
export const REMOVE_FILTERS_LAYOUT = `${namespace}/REMOVE_FILTERS_LAYOUT`;
export const REMOVE_WELL_CUSTOM_ATTRIBUTE = `${namespace}/REMOVE_WELL_CUSTOM_ATTRIBUTE`;
export const REMOVE_WELL_CUSTOM_ATTRIBUTE_VALUES = `${namespace}/REMOVE_WELL_CUSTOM_ATTRIBUTE_VALUES`;
export const RENAME_FILTERS_LAYOUT = `${namespace}/RENAME_FILTERS_LAYOUT`;
export const UPDATE_FILTERS_LAYOUT = `${namespace}/UPDATE_FILTERS_LAYOUT`;
export const UPDATE_WELL_CUSTOM_ATTRIBUTE = `${namespace}/UPDATE_WELL_CUSTOM_ATTRIBUTE`;
export const UPDATE_WELL_CUSTOM_ATTRIBUTE_VALUES = `${namespace}/UPDATE_WELL_CUSTOM_ATTRIBUTE_VALUES`;
export const REORDER_FILTERS_LAYOUTS_LOCALY = `${namespace}/REORDER_FILTERS_LAYOUTS_LOCALY`;
export const REORDER_FILTERS_LAYOUTS_REMOTYLY = `${namespace}/REORDER_FILTERS_LAYOUTS_REMOTYLY`;

type setDraggableColumnAC = (payload: { columnName: string }) => Action;

export const setDraggableColumn: setDraggableColumnAC = payload => ({
  type: SET_DRAGGABLE_COLUMN,
  payload,
});

type setDropIndicatorIndexAC = (payload: { index: number | null }) => Action;

export const setDropIndicatorIndex: setDropIndicatorIndexAC = payload => ({
  type: SET_DROP_INDICATOR_INDEX,
  payload,
});

type setFilterOptionsAC = (payload: {
  options: FilterLayoutOptions | Record<string, never>;
}) => Action;

export const setFilterOptions: setFilterOptionsAC = payload => ({
  type: SET_FILTER_OPTIONS,
  payload,
});

type setCustomFilterOptionsAC = (payload: {
  options: FilterLayoutOptions;
}) => Action;

export const setCustomFilterOptions: setCustomFilterOptionsAC = payload => ({
  type: SET_CUSTOM_FILTER_OPTIONS,
  payload,
});

type setSelectedFilterOptionsAC = (payload: {
  selectedOptions: string[];
}) => Action;

export const setSelectedFilterOptions: setSelectedFilterOptionsAC =
  payload => ({
    type: SET_SELECTED_FILTER_OPTIONS,
    payload,
  });

type ClearSelectedFilterOptionsAC = () => Action;

export const clearSelectedFilterOptions: ClearSelectedFilterOptionsAC = () => ({
  type: CLEAR_SELECTED_FILTER_OPTIONS,
});

type selectFilterOptionAC = (payload: { optionId: string }) => Action;

export const selectFilterOption: selectFilterOptionAC = payload => ({
  type: SELECT_FILTER_OPTION,
  payload,
});

type removeFilterOptionAC = (payload: { optionId: string }) => Action;

export const removeFilterOption: removeFilterOptionAC = payload => ({
  type: REMOVE_FILTER_OPTION,
  payload,
});

type changeFilterOptionVisibleAC = (payload: { optionId: string }) => Action;

export const changeFilterOptionVisible: changeFilterOptionVisibleAC =
  payload => ({
    type: CHANGE_OPTION_VISIBLE,
    payload,
  });

type setCurrentFiltersConfigurationAC = (payload: {
  configuration: string;
}) => Action;

export const setCurrentFiltersConfiguration: setCurrentFiltersConfigurationAC =
  payload => ({
    type: SET_CURRENT_CONFIGURATION,
    payload,
  });

type changeOptionsOrderAC = (payload: {
  dragId: string;
  hoverId: string;
}) => Action;

export const changeFilterOptionsOrder: changeOptionsOrderAC = payload => ({
  type: CHANGE_OPTIONS_ORDER,
  payload,
});

type changeOptionsColumnsOrderAC = (payload: {
  dragIndex: string;
  dropIndex: string;
}) => Action;

export const changeOptionsColumnsOrder: changeOptionsColumnsOrderAC =
  payload => ({
    type: CHANGE_OPTIONS_COLUMNS_ORDER,
    payload,
  });

const FETCH_FILTERS_LAYOUTS_QUERY = gql`
  query ($userId: ID!) {
    listFiltersLayouts(userId: $userId) {
      id
      userId
      name
      configuration
      order
    }
  }
`;

type fetchFiltersLayoutsAC = (userId: string) => GraphqlQueryAction;

export const fetchFiltersLayouts: fetchFiltersLayoutsAC = userId => ({
  type: FETCH_FILTERS_LAYOUTS,
  payload: {
    key: 'listFiltersLayouts',
    graphql: {
      query: FETCH_FILTERS_LAYOUTS_QUERY,
      variables: { userId },
    },
  },
});

const CREATE_FILTER_LAYOUT_MUTATION = gql`
  mutation ($payload: CreateFiltersLayoutInput!) {
    createFiltersLayout(data: $payload) {
      id
      userId
      name
      configuration
      order
    }
  }
`;

type createFiltersLayoutAC = (payload: {
  id: string;
  userId: string;
  name: string;
  configuration: string;
  order: number;
}) => GraphqlMutationAction;

export const createFiltersLayout: createFiltersLayoutAC = payload => ({
  type: CREATE_FILTERS_LAYOUT,
  payload: {
    key: 'createFiltersLayout',
    graphql: {
      mutation: CREATE_FILTER_LAYOUT_MUTATION,
      variables: { payload },
    },
  },
});

type setCurrentFiltersLayoutAC = (payload: FiltersLayouts) => Action;

export const setCurrentFiltersLayout: setCurrentFiltersLayoutAC = payload => ({
  type: SET_CURRENT_FILTERS_LAYOUT,
  payload,
});

const REMOVE_FILTERS_LAYOUT_MUTATION = gql`
  mutation ($id: ID!) {
    deleteFiltersLayout(id: $id) {
      id
      userId
      name
      configuration
      order
    }
  }
`;

type removeFiltersLayoutAC = (payload: { id: string }) => GraphqlMutationAction;

export const removeFiltersLayout: removeFiltersLayoutAC = payload => ({
  type: REMOVE_FILTERS_LAYOUT,
  payload: {
    key: 'deleteFiltersLayout',
    graphql: {
      mutation: REMOVE_FILTERS_LAYOUT_MUTATION,
      variables: payload,
    },
  },
});

const RENAME_FILTERS_LAYOUT_MUTATION = gql`
  mutation ($payload: FiltersLayoutInput!) {
    updateFiltersLayout(data: $payload) {
      id
      userId
      name
      configuration
      order
    }
  }
`;
type updateFiltersLayoutAC = (
  payload: FilterLayoutOptions,
) => GraphqlMutationAction;

export const updateFiltersLayout: updateFiltersLayoutAC = payload => {
  return {
    type: UPDATE_FILTERS_LAYOUT,
    payload: {
      key: 'updateFiltersLayout',
      graphql: {
        mutation: RENAME_FILTERS_LAYOUT_MUTATION,
        variables: { payload },
      },
    },
  };
};

type renameFiltersLayoutAC = (payload: {
  id: string;
  name: string;
}) => GraphqlMutationAction;

export const renameFiltersLayout: renameFiltersLayoutAC = payload => {
  return {
    type: RENAME_FILTERS_LAYOUT,
    payload: {
      key: 'updateFiltersLayout',
      graphql: {
        mutation: RENAME_FILTERS_LAYOUT_MUTATION,
        variables: { payload },
      },
    },
  };
};

const REORDER_FIlTERS_LAYOUTS_MUTATION = gql`
  mutation ($data: [FiltersLayoutInput!]!) {
    reorderFiltersLayouts(data: $data) {
      id
      userId
      name
      configuration
      order
    }
  }
`;

type reorderFiltersLayoutsAC = (payload: {
  data: FiltersLayouts[];
}) => GraphqlMutationAction;

export const reorderFiltersLayouts: reorderFiltersLayoutsAC = payload => {
  return {
    type: REORDER_FILTERS_LAYOUTS_REMOTYLY,
    payload: {
      key: 'reorderFiltersLayouts',
      graphql: {
        mutation: REORDER_FIlTERS_LAYOUTS_MUTATION,
        variables: payload,
      },
    },
  };
};

type reorderFiltersLayoutsLocallyAC = (payload: {
  dragId: string;
  hoverId: string;
}) => Action;

export const reorderFiltersLayoutsLocally: reorderFiltersLayoutsLocallyAC =
  payload => ({
    type: REORDER_FILTERS_LAYOUTS_LOCALY,
    payload,
  });

type setFiltersLayoutsLocallyAC = (payload: {
  layouts: FiltersLayouts[];
}) => Action;

export const setFiltersLayoutsLocally: setFiltersLayoutsLocallyAC =
  payload => ({
    type: SET_FILTERS_LAYOUTS,
    payload,
  });

const FETCH_WELL_CUSTOM_ATTRIBUTES_QUERY = gql`
  query {
    listWellCustomAttributes {
      id
      name
    }
  }
`;
type fetchWellCustomAttributesAC = () => GraphqlQueryAction;

export const fetchWellCustomAttributes: fetchWellCustomAttributesAC = () => {
  return {
    type: FETCH_WELL_CUSTOM_ATTRIBUTES,
    payload: {
      key: 'listWellCustomAttributes',
      graphql: {
        query: FETCH_WELL_CUSTOM_ATTRIBUTES_QUERY,
      },
    },
  };
};

const RENAME_WELL_CUSTOM_ATTRIBUTE_MUTATION = gql`
  mutation ($payload: UpdateWellCustomAttributeInput!) {
    updateWellCustomAttribute(data: $payload) {
      id
      name
    }
  }
`;
type updateWellCustomAttributeAC = (payload: {
  id: string;
  name: string;
}) => GraphqlMutationAction;

export const updateWellCustomAttribute: updateWellCustomAttributeAC =
  payload => {
    return {
      type: UPDATE_WELL_CUSTOM_ATTRIBUTE,
      payload: {
        key: 'updateWellCustomAttribute',
        graphql: {
          mutation: RENAME_WELL_CUSTOM_ATTRIBUTE_MUTATION,
          variables: { payload },
        },
      },
    };
  };

const CREATE_WELL_CUSTOM_ATTRIBUTE_MUTATION = gql`
  mutation ($payload: CreateWellCustomAttributeInput!) {
    createWellCustomAttribute(data: $payload) {
      id
      name
    }
  }
`;

type createWellCustomAttributeAC = (payload: {
  name: string;
  id: string;
}) => GraphqlMutationAction;

export const createWellCustomAttribute: createWellCustomAttributeAC =
  payload => ({
    type: CREATE_WELL_CUSTOM_ATTRIBUTE,
    payload: {
      key: 'createWellCustomAttribute',
      graphql: {
        mutation: CREATE_WELL_CUSTOM_ATTRIBUTE_MUTATION,
        variables: { payload },
      },
    },
  });

const REMOVE_WELL_CUSTOM_ATTRIBUTE_MUTATION = gql`
  mutation ($id: ID!) {
    deleteWellCustomAttribute(id: $id)
  }
`;

type deleteWellCustomAttributeAC = (id: string) => Action;

export const deleteWellCustomAttribute: deleteWellCustomAttributeAC = id => ({
  type: REMOVE_WELL_CUSTOM_ATTRIBUTE,
  payload: {
    key: 'deleteWellCustomAttribute',
    graphql: {
      mutation: REMOVE_WELL_CUSTOM_ATTRIBUTE_MUTATION,
      variables: {
        id,
      },
    },
  },
});

const FETCH_WELL_CUSTOM_ATTRIBUTES_VALUES_QUERY = gql`
  query {
    listWellCustomAttributesValues {
      wellCustomAttributeId
      wellId
      value
    }
  }
`;

type fetchWellCustomAttributesValuesAC = () => GraphqlQueryAction;

export const fetchWellCustomAttributesValues: fetchWellCustomAttributesValuesAC =
  () => ({
    type: FETCH_WELL_CUSTOM_ATTRIBUTES_VALUES,
    payload: {
      key: 'listWellCustomAttributesValues',
      graphql: {
        query: FETCH_WELL_CUSTOM_ATTRIBUTES_VALUES_QUERY,
      },
    },
  });

const UPDATE_WELL_CUSTOM_ATTRIBUTE_VALUES_MUTATION = gql`
  mutation ($payload: [UpdateWellCustomAttributeValueInput!]) {
    updateWellCustomAttributeValues(data: $payload) {
      wellCustomAttributeId
      wellId
      value
    }
  }
`;
type updateWellCustomAttributeValuePayload = {
  wellId: string;
  wellCustomAttributeId: string;
  value: string;
};
type updateWellCustomAttributeValuesAC = (
  payload: updateWellCustomAttributeValuePayload[],
) => GraphqlMutationAction;

export const updateWellCustomAttributeValues: updateWellCustomAttributeValuesAC =
  payload => {
    return {
      type: UPDATE_WELL_CUSTOM_ATTRIBUTE_VALUES,
      payload: {
        key: 'updateWellCustomAttributeValues',
        graphql: {
          mutation: UPDATE_WELL_CUSTOM_ATTRIBUTE_VALUES_MUTATION,
          variables: { payload },
        },
      },
    };
  };

const REMOVE_WELL_CUSTOM_ATTRIBUTE_VALUES_MUTATION = gql`
  mutation ($payload: [DeleteWellCustomAttributeValueInput!]) {
    deleteWellCustomAttributeValues(data: $payload)
  }
`;
type removeWellCustomAttributeValuesPayload = {
  wellId: string;
  wellCustomAttributeId: number;
};
type removeWellCustomAttributeValuesAC = (
  payload: removeWellCustomAttributeValuesPayload[],
) => GraphqlMutationAction;

export const removeWellCustomAttributeValues: removeWellCustomAttributeValuesAC =
  payload => {
    return {
      type: REMOVE_WELL_CUSTOM_ATTRIBUTE_VALUES,
      payload: {
        key: 'deleteWellCustomAttributeValues',
        graphql: {
          mutation: REMOVE_WELL_CUSTOM_ATTRIBUTE_VALUES_MUTATION,
          variables: { payload },
        },
      },
    };
  };

const CREATE_WELL_CUSTOM_ATTRIBUTE_VALUES_MUTATION = gql`
  mutation ($payload: [CreateWellCustomAttributeValueInput!]) {
    createWellCustomAttributeValues(data: $payload) {
      wellCustomAttributeId
      value
      wellId
    }
  }
`;
type createWellCustomAttributeValuesPayload = {
  wellId: string;
  wellCustomAttributeId: number;
  value: string;
};
type createWellCustomAttributeValuesAC = (
  payload: createWellCustomAttributeValuesPayload[],
) => GraphqlMutationAction;

export const createWellCustomAttributeValues: createWellCustomAttributeValuesAC =
  payload => {
    return {
      type: CREATE_WELL_CUSTOM_ATTRIBUTE_VALUES,
      payload: {
        key: 'createWellCustomAttributeValues',
        graphql: {
          mutation: CREATE_WELL_CUSTOM_ATTRIBUTE_VALUES_MUTATION,
          variables: { payload },
        },
      },
    };
  };
