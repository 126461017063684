import gql from 'graphql-tag';
import { AC } from 'store/models';

export const namespace = 'FEATURE_FLAGS';
export const FETCH_ALL_FEATURE_FLAGS = `${namespace}/FETCH_ALL_FEATURE_FLAGS`;
export const FETCH_ALL_FEATURE_FLAGS_ANON = `${namespace}/FETCH_ALL_FEATURE_FLAGS_ANON`;
export const FETCH_ALL_FEATURE_FLAGS_ANON_SUCCESS = `${namespace}/FETCH_ALL_FEATURE_FLAGS_ANON_SUCCESS`;
export const FETCH_ALL_FEATURE_FLAGS_SUCCESS = `${FETCH_ALL_FEATURE_FLAGS}_SUCCESS`;
export const TOGGLE_FEATURE_FLAG_LOCALLY = `${namespace}/TOGGLE_FEATURE_FLAG_LOCALLY`;

const allFeatureFlagsQuery = gql`
  query AllFeatureFlags {
    listAllFeatureFlags {
      key
      name
      toggleable
      value
    }
  }
`;

export const fetchAllFeatureFlags = () => ({
  type: FETCH_ALL_FEATURE_FLAGS,
  payload: {
    key: 'listAllFeatureFlags',
    graphql: { query: allFeatureFlagsQuery },
  },
});

export const toggleFlagLocally: AC<string> = payload => {
  return {
    type: TOGGLE_FEATURE_FLAG_LOCALLY,
    payload,
  };
};

const allFeatureFlagsAnonymouslyQuery = gql`
  query AllFeatureFlagsAnonymously {
    listAllFeatureFlagsAnonymously {
      key
      name
      toggleable
      value
    }
  }
`;

export const fetchAllFeatureFlagsAnonymously = () => ({
  type: FETCH_ALL_FEATURE_FLAGS_ANON,
  payload: {
    key: 'listAllFeatureFlagsAnonymously',
    graphql: { query: allFeatureFlagsAnonymouslyQuery },
  },
});
