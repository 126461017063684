import { scaleLinear } from 'd3-scale';

const createVarianceYScale = (
  height: number,
  minDataPoint: number,
  maxDataPoint: number,
  offset = 0,
): any =>
  scaleLinear().range([height, offset]).domain([minDataPoint, maxDataPoint]);

export default createVarianceYScale;
