import * as R from 'ramda';
import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import styled from 'styled-components';
import { useSelector } from 'store/models';

import { CloseIcon } from 'components/Icons';
import { NormalizedSeriesMapping } from 'modules/series/models';
import LongNameMouseTooltip from 'components/LongNameMouseTooltip/LongNameMouseTooltip';

import {
  AvailableDataSeries,
  DraggableOption,
  ChartOptionsGroup as ChartOptionsGroupType,
  ITEM_TYPES,
  ItemTypes,
  ListChartOptions,
  OPTIONS_TYPES,
  MAX_SELECTED_NAME_WIDTH,
} from '../models';
import GroupItem from './GroupItem';
import LeftOptionButton from './LeftOptionButton';
import { getSeriesColor } from '../utils';
import checkSensorSeriesExists from '../utils/checkSensorSeriesExists';
import {
  getCalculatedSeriesMapping,
  getSensorSeriesMapping,
} from '../../series/SeriesReducer';
import { getCurrentWellId } from '../../ui/UIReducer';
import { removeSeriesAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

interface ChartOptionsGroupProps {
  currentGroup: ChartOptionsGroupType;
  hoveredGroupIndex: number | null;
  hoveredOptionIndex: number | null;
  index: number;
  isInsideAvaliableArea: boolean;
  isShowDraggedGroup: boolean;
  isShowOriginOption: boolean;
  itemType: string;
  movedOption: DraggableOption;
  onAddOptionToGroup: (data: {
    groupId: string;
    optionId: string;
    newOptionIndex: number;
    previousGroupId?: string;
  }) => void;
  onChartTypeMenuOpen: (data: {
    optionId: string;
    elemRect: ClientRect;
    event: MouseEvent;
  }) => void;
  onCreateNewGroup: (data: {
    optionId: string;
    newGroupIndex: number;
    groupId?: string;
  }) => void;
  onMoveGroup: (groupIndex: number, hoveredGroupIndex: number) => void;
  onSetHoveredGroupIndex: (value: number) => void;
  onSetHoveredOptionIndex: (index: number) => void;
  onSetIsShowDraggedGroup: (value: boolean) => void;
  onSetIsShowOriginOption: (value: boolean) => void;
  onSetMovedOption: (value: DraggableOption) => void;
  onSetNewOptionsOrder: (option: ChartOptionsGroupType) => void;
  onSetTempGroupIndex: (value: number | null) => void;
  onStopHovering: () => void;
  onSwitchOptionVisibility: (optionId: string) => void;
  options: ListChartOptions;
  onRemoveOption: (groupId: string, option: AvailableDataSeries) => void;
  seriesMapping: NormalizedSeriesMapping;
  tempGroupIndex: number | null;
  onStopGroupMoving: () => void;
  setNewGroupIsCreated: any;
}

const ChartOptionsGroup = (props: ChartOptionsGroupProps) => {
  const {
    currentGroup,
    hoveredGroupIndex,
    hoveredOptionIndex,
    index,
    isInsideAvaliableArea,
    isShowDraggedGroup,
    isShowOriginOption,
    movedOption,
    onAddOptionToGroup,
    onChartTypeMenuOpen,
    onSetHoveredGroupIndex,
    onSetHoveredOptionIndex,
    onSetIsShowOriginOption,
    onSetMovedOption,
    onSetNewOptionsOrder,
    onSwitchOptionVisibility,
    onStopHovering,
    options,
    onRemoveOption,
    seriesMapping,
    tempGroupIndex,
    onCreateNewGroup,
    itemType,
    onMoveGroup,
    onSetIsShowDraggedGroup,
    onSetTempGroupIndex,
    onStopGroupMoving,
    setNewGroupIsCreated,
  } = props;

  const [optionsIds, setOptionsIds] = React.useState(currentGroup.options);

  const [{ item, isOver }, connectDropTarget] = useDrop(
    {
      accept: ItemTypes.SERIES,
      collect: monitor => ({
        isOver: monitor.isOver(),
        item: monitor.getItem(),
      }),
      drop: (item: any, monitor) => {
        const node = elementRef.current;
        if (!node || monitor.didDrop() || !item.props.currentGroup) {
          return;
        }
        if (R.equals(item.props.currentGroup.options, optionsIds)) {
          onStopGroupMoving();
        }
        const hoverBoundingRect = node.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;
        const newGroupIndex = hoverClientY < hoverMiddleY ? index : 1 + index;

        if (
          !monitor.didDrop() &&
          item.props.itemType === ITEM_TYPES.available
        ) {
          onCreateNewGroup({
            optionId: item.props.option.id,
            newGroupIndex,
          });
        } else if (
          !monitor.didDrop() &&
          item.props.itemType === ITEM_TYPES.selected
        ) {
          onCreateNewGroup({
            optionId: item.props.option.id,
            newGroupIndex,
            groupId: item.props.perentGroup.groupId,
          });
        } else if (
          !monitor.didDrop() &&
          item.props.itemType === ITEM_TYPES.singleOptionGroup &&
          item.id !== currentGroup.groupId &&
          item.props.currentGroup.options[0] &&
          optionsIds.length < 5
        ) {
          onAddOptionToGroup({
            optionId: item.props.currentGroup.options[0],
            groupId: currentGroup.groupId,
            newOptionIndex: hoveredOptionIndex ?? 0,
            previousGroupId: item.props.currentGroup.groupId,
          });
        }
      },
      hover: (item: any, monitor) => {
        const node = elementRef.current;
        if (!node) return null;

        const dragIndex = item.index;
        const hoverIndex = index;
        const hoverBoundingRect = node.getBoundingClientRect();
        const { y } = monitor.getClientOffset() as XYCoord;
        const { bottom, top } = hoverBoundingRect;

        const zoneBottom = bottom - (R.isNil(props.tempGroupIndex) ? 8 : 28);
        const zoneTop =
          top + (index === 0 && props.tempGroupIndex === 0 ? 22 : 8);
        const isOutOfZone = y < zoneBottom && y > zoneTop ? false : true;
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;
        const optionId = R.path(['props', 'option', 'id'], item);
        const optionType = R.path(['options', optionId, 'type'], props);
        const tempGroupIndex = hoverClientY < hoverMiddleY ? index : 1 + index;
        const isLikeOption =
          (item.itemType !== ITEM_TYPES.group &&
            item.itemType !== ITEM_TYPES.singleOptionGroup) ||
          (item.itemType === ITEM_TYPES.singleOptionGroup && !isOutOfZone);
        const isForbidAdding = currentGroup.options.length > 5;
        if (hoveredGroupIndex !== index) {
          onSetHoveredGroupIndex(index);
        }
        if (!R.isNil(props.tempGroupIndex)) {
          onSetTempGroupIndex(null);
        }
        if (itemType === ITEM_TYPES.group) {
          if (isForbidAdding) {
            if (
              item.props.itemTypes === ITEM_TYPES.singleOptionGroup ||
              item.props.itemTypes === ITEM_TYPES.selected ||
              item.props.itemTypes === ITEM_TYPES.available
            ) {
              onSetTempGroupIndex(tempGroupIndex);
              return;
            }
          } else {
            if (
              item.props.itemTypes === ITEM_TYPES.singleOptionGroup ||
              item.props.itemTypes === ITEM_TYPES.selected ||
              (item.props.itemTypes === ITEM_TYPES.available &&
                optionType === OPTIONS_TYPES.series)
            ) {
              onSetTempGroupIndex(null);
              return;
            }
          }

          if (
            item.props.itemType === ITEM_TYPES.singleOptionGroup &&
            item.props.currentGroup.groupId !== currentGroup.groupId &&
            isShowDraggedGroup
          ) {
            onSetHoveredGroupIndex(index);
            onSetIsShowDraggedGroup(false);
          }
        }
        if (itemType === ITEM_TYPES.singleOptionGroup) {
          if (
            item.props.itemTypes === ITEM_TYPES.singleOptionGroup ||
            item.props.itemTypes === ITEM_TYPES.selected ||
            (item.props.itemTypes === ITEM_TYPES.available &&
              optionType === OPTIONS_TYPES.series)
          ) {
            return;
          }
          if (
            item.props.itemType === ITEM_TYPES.singleOptionGroup &&
            item.props.currentGroup.groupId !== currentGroup.groupId &&
            isShowDraggedGroup
          ) {
            onSetIsShowDraggedGroup(false);
          }
        }
        if (
          isLikeOption ||
          currentGroup.groupId === item.id ||
          dragIndex === hoverIndex
        ) {
          return;
        }

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        onSetIsShowDraggedGroup(true);
        onMoveGroup(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    },
    [props, optionsIds],
  );

  const [{ isDragging }, connectDragSource] = useDrag(
    {
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      type: ItemTypes.SERIES,
      end: (item, monitor) => {
        if (!monitor.didDrop()) onStopGroupMoving();
        onStopHovering();
      },
      item: {
        id: currentGroup.groupId,
        index: index,
        itemType: itemType,
        props,
      },
    },
    [props],
  );

  const [movedOptionIndex, setMovedOptionIndex] = React.useState<number | null>(
    null,
  );

  const sensorSeriesMapping = useSelector(getSensorSeriesMapping);
  const calculatedSeriesMapping = useSelector(getCalculatedSeriesMapping);

  const currentWellId = useSelector(getCurrentWellId);

  const onSetMovedOptionIndex = React.useCallback(
    (index: number | null) => {
      if (movedOptionIndex !== index) {
        setMovedOptionIndex(index);
      }
    },
    [movedOptionIndex, setMovedOptionIndex],
  );

  const groupOptions = React.useMemo(() => {
    if (
      R.isEmpty(options) ||
      optionsIds.some(id => !options[id]) ||
      !sensorSeriesMapping
    ) {
      return;
    }

    return optionsIds.map(optionId => {
      if (/s/.test(optionId)) {
        return {
          ...options[optionId],
          exists: checkSensorSeriesExists(
            options[optionId],
            sensorSeriesMapping,
            currentWellId,
          ),
        };
      }
      if (/c/.test(optionId)) {
        return {
          ...options[optionId],
          exists: checkSensorSeriesExists(
            options[optionId],
            calculatedSeriesMapping,
            currentWellId,
          ),
        };
      }
      return options[optionId];
    });
  }, [optionsIds, options, sensorSeriesMapping]);
  const onMoveOption = React.useCallback(
    (hoveredOptionIndex: number, optionIndex?: number) => {
      if (typeof optionIndex !== 'undefined') {
        const currentEl = optionsIds[optionIndex];
        const newArr = [...optionsIds];
        newArr.splice(optionIndex, 1);
        newArr.splice(hoveredOptionIndex, 0, currentEl);
        setOptionsIds(newArr);
        onSetMovedOptionIndex(hoveredOptionIndex);
      }
    },
    [optionsIds, setOptionsIds, onSetMovedOptionIndex],
  );

  const onStopOptionMoving = React.useCallback(() => {
    onSetNewOptionsOrder({ ...currentGroup, options: optionsIds });
  }, [onSetNewOptionsOrder, optionsIds, currentGroup]);

  const isPerent =
    R.pathOr('', ['props', 'perentGroup', 'groupId'], item) ===
    currentGroup.groupId;

  React.useEffect(() => {
    setOptionsIds(currentGroup.options);
  }, [currentGroup.options, setOptionsIds]);

  const disabledAddButton =
    R.path(['props', 'itemType'], item) === ITEM_TYPES.group;

  const onGroupOptionHover = React.useCallback(
    (optionIndex: number) => {
      if (optionIndex !== hoveredOptionIndex) {
        onSetHoveredOptionIndex(optionIndex);
        onSetHoveredGroupIndex(index);
      }
    },
    [
      onSetHoveredGroupIndex,
      onSetHoveredOptionIndex,
      index,
      hoveredOptionIndex,
    ],
  );
  const elementRef = React.useRef<HTMLElement | null>(null);

  const [tooltipText, setTooltipText] = React.useState('');
  const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });
  const [isAnyOptionDraging, setIsAnyOptionDraging] = React.useState(false);

  const isLikeOption =
    isDragging &&
    currentGroup.options.length === 1 &&
    !R.isNil(hoveredOptionIndex);

  return connectDragSource(
    connectDropTarget(
      <div ref={value => (elementRef.current = value)}>
        <ChartOptionsGroup.Wrapper
          isLikeOption={isLikeOption}
          isDragging={isDragging}
          isHidden={
            (isInsideAvaliableArea && isDragging) ||
            (isDragging && !isShowDraggedGroup)
          }
        >
          <ChartOptionsGroup.Container isDragging={isDragging}>
            {groupOptions && !R.isEmpty(groupOptions) && (
              <ChartOptionsGroup.Content isDragging={isDragging}>
                <LeftOptionButton />
                <ChartOptionsGroup.OptionWrapper>
                  {groupOptions.map((option, i) => (
                    <ChartOptionsGroup.ItemWrapper
                      key={option.id}
                      isFirst={
                        i === 0 ||
                        (movedOption.groupId === currentGroup.groupId &&
                          ((movedOptionIndex === 0 && i === 1) ||
                            movedOptionIndex === i) &&
                          !isOver)
                      }
                      isNotExist={option.exists === false}
                    >
                      <GroupItem
                        color={getSeriesColor(option, seriesMapping)}
                        groupId={currentGroup.groupId}
                        groupIndex={index}
                        hoveredGroupIndex={hoveredGroupIndex}
                        hoveredOptionIndex={hoveredOptionIndex}
                        index={i}
                        isCorrentGroupHover={hoveredGroupIndex === index}
                        isGroupDragged={isDragging}
                        isShowOriginOption={isShowOriginOption}
                        itemType={ITEM_TYPES.selected}
                        onAddOptionToGroup={onAddOptionToGroup}
                        onChartTypeMenuOpen={onChartTypeMenuOpen}
                        onMoveOption={onMoveOption}
                        onSetHoveredOptionIndex={onGroupOptionHover}
                        onSetIsShowOriginOption={onSetIsShowOriginOption}
                        onSetMovedOption={onSetMovedOption}
                        onSetMovedOptionIndex={onSetMovedOptionIndex}
                        onStopOptionMoving={onStopOptionMoving}
                        onSwitchOptionVisibility={onSwitchOptionVisibility}
                        onStopHovering={onStopHovering}
                        option={option}
                        optionsList={options}
                        perentGroup={currentGroup}
                        setIsAnyOptionDraging={setIsAnyOptionDraging}
                        setTooltipPosition={setTooltipPosition}
                        setTooltipText={setTooltipText}
                        tempGroupIndex={tempGroupIndex}
                        isNotExist={option.exists === false}
                        setNewGroupIsCreated={setNewGroupIsCreated}
                      />
                    </ChartOptionsGroup.ItemWrapper>
                  ))}
                </ChartOptionsGroup.OptionWrapper>
                <ButtonTooltip content={removeSeriesAttributes}>
                  <ChartOptionsGroup.CloseButtonWrapper>
                    {groupOptions.map(
                      (option, i) =>
                        !(
                          movedOptionIndex === i &&
                          (!R.isNil(tempGroupIndex) ||
                            hoveredGroupIndex !== index)
                        ) && (
                          <ChartOptionsGroup.Button
                            key={option.id}
                            isFirst={
                              i === 0 ||
                              (movedOption.groupId === currentGroup.groupId &&
                                ((movedOptionIndex === 0 && i === 1) ||
                                  movedOptionIndex === i) &&
                                !isOver)
                            }
                            onClick={() =>
                              onRemoveOption(currentGroup.groupId, option)
                            }
                          >
                            {!(
                              movedOptionIndex === i ||
                              (hoveredGroupIndex === index &&
                                hoveredOptionIndex === i &&
                                (movedOption.groupId !== currentGroup.groupId ||
                                  (movedOption.groupId === '' && isOver)))
                            ) && <CloseIcon />}
                          </ChartOptionsGroup.Button>
                        ),
                    )}
                    {index === hoveredGroupIndex &&
                      R.isNil(tempGroupIndex) &&
                      !isDragging &&
                      !disabledAddButton &&
                      !isPerent && (
                        <ChartOptionsGroup.Button>
                          {hoveredOptionIndex !== groupOptions.length && (
                            <CloseIcon />
                          )}
                        </ChartOptionsGroup.Button>
                      )}
                  </ChartOptionsGroup.CloseButtonWrapper>
                </ButtonTooltip>
              </ChartOptionsGroup.Content>
            )}
          </ChartOptionsGroup.Container>
        </ChartOptionsGroup.Wrapper>
        {tooltipText && !isAnyOptionDraging ? (
          <LongNameMouseTooltip
            position={tooltipPosition}
            text={tooltipText}
            maxWidth={MAX_SELECTED_NAME_WIDTH}
          />
        ) : null}
      </div>,
    ),
  );
};

ChartOptionsGroup.Container = styled.div`
  border: ${props =>
    props.isDragging ? props.theme.borders.thingray : 'none'};
  margin: ${props => (props.isDragging ? '0' : '1px 0')};
`;

ChartOptionsGroup.Wrapper = styled.div`
  display: ${props => (props.isHidden ? 'none' : 'block')};
`;

ChartOptionsGroup.Content = styled.div`
  opacity: ${props => (props.isDragging ? 0 : 1)};
  background-color: #fbfbfb;
  display: grid;
  grid-template-columns: 15px auto 25px;
  border: ${props => props.theme.borders.thingray};
`;

ChartOptionsGroup.OptionWrapper = styled.div``;

ChartOptionsGroup.ItemWrapper = styled.div`
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};
  color: ${({ isNotExist }) => (isNotExist ? '#9a9a9a;' : '#484848;')};
`;

ChartOptionsGroup.CloseButtonWrapper = styled.div`
  display: grid;
`;

ChartOptionsGroup.Button = styled.button`
  padding: 0;
  width: 25px;
  min-height: 20px;
  height: 100%;
  border: none;
  border-left: ${props => props.theme.borders.thingray};
  outline: none;
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};

  > svg {
    width: 15px;
  }
`;

export default ChartOptionsGroup;
