import { approximatedSearchByDate } from '../approximatedSearchByDate';

export const sliceArrayByExtremeDates = <T>(
  array: T[],
  minDate: Date,
  maxDate: Date,
  compare: (a: Date, b: T) => number,
) => {
  return array.slice(
    approximatedSearchByDate(array, minDate, compare),
    approximatedSearchByDate(array, maxDate, compare),
  );
};
