import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import type { Action, Selector } from 'store/models';
import { getGraphqlPayload } from 'store/helpers';

import type { User } from './models/user';
import {
  CREATE_USER,
  DELETE_USER,
  FETCH_ALL,
  FETCH_USER,
  namespace,
  SET_IS_MFA_ENABLED,
  UPDATE_USER,
} from './UserActions';
import { Reducer } from 'redux';
const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'user';

type UserState = {
  [id: string]: User;
};

const initialState: UserState = {};

const UserReducer = (state: UserState = initialState, action: Action) => {
  switch (action.type) {
    case `${FETCH_USER}_SUCCESS`:
    case `${SET_IS_MFA_ENABLED}_SUCCESS`: {
      const userData = getGraphqlPayload(action);

      return R.assoc<User, UserState>(userData.id, userData, state);
    }
    case `${FETCH_ALL}_SUCCESS`: {
      const users = JSON.parse(getGraphqlPayload(action).users);
      const usersById = R.indexBy<User, any>(R.prop('id'), users);

      return usersById;
    }
    case `${UPDATE_USER}_SUCCESS`: {
      const user = getGraphqlPayload(action);

      return R.assoc<string, UserState, User>(user.id, user, state);
    }
    case UPDATE_USER: {
      const id = action.payload.graphql.variables.payload.id;
      const user = state[id];
      const newUser = { ...user, ...action.payload.graphql.variables.payload };

      return R.assoc<string, UserState, User>(user.id, newUser, state);
    }
    case `${CREATE_USER}_SUCCESS`: {
      const newUser = getGraphqlPayload(action);
      return R.assoc<string, UserState, User>(newUser.id, newUser, state);
    }
    case `${DELETE_USER}_SUCCESS`: {
      if (action.meta) {
        const userId = action.meta.previousAction.payload.graphql.variables.id;
        return R.omit([userId], state);
      }

      return state;
    }
    default: {
      return state;
    }
  }
};
export const getUsersState = (state: Record<string, any>) => state[STATE_KEY];
export const getUsers: Selector<{
  [id: string]: User;
}> = createSelector(getUsersState, usersState =>
  R.omit(['_persist'], usersState),
);
export const getUser = (_: Record<string, any>, id: string) =>
  R.pathOr({}, [STATE_KEY, id], _);
export const getUsersSortedByName = (_: Record<string, any>): User[] => {
  const usersById = R.omit(['_persist'], R.propOr({}, STATE_KEY, _));
  return R.values(usersById).sort((a, b) => {
    const aUserName = a.lastName + ' ' + a.firstName;
    const bUserName = b.lastName + ' ' + b.firstName;
    if (aUserName < bUserName) return -1;
    if (aUserName > bUserName) return 1;
    return 0;
  });
};
export const getUsersByEmailName: Selector<{
  [email: string]: User;
}> = createSelector(getUsers, users =>
  R.indexBy<string, Record<string, any>>(R.prop('emailName'), R.values(users)),
);

export default filterActions(UserReducer, action =>
  action.type.match(filterRegExp),
) as Reducer<UserState>;
