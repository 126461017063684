import { VARIANCE_TRELLIS } from 'modules/chart/models/chart';
import {
  OIL,
  GAS,
  WATER,
  BOE,
  GOR,
  GLR,
  WATERCUT,
  TOTAL_LIQUID,
} from 'modules/phase/models/phase';

export interface ChartOption {
  id: string;
  title: string;
  isShow: boolean;
  chartType?: string;
  customColor?: string;
  units?: string;
  color?: string;
  isAvailable?: boolean;
  exists?: boolean;
}

export interface AvailableDataSeries {
  id: string;
  title: string;
  isTemp?: boolean;
}

export interface ListChartOptions {
  [key: string]: ChartOption;
}

export interface DraggableOption {
  groupId: string;
  optionId: string;
}

export interface ChartOptionsGroup {
  groupId: string;
  options: string[];
}

export type GroupToDisplay = { ids: string[] }[];

export type DataSeriesGroupToDisplay = {
  dataSeriesGroup: {
    id: string;
    chartType: string | undefined;
    customColor: string | undefined;
  }[];
}[];

export const ItemTypes = {
  SERIES: 'series',
  GROUP: 'group',
};

export const BAR_CHART = 'barChart';
export const STEP_LINE_CHART = 'stepLineChart';

export const NOT_EXISTING_SENSOR_SERIES_MESSAGE =
  'This Sensor Data does not exist for this Well.';

export const NOT_EXISTING_CALCULATED_SERIES_MESSAGE =
  'This Calculated Data does not exist for this Well.';

export const ALL_CAV_GROUPS = [
  { groupId: BOE, options: [BOE] },
  { groupId: OIL, options: [OIL] },
  { groupId: GAS, options: [GAS] },
  { groupId: GOR, options: [GOR] },
  { groupId: GLR, options: [GLR] },
  { groupId: WATER, options: [WATER] },
  { groupId: WATERCUT, options: [WATERCUT] },
  { groupId: VARIANCE_TRELLIS, options: [VARIANCE_TRELLIS] },
];

export const coreSeries = {
  [BOE]: {
    id: BOE,
    title: `${BOE}, BOEPD`,
    isShow: false,
    isAvailable: true,
  },
  [OIL]: {
    id: OIL,
    title: `${OIL}, BOPD`,
    isShow: true,
    isAvailable: false,
  },
  [GAS]: {
    id: GAS,
    title: `${GAS}, MCFD`,
    isShow: true,
    isAvailable: false,
  },
  [GOR]: {
    id: GOR,
    title: `${GOR}, SCF/STB`,
    isShow: false,
    isAvailable: true,
  },
  [WATER]: {
    id: WATER,
    title: `${WATER}, BWPD`,
    isShow: false,
    isAvailable: true,
  },
  [WATERCUT]: {
    id: WATERCUT,
    title: `${WATERCUT}, %`,
    isShow: false,
    isAvailable: true,
  },
  [GLR]: {
    id: GLR,
    title: `${GLR}, SCF/BBL`,
    isShow: false,
    isAvailable: true,
  },
  [TOTAL_LIQUID]: {
    id: TOTAL_LIQUID,
    title: `Total Liquid, BPD`,
    isShow: false,
    isAvailable: true,
  },
  [VARIANCE_TRELLIS]: {
    id: VARIANCE_TRELLIS,
    title: `Variance`,
    isShow: false,
    isAvailable: true,
  },
};

export const OPTIONS_TYPES = {
  series: 'series',
  cav: 'CAV',
};

export const ITEM_TYPES = {
  available: 'AVAILABLE',
  selected: 'SELECTED',
  group: 'GROUP',
  singleOptionGroup: 'SINGLE_OPTION_GROUP',
};

export const PANEL_SIZES = {
  maxSelectedAreaHeight: 465,
  maxRibbonsAreaHeight: 168,
  minAvailableAreaHeight: 92,
  minSelectedAreaHeight: 45,
  minRibbonsAreaHeight: 86,
  titleBlock: 47,
  dragArea: 26,
  optionHeight: 20,
  labelHeight: 13,
};

export const CAV_SERIES_ORDER = {
  [BOE]: 0,
  [OIL]: 1,
  [GAS]: 2,
  [GOR]: 3,
  [WATER]: 4,
  [WATERCUT]: 5,
  [GLR]: 6,
  [VARIANCE_TRELLIS]: 7,
};

export const defaultOptionsGroup = [
  { groupId: OIL, options: [OIL as string] },
  { groupId: GAS, options: [GAS as string] },
];

export const MAX_AVAILABLE_NAME_WIDTH = 256;
export const MAX_SELECTED_NAME_WIDTH = 186;
