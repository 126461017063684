import { utcMinute } from 'd3-time';
import { fetchEventNotes } from 'modules/eventNotes/EventNotesActions';
import {
  OPEN_CAPACITY_DIALOG,
  OPEN_RIBBON_EVENT_DIALOG,
  OPEN_VARIANCE_DIALOG,
  setCurrentWellId,
  SET_RIGHT_PANEL_DIALOG,
} from 'modules/ui/UIActions';
import {
  getCurrentWellId,
  getRightPanelDialogType,
} from 'modules/ui/UIReducer';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { getGraphqlPayload } from 'store/helpers';

import {
  fetchAllLatestEventNotes,
  fetchSentLatestEventNotes,
  fetchIncomingLatestEventNotes,
  INIT_LATEST_EVENT_NOTES_FETCHING,
  REFETCH_LATEST_EVENT_NOTES,
  setSelectedNote,
  SET_ACTIVE_FILTER,
  RESET_STATE,
  fetchAllLatestEventNotesBackground,
  fetchIncomingLatestEventNotesBackground,
  fetchSentLatestEventNotesBackground,
  INIT_BACKGROUND_LATEST_EVENT_NOTES_FETCHING,
  GET_LATEST_UNREAD_NOTES,
  fetchLatestUnreadNotes,
  OPEN_CONVERSATION,
  openCapacityConversation,
  openRibbonConversation,
  openVarianceConversation,
  setUrlParameters,
  FETCH_INCOMING_LATEST_EVENT_NOTES,
  FETCH_ALL_LATEST_EVENT_NOTES,
  FETCH_SENT_LATEST_EVENT_NOTES,
} from './InboxConversationActions';
import {
  getActiveFilter,
  getFetchingState,
  getPageConfiguration,
  getLatestEventNotes,
} from './InboxConversationReducer';
import {
  NOTES_PER_PAGE,
  LatestEventNote,
  NoteFilter,
  NOTES_PER_INITIAL_LOAD,
} from './models';

function* openConversationSaga(action): Generator<any, any, any> {
  const currentWellId = yield select(getCurrentWellId);
  const note: LatestEventNote = action.payload;

  if (currentWellId !== note.wellId) {
    yield put(
      setCurrentWellId({ id: note.wellId, reason: 'CONVERSATION_REDIRECT' }),
    );
  }

  switch (note.eventType) {
    case 'capacity': {
      yield put(
        openCapacityConversation({ id: note.eventId, wellId: note.wellId }),
      );
      break;
    }
    case 'ribbon':
      yield put(openRibbonConversation({ id: note.eventId }));
      break;
    case 'variance': {
      yield put(
        openVarianceConversation({ id: note.eventId, wellId: note.wellId }),
      );
      break;
    }
    default:
      break;
  }

  if (note.urlSearch) {
    yield put(setUrlParameters(note.urlSearch));
  }
}

function* resetSelectedNoteSaga(action): Generator<any, any, any> {
  const newType = yield select(getRightPanelDialogType);

  if (
    ['VarianceChangeEvent', 'RibbonEvent', 'CapacityChangeEvent'].includes(
      newType,
    )
  )
    return;

  yield put(setSelectedNote(null));
}

function* setSelectedNoteSaga(action): Generator<any, any, any> {
  const eventId = action.payload.data.id;
  const eventTypeMatch = action.type.match(/(?<=OPEN_)[A-Z]+/);
  const eventType = eventTypeMatch[0]?.toLowerCase();

  if (
    !eventTypeMatch ||
    ['capacity', 'ribbon', 'variance'].includes(eventType) === false
  )
    return;

  const notes = yield select(getLatestEventNotes);
  const note = notes.find(
    n => n.eventType === eventType && n.eventId === eventId,
  );

  if (!note) return;

  yield put(setSelectedNote(note));
}

function* initLatestEventNotesFetchingSaga(action): Generator<any, any, any> {
  const filter: NoteFilter = yield select(getActiveFilter);
  const pageConfiguration = yield select(getPageConfiguration);
  const isFetching = yield select(getFetchingState);
  const notes = yield select(getLatestEventNotes);
  if (isFetching) return;

  if (action.type === REFETCH_LATEST_EVENT_NOTES) {
    const currentWellId = yield select(getCurrentWellId);
    yield put(fetchEventNotes(currentWellId));
  } else if (action.type === RESET_STATE && filter === 'all') {
    // no need to fetch since reset state doesn't clear notes for 'all' filter
    // for more info see https://github.com/WiseRock/ebs-app/pull/266
    return;
  } else if (action.type === SET_ACTIVE_FILTER && notes.length !== 0) {
    return;
  } else if (/SUCCESS/.test(action.type)) {
    const fetchedNotes = getGraphqlPayload(action);
    if (fetchedNotes.length < NOTES_PER_INITIAL_LOAD) return;
  }

  switch (filter) {
    case NoteFilter.all:
      yield put(fetchAllLatestEventNotes(pageConfiguration));
      break;
    case NoteFilter.incoming:
      yield put(fetchIncomingLatestEventNotes(pageConfiguration));
      break;
    case NoteFilter.sent:
      yield put(fetchSentLatestEventNotes(pageConfiguration));
      break;
  }
}

function* initBackgroundLatestEventNotesFetchingSaga(
  action,
): Generator<any, any, any> {
  const filter: NoteFilter = yield select(getActiveFilter);
  const notes = yield select(getLatestEventNotes);
  const isFetching = yield select(getFetchingState);

  if (isFetching || (action.type === SET_ACTIVE_FILTER && notes.length === 0))
    return;

  const getFromDate = () => {
    const now = new Date();

    if (notes.length === 0) return new Date(now.getTime() - 11 * 1000);

    if (action.type === SET_ACTIVE_FILTER)
      return utcMinute.offset(notes[notes.length - 1].noteTimestamp, -1);

    return notes[0].noteTimestamp;
  };

  const getCount = () => {
    if (action.type === SET_ACTIVE_FILTER) return notes.length;
    return NOTES_PER_PAGE;
  };

  const payload = {
    count: getCount(),
    fromDate: getFromDate(),
    page: 1,
  };

  const currentWellId = yield select(getCurrentWellId);
  yield put(fetchEventNotes(currentWellId));

  switch (filter) {
    case NoteFilter.all:
      yield put(fetchAllLatestEventNotesBackground(payload));
      break;
    case NoteFilter.incoming:
      yield put(fetchIncomingLatestEventNotesBackground(payload));
      break;
    case NoteFilter.sent:
      yield put(fetchSentLatestEventNotesBackground(payload));
      break;
  }
}

function* getLatestUnreadNotes(): Generator<any, any, any> {
  yield put(fetchLatestUnreadNotes());
}

function* getInitialAllLatestEventNoteFetchingSaga(
  action,
): Generator<any, any, any> {
  const pageConfiguration = yield select(getPageConfiguration);

  if (/SUCCESS/.test(action.type)) {
    const fetchedNotes = getGraphqlPayload(action);
    if (fetchedNotes.length < NOTES_PER_INITIAL_LOAD) return;
  }

  yield put(fetchAllLatestEventNotes(pageConfiguration));
}

function* inboxConversationSagas(): Generator<any, any, any> {
  yield all([
    yield takeLatest(OPEN_CONVERSATION, openConversationSaga),
    yield takeLatest(SET_RIGHT_PANEL_DIALOG, resetSelectedNoteSaga),
    yield takeLatest(
      [OPEN_CAPACITY_DIALOG, OPEN_VARIANCE_DIALOG, OPEN_RIBBON_EVENT_DIALOG],
      setSelectedNoteSaga,
    ),
    yield takeLatest(
      [
        SET_ACTIVE_FILTER,
        INIT_LATEST_EVENT_NOTES_FETCHING,
        REFETCH_LATEST_EVENT_NOTES,
        RESET_STATE,
      ],
      initLatestEventNotesFetchingSaga,
    ),
    yield takeLatest(
      [
        `${FETCH_ALL_LATEST_EVENT_NOTES}_SUCCESS`,
        `${FETCH_SENT_LATEST_EVENT_NOTES}_SUCCESS`,
        `${FETCH_INCOMING_LATEST_EVENT_NOTES}_SUCCESS`,
      ],
      initLatestEventNotesFetchingSaga,
    ),
    yield takeLatest(
      [INIT_BACKGROUND_LATEST_EVENT_NOTES_FETCHING, SET_ACTIVE_FILTER],
      initBackgroundLatestEventNotesFetchingSaga,
    ),
    yield takeLatest(
      [GET_LATEST_UNREAD_NOTES, REFETCH_LATEST_EVENT_NOTES],
      getLatestUnreadNotes,
    ),
    yield takeLatest(
      [`${FETCH_ALL_LATEST_EVENT_NOTES}_SUCCESS`],
      getInitialAllLatestEventNoteFetchingSaga,
    ),
  ]);
}

export default inboxConversationSagas;
