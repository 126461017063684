import { line, curveStepAfter } from 'd3-shape';
import { timeDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';

import { NormalizedSeries, SeriesMapping } from '../models';

interface SeriesStepsLineProps {
  data: NormalizedSeries[];
  currentSeriesMapping: SeriesMapping;
  customColor: string | undefined;
  xScale: any;
  yScale: any;
}

const SeriesStepsLine = ({
  data,
  currentSeriesMapping,
  customColor,
  xScale,
  yScale,
}: SeriesStepsLineProps) => {
  const defaultDataKey = `series${currentSeriesMapping.seriesIndex}`;
  const lastRealDate = R.pathOr(new Date(), [data.length - 1, 'day'], data);
  const lastDayData = R.pathOr({}, [data.length - 1], data);
  const newDate = timeDay.offset(lastRealDate, 1);
  const dataWithEmptyLastDay = React.useMemo(() => {
    if (
      !currentSeriesMapping.id.startsWith('s') &&
      !currentSeriesMapping.id.startsWith('c')
    )
      return [...data, { ...lastDayData, day: newDate }];

    return data;
  }, [currentSeriesMapping, data]);

  const createLine: (data: any) => any = React.useMemo(
    () =>
      line()
        .x((d: any) => xScale(new Date(d.day) || 0))
        .y(d => yScale(d[defaultDataKey] ?? d[currentSeriesMapping.id] ?? 0))
        .curve(curveStepAfter),

    [xScale, yScale, defaultDataKey, currentSeriesMapping],
  );
  return (
    <path
      className="panInteraction"
      stroke={customColor || currentSeriesMapping.color}
      fill="none"
      d={createLine(dataWithEmptyLastDay)}
      vectorEffect="non-scaling-stroke"
      strokeLinejoin="bevel"
      strokeWidth={1}
    />
  );
};

export default React.memo<SeriesStepsLineProps>(SeriesStepsLine);
