import * as React from 'react';
import styled from 'styled-components';

type KeyboardKeyProps = {
  children: React.ReactNode;
};

const KeyboardKey = ({ children }: KeyboardKeyProps) => {
  return <KeyboardKey.Wrapper>{children}</KeyboardKey.Wrapper>;
};

KeyboardKey.Wrapper = styled.span`
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  font-weight: bold;
  padding: 0 6px;
  border-radius: 4px;
  background-color: #efefef;
  color: #000000;
  border-bottom: 1px solid #c1c1c1;
`;

export default KeyboardKey;
