import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import styled from 'styled-components';

import SeriesPill from 'modules/chart/components/SeriesPill';

interface VarianceTrellisLoaderProps {
  height: number;
  pillText: string;
  isLast: boolean;
}

const VarianceTrellisLoader = ({
  isLast,
  height,
  pillText,
}: VarianceTrellisLoaderProps) => {
  return (
    <>
      <VarianceTrellisLoader.Container height={height} isLast={isLast}>
        <VarianceTrellisLoader.IndicatorWrapper>
          <CircularProgress color="inherit" />
        </VarianceTrellisLoader.IndicatorWrapper>
        <SeriesPill text={pillText} />
      </VarianceTrellisLoader.Container>
    </>
  );
};

VarianceTrellisLoader.Container = styled.div`
  width: 100%;
  height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 73;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

VarianceTrellisLoader.IndicatorWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default React.memo<VarianceTrellisLoaderProps>(VarianceTrellisLoader);
