import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { timeDay, timeYear } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

import { AppConfig } from 'modules/appConfig/models/appConfig';
import { BOE, OIL, GAS, WATER } from 'modules/phase/models/phase';
import useMetrics from 'modules/metrics/hooks/useMetrics';

import Button from 'components/Button';
import DateInput from 'components/DateInput';
import { MousePointer, Refresh } from 'components/Icons';

import useMouseDownOutside from 'hooks/useMouseDownOutside';
import useTiming from 'hooks/useTiming';

import { COMPARE_OPTION, DrilldownTableParams } from '../models/drilldownTable';
import { FaAngleDown } from 'react-icons/fa';
import {
  grossNetAttributes,
  phaseAttributes,
  RateVolumeAttributes,
  volumeSettingsAttributes,
  refreshAttributes,
  ROIAttributes,
} from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';
import { WellDrilldownSelect } from '../components/WellDrilldownSelect/WellDrilldownSelect';
import { useGetActiveLayout } from '../hooks/useGetActiveLayout';

const varianceTypesOptionsTitles = {
  [BOE]: 'BOE',
  [OIL]: 'Oil',
  [GAS]: 'Gas',
  [WATER]: 'Water',
};

const grossNetOptionsTitles = {
  gross: 'Gross',
  net: 'Net',
};

const volumeRateOptionsTitles = {
  volume: 'Volume',
  rate: 'Rate',
};

const buttonLabels = {
  grossNet: {
    Gross: 'Gross',
    Net: 'Net',
  },
  rateVolume: {
    Rate: 'Rate',
    Volume: 'Volume',
  },
  phase: {
    [OIL]: 'Oil',
    [GAS]: 'Gas',
    [WATER]: 'Water',
    [BOE]: 'BOE',
  },
};

type DrilldownTableFormProps = {
  appConfig: AppConfig;
  beforeSubmit: () => void;
  currentDrilldownTableState: DrilldownTableParams;
  hasNri: boolean;
  isActive: boolean;
  isLoadingWells: boolean;
  isLoadingVariance: boolean;
  regionOfInterestModeOn: () => void;
  setDrilldownTableOption: (option: string) => void;
  setMaxDrilldownTableDate: (date: Date) => void;
  setMinDrilldownTableDate: (date: Date) => void;
  setRateVolume: (rate: string) => void;
  setGrossNet: (grossNet: string) => void;
  onSetCompareOption: (optionKey: string) => void;
};

const DrilldownTableForm = ({
  appConfig,
  beforeSubmit,
  currentDrilldownTableState,
  hasNri,
  isActive,
  regionOfInterestModeOn,
  setGrossNet,
  setRateVolume,
  setMaxDrilldownTableDate,
  setMinDrilldownTableDate,
  onSetCompareOption,
  setDrilldownTableOption,
  isLoadingWells,
  isLoadingVariance,
}: DrilldownTableFormProps) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), []);
  const [menuTimer, setMenuTimer] = React.useState<any>(null);
  const metricts = useMetrics();
  const isCustomDrilldown = useGetActiveLayout();

  const maxAvailableDate = React.useMemo(() => {
    if (currentDrilldownTableState.compareOption === COMPARE_OPTION.extVsCap) {
      return timeYear.offset(appConfig.today, 5);
    }
    return timeDay.offset(appConfig.today, -1);
  }, [appConfig, currentDrilldownTableState]);

  const menuEl = React.useRef(null);

  const closeTrellisMenuWithTimeout = () =>
    setMenuTimer(setTimeout(closeMenu, 500));
  const onTrellisMenuHover = () => {
    if (menuTimer) clearTimeout(menuTimer);
  };

  const wellsRefreshTiming = useTiming({
    onStop: ({ duration }) => {
      const data = { duration };
      metricts.trackEvent('UI_REFRESH_DRILLDOWN_TABLE_WELLS_COMPLETE', data);
    },
  });

  const varianceRefreshTiming = useTiming({
    onStop: ({ duration }) => {
      const data = { duration };
      metricts.trackEvent('UI_REFRESH_DRILLDOWN_TABLE_VARIANCE_COMPLETE', data);
    },
  });

  const handleRefresh = React.useCallback(() => {
    if (isLoadingVariance || isLoadingWells) return;
    wellsRefreshTiming.start();
    varianceRefreshTiming.start();
    beforeSubmit();
  }, [beforeSubmit, isLoadingWells, isLoadingVariance]);

  React.useEffect(() => {
    if (!isLoadingWells) wellsRefreshTiming.stop();
  }, [wellsRefreshTiming.stop, isLoadingWells]);

  React.useEffect(() => {
    if (!isLoadingVariance) varianceRefreshTiming.stop();
  }, [varianceRefreshTiming.stop, isLoadingVariance]);

  useMouseDownOutside([menuEl], closeMenu);

  return (
    <DrilldownTableForm.FormWrapper>
      {isCustomDrilldown && (
        <WellDrilldownSelect
          currentDrilldownTableState={currentDrilldownTableState}
          onSetCompareOption={onSetCompareOption}
          setDrilldownTableOption={setDrilldownTableOption}
          setGrossNet={setGrossNet}
          setRateVolume={setRateVolume}
        />
      )}
      <form>
        {!isCustomDrilldown && (
          <Button width={30} height={26} onClick={regionOfInterestModeOn}>
            <MousePointer />
          </Button>
        )}
        <ButtonTooltip content={ROIAttributes}>
          <DrilldownTableForm.DateInputsContainer
            isCustomDrilldown={isCustomDrilldown}
            isActive={isActive}
          >
            <DateInput
              id="minDate"
              name="minDate"
              isActive={isActive}
              activeColor="primaryText"
              date={currentDrilldownTableState.minDate}
              max={currentDrilldownTableState.maxDate}
              min={new Date(0)}
              onProcess={date => {
                beforeSubmit();
                setMinDrilldownTableDate(date);
              }}
              tabIndex={2}
              transparent
            />
            <DrilldownTableForm.TextDash isActive={isActive} />
            <DateInput
              id="maxDate"
              name="maxDate"
              isActive={isActive}
              activeColor="primaryText"
              date={currentDrilldownTableState.maxDate}
              max={maxAvailableDate}
              min={currentDrilldownTableState.minDate}
              onProcess={date => {
                beforeSubmit();
                setMaxDrilldownTableDate(date);
              }}
              tabIndex={3}
              transparent
            />
          </DrilldownTableForm.DateInputsContainer>
        </ButtonTooltip>
        {isCustomDrilldown && (
          <Button width={30} height={26} onClick={regionOfInterestModeOn}>
            <MousePointer />
          </Button>
        )}

        {!isCustomDrilldown && (
          <div>
            <ButtonTooltip content={volumeSettingsAttributes}>
              <DrilldownTableForm.MenuButton
                style={{ width: '100%' }}
                height={26}
                onClick={() => setMenuOpen(true)}
              >
                <span>
                  {buttonLabels.grossNet[currentDrilldownTableState.grossNet]}{' '}
                  {buttonLabels.phase[currentDrilldownTableState.phase]}{' '}
                  {
                    buttonLabels.rateVolume[
                      currentDrilldownTableState.rateVolume
                    ]
                  }
                </span>

                <FaAngleDown />
              </DrilldownTableForm.MenuButton>
            </ButtonTooltip>

            {isMenuOpen && (
              <DrilldownTableForm.MenuWrapper
                ref={menuEl}
                onMouseLeave={closeTrellisMenuWithTimeout}
                onMouseEnter={onTrellisMenuHover}
              >
                <Paper>
                  <MenuItem>
                    <DrilldownTableForm.MenuItemLabel>
                      Gross/Net
                    </DrilldownTableForm.MenuItemLabel>
                    <ButtonTooltip content={grossNetAttributes}>
                      <select
                        id="grossNet"
                        name="grossNet"
                        value={currentDrilldownTableState.grossNet}
                        style={{ width: '100%' }}
                        onChange={e => {
                          setGrossNet(e.target.value);
                          e.target.blur();
                        }}
                      >
                        {Object.keys(grossNetOptionsTitles).map(optionTitle => (
                          <option
                            key={optionTitle}
                            value={grossNetOptionsTitles[optionTitle]}
                            disabled={optionTitle === 'net' && !hasNri}
                          >
                            {`${grossNetOptionsTitles[optionTitle]}${
                              optionTitle === 'net' && !hasNri
                                ? ' (Unavailable)'
                                : ''
                            }`}
                          </option>
                        ))}
                      </select>
                    </ButtonTooltip>
                  </MenuItem>
                  <MenuItem>
                    <DrilldownTableForm.MenuItemLabel>
                      Phase
                    </DrilldownTableForm.MenuItemLabel>
                    <ButtonTooltip content={phaseAttributes}>
                      <select
                        id="phase"
                        name="phase"
                        value={currentDrilldownTableState.phase}
                        onChange={e => {
                          setDrilldownTableOption(e.target.value);
                          e.target.blur();
                        }}
                        tabIndex={1}
                        style={{ width: '100%' }}
                      >
                        {Object.keys(varianceTypesOptionsTitles).map(
                          optionTitle => (
                            <option
                              key={optionTitle}
                              value={varianceTypesOptionsTitles[optionTitle]}
                            >
                              {varianceTypesOptionsTitles[optionTitle]}
                            </option>
                          ),
                        )}
                      </select>
                    </ButtonTooltip>
                  </MenuItem>
                  <MenuItem>
                    <DrilldownTableForm.MenuItemLabel>
                      Rate/Volume
                    </DrilldownTableForm.MenuItemLabel>
                    <ButtonTooltip content={RateVolumeAttributes}>
                      <select
                        id="rateVolume"
                        name="rateVolume"
                        value={currentDrilldownTableState.rateVolume}
                        onChange={e => {
                          setRateVolume(e.target.value);
                          e.target.blur();
                        }}
                        style={{ width: '100%' }}
                      >
                        {Object.keys(volumeRateOptionsTitles).map(
                          optionTitle => (
                            <option
                              key={optionTitle}
                              value={volumeRateOptionsTitles[optionTitle]}
                            >
                              {volumeRateOptionsTitles[optionTitle]}
                            </option>
                          ),
                        )}
                      </select>
                    </ButtonTooltip>
                  </MenuItem>
                </Paper>
              </DrilldownTableForm.MenuWrapper>
            )}
          </div>
        )}

        <ButtonTooltip content={refreshAttributes}>
          <DrilldownTableForm.RefreshButton
            width={30}
            height={26}
            onClick={handleRefresh}
            disabled={
              wellsRefreshTiming.isTicking || varianceRefreshTiming.isTicking
            }
          >
            <Refresh width={18} height={18} />
          </DrilldownTableForm.RefreshButton>
        </ButtonTooltip>
      </form>
    </DrilldownTableForm.FormWrapper>
  );
};

DrilldownTableForm.FormWrapper = styled.div`
  padding: 8px 8px;

  & > form {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: 1fr;
    gap: 8px;
  }
`;

DrilldownTableForm.PhaseRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  & select {
    width: 140px;
    margin-right: 10px;
  }
`;

DrilldownTableForm.Label = styled.p`
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 10px;
`;

DrilldownTableForm.VariancePhaseWrapper = styled.div`
  margin-bottom: 18px;
`;

DrilldownTableForm.DateRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & > input {
    width: 105px;
    padding-left: 10px;
  }
`;

DrilldownTableForm.DateInputsContainer = styled.div`
  height: 26px;
  display: flex;
  justify-content: ${props =>
    props.isCustomDrilldown ? 'center' : 'space-between'};
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border: ${(props: Record<string, any>) =>
    props.isActive
      ? '2px solid ' + props.theme.colors.primary
      : '1px solid #c1c1c1'};
  width: ${props => (props.isCustomDrilldown ? '356px' : '180px')};
  padding: ${(props: Record<string, any>) =>
    props.isActive ? '0' : '0 1px 0 1px'};
`;

DrilldownTableForm.TextDash = styled.div`
  width: 5px;
  height: 1px;
  border-top: ${(props: Record<string, any>) =>
    `2px solid ${props.theme.colors.primaryText}`};
  margin-right: 4px;
`;

DrilldownTableForm.MenuWrapper = styled.div`
  position: absolute;
  top: 85px;
  right: 0;
  pointer-events: auto;
  z-index: 999;
`;

DrilldownTableForm.RefreshButton = styled(Button)`
  cursor: ${(props: Record<string, any>) =>
    props.disabled ? 'wait' : 'pointer'};
`;

DrilldownTableForm.MenuButton = styled(Button)`
  justify-content: space-between;
  padding-left: 5px;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 1px;
  }
`;

DrilldownTableForm.MenuItemLabel = styled.div`
  width: 200px;
  margin-left: 10px;
  padding: 10px 0;
`;

DrilldownTableForm.FormControlLabel = styled(FormControlLabel)`
  position: relative;
  top: -3px;
  margin: 0 0 3px 0 !important;
  padding: 0 24px 0 0 !important;
  width: 100%;
  line-height: 1em !important;
  height: 30px;
  justify-content: center;
  & .MuiTypography-root {
    font-size: 0.875rem !important;
  }

  & > label {
    width: 100%;
  }
`;

export default DrilldownTableForm;
