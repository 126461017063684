import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import copyDataToClipboard from 'helpers/copyDataToClipboard';
import * as React from 'react';
import { useSelector } from 'store/models';
import styled from 'styled-components';
import { getDrilldownTableParams } from '../DrilldownTableReducer';
import {
  COMPARE_OPTION,
  CustomDrilldownTableDataKeys,
} from '../models/drilldownTable';

const normalizeTableHeaders = (
  dataKeys: readonly string[],
  compareOption: string,
) => {
  if (compareOption === COMPARE_OPTION.actual) return dataKeys;

  if (compareOption === COMPARE_OPTION.extVsCap) {
    return dataKeys.map(key => {
      if (key === 'secondCol') return 'RE Forecast';
      if (key === 'thirdCol') return 'Capacity';
      return key;
    });
  } else if (compareOption === COMPARE_OPTION.actVsExt) {
    return dataKeys.map(key => {
      if (key === 'secondCol') return 'Actual';
      if (key === 'thirdCol') return 'RE Forecast';
      return key;
    });
  }
};

const cropCapacityAndVarianceColumns = (dataKeys: string[]) =>
  dataKeys.slice(0, 2);

const normalizeCustomTableHeaders = (
  dataKeys: readonly string[],
  secondColTitle,
) => {
  return dataKeys.map(key =>
    key === CustomDrilldownTableDataKeys.value ? secondColTitle : key,
  );
};

const generateTSVString = (
  data,
  dataKeys,
  header,
  compareOption,
  customTableHeader,
) => {
  if (customTableHeader) dataKeys = cropCapacityAndVarianceColumns(dataKeys);

  const normalizedDataKeys = customTableHeader
    ? normalizeCustomTableHeaders(dataKeys, customTableHeader)
    : normalizeTableHeaders(dataKeys, compareOption);
  const columnHeaders = header ? header : normalizedDataKeys;

  const firstLine = columnHeaders
    .map(key => key[0].toUpperCase() + key.slice(1))
    .join('\t');

  const rows = data
    .map(datarow => {
      return dataKeys
        .map((dataKey, i) => {
          if (i !== 0) return datarow[dataKey];
          return datarow.planType
            ? datarow[dataKey] + ', ' + datarow.planType
            : datarow[dataKey];
        })
        .join('\t');
    })
    .join('\r\n');
  const result = firstLine + '\r\n' + rows;

  return result;
};

interface ContextMenuProps {
  closeMenu: () => void;
  contextPosition: { clientX: number; clientY: number } | null;
  data: any;
  dataKeys: string[];
  header?: string[];
  onMenuHover: () => void;
  onMenuLeave: () => void;
  customTableHeader?: string | false;
}

const ContextMenu = (
  {
    closeMenu,
    contextPosition,
    data,
    dataKeys,
    header,
    onMenuHover,
    onMenuLeave,
    customTableHeader,
  }: ContextMenuProps,
  ref,
) => {
  const params = useSelector(getDrilldownTableParams);
  const copyString = generateTSVString(
    data,
    dataKeys,
    header,
    params.compareOption,
    customTableHeader,
  );
  const copyToClipboard = () => {
    copyDataToClipboard(copyString);
    closeMenu();
  };

  return (
    <ContextMenu.ContextMenuWrapper
      ref={ref}
      onMouseLeave={onMenuLeave}
      onMouseEnter={onMenuHover}
      x={contextPosition ? contextPosition.clientX : 0}
      y={contextPosition ? contextPosition.clientY : 0}
      onClick={copyToClipboard}
    >
      <ContextMenu.StyledPaper>
        <ContextMenu.MenuList>
          <ContextMenu.MenuItem>
            Copy Table Contents to Clipboard
          </ContextMenu.MenuItem>
        </ContextMenu.MenuList>
      </ContextMenu.StyledPaper>
    </ContextMenu.ContextMenuWrapper>
  );
};

ContextMenu.ContextMenuWrapper = styled.div`
  position: fixed;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  pointer-events: auto;
  z-index: 999;
`;

ContextMenu.StyledPaper = styled(Paper)`
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.5) !important;
`;

ContextMenu.MenuList = styled(MenuList)``;

ContextMenu.MenuItem = styled(MenuItem)`
  min-width: 175px;
  &:hover {
    background-color: #e7e7e7 !important;
  }
`;

export default React.forwardRef<HTMLElement, ContextMenuProps>(ContextMenu);
