import * as React from 'react';

import {
  RIBBON_HEIGHT,
  Y_AXIS_WIDTH,
  VARIANCE_TRELLIS,
} from 'modules/chart/models/chart';
import styled from 'styled-components';

import { getCoreSeriesTitle } from 'modules/chart/utils';
import SeriesPill, {
  SeriesPillListContainer,
} from 'modules/chart/components/SeriesPill';
import { ChartOption } from 'modules/chartOptions/ChartOptionsSagas';
import { ListChartOptions } from 'modules/chartOptions/models';
import { NormalizedSeriesMapping } from 'modules/series/models';

interface LoadingPlaceholderProps {
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
  };
  cavSeries: ChartOption[];
  seriesMapping: NormalizedSeriesMapping;
  chartOptions: ListChartOptions;
  groupToDisplay: { ids: string[] }[];
  ribbonCount: number;
  selectedRibbonsData: { id: string; name: string; order: number }[];
  trellisHeight: number;
}

const LoadingPlaceholder = ({
  drilldownTableParams,
  cavSeries,
  chartOptions,
  seriesMapping,
  groupToDisplay,
  ribbonCount,
  selectedRibbonsData,
  trellisHeight,
}: LoadingPlaceholderProps) => {
  const onGetPillText = (id: string) => {
    if (id === VARIANCE_TRELLIS) {
      return getCoreSeriesTitle(drilldownTableParams.phase, true);
    }
    return getCoreSeriesTitle(id);
  };

  return (
    <LoadingPlaceholder.Wrapper>
      {cavSeries.map((series, i) => (
        <LoadingPlaceholder.Container key={i} height={trellisHeight}>
          <LoadingPlaceholder.YAxisBorder />
          <SeriesPill text={onGetPillText(series.id)} />
        </LoadingPlaceholder.Container>
      ))}

      {groupToDisplay.map((group, i) => (
        <LoadingPlaceholder.Container key={i} height={trellisHeight}>
          <LoadingPlaceholder.YAxisBorder />
          <SeriesPillListContainer>
            {group.ids.map(id => (
              <SeriesPill
                key={id}
                color={
                  chartOptions[id]?.customColor || seriesMapping[id]?.color
                }
                text={seriesMapping[id]?.displayName}
              />
            ))}
          </SeriesPillListContainer>
        </LoadingPlaceholder.Container>
      ))}

      {!!ribbonCount && (
        <LoadingPlaceholder.RibbonWrapper ribbonCount={ribbonCount}>
          {selectedRibbonsData.map((ribbon, i) => (
            <LoadingPlaceholder.RibbonPlaceholder key={i}>
              <LoadingPlaceholder.YAxisBorder />
            </LoadingPlaceholder.RibbonPlaceholder>
          ))}
        </LoadingPlaceholder.RibbonWrapper>
      )}
    </LoadingPlaceholder.Wrapper>
  );
};

LoadingPlaceholder.Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

LoadingPlaceholder.RibbonWrapper = styled.div`
  height: ${props => props.ribbonCount * RIBBON_HEIGHT}px;
  z-index: 75;
  position: absolute;
  width: 100%;
  bottom: -2px;
  border-bottom: 1px solid black;
`;

LoadingPlaceholder.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: ${(props: Record<string, any>) =>
    (50 / (props.index + 1)).toFixed(0)};
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
  font-size: 12px;
  font-weight: 600;
`;

LoadingPlaceholder.YAxisBorder = styled.div`
  height: 100%;
  width: ${Y_AXIS_WIDTH + 1}px;
  border-right: 1px solid black;
`;

LoadingPlaceholder.RibbonPlaceholder = styled.div`
  height: ${RIBBON_HEIGHT}px;
  display: flex;
  cursor: pointer;
`;

export default React.memo<LoadingPlaceholderProps>(LoadingPlaceholder);
