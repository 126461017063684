import * as R from 'ramda';
import { CHARTS_OPTIONS } from 'modules/chart/models/chart';
import { OIL, GAS, WATER, BOE } from 'modules/phase/models/phase';

export const getCoreSeriesTitle = (
  seriesId: string,
  isVariance = false,
): string => {
  const title = R.pathOr('', [seriesId, 'title'], CHARTS_OPTIONS);
  return !isVariance ? title : `${title} Variance`;
};

export const getCoreSeriesDetailedTitle = (
  seriesId: string,
  grossOrNet: string,
  isVariance = false,
): string => {
  const grossNet = [BOE, OIL, GAS, WATER].includes(seriesId)
    ? grossOrNet + ' '
    : '';

  const title = getCoreSeriesTitle(seriesId, isVariance);
  const units = R.pathOr('', [seriesId, 'units'], CHARTS_OPTIONS);
  return `${grossNet + title}, ${units}`;
};

export default getCoreSeriesTitle;
