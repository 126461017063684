import * as React from 'react';
import styled from 'styled-components';
import ButtonTooltip from '../../../components/ButtonTooltip/ButtonTooltip';
import {
  NOT_EXISTING_CALCULATED_SERIES_MESSAGE,
  NOT_EXISTING_SENSOR_SERIES_MESSAGE,
} from '../../chartOptions/models';

interface SeriesPillProps {
  id?: string;
  color?: string;
  text: string;
  isNotExist?: boolean;
  tooltipTitle?: string;
  tooltipDescription?: string;
}

export const SeriesPill = (
  {
    color,
    text,
    isNotExist,
    id,
    tooltipTitle,
    tooltipDescription,
  }: SeriesPillProps,
  ref,
) => {
  const tooltipContent = React.useMemo(() => {
    const title = tooltipTitle;
    const description = tooltipDescription;
    if (title) return { title, description };
  }, [tooltipTitle, tooltipDescription]);

  return (
    <ButtonTooltip content={tooltipContent} ignoreFeatureFlag>
      <SeriesPill.Container
        ref={ref}
        isNotExist={isNotExist}
        color={color}
        $title={
          isNotExist &&
          (id && /s/.test(id)
            ? NOT_EXISTING_SENSOR_SERIES_MESSAGE
            : NOT_EXISTING_CALCULATED_SERIES_MESSAGE)
        }
      >
        {text}
      </SeriesPill.Container>
    </ButtonTooltip>
  );
};

SeriesPill.Container = styled.div`
  color: ${({ isNotExist, color }) =>
    isNotExist ? '#9a9a9a' : color || '#484848'};
  text-decoration: ${({ isNotExist }) =>
    isNotExist ? 'line-through' : 'none'};
  white-space: nowrap;
  position: absolute;
  right: 3px;
  top: 3px;
  padding: 3px;
  line-height: 9px;
  border: ${props => `1px solid ${props?.color || '#484848'}`};
  border-radius: 4px;
  background: white;
  font-weight: 600;
  font-size: 12px;
  z-index: 100;
`;

export const SeriesPillListContainer = styled.div`
  max-width: 100%;
  padding: 3px 3px 0 0;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  overflow: hidden;

  > div {
    position: initial;
    margin-left: 3px;
  }
`;

export default React.memo<SeriesPillProps & { ref?: any }>(
  React.forwardRef<Element, SeriesPillProps>(SeriesPill),
);
