import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'store/models';
import { format as d3Format } from 'd3-format';

import useComponentSize from 'hooks/useComponentSize';
import { DataSeriesTooltipData, TooltipData } from 'modules/ui/models/ui';

import { Series, NormalizedSeriesMapping, NormalizedSeries } from '../models';
import SeriesBars from './SeriesBars';
import SeriesStepsLine from './SeriesStepsLine';
import { BAR_CHART, ListChartOptions } from 'modules/chartOptions/models';
import SeriesSelectedBar from './SeriesSelectedBar';
import {
  getCalculatedSeriesAvailableDates,
  getSensorSeriesAvailableDates,
} from '../SeriesReducer';
import getSeriesData from '../utils/getSeriesData';
import { getYAxisHovered } from 'modules/ui/UIReducer';
import { measureText } from 'modules/filter/utils/text';

interface SVGSeriesTrellisProps {
  chartOptions: ListChartOptions;
  groupOptions: string[];
  height: number;
  onSetTooltipData: (tooltipData: DataSeriesTooltipData | null) => void;
  series: { [key: string]: NormalizedSeries[] };
  seriesMapping: NormalizedSeriesMapping;
  displayMaxDataPoint: number;
  dataMap: { [id: string]: Series[] };
  tooltipData: TooltipData | null;
  xScale: any;
  yAxisLinePos: number | null;
  yScale: any;
  format: any;
}

const SVGSeriesTrellis = ({
  chartOptions,
  dataMap,
  groupOptions,
  height,
  onSetTooltipData,
  series,
  seriesMapping,
  displayMaxDataPoint,
  tooltipData,
  xScale,
  yAxisLinePos,
  yScale,
  format,
}: SVGSeriesTrellisProps) => {
  const yAxisHovered = useSelector(getYAxisHovered);

  const hasSeries = series && !R.isEmpty(series);
  const svgEl: React.RefObject<HTMLElement | null> = React.useRef(null);
  const svgBoundingRect = useComponentSize(svgEl);
  const availableSensorSeriesDates = useSelector(getSensorSeriesAvailableDates);
  const availableCalculatedSeriesDates = useSelector(
    getCalculatedSeriesAvailableDates,
  );
  const cursorRate = React.useMemo(
    () => d3Format(format)(yScale.invert(yAxisLinePos)),
    [yAxisLinePos, format, yScale],
  );

  const backgroundTextWidth = React.useMemo(
    () => measureText(cursorRate, 12) + 3,
    [cursorRate],
  );

  return (
    <svg
      //@ts-expect-error
      ref={svgEl}
      className="trellis_chart series"
      height="100%"
      preserveAspectRatio="none"
      viewBox={`0 0 ${xScale.range()[1]} ${height}`}
      width={xScale.range()[1]}
      shapeRendering="geometricPrecision"
    >
      {!!displayMaxDataPoint && (
        <>
          {hasSeries &&
            groupOptions.map(id =>
              chartOptions[id].chartType === BAR_CHART ? (
                <SeriesBars
                  key={id}
                  data={getSeriesData(id, series)}
                  currentSeriesMapping={seriesMapping[id]}
                  customColor={chartOptions[id].customColor}
                  xScale={xScale}
                  yScale={yScale}
                />
              ) : (
                <SeriesStepsLine
                  key={id}
                  data={getSeriesData(id, series)}
                  currentSeriesMapping={seriesMapping[id]}
                  customColor={chartOptions[id].customColor}
                  xScale={xScale}
                  yScale={yScale}
                />
              ),
            )}

          {yAxisLinePos && (
            <g>
              <g>
                <rect
                  x={4}
                  y={yAxisLinePos - 16}
                  width={backgroundTextWidth}
                  height={16}
                  fill="#ffffff"
                />
                <text
                  fontSize={12}
                  style={{
                    userSelect: 'none',
                  }}
                  textAnchor="start"
                  x={5}
                  y={yAxisLinePos - 4}
                  fill="#c42424"
                >
                  {cursorRate}
                </text>
              </g>
              <line
                stroke="#c42424"
                strokeWidth={1}
                x1={0}
                x2={xScale.range()[1]}
                y1={yAxisLinePos}
                y2={yAxisLinePos}
                vectorEffect="non-scaling-stroke"
              />
            </g>
          )}

          {displayMaxDataPoint < 0 && (
            <line
              stroke="#000000"
              strokeWidth={1}
              x1={0}
              x2={xScale.range()[1]}
              y1={yScale(0)}
              y2={yScale(0)}
            />
          )}

          {!yAxisHovered && (
            <SeriesSelectedBar
              chartOptions={chartOptions}
              dataMap={dataMap}
              groupOptions={groupOptions}
              onSetTooltipData={onSetTooltipData}
              seriesMapping={seriesMapping}
              svgBoundingRect={svgBoundingRect}
              tooltipData={tooltipData}
              xScale={xScale}
              yScale={yScale}
              availableSensorSeriesDates={availableSensorSeriesDates}
              availableCalculatedSeriesDates={availableCalculatedSeriesDates}
            />
          )}
        </>
      )}
    </svg>
  );
};

export default SVGSeriesTrellis;
