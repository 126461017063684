import React from 'react';
import { useDispatch } from 'react-redux';

import { MetricsEventsKey } from '../models';
import * as MetricsActions from '../MetricsActions';

const useMetrics = () => {
  const dispatch = useDispatch();

  const captureEvent = React.useCallback(
    (key: MetricsEventsKey, data?: Record<string, any>) => {
      dispatch(MetricsActions.captureEvent({ key, data }));
    },
    [dispatch],
  );

  return {
    captureEvent,
    trackEvent: captureEvent, // alias
  };
};

export default useMetrics;
