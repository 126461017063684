import {
  NormalizedSensorSeriesMapping,
  NormalizedSeries,
  SensorSeries,
} from '../models';

export const normalizeSensorSeries = (
  data: SensorSeries[],
  wellId: string,
  seriesMapping: NormalizedSensorSeriesMapping,
): NormalizedSeries[] => {
  const timeOffset = new Date().getTimezoneOffset() * -1;

  return data.reduce<NormalizedSeries[]>((acc, d) => {
    const mapping = Object.values(seriesMapping).find(m =>
      m.tags.some(t => t.tagId === d.tag && t.wellId === wellId),
    );

    if (!mapping) return acc;

    //@ts-ignore
    acc.push({
      day: offsetDate(new Date(d.recordedAt), timeOffset),
      [mapping.sensorSeriesId]: d.value,
    });

    return acc;
  }, []);
};

export const offsetDate = (date: Date, offsetMinutes: number): Date => {
  const time = date.getTime();
  const offsetDate = new Date();
  offsetDate.setTime(time + offsetMinutes * 60 * 1000);
  return offsetDate;
};
