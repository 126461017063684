import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentGroup, setCurrentWellId } from 'modules/ui/UIActions';
import { getGroupMode } from 'modules/ui/UIReducer';
import { getFlagsStateMap } from 'modules/featureFlags/FeatureFlagsReducer';
import {
  addSeveralMarkedRows,
  addMarkedRow,
  removeMarkedRow,
  markAllRows,
  resetMarkedRows,
} from '../DrilldownTableActions';
import {
  getCurrentMarkedRows,
  getIsMarkingRows,
} from '../DrilldownTableReducer';

export type RowData = { [key: string]: string };

export const useDrilldownTableMarking = () => {
  const dispatch = useDispatch();
  const groupMode = useSelector(getGroupMode);
  const isMarkingRows = useSelector(getIsMarkingRows);
  const markedRows = useSelector(getCurrentMarkedRows);
  const isAllowedToMark = useSelector(getFlagsStateMap).drilldownMarking;
  const groupSubject = groupMode.subject || 'well';

  React.useEffect(() => {
    if (isAllowedToMark) return;
    dispatch(resetMarkedRows());
  }, [isAllowedToMark]);

  const isRowMarked = React.useCallback(
    (rowData: RowData) => markedRows.includes(rowData[groupSubject]),
    [markedRows, groupSubject],
  );

  const isRowIgnored = React.useCallback(
    (rowData: RowData) => rowData[groupSubject] === 'TOTAL',
    [markedRows, groupSubject],
  );

  const selectRow = React.useCallback(
    (rowData: RowData) => {
      if (!groupMode.isOn)
        return dispatch(
          setCurrentWellId({
            id: rowData.wellId as string,
            reason: 'WELL_SWITCH',
          }),
        );

      dispatch(
        setCurrentGroup({
          subject: groupMode.subject || '',
          item: rowData[groupMode.subject || ''] as string,
        }),
      );
    },
    [groupMode],
  );

  const markSeveralRows = React.useCallback(
    (rows: RowData[]) => {
      if (!isAllowedToMark || rows.length === 1) return;

      const rowsToMark: string[] = [];

      rows.forEach(row => {
        const rowName = row[groupSubject] as string;
        if (rowName === 'TOTAL') return;
        if (markedRows.includes(rowName)) return;
        rowsToMark.push(rowName);
      });

      if (rowsToMark.length === 0) return;

      dispatch(addSeveralMarkedRows(rowsToMark));
    },
    [groupSubject, markedRows, isAllowedToMark],
  );

  const markAll = React.useCallback(() => {
    if (!isAllowedToMark) return;

    dispatch(markAllRows());
  }, []);

  const markRow = React.useCallback(
    (rowData: RowData) => {
      const rowName = rowData[groupSubject] as string;
      if (!isAllowedToMark || markedRows.includes(rowName)) return;
      dispatch(addMarkedRow(rowName));
    },
    [groupSubject, markedRows, isAllowedToMark],
  );

  const unmarkRow = React.useCallback(
    (rowData: RowData) => {
      if (!isAllowedToMark || !isMarkingRows) return;
      dispatch(removeMarkedRow(rowData[groupSubject] as string));
    },
    [isMarkingRows, isAllowedToMark, groupSubject],
  );

  const state = React.useMemo(
    () => ({
      selectRow,
      markSeveralRows,
      markAll,
      markRow,
      unmarkRow,
      isRowMarked,
      isRowIgnored,
      isAllowedToMark,
      isMarkingRows,
      currentMarkedRows: markedRows,
    }),
    [
      selectRow,
      markSeveralRows,
      markAll,
      markRow,
      unmarkRow,
      isAllowedToMark,
      isMarkingRows,
      isRowMarked,
      markedRows,
    ],
  );

  return state;
};
