export type SnackbarNotificationType = 'Info' | 'Warning' | 'Error' | 'Success';

export const NotificationType = {
  Info: 'Info',
  Warning: 'Warning',
  Error: 'Error',
  Success: 'Success',
};

export type SnackbarNotification = {
  message: string;
  level?: SnackbarNotificationType;
};

export type SnackbarNotificationPayload = {
  message: string;
  level?: SnackbarNotificationType;
  duration?: number;
};

export const ERROR_MESSAGES_LIST = {
  toketExpired: 'Token has expired',
  passwordChange: 'Must change password',
  inactiveUser: 'User is inactive',
  notAuthorised: 'User is not authorised',
  couldNotUpdateAttribute: 'Error: Could not update custom attribute values',
};

export const DEFAULT_NOTIFICATION_DURATION = 10000;
export const NOTIFICATION_CLOSE_DURATION = 300;
