import * as React from 'react';
import { ExtendedTabInfo } from 'components/Tabs/Model';
import { Tab } from './Tab';
import styled from 'styled-components';
import { SortAmountDownIcon } from 'components/Icons';
import { drilldownTabAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';
import { useDispatch } from 'react-redux';
import { setLayoutConfiguration } from 'modules/drilldownTable/DrilldownTableActions';
import { DrillDownDropDown } from './DrillDownDropDown';
import { DrilldownTableLayoutConfigOptions } from 'modules/drilldownTable/DrilldownTableReducer';
import { useDrillDownLayout } from 'modules/dashboard/hooks/useDrillDownLayout';

const layoutOptions = [
  { id: 'standard', text: 'Standard Drilldown Layout' },
  { id: 'custom', text: 'Custom Drilldown Layout (beta)' },
] as const;

export const Drilldown = ({ tab }: { tab?: ExtendedTabInfo }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const isCustomDrilldownActive = useDrillDownLayout();
  const dispatch = useDispatch();

  const onSelectOption = (name: DrilldownTableLayoutConfigOptions) => {
    dispatch(setLayoutConfiguration(name));
  };

  return tab ? (
    <ButtonTooltip content={drilldownTabAttributes}>
      <Drilldown.Tab
        onMouseLeave={() => setIsMenuOpen(false)}
        onClick={tab.openTab}
        isActive={tab.isActive}
      >
        {isCustomDrilldownActive && (
          <DrillDownDropDown
            isOpen={isMenuOpen}
            onToggleOpen={() => setIsMenuOpen(open => !open)}
            onSelectOption={onSelectOption}
            options={layoutOptions}
          />
        )}
        <Drilldown.TabName>{tab.tabName}</Drilldown.TabName>
        <SortAmountDownIcon width={19} height={19} fill="#484848" />
      </Drilldown.Tab>
    </ButtonTooltip>
  ) : null;
};

Drilldown.TabName = styled.div`
  margin-right: 6px;
  margin-left: 10px;
`;

Drilldown.Tab = styled(Tab)`
  position: relative;
`;
