import { dateTimeTransform } from 'helpers/transformers/dateTimeTransformer';
import reduxLocalStorage from 'redux-persist/lib/storage';
import reduxSessionStorage from 'redux-persist/lib/storage/session';

export const rootPersistConfig = {
  key: 'root',
  storage: reduxLocalStorage,
  whitelist: [
    'category',
    'phase',
    'allocIssueStatus',
    'allocIssueCause',
    'filter',
  ],
  blacklist: ['appConfig'],
};

export const appConfigPersistConfig = {
  key: 'appConfig',
  storage: reduxLocalStorage,
  transforms: [dateTimeTransform],
  blacklist: ['loomJWS', 'loginBanner'],
};

export const authPersistConfig = {
  key: 'auth',
  storage: reduxLocalStorage,
  blacklist: ['isFreshLogin'],
};

export const chartOptionsPersistConfig = {
  key: 'chartOptions',
  storage: reduxLocalStorage,
};

export const seriesLayoutConfig = {
  key: 'seriesLayout',
  storage: reduxLocalStorage,
  whitelist: ['currentLayout'],
};

export const userPersistConfig = {
  key: 'user',
  storage: reduxLocalStorage,
};

export const drilldownTablePersistConfig = {
  key: 'drilldownTable',
  storage: reduxLocalStorage,
  whitelist: ['params', 'sortDirection', 'sortCriteria', 'layoutConfiguration'],
};

export const filtersLayoutConfig = {
  key: 'FiltersLayouts',
  storage: reduxLocalStorage,
};

export const wellPersistConfig = {
  key: 'well',
  storage: reduxSessionStorage,
  whitelist: ['columnMapping', 'groups', 'wells'],
};

export const UIReducerConfig = {
  key: 'ui',
  storage: reduxSessionStorage,
  whitelist: ['ribbons', 'isFilterTableView', 'isOpenFilterLayoutsPanel'],
};

export const seriesReducerConfig = {
  key: 'series',
  storage: reduxSessionStorage,
  whitelist: ['selectedSeriesMapping'],
};

export const forecastConfig = {
  key: 'externalForecast',
  storage: reduxSessionStorage,
  whitelist: ['forecastVisibility'],
};

export const featureFlagsConfig = {
  key: 'featureFlags',
  storage: reduxLocalStorage,
  whitelist: ['allFlags', 'localValues'],
};
