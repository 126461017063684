import { utcDay, utcMonth, utcYear } from 'd3-time';
import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import { getToday } from 'modules/appConfig/AppConfigReducer';
import { SET_CURRENT_WELL_ID } from 'modules/ui/UIActions';
import type { Action, RootState, Selector } from 'store/models';

import {
  CHANGE_EXTREME_DATES,
  CHANGE_EXTREME_DATES_BY_DAGGING,
  namespace,
  SET_INITIAL_EXTREME_DATES,
  UNDO_EXTREME_DATES_CHANGE,
  POPULATE_PRODUCTION,
  SET_DATA_AVAILIBILITY,
} from './ProductionActions';
import { ProductionPoint } from './models/production';
import { Reducer } from 'redux';

const filterRegExp = new RegExp(`${namespace}/|${SET_CURRENT_WELL_ID}`);
export const STATE_KEY = 'production';

export type ProductionState = {
  extremeDates: {
    min: Date;
    max: Date;
  }[];
  production: ProductionPoint[];
  dataAvailibility: {
    [wellId: string]: boolean;
  };
};

const initialState: ProductionState = {
  extremeDates: [],
  production: [],
  dataAvailibility: {},
};

const ProductionReducer = (
  state: ProductionState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case POPULATE_PRODUCTION: {
      const { data } = action.payload;
      return R.assoc<ProductionPoint[], ProductionState>(
        'production',
        data,
        state,
      );
    }
    case SET_INITIAL_EXTREME_DATES:
    case CHANGE_EXTREME_DATES_BY_DAGGING:
    case CHANGE_EXTREME_DATES: {
      const newExtremeDates = action.payload;
      const { min, max } = newExtremeDates;

      return R.assoc<{ min: Date; max: Date }[], ProductionState>(
        'extremeDates',
        R.append<{ min: Date; max: Date }, any>(
          { min, max },
          state.extremeDates,
        ),
        state,
      );
    }
    case UNDO_EXTREME_DATES_CHANGE: {
      if (state.extremeDates.length > 1) {
        return R.assoc<{ min: Date; max: Date }[], ProductionState>(
          'extremeDates',
          R.dropLast(1, state.extremeDates),
          state,
        );
      }
      return state;
    }
    case SET_DATA_AVAILIBILITY: {
      const { wellId, fromDay } = action.payload;

      return R.assocPath(['dataAvailibility', wellId], fromDay, state);
    }
    case SET_CURRENT_WELL_ID: {
      return R.assoc<ProductionPoint[], ProductionState>(
        'production',
        [],
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getExtremeDates = (
  state: RootState,
): {
  min: Date;
  max: Date;
} => {
  return R.last(state[STATE_KEY].extremeDates);
};

export const getProduction = createSelector(
  getToday,
  state => state[STATE_KEY],
  (today, state): ProductionPoint[] => {
    const production = R.pathOr([], ['production'], state);
    if (R.isEmpty(production)) {
      return production;
    }
    const filtredProduction = production.filter(
      data => utcDay.offset(today, -1).getTime() - data.day.getTime() >= 0,
    );

    return filtredProduction;
  },
);

export const getProductionNri = createSelector(
  getProduction,
  (_, props) => props.wellId as string,
  state => state.well.wells || [],
  (production, wellId, wells): ProductionPoint[] => {
    if (R.isEmpty(wells) || R.isEmpty(production)) return [];
    if (!wells[wellId]) return production;
    const wellInfo = wells[wellId];
    const nri = wellInfo.NRI === '' ? 1 : wellInfo.NRI;
    const netProduction = production.map(productionPoint => ({
      ...productionPoint,
      boe: productionPoint.boe * +nri,
      oil: productionPoint.oil * +nri,
      gas: productionPoint.gas * +nri,
      total_liquid: productionPoint.total_liquid * +nri,
      water: productionPoint.water * +nri,
    }));

    return netProduction;
  },
);

export const getFiltredProduction = createSelector(
  getProduction,
  getExtremeDates,
  (production, extremeDates) => {
    if (!extremeDates) {
      return production;
    }
    const min = utcDay.offset(extremeDates.min, -1).getTime();
    const max = utcDay.offset(extremeDates.max, 1).getTime();
    const filtredProduction = production.filter(
      data => data.day.getTime() >= min && data.day.getTime() <= max,
    );
    return filtredProduction;
  },
);

export const getFiltredProductionNri = createSelector(
  getFiltredProduction,
  (_, props) => props.wellId as string,
  state => state.well.wells || [],
  (production, wellId, wells): ProductionPoint[] => {
    if (R.isEmpty(wells) || R.isEmpty(production)) return [];
    if (!wells[wellId]) return production;
    const wellInfo = wells[wellId];
    const nri = wellInfo.NRI === '' ? 1 : wellInfo.NRI;
    const netProduction = production.map(productionPoint => ({
      ...productionPoint,
      boe: productionPoint.boe * +nri,
      oil: productionPoint.oil * +nri,
      gas: productionPoint.gas * +nri,
      total_liquid: productionPoint.total_liquid * +nri,
      water: productionPoint.water * +nri,
    }));

    return netProduction;
  },
);

export const getExtremeDatesHistoryLength = (state: RootState): number =>
  state[STATE_KEY].extremeDates.length;

export const getPrevExtremeDates = (state: RootState) =>
  R.nth(-2, state[STATE_KEY].extremeDates);

export const getDataAvailibility = (state: RootState, wellId: string) =>
  Boolean(state[STATE_KEY].dataAvailibility[wellId]);

export const getIsProductionAvailible = (state: RootState) =>
  !R.compose(R.isEmpty(), R.pathOr([], [STATE_KEY, 'production']))(state);

export const getLastProdDay: Selector<Date> = createSelector(
  getProduction,
  production =>
    production.reduce((acc, prodPoint, i) => {
      const nextProdPoint = production[i + 1];
      if (prodPoint.oil === 0 && prodPoint.gas === 0 && prodPoint.water === 0)
        return acc;
      if (
        nextProdPoint &&
        nextProdPoint.oil === 0 &&
        nextProdPoint.gas === 0 &&
        nextProdPoint.water === 0
      ) {
        return prodPoint.day;
      }
      return acc;
    }, utcMonth.floor(utcMonth.offset(new Date(), 2))),
);

export const getFullProduction = createSelector(
  (state: any) => state[STATE_KEY],
  (state: RootState): ProductionPoint[] | [] => {
    const production = R.pathOr([], ['production'], state);
    return production;
  },
);

export const getProductionStartDay = createSelector(
  (state: any) => state[STATE_KEY],
  getToday,
  (state: RootState, today): Date => {
    const startDay = R.pathOr(
      utcYear.offset(today, -2),
      ['production', 0, 'day'],
      state,
    );
    return startDay;
  },
);

export const getFullProductionNri = createSelector(
  getProduction,
  (_, props) => props.wellId as string,
  state => state.well.wells || [],
  (production, wellId, wells): ProductionPoint[] => {
    if (R.isEmpty(wells) || R.isEmpty(production)) return [];
    if (!wells[wellId]) return production;
    const wellInfo = wells[wellId];
    const nri = wellInfo.NRI === '' ? 1 : wellInfo.NRI;
    const netProduction = production.map(productionPoint => ({
      ...productionPoint,
      boe: productionPoint.boe * +nri,
      oil: productionPoint.oil * +nri,
      gas: productionPoint.gas * +nri,
      water: productionPoint.water * +nri,
    }));

    return netProduction;
  },
);

export default filterActions(ProductionReducer, action =>
  action.type.match(filterRegExp),
) as Reducer<ProductionState>;
