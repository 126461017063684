import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector, OutputParametricSelector } from 'reselect';

import { getMinDate, getMaxDate } from 'helpers';
import { getCurrentWellId, getSelectedRibbons } from 'modules/ui/UIReducer';
import type { Action, RootState, Selector } from 'store/models';
import { getGraphqlPayload } from 'store/helpers';

import type { Ribbon, RibbonEvent, RibbonOption } from './models';
import {
  FETCH_RIBBONS,
  FETCH_LIST_RIBBON_OPTION,
  namespace,
  UPDATE_RIBBON_EVENT_DATES_LOCALLY,
  UPDATE_RIBBON_EVENT_EXTRA_INPUTS_DATA_LOCALLY,
  DELETE_RIBBON_EVENT_LOCALLY,
  POPULATE_RIBBON_EVENTS,
  UPDATE_RIBBON_EVENT_NO_END_DATE_LOCALLY,
  UPDATE_RIBBON_EVENT_NOTES_LOCALLY,
  UPDATE_RIBBON_EVENT_OPTION_LOCALLY,
  UPDATE_RIBBON_EVENT_DESCRIPTION_LOCALLY,
} from './RibbonActions';
import { normalizeRibbonOptionData } from './utils';
import { Reducer } from 'redux';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'ribbon';

type RibbonState = {
  ribbons: Ribbon[];
  ribbonEvents: { [wellId: string]: { [ribbonId: string]: RibbonEvent[] } };
  ribbonOptions: RibbonOption[];
  fetchingStatus: {
    [wellId: string]: boolean;
  };
};

const initialState: RibbonState = {
  ribbons: [],
  ribbonEvents: {},
  ribbonOptions: [],
  fetchingStatus: {},
};

const RibbonReducer = (state: RibbonState = initialState, action: Action) => {
  switch (action.type) {
    case `${FETCH_RIBBONS}_SUCCESS`: {
      const listRibbons = getGraphqlPayload(action);
      return R.assoc<Ribbon[], RibbonState>('ribbons', listRibbons, state);
    }
    case `${FETCH_LIST_RIBBON_OPTION}_SUCCESS`: {
      const listRibbonOptions = JSON.parse(
        getGraphqlPayload(action).ribbonOptions,
      );
      const normalizedData = normalizeRibbonOptionData(listRibbonOptions);
      return R.assoc<Ribbon[], RibbonState>(
        'ribbonOptions',
        normalizedData,
        state,
      );
    }
    case POPULATE_RIBBON_EVENTS: {
      const { wellId, ribbonEvents } = action.payload;
      return R.compose(
        R.assocPath(['ribbonEvents', wellId], ribbonEvents),
        R.assocPath(['fetchingStatus', wellId], true),
      )(state);
    }
    case UPDATE_RIBBON_EVENT_DESCRIPTION_LOCALLY: {
      const { id, description, wellId } = action.payload;
      return R.assocPath(
        ['ribbonEvents', wellId, id, 'description'],
        description,
        state,
      );
    }
    case UPDATE_RIBBON_EVENT_DATES_LOCALLY: {
      const { ribbonEventId, dates, wellId } = action.payload;
      const dayStart = getMinDate(...(dates as [Date, Date]));
      const dayEnd = getMaxDate(...(dates as [Date, Date]));

      return R.compose(
        R.assocPath<string, RibbonState, Date>(
          ['ribbonEvents', wellId, ribbonEventId, 'dayStart'],
          dayStart,
        ),
        R.assocPath(['ribbonEvents', wellId, ribbonEventId, 'dayEnd'], dayEnd),
      )(state);
    }
    case UPDATE_RIBBON_EVENT_OPTION_LOCALLY: {
      const { ribbonEventId, ribbonOptionId, wellId } = action.payload;
      return R.assocPath<string, RibbonState, string>(
        ['ribbonEvents', wellId, ribbonEventId, 'ribbonOptionId'],
        ribbonOptionId,
        state,
      );
    }
    case UPDATE_RIBBON_EVENT_NOTES_LOCALLY: {
      const { ribbonEventId, notes, wellId } = action.payload;
      return R.assocPath<string, RibbonState, any>(
        ['ribbonEvents', wellId, ribbonEventId, 'notes'],
        notes,
        state,
      );
    }
    case DELETE_RIBBON_EVENT_LOCALLY: {
      const { ribbonEventId, wellId } = action.payload;
      return R.dissocPath<string, any>(
        ['ribbonEvents', wellId, ribbonEventId],
        state,
      );
    }
    case UPDATE_RIBBON_EVENT_EXTRA_INPUTS_DATA_LOCALLY: {
      const { ribbonEventId, extraInputsData, wellId } = action.payload;

      return R.assocPath<string, RibbonState, any>(
        ['ribbonEvents', wellId, ribbonEventId, 'extraInputsData'],
        extraInputsData,
        state,
      );
    }
    case UPDATE_RIBBON_EVENT_NO_END_DATE_LOCALLY: {
      const { ribbonEventId, noEndDate, wellId } = action.payload;

      return R.assocPath<string, RibbonState, any>(
        ['ribbonEvents', wellId, ribbonEventId, 'noEndDate'],
        noEndDate,
        state,
      );
    }

    default: {
      return state;
    }
  }
};

export const getRibbonState = (state: RootState): RibbonState =>
  state[STATE_KEY];
export const getRibbons: Selector<Ribbon[]> = createSelector(
  getRibbonState,
  state => state.ribbons.sort((a, b) => +a.order - +b.order),
);
export const getRibbonById = createSelector(
  (state: RootState, id: string) => id,
  getRibbonState,
  (id, state): Ribbon | undefined => {
    if (R.isNil(id)) {
      return undefined;
    }
    return state.ribbons.find(ribbon => ribbon.id === id);
  },
);

export const getWellRibbonEvents: Selector<RibbonEvent[]> = createSelector(
  getRibbonState,
  getCurrentWellId,
  (state, wellId) =>
    R.compose(R.values(), R.pathOr({}, ['ribbonEvents', wellId]))(state),
);
export const getEventsOfSelectedRibbons: Selector<{
  [ribbon: string]: RibbonEvent[];
}> = createSelector(
  getWellRibbonEvents,
  getSelectedRibbons,
  (ribbonEvents, selectedRibbons) => {
    const selectedRibbonsId = R.keys(selectedRibbons);
    return R.compose(
      R.pick(selectedRibbonsId),
      R.groupBy(event => event.ribbonId),
    )(ribbonEvents);
  },
);
export const getRibbonOptions = createSelector(getRibbonState, state => {
  const ribbonOptionsObj = state.ribbonOptions.reduce((acc, option) => {
    acc[option.id] = option;
    return acc;
  }, {});
  return ribbonOptionsObj as RibbonOption[];
});

export const getRibbonOptionsColors: Selector<{
  [categoryId: string]: string;
}> = createSelector(getRibbonOptions, ribbonOptions =>
  R.keys(ribbonOptions).reduce((acc, optionId) => {
    acc[optionId] = ribbonOptions[optionId].color;

    return acc;
  }, {}),
);

export const getRibbonEvents: Selector<RibbonEvent[]> = createSelector(
  getRibbonState,
  getCurrentWellId,
  (state, wellId) => {
    return R.pathOr({}, ['ribbonEvents', wellId], state);
  },
);

export const getRibbonEventsFor = createSelector(
  getRibbonState,
  (_: RootState, props) => props.wellId as string,
  (state, wellId): RibbonEvent[] => {
    return R.pathOr({}, ['ribbonEvents', wellId], state);
  },
);

export const getRibbonEventsByIds: OutputParametricSelector<
  RootState,
  { wellId: string; ribbonEventIds: string[] },
  RibbonEvent[] | Record<string, never>[],
  any
> = createSelector(
  getRibbonEvents,
  (_, { ribbonEventIds }: { wellId: string; ribbonEventIds: string[] }) =>
    ribbonEventIds,
  (wellRibbonEventsById, ribbonEventIds): RibbonEvent[] =>
    ribbonEventIds.map(
      ribbonEventId => wellRibbonEventsById[ribbonEventId] || null,
    ),
);

export const getRibbonFetchingStatus: Selector<boolean> = createSelector(
  getRibbonState,
  getCurrentWellId,
  (state, wellId) => {
    return R.pathOr(false, ['fetchingStatus', wellId], state);
  },
);

export const getCurrentRibbonOptions = (state: RootState, ribbonId: string) => {
  const ribbonOptions = getRibbonOptions(state);
  return R.values(ribbonOptions).filter(
    (option: RibbonOption) => option.ribbonId === ribbonId,
  ) as RibbonOption[];
};

export const getDefaultEventOptionId = (state: RootState, ribbonId: string) => {
  const currentRibbonOptions: RibbonOption[] = getCurrentRibbonOptions(
    state,
    ribbonId,
  );
  return R.values(currentRibbonOptions).reduce(
    (min, option) => (!min.order || option.order < min.order ? option : min),
    {},
  ).id as string;
};

export const getHasRibbonsDataForCurrentWell: Selector<boolean> =
  createSelector(
    getRibbonState,
    getCurrentWellId,
    (state, wellId) => !!state.ribbonEvents[wellId],
  );

export default filterActions(RibbonReducer, action =>
  action.type.match(filterRegExp),
) as Reducer<RibbonState>;
