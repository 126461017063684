import CircularProgress from '@material-ui/core/CircularProgress';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import { filterWellsOptimized } from 'modules/filter/utils/filter';
import {
  addFilter,
  addSeveralFilters,
  setFilter,
  removeFilter,
  clearFilter,
  clearAllFilters,
} from 'modules/filter/FilterActions';
import { getFilters } from 'modules/filter/FilterReducer';
import { redirectToChartFromFilters } from 'modules/router/RouterActions';
import { getIsOpenFilterLayoutsPanel } from 'modules/ui/UIReducer';
import {
  closeFilterLayoutsPanel,
  openFilterLayoutsPanel,
} from 'modules/ui/UIActions';
import { getWellsAsArray, getColumnMapping } from 'modules/well/WellReducer';

import useShortcut, { Code } from 'hooks/useShortcut';

import Button from 'components/Button';
import Card from 'components/Card';
import { CloseIcon } from 'components/Icons';
import FilterLayoutsPanel from 'modules/filterLayouts/container/FilterLayoutsPanel';
import {
  getCustomSelectedFiltersLayoutsOptions,
  getDraggableColumn,
  getSelectedFiltersLayoutsOptionsToDisplay,
  getWellCustomAttributesValues,
} from 'modules/filterLayouts/FilterLayoutsReducer';
import {
  changeFilterOptionVisible,
  removeFilterOption,
} from 'modules/filterLayouts/FilterLayoutsActions';
import { CELL_HEIGHT } from 'modules/drilldownTable/models/drilldownTable';
import FilterLayoutsDropdownButton from 'modules/filterLayouts/container/FilterLayoutsDropdownButton';
import { getSpotfireFilteredWells } from 'modules/spotfire/SpotfireReducer';

import TableView from '../components/tableView/TableView';
import { FILTER_LAYOUT_PANEL_WIDTH } from '../models/filter';
import { joinCustomAttributesToWellsOptimized } from '../utils/customAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';
import { closeButtonAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';
import { getCustomFilterLayoutsOptionsIdsAndNames } from '../../filterLayouts/FilterLayoutsReducer';
import ArrayBoost from 'helpers/arrayBoost';

type FilterPageProps = {
  history: RouterHistory;
};

const FilterPage = ({ history }: FilterPageProps) => {
  const dispatch = useDispatch();
  const customAttributes = useSelector(
    getCustomSelectedFiltersLayoutsOptions,
    R.equals,
  );
  const spotfireWells = useSelector(getSpotfireFilteredWells);
  const customValues = useSelector(getWellCustomAttributesValues, R.equals);
  const columnMapping = useSelector(getColumnMapping);
  const filters = useSelector(getFilters);
  const wells = useSelector(getWellsAsArray);
  const isOpenLayoutsPanel: boolean = useSelector(getIsOpenFilterLayoutsPanel);
  const selectedFilterLayoutsOptionsToDisplay = useSelector(
    getSelectedFiltersLayoutsOptionsToDisplay,
    R.equals,
  );
  const customOptionsIdsAndNames: {
    ids: string[];
    names: string[];
  } = useSelector(getCustomFilterLayoutsOptionsIdsAndNames);

  const draggableColumn = useSelector(getDraggableColumn);
  const openLayoutsPanel = React.useCallback(() => {
    dispatch(openFilterLayoutsPanel());
  }, [dispatch]);

  const closeLayoutsPanel = React.useCallback(() => {
    dispatch(closeFilterLayoutsPanel());
  }, [dispatch]);

  const onAddFilter = React.useCallback(
    ({ filterName, filterValue }) =>
      dispatch(addFilter({ filterName, filterValue })),
    [dispatch],
  );
  const onAddSeveralFilters = React.useCallback(
    ({ filterName, filterValues }) =>
      dispatch(addSeveralFilters({ filterName, filterValues })),
    [dispatch],
  );
  const onRemoveFilter = React.useCallback(
    ({ filterName, filterValue }) =>
      dispatch(removeFilter({ filterName, filterValue })),
    [dispatch],
  );
  const onSetFilter = React.useCallback(
    ({ filterName, filterValue }) =>
      dispatch(setFilter({ filterName, filterValue })),
    [dispatch],
  );
  const onClearFilter = React.useCallback(
    filterName => dispatch(clearFilter(filterName)),
    [dispatch],
  );
  const onClearAllFilters = React.useCallback(
    () => dispatch(clearAllFilters()),
    [dispatch],
  );
  const [activeTable, setActiveTable] = React.useState(null);
  const containerEl = React.useRef();

  const wellsWithCustomAttributes = React.useMemo(() => {
    const resultWells = joinCustomAttributesToWellsOptimized(
      wells,
      customAttributes,
      customValues,
    );

    if (!spotfireWells || spotfireWells.length < 1) return resultWells;

    return ArrayBoost.filter<any>(resultWells, well => {
      return spotfireWells.includes(well.id);
    });
  }, [wells, customAttributes, customValues, spotfireWells]);

  const filteredWells = React.useMemo(
    () => filterWellsOptimized(wellsWithCustomAttributes, filters),
    [wellsWithCustomAttributes, filters],
  );

  const setActive = e => {
    const target = e.target;

    const highlight = node => {
      if (node.tagName === 'HTML') {
        setActiveTable(null);
        return;
      }
      if (node.classList.contains('filter-table')) {
        setActiveTable(node.id);
        return;
      }

      highlight(node.parentNode);
    };

    highlight(target);
  };

  const removeFilterLayoutOption = React.useCallback(
    (optionId: string, filterName: string) => {
      dispatch(removeFilterOption({ optionId }));
      onClearFilter(filterName);
    },
    [dispatch],
  );

  const changeFilterLayoutOption = React.useCallback(
    (optionId: string) => {
      dispatch(changeFilterOptionVisible({ optionId }));
    },
    [dispatch],
  );

  useShortcut(() => window.history.back(), Code.Escape, []);

  return (
    <FilterPage.Container
      id="filterPageRoot"
      isOpenPanel={isOpenLayoutsPanel}
      onClick={e => setActive(e)}
      ref={containerEl}
    >
      <Card>
        {!filters ||
        R.isEmpty(filters) ||
        R.isEmpty(wells) ||
        R.isEmpty(columnMapping) ? (
          <FilterPage.ProgressWrapper>
            <CircularProgress color="inherit" />
          </FilterPage.ProgressWrapper>
        ) : (
          <>
            <FilterPage.Header>
              <FilterPage.Title>Well Attributes</FilterPage.Title>

              <FilterPage.CloseButtonWrapper>
                {!isOpenLayoutsPanel && (
                  <FilterPage.ViewButtonWrapper style={{ margin: 0 }}>
                    <FilterLayoutsDropdownButton onClick={openLayoutsPanel} />
                  </FilterPage.ViewButtonWrapper>
                )}
                <ButtonTooltip content={closeButtonAttributes}>
                  <Button
                    transparent
                    onClick={() => dispatch(redirectToChartFromFilters())}
                  >
                    <CloseIcon />
                  </Button>
                </ButtonTooltip>
              </FilterPage.CloseButtonWrapper>
            </FilterPage.Header>

            <FilterPage.Content>
              <TableView
                activeTable={activeTable}
                addFilter={onAddFilter}
                addSeveralFilters={onAddSeveralFilters}
                clearFilter={onClearFilter}
                filteredWells={filteredWells}
                filters={filters}
                clearAllFilters={onClearAllFilters}
                setFilter={onSetFilter}
                removeFilter={onRemoveFilter}
                draggableColumn={draggableColumn}
                selectedFilterLayoutsOptionsToDisplay={
                  selectedFilterLayoutsOptionsToDisplay
                }
                wells={wellsWithCustomAttributes}
                customOptionsIdsAndNames={customOptionsIdsAndNames}
              />
            </FilterPage.Content>
          </>
        )}
      </Card>
      {isOpenLayoutsPanel ? (
        <FilterLayoutsPanel
          isPanelOpen={isOpenLayoutsPanel}
          onPanelClose={closeLayoutsPanel}
          onChangeOptionVisible={changeFilterLayoutOption}
          onRemoveOption={removeFilterLayoutOption}
        />
      ) : null}
    </FilterPage.Container>
  );
};

FilterPage.Container = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 5px;
  display: grid;
  grid-template-rows: 100% 100%;
  grid-template-columns: ${props =>
    props.isOpenPanel ? `auto ${FILTER_LAYOUT_PANEL_WIDTH}px ` : '1f'};
  gap: 5px;
`;

FilterPage.ProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

FilterPage.Header = styled.div`
  height: 45px;
  border-bottom: ${(props: Record<string, any>) =>
    `1px solid ${props.theme.colors.primaryText}`};
  display: flex;
  align-items: center;
  color: ${(props: Record<string, any>) => props.theme.colors.primaryText};
`;

FilterPage.Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
  margin-right: 20px;
`;

FilterPage.ViewButtonWrapper = styled.div`
  margin: 0 10px;
`;

FilterPage.CloseButtonWrapper = styled.div`
  flex-grow: 10;
  display: flex;
  justify-content: flex-end;
`;

FilterPage.Content = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 5px;
  padding: 0 5px 0 5px;
  display: flex;
  justify-content: space-around;
  font-family: 'Lato', sans-serif;

  > div {
    width: 100%;

    .wtHolder,
    .wtHider {
      width: 100% !important;
    }

    td div {
      padding-right: 2px;
      height: ${CELL_HEIGHT + 5}px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

FilterPage.Divider = styled.div`
  height: 100%;
  width: 5px;
`;

FilterPage.Column = styled.div`
  width: ${(props: Record<string, any>) =>
    `calc((100% - ${(props.columnQuant - 1) * 5}px) / ${props.columnQuant})`};
  display: flex;
  flex-direction: column;
`;

export default FilterPage;
