import { FeatureFlag } from '../models';

export const getFlagValue = (
  flagKey: string,
  allFlags: FeatureFlag[],
  localValues: Record<string, boolean> = {},
) => {
  return (
    localValues[flagKey] ??
    allFlags.find(flag => flag.key === flagKey)?.value ??
    false
  );
};

export const makeGetFlagValueFn =
  (allFlags: FeatureFlag[], localValues: Record<string, boolean>) =>
  (flagKey: string) =>
    getFlagValue(flagKey, allFlags, localValues);
