import { timeFormat } from 'd3-time-format';
import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from '../../../store/models';
import { getUser, getUsersByEmailName } from '../../user/UserReducer';
import textEllipsis from '../helpers/textEllipsis';
import { faFileImage, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getAttachmentsTypes from '../helpers/getAttachmentsTypes';
import { User } from '../../user/models/user';
import { getToday } from '../../appConfig/AppConfigReducer';
import useDate from '../hooks/useDate';

export const getNoteText = (
  text: string,
  usersByEmailName: { [name: string]: User },
) => {
  const words = text.split(' ');
  const emailNames = Object.entries(usersByEmailName)
    .filter(([_, u]) => u.taggable)
    .map(([name]) => name);

  return words.map((word, index) => {
    if (word.startsWith('@')) {
      if (emailNames.includes(word.slice(1, word.length))) {
        return (
          <span key={word} className="tag">
            {index === words.length - 1 ? word : word + ' '}
          </span>
        );
      }
      return (
        <span key={word}>{index === words.length - 1 ? word : word + ' '}</span>
      );
    }
    return (
      <span key={word}>{index === words.length - 1 ? word : word + ' '}</span>
    );
  });
};

const NotesTooltipItem = ({ note }) => {
  const { noteTimestamp, noteText, userId, attachments } = note;
  const user = useSelector(state => getUser(state, userId));
  const usersByEmail = useSelector(getUsersByEmailName);
  const formatTime = timeFormat('%H:%M %p');
  const formatDate = timeFormat('%d %b');
  const date = new Date(noteTimestamp);
  const today = useSelector(getToday);
  const attachmentTypes = React.useMemo(
    () => getAttachmentsTypes(attachments),
    [attachments],
  );

  const { isToday, isYesterday } = useDate(today, date);

  return (
    <NotesTooltipItem.Container>
      <div className="header">
        <span className="name">
          {`${user.firstName} ${user.lastName}`}
          {attachmentTypes.hasPhotos && (
            <FontAwesomeIcon
              icon={faFileImage}
              color="#909090"
              className="icon"
            />
          )}
          {attachmentTypes.hasDocs && (
            <FontAwesomeIcon
              icon={faPaperclip}
              color="#909090"
              className="icon"
              transform={{ rotate: 90 }}
            />
          )}
        </span>
        <span className="time">
          {isYesterday && 'Yesterday'}
          {isToday && formatTime(date)}
          {!isToday && !isYesterday && formatDate(date)}
        </span>
      </div>
      <div className="content">
        {getNoteText(textEllipsis(noteText, 160), usersByEmail) || 'No content'}
      </div>
    </NotesTooltipItem.Container>
  );
};

NotesTooltipItem.Container = styled.li`
  padding-top: 4px;
  padding-bottom: 12px;

  .header {
    display: flex;
    align-items: center;
    height: 16px;
    justify-content: space-between;
    margin-bottom: 7px;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  .icon {
    margin-left: 4px;
    transform: translateY(-3px);
    width: 12px;
    height: 12px;
  }

  .name {
    font-weight: bold;
    display: flex;
    align-items: flex-end;
  }

  .time {
    color: #909090;
  }

  .tag {
    color: #41ab5d;
  }

  .content {
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
`;

export default NotesTooltipItem;
