import * as R from 'ramda';
import * as React from 'react';

import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import type { Category } from 'modules/category/models/category';
import type { Ribbon, RibbonEvent, RibbonOption } from 'modules/ribbon/models';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';
import type { IndexDialog, IdDialog } from 'modules/ui/models/ui';

import CapacityVisibleDivider from './capacityEvent/CapacityVisibleDivider';
import EditCapacityButton from './capacityEvent/EditCapacityButton';
import EditVarianceButton from './varianceEvent/EditVarianceButton';
import RibbonInteractiveDivider from './ribbon/RibbonInteractiveDivider';
import EventVisibleDivider from './varianceEvent/EventVisibleDivider';
import { isIdNew } from 'helpers';

const getMiddle = (xCoords: { x1: number; x2: number }): number => {
  const { x1, x2 } = xCoords;

  return (x2 - x1) / 2 + x1;
};

const getCapacityButtonPos = (highlightedCapacity, capacitySegmentsAreas) => {
  if (
    highlightedCapacity.show &&
    capacitySegmentsAreas[highlightedCapacity.index]
  ) {
    return getMiddle(capacitySegmentsAreas[highlightedCapacity.index]);
  }
  return 0;
};

const getEventButtonPos = (
  highlightedEvent,
  highlightedEventDivider,
  varianceEventsAreas,
) => {
  if (
    highlightedEventDivider.show &&
    varianceEventsAreas[highlightedEventDivider.index]
  ) {
    return getMiddle(varianceEventsAreas[highlightedEventDivider.index]);
  } else if (
    highlightedEvent.show &&
    varianceEventsAreas[highlightedEvent.index]
  ) {
    return getMiddle(varianceEventsAreas[highlightedEvent.index]);
  }

  return 0;
};

const getvarianceDialogButtonPos = (varianceDialog, varianceEventsAreas) => {
  if (varianceDialog.show && varianceEventsAreas[varianceDialog.index]) {
    return getMiddle(varianceEventsAreas[varianceDialog.index]);
  }

  return 0;
};

interface HighlightablesProps {
  additionMode: { isOn: boolean; subject: string | null };
  capacityCategories: { [id: string]: Category };
  capacityDialog: { show: boolean; index: number };
  capacityEvents: CapacityChangeEvent[];
  capacitySegmentsAreas: Array<{ x1: number; x2: number }>;
  chartPos: { x1: number; x2: number };
  finishDrag: () => void;
  eventColors: { [key: string]: string };
  varianceDialog: { show: boolean; index: number };
  highlightedCapacity: IndexDialog;
  highlightedCapacityDivider: IndexDialog;
  highlightedEvent: IndexDialog;
  highlightedEventDivider: IndexDialog;
  highlightedRibbonEvent: { show: boolean; index: number };
  isPossibleEditCapacity: boolean;
  isPossibleEditVariance: boolean;
  isYAxisDragging: boolean;
  leftOffset: number;
  onCapacityDialogClose: () => void;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onVarianceDialogClose: () => void;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  onHighlightCapacityOff: () => void;
  onHighlightCapacityDividerOff: () => void;
  onHighlightVarianceOff: () => void;
  onHighlightEventDividerOff: () => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  ribbonDialog: IdDialog;
  ribbonEvents: { [id: number]: RibbonEvent };
  ribbonMap: { [id: number]: Ribbon };
  ribbonOptions: RibbonOption[];
  startDrag: () => void;
  today: Date;
  varianceCategories: { [id: string]: Category };
  varianceEvents: VarianceEvent[];
  varianceEventsAreas: Array<{ x1: number; x2: number }>;
  xScale: any;
}

const Highlightables = ({
  additionMode,
  capacityCategories,
  capacityDialog,
  capacityEvents,
  capacitySegmentsAreas,
  chartPos,
  eventColors,
  finishDrag,
  varianceDialog,
  highlightedCapacity,
  highlightedCapacityDivider,
  highlightedEvent,
  highlightedEventDivider,
  highlightedRibbonEvent,
  isPossibleEditCapacity,
  isPossibleEditVariance,
  isYAxisDragging,
  leftOffset,
  onCapacityDialogClose,
  onCapacityDialogOpen,
  onVarianceDialogClose,
  onVarianceDialogOpen,
  onHighlightCapacityOff,
  onHighlightCapacityDividerOff,
  onHighlightVarianceOff,
  onHighlightEventDividerOff,
  onXAxisScaling,
  ribbonDialog,
  ribbonEvents,
  ribbonMap,
  ribbonOptions,
  startDrag,
  today,
  varianceCategories,
  varianceEvents,
  varianceEventsAreas,
  xScale,
}: HighlightablesProps) => {
  const capacitySubCategory = React.useMemo(() => {
    if (
      !capacityEvents[highlightedCapacity.index] ||
      !capacityCategories[
        capacityEvents[highlightedCapacity.index].capacityChangeOptionId
      ] ||
      R.isEmpty(capacityCategories)
    )
      return '';
    return capacityCategories[
      capacityEvents[highlightedCapacity.index].capacityChangeOptionId
    ].subCause;
  }, [capacityEvents, capacityCategories, highlightedCapacity.index]);

  const varianceSubCategory = React.useMemo(() => {
    if (
      !varianceEvents[highlightedEvent.index] ||
      !varianceCategories[
        varianceEvents[highlightedEvent.index].varianceOptionId
      ] ||
      R.isEmpty(varianceCategories)
    )
      return '';
    return varianceCategories[
      varianceEvents[highlightedEvent.index].varianceOptionId
    ].subCause;
  }, [varianceCategories, varianceEvents, highlightedEvent.index]);
  const editCapacityButtonPos = React.useMemo(
    () => getCapacityButtonPos(highlightedCapacity, capacitySegmentsAreas),
    [highlightedCapacity, capacitySegmentsAreas],
  );
  const editCapacityDialogButtonPos = React.useMemo(
    () => getCapacityButtonPos(capacityDialog, capacitySegmentsAreas),
    [capacityDialog, capacitySegmentsAreas],
  );
  const editVarianceButtonPos = React.useMemo(
    () =>
      getEventButtonPos(
        highlightedEvent,
        highlightedEventDivider,
        varianceEventsAreas,
      ),
    [highlightedEvent, highlightedEventDivider, varianceEventsAreas],
  );
  const editVarianceDialogButtonPos = React.useMemo(
    () => getvarianceDialogButtonPos(varianceDialog, varianceEventsAreas),
    [varianceDialog, varianceEventsAreas],
  );
  const difference = Math.abs(editCapacityButtonPos - editVarianceButtonPos);
  const adjustedPositions =
    difference < 20
      ? {
          capacity:
            editCapacityButtonPos +
            (20 - editCapacityButtonPos + editVarianceButtonPos) / 2,
          variance:
            editVarianceButtonPos -
            (20 - editCapacityButtonPos + editVarianceButtonPos) / 2,
        }
      : {
          capacity: editCapacityButtonPos,
          variance: editVarianceButtonPos,
        };

  const ribbonId = R.pathOr('', [ribbonDialog.id, 'ribbonId'], ribbonEvents);
  const isRibbonReadOnly = R.pathOr(true, [ribbonId, 'readOnly'], ribbonMap);

  const newlyCreatedVarianceEvent = React.useMemo(
    () => varianceEvents.find(e => isIdNew(e.id)),
    [varianceEvents],
  );

  const newlyCreatedRibbonEvent = React.useMemo(
    () => Object.values(ribbonEvents).find(e => isIdNew(e.id)),
    [ribbonEvents],
  );

  return (
    <>
      {!additionMode.isOn &&
        ribbonDialog.show &&
        !isRibbonReadOnly &&
        ribbonDialog.id &&
        ribbonEvents[ribbonDialog.id] && (
          <RibbonInteractiveDivider
            allRibbonEvents={ribbonEvents}
            finishDrag={finishDrag}
            isYAxisDragging={isYAxisDragging}
            leftOffset={leftOffset}
            onXAxisScaling={onXAxisScaling}
            selectedEventId={ribbonDialog.id}
            startDrag={startDrag}
            today={today}
            xScale={xScale}
          />
        )}
      {capacityDialog.show &&
        !R.isEmpty(capacityEvents) &&
        !R.isNil(capacityDialog.index) &&
        capacityEvents[capacityDialog.index] && (
          <CapacityVisibleDivider
            capacityEvents={capacityEvents}
            index={capacityDialog.index || 0}
            isEditable={isPossibleEditCapacity}
            showPrevEvent={false}
            xScale={xScale}
          />
        )}
      {highlightedCapacity.show &&
        !R.isEmpty(capacityEvents) &&
        !R.isNil(highlightedCapacity.index) &&
        capacityEvents[highlightedCapacity.index] && (
          <CapacityVisibleDivider
            capacityEvents={capacityEvents}
            index={highlightedCapacity.index || 0}
            isEditable={false}
            neighbourIsEditable={false}
            showPrevEvent={false}
            xScale={xScale}
          />
        )}
      {highlightedCapacityDivider.show &&
        !R.isEmpty(capacityEvents) &&
        !R.isNil(highlightedCapacityDivider.index) &&
        capacityEvents[highlightedCapacityDivider.index] && (
          <CapacityVisibleDivider
            capacityEvents={capacityEvents}
            index={highlightedCapacityDivider.index || 0}
            isEditable={false}
            neighbourIsEditable={false}
            showPrevEvent={true}
            xScale={xScale}
          />
        )}
      {varianceDialog.show &&
        !R.isNil(varianceDialog.index) &&
        varianceEvents[varianceDialog.index] && (
          <EventVisibleDivider
            color={
              eventColors[varianceEvents[varianceDialog.index].varianceOptionId]
            }
            eventDates={R.pick(
              ['dayStart', 'dayEnd'],
              varianceEvents[varianceDialog.index],
            )}
            isEditable={isPossibleEditVariance}
            xScale={xScale}
          />
        )}
      {newlyCreatedVarianceEvent && (
        <EventVisibleDivider
          color={eventColors[newlyCreatedVarianceEvent.varianceOptionId]}
          eventDates={R.pick(['dayStart', 'dayEnd'], newlyCreatedVarianceEvent)}
          isEditable={isPossibleEditVariance}
          xScale={xScale}
        />
      )}
      {highlightedEvent.show &&
        !R.isNil(highlightedEvent.index) &&
        varianceEvents[highlightedEvent.index] && (
          <EventVisibleDivider
            color={
              eventColors[
                varianceEvents[highlightedEvent.index].varianceOptionId
              ]
            }
            eventDates={R.pick(
              ['dayStart', 'dayEnd'],
              varianceEvents[highlightedEvent.index],
            )}
            isEditable={false}
            xScale={xScale}
          />
        )}
      {highlightedEventDivider.show &&
        !R.isNil(highlightedEventDivider.index) &&
        varianceEvents[highlightedEventDivider.index] && (
          <EventVisibleDivider
            color={
              eventColors[
                varianceEvents[highlightedEventDivider.index].varianceOptionId
              ]
            }
            eventDates={R.pick(
              ['dayStart', 'dayEnd'],
              varianceEvents[highlightedEventDivider.index],
            )}
            isEditable={false}
            xScale={xScale}
          />
        )}
      {ribbonDialog.show &&
        !R.isNil(ribbonDialog.id) &&
        ribbonEvents[ribbonDialog.id] && (
          <EventVisibleDivider
            color={R.pathOr<
              Record<string, any>,
              Record<string, any>,
              Record<string, any>,
              Record<string, any>
            >(
              '#000000',
              [`${ribbonEvents[ribbonDialog.id].ribbonOptionId}`, 'color'],
              ribbonOptions,
            )}
            eventDates={R.pick(
              ['dayStart', 'dayEnd'],
              ribbonEvents[ribbonDialog.id],
            )}
            isEditable={isPossibleEditVariance}
            hideLine={ribbonEvents[ribbonDialog.id].noEndDate ? 'right' : ''}
            xScale={xScale}
          />
        )}
      {newlyCreatedRibbonEvent && (
        <EventVisibleDivider
          color={R.pathOr<
            Record<string, any>,
            Record<string, any>,
            Record<string, any>,
            Record<string, any>
          >(
            '#000000',
            [`${newlyCreatedRibbonEvent.ribbonOptionId}`, 'color'],
            ribbonOptions,
          )}
          eventDates={R.pick(['dayStart', 'dayEnd'], newlyCreatedRibbonEvent)}
          isEditable={isPossibleEditVariance}
          hideLine={newlyCreatedRibbonEvent.noEndDate ? 'right' : ''}
          xScale={xScale}
        />
      )}
      {highlightedRibbonEvent.show &&
        !R.isNil(highlightedRibbonEvent.index) &&
        ribbonEvents[highlightedRibbonEvent.index] && (
          <EventVisibleDivider
            color={
              ribbonOptions[
                ribbonEvents[highlightedRibbonEvent.index].ribbonOptionId
              ].color
            }
            eventDates={R.pick(
              ['dayStart', 'dayEnd'],
              ribbonEvents[highlightedRibbonEvent.index],
            )}
            isEditable={false}
            hideLine={
              ribbonEvents[highlightedRibbonEvent.index].noEndDate
                ? 'right'
                : ''
            }
            xScale={xScale}
          />
        )}
      {highlightedCapacity.show &&
        capacityEvents[highlightedCapacity.index] &&
        capacityEvents[highlightedCapacity.index].id &&
        adjustedPositions.capacity < chartPos.x2 &&
        adjustedPositions.capacity > chartPos.x1 &&
        editCapacityButtonPos && (
          <EditCapacityButton
            xScale={xScale}
            subCategory={capacitySubCategory}
            x={adjustedPositions.capacity}
            onClick={() => {
              onHighlightVarianceOff();
              onHighlightEventDividerOff();
              onHighlightCapacityDividerOff();
              if (
                capacityDialog.show &&
                capacityDialog.index === highlightedCapacity.index
              )
                return;
              onCapacityDialogOpen(
                highlightedCapacity.index,
                capacityEvents[highlightedCapacity.index].id,
              );
            }}
          />
        )}
      {capacityDialog.show &&
        capacityEvents[capacityDialog.index] &&
        capacityEvents[capacityDialog.index].id &&
        !(
          highlightedCapacity.show &&
          capacityDialog.index === highlightedCapacity.index
        ) &&
        editCapacityDialogButtonPos < chartPos.x2 &&
        editCapacityDialogButtonPos > chartPos.x1 &&
        editCapacityDialogButtonPos && (
          <EditCapacityButton
            xScale={xScale}
            subCategory={capacitySubCategory}
            x={editCapacityDialogButtonPos}
            onClick={() => {
              onHighlightVarianceOff();
              onHighlightEventDividerOff();
              onHighlightCapacityDividerOff();
              onCapacityDialogClose();
            }}
          />
        )}
      {highlightedEvent.show &&
        varianceEvents[highlightedEvent.index] &&
        varianceEvents[highlightedEvent.index].id &&
        adjustedPositions.variance < chartPos.x2 &&
        adjustedPositions.variance > chartPos.x1 &&
        editVarianceButtonPos && (
          <EditVarianceButton
            xScale={xScale}
            x={adjustedPositions.variance}
            onClick={() => {
              onHighlightCapacityOff();
              onHighlightCapacityDividerOff();
              onHighlightEventDividerOff();
              if (
                varianceDialog.show &&
                varianceDialog.index === highlightedEvent.index
              )
                return;
              onVarianceDialogOpen(
                highlightedEvent.index,
                varianceEvents[highlightedEvent.index].id,
              );
            }}
            subCategory={varianceSubCategory}
            color={
              eventColors[
                varianceEvents[highlightedEvent.index].varianceOptionId
              ]
            }
          />
        )}
      {highlightedEventDivider.show &&
        varianceEvents[highlightedEventDivider.index] &&
        varianceEvents[highlightedEventDivider.index].id &&
        adjustedPositions.variance < chartPos.x2 &&
        adjustedPositions.variance > chartPos.x1 &&
        editVarianceButtonPos && (
          <EditVarianceButton
            xScale={xScale}
            x={adjustedPositions.variance}
            onClick={() => {
              onHighlightCapacityOff();
              onHighlightCapacityDividerOff();
              onHighlightEventDividerOff();
              if (
                varianceDialog.show &&
                varianceDialog.index === highlightedEventDivider.index
              )
                return;
              onVarianceDialogOpen(
                highlightedEventDivider.index,
                varianceEvents[highlightedEventDivider.index].id,
              );
            }}
            subCategory={varianceSubCategory}
            color={
              eventColors[
                varianceEvents[highlightedEventDivider.index].varianceOptionId
              ]
            }
          />
        )}
      {varianceDialog.show &&
        varianceEvents[varianceDialog.index] &&
        varianceEvents[varianceDialog.index].id &&
        !(
          highlightedEvent.show &&
          varianceDialog.index === highlightedEvent.index
        ) &&
        !(
          highlightedEventDivider.show &&
          varianceDialog.index === highlightedEventDivider.index
        ) &&
        editVarianceDialogButtonPos < chartPos.x2 &&
        editVarianceDialogButtonPos > chartPos.x1 &&
        editVarianceDialogButtonPos && (
          <EditVarianceButton
            xScale={xScale}
            x={editVarianceDialogButtonPos}
            color={
              eventColors[varianceEvents[varianceDialog.index].varianceOptionId]
            }
            subCategory={varianceSubCategory}
            onClick={() => {
              onHighlightCapacityOff();
              onHighlightCapacityDividerOff();
              onHighlightEventDividerOff();
              onVarianceDialogClose();
            }}
          />
        )}
    </>
  );
};

export default Highlightables;
