import { timeDay } from 'd3-time';
import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import { getMinDate } from 'helpers';

import type { Action, RootState, Selector } from 'store/models';
import { getToday } from 'modules/appConfig/AppConfigReducer';
import { LOGOUT } from 'modules/auth/AuthActions';
import { FETCH_WELL_CAPACITY } from 'modules/capacityChangeEvent/CapacityChangeEventActions';
import { VARIANCE_TRELLIS } from 'modules/chart/models/chart';
import { orderChartsNames } from 'modules/chart/utils';
import {
  OIL,
  GAS,
  WATER,
  BOE,
  GOR,
  GLR,
  WATERCUT,
} from 'modules/phase/models/phase';
import { FETCH_WELL_PRODUCTION } from 'modules/production/ProductionActions';

import {
  FETCH_GROUP_CHART_DATE_RANGE,
  FETCH_GROUP_VARIANCE_CHART_DATE_RANGE,
  FETCH_NET_GROUP_CHART_DATE_RANGE,
  namespace as groupChartNamspace,
} from 'modules/groupChart/GroupChartActions';
import { FETCH_VARIANCE_EVENTS } from 'modules/varianceEvent/VarianceEventActions';

import {
  ACTIVATE_DRILLDOWN_PANEL,
  ACTIVATE_NOTES_PANEL,
  CHANGE_GROUP_MODE_SUBJECT,
  CLOSE_ALLOC_ISSUE_ALERT_WINDOW,
  CLOSE_CAPACITY_DELETE_ALERT_WINDOW,
  CLOSE_CAPACITY_CANCEL_EDITS_ALERT_WINDOW,
  CLOSE_RIBBON_EVENT_ALERT_WINDOW,
  CLOSE_VARIANCE_ALERT_WINDOW,
  DISABLE_ADDITION_MODE,
  DISABLE_GROUP_MODE,
  DISABLE_NOTES_MODE,
  DISABLE_REGION_OF_INTEREST_MODE,
  DISABLE_ZOOM_IN_MODE,
  ENABLE_ADDITION_MODE,
  ENABLE_GROUP_MODE,
  ENABLE_NOTES_MODE,
  ENABLE_REGION_OF_INTEREST_MODE,
  ENABLE_ZOOM_IN_MODE,
  HIGHLIGHT_ALLOC_ISSUE,
  HIGHLIGHT_ALLOC_ISSUE_DIVIDER,
  HIGHLIGHT_ALLOC_ISSUE_DIVIDER_OFF,
  HIGHLIGHT_ALLOC_ISSUE_OFF,
  HIGHLIGHT_CAPACITY,
  HIGHLIGHT_CAPACITY_DIVIDER,
  HIGHLIGHT_CAPACITY_DIVIDER_OFF,
  HIGHLIGHT_CAPACITY_OFF,
  HIGHLIGHT_OFF_EVERYTHING,
  HIGHLIGHT_RIBBON_EVENT_DIVIDER,
  HIGHLIGHT_RIBBON_EVENT_DIVIDER_OFF,
  HIGHLIGHT_VARIANCE,
  HIGHLIGHT_VARIANCE_DIVIDER,
  HIGHLIGHT_VARIANCE_DIVIDER_OFF,
  HIGHLIGHT_VARIANCE_OFF,
  RESET_ACTIVE_PANEL,
  SET_CURRENT_GROUP,
  SET_CURRENT_WELL_ID,
  SET_NOTES_PRODUCTION_DAY,
  SET_RIBBONS,
  SET_UI_STATE,
  SET_WAS_DRAGGING,
  OPEN_ALLOC_ISSUE_ALERT_WINDOW,
  OPEN_CAPACITY_DELETE_ALERT_WINDOW,
  OPEN_CAPACITY_CANCEL_EDITS_ALERT_WINDOW,
  OPEN_RIBBON_EVENT_ALERT_WINDOW,
  OPEN_VARIANCE_ALERT_WINDOW,
  SET_TOOLTIP_DATA,
  SET_TRELLISES,
  SWITCH_RIBBONS,
  SWITCH_TRELLISES,
  TOGGLE_ISSUES_VISIBILITY,
  RESET_CURRENT_GROUP,
  START_X_AXIS_DRAGGING,
  START_Y_AXIS_DRAGGING,
  STOP_X_AXIS_DRAGGING,
  STOP_Y_AXIS_DRAGGING,
  namespace as uiNamespace,
  OPEN_IMAGE_DETAILS_OVERLAY,
  CLOSE_IMAGE_DETAILS_OVERLAY,
  OPEN_FILTER_LAYOUTS_PANEL,
  CLOSE_FILTER_LAYOUTS_PANEL,
  OPEN_EVENT_CONVERSATION,
  CLOSE_EVENT_CONVERSATION,
  SET_RIGHT_PANEL_DIALOG,
  EXPAND_RIGHT_PANEL,
  COLLAPSE_RIGHT_PANEL,
  EXPAND_RIGHT_PANEL_WITHOUT_CHANGING_DIALOG,
  SET_LAST_EDITED_CAPACITY_CHANGE_EVENT,
  SET_LEFT_PANEL_ACTIVE_TAB,
  SET_CHART_WIDTH,
  SET_NEWLY_CREATED_VARIANCE_EVENT,
  SET_RIGHT_PANEL_TEMPLATE,
  STOP_RECORDING_VIDEO,
  START_RECORDING_VIDEO,
  SET_Y_AXIS_HOVERED,
  SET_FILTERS_COLUMNS_WIDTH,
  SET_CAPACITY_LINE_DRAGGING,
} from './UIActions';
import {
  IndexDialog,
  IdDialog,
  Mode,
  DEFAULT_TRELLISES,
  EMPTY_TRELLISES,
  DRILLDOWN_PANEL,
  NOTES_PANEL,
  initialState,
  UIState,
  TooltipData,
  NotesSection,
} from './models/ui';
import { OPEN_CONVERSATION } from 'modules/inboxConversation/InboxConversationActions';
import { Reducer } from 'redux';
import { CapacityChangeEvent } from '../capacityChangeEvent/models/capacityChangeEvent';
import { VarianceEvent } from '../varianceEvent/models/varianceEvent';
import { DrilldownTableState } from '../drilldownTable/DrilldownTableReducer';
import {
  TOGGLE_NOTES_RIBBON_SECTION_PIN,
  HOVER_NOTES_RIBBON_SECTION,
  UNHOVER_NOTES_RIBBON_SECTION,
  SET_SHOULD_FILTERS_SCROLL_RIGHT,
} from './UIActions';
import getTodayWithTimezoneOffset from '../appConfig/utils/getTodayWithTimezoneOffset';

const FETCH_WELL_CAPACITY_SUCCESS = `${FETCH_WELL_CAPACITY}_SUCCESS`;
const FETCH_VARIANCE_EVENTS_SUCCESS = `${FETCH_VARIANCE_EVENTS}_SUCCESS`;

const filterRegExp = new RegExp(`${uiNamespace}/|${groupChartNamspace}/`);
export const STATE_KEY = 'ui';

const UIReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case STOP_RECORDING_VIDEO:
    case START_RECORDING_VIDEO: {
      return R.assocPath(
        ['screenInteraction', 'isRecording'],
        action.type === START_RECORDING_VIDEO,
        state,
      );
    }
    case SET_LEFT_PANEL_ACTIVE_TAB: {
      const leftPanelActiveTabId = action.payload || null;
      return { ...state, leftPanelActiveTabId };
    }
    case SET_RIGHT_PANEL_TEMPLATE: {
      const template = { [action.payload.name]: action.payload.data };
      const rightPanelTemplates = { ...state.rightPanelTemplates, ...template };
      return { ...state, rightPanelTemplates };
    }
    case SET_RIGHT_PANEL_DIALOG: {
      const rightPanelDialog = action.payload;
      const rightPanelDialogs = { ...state.rightPanelDialogs };
      if (rightPanelDialog.type && rightPanelDialog.data) {
        rightPanelDialogs[rightPanelDialog.type] = rightPanelDialog.data;
      }
      return { ...state, rightPanelDialog, rightPanelDialogs };
    }
    case EXPAND_RIGHT_PANEL: {
      return {
        ...state,
        rightPanelExpanded: true,
        rightPanelDialog: action.payload ?? initialState.rightPanelDialog,
      };
    }
    case SET_Y_AXIS_HOVERED: {
      return { ...state, yAxisHovered: action.payload };
    }
    case EXPAND_RIGHT_PANEL_WITHOUT_CHANGING_DIALOG: {
      return { ...state, rightPanelExpanded: true };
    }
    case COLLAPSE_RIGHT_PANEL: {
      return { ...state, rightPanelExpanded: false, rightPanelDialog: null };
    }

    case FETCH_WELL_CAPACITY_SUCCESS: {
      return R.assocPath(
        ['dataFetchingStatus', 'isCapacityEventsFetched'],
        true,
        state,
      );
    }
    case FETCH_WELL_CAPACITY: {
      return R.assocPath(
        ['dataFetchingStatus', 'isCapacityEventsFetched'],
        false,
        state,
      );
    }
    case FETCH_VARIANCE_EVENTS_SUCCESS: {
      return R.assocPath(
        ['dataFetchingStatus', 'isVarianceEventsFetched'],
        true,
        state,
      );
    }
    case FETCH_VARIANCE_EVENTS: {
      return R.assocPath(
        ['dataFetchingStatus', 'isVarianceEventsFetched'],
        false,
        state,
      );
    }
    case OPEN_EVENT_CONVERSATION: {
      return R.assoc('isEventConversationOpen', true, state);
    }
    case CLOSE_EVENT_CONVERSATION: {
      return R.assoc('isEventConversationOpen', false, state);
    }
    case SET_WAS_DRAGGING: {
      return R.assoc<boolean, UIState>('wasDragging', true, state);
    }
    case START_X_AXIS_DRAGGING: {
      return R.compose(
        R.assoc<boolean, UIState>('isXAxisDragging', true),
        R.assoc<boolean, UIState>('wasDragging', false),
      )(state);
    }
    case START_Y_AXIS_DRAGGING: {
      return R.assoc<boolean, UIState>('isYAxisDragging', true, state);
    }
    case STOP_X_AXIS_DRAGGING: {
      return R.assoc<boolean, UIState>('isXAxisDragging', false, state);
    }
    case STOP_Y_AXIS_DRAGGING: {
      return R.assoc<boolean, UIState>('isYAxisDragging', false, state);
    }
    case SET_LAST_EDITED_CAPACITY_CHANGE_EVENT:
      return R.assoc('lastEditedCapacityChangeEvent', action.payload, state);
    case SET_NEWLY_CREATED_VARIANCE_EVENT:
      return R.assoc('newlyCreatedVarianceEvent', action.payload, state);
    case SWITCH_TRELLISES: {
      const { name, checked } = action.payload;
      if (name === 'all') {
        const allTrellises = {
          [OIL]: checked,
          [GAS]: checked,
          [WATER]: checked,
          [BOE]: checked,
          [GOR]: checked,
          [WATERCUT]: checked,
          [GLR]: checked,
          [VARIANCE_TRELLIS]: checked,
        };
        if (checked === false) {
          let rightPanelDialog = state.rightPanelDialog;
          const dialogsToClose = [
            'CapacityChangeEvent',
            'VarianceChangeEvent',
            'WellNotes',
          ];

          if (dialogsToClose.includes(String(rightPanelDialog?.type))) {
            rightPanelDialog = initialState.rightPanelDialog;
          }

          return R.compose(
            R.assoc<Record<string, any>, UIState>('trellises', allTrellises),
            R.assoc('rightPanelDialog', rightPanelDialog),
          )(state);
        }

        return R.assoc<Record<string, any>, UIState>(
          'trellises',
          allTrellises,
          state,
        );
      }
      return R.assocPath<string, UIState, boolean>(
        ['trellises', name],
        checked,
        state,
      );
    }
    case SET_TRELLISES: {
      const trellises = action.payload;
      const trellisesMap = trellises.reduce((acc, trellisName) => {
        acc[trellisName] = true;

        return acc;
      }, R.clone(EMPTY_TRELLISES));

      return R.assoc<Record<string, any>, UIState>(
        'trellises',
        trellisesMap,
        state,
      );
    }
    case SWITCH_RIBBONS: {
      const { id } = action.payload;
      if (R.pathOr(false, ['ribbons', 'selectedRibbons', id], state)) {
        return R.assocPath(
          ['ribbons', 'selectedRibbons'],
          R.omit([id], state.ribbons.selectedRibbons),
          state,
        );
      }
      return R.assocPath(
        ['ribbons', 'selectedRibbons'],
        R.assoc(id, true, state.ribbons.selectedRibbons),
        state,
      );
    }
    case SET_RIBBONS: {
      const ribbons = action.payload;
      const ribbonsMap = ribbons.reduce((acc, ribbonId) => {
        acc[ribbonId] = true;

        return acc;
      }, {});

      return R.assocPath(['ribbons', 'selectedRibbons'], ribbonsMap, state);
    }
    case HIGHLIGHT_RIBBON_EVENT_DIVIDER: {
      const index = action.payload;

      return R.assocPath<string, UIState, IndexDialog>(
        ['ribbons', 'highlightedRibbonEvent'],
        { show: true, index },
        state,
      );
    }
    case HIGHLIGHT_RIBBON_EVENT_DIVIDER_OFF: {
      return R.assocPath<string, UIState, IndexDialog>(
        ['ribbons', 'highlightedRibbonEvent'],
        { show: false, index: 0 },
        state,
      );
    }
    case OPEN_RIBBON_EVENT_ALERT_WINDOW: {
      const index = action.payload;

      return R.assocPath<string, UIState, IndexDialog>(
        ['ribbons', 'ribbonEventAlertWindow'],
        { show: true, index },
        state,
      );
    }
    case CLOSE_RIBBON_EVENT_ALERT_WINDOW: {
      return R.assocPath<string, UIState, IndexDialog>(
        ['ribbons', 'ribbonEventAlertWindow'],
        { show: false, index: 0 },
        state,
      );
    }
    case OPEN_CAPACITY_DELETE_ALERT_WINDOW: {
      const index = action.payload;

      return R.assoc<IndexDialog, UIState>(
        'capacityDeleteAlertWindow',
        { show: true, index },
        state,
      );
    }
    case OPEN_CAPACITY_CANCEL_EDITS_ALERT_WINDOW: {
      const index = action.payload;

      return R.assoc<IndexDialog, UIState>(
        'capacityCancelEditsAlertWindow',
        { show: true, index },
        state,
      );
    }
    case OPEN_VARIANCE_ALERT_WINDOW: {
      const index = action.payload;

      return R.assoc<IndexDialog, UIState>(
        'varianceAlertWindow',
        { show: true, index },
        state,
      );
    }
    case OPEN_IMAGE_DETAILS_OVERLAY: {
      return R.assoc<{ show: boolean }, UIState>(
        'noteImageDetailsOverlay',
        { show: true },
        state,
      );
    }
    case CLOSE_IMAGE_DETAILS_OVERLAY: {
      return R.assoc<{ show: boolean }, UIState>(
        'noteImageDetailsOverlay',
        { show: false },
        state,
      );
    }
    case OPEN_ALLOC_ISSUE_ALERT_WINDOW: {
      const { index, id } = action.payload;

      return R.assoc<IndexDialog, UIState>(
        'allocIssueAlertWindow',
        { show: true, index, id },
        state,
      );
    }
    case CLOSE_CAPACITY_DELETE_ALERT_WINDOW: {
      return R.assoc<IndexDialog, UIState>(
        'capacityDeleteAlertWindow',
        { show: false, index: 0 },
        state,
      );
    }
    case CLOSE_CAPACITY_CANCEL_EDITS_ALERT_WINDOW: {
      return R.assoc<IndexDialog, UIState>(
        'capacityCancelEditsAlertWindow',
        { show: false, index: 0 },
        state,
      );
    }
    case CLOSE_VARIANCE_ALERT_WINDOW: {
      return R.assoc<IndexDialog, UIState>(
        'varianceAlertWindow',
        { show: false, index: 0 },
        state,
      );
    }
    case CLOSE_ALLOC_ISSUE_ALERT_WINDOW: {
      return R.assoc<{ show: boolean; id: string }, UIState>(
        'allocIssueAlertWindow',
        { show: false, id: '', index: 0 },
        state,
      );
    }
    case SET_UI_STATE: {
      const stateModification = action.payload;
      const newState = R.mergeDeepRight(state, stateModification);

      return newState;
    }
    case TOGGLE_ISSUES_VISIBILITY: {
      const prevShowIssues = state.allocIssuesVisibility;

      return R.assoc<boolean, UIState>(
        'allocIssuesVisibility',
        !prevShowIssues,
        state,
      );
    }
    case ACTIVATE_DRILLDOWN_PANEL: {
      return R.assoc<string, UIState>('activePanel', DRILLDOWN_PANEL, state);
    }
    case ACTIVATE_NOTES_PANEL: {
      return R.assoc<string, UIState>('activePanel', NOTES_PANEL, state);
    }

    case RESET_ACTIVE_PANEL: {
      return R.assoc<null, UIState>('activePanel', null, state);
    }
    case SET_NOTES_PRODUCTION_DAY: {
      const newDate = action.payload;

      return R.assoc<Date, UIState>('notesProductionDay', newDate, state);
    }
    case ENABLE_ADDITION_MODE: {
      if (state.zoomInMode.isOn) return state;
      const subject = action.payload;
      const additionMode = { isOn: true, subject };
      return R.assoc<Mode, UIState>('additionMode', additionMode, state);
    }
    case DISABLE_ADDITION_MODE: {
      const additionMode = { isOn: false, subject: null };

      return R.compose(
        R.assoc<Mode, UIState>('additionMode', additionMode),
        R.assoc<Mode, UIState>('newlyCreatedVarianceEvent', null),
      )(state);
    }
    case ENABLE_ZOOM_IN_MODE: {
      if (state.additionMode.isOn) return state;
      return R.assoc<Mode, UIState>('zoomInMode', { isOn: true }, state);
    }
    case DISABLE_ZOOM_IN_MODE: {
      return R.assoc<Mode, UIState>('zoomInMode', { isOn: false }, state);
    }
    case HIGHLIGHT_ALLOC_ISSUE: {
      const issueId = action.payload;
      const highlightedAllocIssue = { show: true, id: issueId };
      const highlightedAllocIssueDivider = { show: false, id: '' };

      return R.compose(
        R.assoc<IdDialog, UIState>(
          'highlightedAllocIssue',
          highlightedAllocIssue,
        ),
        R.assoc('highlightedAllocIssueDivider', highlightedAllocIssueDivider),
      )(state);
    }
    case HIGHLIGHT_ALLOC_ISSUE_DIVIDER: {
      const issueId = action.payload;
      const highlightedAllocIssueDivider = { show: true, id: issueId };
      const highlightedAllocIssue = { show: false, id: '' };

      return R.compose(
        R.assoc<IdDialog, UIState>(
          'highlightedAllocIssueDivider',
          highlightedAllocIssueDivider,
        ),
        R.assoc('highlightedAllocIssue', highlightedAllocIssue),
      )(state);
    }
    case HIGHLIGHT_ALLOC_ISSUE_OFF: {
      return R.assoc<IdDialog, UIState>(
        'highlightedAllocIssue',
        { show: false, id: '' },
        state,
      );
    }
    case HIGHLIGHT_ALLOC_ISSUE_DIVIDER_OFF: {
      return R.assoc<IdDialog, UIState>(
        'highlightedAllocIssueDivider',
        { show: false, id: '' },
        state,
      );
    }
    case HIGHLIGHT_CAPACITY: {
      const index = action.payload;
      const highlightedCapacity = { show: true, index };
      const highlightedCapacityDivider = { show: false, index: 0 };

      return R.compose(
        R.assoc<IndexDialog, UIState>(
          'highlightedCapacity',
          highlightedCapacity,
        ),
        R.assoc('highlightedCapacityDivider', highlightedCapacityDivider),
      )(state);
    }
    case HIGHLIGHT_CAPACITY_DIVIDER: {
      const index = action.payload;
      const highlightedCapacityDivider = { show: true, index };
      const highlightedCapacity = { show: false, index: 0 };

      return R.compose(
        R.assoc<IndexDialog, UIState>(
          'highlightedCapacityDivider',
          highlightedCapacityDivider,
        ),
        R.assoc('highlightedCapacity', highlightedCapacity),
      )(state);
    }
    case HIGHLIGHT_CAPACITY_OFF: {
      return R.assoc<IndexDialog, UIState>(
        'highlightedCapacity',
        { show: false, index: 0 },
        state,
      );
    }
    case HIGHLIGHT_CAPACITY_DIVIDER_OFF: {
      return R.assoc<IndexDialog, UIState>(
        'highlightedCapacityDivider',
        { show: false, index: 0 },
        state,
      );
    }
    case HIGHLIGHT_VARIANCE: {
      const index = action.payload;
      const highlightedVariance = { show: true, index };
      const highlightedVarianceDivider = { show: false, index: 0 };

      return R.compose(
        R.assoc<IndexDialog, UIState>(
          'highlightedVariance',
          highlightedVariance,
        ),
        R.assoc('highlightedVarianceDivider', highlightedVarianceDivider),
      )(state);
    }
    case HIGHLIGHT_VARIANCE_DIVIDER: {
      const index = action.payload;
      const highlightedVarianceDivider = { show: true, index };
      const highlightedVariance = { show: false, index: 0 };

      return R.compose(
        R.assoc<IndexDialog, UIState>(
          'highlightedVarianceDivider',
          highlightedVarianceDivider,
        ),
        R.assoc('highlightedVariance', highlightedVariance),
      )(state);
    }
    case HIGHLIGHT_VARIANCE_OFF: {
      return R.assoc<IndexDialog, UIState>(
        'highlightedVariance',
        { show: false, index: 0 },
        state,
      );
    }
    case HIGHLIGHT_VARIANCE_DIVIDER_OFF: {
      return R.assoc<IndexDialog, UIState>(
        'highlightedVarianceDivider',
        { show: false, index: 0 },
        state,
      );
    }
    case HIGHLIGHT_OFF_EVERYTHING: {
      return R.compose(
        R.assoc<IndexDialog, UIState>('highlightedAllocIssue', {
          show: false,
          index: 0,
        }),
        R.assoc('highlightedAllocIssueDivider', { show: false, index: 0 }),
        R.assoc('highlightedCapacity', { show: false, index: 0 }),
        R.assoc('highlightedCapacityDivider', { show: false, index: 0 }),
        R.assoc('highlightedVariance', { show: false, index: 0 }),
        R.assoc('highlightedVarianceDivider', { show: false, index: 0 }),
      )(state);
    }
    case LOGOUT: {
      return initialState;
    }
    case FETCH_WELL_PRODUCTION: {
      return R.assoc<number, UIState>(
        'isProductionLoading',
        state.isProductionLoading + 1,
        state,
      );
    }
    case `${FETCH_WELL_PRODUCTION}_SUCCESS`: {
      return R.assoc<number, UIState>(
        'isProductionLoading',
        state.isProductionLoading - 1,
        state,
      );
    }
    case ENABLE_GROUP_MODE: {
      const subject = action.payload;
      const data = { isOn: true, subject };
      return R.assoc<Mode, UIState>('groupMode', data, state);
    }
    case CHANGE_GROUP_MODE_SUBJECT: {
      const subject = action.payload;

      return R.assoc<Mode, UIState>(
        'groupMode',
        { isOn: true, subject },
        state,
      );
    }
    case OPEN_CONVERSATION:
    case DISABLE_GROUP_MODE: {
      return R.compose(
        R.assoc<Mode, UIState>('groupMode', { isOn: false, subject: null }),
        R.assoc('currentGroup', initialState.currentGroup),
      )(state);
    }
    case SET_CURRENT_WELL_ID: {
      const currentId = action.payload.id;

      return R.assoc<string, UIState>('currentWellId', currentId, state);
    }
    case SET_CURRENT_GROUP: {
      const group = action.payload;

      return R.assoc<{ subject: string; item: string }, UIState>(
        'currentGroup',
        group,
        state,
      );
    }
    case FETCH_NET_GROUP_CHART_DATE_RANGE:
    case FETCH_GROUP_CHART_DATE_RANGE: {
      return R.assoc<number, UIState, UIState>(
        'groupChartLoadingCounter',
        state.groupChartLoadingCounter + 1,
        state,
      );
    }
    case FETCH_GROUP_VARIANCE_CHART_DATE_RANGE: {
      return R.assoc(
        'groupVarianceChartLoadingCounter',
        state.groupVarianceChartLoadingCounter + 1,
        state,
      );
    }
    case `${FETCH_NET_GROUP_CHART_DATE_RANGE}_SUCCESS`:
    case `${FETCH_GROUP_CHART_DATE_RANGE}_SUCCESS`: {
      return R.assoc<number, UIState, UIState>(
        'groupChartLoadingCounter',
        state.groupChartLoadingCounter - 1,
        state,
      );
    }
    case `${FETCH_GROUP_VARIANCE_CHART_DATE_RANGE}_SUCCESS`: {
      return R.assoc(
        'groupVarianceChartLoadingCounter',
        state.groupVarianceChartLoadingCounter - 1,
        state,
      );
    }
    case RESET_CURRENT_GROUP: {
      return R.assoc<Record<string, any>, UIState>(
        'currentGroup',
        initialState.currentGroup,
        state,
      );
    }
    case ENABLE_REGION_OF_INTEREST_MODE: {
      if (state.additionMode.isOn) return state;
      if (state.zoomInMode.isOn) return state;
      if (state.notesMode.isOn) return state;
      return R.assoc<Mode, UIState>(
        'regionOfInterestMode',
        { isOn: true },
        state,
      );
    }
    case DISABLE_REGION_OF_INTEREST_MODE: {
      return R.assoc<Mode, UIState>(
        'regionOfInterestMode',
        { isOn: false },
        state,
      );
    }
    case ENABLE_NOTES_MODE: {
      if (state.additionMode.isOn) return state;
      if (state.zoomInMode.isOn) return state;
      if (state.regionOfInterestMode.isOn) return state;
      return R.assoc<Mode, UIState>('notesMode', { isOn: true }, state);
    }
    case DISABLE_NOTES_MODE: {
      return R.assoc<Mode, UIState>('notesMode', { isOn: false }, state);
    }
    case SET_TOOLTIP_DATA: {
      const { data } = action.payload;
      if (
        R.path(['tooltipData', 'trellisTooltipData'], state) === null &&
        R.path(['trellisTooltipData'], data) === null
      ) {
        return state;
      }
      return R.assoc<Date, UIState>('tooltipData', data, state);
    }
    case OPEN_FILTER_LAYOUTS_PANEL: {
      return R.assoc('isOpenFilterLayoutsPanel', true, state);
    }
    case CLOSE_FILTER_LAYOUTS_PANEL: {
      return R.assoc('isOpenFilterLayoutsPanel', false, state);
    }
    case SET_CHART_WIDTH: {
      return R.assoc('chartWidth', action.payload, state);
    }
    case TOGGLE_NOTES_RIBBON_SECTION_PIN: {
      const prev = state.notesSection;
      return R.assoc(
        'notesSection',
        { ...prev, isPinned: !prev.isPinned },
        state,
      );
    }
    case HOVER_NOTES_RIBBON_SECTION: {
      const prev = state.notesSection;
      if (!prev.isHovered && !prev.isPinned) {
        return R.assoc('notesSection', { ...prev, isHovered: true }, state);
      }
      return state;
    }
    case UNHOVER_NOTES_RIBBON_SECTION: {
      const prev = state.notesSection;
      if (prev.isHovered && !prev.isPinned) {
        return R.assoc('notesSection', { ...prev, isHovered: false }, state);
      }
      return state;
    }
    case SET_SHOULD_FILTERS_SCROLL_RIGHT: {
      return R.assoc('shouldFiltersScrollRight', action.payload, state);
    }
    case SET_FILTERS_COLUMNS_WIDTH: {
      return R.assoc('filtersColumnsWidth', action.payload, state);
    }
    case SET_CAPACITY_LINE_DRAGGING: {
      return R.assoc('capacityLineDragging', action.payload, state);
    }
    default: {
      return state;
    }
  }
};

export const getUIState = (state: RootState) => state[STATE_KEY] as UIState;

export const getIsRightPanelExpanded = createSelector(
  getUIState,
  state => state.rightPanelExpanded,
);

export const getRightPanelDialog = createSelector(
  getUIState,
  state => state.rightPanelDialog,
);

export const getRightPanelDialogType = createSelector(
  getUIState,
  state => state.rightPanelDialog?.type,
);

export const getRightPanelDialogs = createSelector(
  getUIState,
  state => state.rightPanelDialogs,
);

export const getRightPanelTemplates = createSelector(
  getUIState,
  state => state.rightPanelTemplates,
);

export const getIsOpenFilterLayoutsPanel = createSelector(
  getUIState,
  state => state.isOpenFilterLayoutsPanel,
);

export const getDataFetchingStatus = createSelector(
  getUIState,
  state => state.dataFetchingStatus,
);

export const getYAxisHovered = (state: RootState) =>
  state[STATE_KEY].yAxisHovered;

export const getTrellises = (state: RootState) =>
  state[STATE_KEY].trellises || DEFAULT_TRELLISES;

export const getTrellisesArray = createSelector(getTrellises, trellises =>
  orderChartsNames(trellises),
);

export const getCapacityDeleteAlertWindow = (state: RootState) =>
  state[STATE_KEY].capacityDeleteAlertWindow || { show: false, index: null };

export const getCapacityEditsAlertWindow = (state: RootState) =>
  state[STATE_KEY].capacityCancelEditsAlertWindow || {
    show: false,
    index: null,
  };

export const getCurrentWellId = (state: RootState) =>
  state[STATE_KEY].currentWellId || '';

export const getCurrentGroup = createSelector(getUIState, state => {
  const group = state.currentGroup || {};
  if (R.isEmpty(group)) return group;
  return {
    subject: group.subject,
    item: group.item === 'empty' ? '' : group.item,
  };
});
export const getVarianceAlertWindow = (state: RootState) =>
  state[STATE_KEY].varianceAlertWindow || { show: false, index: null };
export const getNoteImageDetailsOverlay = (state: RootState) =>
  state[STATE_KEY].noteImageDetailsOverlay || { show: false };
export const getAllocIssueAlertWindow = (state: RootState) =>
  state[STATE_KEY].allocIssueAlertWindow || { show: false, index: 0, id: '' };
export const getAllocIssuesVisibility = (state: RootState) =>
  state[STATE_KEY].allocIssuesVisibility || false;
export const getActivePanel = (state: RootState): string | null =>
  state[STATE_KEY].activePanel || null;
export const getNotesProductionDay: Selector<Date> = createSelector(
  getToday,
  state => state[STATE_KEY].notesProductionDay,
  (today, notesProductionDay) => {
    const minToday = getMinDate(today, getTodayWithTimezoneOffset());
    return timeDay.round(getMinDate(minToday, notesProductionDay));
  },
);
export const getAdditionMode = (
  state: RootState,
): { isOn: boolean; subject: string | null } =>
  state[STATE_KEY].additionMode || { isOn: false, subject: null };
export const getNotesMode = (state: RootState) =>
  state[STATE_KEY].notesMode || { isOn: false };
export const getRegionOfInterestMode = (state: RootState) =>
  state[STATE_KEY].regionOfInterestMode || { isOn: false };
export const getZoomInMode = (state: RootState) =>
  state[STATE_KEY].zoomInMode || { isOn: false };
export const getHighlightedAllocIssue = (state: RootState) =>
  state[STATE_KEY].highlightedAllocIssue || { show: false, id: '' };
export const getHighlightedAllocIssueDivider = (state: RootState) =>
  state[STATE_KEY].highlightedAllocIssueDivider || { show: false, id: '' };
export const getHighlightedCapacity = (state: RootState) =>
  state[STATE_KEY].highlightedCapacity || { show: false, index: '' };
export const getHighlightedCapacityDivider = (state: RootState) =>
  state[STATE_KEY].highlightedCapacityDivider || { show: false, index: '' };
export const getHighlightedVariance = (state: RootState) =>
  state[STATE_KEY].highlightedVariance || { show: false, index: '' };
export const getHighlightedVarianceDivider = (state: RootState) =>
  state[STATE_KEY].highlightedVarianceDivider
    ? ({ ...state[STATE_KEY].highlightedVarianceDivider } as {
        show: boolean;
        index: number;
      })
    : { show: false, index: 0 };
export const getProductionLoadingStatus = (state: RootState) =>
  state[STATE_KEY].isProductionLoading || false;
export const getGroupMode = (state: DrilldownTableState): Mode =>
  state[STATE_KEY].groupMode || [STATE_KEY, 'groupMode'];

export const getGroupChartLoadingStatus = (state: RootState) =>
  state[STATE_KEY].groupChartLoadingCounter !== 0 || false;
export const getGroupVarainceChartLoadingStatus = createSelector(
  getUIState,
  state => state.groupVarianceChartLoadingCounter !== 0,
);
export const getTooltipData = (state: RootState) =>
  state[STATE_KEY].tooltipData as TooltipData | null;
export const getSelectedRibbons = (state: RootState) =>
  state[STATE_KEY].ribbons.selectedRibbons as { [riboons: string]: boolean };
export const getRibbonEventAlertWindow = (state: RootState) =>
  state[STATE_KEY].ribbons.ribbonEventAlertWindow || {
    show: false,
    index: null,
  };
export const getHighlightedRibbonEvent = (state: RootState): IndexDialog =>
  state[STATE_KEY].ribbons.highlightedRibbonEvent;
export const getIsXAxisDragging = (state: RootState): boolean =>
  state[STATE_KEY].isXAxisDragging;
export const getIsYAxisDragging = (state: RootState): boolean =>
  state[STATE_KEY].isYAxisDragging;
export const getWasDragging = (state: RootState): boolean =>
  state[STATE_KEY].wasDragging;

// TODO: cleanup isEventConversationOpen, it seems outdated
export const getIsEventConversationOpen = (state: RootState): boolean =>
  state[STATE_KEY].isEventConversationOpen;

export const getLastEditedCapacityChangeEvent = (
  state: RootState,
): CapacityChangeEvent | null => state[STATE_KEY].lastEditedCapacityChangeEvent;
export const getNewlyCreatedVarianceEvent = (
  state: RootState,
): VarianceEvent | null => state[STATE_KEY].newlyCreatedVarianceEvent;

export const getLeftPanelActiveTabId = (state: RootState): string | null =>
  state[STATE_KEY].leftPanelActiveTabId;

export const getChartWidth = (state: RootState): number =>
  state[STATE_KEY].chartWidth;

export const getScreenInteraction = (
  state: Record<string, any>,
): UIState['screenInteraction'] => state[STATE_KEY].screenInteraction;

export const getShouldFiltersScrollRight = createSelector(
  getUIState,
  uiState => uiState.shouldFiltersScrollRight,
);

export default filterActions(
  UIReducer as any,
  action =>
    action.type.match(filterRegExp) ||
    [
      FETCH_WELL_CAPACITY,
      FETCH_WELL_CAPACITY_SUCCESS,
      FETCH_WELL_PRODUCTION,
      FETCH_VARIANCE_EVENTS_SUCCESS,
      FETCH_VARIANCE_EVENTS,
      OPEN_CONVERSATION,
      LOGOUT,
      `${FETCH_WELL_PRODUCTION}_SUCCESS`,
    ].includes(action.type),
) as Reducer<UIState>;

export const getNotesSection = (state: RootState): NotesSection =>
  state[STATE_KEY].notesSection;

export const getFiltersColumnsWidth = (
  state: RootState,
): { [key: string]: number } => state[STATE_KEY].filtersColumnsWidth;

export const getCapacityLineDragging = (state: RootState): boolean =>
  state[STATE_KEY].capacityLineDragging;
