import * as R from 'ramda';

import {
  getCalculatedSeriesFromLayoutConfiguration,
  getSeriesFromLayoutConfiguration,
} from './getSeriesFromLayoutConfiguration';

export const calculateSeriesConfigurationsDifference = (
  a: string,
  b: string,
) => {
  const aSeries = getSeriesFromLayoutConfiguration(a);
  const bSeries = getSeriesFromLayoutConfiguration(b);
  return aSeries.length > bSeries.length
    ? R.difference(aSeries, bSeries)
    : R.difference(bSeries, aSeries);
};

export const calculateCalculatedSeriesConfigurationsDifference = (
  a: string,
  b: string,
) => {
  const aSeries = getCalculatedSeriesFromLayoutConfiguration(a);
  const bSeries = getCalculatedSeriesFromLayoutConfiguration(b);
  return aSeries.length > bSeries.length
    ? R.difference(aSeries, bSeries)
    : R.difference(bSeries, aSeries);
};
