import { NormalizedSeries } from '../models';

const getSeriesData = (
  optionId: string,
  series: { [key: string]: NormalizedSeries[] },
) => {
  return optionId.startsWith('s') || optionId.startsWith('c')
    ? series[optionId] ?? []
    : series['dataSeries'];
};

export default getSeriesData;
