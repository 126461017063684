import { connectRouter } from 'connected-react-router';
import { LOGOUT } from 'modules/auth/AuthActions';
import {
  getCoreSeriesOptionsToDisplay,
  getDataSeriesGroupsWithColorAndTypeToDisplay,
} from 'modules/chartOptions/ChartOptionsReducer';
import { getDrilldownTableParams } from 'modules/drilldownTable/DrilldownTableReducer';
import { getForecastStatus } from 'modules/externalForecast/ExternalForecastReducer';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import {
  getIsRightPanelExpanded,
  getSelectedRibbons,
} from 'modules/ui/UIReducer';
import { createSelector } from 'reselect';
import { CustomRouterState, RouterQueryParameters } from './models/router';
import { REPLACE_PARAMETERS, SET_PARAMETERS } from './RouterActions';
import { parseSearchParams } from './utils/router';
import { DataSeriesGroupToDisplay } from '../chartOptions/models';

export const STATE_KEY = 'router';

const customConnectRouter = history => {
  const routerReducer = connectRouter(history);
  const initialState = routerReducer(undefined, { type: '' });

  const createInitialState = () => ({
    ...initialState,
    dashboardQueryParameters: {
      pathname: window.location.pathname,
      search: parseSearchParams(window.location.search),
      meta: {},
    },
  });

  const customReducer = (
    state: CustomRouterState = createInitialState(),
    action,
  ): CustomRouterState => {
    const newRouterState = routerReducer(state, action);

    switch (action.type) {
      case REPLACE_PARAMETERS: {
        const { pathname, search, meta } = action.payload;
        return {
          ...newRouterState,
          dashboardQueryParameters: {
            pathname: pathname ?? state.dashboardQueryParameters.pathname,
            search: {
              ...state.dashboardQueryParameters.search,
              ...search,
            },
            meta: {
              ...state.dashboardQueryParameters.meta,
              ...meta,
            },
          },
        };
      }
      case SET_PARAMETERS: {
        const { pathname, search, meta } = action.payload;
        return {
          ...newRouterState,
          dashboardQueryParameters: {
            pathname: pathname ?? state.dashboardQueryParameters.pathname,
            search: search ?? state.dashboardQueryParameters.search,
            meta: meta ?? {},
          },
        };
      }
      case LOGOUT:
        return createInitialState();
      default:
        return {
          ...state,
          ...newRouterState,
        };
    }
  };

  return customReducer;
};

export const getUrlValues = (state): RouterQueryParameters =>
  (state[STATE_KEY] as CustomRouterState).dashboardQueryParameters;

export const getDashboardRedirectParameters = createSelector(
  state =>
    getCoreSeriesOptionsToDisplay(state).map(
      (series: { id: any }) => series.id,
    ),
  getDataSeriesGroupsWithColorAndTypeToDisplay,
  getSelectedRibbons,
  getIsRightPanelExpanded,
  getForecastStatus,
  getDrilldownTableParams,
  getExtremeDates,
  (
    coreSeries,
    dataSeries,
    selectedRibbons,
    isRightPanelExpanded,
    forecastStatus,
    { grossNet, compareOption },
    extremeDates,
  ) => {
    const sortedSeries = dataSeries.reduce(
      (acc, series) => {
        if (series.dataSeriesGroup.some(elem => !/s/.test(elem.id))) {
          acc.dataSeries.push(series);
        } else {
          acc.sensorSeries.push(series);
        }
        return acc;
      },
      {
        sensorSeries: [] as DataSeriesGroupToDisplay,
        dataSeries: [] as DataSeriesGroupToDisplay,
      },
    );

    return {
      coreSeries,
      dataSeries: sortedSeries.dataSeries,
      legend: isRightPanelExpanded,
      external: forecastStatus,
      grossNet,
      compare: compareOption,
      ribbons: Object.keys(selectedRibbons),
      extremeDates,
      sensorSeries: sortedSeries.sensorSeries,
    };
  },
);

export default customConnectRouter;
