import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient, ApolloLink, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import * as R from 'ramda';

import AppConfig from 'config/AppConfig';

const request = async (operation: any) => {
  const token = R.propOr(null, 'token', operation.variables);
  operation.variables = R.omit(['token'], operation.variables);

  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(_operation => request(_operation))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const uploadLink = createUploadLink({ uri: AppConfig.apiUrl });

//$FlowFixMe
const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          //eslint-disable-next-line no-console
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }

      if (networkError) {
        //eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    requestLink,
    uploadLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'none',
    },
  },
});

export default client;
