import { oembed } from '@loomhq/loom-embed';

export const getEmbedLoom = async (
  sharedUrl: string,
  options?: { width?: number; height?: number },
) => {
  const { html } = await oembed(sharedUrl, options);

  return html;
};
