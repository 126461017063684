import type { MetricsPluginInternals } from '../../models';

const HeapPlugin: MetricsPluginInternals = {
  isSourceCodeLoaded: () => {
    return !!(
      window.heap &&
      window.heap.identify &&
      window.heap.resetIdentity &&
      window.heap.addUserProperties &&
      window.heap.track
    );
  },

  identifyUser: user => {
    window.heap.identify(user.id);
    window.heap.addUserProperties(user);
  },

  anonomizeUser: () => {
    window.heap.resetIdentity();
    window.heap.clearEventProperties();
  },

  captureEvent: (event, data) => {
    window.heap.track(
      event.name,
      Object.assign({ eventCode: event.code }, data),
    );
  },

  captureError: error => {
    return;
  },

  initialize: () => {
    const DEFAULT_HEAP_ID = '4212603160';
    const HEAP_ID = process.env.REACT_APP_HEAP_ID || DEFAULT_HEAP_ID;

    window.heap.load(HEAP_ID);
  },
};

export default HeapPlugin;
