import gql from 'graphql-tag';

import type { AC } from 'store/models';
import { MetricsData, MetricsEventsKey } from './models';

export const namespace = 'METRICS';
export const TRACK_EVENT = `${namespace}/TRACK_EVENT`;
export const FETCH_METRICS_TOOLS_CONFIG = `${namespace}/FETCH_METRICS_TOOLS_CONFIG`;
export const FETCH_METRICS_TOOLS_CONFIG_SUCCESS = `${namespace}/FETCH_METRICS_TOOLS_CONFIG_SUCCESS`;

const metricsToolsConfigQuery = gql`
  query MetricsToolsConfigQuery {
    metricsToolsConfiguration {
      newRelic {
        accId
        appId
        licenseKey
      }
      logRocket {
        appId
      }
    }
  }
`;

export const fetchMetricsToolsConfig = () => ({
  type: FETCH_METRICS_TOOLS_CONFIG,
  payload: {
    key: 'metricsToolsConfiguration',
    graphql: {
      query: metricsToolsConfigQuery,
    },
  },
});

export const captureEvent: AC<{
  key: MetricsEventsKey;
  data?: MetricsData;
}> = payload => ({
  type: TRACK_EVENT,
  payload,
});
