import { timeDay, utcDay } from 'd3-time';

import { RIBBON_HEIGHT as height } from 'modules/chart/models/chart';
import { RibbonEvent } from 'modules/ribbon/models';

const getRibbonEventBounds = (
  ribbonEvent: RibbonEvent,
  todayDate: Date,
  extremeDates: { min: Date; max: Date },
  xScale: (date: Date) => number,
  chartWidth: number,
) => {
  const startDayDate = new Date(ribbonEvent.dayStart);
  const endDayDate = new Date(ribbonEvent.dayEnd || todayDate);
  const x1 = xScale(startDayDate);
  const x2 = ribbonEvent.noEndDate
    ? xScale(timeDay.round(todayDate))
    : xScale(timeDay.round(timeDay.offset(endDayDate, 1)));

  const minDate = new Date(extremeDates.min);
  const maxDate = new Date(extremeDates.max);
  const startsAfterExtreme = startDayDate.getTime() > minDate.getTime();
  const endsBeforeExtreme = endDayDate.getTime() < maxDate.getTime();

  const widthStart = startsAfterExtreme ? x1 : xScale(minDate);
  const widthEnd = endsBeforeExtreme ? x2 : xScale(utcDay.offset(maxDate, 1));
  const width = widthEnd - widthStart;

  return {
    x1,
    x2,
    yLevel: 0,
    y1: 0,
    y2: 0,
    textX: widthStart + width / 2,
    width,
    height,
  };
};

export default getRibbonEventBounds;
