import { timeFormat } from 'd3-time-format';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useNonInputKeydown } from 'hooks/useKeydown';
import Button from 'components/Button';
import CloseOnMouseClickOutside from 'components/CloseOnMouseClickOutside';
import { getPermissions } from 'modules/auth/AuthReducer';
import RightPanel from 'modules/dashboard/components/RightPanel';

import {
  getRibbonOptions,
  getEventsOfSelectedRibbons,
  getRibbonById,
} from 'modules/ribbon/RibbonReducer';
import {
  getCurrentWellId,
  getHighlightedRibbonEvent,
  getWasDragging,
} from 'modules/ui/UIReducer';
import {
  highlightRibbonEventDivider,
  highlightRibbonEventDividerOff,
} from 'modules/ui/UIActions';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import useAdditionMode from 'modules/ui/hooks/useAdditionMode';
import EventNoteTooltip from 'modules/eventNotes/components/EventNoteTooltip';
import { useSelector } from 'store/models';
import LegendItemDescriptionTooltip from '../../dashboard/components/LegendItemDescriptionTooltip';

const RibbonDetailsPanel = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();
  const { additionMode, additionModeOn } = useAdditionMode();
  const { isAllowedEditRibbonEvents } = useSelector(getPermissions);
  const ribbonOptions = useSelector(getRibbonOptions);
  const highlightedRibbonEvent = useSelector(getHighlightedRibbonEvent);
  const currentRibbon = useSelector(s => getRibbonById(s, rightPanel.dialogId));
  const currentWellId = useSelector(getCurrentWellId);
  const selectedRibbonEvents = useSelector(getEventsOfSelectedRibbons);
  const chartWasDragging = useSelector(getWasDragging);

  const sortedRibbonEvents = React.useMemo(() => {
    const ribbonEvents = selectedRibbonEvents[rightPanel.dialogId] || [];
    const newestFirst = (a, b) => b.dayStart.getTime() - a.dayStart.getTime();
    return R.sort(newestFirst, ribbonEvents);
  }, [selectedRibbonEvents, rightPanel.dialogId]);

  const onRibbonDetailsPanelClose = React.useCallback(() => {
    rightPanel.unsetDialogOfType('RibbonDetails');
  }, [rightPanel.unsetDialogOfType]);

  const onRibbonEventClick = React.useCallback(
    (id: string) => rightPanel.setDialog({ type: 'RibbonEvent', data: { id } }),
    [rightPanel.setDialog],
  );

  const isAddingRibbonEvent = additionMode.subject === 'ribbonEvent';
  const shouldShowRibbonEvents =
    !R.isEmpty(sortedRibbonEvents) && !R.isEmpty(ribbonOptions);

  useNonInputKeydown(
    ({ keyName }) => {
      if (!rightPanel.isDialogOfType('RibbonDetails')) return;
      if (keyName === 'ESCAPE') return onRibbonDetailsPanelClose();
    },
    [rightPanel.isDialogOfType, onRibbonDetailsPanelClose],
  );

  return (
    <RightPanel
      onDialogClose={onRibbonDetailsPanelClose}
      isShown
      title={`${R.pathOr('', ['name'], currentRibbon)} Ribbon`}
    >
      <CloseOnMouseClickOutside
        exceptForClassName="ribbon-details-interactive"
        closeHandler={() => !chartWasDragging && onRibbonDetailsPanelClose()}
        event="mouseup"
      >
        <RibbonDetailsPanel.Wrapper>
          <RibbonDetailsPanel.Events>
            {!shouldShowRibbonEvents && !isAddingRibbonEvent && (
              <RibbonDetailsPanel.NoEventsMsg>
                <strong>No events to show.</strong>
              </RibbonDetailsPanel.NoEventsMsg>
            )}

            {!shouldShowRibbonEvents && isAddingRibbonEvent && (
              <RibbonDetailsPanel.NoEventsMsg>
                <strong>Event creation in progress...</strong>
                <span>Click on the chart to specify a start date.</span>
              </RibbonDetailsPanel.NoEventsMsg>
            )}

            {shouldShowRibbonEvents && (
              <>
                <RibbonDetailsPanel.EventsHeader>
                  {`${R.pathOr('', ['name'], currentRibbon)} Ribbon Events`}
                </RibbonDetailsPanel.EventsHeader>

                {sortedRibbonEvents.map(event => (
                  <RibbonDetailsPanel.Item
                    key={event.id}
                    onClick={() => onRibbonEventClick(event.id)}
                    onMouseEnter={() =>
                      dispatch(highlightRibbonEventDivider(event.id))
                    }
                    onMouseLeave={() =>
                      dispatch(highlightRibbonEventDividerOff())
                    }
                    isHighlighted={
                      highlightedRibbonEvent &&
                      highlightedRibbonEvent.show &&
                      highlightedRibbonEvent.index === event.id
                    }
                  >
                    {ribbonOptions[event.ribbonOptionId] &&
                      ribbonOptions[event.ribbonOptionId].color && (
                        <RibbonDetailsPanel.ColorMark
                          color={ribbonOptions[event.ribbonOptionId].color}
                        />
                      )}
                    <RibbonDetailsPanel.EventLabel>
                      {`${timeFormat('%m/%d/%y')(event.dayStart)}: ${
                        ribbonOptions[event.ribbonOptionId].type
                      }`}
                      <RibbonDetailsPanel.CommentContainer>
                        <EventNoteTooltip
                          eventType="ribbon"
                          eventId={event.id}
                          wellId={currentWellId}
                        />
                      </RibbonDetailsPanel.CommentContainer>

                      <RibbonDetailsPanel.CommentContainer>
                        <LegendItemDescriptionTooltip
                          description={event.description}
                        />
                      </RibbonDetailsPanel.CommentContainer>
                    </RibbonDetailsPanel.EventLabel>
                  </RibbonDetailsPanel.Item>
                ))}
              </>
            )}
          </RibbonDetailsPanel.Events>

          <RibbonDetailsPanel.Footer>
            {!isAddingRibbonEvent && (
              <Button
                width={125}
                onClick={() => additionModeOn('ribbonEvent')}
                disabled={!isAllowedEditRibbonEvents || currentRibbon?.readOnly}
              >
                Add Ribbon Event
              </Button>
            )}
          </RibbonDetailsPanel.Footer>
        </RibbonDetailsPanel.Wrapper>
      </CloseOnMouseClickOutside>
    </RightPanel>
  );
};

export default RibbonDetailsPanel;

RibbonDetailsPanel.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

RibbonDetailsPanel.Events = styled.div`
  flex: 1;
  overflow: auto;
`;

RibbonDetailsPanel.NoEventsMsg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  strong {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }
`;

RibbonDetailsPanel.EventsHeader = styled.div`
  height: 35px;
  padding: 15px 0 0 20px;
  font-size: 14px;
  font-weight: bold;
`;

RibbonDetailsPanel.Item = styled.div`
  display: flex;
  padding: 10px 0 10px 20px;
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props: Record<string, any>) =>
    props.isHighlighted ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

RibbonDetailsPanel.ColorMark = styled.div`
  min-width: 20px;
  min-height: 20px;
  background-color: ${(props: Record<string, any>) =>
    props.color ? props.color : 'black'};
  margin-right: 8px;
`;

RibbonDetailsPanel.EventLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

RibbonDetailsPanel.CommentContainer = styled.span``;

RibbonDetailsPanel.Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid #ccc;
  min-height: 54px;
`;
