import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';

import * as React from 'react';
import styled from 'styled-components';

import type { Group } from 'modules/well/models/well';

import Button from 'components/Button';

import useMenu from 'hooks/useMenu';
import { groupingAttributes } from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';
import { Mode } from '../../ui/models/ui';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { GroupIcon } from 'components/Icons';
import { useSelector } from '../../../store/models';
import { getFlagsStateMap } from '../../featureFlags/FeatureFlagsReducer';

interface GroupMenuType {
  groupsByOrder: Group[];
  handleGroupSelection: (group: MouseEvent) => void;
  groupMode: Mode;
}

const GroupMenu = ({
  groupsByOrder,
  handleGroupSelection,
  groupMode,
}: GroupMenuType) => {
  const flagsState: ReturnType<typeof getFlagsStateMap> =
    useSelector(getFlagsStateMap);
  const menuEl = React.useRef(null);
  const [isMenuOpen, onMenuHover, onMenuLeave, onTargetClick, closeMenu] =
    useMenu(menuEl);

  const handleMenuItemChoose = React.useCallback(
    (e: MouseEvent) => {
      handleGroupSelection(e);
      closeMenu();
    },
    [handleGroupSelection, closeMenu],
  );

  const groupButtonTitle = React.useMemo(
    () =>
      groupMode.subject === 'all'
        ? 'All'
        : `By ${
            groupMode.subject
              ? groupsByOrder.find(item => item.key === groupMode.subject)
                  ?.title
              : 'Well'
          }`,
    [groupMode, groupsByOrder],
  );

  return (
    <div>
      <ButtonTooltip content={groupingAttributes}>
        <GroupMenu.MenuButton
          width={flagsState.customDrilldown ? 193 : 30}
          height={26}
          open={isMenuOpen}
          onClick={onTargetClick}
          isCustomDrilldown={flagsState.customDrilldown}
        >
          {flagsState.customDrilldown ? (
            <>
              {groupButtonTitle}
              <RiArrowDropDownLine className="icon" />
            </>
          ) : (
            <GroupIcon />
          )}
        </GroupMenu.MenuButton>
      </ButtonTooltip>
      {isMenuOpen && (
        <GroupMenu.GroupMenuWrapper
          ref={menuEl}
          onMouseLeave={onMenuLeave}
          onMouseEnter={onMenuHover}
        >
          <GroupMenu.StyledPaper>
            <GroupMenu.MenuList>
              {groupsByOrder.map(group => (
                <GroupMenu.MenuItem
                  key={group.key}
                  id={group.key}
                  onClick={handleMenuItemChoose}
                >
                  {group.title}
                </GroupMenu.MenuItem>
              ))}
            </GroupMenu.MenuList>
          </GroupMenu.StyledPaper>
        </GroupMenu.GroupMenuWrapper>
      )}
    </div>
  );
};

GroupMenu.GroupMenuWrapper = styled.div`
  position: absolute;
  top: 136px;
  left: 14px;
  pointer-events: auto;
  z-index: 999;
  border: 1px solid #484848;
  max-height: 70vh;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #484848;
  }
`;

GroupMenu.StyledPaper = styled(Paper)`
  box-shadow: none !important;
`;

GroupMenu.MenuList = styled(MenuList)``;

GroupMenu.MenuItem = styled(MenuItem)`
  min-width: 175px;
  &:hover {
    background-color: #484848 !important;
    color: white;
  }
`;

GroupMenu.MenuButton = styled(Button)`
  border: ${props => {
    return props.open ? '1px solid #3297fd' : '';
  }};
  ${props =>
    props.isCustomDrilldown
      ? `justify-content: space-between;

  .icon {
    width: 26px;
    height: 26px;
  }`
      : ''}
`;

export default GroupMenu;
