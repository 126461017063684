import * as React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { timeDay, timeYear } from 'd3-time';

import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';
import { getMinDate } from 'helpers';
import {
  clearDrilldownTable,
  setDrilldownTableParams,
} from 'modules/drilldownTable/DrilldownTableActions';

interface Props {
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
    compareOption: string;
  };
  today: Date;
}

const useRoiDragging = ({ drilldownTableParams, today }: Props) => {
  const dispatch = useDispatch();

  const [localRoiDates, setLocalRoiDates] = React.useState(
    R.pick(['maxDate', 'minDate'], drilldownTableParams),
  );
  const [wasChanged, setWasChanged] = React.useState(false);

  const [isRoiDragging, setIsRoiDragging] = React.useState(false);

  const changeMaxRoiDate = React.useCallback(
    date => {
      const maxAvailableDate =
        drilldownTableParams.compareOption === COMPARE_OPTION.extVsCap
          ? timeYear.offset(today, 5)
          : timeDay.offset(today, -1);
      setIsRoiDragging(true);
      setWasChanged(true);
      setLocalRoiDates(prev => ({
        ...prev,
        maxDate: getMinDate(date, maxAvailableDate),
      }));
    },

    [setLocalRoiDates, today, setIsRoiDragging, setWasChanged],
  );

  const minDate = drilldownTableParams.minDate.getTime();
  const maxDate = drilldownTableParams.maxDate.getTime();

  React.useEffect(() => {
    if (
      minDate !== localRoiDates?.minDate?.getTime() ||
      maxDate !== localRoiDates?.maxDate?.getTime()
    ) {
      setLocalRoiDates(R.pick(['maxDate', 'minDate'], drilldownTableParams));
    }
  }, [minDate, maxDate]);

  React.useEffect(() => {
    if (!isRoiDragging && wasChanged) {
      dispatch(setDrilldownTableParams(localRoiDates));
      dispatch(clearDrilldownTable());
      setWasChanged(false);
    }
  }, [localRoiDates, isRoiDragging, wasChanged, setWasChanged]);

  const changeMinRoiDate = React.useCallback(
    date => {
      setIsRoiDragging(true);
      setWasChanged(true);
      setLocalRoiDates(prev => ({
        ...prev,
        minDate: date,
      }));
    },

    [setLocalRoiDates, setWasChanged, setLocalRoiDates, today],
  );

  const endRoiDragging = React.useCallback(() => {
    setIsRoiDragging(false);
  }, []);

  return {
    localRoiDates,
    changeMinRoiDate,
    changeMaxRoiDate,
    endRoiDragging,
  };
};

export default useRoiDragging;
