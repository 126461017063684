import * as React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { utils, writeFile } from 'xlsx-js-style';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import {
  ChartOption,
  GroupToDisplay,
  ListChartOptions,
} from 'modules/chartOptions/models/index';
import { ForecastData } from 'modules/externalForecast/models/index';
import { GroupChartPoint } from 'modules/groupChart/models/groupChart';
import { ProductionPoint } from 'modules/production/models/production';
import { NormalizedSeries } from 'modules/series/models';
import { pushNotification } from 'modules/notification/NotificationActions';
import { useDispatch } from 'react-redux';
import { sliceArrayByExtremeDates } from 'helpers/transformers/sliceArrayByExtremeDates';
import { timeDay, utcDay } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import {
  excelCellsAlphabet,
  excelCellStyle,
  excelHeaderStyle,
  getValidCellValue,
  calculateVariance,
} from 'helpers/excelExportConstants';

interface TrellisContextMenuProps {
  closeMenu: () => void;
  contextPosition: { clientX: number; clientY: number } | null;
  onMenuHover: () => void;
  onMenuLeave: () => void;
  chartData: {
    extremeDates: {
      min: Date;
      max: Date;
    };
    cavSeries: ChartOption[];
    forecastData: ForecastData;
    compareOption: string;
    hasEnoughData: boolean;
    groupToDisplay: GroupToDisplay;
    title: string;
  };
  wellChartData?: {
    fullProduction: ProductionPoint[];
    seriesOfCurrentWell: {
      data: NormalizedSeries[];
      joined: NormalizedSeries[];
      exists: boolean;
    };
    sensorSeries: {
      [key: string]: NormalizedSeries[];
    };
    indicatorsCapacity: {
      [indicator: string]: Array<{ date: Date; capacity: number }[]>;
    };
    fullCapacityData: {
      [phase: string]: Array<{ date: Date; capacity: number }[]>;
    };
    calculatedSeries: {
      [key: string]: NormalizedSeries[];
    };
    chartOptions: ListChartOptions;
    currentWellId: string;
  };
  groupChartData?: {
    groupChartDataPoints: GroupChartPoint[];
    groupVarianceChartSlice: any;
    currentGroup: Record<string, never> | { subject: string; item: string };
    interactiveTitle: string;
  };
}

const TrellisContextMenu = (
  {
    closeMenu,
    contextPosition,
    onMenuHover,
    onMenuLeave,
    wellChartData,
    groupChartData,
    chartData,
  }: TrellisContextMenuProps,
  ref,
) => {
  const dispatch = useDispatch();

  const exportToExcel = React.useCallback(() => {
    const {
      hasEnoughData,
      groupToDisplay,
      cavSeries,
      extremeDates,
      forecastData,
      title,
      compareOption,
    } = chartData;

    if (!hasEnoughData) {
      return dispatch(
        pushNotification({
          message: 'Please wait until chart data will finish loading',
        }),
      );
    }

    if (R.isEmpty(groupToDisplay) && R.isEmpty(cavSeries)) {
      return dispatch(
        pushNotification({
          message: 'Please select some trellis options first',
        }),
      );
    }

    const { min, max } = extremeDates;

    const minDate = timeDay.floor(min);
    const maxDate = timeDay.ceil(max);

    if (wellChartData && !groupChartData) {
      const {
        indicatorsCapacity,
        fullCapacityData,
        chartOptions,
        fullProduction,
        seriesOfCurrentWell,
        currentWellId,
        sensorSeries,
        calculatedSeries,
      } = wellChartData;

      const allCapacityDataObject = {
        ...indicatorsCapacity,
        ...fullCapacityData,
      };

      const trellisIds = groupToDisplay
        ?.map(groupItem => {
          return groupItem.ids;
        })
        ?.flat();

      const dailyTrellisCharts = trellisIds
        .filter(
          dailyTrellisId =>
            !(dailyTrellisId.startsWith('s') || dailyTrellisId.startsWith('c')),
        )
        .map(dailyTrellisId => {
          return chartOptions[dailyTrellisId];
        });

      const zoomProduction = sliceArrayByExtremeDates(
        fullProduction,
        minDate,
        maxDate,
        (date, e) =>
          utcDay.floor(date).getTime() - utcDay.floor(e.day).getTime(),
      );

      const capacityDataSliced = Object.keys(allCapacityDataObject).reduce(
        (acc, key) => {
          const capacity = (allCapacityDataObject?.[key] || [])
            .flat()
            .sort((a, b) => a.date.getTime() - b.date.getTime());
          const capacitySliced = sliceArrayByExtremeDates(
            capacity,
            minDate,
            maxDate,
            (date, e) => date.getTime() - e.date.getTime(),
          );
          acc[key] = capacitySliced;
          return acc;
        },
        {} as {
          [key: string]: {
            date: Date;
            capacity: number;
          }[];
        },
      );
      const forecastDataSliced = sliceArrayByExtremeDates(
        forecastData,
        minDate,
        maxDate,
        (date, e) =>
          utcDay.floor(date).getTime() - utcDay.floor(e.day).getTime(),
      );

      const seriesOfCurrentWellSliced = sliceArrayByExtremeDates(
        seriesOfCurrentWell?.joined,
        minDate,
        maxDate,
        (date, e) =>
          utcDay.floor(date).getTime() - utcDay.floor(e.day).getTime(),
      );

      const dailyDataBody = !R.isEmpty(cavSeries)
        ? cavSeries.reduce((acc, seriesItem) => {
            const capacityData = capacityDataSliced[seriesItem.id];
            if (!capacityData) return acc;
            const capacityDataAsObject: any = capacityData.reduce(
              (acc, item) => {
                if (acc[timeFormat('%m/%d/%Y')(item.date)]) {
                  return acc;
                }
                acc[timeFormat('%m/%d/%Y')(item.date)] = item;
                return acc;
              },
              {},
            );

            const productionDataAsObject: any = zoomProduction.reduce(
              (acc, item) => {
                acc[timeFormat('%m/%d/%Y')(item.day)] = item;
                return acc;
              },
              {},
            );

            const foreCastDataAsObject: any = forecastDataSliced.reduce(
              (acc, item) => {
                acc[timeFormat('%m/%d/%Y')(item.day)] = item;
                return acc;
              },
              {},
            );

            const seriesDataAsObject: any = seriesOfCurrentWellSliced.reduce(
              (acc, item) => {
                if (acc[timeFormat('%m/%d/%Y')(item.day)]) return acc;
                acc[timeFormat('%m/%d/%Y')(item.day)] = item;
                return acc;
              },
              {},
            );

            const dataLength = Math.max(
              Object.keys(capacityDataAsObject)?.length || 0,
              Object.keys(productionDataAsObject)?.length || 0,
              Object.keys(foreCastDataAsObject)?.length || 0,
              Object.keys(seriesDataAsObject)?.length || 0,
            );

            const iterableObject =
              Object.values({
                capacityDataAsObject,
                productionDataAsObject,
                foreCastDataAsObject,
                seriesDataAsObject,
              }).find(value => Object.keys(value).length === dataLength) ||
              capacityDataAsObject;

            iterableObject &&
              Object.keys(iterableObject).forEach(key => {
                const capacityDataItem = capacityDataAsObject?.[key];
                const productionItem = productionDataAsObject?.[key];
                const forecastItem = foreCastDataAsObject?.[key];
                const dailyDataItem = seriesDataAsObject?.[key];

                acc[key] = {
                  ...(acc?.[key] || {}),
                  Day: {
                    v: key,
                    ...excelCellStyle,
                  },
                  Well: { v: title, ...excelCellStyle },
                  'Well ID': { v: currentWellId, ...excelCellStyle },
                  [seriesItem.title]: {
                    v: getValidCellValue(
                      productionItem?.[seriesItem.id.toLowerCase()],
                    ),
                    ...excelCellStyle,
                  },
                  [`${seriesItem.title} - Capacity`]: {
                    v: getValidCellValue(capacityDataItem?.capacity),
                    ...excelCellStyle,
                  },
                  [`${seriesItem.title} - Forecast`]: {
                    v: getValidCellValue(
                      forecastItem?.[seriesItem.id.toLowerCase()],
                    ),
                    ...excelCellStyle,
                  },
                  [`${seriesItem.title} - Variance`]: {
                    v: calculateVariance({
                      compareOption: compareOption,
                      production: productionItem?.[seriesItem.id.toLowerCase()],
                      capacity: capacityDataItem?.capacity,
                      forecast: forecastItem?.[seriesItem.id.toLowerCase()],
                    }),
                    ...excelCellStyle,
                  },
                };

                dailyTrellisCharts?.forEach(dailyChart => {
                  acc[key] = {
                    ...(acc?.[key] || {}),
                    [`${dailyChart.title}`]: {
                      v: getValidCellValue(
                        dailyDataItem?.[`series${dailyChart.id}`],
                      ),
                      ...excelCellStyle,
                    },
                  };
                });
              });

            return acc;
          }, {} as { [key: string]: any })
        : dailyTrellisCharts.reduce((acc, dailyChart) => {
            for (let idx = 0; idx < seriesOfCurrentWellSliced.length; idx++) {
              const dailyDataItem = seriesOfCurrentWellSliced?.[idx];

              acc[idx] = {
                ...(acc?.[idx] || {}),
                Day: {
                  v: timeFormat('%m/%d/%Y')(dailyDataItem?.day),
                  ...excelCellStyle,
                },
                Well: { v: title, ...excelCellStyle },
                'Well ID': { v: currentWellId, ...excelCellStyle },
                [`${dailyChart.title}`]: {
                  v: getValidCellValue(
                    dailyDataItem?.[`series${dailyChart.id}`],
                  ),
                  ...excelCellStyle,
                },
              };
            }

            return acc;
          }, [] as { [key: string]: any }[]);

      const sensorTrellisCharts = trellisIds
        ?.filter(
          dailyTrellisId =>
            dailyTrellisId.startsWith('s') || dailyTrellisId.startsWith('c'),
        )
        .map(dailyTrellisId => {
          return chartOptions[dailyTrellisId];
        });

      const sensorDataBody =
        R.isEmpty(sensorSeries) && R.isEmpty(calculatedSeries)
          ? []
          : sensorTrellisCharts
              ?.map(sensorItem => {
                const slicedSensorArray = sliceArrayByExtremeDates(
                  sensorItem.id.startsWith('s')
                    ? sensorSeries?.[sensorItem.id] || []
                    : calculatedSeries?.[sensorItem.id] || [],
                  minDate,
                  maxDate,
                  (date, e) =>
                    utcDay.floor(date).getTime() -
                    utcDay.floor(e.day).getTime(),
                );

                const sensorObjectsArray = slicedSensorArray.map(item => {
                  const finalObject = {
                    'Well ID': { v: currentWellId, ...excelCellStyle },
                    Well: { v: title, ...excelCellStyle },
                    'Sensor - Calculated': {
                      v: sensorItem.title,
                      ...excelCellStyle,
                    },
                    'Timestamp UTC': {
                      v: timeFormat('%m/%d/%Y %H:%M:%S.%L')(item.day),
                      ...excelCellStyle,
                    },
                    Value: {
                      v: getValidCellValue(item[sensorItem.id]),
                      ...excelCellStyle,
                    },
                  };

                  return finalObject;
                });

                return sensorObjectsArray;
              })
              ?.flat();

      if (!R.isEmpty(dailyDataBody) || !R.isEmpty(sensorDataBody)) {
        const workbook = utils.book_new();

        if (!R.isEmpty(dailyDataBody)) {
          const dailyDataColsStyles = Object.keys(
            Object.values(dailyDataBody)?.[0],
          )?.map(key => {
            switch (key) {
              case 'Day':
                return { wch: 12 };
              case 'Well':
                return { wch: title.length };
              case 'Well ID':
                return { wch: currentWellId.length + 2 };
              default:
                return {
                  wch: 20,
                };
            }
          });

          const dailyDataSheet = utils.json_to_sheet(
            Object.values(dailyDataBody),
          );

          dailyDataColsStyles?.forEach((_, idx) => {
            if (dailyDataSheet?.[`${excelCellsAlphabet[idx]}1`]) {
              dailyDataSheet[`${excelCellsAlphabet[idx]}1`].s =
                excelHeaderStyle;
            }
          });

          dailyDataSheet['!cols'] = dailyDataColsStyles;

          utils.book_append_sheet(workbook, dailyDataSheet, 'Daily data');
        }

        if (!R.isEmpty(sensorDataBody)) {
          const sensorDataColsStyles = [
            { wch: currentWellId.length + 2 },
            { wch: title.length },
            { wch: 20 },
            { wch: 24 },
            { wch: 24 },
          ];

          const sensorSheet = utils.json_to_sheet(sensorDataBody);

          sensorDataColsStyles?.forEach((_, idx) => {
            if (sensorSheet?.[`${excelCellsAlphabet[idx]}1`]) {
              sensorSheet[`${excelCellsAlphabet[idx]}1`].s = excelHeaderStyle;
            }
          });

          sensorSheet['!cols'] = sensorDataColsStyles;

          utils.book_append_sheet(workbook, sensorSheet, 'Sensor data');
        }

        writeFile(workbook, `${title}.xlsx`);
      } else {
        return dispatch(
          pushNotification({
            message: 'There is no data within the zoom range',
          }),
        );
      }
    } else if (groupChartData && !wellChartData) {
      const { groupChartDataPoints } = groupChartData;

      const forecastDataSliced = sliceArrayByExtremeDates(
        forecastData,
        minDate,
        maxDate,
        (date, e: any) =>
          utcDay.floor(date).getTime() - utcDay.floor(e.day).getTime(),
      )?.reduce((acc, forecastItem) => {
        acc[timeFormat('%m/%d/%Y')(forecastItem.day)] = forecastItem;
        return acc;
      }, {});

      const headerAndData = cavSeries.reduce((acc, cavItem) => {
        if (groupChartDataPoints[cavItem.id]) {
          const dataObject = sliceArrayByExtremeDates(
            groupChartDataPoints[cavItem.id],
            minDate,
            maxDate,
            (date, e: any) =>
              utcDay.floor(date).getTime() - utcDay.floor(e.day).getTime(),
          ).reduce((acc, dataItem) => {
            acc[timeFormat('%m/%d/%Y')(dataItem.day)] = dataItem;
            return acc;
          }, {});

          acc[cavItem.id] = {
            dataObject,
            header: cavItem.title,
          };
        }

        return acc;
      }, {});

      if (!R.isEmpty(headerAndData)) {
        const tableData = Object.keys(headerAndData).reduce((acc, dataKey) => {
          const { header, dataObject } = headerAndData[dataKey];
          Object.keys(dataObject).forEach(key => {
            const item = dataObject[key];

            acc[key] = {
              ...(acc?.[key] || {}),
              Day: { v: timeFormat('%m/%d/%Y')(item.day), ...excelCellStyle },
              Group: { v: title, ...excelCellStyle },
              [header]: {
                v: item.production || 0,
                ...excelCellStyle,
              },
              [`${header} - Capacity`]: {
                v: item.capacity || 0,
                ...excelCellStyle,
              },
              [`${header} - Forecast`]: {
                v: 'No data',
                ...excelCellStyle,
              },
              [`${header} - Variance`]: {
                v: getValidCellValue(
                  calculateVariance({
                    compareOption: compareOption,
                    production: item.production || 0,
                    capacity: item.capacity || 0,
                  }),
                ),
                ...excelCellStyle,
              },
            };
          });

          Object.keys(forecastDataSliced).forEach(key => {
            const forecastItem = forecastDataSliced[key];
            acc[key] = {
              ...(acc?.[key] || {}),
              [`${header} - Forecast`]: {
                v: getValidCellValue(forecastItem?.[dataKey.toLowerCase()]),
                ...excelCellStyle,
              },
              [`${header} - Variance`]: {
                v: getValidCellValue(
                  calculateVariance({
                    compareOption: compareOption,
                    production: acc?.[key]?.[header]?.v,
                    capacity: acc?.[key]?.[`${header} - Capacity`]?.v,
                    forecast: forecastItem?.[dataKey.toLowerCase()],
                  }),
                ),
                ...excelCellStyle,
              },
            };
          });

          return acc;
        }, [] as { [key: string]: any }[]);

        const normalizedTableData = Object.values(tableData);

        const tableDataSheet = utils.json_to_sheet(normalizedTableData);

        const tableDataColStyles = Object.keys(normalizedTableData?.[0])?.map(
          key => {
            switch (key) {
              case 'Day':
                return { wch: 10 };
              case 'Group':
                return {
                  wch: title.length > 5 ? title.length : 10,
                };
              default:
                return {
                  wch: 20,
                };
            }
          },
        );

        for (let idx = 0; idx < tableDataColStyles.length; idx++) {
          if (tableDataSheet?.[`${excelCellsAlphabet[idx]}1`]) {
            tableDataSheet[`${excelCellsAlphabet[idx]}1`].s = excelHeaderStyle;
          }
        }

        tableDataSheet['!cols'] = tableDataColStyles;

        const workbook = utils.book_new();
        utils.book_append_sheet(
          workbook,
          tableDataSheet,
          title || 'Table data',
        );
        writeFile(workbook, `${title || 'Table data'}.xlsx`);
      } else {
        return dispatch(
          pushNotification({ message: 'There is no data within zoom range' }),
        );
      }
    }
  }, [dispatch, wellChartData, groupChartData, chartData]);

  const onClickCallback = React.useCallback(
    e => {
      e.preventDefault();
      exportToExcel();
      closeMenu();
    },
    [exportToExcel],
  );

  return (
    <TrellisContextMenu.TrellisContextMenuWrapper
      ref={ref}
      onMouseLeave={onMenuLeave}
      onMouseEnter={onMenuHover}
      x={contextPosition ? contextPosition.clientX : 0}
      y={contextPosition ? contextPosition.clientY : 0}
    >
      <TrellisContextMenu.StyledPaper>
        <TrellisContextMenu.MenuList>
          <TrellisContextMenu.MenuItem onClick={onClickCallback}>
            Export to EXCEL
          </TrellisContextMenu.MenuItem>
        </TrellisContextMenu.MenuList>
      </TrellisContextMenu.StyledPaper>
    </TrellisContextMenu.TrellisContextMenuWrapper>
  );
};

TrellisContextMenu.TrellisContextMenuWrapper = styled.div`
  position: fixed;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  pointer-events: auto;
  z-index: 999;
`;

TrellisContextMenu.StyledPaper = styled(Paper)`
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.5) !important;
`;

TrellisContextMenu.MenuList = styled(MenuList)``;

TrellisContextMenu.MenuItem = styled(MenuItem)`
  min-width: 175px;
  &:hover {
    background-color: #e7e7e7 !important;
  }
`;

export default React.forwardRef<HTMLElement, TrellisContextMenuProps>(
  TrellisContextMenu,
);
