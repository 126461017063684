type authType = {
  authScheme: string;
  internalAuthConfig: { autoCreateUsers: boolean; provider: string };
};

export type LoginBanner = {
  color: string;
  title: string;
  message: string;
};

export type AppConfig = {
  // allocationIssues: boolean;
  authConfig: authType;
  authConfigNormal: authType;
  authConfigOld: authType;
  authConfigSso: authType;
  authScheme: string;
  // capacityVsExternal: boolean;
  // conversationsPolling: boolean;
  default_gasPriceAssumption: number;
  default_minSummedCapacityDateSetting: Date;
  default_oilPriceAssumption: number;
  drilldownDefaultFromDate: Date;
  drilldownDefaultToDate: Date;
  // drilldownMarking: boolean;
  gasPriceAssumption: number;
  // groupBy: boolean;
  loomJWS: string;
  minSummedCapacityDateSetting: Date;
  oilPriceAssumption: number;
  // recordVideo: boolean;
  // sensorSeries: boolean;
  // showExternalForecast: boolean;
  // spotfireDashboards: boolean;
  // timeSeries: boolean;
  today: Date;
  todayOffset: number;
  tokenExpTime: string;
  loginBanner: LoginBanner;
  // varianceDrilldownMarkingFilter: boolean;
  // varianceTrellis: boolean;
  // generateRaster: boolean;
  _persist: { rehydrated: boolean; version: number };
};

export const LOCAL_APP_SETTINGS = [
  {
    name: 'Oil Price Assumption ($/BBL)',
    key: 'oilPriceAssumption',
    convert: value => parseFloat(value),
    default: 70,
  },
  {
    name: 'Gas Price Assumption ($/MCF)',
    key: 'gasPriceAssumption',
    convert: value => parseFloat(value),
    default: 3,
  },
  {
    name: 'Minimum Date to Show Grouped Capacities',
    key: 'minSummedCapacityDateSetting',
    convert: value => new Date(value),
    default: new Date(0),
  },
];

export const DEFAULT_BANNER = {
  color: '#FF0000',
  title: 'Wise Rock is currently unavailable for some customers.',
  message:
    'We are investigating the issue and hope to have an update soon. Feel free to email support@wiserocksoftware.com with questions.',
};

export const LOCAL_APP_SETTINGS_KEYS = LOCAL_APP_SETTINGS.map(
  setting => setting.key,
);
