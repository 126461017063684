import * as React from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from 'components/Button';

interface DefaultLayoutConfigurationProps {
  phase: string;
  grossNet: string;
  rateVolume: string;
  setPhaseLocally: (phase: string) => void;
  setGrossNetLocally: (grossNet: string) => void;
  setRateVolumeLocally: (rateVolume: string) => void;
}

export const DefaultLayoutConfiguration = ({
  phase,
  grossNet,
  rateVolume,
  setPhaseLocally,
  setGrossNetLocally,
  setRateVolumeLocally,
}: DefaultLayoutConfigurationProps) => {
  return (
    <DefaultLayoutConfiguration.LayoutConfiguration>
      <DefaultLayoutConfiguration.MenuTitle>
        Layout Configuration
      </DefaultLayoutConfiguration.MenuTitle>
      <DefaultLayoutConfiguration.PhaseOptionsContainer>
        Phase
        <DefaultLayoutConfiguration.RadioGroup
          aria-label="Phase-select"
          name="Phase"
          value={phase}
          onChange={e => {
            setPhaseLocally(e.target.value);
          }}
        >
          <FormControlLabel
            value="BOE"
            classes={{ label: 'label', root: 'rootLabel' }}
            control={
              <Radio
                classes={{ root: 'radio' }}
                color="primary"
                size={'small'}
              />
            }
            label="BOE"
          />
          <FormControlLabel
            value="Oil"
            classes={{ label: 'label', root: 'rootLabel' }}
            control={
              <Radio
                classes={{ root: 'radio' }}
                color="primary"
                size={'small'}
              />
            }
            label="Oil"
          />
          <FormControlLabel
            value="Gas"
            classes={{ label: 'label', root: 'rootLabel' }}
            control={
              <Radio
                classes={{ root: 'radio' }}
                color="primary"
                size={'small'}
              />
            }
            label="Gas"
          />
          <FormControlLabel
            value="Water"
            classes={{ label: 'label', root: 'rootLabel' }}
            control={
              <Radio
                classes={{ root: 'radio' }}
                color="primary"
                size={'small'}
              />
            }
            label="Water"
          />
        </DefaultLayoutConfiguration.RadioGroup>
      </DefaultLayoutConfiguration.PhaseOptionsContainer>
      <DefaultLayoutConfiguration.ButtonsContainer>
        <DefaultLayoutConfiguration.FilterButton
          isSelected={grossNet === 'Gross'}
          onClick={() => {
            setGrossNetLocally('Gross');
          }}
        >
          Gross
        </DefaultLayoutConfiguration.FilterButton>
        <DefaultLayoutConfiguration.FilterButton
          isSelected={grossNet === 'Net'}
          onClick={() => {
            setGrossNetLocally('Net');
          }}
        >
          Net
        </DefaultLayoutConfiguration.FilterButton>
      </DefaultLayoutConfiguration.ButtonsContainer>
      <DefaultLayoutConfiguration.ButtonsContainer second>
        <DefaultLayoutConfiguration.FilterButton
          isSelected={rateVolume === 'Rate'}
          onClick={() => {
            setRateVolumeLocally('Rate');
          }}
        >
          Rate
        </DefaultLayoutConfiguration.FilterButton>
        <DefaultLayoutConfiguration.FilterButton
          isSelected={rateVolume === 'Volume'}
          onClick={() => {
            setRateVolumeLocally('Volume');
          }}
        >
          Volume
        </DefaultLayoutConfiguration.FilterButton>
      </DefaultLayoutConfiguration.ButtonsContainer>
    </DefaultLayoutConfiguration.LayoutConfiguration>
  );
};

DefaultLayoutConfiguration.MenuTitle = styled.div`
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 14px 0;
`;

DefaultLayoutConfiguration.LayoutConfiguration = styled.div`
  padding-left: 24px;
  padding-bottom: 44px;
  width: 147px;
  height: 100%;
  border-left: 1px solid #c1c1c1;
  z-index: 981;

  .rootLabel {
    width: fit-content;
    margin: 0;
  }

  .label {
    font-size: 12px;
    line-height: 16px;
    margin-left: 0;
  }

  .radio {
    width: 16px;
    height: 16px;
    padding: 0;
    margin-right: 8px;
    & .MuiSvgIcon-root {
      font-size: 16px;
    }
  }
`;

DefaultLayoutConfiguration.PhaseOptionsContainer = styled.div`
  margin-top: 2px;
`;

DefaultLayoutConfiguration.RadioGroup = styled(RadioGroup)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: 16px;
  row-gap: 12px;
  width: 123px;
  margin-top: 12px;
`;

DefaultLayoutConfiguration.ButtonsContainer = styled.div`
  border: 1px solid #c1c1c1;
  display: flex;
  justify-content: center;
  width: 186px;
  margin: ${props => (props.second ? '16px' : '24px')} auto 0 auto;
`;

DefaultLayoutConfiguration.FilterButton = styled(Button)`
  width: 93px;
  height: auto;
  border: none;
  line-height: 20px;
  ${({ isSelected }) =>
    isSelected &&
    'background: linear-gradient(180deg, #FAFAFA 0%, #F5F5F5 100%);'}

  &:nth-child(n+2) {
    border-left: 1px solid #c1c1c1;
  }
`;
