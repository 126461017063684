import * as R from 'ramda';
import { all, put, select, take, takeLatest, fork } from 'redux-saga/effects';
import { Action } from 'store/models';

import {
  REORDER_SERIES_LAYOUT_LOCALY,
  setUserLayoutsLocally,
  SET_CURRENT_CONFIGURATION,
} from './SeriesLayoutsActions';
import {
  getCurrentLayoutConfiguration,
  getUserSeriesLayouts,
} from './SeriesLayoutsReducer';
import {
  initFetchCalculatedSeries,
  initFetchSensorSeries,
} from 'modules/series/SeriesActions';
import { getChartWidth } from 'modules/ui/UIReducer';
import {
  calculateCalculatedSeriesConfigurationsDifference,
  calculateSeriesConfigurationsDifference,
} from './utils/calculateSeriesConfigurationsDifference';

function* fetchSensorSeriesSaga(): Generator<any, any, any> {
  while (true) {
    const previousConfiguration = yield select(getCurrentLayoutConfiguration);
    yield take(SET_CURRENT_CONFIGURATION);
    const nextConfiguration = yield select(getCurrentLayoutConfiguration);

    const difference = calculateSeriesConfigurationsDifference(
      previousConfiguration,
      nextConfiguration,
    );

    if (!difference.length) continue;

    const chartWidth = yield select(getChartWidth);

    yield put(
      initFetchSensorSeries({
        chartWidth,
        series: difference,
      }),
    );
  }
}

function* fetchCalculatedSeries(): Generator<any, any, any> {
  while (true) {
    const previousConfiguration = yield select(getCurrentLayoutConfiguration);
    yield take(SET_CURRENT_CONFIGURATION);
    const nextConfiguration = yield select(getCurrentLayoutConfiguration);

    const difference = calculateCalculatedSeriesConfigurationsDifference(
      previousConfiguration,
      nextConfiguration,
    );

    if (!difference.length) continue;

    const chartWidth = yield select(getChartWidth);

    yield put(
      initFetchCalculatedSeries({
        chartWidth,
        series: difference,
      }),
    );
  }
}

function* reorderSeriesLayouts(action: Action): Generator<any, any, any> {
  const {
    payload: { dragId, hoverId },
  } = action;
  const userLayouts = yield select(getUserSeriesLayouts);
  const dragLayout = userLayouts[dragId];
  const hoverLayout = userLayouts[hoverId];

  const layouts = R.compose(
    R.assocPath([dragId, 'order'], hoverLayout.order),
    R.assocPath([hoverId, 'order'], dragLayout.order),
  )(userLayouts);
  yield put(setUserLayoutsLocally(layouts));
}

function* seriesLayoutsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(REORDER_SERIES_LAYOUT_LOCALY, reorderSeriesLayouts),
    fork(fetchSensorSeriesSaga),
    fork(fetchCalculatedSeries),
  ]);
}

export default seriesLayoutsSagas;
