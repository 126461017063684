import * as R from 'ramda';
import packageInfo from '../../package.json';

export const Environment = {
  local: 'local', // localhost:3000
  development: 'development',
  demo: 'demo',
  stage: 'stage',
  production: 'production',
};

const environmentConfig = {
  environment: R.defaultTo(
    Environment.local,
    process.env.REACT_APP_ENVIRONMENT,
  ),
  packageVersion: packageInfo.version,
};

export default environmentConfig;
