import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { CloseIcon } from 'components/Icons';

type ModalWindowV2Props = {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  handleClose?: () => void;
  description?: string;
  width?: number;
  error?: React.ReactNode;
  specId?: string;
};

const ModalWindowV2 = (props: ModalWindowV2Props) => {
  return (
    <ModalWindowV2.Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      data-spec-id={props.specId}
      className="modal-window-v2"
      disableEnforceFocus
      open={props.isOpen}
      onClose={props.handleClose}
    >
      <ModalWindowV2.Wrapper width={props.width}>
        <ModalWindowV2.Paper>
          {props.handleClose && (
            <ModalWindowV2.Close onClick={props.handleClose}>
              <CloseIcon width={16} height={16} />
            </ModalWindowV2.Close>
          )}

          <ModalWindowV2.Title id="modal-title">
            {props.title}
          </ModalWindowV2.Title>

          <ModalWindowV2.Description id="modal-description">
            {props.description}
          </ModalWindowV2.Description>

          {props.error && (
            <ModalWindowV2.Error>{props.error}</ModalWindowV2.Error>
          )}

          {props.children}
        </ModalWindowV2.Paper>
      </ModalWindowV2.Wrapper>
    </ModalWindowV2.Modal>
  );
};

ModalWindowV2.Modal = styled(Modal)`
  overflow-y: auto;
`;

ModalWindowV2.Wrapper = styled.div`
  max-width: 100%;
  width: ${({ width }) => (width ?? 450) + 'px'};
  margin: 0 auto;
  padding: 45px 0;
`;

ModalWindowV2.Paper = styled(Paper)`
  border-radius: 8px;
  padding: 17px 20px;
  color: #484848;
  position: relative;
`;

ModalWindowV2.Title = styled.h2`
  margin-bottom: 22px;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
  color: #484848;
`;

ModalWindowV2.Description = styled.p`
  display: none;
`;

ModalWindowV2.Error = styled.p`
  background-color: rgba(198, 0, 0, 0.05);
  border-radius: 4px;
  margin: 0 0 16px;
  padding: 12px;
  color: #c60000;
`;

ModalWindowV2.Close = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

export default ModalWindowV2;
