const getAttachmentsTypes = (attachments: any) => {
  const result = { hasPhotos: false, hasDocs: false };
  for (const item of attachments) {
    if (
      /([a-zA-Z0-9\s_\\.\-():])+(.bmp|.jpg|.jpeg|.png|.svg|.webp)$/i.test(
        item.name,
      )
    ) {
      result.hasPhotos = true;
      continue;
    }
    result.hasDocs = true;
  }
  return result;
};

export default getAttachmentsTypes;
