import * as React from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  NormalizedSeriesMapping,
  NormalizedCoreSeriesMapping,
} from 'modules/series/models';

interface CustomLayoutConfigurationProps {
  seriesMapping: NormalizedSeriesMapping;
  coreSeriesMapping: NormalizedCoreSeriesMapping;
  setSelectedSeries: (selectedSeriesIndex: string) => void;
  setAggregateFn: (setAggregateFn: string) => void;
  aggregateFn: string;
  selectedSeries: string | number;
}

export const CustomLayoutConfiguration = ({
  seriesMapping,
  coreSeriesMapping,
  setSelectedSeries,
  setAggregateFn,
  aggregateFn,
  selectedSeries,
}: CustomLayoutConfigurationProps) => {
  return (
    <CustomLayoutConfiguration.LayoutConfiguration>
      <CustomLayoutConfiguration.MenuTitle>
        Layout Configuration
      </CustomLayoutConfiguration.MenuTitle>
      <CustomLayoutConfiguration.PhaseOptionsContainer>
        Time Series
        <select
          style={{ marginTop: '8px', width: '184px' }}
          onChange={e => {
            setSelectedSeries(e.target.value);
          }}
          defaultValue={
            Object.values(coreSeriesMapping).find(
              item => item.id === selectedSeries,
            )?.id ||
            Object.values(seriesMapping).find(
              item => item.seriesIndex === selectedSeries,
            )?.seriesIndex
          }
        >
          {Object.keys(coreSeriesMapping).map(coreSeriesKey => {
            const coreSeries = coreSeriesMapping[coreSeriesKey];
            if (coreSeries.id === 'VARIANCE_TRELLIS') return null;
            return (
              <option
                key={coreSeries.id}
                selected={selectedSeries === coreSeries.id}
                value={coreSeries.id}
              >
                {coreSeries.title}
              </option>
            );
          })}
          {Object.keys(seriesMapping).map(seriesKey => {
            const currentSeries = seriesMapping[seriesKey];
            return (
              <option
                key={currentSeries.seriesIndex}
                selected={Number(selectedSeries) === currentSeries.seriesIndex}
                value={currentSeries.seriesIndex}
              >{`${currentSeries.displayName}, ${currentSeries.units}`}</option>
            );
          })}
        </select>
      </CustomLayoutConfiguration.PhaseOptionsContainer>

      <CustomLayoutConfiguration.RadioGroup
        aria-label="Aggregate-functions"
        name="AggregateFn"
        onChange={e => {
          setAggregateFn(e.target.value);
        }}
      >
        <h3 className="title">Aggregation method</h3>
        <FormControlLabel
          classes={{ label: 'label', root: 'root' }}
          value="avg"
          control={
            <Radio
              checked={aggregateFn === 'avg'}
              className="input"
              color="primary"
              size={'small'}
            />
          }
          label="Average"
        />
        <FormControlLabel
          classes={{ label: 'label', root: 'root' }}
          value="sum"
          control={
            <Radio
              checked={aggregateFn === 'sum'}
              className="input"
              color="primary"
              size={'small'}
            />
          }
          label="Sum"
        />
        <FormControlLabel
          classes={{ label: 'label', root: 'root' }}
          value="min"
          control={
            <Radio
              checked={aggregateFn === 'min'}
              className="input"
              color="primary"
              size={'small'}
            />
          }
          label="Minimum"
        />
        <FormControlLabel
          classes={{ label: 'label', root: 'root' }}
          value="change"
          control={
            <Radio
              checked={aggregateFn === 'change'}
              className="input"
              color="primary"
              size={'small'}
            />
          }
          label="Change"
        />
        <FormControlLabel
          classes={{ label: 'label', root: 'root' }}
          value="max"
          control={
            <Radio
              checked={aggregateFn === 'max'}
              className="input"
              color="primary"
              size={'small'}
            />
          }
          label="Maximum"
        />
      </CustomLayoutConfiguration.RadioGroup>
    </CustomLayoutConfiguration.LayoutConfiguration>
  );
};

CustomLayoutConfiguration.MenuTitle = styled.div`
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 14px 24px;
`;

CustomLayoutConfiguration.LayoutConfiguration = styled.div`
  height: 100%;
  border-left: 1px solid #c1c1c1;
  z-index: 981;
  padding-bottom: 30px;
`;

CustomLayoutConfiguration.PhaseOptionsContainer = styled.div`
  margin-left: 24px;
  margin-top: 6px;
`;

CustomLayoutConfiguration.RadioGroup = styled(RadioGroup)`
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  padding-left: 24px;
  padding-right: 31px;

  .title {
    font-size: 14px;
    color: #484848;
    margin-bottom: 18px;
  }

  .input {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  .root {
    width: calc((100% - 16px) / 2);
    margin: 0;
  }

  .root:not(:last-child) {
    margin-bottom: 12px;
  }

  .label {
    font-size: 12px;
  }
`;
