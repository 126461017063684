import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'store/models';
import { FaCheck } from 'react-icons/fa';
import { getId, getFullName, getPermissions } from 'modules/auth/AuthReducer';
import { getUser } from 'modules/user/UserReducer';
import { getFlagsStateMap } from 'modules/featureFlags/FeatureFlagsReducer';

import Button from 'components/Button';
import TitledDivider from 'components/TitledDivider';
import Modal from 'components/ModalWindowV2';

import { getUserTableConfiguration } from '../constants';
import InputCheckbox from 'components/InputCheckbox';
import { useDispatch } from 'react-redux';
import { setIsMfaEnabled } from '../UserActions';

type AccountWindowProps = {
  open: boolean;
  handleClose: () => void;
};

const AccountWindow = ({ open, handleClose }: AccountWindowProps) => {
  const userId: string = useSelector(getId);
  const flags = useSelector(getFlagsStateMap);
  const permissions = useSelector(getPermissions);
  const name = useSelector(getFullName);
  const user = useSelector(store => getUser(store, userId));
  const dispatch = useDispatch();

  const configuration = React.useMemo(
    () => getUserTableConfiguration(flags, permissions),
    [flags, permissions],
  );

  const onMfaEnabledChange = React.useCallback(
    e => {
      const checked = e.target.checked;
      dispatch(setIsMfaEnabled({ id: user.id, mfaEnabled: checked }));
    },
    [user.id, dispatch],
  );

  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      title="My Account"
      specId="my-account-modal"
      width={460}
    >
      <AccountWindow.UserName>{name}</AccountWindow.UserName>

      <AccountWindow.UserEmail>{user.email}</AccountWindow.UserEmail>

      <InputCheckbox
        id="mfaCode"
        name="mfaCode"
        label="Enable MFA (email)"
        checked={user?.mfaEnabled}
        onChange={onMfaEnabledChange}
        marginTop={20}
      />

      <TitledDivider>Permissions</TitledDivider>
      <AccountWindow.Permissions>
        {configuration.permissions.map(permission => {
          if (!user[permission.key]) return null;
          return (
            <AccountWindow.Permission key={permission.key}>
              <AccountWindow.CheckIcon />
              {permission.label}
            </AccountWindow.Permission>
          );
        })}
      </AccountWindow.Permissions>

      <Button
        width={85}
        onClick={handleClose}
        data-spec-id="my-account-modal-close-btn"
      >
        Close
      </Button>
    </Modal>
  );
};

AccountWindow.UserName = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 6px;
`;

AccountWindow.UserEmail = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

AccountWindow.Permissions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

AccountWindow.Permission = styled.p`
  flex-basis: 50%;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  position: relative;
  padding: 0 10px 0 20px;
`;

AccountWindow.CheckIcon = styled(FaCheck)`
  color: #41ab5d;
  position: absolute;
  top: 2px;
  left: 0;
`;

export default AccountWindow;
