import { ChartOption } from '../../chartOptions/models/index';
export interface SeriesMapping {
  id: string;
  seriesIndex: number;
  sourceName: string;
  displayName: string;
  units: string;
  order: number | null;
  color: string;
  aggregateMethod: string;
}
export interface SensorSeriesMapping {
  source: string;
  name: string;
  sensorSeriesId: string;
  units: string;
  color: string;
  wellSensorSeries: {
    tagId: string;
    wellId: string;
  }[];
}

export interface CalculatedSeriesMapping {
  source: string;
  name: string;
  calculatedSeriesId: string;
  units: string;
  color: string;
  tableName: string;
  wellCalculatedSeries: {
    wellId: string;
  }[];
}

export type NormalizedSensorSeriesMappingItem = Omit<
  SensorSeriesMapping,
  'wellSensorSeries'
> & {
  tags: {
    tagId: string;
    wellId: string;
  }[];
};

export type NormalizedCalculatedSeriesMappingItem = Omit<
  SensorSeriesMapping,
  'wellCalculatedSeries'
> & {
  tags: {
    wellId: string;
  }[];
};

export interface NormalizedSensorSeriesMapping {
  [key: string]: NormalizedSensorSeriesMappingItem;
}

export interface NormalizedCalculatedSeriesMapping {
  [key: string]: NormalizedCalculatedSeriesMappingItem;
}

export interface NormalizedSeriesMapping {
  [key: string]: SeriesMapping;
}

export interface NormalizedCoreSeriesMapping {
  [key: string]: ChartOption;
}

export interface SelectedSeriesMapping {
  [key: string]: boolean;
}

export type Series = {
  id: string;
  wellId: string;
  day: Date;
} & { [key: string]: number };

export type NormalizedSeries = {
  day: Date;
} & { [key: string]: number };

export type SensorSeries = {
  tag: string;
  recordedAt: Date;
  value: number;
};

export type CalculatedSeries = {
  well_id: string;
  timestamp_utc: string;
  value: number;
};

export const MAGNIFICATION_FACTOR = 3;
export const SERIES_PILL_HEIGHT = 30;
export const SERIES_PILL_OFFSET = 3;
