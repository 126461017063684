import * as React from 'react';
import EventRect from './EventRect';
import { Dialog } from '../../ui/models/dialog';
import { NormalizedEvent, TooltipOptionsType } from '../models/index';
import { Note } from '../../notes/models/notes';
import EventTriangle from './EventTriangle';

interface SVGNotesChartProps {
  events: NormalizedEvent[];
  height: number;
  xScale: any;
  onPanelOpen: (data: Dialog) => void;
  openTooltip: (data: TooltipOptionsType) => void;
  closeTooltip: () => void;
  externalLinePosition: any;
  trianglesOptions: {
    position: number;
    note: Note;
  }[];
}

const SVGNotesChart = ({
  events,
  height,
  xScale,
  onPanelOpen,
  openTooltip,
  closeTooltip,
  externalLinePosition,
  trianglesOptions,
}: SVGNotesChartProps) => {
  return (
    <svg
      height={height}
      width={xScale.range()[1] - 1}
      viewBox={`0 0 ${xScale.range()[1]} ${height}`}
      shapeRendering="geometricPrecision"
      style={{ position: 'relative', display: 'block' }}
      preserveAspectRatio="none"
    >
      {events.map((event: NormalizedEvent) => (
        <EventRect
          key={event.id}
          closeTooltip={closeTooltip}
          openTooltip={openTooltip}
          event={event}
          onPanelOpen={onPanelOpen}
        />
      ))}
      {trianglesOptions.map(options => {
        if (externalLinePosition === options.position) return null;
        return (
          <EventTriangle
            key={options.note.id}
            position={options.position}
            note={options.note}
            openTooltip={openTooltip}
            closeTooltip={closeTooltip}
          />
        );
      })}
    </svg>
  );
};

export default SVGNotesChart;
