import { format } from 'd3-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as R from 'ramda';
import * as React from 'react';
import { Column, Table, AutoSizer, SortDirection } from 'react-virtualized';
import styled from 'styled-components';

import useMenu from 'hooks/useMenu';

import { CELL_HEIGHT, VarianceTableItem } from '../models/drilldownTable';
import ContextMenu from './ContextMenu';
import VarianceTableRow from './VarianceTableRow';
import VarianceHeaderRow from './VarianceHeaderRow';
import { useMultipleSelection } from '../hooks/useMultipleSelection';
import { getDrilldownTableParams } from '../DrilldownTableReducer';
import { measureText } from 'modules/filter/utils/text';
import { normalizeProductionLost } from '../utils/normalizeProductionLose';
import { getAppConfig } from '../../appConfig/AppConfigReducer';
import { getLostProductionExplanation } from 'modules/appSettings/utils';
import { calculateVarianceTotalColumn } from '../utils/calculateVarianceDrilldownTotalColumn';
import { useSelector } from 'store/models';

const numberRateFormat = format(',.1f');
const numberVolumeFormat = format(',d');

const UP_KEYCODE = 38;
const DOWN_KEYCODE = 40;

interface VarianceDrilldownTableColumnsProps {
  addSeveralOptions: (optionIds: string[]) => void;
  addVarianceOption: (optioinId: string) => void;
  currentTable: string | null;
  currentVarianceOptionIds: string[];
  drilldownTable: VarianceTableItem[];
  isLoading: boolean;
  onChoose: (rowData: Record<string, any>) => void;
  removeVarianceOption: (optioinId: string) => void;
  resetCurrentVarianceOption: () => void;
  searchWord: string;
  tableHeight: number;
  volumeType: boolean;
  firstColDataKey: string;
  secondColDataKey: string;
  thirdColDataKey: string;
  fourthColDataKey: string;
}

const VarianceDrilldownTableColumns = ({
  addSeveralOptions,
  addVarianceOption,
  currentTable,
  currentVarianceOptionIds,
  drilldownTable,
  isLoading,
  onChoose,
  removeVarianceOption,
  resetCurrentVarianceOption,
  searchWord,
  tableHeight,
  volumeType,
  firstColDataKey,
  secondColDataKey,
  thirdColDataKey,
  fourthColDataKey,
}: VarianceDrilldownTableColumnsProps) => {
  const numberFormat = volumeType ? numberVolumeFormat : numberRateFormat;

  const { rateVolume } = useSelector(getDrilldownTableParams);

  const optionColumns = useSelector(
    state => state.drilldownTable.optionColumns,
  );
  const appConfig = useSelector(getAppConfig);
  const explainTitle = React.useMemo(
    () =>
      getLostProductionExplanation({
        gasPrice: appConfig.gasPriceAssumption,
        oilPrice: appConfig.oilPriceAssumption,
      }),
    [appConfig.gasPriceAssumption, appConfig.oilPriceAssumption],
  );
  const contextMenuColumns = React.useMemo(() => {
    const columnMapping = {
      [firstColDataKey]: 'Variance Category',
      [secondColDataKey]: '%',
      [thirdColDataKey]: '$',
      [fourthColDataKey]: 'Variance',
    };

    const columnsToPick = [firstColDataKey];
    if (optionColumns.includes(secondColDataKey))
      columnsToPick.push(secondColDataKey);
    if (optionColumns.includes(thirdColDataKey))
      columnsToPick.push(thirdColDataKey);
    columnsToPick.push(fourthColDataKey);

    return {
      header: columnsToPick.map(col => columnMapping[col]),
      dataKeys: columnsToPick,
    };
  }, [
    optionColumns,
    firstColDataKey,
    secondColDataKey,
    thirdColDataKey,
    fourthColDataKey,
  ]);

  const searchedTable: any[] = React.useMemo(() => {
    const searchWordInLowerCase = searchWord.toLowerCase();
    const searchResult = drilldownTable.filter(drilldownTableRow =>
      (drilldownTableRow[firstColDataKey] as string)
        .toLowerCase()
        .includes(searchWordInLowerCase),
    );
    const normMatchedItems = searchWord
      ? searchResult.map(drilldownItem => ({
          ...drilldownItem,
          matched: [
            (drilldownItem[firstColDataKey] as string)
              .toLowerCase()
              .indexOf(searchWordInLowerCase),
            (drilldownItem[firstColDataKey] as string)
              .toLowerCase()
              .indexOf(searchWordInLowerCase) + searchWord.length,
          ],
        }))
      : searchResult;
    return normMatchedItems;
  }, [firstColDataKey, searchWord, drilldownTable]);

  const [sortBy, setSortBy] = React.useState(fourthColDataKey);
  const [sortDirection, setSortDirection] = React.useState(SortDirection.ASC);
  const sort = sortTarget => {
    if (sortTarget === sortBy) {
      setSortDirection(
        sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC,
      );
    } else {
      setSortBy(sortTarget);
      setSortDirection(SortDirection.ASC);
    }
  };
  const sortedList = React.useMemo(() => {
    if (sortDirection === SortDirection.ASC) {
      const sortResult = searchedTable.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return -1;
        if (a[sortBy] > b[sortBy]) return 1;
        return 0;
      });
      return sortResult;
    }
    const sortResult = searchedTable.sort((a, b) => {
      if (a[sortBy] > b[sortBy]) return -1;
      if (a[sortBy] < b[sortBy]) return 1;
      return 0;
    });

    return sortResult;
  }, [sortBy, sortDirection, searchedTable]);

  const totalRow = React.useMemo(() => {
    return calculateVarianceTotalColumn(
      sortedList,
      firstColDataKey,
      secondColDataKey,
      thirdColDataKey,
      fourthColDataKey,
    );
  }, [
    firstColDataKey,
    secondColDataKey,
    thirdColDataKey,
    fourthColDataKey,
    sortedList,
  ]);
  const showBottomTotalRow =
    tableHeight <= CELL_HEIGHT * searchedTable.length + 68;
  const resultList = showBottomTotalRow
    ? searchedTable
    : searchedTable.concat(totalRow);

  const columnWidths = React.useMemo(() => {
    const widths = resultList.reduce(
      (acc, row) => {
        if (row[secondColDataKey] === undefined) return acc;

        acc[fourthColDataKey] = Math.max(
          acc[fourthColDataKey],
          measureText(numberFormat(row[fourthColDataKey]), 12),
        );

        acc[secondColDataKey] = Math.max(
          acc[secondColDataKey],
          measureText(row[secondColDataKey] + '%', 12),
        );
        acc[thirdColDataKey] = Math.max(
          acc[thirdColDataKey],
          measureText(normalizeProductionLost(row[thirdColDataKey]), 12),
        );

        return acc;
      },
      {
        [fourthColDataKey]: measureText(
          numberFormat(totalRow[fourthColDataKey]),
          12,
        ),
        [secondColDataKey]: measureText('%', 12),
        [thirdColDataKey]: measureText('$/Day', 12),
      },
    );

    widths[secondColDataKey] += 16;
    widths[thirdColDataKey] += 16;
    if (widths[fourthColDataKey] < 35) widths[fourthColDataKey] = 35;

    return widths;
  }, [resultList, secondColDataKey, thirdColDataKey]);

  const { lastSelected, select, selectHandler } = useMultipleSelection<{
    event: MouseEvent;
    index: number;
    rowData: Record<string, any>;
  }>({
    isItemSelected: ({ rowData }) =>
      currentVarianceOptionIds.includes(rowData.varianceOptionId),
    selectedItemCount: currentVarianceOptionIds.length,
    onSelect: ({ rowData }) => onChoose(rowData),
    onSelectMany: ({ from, to }) => {
      const ids = searchedTable.slice(from, to).map(r => r.varianceOptionId);
      addSeveralOptions(ids);
    },
    onAddSelection: ({ rowData }) =>
      addVarianceOption(rowData.varianceOptionId),
    onDeselect: ({ rowData }) => removeVarianceOption(rowData.varianceOptionId),
  });

  const rowRenderer = (props: {
    index: number;
    onRowClick: (row: {
      event: MouseEvent;
      index: number;
      rowData: Record<string, any>;
    }) => void;
    rowData: Record<string, any>;
    style: { [prop: string]: string };
    className: string;
  }) => {
    const { className, rowData } = props;
    const isHighlighted = R.isEmpty(currentVarianceOptionIds)
      ? true
      : currentVarianceOptionIds.includes(rowData.varianceOptionId);
    const newClassName = isHighlighted
      ? className + ' row--highlighted'
      : className + ' row--not-highlighted';

    return (
      <VarianceTableRow
        {...props}
        className={newClassName}
        firstColDataKey={firstColDataKey}
        secondColDataKey={secondColDataKey}
        thirdColDataKey={thirdColDataKey}
        fourthColDataKey={fourthColDataKey}
        optionColumns={optionColumns}
        hasColorEffects
        isHighlighted={isHighlighted}
        bgColor={rowData.color}
        format={numberFormat}
        wide={volumeType}
        isTotalRow={
          !showBottomTotalRow && props.index === resultList.length - 1
        }
        columnWidths={columnWidths}
        explainTitle={explainTitle}
      />
    );
  };

  const headerRowRenderer = (props: {
    className: string;
    style: { [prop: string]: string };
    sort: (sortTarget: string) => void;
    sortBy: string;
    sortDirection: string;
  }) => {
    return (
      <VarianceHeaderRow
        {...props}
        firstColDataKey={firstColDataKey}
        secondColDataKey={secondColDataKey}
        thirdColDataKey={thirdColDataKey}
        fourthColDataKey={fourthColDataKey}
        rateVolume={rateVolume}
        optionColumns={optionColumns}
        wide={volumeType}
        isVarianceOptionSelected={currentVarianceOptionIds.length > 0}
        resetCurrentVarianceOption={resetCurrentVarianceOption}
        columnWidths={columnWidths}
        explainTitle={explainTitle}
      />
    );
  };

  const chooseRowByKeyboard = React.useCallback(
    (e: KeyboardEvent) => {
      const willCrash = R.isEmpty(searchedTable);
      if (willCrash || currentTable !== 'VarianceDrilldownTable') return;
      const { target } = e;
      if (target instanceof HTMLElement) {
        const { tagName } = target;

        if (
          tagName === 'INPUT' ||
          tagName === 'TEXTAREA' ||
          tagName === 'SELECT'
        )
          return;
        const { keyCode } = e;
        if (!R.isEmpty(currentVarianceOptionIds)) {
          const highlightedIndecies = searchedTable.reduce((acc, item, i) => {
            if (currentVarianceOptionIds.includes(item.varianceOptionId))
              acc.push(i);
            return acc;
          }, []);

          if (keyCode === UP_KEYCODE) {
            e.preventDefault();
            const newHighlightedIndex = lastSelected
              ? Math.max(lastSelected - 1, 0)
              : Math.max(highlightedIndecies[0] - 1, 0);
            const newItem = searchedTable[newHighlightedIndex];
            onChoose(newItem);
            select(newHighlightedIndex);
          } else if (keyCode === DOWN_KEYCODE) {
            e.preventDefault();
            const lastItemIndex = searchedTable.length - 1;
            const lastHighlightedIndexByOrder = highlightedIndecies.length - 1;
            const newHighlightedIndex = lastSelected
              ? Math.min(lastSelected + 1, lastItemIndex)
              : Math.min(
                  highlightedIndecies[lastHighlightedIndexByOrder] + 1,
                  lastItemIndex,
                );
            const newItem = searchedTable[newHighlightedIndex];
            onChoose(newItem);
            select(newHighlightedIndex);
          }
        }
      }
    },
    [
      currentVarianceOptionIds,
      currentTable,
      lastSelected,
      onChoose,
      searchedTable,
    ],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', chooseRowByKeyboard);
    return () => {
      document.removeEventListener('keydown', chooseRowByKeyboard);
    };
  }, [chooseRowByKeyboard]);

  const contextMenuElRef = React.useRef(null);
  const [
    isMenuOpen,
    onMenuHover,
    onMenuLeave,
    onTargetClick,
    closeMenu,
    contextPosition,
  ] = useMenu(contextMenuElRef);

  return (
    <VarianceDrilldownTableColumns.Container
      onClick={e => {
        if (
          Boolean(e.target.className.includes) &&
          e.target.className.includes('ReactVirtualized__Table__Grid')
        )
          resetCurrentVarianceOption();
      }}
      onContextMenu={e => {
        e.preventDefault();
        onTargetClick(e);
      }}
    >
      {!isLoading ? (
        <>
          <AutoSizer>
            {({ height, width }) => (
              <VarianceDrilldownTableColumns.StyledTable
                rowStyle={{ alignItems: 'stretch' }}
                rowHeight={CELL_HEIGHT}
                headerHeight={CELL_HEIGHT}
                headerRowRenderer={(props: {
                  className: string;
                  style: { [prop: string]: string };
                }) =>
                  headerRowRenderer({
                    ...props,
                    sortBy,
                    sortDirection,
                    sort,
                  })
                }
                onRowClick={selectHandler}
                rowCount={resultList.length}
                rowGetter={({ index }) => resultList[index]}
                height={showBottomTotalRow ? height - CELL_HEIGHT : height}
                width={width}
                rowRenderer={rowRenderer}
                scrollToAlignment="auto"
                showBottomTotalRow={showBottomTotalRow}
              >
                <Column
                  label={
                    firstColDataKey[0].toUpperCase() +
                    firstColDataKey.substring(1)
                  }
                  dataKey={firstColDataKey}
                  width={140}
                  flexGrow={1}
                  style={{ display: 'flex', alignItems: 'center' }}
                />
                <Column
                  width={55}
                  label="Percent"
                  dataKey={secondColDataKey}
                  style={{ display: 'flex', alignItems: 'center' }}
                />
                <Column
                  width={65}
                  label="Currency"
                  dataKey={thirdColDataKey}
                  style={{ display: 'flex', alignItems: 'center' }}
                />
                <Column
                  width={55}
                  label="Var"
                  dataKey={fourthColDataKey}
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </VarianceDrilldownTableColumns.StyledTable>
            )}
          </AutoSizer>
          {showBottomTotalRow ? (
            <VarianceDrilldownTableColumns.TotalRow wide={volumeType}>
              <div>TOTAL</div>
              <VarianceDrilldownTableColumns.TotalColumn
                title={totalRow[fourthColDataKey]}
                width={columnWidths[fourthColDataKey]}
                variance
              >
                {numberFormat(totalRow[fourthColDataKey])}
              </VarianceDrilldownTableColumns.TotalColumn>
              {optionColumns.includes(secondColDataKey) && (
                <VarianceDrilldownTableColumns.TotalColumn
                  title="100.0"
                  width={columnWidths[secondColDataKey]}
                >
                  {'100 %'}
                </VarianceDrilldownTableColumns.TotalColumn>
              )}
              {optionColumns.includes(thirdColDataKey) && (
                <VarianceDrilldownTableColumns.TotalColumn
                  title={explainTitle}
                  width={columnWidths[thirdColDataKey]}
                >
                  {normalizeProductionLost(totalRow[thirdColDataKey])}
                </VarianceDrilldownTableColumns.TotalColumn>
              )}
            </VarianceDrilldownTableColumns.TotalRow>
          ) : null}
        </>
      ) : (
        <VarianceDrilldownTableColumns.CircularProgressWrapper>
          <CircularProgress color="inherit" />
        </VarianceDrilldownTableColumns.CircularProgressWrapper>
      )}
      {isMenuOpen && (
        <ContextMenu
          closeMenu={closeMenu}
          contextPosition={contextPosition}
          data={searchedTable.concat(totalRow)}
          dataKeys={contextMenuColumns.dataKeys}
          header={contextMenuColumns.header}
          ref={contextMenuElRef}
          onMenuHover={onMenuHover}
          onMenuLeave={onMenuLeave}
        />
      )}
    </VarianceDrilldownTableColumns.Container>
  );
};

VarianceDrilldownTableColumns.Container = styled.div`
  height: calc(100% - 43px);
  overflow: hidden;
  font-family: 'Lato', sans-serif;
  border-top: 1px solid #c1c1c1;

  & > div:first-child {
    z-index: 20;
    position: relative;
  }
`;

VarianceDrilldownTableColumns.StyledTable = styled(Table)`
  user-select: none;
  z-index: 20;

  & * {
    outline: none;
  }

  & .ReactVirtualized__Table__headerRow {
    background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
    font-size: 12px;
    height: 18px;
  }

  & .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: flex-end;

    &:nth-child(2) {
      justify-content: flex-start;
      flex-direction: row-reverse;
      border-left: 1px solid #c1c1c1;
      margin-right: 5px;
    }
  }

  & .ReactVirtualized__Table__row {
    box-shadow: 1px 0 0 0 #c1c1c1, 1px 1px 0 0 #c1c1c1, 0 1px 0 0 #c1c1c1 inset;

    &:first-child {
      box-shadow: 1px 0 0 0 #c1c1c1, 1px 1px 0 0 #c1c1c1;
    }

    &:last-child {
      border-bottom: 1px solid #c1c1c1;

      > div {
        font-weight: ${props => (props.showBottomTotalRow ? '500' : '700')};
        color: ${props => (props.showBottomTotalRow ? '' : 'rgba(0,0,0,0.8)')};
      }
    }

    &:hover {
      box-shadow: 1px 0 0 0 black, 0 1px 0 0 black;
      z-index: 20;
      border-top: 1px solid black;
    }

    &:last-child:hover {
      border-bottom: 1px solid black;
    }
  }

  & .ReactVirtualized__Table__rowColumn[aria-colindex='1'] {
    margin: 0 !important;
    padding: 0 5px !important;
    border-top: 1px solid #c1c1c1;
  }

  & .ReactVirtualized__Table__rowColumn[aria-colindex='2'] {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border-left: 1px solid #c1c1c1;
    margin-right: 5px;
  }

  & span {
    text-transform: none;
    overflow: visible;
  }

  & .row--highlighted {
    opacity: 1;
  }

  & .row--not-highlighted {
    opacity: 0.4;
  }
`;

VarianceDrilldownTableColumns.CircularProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

VarianceDrilldownTableColumns.TotalRow = styled.div`
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #c1c1c1;
  width: 100%;
  line-height: 1em;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  padding-right: 7px;
  padding-bottom: 2px;
  height: ${CELL_HEIGHT - 1}px;
  z-index: 10;
  & > div {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-right: 2px;
    cursor: default;
  }
  & > div:first-child {
    flex: 1 1 200px;
    padding-left: 5px;
  }

  & > div:not(:first-child) {
    border-left: 1px solid #c1c1c1;
    justify-content: flex-end;
  }
`;

VarianceDrilldownTableColumns.TotalColumn = styled.div`
  width: ${({ width, variance }) => (variance ? width + 5 : width)}px;
  margin-right: 0px;
`;

export default React.memo<VarianceDrilldownTableColumnsProps>(
  VarianceDrilldownTableColumns,
);
