import { EventNote } from '../models';
import * as R from 'ramda';
import appConfig from 'config/AppConfig';

const normalizeEventNotes = (notes: EventNote[], wellId: string): any => {
  const apiUrl = appConfig.apiUrl.endsWith('/')
    ? appConfig.apiUrl
    : `${appConfig.apiUrl}/`;

  const normilizedNotes = notes.reduce((acc, note) => {
    return R.assocPath(
      [note.eventType, note.eventId, note.id],
      {
        ...note,
        noteTimestamp: new Date(note.noteTimestamp),
        attachments: note.attachments.map(a => ({
          ...a,
          url: a.type === 'image' ? `${apiUrl}${a.url}` : a.url,
        })),
      },
      acc,
    );
  }, {});
  return normilizedNotes;
};
export default normalizeEventNotes;
