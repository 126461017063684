import { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import { Capacity, Phase, PhaseToCapacity } from '../model';

export const Actions = {
  setCapacityFor: (payload: {
    eventId: string;
    capacity: Capacity[];
    phase: Phase;
  }) => ({
    type: 'SET_CAPACITY_FOR' as const,
    payload,
  }),
  setCapacity: (payload: PhaseToCapacity) => ({
    type: 'SET_CAPACITY' as const,
    payload,
  }),
  setIndicatorsCapacity: (payload: PhaseToCapacity) => ({
    type: 'SET_INDICATORS_CAPACITY' as const,
    payload,
  }),
  setCapacityEvents: (payload: CapacityChangeEvent[]) => ({
    type: 'SET_CAPACITY_EVENTS' as const,
    payload,
  }),
  updateCapacityEventDecline: (payload: {
    eventId: string;
    decline: number;
    phase: Phase;
  }) => ({
    type: 'UPDATE_CAPACITY_EVENT_DECLINE' as const,
    payload,
  }),
  updateCapacityEventBFactor: (payload: {
    eventId: string;
    bFactor: number;
    phase: Phase;
  }) => ({
    type: 'UPDATE_CAPACITY_EVENT_B_FACTOR' as const,
    payload,
  }),
  updateCapacityEventInitialRate: (payload: {
    eventId: string;
    rate: number;
    phase: Phase;
  }) => ({
    type: 'UPDATE_CAPACITY_EVENT_INITIAL_RATE' as const,
    payload,
  }),
  setIdHistory: (payload: any) => ({
    type: 'SET_ID_HISTORY' as const,
    payload,
  }),
};
