import {
  decodeCalculatedSeries,
  decodeDataSeries,
  decodeSensorSeries,
} from 'modules/router/utils/router';

export const getSeriesFromLayoutConfiguration = (configuration: string) => {
  const sensorSeriesString = configuration.match(/(?<=sensorseries=)[^&]+&??/);
  const dataSeriesString = configuration.match(/(?<=dataseries=)[^&]+&??/);

  const sensorSeries = sensorSeriesString
    ? decodeSensorSeries(sensorSeriesString[0]).map(s =>
        s.dataSeriesGroup.map(g => g.id),
      )
    : [];
  const dataSeries = dataSeriesString
    ? decodeDataSeries(dataSeriesString[0]).map(s =>
        s.dataSeriesGroup.filter(g => g.id.startsWith('s')).map(g => g.id),
      )
    : [];

  return sensorSeries.concat(dataSeries).flat();
};

export const getCalculatedSeriesFromLayoutConfiguration = (
  configuration: string,
) => {
  const calculatedSeriesString = configuration.match(
    /(?<=calculatedseries=)[^&]+&??/,
  );
  const dataSeriesString = configuration.match(/(?<=dataseries=)[^&]+&??/);

  const sensorSeries = calculatedSeriesString
    ? decodeCalculatedSeries(calculatedSeriesString[0]).map(s =>
        s.dataSeriesGroup.map(g => g.id),
      )
    : [];
  const dataSeries = dataSeriesString
    ? decodeDataSeries(dataSeriesString[0]).map(s =>
        s.dataSeriesGroup.filter(g => g.id.startsWith('c')).map(g => g.id),
      )
    : [];
  return sensorSeries.concat(dataSeries).flat();
};
