import * as R from 'ramda';

import type { CapacityChangeEvent } from '../models/capacityChangeEvent';
import { convertToChartDate } from 'helpers';

const normalizeCapacity = (
  rawCapacityChangeEvents: any[],
): { [id: string]: CapacityChangeEvent } => {
  const entries = rawCapacityChangeEvents.map(rawCapacityEvent =>
    R.compose(
      R.assoc(
        'dayInit',
        convertToChartDate(new Date(rawCapacityEvent.dayInit)),
      ),
      R.assoc('extraInputsData', JSON.parse(rawCapacityEvent.extraInputsData)),
    )(rawCapacityEvent),
  );

  return R.indexBy(R.prop('id'), entries);
};

export default normalizeCapacity;
