import * as R from 'ramda';
import { TimeInterval, timeHour } from 'd3-time';
import { timeFormat } from 'd3-time-format';

import { getMinDate } from 'helpers';
import {
  BOE,
  GLR,
  GOR,
  WATERCUT,
  INDICATORS,
  TOTAL_LIQUID,
} from 'modules/phase/models/phase';
import {
  countBoeValue,
  countGlrValue,
  countGorValue,
  countTotalLiquidValue,
  countWatercutValue,
} from 'modules/production/utils';

import type { CapacityChangeEvent } from '../models/capacityChangeEvent';

export const INDICATORS_LIST = [BOE, GOR, WATERCUT, GLR, TOTAL_LIQUID];
export const INDICATORS_VALUE_CALC = {
  [GOR]: countGorValue,
  [BOE]: countBoeValue,
  [WATERCUT]: countWatercutValue,
  [TOTAL_LIQUID]: countTotalLiquidValue,
  [GLR]: countGlrValue,
};

const filterUniqueDates = (dataset: { date: Date; capacity: number }[][]) =>
  dataset.map(arr =>
    Object.values(
      arr.reduce((acc, item) => {
        if (acc[timeFormat('%m/%d/%Y')(item.date)]) {
          return acc;
        }
        acc[timeFormat('%m/%d/%Y')(item.date)] = item;
        return acc;
      }, {} as { [date: string]: { date: Date; capacity: number } }),
    ),
  );

const countIndicatorsCapacityChartDataByIndicator = ({
  capacityEvents,
  capacityDataByPhase,
  lastDate,
  nri,
}: {
  capacityEvents: CapacityChangeEvent[];
  capacityDataByPhase: {
    [phase: string]: Array<{ date: Date; capacity: number }[]>;
  };
  lastDate: Date;
  nri?: number;
}) => {
  const interval = timeHour.every(24) as TimeInterval;
  const timeRanges = capacityEvents.map((capEvent, i) => {
    const lastRangeDate =
      i !== 0
        ? getMinDate(
            timeHour.offset(capacityEvents[i - 1].dayInit, 24),
            timeHour.offset(lastDate, 24),
          )
        : lastDate;

    return interval.range(capEvent.dayInit, lastRangeDate);
  });
  const timeRangesMap = INDICATORS_LIST.reduce((acc, indicatorName) => {
    acc[indicatorName] = R.clone(timeRanges);

    return acc;
  }, {});

  return R.keys(timeRangesMap).reduce((acc, indicatorName, i) => {
    const inputCapacityDatasets = [
      filterUniqueDates(capacityDataByPhase[INDICATORS[indicatorName][0]]),
      filterUniqueDates(capacityDataByPhase[INDICATORS[indicatorName][1]]),
    ];
    if (INDICATORS[indicatorName][2]) {
      inputCapacityDatasets.push(
        capacityDataByPhase[INDICATORS[indicatorName][2]],
      );
    }
    const valueUnitCalculator = INDICATORS_VALUE_CALC[indicatorName];
    const indicatorDataset = timeRangesMap[indicatorName].map((timeRange, i) =>
      timeRange.map((date, j) => {
        if (inputCapacityDatasets.length === 2) {
          const capacityValue = valueUnitCalculator(
            inputCapacityDatasets[0][i][j]
              ? inputCapacityDatasets[0][i][j].capacity
              : 0,
            inputCapacityDatasets[1][i][j]
              ? inputCapacityDatasets[1][i][j].capacity
              : 0,
          );
          return {
            date,
            capacity:
              nri !== undefined &&
              (indicatorName === BOE || indicatorName === TOTAL_LIQUID)
                ? capacityValue * nri
                : capacityValue,
          };
        }

        const capacityValue = valueUnitCalculator(
          inputCapacityDatasets[0][i][j]
            ? inputCapacityDatasets[0][i][j].capacity
            : 0,
          inputCapacityDatasets[1][i][j]
            ? inputCapacityDatasets[1][i][j].capacity
            : 0,
          inputCapacityDatasets[2][i][j]
            ? inputCapacityDatasets[2][i][j].capacity
            : 0,
        );

        return {
          date,
          capacity:
            nri !== undefined &&
            (indicatorName === BOE || indicatorName === TOTAL_LIQUID)
              ? capacityValue * nri
              : capacityValue,
        };
      }),
    );
    acc[indicatorName] = indicatorDataset;

    return acc;
  }, {});
};

export default countIndicatorsCapacityChartDataByIndicator;
