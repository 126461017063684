import { Middleware } from 'redux';

import type { Action, RootState } from 'store/models';

import * as AppActions from 'modules/app/AppActions';
import * as AuthActions from 'modules/auth/AuthActions';

import MetricsService from '../services/Metrics';
import { getAuthRolesString } from '../helpers/getAuthRolesString';
import { getAuthPermissionsString } from '../helpers/getAuthPermissionsString';

const metricsAuthMiddleware: Middleware =
  (store: any) => (next: any) => (action: Action) => {
    if (action.type === AuthActions.LOGOUT) {
      MetricsService.anonomizeUser();
      return next(action);
    }

    if (action.type === AppActions.AUTHENTICATED) {
      const state: RootState = store.getState();
      const auth = state.auth;

      if (!auth.id || !auth.firstName || !auth.lastName) {
        return next(action);
      }

      const roles = getAuthRolesString(auth);
      const perm = getAuthPermissionsString(auth);

      MetricsService.identifyUser({
        id: auth.id,
        name: `${auth.firstName} ${auth.lastName}`,
        roles,
        perm,
      });
    }

    return next(action);
  };

export default metricsAuthMiddleware;
