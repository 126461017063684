import React from 'react';
import domtoimage from 'dom-to-image';

interface ScreenshotProps {
  selector: string;
  onTakeScreenshot?: (screenshot: Blob) => void;
}

export const useScreenshot = ({
  onTakeScreenshot,
  selector,
}: ScreenshotProps) => {
  const [screenshot, setScreenshot] = React.useState<Blob | null>(null);
  const [isTakingScreenshot, setIsTakingScreenshot] = React.useState(false);

  const tryGetNode = React.useCallback((selector: string) => {
    try {
      return document.querySelector(selector);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Provided selector is not valid!');
    }

    return null;
  }, []);

  const takeScreenshot = React.useCallback(() => {
    setIsTakingScreenshot(true);
    const node = tryGetNode(selector);
    if (!node) return setIsTakingScreenshot(false);

    window.requestAnimationFrame(() => {
      domtoimage
        .toBlob(node, {
          quality: 1,
          bgcolor: '#ffffff',
        })
        .then(blob => {
          setScreenshot(blob);
        })
        .catch(error => {
          setIsTakingScreenshot(false);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    });
  }, [selector]);

  React.useEffect(() => {
    if (!screenshot) return;

    onTakeScreenshot?.call(null, screenshot);

    setScreenshot(null);
    setIsTakingScreenshot(false);
  }, [screenshot]);

  return {
    isTakingScreenshot,
    screenshot,
    takeScreenshot,
  };
};
