import {
  CustomAttributeValue,
  FilterLayoutOptions,
} from 'modules/filterLayouts/models';
import * as R from 'ramda';
import { Well } from 'modules/well/models/well';
import ArrayBoost from 'helpers/arrayBoost';

export const joinCustomAttributesToWells = (
  wells: Well[],
  customAttributes: FilterLayoutOptions,
  customValues: CustomAttributeValue[],
) => {
  const customData = customValues.reduce((acc, v) => {
    if (!acc[v.wellId]) acc[v.wellId] = {};
    const filterName = R.pathOr(
      v.wellCustomAttributeId,
      ['custom' + v.wellCustomAttributeId, 'filterName'],
      customAttributes,
    );
    acc[v.wellId][filterName] = v.value;
    return acc;
  }, {});

  const wellsWithCustomAttributes = wells.map(w => ({
    ...w,
    ...customData[w.id],
  }));

  return wellsWithCustomAttributes;
};

export const joinCustomAttributesToWellsOptimized = (
  wells: Well[],
  customAttributes: FilterLayoutOptions,
  customValues: CustomAttributeValue[],
) => {
  const customData = customValues.reduce((acc, v) => {
    if (!acc[v.wellId]) acc[v.wellId] = {};
    const filterName = R.pathOr(
      v.wellCustomAttributeId,
      ['custom' + v.wellCustomAttributeId, 'filterName'],
      customAttributes,
    );
    acc[v.wellId][filterName] = v.value;
    return acc;
  }, {});

  const wellsWithCustomAttributes = ArrayBoost.map<Well, any>(wells, w => ({
    ...w,
    ...customData[w.id],
  }));

  return wellsWithCustomAttributes;
};
