import * as R from 'ramda';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getGraphqlPayload } from 'store/helpers';

import { FETCH_APP_CONFIG, normalizeAppConfig } from './AppConfigActions';

import { LOCAL_APP_SETTINGS_KEYS } from './models/appConfig';

function* normilizeAppConfigsSaga(action): Generator<any, any, any> {
  const config = getGraphqlPayload(action).appConfig;
  const localSettingsToMerge = R.pick(LOCAL_APP_SETTINGS_KEYS, config);

  yield put(
    normalizeAppConfig({
      ...config,
      ...localSettingsToMerge,
    }),
  );
}

function* appConfigSagas(): Generator<any, any, any> {
  yield all([
    takeLatest([`${FETCH_APP_CONFIG}_SUCCESS`], normilizeAppConfigsSaga),
  ]);
}

export default appConfigSagas;
