import { all, put, takeLatest } from 'redux-saga/effects';

import { AUTHENTICATED } from 'modules/app/AppActions';
import { fetchAllFeatureFlags } from './FeatureFlagsActions';

function* fetchAllFeatureFlagsSaga(): Generator<any, any, any> {
  yield put(fetchAllFeatureFlags());
}

function* featureFlagsSagas(): Generator<any, any, any> {
  yield all([takeLatest([AUTHENTICATED], fetchAllFeatureFlagsSaga)]);
}

export default featureFlagsSagas;
