import { setLayoutConfiguration } from 'modules/drilldownTable/DrilldownTableActions';
import { getFlagsStateMap } from 'modules/featureFlags/FeatureFlagsReducer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirstRender } from './useFirstRender';

export function useDrillDownLayout() {
  const flagsState: ReturnType<typeof getFlagsStateMap> =
    useSelector(getFlagsStateMap);
  const dispatch = useDispatch();
  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (!isFirstRender && !flagsState.customDrilldown)
      dispatch(setLayoutConfiguration('standard'));
  }, [flagsState.customDrilldown]);

  return flagsState.customDrilldown;
}
