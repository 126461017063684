import * as R from 'ramda';
import {
  all,
  call,
  put,
  putResolve,
  takeLatest,
  select,
} from 'redux-saga/effects';

import { NORMALIZE_APP_CONFIG } from 'modules/appConfig/AppConfigActions';
import { parseSearchParams } from 'modules/router/utils/router';
import { SET_CURRENT_WELL_ID, SET_WELL_REASON } from 'modules/ui/UIActions';
import { getCurrentWellId } from 'modules/ui/UIReducer';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import { Action } from 'store/models';
import DB from 'database';

import { getDefaultProductionRange, normalizeProduction } from './utils';
import {
  initFetchProduction,
  changeExtremeDates,
  populateProduction,
  setDataAvailibility,
  CHANGE_EXTREME_DATE,
  INIT_FETCH_PRODUCTION,
  FETCH_WELL_PRODUCTION,
  fetchWellProductionDateRange,
  FETCH_WELL_PRODUCTION_DATE_RANGE,
} from './ProductionActions';
import { getExtremeDates } from './ProductionReducer';
import { POPULATE_PRIMARY_CHART_DATA } from 'modules/chart/ChartActions';
import { timeDay } from 'd3-time';

/**
 * Changes production extreme dates and starts production data fetching if needed
 */
function* changeExtremeDatesSaga(action: Action): Generator<any, any, any> {
  const { datePosition, dateValue } = action.payload;

  const extremeDates = yield select(getExtremeDates);

  const newExtremeDates = R.assoc(datePosition, dateValue, extremeDates);
  yield putResolve(changeExtremeDates(newExtremeDates));
}
/**
 * Checks whether production data is already cached and decides
 * whether it is needed to fetch new data and what time range to fetch
 */
function* initFetchProductionSaga(): Generator<any, any, any> {
  const currentWellId = yield select(getCurrentWellId);
  if (!currentWellId) return;

  const productionExists = yield call(
    DB.checkWellProductionExists,
    currentWellId,
  );

  if (productionExists) {
    const dataFromDB = yield call(DB.getWellProduction, currentWellId);
    yield put(populateProduction({ wellId: currentWellId, data: dataFromDB }));
    yield put(
      setDataAvailibility({
        wellId: currentWellId,
        fromDay: dataFromDB[0].day,
      }),
    );

    return;
  }
  const extemeDates = yield select(getExtremeDates);
  const searchString = window.location.search;
  const routeSearchParams = parseSearchParams(searchString);
  const dateSource = R.isNil(extemeDates) ? routeSearchParams : extemeDates;
  yield put(
    fetchWellProductionDateRange({
      wellId: currentWellId,
      minDate: timeDay(R.pathOr(new Date('01-01-15'), ['min'], dateSource)),
      maxDate: timeDay(R.pathOr(new Date(), ['max'], dateSource)),
    }),
  );
}

/**
 * When current well is changed, starts production fetching
 */
function* fetchProductionForCurrentWellSaga(action): Generator<any, any, any> {
  const reason: SET_WELL_REASON = action.payload.reason;
  if (reason === 'URL_CHANGE') return;

  if (
    window.location.pathname.includes('/dashboard') ||
    window.location.pathname.includes('/filter') ||
    window.location.pathname.includes('/share') ||
    window.location.pathname.includes('/backlog')
  ) {
    yield put(initFetchProduction());
  }
}

function* setInitialExtremeDates(action): Generator<any, any, any> {
  const storeExtremeDates = yield select(getExtremeDates);
  const parsedSearchParams = parseSearchParams(window.location.search);
  const routeExtremeDates: { min: Date | null; max: Date | null } = {
    min: parsedSearchParams.min,
    max: parsedSearchParams.max,
  };
  const routeDatesMatchStore =
    routeExtremeDates.min &&
    routeExtremeDates.max &&
    storeExtremeDates &&
    routeExtremeDates.min.getTime() === storeExtremeDates.min.getTime() &&
    routeExtremeDates.max.getTime() === storeExtremeDates.max.getTime();

  if (!routeDatesMatchStore && routeExtremeDates.min && routeExtremeDates.max) {
    yield put(
      changeExtremeDates({
        min: routeExtremeDates.min,
        max: routeExtremeDates.max,
      }),
    );

    return;
  } else if (!storeExtremeDates) {
    const config = action.payload;
    const defaultProductionRange = getDefaultProductionRange(
      config.today ? new Date(config.today) : new Date(),
    );

    yield put(changeExtremeDates(defaultProductionRange));
  }
}

function* populateProductionSaga(action): Generator<any, any, any> {
  const {
    payload: { wellId },
  } = getGraphqlPrevActionVariables(action);
  const { responseData } = getGraphqlPayload(action);
  const rawProduction = JSON.parse(responseData).data;
  if (R.isEmpty(rawProduction)) {
    yield put(
      populateProduction({
        wellId,
        data: [
          {
            boe: 0,
            day: new Date(),
            gas: 0,
            glr: 0,
            gor: 0,
            oil: 0,
            water: 0,
            watercut: 0,
            total_liquid: 0,
            wellId,
          },
        ],
      }),
    );

    return;
  }
  const dataFromDB = yield call(DB.getWellProduction, rawProduction[0].wellId);

  const currentWellId = yield select(getCurrentWellId);

  const newWellProduction = normalizeProduction({
    rawProduction,
    readyProduction: dataFromDB,
  });

  const fromDay = newWellProduction[0].day;
  yield call(DB.saveWellProduction, wellId, newWellProduction);
  if (wellId === currentWellId) {
    yield put(populateProduction({ wellId, data: newWellProduction }));
    yield put(setDataAvailibility({ wellId, fromDay }));
  } else {
    yield put(setDataAvailibility({ wellId, fromDay }));
  }
}
function* populateProductionDateRangeSaga(action): Generator<any, any, any> {
  if (action.payload.type !== `${FETCH_WELL_PRODUCTION_DATE_RANGE}_SUCCESS`)
    return;

  const {
    payload: { wellId },
  } = getGraphqlPrevActionVariables(action.payload);
  const { responseData } = getGraphqlPayload(action.payload);
  const rawProduction = JSON.parse(responseData).data;

  if (R.isEmpty(rawProduction)) {
    yield put(
      populateProduction({
        wellId,
        data: [
          {
            boe: 0,
            day: new Date(),
            gas: 0,
            glr: 0,
            gor: 0,
            oil: 0,
            water: 0,
            watercut: 0,
            total_liquid: 0,
            wellId,
          },
        ],
      }),
    );

    return;
  }
  const currentWellId = yield select(getCurrentWellId);
  const newWellProduction = normalizeProduction({
    rawProduction,
    readyProduction: [],
  });

  const fromDay = newWellProduction[0]?.day
    ? rawProduction[0]?.day
    : new Date();
  if (wellId === currentWellId) {
    yield put(populateProduction({ wellId, data: newWellProduction }));
    yield put(setDataAvailibility({ wellId, fromDay }));
  } else {
    yield put(setDataAvailibility({ wellId, fromDay }));
  }
}

function* productionSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(SET_CURRENT_WELL_ID, fetchProductionForCurrentWellSaga),
    takeLatest(CHANGE_EXTREME_DATE, changeExtremeDatesSaga),
    takeLatest(INIT_FETCH_PRODUCTION, initFetchProductionSaga),
    takeLatest(NORMALIZE_APP_CONFIG, setInitialExtremeDates),
    takeLatest(`${FETCH_WELL_PRODUCTION}_SUCCESS`, populateProductionSaga),
    takeLatest(POPULATE_PRIMARY_CHART_DATA, populateProductionDateRangeSaga),
  ]);
}

export default productionSagas;
