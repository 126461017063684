class WellRequestsAbortController {
  controller = new window.AbortController();

  abortCurrentRequests() {
    this.controller.abort();
    this.controller = new window.AbortController();
  }

  getSignal = () => {
    return this.controller.signal;
  };

  getContext = () => {
    return {
      fetchOptions: { signal: this.getSignal() },
    };
  };
}

export default new WellRequestsAbortController();
