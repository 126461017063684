import type { Well } from 'modules/well/models/well';

import { CustomDrilldownTableItem } from '../models/drilldownTable';

const normalizeCustomDrilldownTable = (
  customDrilldownTable: Record<string, any>[],
  wells: { [wellId: string]: Well },
  seriesKey: string,
  additionalKeys: string[],
): any => {
  const result = customDrilldownTable.reduce((acc, tableElem) => {
    const well = wells[tableElem.well_id];
    const additionalData: Record<string, any> = {};

    if (!well) return acc;

    additionalKeys.forEach(key => {
      additionalData[key] = well[key];
    });

    acc.push({
      act: tableElem.act || 0,
      cap: tableElem.cap || 0,
      var: tableElem.var || 0,
      value: tableElem[seriesKey] || 0,
      wellId: tableElem.well_id,
      well: well.LEASE,
      ...additionalData,
    });
    return acc;
  }, [] as CustomDrilldownTableItem[]);

  return result;
};

export default normalizeCustomDrilldownTable;
