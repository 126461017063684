import newRelic from './plugins/NewRelicPlugin';
import heap from './plugins/HeapPlugin';
import sentry from './plugins/SentryPlugin';
import logRocket from './plugins/LogRocketPlugin';

import type {
  MetricsUser,
  MetricsError,
  MetricsData,
  MetricsEventsKey,
} from '../models';
import { METRICS_EVENTS } from '../constants';
import MetricsPlugin from './MetricsPlugin';
import MetricsPluginPermissions from './MetricsPluginPermissions';

type MetricsServiceConfig = {
  plugins: MetricsPlugin[];
};

class MetricsService {
  public static debug = false;
  private config: MetricsServiceConfig;
  private plugins: {
    instance: MetricsPlugin;
    permissions: MetricsPluginPermissions;
  }[];

  constructor(config: MetricsServiceConfig) {
    this.config = config;
    this.plugins = config.plugins.map(plugin => {
      return {
        instance: plugin,
        permissions: new MetricsPluginPermissions(plugin),
      };
    });
  }

  identifyUser = (auth: MetricsUser) => {
    this.plugins.forEach(({ instance, permissions }) => {
      if (!permissions.canCaptureAuth()) return;
      instance.identifyUser(auth);
    });
  };

  anonomizeUser = () => {
    this.plugins.forEach(({ instance, permissions }) => {
      if (!permissions.canCaptureAuth()) return;
      instance.anonomizeUser();
    });
  };

  captureEvent = (key: MetricsEventsKey, data?: MetricsData) => {
    const event = METRICS_EVENTS[key];

    if (!event) return;

    this.plugins.forEach(({ instance, permissions }) => {
      if (!permissions.canCaptureEvent()) return;
      instance.captureEvent(event, data);
    });
  };

  captureError = (error: MetricsError, data?: MetricsData) => {
    this.plugins.forEach(({ instance, permissions }) => {
      if (!permissions.canCaptureError()) return;
      instance.captureError(error, data);
    });
  };
}

export const newRelicPlugin = new MetricsPlugin(newRelic, {
  id: 'NewRelic',
  env: ['*'],
  capture: ['*'],
  debug: MetricsService.debug,
});

export const heapPlugin = new MetricsPlugin(heap, {
  id: 'Heap',
  env: ['*', '!local'],
  capture: ['auth', 'event'],
  debug: MetricsService.debug,
});

export const sentryPlugin = new MetricsPlugin(sentry, {
  id: 'Sentry',
  env: ['*', '!local'],
  capture: ['auth', 'error'],
  debug: MetricsService.debug,
});

export const logRocketPlugin = new MetricsPlugin(logRocket, {
  id: 'LogRocket',
  env: ['*'],
  capture: ['*'],
  debug: MetricsService.debug,
});

export default new MetricsService({
  plugins: [newRelicPlugin, heapPlugin, sentryPlugin, logRocketPlugin],
});
