import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import { FaInfo } from 'react-icons/fa';
import styled from 'styled-components';
import { LEFT_PANEL_WIDTH } from 'modules/dashboard/models/dashboard';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import useNotesParams from 'modules/wellChart/hooks/useNotesParams';

interface EditCapacityButtonProps {
  subCategory: string;
  x: number;
  onClick: () => void;
  xScale: any;
}

const EditCapacityButton = ({
  subCategory,
  onClick,
  x,
  xScale,
}: EditCapacityButtonProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const { height: notesSectionHeight, isNotesSectionShown } =
    useNotesParams(xScale);
  return (
    <Wrapper
      x={x}
      notesSectionHeight={isNotesSectionShown ? notesSectionHeight : 0}
      id="edit-button"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <StyledButton
        style={{
          backgroundColor: '#F0F0F0',
          border: '1px solid rgba(0, 0, 0, 0.75)',
          color: 'rgba(0, 0, 0, 0.75)',
          padding: '0',
        }}
        onClick={() => onClick()}
        className="capacity-interactive interactive"
      >
        <FaInfo size="0.55em" />
      </StyledButton>
      {showTooltip && (
        <EditCapacityButton.TooltipContainer>
          <EditCapacityButton.Tooltip>
            <span>Capacity Change</span>
            <span>{subCategory}</span>
          </EditCapacityButton.Tooltip>
        </EditCapacityButton.TooltipContainer>
      )}
    </Wrapper>
  );
};

const StyledButton = styled(IconButton)`
  height: 20px;
  width: 20px;
`;

EditCapacityButton.TooltipContainer = styled.div`
  position: relative;
  top: 15px;
  left: -65px;
  min-width: 150px;
  display: flex;
  justify-content: center;
`;

EditCapacityButton.Tooltip = styled.div`
  padding: 5px;
  height: auto;
  width: auto;
  min-width: 90px;
  background: white;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  display: grid;
  justify-items: center;
  z-index: 100;
`;

const Wrapper = styled.div`
  position: absolute;
  top: ${props => props.notesSectionHeight - 9}px;
  left: calc(
    ${(props: { x: number }) => props.x - LEFT_PANEL_WIDTH - Y_AXIS_WIDTH}px -
      20px
  );
  z-index: 100;
`;

export default React.memo<EditCapacityButtonProps>(EditCapacityButton);
