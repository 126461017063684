import gql from 'graphql-tag';

import type { Action, GraphqlQueryAction } from 'store/models';

export const namespace = 'appConfig';
export const FETCH_APP_CONFIG = `${namespace}/FETCH_APP_CONFIG`;
export const SET_APP_CONFIG_LOCALLY = `${namespace}/SET_APP_CONFIG_LOCALLY`;
export const NORMALIZE_APP_CONFIG = `${namespace}/NORMALIZE_APP_CONFIG`;

const FETCH_APP_CONFIG_QUERY = gql`
  query {
    getAppConfig {
      appConfig
    }
  }
`;

type normalizeAppConfigAC = (payload: {
  drilldownDefaultFromDate: Date;
  drilldownDefaultToDate: Date;
  today: Date;
  todayOffset: number;
  minSummedCapacityDateSetting: boolean;
}) => Action;

export const normalizeAppConfig: normalizeAppConfigAC = payload => ({
  type: NORMALIZE_APP_CONFIG,
  payload,
});

type fetchAppConfigAC = () => GraphqlQueryAction;

export const fetchAppConfig: fetchAppConfigAC = () => ({
  type: FETCH_APP_CONFIG,
  payload: {
    key: 'getAppConfig',
    graphql: {
      query: FETCH_APP_CONFIG_QUERY,
    },
  },
});

type setAppConfigLocallyAC = (payload: { [key: string]: any }) => Action;

export const setAppConfigLocally: setAppConfigLocallyAC = payload => ({
  type: SET_APP_CONFIG_LOCALLY,
  payload,
});
