import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

import { DescriptionIcon } from 'components/Icons';

const LegendItemDescriptionTooltip = ({
  description,
}: {
  description?: string | null;
}) => {
  if (!description) return null;

  return (
    <Tooltip
      title={
        <>
          <LegendItemDescriptionTooltip.Header>
            Description:
          </LegendItemDescriptionTooltip.Header>
          <p>{description}</p>
        </>
      }
      placement="bottom"
      enterDelay={0}
    >
      <LegendItemDescriptionTooltip.IconWrapper>
        <DescriptionIcon width={12} height={12} color="#484848" />
      </LegendItemDescriptionTooltip.IconWrapper>
    </Tooltip>
  );
};

LegendItemDescriptionTooltip.IconWrapper = styled.div`
  svg {
    display: block;
  }
`;

LegendItemDescriptionTooltip.Header = styled.h3`
  font-weight: bold !important;
  font-size: 12px !important;
  height: 15px;
`;

export default LegendItemDescriptionTooltip;
