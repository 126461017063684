export const approximatedSearchByDate = <T>(
  array: T[],
  date: Date,
  compare: (a: Date, b: T) => number,
): number => {
  let m = 0;
  let n = array.length - 1;
  let min = Infinity;
  let approximated = -1;

  while (m <= n) {
    const k = (n + m) >> 1;
    const cmp = compare(date, array[k]);

    if (min > Math.abs(cmp)) {
      min = Math.abs(cmp);
      approximated = k;
    }

    if (cmp > 0) {
      m = k + 1;
    } else if (cmp < 0) {
      n = k - 1;
    } else {
      return k;
    }
  }

  return approximated;
};
