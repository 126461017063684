import { AuthState } from 'modules/auth/AuthReducer';
import { METRICS_AUTH_PERMISSIONS_CODES } from '../constants';

export const getAuthPermissionsString = (auth: AuthState) => {
  const res: number[] = [];

  Object.keys(auth).forEach(key => {
    const isPermitted = auth[key];
    const code = METRICS_AUTH_PERMISSIONS_CODES[key];
    if (isPermitted && code !== undefined) res.push(code);
  });

  return res.sort((a, b) => a - b).join(',');
};
