import { REHYDRATE } from 'redux-persist';

import { all, put, select, takeLatest } from 'redux-saga/effects';

import { LOGIN, LOGIN_VIA_SSO } from 'modules/auth/AuthActions';
import { getIsAuthenticated } from 'modules/auth/AuthReducer';

import { appAuthenticated, appBootstrap } from './AppActions';

function* appBootstrapSaga(): Generator<any, any, any> {
  yield put(appBootstrap());
}

function* appAuthenticatedSaga(): Generator<any, any, any> {
  const isAuthenticated = yield select(getIsAuthenticated);

  if (isAuthenticated) {
    return yield put(appAuthenticated());
  }
}

function* appSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(REHYDRATE, appBootstrapSaga),
    takeLatest(
      [REHYDRATE, `${LOGIN}_SUCCESS`, `${LOGIN_VIA_SSO}_SUCCESS`],
      appAuthenticatedSaga,
    ),
  ]);
}

export default appSagas;
