import { utcDay, TimeInterval } from 'd3-time';

import { OIL, GAS, WATER, BOE } from 'modules/phase/models/phase';

import { countBoeValue } from 'modules/production/utils';

import type { GroupChart, GroupChartPoint } from '../models/groupChart';
import { convertToChartDate } from '../../../helpers';
interface EmptyData {
  production: number;
  capacity: number;
  variance: {
    null: number;
  };
  day: Date;
}
interface GroupData {
  day: Date;
  oilProduction: number;
  gasProduction: number;
  waterProduction: number;
  oilCapacity: number;
  gasCapacity: number;
  waterCapacity: number;
}
interface GroupVarianceData {
  day: Date;
  oilVariance: number;
  gasVariance: number;
  waterVariance: number;
  varianceOptionId: string;
  allocIssueId: string;
}

interface ChartData {
  groupData: GroupData[];
  groupVarianceData: GroupVarianceData[];
}

const phasesList = [OIL, GAS, WATER, BOE];

const normalizeGroupVarianceChartData = (
  chartData: ChartData,
  extremeDates: { min: Date; max: Date },
): GroupChart => {
  const { groupVarianceData } = chartData;
  const groupDataIndexedByDay = groupVarianceData.reduce((acc, data) => {
    const {
      day: varianceDay,
      oilVariance,
      waterVariance,
      gasVariance,
      varianceOptionId,
      allocIssueId,
    } = data;
    const dayString = varianceDay.toString();
    const varianceOptionKey: string = varianceOptionId
      ? varianceOptionId
      : allocIssueId
      ? 'allocIssue'
      : 'null';
    if (acc[dayString]) {
      const oilVarianceData = acc[dayString][OIL].variance;
      acc[dayString][OIL].variance = {
        ...oilVarianceData,
        [varianceOptionKey]:
          (oilVarianceData[varianceOptionKey] ?? 0) + oilVariance,
      };
      const gasVarianceData = acc[dayString][GAS].variance;
      acc[dayString][GAS].variance = {
        ...gasVarianceData,
        [varianceOptionKey]:
          (gasVarianceData[varianceOptionKey] ?? 0) + gasVariance,
      };
      const waterVarianceData = acc[dayString][WATER].variance;
      acc[dayString][WATER].variance = {
        ...waterVarianceData,
        [varianceOptionKey]:
          (waterVarianceData[varianceOptionKey] ?? 0) + waterVariance,
      };
      return acc;
    }

    const dataPoint = {
      day: new Date(varianceDay),
      [OIL]: {
        variance: {
          [varianceOptionKey]: oilVariance,
        },
      },
      [GAS]: {
        variance: { [varianceOptionKey]: gasVariance },
      },
      [WATER]: {
        variance: { [varianceOptionKey]: waterVariance },
      },
    };
    acc[dayString] = dataPoint;
    return acc;
  }, {} as GroupChartPoint);

  const sorted = Object.values(groupDataIndexedByDay).sort(
    (a: GroupChartPoint, b: GroupChartPoint) =>
      a.day.getTime() - b.day.getTime(),
  );
  const interval = utcDay.every(1) as TimeInterval;
  const intervalEnd = sorted[0] ? sorted[0].day : extremeDates.max;
  const range = interval.range(extremeDates.min, intervalEnd);
  const emptyDays = range.map(date => {
    const emptyData = phasesList.reduce((acc, phase) => {
      acc[phase] = {
        production: 0,
        capacity: 0,
        variance: {
          null: 0,
        },
      };

      return acc;
    }, {} as EmptyData);

    emptyData.day = date;
    return emptyData;
  });

  const withIndicators = sorted.map(groupChartDataPoint => {
    const boeVariance = Object.keys(groupChartDataPoint[OIL].variance).reduce(
      (acc, varianceKey) => {
        acc[varianceKey] = countBoeValue(
          groupChartDataPoint[OIL].variance[varianceKey],
          groupChartDataPoint[GAS].variance[varianceKey],
        );
        return acc;
      },
      {},
    );

    return {
      ...groupChartDataPoint,
      [BOE]: {
        variance: boeVariance,
      },
    };
  });

  const consolidatedData = emptyDays.concat(withIndicators as any);

  const dataByPhase: GroupChart = phasesList.reduce((acc, phase) => {
    const phaseData = consolidatedData.map(dataPoint => ({
      day: convertToChartDate(dataPoint.day),
      ...dataPoint[phase],
    }));
    acc[phase] = phaseData;

    return acc;
  }, {});

  return dataByPhase;
};

export default normalizeGroupVarianceChartData;
