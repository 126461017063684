import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentWellId } from 'modules/ui/UIActions';
import { changeExtremeDates } from 'modules/production/ProductionActions';
import { getAllWells } from 'modules/well/WellReducer';
import { setMarkedRows } from 'modules/drilldownTable/DrilldownTableActions';
import {
  setSpotfireFilteredWells,
  setSpotfireMarkedWells,
  setSpotfireViewStartDate,
  setSpotfireViewEndDate,
} from '../SpotfireActions';
import useSpotfireOutputStorage from '../hooks/useSpotfireOutputStorage';
import spotfireDate from '../utils/spotfireDate';
import spotfireIdsArray from '../utils/spotfireIdsArray';
import {
  openCapacityConversation,
  openRibbonConversation,
  openVarianceConversation,
} from '../../inboxConversation/InboxConversationActions';
import { getCurrentWellId } from '../../ui/UIReducer';
import { SpotfireOutEventPanel } from '../models';

const DashboardsOutputPersistor = () => {
  const dispatch = useDispatch();
  const wells = useSelector(getAllWells);
  const currentWellId = useSelector(getCurrentWellId);
  const { data, reset } = useSpotfireOutputStorage();

  React.useEffect(() => {
    reset();
  }, []);

  React.useEffect(() => {
    const wellIds = spotfireIdsArray.parse(data.outSfFilteredWells);
    dispatch(setSpotfireFilteredWells(wellIds));
  }, [dispatch, data.outSfFilteredWells]);

  React.useEffect(() => {
    const wellIds = spotfireIdsArray.parse(data.outMarkedWells);
    dispatch(setSpotfireMarkedWells(wellIds));

    if (wellIds) {
      const wellLeases = wellIds.map(id => wells[id]?.LEASE).filter(Boolean);
      dispatch(setMarkedRows(wellLeases));
    }

    if (wellIds?.length === 1) {
      dispatch(setCurrentWellId({ id: wellIds[0], reason: 'SPOTFIRE' }));
    }
  }, [dispatch, data.outMarkedWells]);

  React.useEffect(() => {
    dispatch(setSpotfireViewStartDate(data.outViewStartDate));
  }, [dispatch, data.outViewStartDate]);

  React.useEffect(() => {
    dispatch(setSpotfireViewEndDate(data.outViewEndDate));
  }, [dispatch, data.outViewEndDate]);

  React.useEffect(() => {
    let panelData: SpotfireOutEventPanel;
    try {
      panelData = JSON.parse(data.outEventPanel);
    } catch {
      return;
    }

    if (!panelData.wellId || !panelData.eventId || !panelData.eventType) {
      return;
    }

    if (currentWellId !== panelData.wellId) {
      dispatch(
        setCurrentWellId({
          id: panelData.wellId,
          reason: 'SPOTFIRE',
        }),
      );
    }

    if (panelData.eventType === 'variance') {
      dispatch(
        openVarianceConversation({
          id: panelData.eventId,
          wellId: panelData.wellId,
        }),
      );
      return;
    }

    if (panelData.eventType === 'capacity') {
      dispatch(
        openCapacityConversation({
          id: panelData.eventId,
          wellId: panelData.wellId,
        }),
      );
      return;
    }

    if (panelData.eventType === 'ribbon') {
      dispatch(
        openRibbonConversation({
          id: panelData.eventId,
        }),
      );
      return;
    }
  }, [dispatch, data.outEventPanel]);

  React.useEffect(() => {
    if (data.outViewStartDate && data.outViewEndDate) {
      const min = spotfireDate.parse(data.outViewStartDate);
      const max = spotfireDate.parse(data.outViewEndDate);
      if (min && max) dispatch(changeExtremeDates({ min, max }));
    }
  }, [dispatch, data.outViewStartDate, data.outViewEndDate]);

  return null;
};

export default React.memo(DashboardsOutputPersistor);
