import * as React from 'react';
import styled from 'styled-components';
import { SortDirection } from 'react-virtualized';

import { GroupsMap } from 'modules/well/models/well';
import { SortIndicator, SortIndicatorABS } from 'components/Icons';
import { MaxColumnWidth } from '../models/drilldownTable';
import getGroupTitleByGroupKey from '../utils/getGroupTitleByGroupKey';

interface WellHeaderRowProps {
  className: string;
  firstColDataKey: string;
  fourthColDataKey: string;
  groups: GroupsMap;
  maxColumnWidth: MaxColumnWidth;
  onClick: (data: { sortBy: string; sortDirection: string }) => void;
  secondColDataKey: string;
  secondColTitle: string;
  sortBy: string;
  sortDirection: string;
  style: { [prop: string]: string };
  thirdColDataKey: string;
  thirdColTitle: string;
  fifthColDataKey: string;
  varianceSortingParams: { abs: boolean; direction: string };
  variancePercentSortingParams: { abs: boolean; direction: string };
  markAllRows: () => void;
  displayClearButton: boolean;
  showVariancePercentage: boolean;
}

const WellHeaderRow = ({
  className,
  firstColDataKey,
  fourthColDataKey,
  groups,
  maxColumnWidth,
  onClick,
  secondColDataKey,
  secondColTitle,
  sortBy,
  sortDirection,
  style,
  thirdColDataKey,
  thirdColTitle,
  fifthColDataKey,
  varianceSortingParams,
  variancePercentSortingParams,
  markAllRows,
  displayClearButton,
  showVariancePercentage,
}: WellHeaderRowProps) => (
  <div className={className} role="row" style={style}>
    <WellHeaderRow.FirstCol
      onClick={() =>
        onClick({
          sortBy: firstColDataKey,
          sortDirection:
            sortDirection === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC,
        })
      }
    >
      <WellHeaderRow.FirstColName>
        {getGroupTitleByGroupKey(firstColDataKey, groups)}

        {sortBy === firstColDataKey && (
          <SortIndicator sortDirection={sortDirection} />
        )}
      </WellHeaderRow.FirstColName>

      {displayClearButton && (
        <WellHeaderRow.ClearButton
          onClick={e => {
            e.stopPropagation();
            markAllRows();
          }}
        >
          Clear Selection
        </WellHeaderRow.ClearButton>
      )}
    </WellHeaderRow.FirstCol>

    <WellHeaderRow.Col
      withIcon={sortBy === secondColDataKey}
      onClick={() =>
        onClick({
          sortBy: secondColDataKey,
          sortDirection:
            sortDirection === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC,
        })
      }
      width={maxColumnWidth.secondColWidth}
    >
      <WellHeaderRow.ColTextContainer>
        {secondColTitle}
        {sortBy === secondColDataKey && (
          <WellHeaderRow.SortIndicatorWrapper>
            <SortIndicator sortDirection={sortDirection} />
          </WellHeaderRow.SortIndicatorWrapper>
        )}
      </WellHeaderRow.ColTextContainer>
    </WellHeaderRow.Col>

    <WellHeaderRow.Col
      withIcon={sortBy === thirdColDataKey}
      onClick={() =>
        onClick({
          sortBy: thirdColDataKey,
          sortDirection:
            sortDirection === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC,
        })
      }
      width={maxColumnWidth.thirdColWidth}
    >
      <WellHeaderRow.ColTextContainer>
        {thirdColTitle}
        {sortBy === thirdColDataKey && (
          <WellHeaderRow.SortIndicatorWrapper>
            <SortIndicator sortDirection={sortDirection} />
          </WellHeaderRow.SortIndicatorWrapper>
        )}
      </WellHeaderRow.ColTextContainer>
    </WellHeaderRow.Col>

    <WellHeaderRow.FourthCol
      withIcon={sortBy === fourthColDataKey}
      onClick={() => onClick({ sortBy: fourthColDataKey, sortDirection: '' })}
      width={maxColumnWidth.fourthColWidth}
    >
      <WellHeaderRow.ColTextContainer>
        Var.
        {sortBy === fourthColDataKey && !varianceSortingParams.abs && (
          <WellHeaderRow.SortIndicatorWrapper>
            <SortIndicator
              sortDirection={varianceSortingParams.direction}
              style={{ marginLeft: '1px' }}
            />
          </WellHeaderRow.SortIndicatorWrapper>
        )}
        {sortBy === fourthColDataKey && varianceSortingParams.abs && (
          <WellHeaderRow.SortIndicatorWrapper>
            <SortIndicatorABS
              sortDirection={varianceSortingParams.direction}
              style={{ marginLeft: '1px' }}
            />
          </WellHeaderRow.SortIndicatorWrapper>
        )}
      </WellHeaderRow.ColTextContainer>
    </WellHeaderRow.FourthCol>
    {showVariancePercentage && (
      <WellHeaderRow.FourthCol
        withIcon={sortBy === fifthColDataKey}
        onClick={() => onClick({ sortBy: fifthColDataKey, sortDirection: '' })}
        width={maxColumnWidth.fifthColWidth}
      >
        <WellHeaderRow.ColTextContainer percentage>
          {'% Var.'}
          {sortBy === fifthColDataKey && !variancePercentSortingParams.abs && (
            <WellHeaderRow.SortIndicatorWrapper>
              <SortIndicator
                sortDirection={variancePercentSortingParams.direction}
                style={{ marginLeft: '1px' }}
              />
            </WellHeaderRow.SortIndicatorWrapper>
          )}
          {sortBy === fifthColDataKey && variancePercentSortingParams.abs && (
            <WellHeaderRow.SortIndicatorWrapper>
              <SortIndicatorABS
                sortDirection={variancePercentSortingParams.direction}
                style={{ marginLeft: '1px' }}
              />
            </WellHeaderRow.SortIndicatorWrapper>
          )}
        </WellHeaderRow.ColTextContainer>
      </WellHeaderRow.FourthCol>
    )}
  </div>
);

WellHeaderRow.CommonCol = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-transform: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-left: 1px solid #c1c1c1;
  padding-right: 2px;
  cursor: pointer;
  line-height: 1.3em;
`;

WellHeaderRow.Col = styled(WellHeaderRow.CommonCol)`
  flex: 0 0 ${(props: Record<string, any>) => props.width + 1}px;
  box-sizing: content-box;
  position: relative;
`;

WellHeaderRow.FourthCol = styled(WellHeaderRow.CommonCol)`
  flex: 0 0 ${(props: Record<string, any>) => props.width + 1}px;
  box-sizing: content-box;
  position: relative;
`;

WellHeaderRow.FirstCol = styled(WellHeaderRow.CommonCol)`
  padding-left: 5px;
  justify-content: space-between;
  border-left: none;
  flex: 1 1 200px;
`;

WellHeaderRow.ColTextContainer = styled.div`
  width: ${props => (props.percentage ? '45px' : '40px')};
  display: grid;
  grid-template-columns: ${props => (props.percentage ? '35px' : '30px')} 10px;
  justify-items: end;
`;

WellHeaderRow.SortIndicatorWrapper = styled.div`
  position: relative;
  left: 5px;
  height: 5px;
`;

WellHeaderRow.FirstColName = styled.div`
  display: flex;
`;

WellHeaderRow.ClearButton = styled.span`
  color: rgba(0, 0, 0, 0.8);
  margin-right: 6px;
`;

export default WellHeaderRow;
