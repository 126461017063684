import * as R from 'ramda';

const IntegrationApiUrl = 'https://api.dev.app.wiserocksoftware.com';
// const IntegrationApiUrl = 'http://localhost:4000';

export const HandsontablelicenseKey = '99665-411c9-4d970-14e2a-9c95f';

const getApiUrl = () => {
  const { host } = window.location;

  if (host.includes('localhost')) {
    return IntegrationApiUrl;
  }

  return `https://api.${host}`;
};

const appConfig = {
  apiUrl: R.defaultTo(getApiUrl(), process.env.REACT_APP_API_URL),
};

export default appConfig;
