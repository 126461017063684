import { scaleUtc } from 'd3-scale';
import { timeDay } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';
import { throttle } from 'throttle-debounce';

import {
  getMinDate,
  getMaxDate,
  getVarianceExtremeDates,
  isIdNew,
} from 'helpers';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

type FixedDateOfEvent = Date | null;

interface OverlayAddVarianceProps {
  additionModeOff: () => void;
  allocIssues: AllocIssue[];
  deleteLocalVarianceEvent: (id: string) => void;
  extremeDates: { [key: string]: Date };
  height: number;
  leftOffset: number;
  onVarianceEventUpdate: (varianceData: {
    dates: Date[];
    varianceEventId: string;
  }) => void;
  onVarianceEventCreateFirstClick: (date: Date) => void;
  onVarianceEventCreateSecondClick: (evcent: VarianceEvent) => void;
  today: Date;
  varianceEvents: VarianceEvent[];
  width: number;
}

const OverlayAddVariance = (
  {
    additionModeOff,
    allocIssues,
    deleteLocalVarianceEvent,
    extremeDates,
    height,
    leftOffset,
    onVarianceEventCreateFirstClick,
    onVarianceEventCreateSecondClick,
    onVarianceEventUpdate,
    today,
    varianceEvents,
    width,
  }: OverlayAddVarianceProps,
  ref,
) => {
  const localEvent = varianceEvents.find(
    event => isIdNew(event.id) && !event.syncing,
  );
  const xScale = scaleUtc()
    .range([0, width])
    .domain([extremeDates.min, extremeDates.max]);

  const [isCreating, setCreatingStatus] = React.useState(false);
  const [lineXPosition, setLineXPosition] = React.useState(0);
  const [createdEventIndex, setCreatedEventIndex] = React.useState(0);
  const [fixedDateOfEvent, setFixedDateOfEvent] =
    React.useState<FixedDateOfEvent>(null);

  const startCreatingLineMove = () => setCreatingStatus(true);
  const finishCreatingLineMove = React.useCallback(
    () => setCreatingStatus(false),
    [],
  );
  const moveLine = React.useCallback(
    e => setLineXPosition(e.clientX - leftOffset - Y_AXIS_WIDTH - 10),
    [leftOffset],
  );
  const hideLine = () => setLineXPosition(0);

  const initEventCreating = React.useCallback(
    (date: Date) => {
      const index =
        varianceEvents.filter(event => event.dayEnd > date).length || 0;
      setCreatedEventIndex(index);
      setFixedDateOfEvent(date);
      startCreatingLineMove();
      onVarianceEventCreateFirstClick(date);
    },
    [onVarianceEventCreateFirstClick, varianceEvents],
  );

  const updateEventOnMove = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    if (!localEvent) return;
    const extremeEventDates = getVarianceExtremeDates(
      allocIssues,
      varianceEvents,
      localEvent.id,
      today,
      'Variance Event',
    );
    const pointerPosition = Math.min(
      Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
      xScale.range()[1],
    );
    const pointerInverted = xScale.invert(pointerPosition);
    const dateOfCursor =
      fixedDateOfEvent && fixedDateOfEvent > pointerInverted
        ? timeDay.round(pointerInverted)
        : timeDay.round(timeDay.offset(pointerInverted, -1));
    const adjustedDate =
      fixedDateOfEvent && fixedDateOfEvent > dateOfCursor
        ? getMaxDate(dateOfCursor, extremeEventDates[0])
        : getMinDate(dateOfCursor, extremeEventDates[1]);

    const newEventDates = [
      adjustedDate,
      fixedDateOfEvent ? fixedDateOfEvent : adjustedDate,
    ];

    const oldDatesSum =
      localEvent.dayEnd.getTime() + localEvent.dayStart.getTime();
    const newDatesSum = newEventDates[0].getTime() + newEventDates[1].getTime();

    if (oldDatesSum === newDatesSum) return;

    onVarianceEventUpdate({
      varianceEventId: localEvent.id,
      dates: newEventDates,
    });
  };

  const finishEventCreating = React.useCallback(async () => {
    if (!fixedDateOfEvent) return;
    await finishCreatingLineMove();
    onVarianceEventCreateSecondClick(varianceEvents[createdEventIndex]);
    setFixedDateOfEvent(null);
    additionModeOff();
  }, [
    finishCreatingLineMove,
    additionModeOff,
    fixedDateOfEvent,
    onVarianceEventCreateSecondClick,
    createdEventIndex,
    varianceEvents,
  ]);

  const handleMouseClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (!isCreating) {
        const date = timeDay.floor(
          xScale.invert(e.clientX - leftOffset - Y_AXIS_WIDTH - 10),
        );
        if (
          varianceEvents.some(
            event => date >= event.dayStart && date <= event.dayEnd,
          ) ||
          date >= today
        )
          return;
        initEventCreating(date);
      } else {
        finishEventCreating();
      }
    },
    [
      today,
      leftOffset,
      varianceEvents,
      finishEventCreating,
      initEventCreating,
      isCreating,
      xScale,
    ],
  );

  const refValue = React.useRef({ isCreating, createdEventIndex, id: '' });
  React.useEffect(() => {
    refValue.current = {
      isCreating,
      createdEventIndex,
      id: localEvent ? localEvent.id : '',
    };
  }, [localEvent, isCreating, createdEventIndex]);

  const existedRanges = React.useMemo(
    () =>
      //@ts-expect-error
      allocIssues.concat(varianceEvents).map(varianceEntity => ({
        dateStart: varianceEntity.dateStart || (varianceEntity as any).dayStart,
        dateEnd: varianceEntity.dateEnd || (varianceEntity as any).dayEnd,
        id: varianceEntity.id,
      })),
    [varianceEvents, allocIssues],
  );

  const unmountHandler = React.useCallback(() => {
    if (
      refValue.current &&
      refValue.current.id &&
      refValue.current.isCreating
    ) {
      deleteLocalVarianceEvent(refValue.current.id);
    }
  }, [deleteLocalVarianceEvent]);

  React.useEffect(() => {
    return () => {
      unmountHandler();
    };
  }, [unmountHandler]);

  return (
    <OverlayAddVariance.Container
      height={height}
      className="variance-interactive interactive"
    >
      <svg
        ref={ref}
        height={height}
        onMouseMove={e => throttle(300, false, moveLine(e))}
        onMouseOver={e => moveLine(e)}
        preserveAspectRatio="none"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
      >
        <g
          onClick={e => handleMouseClick(e)}
          onMouseMove={isCreating ? e => updateEventOnMove(e) : undefined}
        >
          <rect
            fill={process.env.DEBUG ? 'rgba(150, 0, 150, 0.5)' : 'transparent'}
            height={height}
            width={width}
            x={0}
            y={0}
          />

          {existedRanges.map((range, i) => {
            const startPos = xScale(range.dateStart);
            const endPos = xScale(timeDay.offset(range.dateEnd, 1));

            return (
              <polygon
                fill={
                  process.env.DEBUG ? 'rgba(150, 0, 150, 0.7)' : 'transparent'
                }
                key={`varianceOverlay_${range.id}`}
                onMouseEnter={() => hideLine()}
                onMouseMove={e => {
                  if (!isCreating) e.stopPropagation();
                  hideLine();
                }}
                onClick={e => {
                  if (!isCreating) e.stopPropagation();
                }}
                points={`${startPos},0 ${endPos},0 ${endPos},${height} ${startPos}, ${height}`}
              />
            );
          })}

          <polygon
            fill={process.env.DEBUG ? 'rgba(50, 50, 50, 0.7)' : 'transparent'}
            onMouseEnter={() => hideLine()}
            onMouseMove={e => {
              if (!isCreating) e.stopPropagation();
              hideLine();
            }}
            onClick={e => {
              if (!isCreating) e.stopPropagation();
            }}
            points={`${xScale(today)},0 ${xScale(extremeDates.max)},0 ${xScale(
              extremeDates.max,
            )},${height} ${xScale(today)}, ${height}`}
          />

          <line
            stroke={isCreating ? 'transparent' : 'black'}
            strokeDasharray="2"
            strokeWidth="1"
            x1={0}
            x2={0}
            y1={0}
            y2={height}
            transform={`translate(${lineXPosition}, 0)`}
          />
        </g>
      </svg>
    </OverlayAddVariance.Container>
  );
};

OverlayAddVariance.Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 75;
`;

export default React.memo<OverlayAddVarianceProps & { ref: any }>(
  React.forwardRef<Element, OverlayAddVarianceProps>(OverlayAddVariance),
);
