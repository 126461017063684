import Button from 'components/Button';
import * as React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import styled from 'styled-components';

interface LoomAttachmentProps {
  attachment: { name: string; url: string };
  isEditing: boolean;
  remove: () => void;
  visualize: () => void;
  getInlineLoom: () => Promise<string>;
}

export const LoomAttachment = ({
  remove,
  attachment,
  visualize,
  isEditing,
  getInlineLoom,
}: LoomAttachmentProps) => {
  const [loomHTML, setLoomHTML] = React.useState('');

  const loadLoom = React.useCallback(async () => {
    const html = await getInlineLoom();
    setLoomHTML(html);
  }, []);

  React.useEffect(() => {
    loadLoom();
  }, [getInlineLoom]);

  return (
    <LoomAttachment.Container isLoaded={!!loomHTML}>
      {loomHTML ? (
        <div
          className="loom-video"
          dangerouslySetInnerHTML={{ __html: loomHTML }}
        ></div>
      ) : (
        <LoomAttachment.Title onClick={visualize}>
          {attachment.name || 'Video Message'}
        </LoomAttachment.Title>
      )}

      {isEditing && (
        <LoomAttachment.Button
          transparent
          width={20}
          height={20}
          onClick={remove}
        >
          <FaTrashAlt size={16} />
        </LoomAttachment.Button>
      )}
    </LoomAttachment.Container>
  );
};

LoomAttachment.Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: 1fr;
  padding: 0 12px 12px 12px;
  align-items: center;
  background: ${({ isLoaded }) => (isLoaded ? '#fff' : '#eee')};
  height: ${({ isLoaded }) => (isLoaded ? 'auto' : '28px')};
  position: relative;

  .loom-video > iframe {
    display: block;
  }
`;

LoomAttachment.Title = styled.div`
  font-weight: bold;
  cursor: pointer;
`;

LoomAttachment.Button = styled(Button)`
  padding: 0;
  position: relative;
  z-index: 5;
`;
