import gql from 'graphql-tag';
import * as R from 'ramda';

import type {
  Action,
  GraphqlQueryAction,
  GraphqlMutationAction,
} from 'store/models';
import wellRequestsAbortController from 'modules/well/utils/wellRequestsAbortController';

import type {
  CapacityChangeEvent,
  CreateCapacityChangeEventInput,
} from './models/capacityChangeEvent';

export const namespace = 'CAPACITY';

export const FETCH_WELL_CAPACITY = `${namespace}/FETCH_CAPACITY`;
export const INIT_FETCH_CAPAITY_EVENTS = `${namespace}/INIT_FETCH_CAPAITY_EVENTS`;
export const UPDATE_REMOTE_CAPACITY = `${namespace}/UPDATE_REMOTE_CAPACITY`;
export const UPDATE_REMOTE_CAPACITY_DESCRIPTION = `${namespace}/UPDATE_REMOTE_CAPACITY_DESCRIPTION`;
export const DELETE_REMOTE_CAPACITY = `${namespace}/DELETE_REMOTE_CAPACITY`;
export const CREATE_REMOTE_CAPACITY = `${namespace}/CREATE_REMOTE_CAPACITY`;
export const CREATE_REMOTE_CAPACITY_AND_SYNC_LOCALLY = `${namespace}/CREATE_REMOTE_CAPACITY_AND_SYNC_LOCALLY`;
export const CREATE_REMOTE_CAPACITY_AND_SYNC_REMOTE = `${namespace}/CREATE_REMOTE_CAPACITY_AND_SYNC_REMOTE`;

export const CREATE_CAPACITY_LOCALLY = `${namespace}/CREATE_CAPACITY_LOCALLY`;
export const INIT_UPDATE_CAPACITY_DAY_LOCALLY = `${namespace}/INIT_UPDATE_CAPACITY_DAY_LOCALLY`;
export const UPDATE_CAPACITY_DAY_LOCALLY = `${namespace}/UPDATE_CAPACITY_DAY_LOCALLY`;
export const UPDATE_CAPACITY_DESCRIPTION_LOCALLY = `${namespace}/UPDATE_CAPACITY_DESCRIPTION_LOCALLY`;
export const UPDATE_CAPACITY_DECLINE_DIRECTLY_LOCALLY = `${namespace}/UPDATE_CAPACITY_DECLINE_DIRECTLY_LOCALLY`;
export const UPDATE_CAPACITY_DECLINE_INDIRECTLY_LOCALLY = `${namespace}/UPDATE_CAPACITY_DECLINE_INDIRECTLY_LOCALLY`;
export const UPDATE_CAPACITY_RATE_LOCALLY = `${namespace}/UPDATE_CAPACITY_RATE_LOCALLY`;
export const UPDATE_CAPACITY_BFACTOR_DIRECTLY_LOCALLY = `${namespace}/UPDATE_CAPACITY_BFACTOR_DIRECTLY_LOCALLY`;
export const UPDATE_CAPACITY_BFACTOR_INDIRECTLY_LOCALLY = `${namespace}/UPDATE_CAPACITY_BFACTOR_INDIRECTLY_LOCALLY`;
export const UPDATE_CAPACITY_CATEGORY_LOCALLY = `${namespace}/UPDATE_CAPACITY_CATEGORY_LOCALLY`;
export const UPDATE_CAPACITY_EXTRA_INPUTS_LOCALLY = `${namespace}/UPDATE_CAPACITY_EXTRA_INPUTS_LOCALLY`;
export const UPDATE_CAPACITY_ANY_FIELD_LOCALLY = `${namespace}/UPDATE_CAPACITY_ANY_FIELD_LOCALLY`;
export const DELETE_CAPACITY_LOCALLY = `${namespace}/DELETE_CAPACITY_LOCALLY`;

export const POPULATE_CAPACITY_AFTER_CREATING = `${namespace}/POPULATE_CAPACITY_AFTER_CREATING`;

export const SET_CAPACITY = `${namespace}/SET_CAPACITY`;
export const POPULATE_CAPACITY_EVENTS = `${namespace}/POPULATE_CAPACITY_EVENTS`;
export const MOVE_WELL_CAPACITY_TO_BUFFER = `${namespace}/MOVE_WELL_CAPACITY_TO_BUFFER`;
export const RESTORE_WELL_CAPACITY_FROM_BUFFER = `${namespace}/RESTORE_WELL_CAPACITY_FROM_BUFFER`;
export const CLEAR_BUFFER = `${namespace}/CLEAR_BUFFER`;

const FETCH_WELL_CAPACITY_QUERY = gql`
  query ($wellId: ID!) {
    listCapacityChangeEvents(wellId: $wellId) {
      id
      wellId
      capacityChangeOptionId
      oilRateInit
      oilDeclineInitDailyNom
      oilBFactor
      gasRateInit
      gasDeclineInitDailyNom
      gasBFactor
      waterRateInit
      waterDeclineInitDailyNom
      waterBFactor
      dayInit
      extraInputsData
      oilDeclineMinimum
      gasDeclineMinimum
      waterDeclineMinimum
      notes
      isLockSlopeCapacityLine
      description
    }
  }
`;

type fetchWellCapacityAC = (payload: string) => GraphqlQueryAction;

export const fetchWellCapacity: fetchWellCapacityAC = (wellId: string) => ({
  type: FETCH_WELL_CAPACITY,
  payload: {
    key: 'listCapacityChangeEvents',
    graphql: {
      query: FETCH_WELL_CAPACITY_QUERY,
      variables: {
        wellId,
      },
    },
    options: {
      getContext: wellRequestsAbortController.getContext,
    },
  },
});

const UPDATE_CAPACITY_MUTATION = gql`
  mutation ($payload: UpdateCapacityChangeEventInput!) {
    updateCapacityChangeEvent(data: $payload) {
      capacityEvent {
        id
        wellId
        capacityChangeOptionId
        oilRateInit
        oilDeclineInitDailyNom
        oilBFactor
        gasRateInit
        gasDeclineInitDailyNom
        gasBFactor
        waterRateInit
        waterDeclineInitDailyNom
        waterBFactor
        dayInit
        extraInputsData
        oilDeclineMinimum
        gasDeclineMinimum
        waterDeclineMinimum
        notes
        isLockSlopeCapacityLine
        description
      }
      message
      code
    }
  }
`;

type updateRemoteCapacityAC = (
  payload: CapacityChangeEvent,
) => GraphqlMutationAction;

export const updateRemoteCapacity: updateRemoteCapacityAC = payload => ({
  type: UPDATE_REMOTE_CAPACITY,
  payload: {
    key: 'updateCapacityChangeEvent',
    graphql: {
      mutation: UPDATE_CAPACITY_MUTATION,
      variables: {
        payload: R.omit(['phase'], payload),
      },
    },
  },
});

const UPDATE_CAPACITY_DESCRIPTION_MUTATION = gql`
  mutation ($payload: UpdateCapacityChangeEventDescriptionInput!) {
    updateCapacityChangeEventDescription(data: $payload) {
      id
      wellId
      capacityChangeOptionId
      oilRateInit
      oilDeclineInitDailyNom
      oilBFactor
      gasRateInit
      gasDeclineInitDailyNom
      gasBFactor
      waterRateInit
      waterDeclineInitDailyNom
      waterBFactor
      dayInit
      extraInputsData
      oilDeclineMinimum
      gasDeclineMinimum
      waterDeclineMinimum
      notes
      isLockSlopeCapacityLine
      description
    }
  }
`;

type updateRemoteCapacityDescriptionAC = (payload: {
  id: string;
  description: string | null;
}) => GraphqlMutationAction;

export const updateRemoteCapacityDescription: updateRemoteCapacityDescriptionAC =
  payload => ({
    type: UPDATE_REMOTE_CAPACITY_DESCRIPTION,
    payload: {
      key: 'updateCapacityChangeEventDescription',
      graphql: {
        mutation: UPDATE_CAPACITY_DESCRIPTION_MUTATION,
        variables: {
          payload,
        },
      },
    },
  });

const DELETE_REMOTE_CAPACITY_MUTATION = gql`
  mutation ($id: ID!) {
    deleteCapacityChangeEvent(id: $id)
  }
`;

type deleteRemoteCapacityAC = (id: string) => GraphqlMutationAction;

export const deleteRemoteCapacity: deleteRemoteCapacityAC = id => ({
  type: DELETE_REMOTE_CAPACITY,
  payload: {
    key: 'deleteCapacityChangeEvent',
    graphql: {
      mutation: DELETE_REMOTE_CAPACITY_MUTATION,
      variables: { id },
    },
  },
});

const CREATE_CAPACITY_MUTATION = gql`
  mutation ($payload: CreateCapacityChangeEventInput!) {
    createCapacityChangeEvent(data: $payload) {
      capacityEvent {
        id
        wellId
        capacityChangeOptionId
        oilRateInit
        oilDeclineInitDailyNom
        oilBFactor
        gasRateInit
        gasDeclineInitDailyNom
        gasBFactor
        waterRateInit
        waterDeclineInitDailyNom
        waterBFactor
        dayInit
        extraInputsData
        oilDeclineMinimum
        gasDeclineMinimum
        waterDeclineMinimum
        notes
        isLockSlopeCapacityLine
        description
      }
      message
      code
    }
  }
`;

type createRemoteCapacityAC = (
  payload: CreateCapacityChangeEventInput,
) => GraphqlMutationAction;

export const createRemoteCapacity: createRemoteCapacityAC = payload => ({
  type: CREATE_REMOTE_CAPACITY,
  payload: {
    key: 'createCapacityChangeEvent',
    graphql: {
      mutation: CREATE_CAPACITY_MUTATION,
      variables: {
        payload: R.omit(['id'], payload),
      },
    },
  },
});

type createRemoteCapacityAndSyncAC = (payload: {
  modifiedEvent: CreateCapacityChangeEventInput;
  capacityEventId: string;
  wellId: string;
}) => Action;

export const createRemoteCapacityAndSyncSuccess: createRemoteCapacityAndSyncAC =
  payload => ({
    type: CREATE_REMOTE_CAPACITY_AND_SYNC_REMOTE,
    payload,
  });

export const createRemoteCapacityAndSync = payload => ({
  type: CREATE_REMOTE_CAPACITY_AND_SYNC_LOCALLY,
  payload,
});

type createCapacityLocallyAC = ({ date: Date, wellId: string }) => Action;

export const createCapacityLocally: createCapacityLocallyAC = payload => ({
  type: CREATE_CAPACITY_LOCALLY,
  payload,
});

type initUpdateCapacityDayLocallyAC = (payload: {
  wellId: string;
  capacityEventId: string;
  newDayInit: Date;
}) => Action;

export const initUpdateCapacityDayLocally: initUpdateCapacityDayLocallyAC =
  payload => ({
    type: INIT_UPDATE_CAPACITY_DAY_LOCALLY,
    payload,
  });

type updateCapacityDayLocallyAC = (payload: {
  wellId: string;
  capacityEventId: string;
  modifiedCapacityEvent: CapacityChangeEvent;
}) => Action;

export const updateCapacityDayLocally: updateCapacityDayLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_DAY_LOCALLY,
    payload,
  });

type updateCapacityRateLocallyAC = (payload: {
  wellId: string;
  capacityEventId: string;
  newRate: number;
  phase: string;
}) => Action;

export const updateCapacityRateLocally: updateCapacityRateLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_RATE_LOCALLY,
    payload,
  });

type updateCapacityBFactorDirectlyLocallyAC = (payload: {
  wellId: string;
  capacityEventId: string;
  newBFactor: number;
  phase: string;
}) => Action;

export const updateCapacityBFactorDirectlyLocally: updateCapacityBFactorDirectlyLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_BFACTOR_DIRECTLY_LOCALLY,
    payload,
  });

type updateCapacityBFactorIndirectlyLocallyAC = (payload: {
  wellId: string;
  phase: string;
  capacityEventId: string;
  rate: number;
  date: Date;
}) => Action;

export const updateCapacityBFactorIndirectlyLocally: updateCapacityBFactorIndirectlyLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_BFACTOR_INDIRECTLY_LOCALLY,
    payload,
  });

type updateCapacityCategoryLocallyAC = (payload: {
  wellId: string;
  capacityEventId: string;
  categoryId: string | null;
}) => Action;

export const updateCapacityCategoryLocally: updateCapacityCategoryLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_CATEGORY_LOCALLY,
    payload,
  });

type updateCapacityExtraInputsLocallyAC = (payload: {
  wellId: string;
  capacityEventId: string;
  newExtraInputsData: Record<string, any>;
}) => Action;

export const updateCapacityExtraInputsLocally: updateCapacityExtraInputsLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_EXTRA_INPUTS_LOCALLY,
    payload,
  });

type updateCapacityEventDeclineDirectlyLocallyAC = (payload: {
  wellId: string;
  capacityEventId: string;
  newDeclineInit: number;
  phase: string;
}) => Action;

export const updateCapacityEventDeclineDirectlyLocally: updateCapacityEventDeclineDirectlyLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_DECLINE_DIRECTLY_LOCALLY,
    payload,
  });

type updateCapacityEventDeclineIndirectlyLocallyAC = (payload: {
  wellId: string;
  capacityEventId: string;
  phase: string;
  date: Date;
  rate: number;
}) => Action;

export const updateCapacityEventDeclineIndirectlyLocally: updateCapacityEventDeclineIndirectlyLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_DECLINE_INDIRECTLY_LOCALLY,
    payload,
  });

type updateCapacityEventDescriptionLocallyAC = (payload: {
  wellId: string;
  id: string;
  description: string | null;
}) => Action;

export const updateCapacityEventDescriptionLocally: updateCapacityEventDescriptionLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_DESCRIPTION_LOCALLY,
    payload,
  });

type updateCapacityAnyFieldLocallyAC = (payload: {
  wellId: string;
  capacityEventId: string;
  values: Record<string, any>;
}) => Action;

export const updateCapacityAnyFieldLocally: updateCapacityAnyFieldLocallyAC =
  payload => ({
    type: UPDATE_CAPACITY_ANY_FIELD_LOCALLY,
    payload,
  });

type deleteCapacityLocallyAC = (peayload: {
  wellId: string;
  capacityEventId: string;
}) => Action;

export const deleteCapacityLocally: deleteCapacityLocallyAC = payload => ({
  type: DELETE_CAPACITY_LOCALLY,
  payload,
});

type populateCapacityAfterCreatingAC = ({
  wellId: string,
  newCapacityEvent: CapacityChangeEvent,
}) => Action;

export const populateCapacityAfterCreating: populateCapacityAfterCreatingAC =
  payload => ({
    type: POPULATE_CAPACITY_AFTER_CREATING,
    payload,
  });

type moveWellCapacityToBufferAC = (wellId: string) => Action;

export const moveWellCapacityToBuffer: moveWellCapacityToBufferAC =
  payload => ({
    type: MOVE_WELL_CAPACITY_TO_BUFFER,
    payload,
  });

type restoreWellCapacityFromBufferAC = (payload: {
  wellId: string;
  capacityEventId: string;
}) => Action;

export const restoreWellCapacityFromBuffer: restoreWellCapacityFromBufferAC =
  payload => ({
    type: RESTORE_WELL_CAPACITY_FROM_BUFFER,
    payload,
  });

export const clearBuffer = () => ({
  type: CLEAR_BUFFER,
});

type setCapacityAC = (payload: {
  wellId: string;
  modifiedCapacityEvent: CapacityChangeEvent;
  capacityEventId: string;
}) => Action;

export const setCapacity: setCapacityAC = (payload: {
  wellId: string;
  modifiedCapacityEvent: CapacityChangeEvent;
  capacityEventId: string;
}) => ({
  type: SET_CAPACITY,
  payload,
});

type populateCapacityEventsAC = (payload: {
  wellId: string;
  events: { [key: string]: CapacityChangeEvent };
}) => Action;

export const populateCapacityEvents: populateCapacityEventsAC = (payload: {
  wellId: string;
  events: { [key: string]: CapacityChangeEvent };
}) => ({
  type: POPULATE_CAPACITY_EVENTS,
  payload,
});

export const initFetchCapacityEvents = () => ({
  type: INIT_FETCH_CAPAITY_EVENTS,
});
