import * as React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import { RibbonEvent, RibbonOption, Ribbon } from 'modules/ribbon/models';
import { RibbonTooltipData } from 'modules/ui/models/ui';

import SVGRibbonChart from './SVGRibbonChart';

interface RibbonChartProps {
  extremeDates: { min: Date; max: Date };
  isAxisDragging: boolean;
  isTrellises: boolean;
  isLast: boolean;
  onDividerHover(string): void;
  onHighlightRibbonEventDividerOff(): void;
  onRibbonDetailsPanelOpen(string): void;
  onRibbonEventDialogOpen(string): void;
  onSetRibbonTooltipData(ribbonTooltipData: RibbonTooltipData | null): void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  order: number;
  ribbon: Ribbon;
  ribbonEvents: RibbonEvent[];
  ribbonOptions: RibbonOption[];
  today: Date;
  width: number;
  xScale: any;
}
const RibbonChart = ({
  extremeDates,
  isAxisDragging,
  isTrellises,
  isLast,
  onHighlightRibbonEventDividerOff,
  onDividerHover,
  onRibbonDetailsPanelOpen,
  onRibbonEventDialogOpen,
  onSetRibbonTooltipData,
  onXAxisScaling,
  order,
  ribbon,
  ribbonEvents,
  ribbonOptions,
  today,
  width,
  xScale,
}: RibbonChartProps) => {
  const [activeEventId, setActiveEventId] = React.useState<string | null>(null);
  const ribbonType = ribbon.name;

  const onLeftPanelMouseMove = React.useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      onSetRibbonTooltipData({ clientX, clientY, ribbonType });
    },
    [onSetRibbonTooltipData, ribbonType],
  );

  const mouseMoveListener = React.useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const ribbonEvent = ribbonEvents?.find(e => activeEventId === e.id);

      if (ribbonEvent) {
        const { ribbonOptionId, dayStart, dayEnd } = ribbonEvent;
        const option = ribbonOptions[ribbonOptionId] || {};
        const optionType = option.type || 'unknown';
        onSetRibbonTooltipData({
          clientX,
          clientY,
          dayStart,
          dayEnd,
          optionType,
          ribbonType,
        });
        onDividerHover(ribbonEvent.id);
        return;
      }

      onSetRibbonTooltipData({ clientX, clientY, ribbonType });
      onDividerHover(null);
    },
    [
      onSetRibbonTooltipData,
      ribbonEvents,
      onDividerHover,
      ribbonOptions,
      ribbonType,
      activeEventId,
    ],
  ) as EventListener;

  const mouseLeaveListener = React.useCallback(() => {
    onSetRibbonTooltipData(null);
    onDividerHover(null);
    onHighlightRibbonEventDividerOff();
  }, [onSetRibbonTooltipData, onDividerHover]);

  const chartWrapperRef = React.useRef<any>(null);

  const onWheelHandler = React.useCallback(
    e => {
      onXAxisScaling(e, chartWrapperRef);
    },
    [chartWrapperRef, onXAxisScaling],
  );

  return (
    <RibbonChart.Container
      onMouseMove={mouseMoveListener}
      onMouseLeave={mouseLeaveListener}
    >
      <RibbonChart.LeftPanel
        isLast={isLast}
        isShowTopBorder={!isTrellises && order === 0}
        onClick={() => onRibbonDetailsPanelOpen(ribbon.id)}
        onMouseMove={onLeftPanelMouseMove}
      />
      <RibbonChart.ChartWrapper
        ref={chartWrapperRef}
        width={width}
        onClick={() => onRibbonDetailsPanelOpen(ribbon.id)}
        isLast={isLast}
        className="ribbon-details-interactive"
        isAxisDragging={isAxisDragging}
        onWheel={onWheelHandler}
      >
        {!R.isEmpty(ribbonOptions) && (
          <SVGRibbonChart
            extremeDates={extremeDates}
            isAxisDragging={isAxisDragging}
            onRibbonEventDialogOpen={onRibbonEventDialogOpen}
            onSetActiveEventId={setActiveEventId}
            ribbonEvents={ribbonEvents}
            ribbonOptions={ribbonOptions}
            today={today}
            xScale={xScale}
          />
        )}
      </RibbonChart.ChartWrapper>
    </RibbonChart.Container>
  );
};

RibbonChart.Container = styled.div`
  display: flex;
  cursor: pointer;
`;

RibbonChart.ChartWrapper = styled.div`
  width: ${props => props.width}px;
  border-bottom: ${({ isLast }) => (isLast ? '' : '1px solid black')};
  cursor: ${props => (props.isAxisDragging ? 'grabbing' : 'pointer')};
`;

RibbonChart.LeftPanel = styled.div`
  position: relative;
  top: ${props => (props.isShowTopBorder ? '-1px' : 0)};
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  border-bottom: ${({ isLast }) => (isLast ? '' : '1px solid black')};
  border-top: ${props => (props.isShowTopBorder ? '1px solid black' : 'none')};

  ${({ isLast }) =>
    isLast
      ? `
    ::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: #000;
    }
  `
      : ''}
`;

export default RibbonChart;
