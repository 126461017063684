import { LayoutType } from 'modules/seriesLayouts/models/seriesLayouts';

export const ItemTypes = {
  LAYOUT: 'layout',
  OPTION: 'option',
};

export type CustomAttributeValue = {
  wellCustomAttributeId: number;
  wellId: number;
  value: string;
};

export interface LayoutOption {
  id: string;
  displayName: string;
  filterName: string;
  order: number;
  isShown: boolean;
  isSelected: boolean;
}

export interface FilterLayoutOptions {
  [key: string]: LayoutOption;
}

export interface FiltersLayouts {
  id: string;
  userId: string;
  name: string;
  configuration: string;
  order: number;
}

export interface ListFiltersLayouts {
  [key: string]: FiltersLayouts;
}

export interface currentFilterLayout {
  id: string;
  configuration: string;
  type: LayoutType;
  unsaved: boolean;
}

export const LAYOUTS_PANEL_SIZES = {
  maxSelectedAreaHeight: 465,
  minAvailableAreaHeight: 92,
  minSelectedAreaHeight: 114,
  minRibbonsAreaHeight: 86,
  titleBlock: 47,
  dragArea: 26,
  optionHeight: 20,
  labelHeight: 13,
};

export const NO_FILTERS_MESSAGE =
  'No attributes are currently selected in the Layout Configuration panel';
