import { getTextColorFromBgColor } from 'helpers';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  highlightRibbonEventDivider,
  highlightRibbonEventDividerOff,
} from 'modules/ui/UIActions';
import { getRibbonOptions } from 'modules/ribbon/RibbonReducer';
import { Dialog } from '../../ui/models/dialog';
import { NormalizedEvent, TooltipOptionsType } from '../models/index';

interface EventRectProps {
  event: NormalizedEvent;
  onPanelOpen: (data: Dialog) => void;
  openTooltip: (data: TooltipOptionsType) => void;
  closeTooltip: () => void;
}

const EventRect = ({
  event,
  onPanelOpen,
  openTooltip,
  closeTooltip,
}: EventRectProps) => {
  const dispatch = useDispatch();
  const { position, color, id, type, notes, index, title, subTitle, optionId } =
    event;

  const { x1, x2, y1, y2 } = position;
  const isSmall = x2 - x1 <= 50;

  const ribbonOptions = useSelector(getRibbonOptions);
  const ribbonOptionName = React.useMemo(() => {
    if (type === 'ribbon' && optionId) {
      return ribbonOptions[optionId]?.type;
    }
  }, [optionId, ribbonOptions, type]);

  const ribbonRectHover = React.useCallback(
    id => {
      if (type === 'ribbon') dispatch(highlightRibbonEventDivider(id));
    },
    [dispatch, type],
  );

  const ribbonRectHoverOff = React.useCallback(() => {
    if (type === 'ribbon') dispatch(highlightRibbonEventDividerOff());
  }, [dispatch, type]);

  const onOpenTooltip = () => {
    openTooltip({
      type,
      x: x1,
      y: y2 + 15,
      notes,
      title: title || ribbonOptionName || '',
      subTitle: subTitle || '',
    });
  };

  const onMouseEnter = () => {
    onOpenTooltip();
    ribbonRectHover(id);
  };

  const onMouseLeave = () => {
    closeTooltip();
    ribbonRectHoverOff();
  };

  return (
    <g
      style={{ position: 'relative' }}
      cursor={type === 'ribbon' ? 'auto' : 'pointer'}
      key={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={e => {
        e.stopPropagation();
        if (type === 'ribbon') {
          onPanelOpen({ type: 'RibbonEvent', data: { id } });
          return;
        }
        if (type === 'capacity') {
          onPanelOpen({
            type: 'CapacityChangeEvent',
            data: { id, index: index || 0 },
          });
          return;
        }

        onPanelOpen({
          type: 'VarianceChangeEvent',
          data: { id, index: index || 0 },
        });
      }}
      pointerEvents="auto"
    >
      <defs>
        <clipPath id={`clip${id}`}>
          <polygon
            strokeWidth={1}
            stroke="black"
            fill={color || '#000000'}
            points={`${x1},${y1 - 2} ${x2},${y1 - 2} ${x2},${y2} ${x1},${y2}`}
          />
        </clipPath>
      </defs>
      <g clipPath={`url(#clip${id})`}>
        <polygon
          strokeWidth={1}
          stroke="black"
          fill={color || '#000000'}
          points={`${x1},${y1 - 2} ${x2},${y1 - 2} ${x2},${y2} ${x1},${y2}`}
        />
        <circle
          fill="white"
          opacity="50%"
          r="7"
          cx={isSmall ? x1 + 7 : (x2 + x1) / 2 - 10}
          cy={(y2 + y1 - 2) / 2}
        />
        <text
          textAnchor="middle"
          fontFamily="Lato"
          fontWeight="700"
          fontSize="10"
          x={isSmall ? x1 + 7 : (x2 + x1) / 2 - 10}
          y={(y2 + y1 + 5) / 2}
          fill={getTextColorFromBgColor(color || '#000000')}
        >
          {type[0].toUpperCase()}
        </text>

        <svg
          style={{ opacity: '0.75' }}
          viewBox="0 0 32 32"
          width="10px"
          height="10px"
          fill="white"
          x={isSmall ? x1 + 16 : (x2 + x1) / 2}
          y={(y2 + y1) / 2 - 7}
        >
          <path
            opacity="75%"
            d={`M16 2c8.837 0 16 5.82 16 13s-7.163 13-16 13c-0.849 0-1.682-0.054-2.495-0.158-3.437 3.437-7.539 4.053-11.505 4.144v-0.841c2.142-1.049 4-2.961 4-5.145 
            0-0.305-0.024-0.604-0.068-0.897-3.619-2.383-5.932-6.024-5.932-10.103 0-7.18 7.163-13 16-13z`}
          />
        </svg>
        <text
          opacity="75%"
          textAnchor="middle"
          fontFamily="Lato"
          fontWeight="700"
          fontSize="12"
          x={
            isSmall
              ? x1 + 30
              : (x2 + x1) / 2 +
                16 +
                String(Object.keys(notes).length).length * 2
          }
          y={(y2 + y1 + 5) / 2}
          fill={getTextColorFromBgColor(color || '#000000')}
        >
          {Object.keys(notes).length}
        </text>
      </g>
    </g>
  );
};

export default EventRect;
