import type { Action } from 'store/models';
import {
  AvailableDataSeries,
  ChartOption,
  ChartOptionsGroup,
  DataSeriesGroupToDisplay,
  DraggableOption,
  ListChartOptions,
} from './models';

export const namespace = 'CHART_OPTIONS';
export const ADD_AVAILABLE_OPTIONS = `${namespace}/ADD_AVAILABLE_OPTIONS`;
export const REMOVE_AVAILABLE_OPTION = `${namespace}/REMOVE_AVAILABLE_OPTION`;
export const ADD_OPTION_TO_GROUP = `${namespace}/ADD_OPTION_TO_GROUP`;
export const ADD_OPTIONS_GROUP = `${namespace}/ADD_OPTIONS_GROUP`;
export const ADD_OPTIONS = `${namespace}/ADD_OPTIONS`;
export const ADD_SENSOR_OPTIONS = `${namespace}/ADD_SENSOR_OPTIONS`;
export const MOVE_OPTION_GROUP = `${namespace}/MOVE_OPTION_GROUP`;
export const MOVE_OPTION_IN_GROUP = `${namespace}/MOVE_OPTION_IN_GROUP`;
export const MOVE_OPTION = `${namespace}/MOVE_OPTION`;
export const REMOVE_GROUP_THROUGH_TOP_CONTROLS = `${namespace}/REMOVE_GROUP_THROUGH_TOP_CONTROLS`;
export const REMOVE_OPTION_FROM_GROUP = `${namespace}/REMOVE_OPTION_FROM_GROUP`;
export const REMOVE_OPTIONS_GROUP = `${namespace}/REMOVE_OPTIONS_GROUP`;
export const SWITCH_CHART_TYPE = `${namespace}/SWITCH_CHART_TYPE`;
export const SWITCH_OPTION_VISIBLE = `${namespace}/SWITCH_OPTION_VISIBLE`;
export const SWITCH_OPTIONS_VISIBLE = `${namespace}/SWITCH_OPTIONS_VISIBLE`;
export const SET_AVAILABLE_CORESERIES = `${namespace}/SET_AVAILABLE_CORESERIES`;
export const SET_AVAILABLE_OPTIONS = `${namespace}/SET_AVAILABLE_OPTIONS`;
export const SET_NEW_GROUP_ORDER = `${namespace}/SET_NEW_GROUP_ORDER`;
export const SET_NEW_OPTIONS_ORDER = `${namespace}/SET_NEW_OPTIONS_ORDER`;
export const SET_CUSTOM_COLOR = `${namespace}/SET_CUSTOM_COLOR`;
export const SET_INITIAL_STATE = `${namespace}/SET_INITIAL_STATE`;
export const RESET_DATA_SERIES = `${namespace}/RESET_DATA_SERIES`;
export const RESET_SENSOR_SERIES = `${namespace}/RESET_SENSOR_SERIES`;
export const RESET_CALCULATED_SERIES = `${namespace}/RESET_CALCULATED_SERIES`;
export const SWITCH_CAV_OPTION_IN_TOP_CONTROLS = `${namespace}/SWITCH_CAV_OPTION_IN_TOP_CONTROLS`;
export const SET_CORESERIES_FROM_ROUTE = `${namespace}/SET_CORESERIES_FROM_ROUTE`;
export const SET_CORESERIES = `${namespace}/SET_CORESERIES`;
export const SET_DATA_SERIES_FROM_ROUTE = `${namespace}/SET_DATA_SERIES_FROM_ROUTE`;

//CAV options
export const SET_ALL_CORESERIES_OPTION = `${namespace}/SET_ALL_CORESERIES_OPTION`;
export const SET_CORESERIES_OPTION = `${namespace}/SET_CORESERIES_OPTION`;

export const SWITCH_SERIES_MOD = `${namespace}/SWITCH_SERIES_MOD`;

type setAllCoreSeriesOptionAC = (payload: { isShow: boolean }) => Action;

export const setAllCoreSeriesOption: setAllCoreSeriesOptionAC = payload => ({
  type: SET_ALL_CORESERIES_OPTION,
  payload,
});

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const resetDataSeries = () => ({
  type: RESET_DATA_SERIES,
});

export const resetSensorSeries = () => ({
  type: RESET_SENSOR_SERIES,
});

export const resetCalculatedSeries = () => ({
  type: RESET_CALCULATED_SERIES,
});

type setCoreSeriesOptionAC = (payload: {
  optionId: string;
  isShow: boolean;
}) => Action;

export const setCoreSeriesOption: setCoreSeriesOptionAC = payload => ({
  type: SET_CORESERIES_OPTION,
  payload,
});
type setAvailableCoreSeriesAC = (payload: {
  optionId: string;
  isAvailable: boolean;
}) => Action;

export const setAvailableCoreSeries: setAvailableCoreSeriesAC = payload => ({
  type: SET_AVAILABLE_CORESERIES,
  payload,
});

type setCoreSeriesFromRouteAC = (payload: { series: string[] }) => Action;

export const setCoreSeriesFromRoute: setCoreSeriesFromRouteAC = payload => ({
  type: SET_CORESERIES_FROM_ROUTE,
  payload,
});

type setCoreSeriesAC = (payload: { series: ChartOption[] }) => Action;

export const setCoreSeries: setCoreSeriesAC = payload => ({
  type: SET_CORESERIES,
  payload,
});

type setDataSeriesFromRouteAC = (payload: {
  dataSeries: DataSeriesGroupToDisplay;
  sensorSeries: DataSeriesGroupToDisplay;
}) => Action;

export const setDataSeriesFromRoute: setDataSeriesFromRouteAC = payload => ({
  type: SET_DATA_SERIES_FROM_ROUTE,
  payload,
});

type switchCavOptionInTopContolsAC = (payload: {
  optionId: string;
  checked: boolean;
}) => Action;

export const switchCavOptionInTopContols: switchCavOptionInTopContolsAC =
  payload => ({
    type: SWITCH_CAV_OPTION_IN_TOP_CONTROLS,
    payload,
  });

type removeGroupThroughTopControlsAC = (payload: { groupId: string }) => Action;

export const removeGroupThroughTopControls: removeGroupThroughTopControlsAC =
  payload => ({
    type: REMOVE_GROUP_THROUGH_TOP_CONTROLS,
    payload,
  });

type setNewOptionsOrderAC = (payload: {
  groupId: string;
  options: string[];
}) => Action;

export const setNewOptionsOrder: setNewOptionsOrderAC = payload => ({
  type: SET_NEW_OPTIONS_ORDER,
  payload,
});

type setNewGroupOrderAC = (payload: ChartOptionsGroup[]) => Action;

export const setNewGroupOrder: setNewGroupOrderAC = payload => ({
  type: SET_NEW_GROUP_ORDER,
  payload,
});

type switchChartTypeAC = (payload: {
  optionId: string;
  type: string;
}) => Action;

export const switchChartType: switchChartTypeAC = payload => ({
  type: SWITCH_CHART_TYPE,
  payload,
});

type switchAC = (payload: { optionId: string }) => Action;

export const switchOptionVisible: switchAC = payload => ({
  type: SWITCH_OPTION_VISIBLE,
  payload,
});

type switchOptionsAC = (payload: {
  options: string[];
  show: boolean;
}) => Action;

export const switchOptionsVisible: switchOptionsAC = payload => ({
  type: SWITCH_OPTIONS_VISIBLE,
  payload,
});

type addOptionsGroupAC = (payload: {
  newGroupIndex?: number;
  optionId: string;
}) => Action;

export const addOptionsGroup: addOptionsGroupAC = payload => ({
  type: ADD_OPTIONS_GROUP,
  payload,
});

type addOptionToGroupAC = (payload: {
  groupId: string;
  optionId: string;
  newOptionIndex: number;
}) => Action;

export const addOptionToGroup: addOptionToGroupAC = payload => ({
  type: ADD_OPTION_TO_GROUP,
  payload,
});

type removeAvailableOptionAC = (payload: { optionId: string }) => Action;

export const removeAvailableOption: removeAvailableOptionAC = payload => ({
  type: REMOVE_AVAILABLE_OPTION,
  payload,
});

type addOptionsAC = (payload: ListChartOptions) => Action;

export const addOptions: addOptionsAC = payload => ({
  type: ADD_OPTIONS,
  payload,
});

export const addSensorOptions = () => ({
  type: ADD_SENSOR_OPTIONS,
});

type addAvailableOptionsAC = (payload: AvailableDataSeries[]) => Action;

export const addAvailableOptions: addAvailableOptionsAC = payload => ({
  type: ADD_AVAILABLE_OPTIONS,
  payload,
});

export const setAvailableOptions: addAvailableOptionsAC = payload => ({
  type: SET_AVAILABLE_OPTIONS,
  payload,
});

type moveOptionAC = (payload: ChartOptionsGroup) => Action;

export const moveOption: moveOptionAC = payload => ({
  type: MOVE_OPTION,
  payload,
});
type removeOptionFromGroupAC = (payload: DraggableOption) => Action;

export const removeOptionFromGroup: removeOptionFromGroupAC = payload => ({
  type: REMOVE_OPTION_FROM_GROUP,
  payload,
});

type removeOptionsGroupAC = (payload: ChartOptionsGroup) => Action;

export const removeOptionsGroup: removeOptionsGroupAC = payload => ({
  type: REMOVE_OPTIONS_GROUP,
  payload,
});

type setCustomColorAC = (payload: { color: string; id: string }) => Action;

export const setCustomColor: setCustomColorAC = payload => ({
  type: SET_CUSTOM_COLOR,
  payload,
});
