import { timeDay } from 'd3-time';
import useNotesParams from 'modules/wellChart/hooks/useNotesParams';
import * as React from 'react';
import styled from 'styled-components';

interface EventVisibleDividerProps {
  color: string;
  eventDates: { dayStart: Date; dayEnd: Date };
  hideLine?: string;
  isEditable?: boolean;
  xScale: any;
}

const EventVisibleDivider = ({
  color,
  eventDates,
  hideLine,
  isEditable,
  xScale,
}: EventVisibleDividerProps) => {
  const { height: notesSectionHeight, isNotesSectionShown } =
    useNotesParams(xScale);
  return (
    <EventVisibleDivider.Container
      notesSectionHeight={isNotesSectionShown ? notesSectionHeight : 0}
    >
      {hideLine !== 'left' && (
        <EventVisibleDivider.Line
          position={xScale(eventDates.dayStart)}
          color={color}
          lineWidth={isEditable ? '3' : '1'}
          isEditable={isEditable}
        />
      )}
      {hideLine !== 'right' && (
        <EventVisibleDivider.Line
          position={xScale(timeDay.offset(eventDates.dayEnd, 1))}
          lineWidth={isEditable ? '3' : '1'}
          color={color}
        />
      )}
    </EventVisibleDivider.Container>
  );
};

EventVisibleDivider.Container = styled.div`
  position: absolute;
  z-index: 74;
  top: ${props => props.notesSectionHeight}px;
  height: calc(100% - ${props => props.notesSectionHeight}px);
  width: 100%;
  left: 0;
  pointer-events: none;
  overflow: hidden;
`;

EventVisibleDivider.Line = styled.div`
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: transparent;
  border-style: solid;
  border-color: ${(props: Record<string, any>) => props.color};
  border-left-width: ${(props: Record<string, any>) => props.lineWidth}px;
  transform: ${(props: Record<string, any>) =>
    `translateX(${props.position - props.lineWidth / 2}px)`};
  pointer-events: none;
`;

export default React.memo<EventVisibleDividerProps>(EventVisibleDivider);
