import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLayoutConfiguration } from '../DrilldownTableReducer';

export function useGetActiveLayout() {
  const layoutConfiguration: ReturnType<typeof getCurrentLayoutConfiguration> =
    useSelector(getCurrentLayoutConfiguration);
  const isCustomDrilldown = React.useMemo(
    () => layoutConfiguration === 'custom',
    [layoutConfiguration],
  );

  return isCustomDrilldown;
}
