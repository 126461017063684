import { timeDay, utcDay } from 'd3-time';
import * as React from 'react';

import { getMaxDate, getMinDate, isIdNew } from 'helpers';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

interface EventDividerProps {
  varianceEvent: VarianceEvent;
  eventIndex: number;
  extremeEventDates: Date[];
  finishDrag: () => void;
  height: number;
  isAxisDragging: boolean;
  isEditable: boolean;
  isDragging: boolean;
  leftOffset: number;
  onDividerHover: (eventId: number) => void;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  onVarianceEventUpdate: (varianceData: {
    dates: Date[];
    varianceEventId: string;
  }) => void;
  onHighlightEventDividerOff: () => void;
  startDrag: () => void;
  varianceDialog: { show: boolean; index: number };
  xScale: any;
}

const EventDivider = ({
  varianceEvent,
  eventIndex,
  extremeEventDates,
  finishDrag,
  height,
  isAxisDragging,
  isEditable,
  isDragging,
  leftOffset,
  onVarianceDialogOpen,
  onDividerHover,
  onVarianceEventUpdate,
  onHighlightEventDividerOff,
  startDrag,
  varianceDialog,
  xScale,
}: EventDividerProps) => {
  const beginEventStartDrag = () => {
    if (isEditable) {
      if (!varianceEvent.id) return;
      document.addEventListener('mousemove', proceedEventStartDrag);
      document.addEventListener('mouseup', finishEventStartDrag);
      startDrag();
      onVarianceDialogOpen(eventIndex, varianceEvent.id);
    } else {
      onVarianceDialogOpen(eventIndex, varianceEvent.id);
    }
  };

  const proceedEventStartDrag = React.useCallback(
    (e: MouseEvent) => {
      const fixedDateOfEvent = varianceEvent.dayEnd;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = timeDay.round(xScale.invert(pointerPosition));
      const adjustedDate =
        fixedDateOfEvent && fixedDateOfEvent > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeEventDates[0])
          : getMinDate(dateOfCursor, extremeEventDates[1]);
      const newDay = timeDay.round(adjustedDate);
      onVarianceEventUpdate({
        varianceEventId: varianceEvent.id,
        dates: [newDay, varianceEvent.dayEnd],
      });
    },
    [
      varianceEvent.dayEnd,
      varianceEvent.id,
      extremeEventDates,
      onVarianceEventUpdate,
      xScale,
      leftOffset,
    ],
  );

  const finishEventStartDrag = React.useCallback(
    (e: MouseEvent) => {
      document.removeEventListener('mousemove', proceedEventStartDrag);
      document.removeEventListener('mouseup', finishEventStartDrag);
      finishDrag();
      const fixedDateOfEvent = varianceEvent.dayEnd;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = timeDay.round(xScale.invert(pointerPosition));
      const adjustedDate =
        fixedDateOfEvent && fixedDateOfEvent > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeEventDates[0])
          : getMinDate(dateOfCursor, extremeEventDates[1]);
      const newDay = timeDay.round(adjustedDate);
      onVarianceEventUpdate({
        varianceEventId: varianceEvent.id,
        dates: [newDay, varianceEvent.dayEnd],
      });
    },
    [
      varianceEvent.dayEnd,
      varianceEvent.id,
      extremeEventDates,
      finishDrag,
      onVarianceEventUpdate,
      proceedEventStartDrag,
      xScale,
      leftOffset,
    ],
  );

  const beginEventEndDrag = () => {
    if (isEditable) {
      if (!varianceEvent.id || isIdNew(varianceEvent.id)) return;
      document.addEventListener('mousemove', proceedEventEndDrag);
      document.addEventListener('mouseup', finishEventEndDrag);
      startDrag();
      onVarianceDialogOpen(eventIndex, varianceEvent.id);
    } else {
      onVarianceDialogOpen(eventIndex, varianceEvent.id);
    }
  };

  const proceedEventEndDrag = React.useCallback(
    (e: MouseEvent) => {
      const fixedDateOfEvent = varianceEvent.dayStart;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = timeDay.round(
        utcDay.offset(xScale.invert(pointerPosition), -1),
      );
      const adjustedDate =
        fixedDateOfEvent && fixedDateOfEvent > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeEventDates[0])
          : getMinDate(dateOfCursor, extremeEventDates[1]);
      const newDay = timeDay.round(adjustedDate);
      onVarianceEventUpdate({
        varianceEventId: varianceEvent.id,
        dates: [newDay, fixedDateOfEvent],
      });
    },
    [
      extremeEventDates,
      varianceEvent,
      onVarianceEventUpdate,
      xScale,
      leftOffset,
    ],
  );

  const finishEventEndDrag = React.useCallback(
    (e: MouseEvent) => {
      document.removeEventListener('mousemove', proceedEventEndDrag);
      document.removeEventListener('mouseup', finishEventEndDrag);
      finishDrag();
      const fixedDateOfEvent = varianceEvent.dayStart;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = timeDay.round(
        utcDay.offset(xScale.invert(pointerPosition), -1),
      );
      const adjustedDate =
        fixedDateOfEvent && fixedDateOfEvent > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeEventDates[0])
          : getMinDate(dateOfCursor, extremeEventDates[1]);
      const newDay = timeDay.round(adjustedDate);

      onVarianceEventUpdate({
        varianceEventId: varianceEvent.id,
        dates: [varianceEvent.dayStart, newDay],
      });
    },
    [
      varianceEvent.dayStart,
      varianceEvent.id,
      extremeEventDates,
      finishDrag,
      onVarianceEventUpdate,
      proceedEventEndDrag,
      xScale,
      leftOffset,
    ],
  );

  React.useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', proceedEventEndDrag);
      document.removeEventListener('mousemove', proceedEventStartDrag);
      document.removeEventListener('mouseup', finishEventEndDrag);
      document.removeEventListener('mouseup', finishEventStartDrag);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <g>
      <line
        className={`dragLine variance-interactive interactive ${
          varianceDialog.show ? '' : 'panInteraction'
        }`}
        cursor={
          isAxisDragging ? 'grabbing' : isEditable ? 'ew-resize' : 'pointer'
        }
        onMouseUp={() => {
          !isDragging && beginEventStartDrag();
        }}
        onMouseDown={() => {
          varianceDialog.show && beginEventStartDrag();
        }}
        onMouseMove={() => (isDragging ? null : onDividerHover(eventIndex))}
        onMouseLeave={() => (isDragging ? null : onHighlightEventDividerOff())}
        stroke="transparent"
        strokeWidth="15"
        x1={xScale(varianceEvent.dayStart)}
        x2={xScale(varianceEvent.dayStart)}
        y1={0}
        y2={height}
        vectorEffect="non-scaling-stroke"
      />
      <line
        className={`dragLine variance-interactive interactive ${
          varianceDialog.show ? '' : 'panInteraction'
        }`}
        cursor={
          isAxisDragging ? 'grabbing' : isEditable ? 'ew-resize' : 'pointer'
        }
        onMouseUp={() => {
          !isDragging && beginEventStartDrag();
        }}
        onMouseDown={() => {
          varianceDialog.show && beginEventEndDrag();
        }}
        onMouseMove={e => (isDragging ? null : onDividerHover(eventIndex))}
        onMouseLeave={() => (isDragging ? null : onHighlightEventDividerOff())}
        stroke="transparent"
        strokeWidth="15"
        x1={xScale(timeDay.offset(varianceEvent.dayEnd, 1))}
        x2={xScale(timeDay.offset(varianceEvent.dayEnd, 1))}
        y1={0}
        y2={height}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  );
};

export default React.memo<EventDividerProps>(EventDivider);
