import * as R from 'ramda';

export const buildAuthData = (data: any): any => ({
  ...R.compose(
    R.pick([
      'id',
      'email',
      'firstName',
      'lastName',
      'isAdmin',
      'isAllowedEditCapChanges',
      'isAllowedEditVarEvents',
      'isAllowedEditAllocIssues',
      'isAllowedEditRibbonEvents',
      'inactive',
      'mustChangePassword',
      'userUuid',
      'isUserManager',
      'acceptedPolicies',
    ]),
  )(data.user),
  isFreshLogin: true,
  isAuthenticated: true,
  token: data.token,
  loginTime: new Date().toISOString(),
  tokenCreationTime: new Date(),
});
