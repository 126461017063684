import { timeDay, utcDay } from 'd3-time';
import { format as d3Format } from 'd3-format';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import { Y_AXIS_WIDTH, VARIANCE_UNITS } from 'modules/chart/models/chart';
import {
  createNormalYScale,
  getCoreSeriesTitle,
  getCoreSeriesDetailedTitle,
  createVarianceYScale,
} from 'modules/chart/utils';
import RegionOfInterest from 'modules/chart/components/RegionOfInterest';
import YAxis from 'modules/chart/components/YAxis';
import SeriesPill from 'modules/chart/components/SeriesPill';
import { ForecastData } from 'modules/externalForecast/models';
import type { ProductionPoint } from 'modules/production/models/production';
import type {
  IdIndexDialog,
  IdDialog,
  TooltipData,
  TrellisTooltipData,
} from 'modules/ui/models/ui';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import usePrevious from 'hooks/usePrevious';

import { getTextMarksIndices } from '../utils';
import SVGWellTrellis from './SVGWellTrellis';
import SVGIndicatorTrellisInteraction from './SVGIndicatorTrellisInteraction';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';
import useGetCavTolltipData from 'hooks/useGetCavTolltipData';
import { normalizeProductionLost } from '../utils/normalizeProductionLost';
import { getAppConfig } from '../../appConfig/AppConfigReducer';
import { getVarianceEvent } from 'modules/varianceEvent/VarianceEventReducer';
import {
  getNewlyCreatedVarianceEvent,
  getYAxisHovered,
} from 'modules/ui/UIReducer';
import { getLostProductionExplanation } from 'modules/appSettings/utils';
import { useSelector } from 'store/models';
import { sliceArrayByExtremeDates } from 'helpers/transformers/sliceArrayByExtremeDates';
import { approximatedSearchByDate } from '../../../helpers/approximatedSearchByDate';
import getMaxDataPoints from '../utils/getMaxDataPoints';
import { SERIES_PILL_HEIGHT, SERIES_PILL_OFFSET } from '../../series/models';
import useThrottledCallback from 'hooks/useThrottledCallback';
import useForecast from 'modules/externalForecast/hooks/useForecast';

type CapacityData = { date: Date; capacity: number }[];

interface DivIndicatorChartProps {
  seriesId: string;

  allWellAllocIssues: AllocIssue[];
  allocIssueDialog: IdIndexDialog;
  allocIssuesVisibility: boolean;
  capacityDialog: {
    show: boolean;
    index: number;
  };
  capacityChangeEvents: CapacityChangeEvent[];
  capacityData: Array<{ date: Date; capacity: number }[]>;
  changeMinRoiDate: (minDate: Date) => void;
  changeMaxRoiDate: (minDate: Date) => void;
  localRoiDates: { maxDate: Date; minDate: Date };
  endRoiDragging: () => void;
  chartWasDragging: boolean;
  currentWellId: string;
  displaysRegionOfInterest: boolean;
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
    compareOption: string;
  };
  eventColors: { [key: string]: string };
  extremeDates: { min: Date; max: Date };
  finishDrag: () => void;
  forecastData: ForecastData;
  format: string;
  hasCapacityChanges: boolean;
  height: number;
  highlightedAllocIssue: IdDialog;
  highlightedAllocIssueDivider: IdDialog;
  highlightedEvent: { show: boolean; index: number };
  highlightedEventDivider: { show: boolean; index: number };
  isAxisDragging: boolean;
  isDragging: boolean;
  isDisplayingForecast: boolean;
  isPossibleEditAlloc: boolean;
  production: ProductionPoint[];
  leftOffset: number;
  varianceEvents: VarianceEvent[];
  varianceDialog: { show: boolean; index: number };
  startDrag: () => void;
  onCapacityDividerHover: (eventId: number) => void;
  onCapacityDialogClose: () => void;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  onEventDividerHover: (eventId: number) => void;
  onDayInitChange: (dayData: {
    capacityEventId: string;
    newDayInit: Date;
  }) => void;
  onVarianceEventUpdate: (varianceData: {
    dates: Date[];
    varianceEventId: string;
  }) => void;
  onHighlightCapacityDividerOff: () => void;
  onHighlightEventDividerOff: () => void;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  permissions: { [permission: string]: boolean };
  position: number;
  regionOfInterest?: boolean;
  showBarHoverEffect: boolean;
  xScale: any;
  width: number;
  isLast: boolean;
  onAllocIssueDialogOpen: ({ index: number, id: string }) => void;
  onAllocIssueDividerHover: (eventId: string) => void;
  onAllocIssueUpdate: ({ updatedIssue: AllocIssue, data: Object }) => void;
  onHighlightAllocIssueDividerOff: () => void;
  onLocalAllocIssueUpdate: ({ updatedIssue: AllocIssue, data: Object }) => void;
  today: Date;
  tooltipData: TooltipData | null;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  varianceEventSum: (
    | { oil: number; gas: number; water: number; boe: number }
    | undefined
  )[];
}

const DivIndicatorChart = ({
  seriesId,

  allWellAllocIssues,
  allocIssueDialog,
  allocIssuesVisibility,
  capacityChangeEvents,
  capacityDialog,
  capacityData,
  changeMinRoiDate,
  changeMaxRoiDate,
  localRoiDates,
  endRoiDragging,
  chartWasDragging,
  currentWellId,
  drilldownTableParams,
  displaysRegionOfInterest,
  varianceEvents,
  eventColors,
  extremeDates,
  finishDrag,
  forecastData,
  format,
  hasCapacityChanges,
  height,
  highlightedAllocIssue,
  highlightedAllocIssueDivider,
  highlightedEvent,
  highlightedEventDivider,
  isAxisDragging,
  isDisplayingForecast,
  isDragging,
  isPossibleEditAlloc,
  leftOffset,
  regionOfInterest,
  showBarHoverEffect,
  startDrag,
  onCapacityDividerHover,
  onEventDividerHover,
  onCapacityDialogClose,
  onCapacityDialogOpen,
  onDayInitChange,
  onVarianceEventUpdate,
  onXAxisScaling,
  onHighlightCapacityDividerOff,
  onHighlightEventDividerOff,
  onSetTooltipData,
  onVarianceDialogOpen,
  position,
  production,
  tooltipData,
  varianceDialog,
  width,
  xScale,
  isLast,
  onAllocIssueDialogOpen,
  onAllocIssueDividerHover,
  onAllocIssueUpdate,
  onHighlightAllocIssueDividerOff,
  onLocalAllocIssueUpdate,
  permissions,
  today,
  varianceEventSum,
}: DivIndicatorChartProps) => {
  const productionKey = seriesId.toLowerCase();
  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const appConfig = useSelector(getAppConfig);
  const explainTitle = React.useMemo(
    () =>
      getLostProductionExplanation({
        gasPrice: appConfig.gasPriceAssumption,
        oilPrice: appConfig.oilPriceAssumption,
      }),
    [appConfig.gasPriceAssumption, appConfig.oilPriceAssumption],
  );
  const staticCreatedVarianceEvent = useSelector(getNewlyCreatedVarianceEvent);
  const createdVarianceEvent: any = useSelector(state =>
    getVarianceEvent(state, {
      wellId: currentWellId,
      varianceEventId: staticCreatedVarianceEvent?.id ?? '',
    }),
  );

  const yAxisHovered = useSelector(getYAxisHovered);

  const createdVarianceEventIndex = React.useMemo(
    () =>
      staticCreatedVarianceEvent
        ? varianceEvents.findIndex(v => v.id === staticCreatedVarianceEvent.id)
        : -1,
    [staticCreatedVarianceEvent],
  );

  const capacityVarianceData = React.useMemo(() => {
    return capacityData.map(dataset => {
      if (dataset[0] && dataset[0].date > today) return [];
      const numberForSLice = approximatedSearchByDate(
        dataset,
        utcDay.offset(today, 1),
        (date, e) =>
          utcDay.floor(date).getTime() - utcDay.floor(e.date).getTime(),
      );
      return dataset.slice(0, numberForSLice);
    });
  }, [capacityData, today]);

  const [displayMinDataPoint, setDisplayMinDataPoint] = React.useState(0);
  const [displayMaxDataPoint, setDisplayMaxDataPoint] = React.useState(0);
  const firstSeriesPillElem = React.useRef<HTMLElement | null>(null);
  const [isAdjusted, setIsAdjusted] = React.useState(false);
  const [yScaleOffset, setYScaleOffset] = React.useState(0);
  const [maxDataPoint, setMaxDataPoint] = React.useState(0);

  const yScale = React.useMemo(() => {
    return createVarianceYScale(
      height,
      displayMinDataPoint,
      displayMaxDataPoint,
      yScaleOffset,
    );
  }, [displayMaxDataPoint, displayMinDataPoint, height, yScaleOffset]);

  const { isVisible: isForecastVisible } = useForecast();

  const calculateMaxDataPoint = useThrottledCallback(
    (
      production,
      productionKey,
      capacityData,
      extremeDates,
      forecastData,
      trellisTitle,
      firstPillDate,
    ) => {
      const { global: globalMaxDataPoint, pill: pillMaxDataPoint } =
        getMaxDataPoints(
          production,
          productionKey,
          capacityData,
          extremeDates,
          forecastData,
          trellisTitle,
          firstPillDate,
        );
      setMaxDataPoint(globalMaxDataPoint);

      const tempYScale = createNormalYScale(height, globalMaxDataPoint, 0);

      if (!globalMaxDataPoint || isAdjusted) return;

      const pillMaxDataPointOffset = tempYScale(pillMaxDataPoint);

      if (pillMaxDataPointOffset < SERIES_PILL_HEIGHT) {
        setYScaleOffset(
          SERIES_PILL_HEIGHT - pillMaxDataPointOffset + SERIES_PILL_OFFSET,
        );
      }
      if (pillMaxDataPointOffset > SERIES_PILL_HEIGHT + SERIES_PILL_OFFSET) {
        setYScaleOffset(0);
      }
    },
    [isAdjusted],
    50,
  );

  React.useEffect(() => {
    calculateMaxDataPoint(
      production,
      productionKey,
      capacityData,
      extremeDates,
      isForecastVisible ? forecastData : [],
      seriesId.toLocaleLowerCase(),
      xScale.invert(firstSeriesPillElem?.current?.offsetLeft),
    );
  }, [
    production,
    productionKey,
    capacityData,
    extremeDates,
    forecastData,
    seriesId,
    setMaxDataPoint,
    isForecastVisible,
  ]);

  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    setDisplayMinDataPoint(0);
    setDisplayMaxDataPoint(maxDataPoint);
  }, [setIsAdjusted, setDisplayMaxDataPoint, maxDataPoint]);

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );
  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );

  const sortCapacityData = React.useMemo(() => {
    const filtredCapacity = capacityData.map((data, i) => {
      const clon = R.clone(data);
      clon.pop();
      return i === 0 ? data : clon;
    });
    return R.flatten(filtredCapacity).sort((a, b) =>
      utcDay.count(b.date, a.date),
    ) as { date: Date; capacity: number }[];
  }, [capacityData]);
  const eventCapacityData = React.useMemo(
    () =>
      varianceEvents.reduce((acc, event) => {
        const selectedPoints = sliceArrayByExtremeDates(
          sortCapacityData,
          timeDay.round(event.dayStart),
          timeDay.offset(timeDay.round(event.dayEnd), 1),
          (date, e) => date.getTime() - e.date.getTime(),
        );
        acc.push(selectedPoints.reverse());
        return acc;
      }, [] as CapacityData[]),

    [varianceEvents, sortCapacityData],
  );

  const prevMaxDataPoint = usePrevious(maxDataPoint);
  const prevWellId = usePrevious(currentWellId);

  React.useEffect(() => {
    if (prevMaxDataPoint !== maxDataPoint || !displayMaxDataPoint) {
      setDisplayMaxDataPoint(maxDataPoint);
    }
  }, [prevMaxDataPoint, maxDataPoint, displayMaxDataPoint]);

  React.useEffect(() => {
    if (prevWellId && prevWellId !== currentWellId) resetMax();
  }, [currentWellId, prevWellId, resetMax]);

  const textMarks = getTextMarksIndices(
    varianceEvents,
    staticCreatedVarianceEvent
      ? { index: createdVarianceEventIndex, show: true }
      : highlightedEvent,
    highlightedEventDivider,
    staticCreatedVarianceEvent ? createdVarianceEvent : varianceDialog,
  );
  const secondaryTooltipData = useGetCavTolltipData({
    capacityVarianceData: capacityData,
    forecastData,
    production,
    tooltipData,
    trellisTitle: seriesId,
  });

  return (
    <>
      <DivIndicatorChart.Container height={height} isLast={isLast}>
        <DivIndicatorChart.SVGWrapper className="trellis-chart-wrapper">
          <SVGWellTrellis
            allocIssues={allWellAllocIssues}
            allocIssueDialog={allocIssueDialog}
            allocIssuesVisibility={allocIssuesVisibility}
            capacity={capacityChangeEvents}
            capacityDialog={capacityDialog}
            capacityLineData={capacityData}
            capacityVarianceData={capacityVarianceData}
            eventCapacityData={eventCapacityData}
            eventColors={eventColors}
            forecastData={forecastData}
            height={height}
            highlightedAllocIssue={highlightedAllocIssue}
            highlightedAllocIssueDivider={highlightedAllocIssueDivider}
            isAxisDragging={isAxisDragging}
            isDisplayingForecast={isDisplayingForecast}
            isPossibleEditAlloc={isPossibleEditAlloc}
            maxDataPoint={displayMaxDataPoint}
            onSetTooltipData={onSetTooltipData}
            production={production}
            showBarHoverEffect={showBarHoverEffect}
            tooltipData={tooltipData}
            trellisTitle={seriesId}
            varianceEvents={varianceEvents}
            xScale={xScale}
            yAxisLinePos={yAxisLinePos}
            yScale={yScale}
            format={format}
          />
          {!(isAxisDragging && chartWasDragging) && !!displayMaxDataPoint && (
            <SVGIndicatorTrellisInteraction
              allocIssuesVisibility={allocIssuesVisibility}
              allocIssueDialog={allocIssueDialog}
              allWellAllocIssues={allWellAllocIssues}
              capacityDialog={capacityDialog}
              eventCapacityData={eventCapacityData}
              height={height}
              isAxisDragging={isAxisDragging}
              width={width}
              capacity={capacityChangeEvents}
              varianceEvents={varianceEvents}
              leftOffset={leftOffset}
              trellisTitle={seriesId}
              xScale={xScale}
              startDrag={startDrag}
              finishDrag={finishDrag}
              isDragging={isDragging}
              onAllocIssueDialogOpen={onAllocIssueDialogOpen}
              onAllocIssueDividerHover={onAllocIssueDividerHover}
              onAllocIssueUpdate={onAllocIssueUpdate}
              onHighlightAllocIssueDividerOff={onHighlightAllocIssueDividerOff}
              onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
              onCapacityDialogOpen={onCapacityDialogOpen}
              onDayInitChange={onDayInitChange}
              onCapacityDividerHover={onCapacityDividerHover}
              onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
              onVarianceDialogOpen={onVarianceDialogOpen}
              onHighlightEventDividerOff={onHighlightEventDividerOff}
              onVarianceEventUpdate={onVarianceEventUpdate}
              onEventDividerHover={onEventDividerHover}
              production={production}
              permissions={permissions}
              today={today}
              varianceDialog={varianceDialog}
              yScale={yScale}
              onXAxisScaling={onXAxisScaling}
            />
          )}
          {displaysRegionOfInterest &&
            regionOfInterest &&
            drilldownTableParams &&
            drilldownTableParams.phase === seriesId &&
            changeMinRoiDate &&
            changeMaxRoiDate && (
              <RegionOfInterest
                changeMinRoiDate={changeMinRoiDate}
                changeMaxRoiDate={changeMaxRoiDate}
                endRoiDragging={endRoiDragging}
                hasCapacityChanges={hasCapacityChanges}
                leftOffset={leftOffset}
                minDate={localRoiDates.minDate}
                maxDate={localRoiDates.maxDate}
                xScale={xScale}
                startDrag={startDrag}
                finishDrag={finishDrag}
                height={height}
                width={width}
                position={height * position}
                onCapacityDialogClose={onCapacityDialogClose}
              />
            )}
        </DivIndicatorChart.SVGWrapper>
        <DivIndicatorChart.YAxisContainer>
          <YAxis
            format={format}
            height={height}
            hideLine={hideLine}
            isDragging={false}
            maxDataPoint={displayMaxDataPoint}
            isXAxisDragging={false}
            setDisplayMaxDataPoint={setDisplayMaxDataPoint}
            setIsAdjusted={setIsAdjusted}
            showLine={showLine}
            resetMax={resetMax}
            isAdjusted={isAdjusted}
            yScale={yScale}
          />
        </DivIndicatorChart.YAxisContainer>
        {seriesId === 'BOE' && (
          <>
            {Object.keys(textMarks).map(eventIndex => {
              const index = parseInt(eventIndex);
              const currentOilSum = R.path([index, 'oil'], varianceEventSum);
              const currentGasSum = R.path([index, 'gas'], varianceEventSum);
              const totalSum = Math.round(
                appConfig.oilPriceAssumption * currentOilSum +
                  appConfig.gasPriceAssumption * currentGasSum,
              );
              const productionLost = normalizeProductionLost(totalSum);

              return (
                <DivIndicatorChart.VarianceTextMarkWrapper
                  key={seriesId + eventIndex + textMarks[eventIndex]}
                  width={
                    Math.min(
                      xScale(utcDay.offset(varianceEvents[index].dayEnd, 1)),
                      xScale.range()[1],
                    ) -
                    Math.max(
                      xScale(varianceEvents[index].dayStart),
                      xScale.range()[0],
                    )
                  }
                  color="#484848"
                  position={Math.max(
                    xScale(varianceEvents[index].dayStart),
                    xScale.range()[0],
                  )}
                  topOffset={staticCreatedVarianceEvent ? 0 : 10}
                  className="variance-interactive interactive"
                >
                  <DivIndicatorChart.Tooltip title={explainTitle}>
                    {d3Format('-,.0f')(
                      R.pathOr(0, [index, 'boe'], varianceEventSum),
                    )}{' '}
                    {VARIANCE_UNITS[seriesId]}
                    <br />
                    {productionLost}
                  </DivIndicatorChart.Tooltip>
                </DivIndicatorChart.VarianceTextMarkWrapper>
              );
            })}
          </>
        )}
        <SeriesPill
          ref={firstSeriesPillElem}
          text={getCoreSeriesTitle(seriesId)}
          tooltipTitle={getCoreSeriesDetailedTitle(
            seriesId,
            drilldownTableParams.grossNet,
          )}
          tooltipDescription="Core Series"
        />
        {!yAxisHovered &&
          tooltipData &&
          !isAxisDragging &&
          (tooltipData.trellisTooltipData ||
            tooltipData.ribbonTooltipData ||
            tooltipData.dataSeriesTooltipData) &&
          tooltipData.trellisTooltipData?.trellis !== seriesId && (
            <SecondaryInformationTooltip
              containerHeight={height}
              isDisplayingForecast={isDisplayingForecast}
              leftOffset={leftOffset}
              tooltipData={tooltipData}
              trellisTitle={seriesId}
              secondaryCavTooltipData={secondaryTooltipData}
              yScale={yScale}
              today={today}
            />
          )}
      </DivIndicatorChart.Container>
    </>
  );
};

DivIndicatorChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

DivIndicatorChart.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

DivIndicatorChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

DivIndicatorChart.VarianceTextMarkWrapper = styled.div`
  width: ${(props: Record<string, any>) => props.width}px;
  color: ${(props: Record<string, any>) => props.color};
  font-size: 15px;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  padding-top: ${({ topOffset }) => topOffset}px;
  align-items: flex-start;
  position: absolute;
  left: ${(props: Record<string, any>) => props.position}px;
  text-align: center;
  user-select: none;
`;

DivIndicatorChart.Tooltip = styled.div`
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  opacity: 0.89;
  border-radius: 2px;
  pointer-events: auto;
  background-color: #ffffff;
  padding: 2px;
  margin-top: 4px;
  line-height: 16px;
  font-weight: 700;
  z-index: 400;
  white-space: nowrap;
`;

DivIndicatorChart.InteractionSVGWrapper = styled(DivIndicatorChart.SVGWrapper)`
  height: calc(${(props: Record<string, any>) => props.height}px - 1px);
  z-index: 74;
`;

export default DivIndicatorChart;
