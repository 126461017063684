import type { User } from 'modules/user/models/user';
import { MENTION_REG_EXP } from 'modules/mentionNotification/models/mentionNotification';
const LFCR_REG_EXP = /(\n|\r|\n\r)/g;

export const highlightMentions = (
  noteText: string,
  usersByEmailName: { [name: string]: User },
  color = '#35995b',
) => {
  const emailNames = Object.entries(usersByEmailName)
    .filter(([_, u]) => u.taggable)
    .map(([name]) => name);
  const withMentions = noteText.replace(MENTION_REG_EXP, match => {
    const emailName = match.substring(1);
    if (emailNames.includes(emailName)) {
      return `<span style="color:${color}">${match}</span>`;
    }

    return match;
  });
  const withBreaks = withMentions.replace(LFCR_REG_EXP, '<br/>');

  return { __html: withBreaks };
};
