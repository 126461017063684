import * as R from 'ramda';
import { all, put, takeLatest, select, take } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { SET_CURRENT_WELL_ID } from 'modules/ui/UIActions';
import { Action } from 'store/models';

import {
  addEventNote,
  createEventNoteRemotely,
  CREATE_EVENT_NOTE_LOCALLY,
  fetchEventNotes,
  FETCH_EVENT_NOTES,
  populateEventNotes,
  removeEventNoteRemotely,
  REMOVE_EVENT_NOTE_LOCALLY,
  UPDATE_EVENT_NOTE_LOCALLY,
  updateEventNoteRemotely,
  CREATE_EVENT_NOTE_REMOTELY,
  REMOVE_EVENT_NOTE_ATTACHMENT,
} from './EventNotesActions';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import { normalizeEventNotes } from './utils';
import { getId } from 'modules/auth/AuthReducer';
import { isIdNew } from 'helpers';
import { deleteAttachment } from 'modules/noteAttachment/NoteAttachmentActions';
import { getUrlValues } from '../router/RouterReducer';
import { stringifySearchParams } from '../router/utils/router';
import { INIT_FETCH_SECONDARY_CHART_DATA } from 'modules/chart/ChartActions';
import { getCurrentWellId } from 'modules/ui/UIReducer';

function* fetchEventNotesForCurrentWellSaga(): Generator<any, any, any> {
  yield take(INIT_FETCH_SECONDARY_CHART_DATA);

  if (
    window.location.pathname.includes('/dashboard') ||
    window.location.pathname.includes('/filter') ||
    window.location.pathname.includes('/share')
  ) {
    const currentWellId = yield select(getCurrentWellId);
    yield put(fetchEventNotes(currentWellId));
  }
}
function* normalizeEventNotesSaga(action: Action): Generator<any, any, any> {
  const payload = getGraphqlPayload(action);
  const { wellId } = getGraphqlPrevActionVariables(action);
  const normalizedEventNotes = normalizeEventNotes(payload, wellId);

  yield put(populateEventNotes({ wellId, notes: normalizedEventNotes || {} }));
}

function* createEventNoteSaga(action: Action): Generator<any, any, any> {
  const newUrlData = yield select(getUrlValues);
  const {
    payload: { wellId, eventId, noteText, eventType, attachments = [] },
  } = action;
  const userId = yield select(getId);
  const newId = uuid();
  const noteTimestamp = new Date();
  const newEvent = {
    eventType,
    eventId,
    userId,
    userOverwrite: null,
    noteTimestamp,
    noteText,
    isEdited: false,
  };

  yield put(
    addEventNote({
      wellId,
      note: { ...newEvent, id: newId, attachments: R.values(attachments) },
    }),
  );
  const attachmentIds = R.values(attachments).map(attachment => attachment.id);

  yield put(
    createEventNoteRemotely({
      ...newEvent,
      attachmentIds,
      id: newId,
      urlSearch: stringifySearchParams(newUrlData.search),
    }),
  );
}

function* removeEventNoteSaga(action: Action): Generator<any, any, any> {
  const {
    payload: { id },
  } = action;
  if (isIdNew(id)) {
    const successAction = yield take([
      `${CREATE_EVENT_NOTE_REMOTELY}_SUCCESS`,
      `${CREATE_EVENT_NOTE_REMOTELY}_FAIL`,
    ]);
    const { id: newId } = getGraphqlPayload(successAction);
    yield put(removeEventNoteRemotely({ id: newId }));
  }
  yield put(removeEventNoteRemotely({ id }));
}

function* removeEventNoteAttachmeentSaga(
  action: Action,
): Generator<any, any, any> {
  const {
    payload: { fileId },
  } = action;

  if (!isIdNew(fileId)) {
    yield put(deleteAttachment(fileId));
  }
}

function* updateEventNoteSaga(action: Action): Generator<any, any, any> {
  const {
    payload: { note },
  } = action;
  const attachmentIds = R.values(note.attachments).map(
    attachment => attachment.id,
  );
  const noteWithAttachmentIds = R.compose(
    R.dissoc('attachments'),
    R.assoc('attachmentIds', attachmentIds),
  )(note);

  yield put(updateEventNoteRemotely(noteWithAttachmentIds));
}

function* eventNotesSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(SET_CURRENT_WELL_ID, fetchEventNotesForCurrentWellSaga),
    takeLatest(`${FETCH_EVENT_NOTES}_SUCCESS`, normalizeEventNotesSaga),
    takeLatest(CREATE_EVENT_NOTE_LOCALLY, createEventNoteSaga),
    takeLatest(REMOVE_EVENT_NOTE_LOCALLY, removeEventNoteSaga),
    takeLatest(REMOVE_EVENT_NOTE_ATTACHMENT, removeEventNoteAttachmeentSaga),
    takeLatest(UPDATE_EVENT_NOTE_LOCALLY, updateEventNoteSaga),
  ]);
}

export default eventNotesSagas;
