import { filterActions } from 'redux-ignore';
import { Action, Selector } from 'store/models';
import * as R from 'ramda';
import {
  namespace,
  REQUEST_HELP,
  SET_SHOW_SUCCESS_MESSAGE,
} from './RequestHelpActions';
import { createSelector } from 'reselect';
import { Reducer } from 'redux';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'requestHelp';

interface RequestHelpState {
  showSuccessMessage: boolean;
}

const initialState: RequestHelpState = {
  showSuccessMessage: false,
};

const RequestHelpReducer = (
  state: RequestHelpState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case `${REQUEST_HELP}_SUCCESS`: {
      return R.assoc('showSuccessMessage', true, state);
    }
    case SET_SHOW_SUCCESS_MESSAGE: {
      return R.assoc('showSuccessMessage', action.payload, state);
    }
    default: {
      return state;
    }
  }
};

const getState = (state: Record<string, any>) => state[STATE_KEY];

export const getShowSuccessMessage: Selector<boolean> = createSelector(
  getState,
  state => state.showSuccessMessage,
);

export default filterActions(RequestHelpReducer, action =>
  action.type.match(filterRegExp),
) as Reducer<RequestHelpState>;
