import {
  all,
  delay,
  put,
  takeLatest,
  select,
  take,
  call,
} from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import * as R from 'ramda';

import { convertFromChartDate, isIdNew } from 'helpers';
import {
  CLOSE_VARIANCE_DIALOG,
  setNewlyCreatedVarianceEvent,
  setRightPanelDialog,
  SET_WELL_REASON,
} from 'modules/ui/UIActions';
import { getDefaultVarianceCategoryId } from 'modules/category/CategoryReducer';
import { Action } from 'store/models';

import {
  CREATE_VARIANCE_EVENT_LOCALLY,
  UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY,
  UPDATE_VARIANCE_EVENT_DATES_LOCALLY,
  UPDATE_VARIANCE_EVENT_NOTES_LOCALLY,
  UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY,
  fetchVarianceEvents,
  updateRemoteVarianceEvent,
  populateVarianceEventsAfterCreating,
  deleteRemoteVarianceEvent,
  DELETE_VARIANCE_EVENT_LOCALLY,
  CREATE_REMOTE_VARIANCE_EVENT,
  FETCH_VARIANCE_EVENTS,
  UPDATE_VARIANCE_EVENT_DESCRIPTION_LOCALLY,
  updateRemoteVarianceEventDescription,
  populateVarianceEvents,
  UPDATE_REMOTE_VARIANCE_EVENT,
  SYNC_VARIANCE_EVENT_REMOTELY,
  SYNC_VARIANCE_EVENT_LOCALLY,
  syncVarianceEventRemotely,
  syncVarianceEventLocally as syncVarianceEventLocallyAC,
} from './VarianceEventActions';
import {
  getVarianceEvent,
  getWellVarianceEventsIndexedById,
  getWellVarianceEventsSortedByDate,
} from './VarianceEventReducer';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import {
  getCapacityLineDragging,
  getCurrentWellId,
  getRightPanelDialog,
} from 'modules/ui/UIReducer';
import { parseSearchParams } from 'modules/router/utils/router';
import { EventPanel } from 'modules/router/models/router';
import { OPEN_VARIANCE_CONVERSATION } from 'modules/inboxConversation/InboxConversationActions';
import { VarianceEvent } from './models/varianceEvent';
import { normalizeVarianceEvents } from './utils';
import { POPULATE_PRIMARY_CHART_DATA } from 'modules/chart/ChartActions';
import { parseVarianceEvents } from './utils/parseVarianceEvents';
import { pushNotification } from '../notification/NotificationActions';

export function* fetchWellVarianceEventsSaga(
  action: Action,
): Generator<any, any, any> {
  const reason: SET_WELL_REASON = action.payload?.reason;

  if (reason === 'URL_CHANGE') return;

  if (
    window.location.pathname.includes('/dashboard') ||
    window.location.pathname.includes('/filter') ||
    window.location.pathname.includes('/share')
  ) {
    let currentWellId = action.payload?.id;
    if (!currentWellId) currentWellId = yield select(getCurrentWellId);

    yield put(fetchVarianceEvents(currentWellId));
  }
}

function* initSyncVarianceWithApiSaga(action): Generator<any, any, any> {
  const { varianceEventId, wellId } = action.payload;

  let localVarianceEvent = yield select(getVarianceEvent, {
    wellId,
    varianceEventId,
  });

  yield delay(700);

  const unSyncedVarianceEventIsUpdating =
    isIdNew(varianceEventId) &&
    action.type === UPDATE_VARIANCE_EVENT_DATES_LOCALLY;

  if (unSyncedVarianceEventIsUpdating) {
    const action = yield take(SYNC_VARIANCE_EVENT_LOCALLY);

    const { varianceEvent: createdVarianceEvent, code } = action.payload;

    if (code === 409) return;

    localVarianceEvent = createdVarianceEvent;
  }

  yield put(
    updateRemoteVarianceEvent({
      ...localVarianceEvent,
      dayStart: convertFromChartDate(new Date(localVarianceEvent.dayStart)),
      dayEnd: convertFromChartDate(new Date(localVarianceEvent.dayEnd)),
    }),
  );
}

function* syncVarianceEventLocally(action): Generator<any, any, any> {
  const payload = getGraphqlPayload(action);
  let { varianceEvent: newVarianceEvent } = payload;
  const { code } = payload;

  const isVarianceLineDragging = yield select(getCapacityLineDragging);
  const dialogType = yield select(getRightPanelDialog);
  const isUnSyncVarianceEventDragging =
    isIdNew(dialogType.data.id) &&
    isVarianceLineDragging &&
    dialogType.type === 'VarianceChangeEvent';

  if (isUnSyncVarianceEventDragging) {
    const {
      payload: { dates },
    } = yield take(UPDATE_VARIANCE_EVENT_DATES_LOCALLY);

    newVarianceEvent = {
      ...newVarianceEvent,
      dayStart: dates[0],
      dayEnd: dates[1],
    };
  }

  yield put(
    syncVarianceEventLocallyAC({
      varianceEvent: newVarianceEvent,
      code,
    }),
  );

  yield call(updateRouteWithEventPanelAfterCreatingSaga, action);

  yield put(
    syncVarianceEventRemotely({
      varianceEventId: newVarianceEvent.id,
      wellId: newVarianceEvent.wellId,
    }),
  );
}

function* calculateNewVarianceEventSaga(action): Generator<any, any, any> {
  const { wellId, date } = action.payload;
  const oldVarianceEvents = yield select(getWellVarianceEventsIndexedById, {
    wellId,
  });
  const defaultVarianceCategory = yield select(getDefaultVarianceCategoryId);
  const newVarianceEventId = uuid();
  const newEvent: VarianceEvent = {
    id: newVarianceEventId,
    dayStart: date,
    dayEnd: date,
    wellId,
    varianceOptionId: defaultVarianceCategory,
    extraInputsData: null,
    description: null,
    notes: null,
  };
  const newVarianceEvents = {
    ...oldVarianceEvents,
    [newVarianceEventId]: newEvent,
  };

  yield put(setNewlyCreatedVarianceEvent(newEvent));
  yield put(populateVarianceEventsAfterCreating({ wellId, newVarianceEvents }));
}

function* deleteVarianceEventRemotelySaga(action): Generator<any, any, any> {
  const { varianceEventId } = action.payload;

  if (!isIdNew(varianceEventId)) {
    yield put(deleteRemoteVarianceEvent(varianceEventId));
  }
}

function* updateRouteWithEventPanelAfterCreatingSaga(
  action,
): Generator<any, any, any> {
  const wellId = yield select(getCurrentWellId);
  const events = yield select(getWellVarianceEventsSortedByDate, { wellId });
  const oldVarianceEvent = yield select(getRightPanelDialog);
  const {
    varianceEvent: newVarianceEvent,
    message,
    code,
  } = getGraphqlPayload(action);

  if (code === 409) {
    yield put(pushNotification({ message }));
    yield put(setRightPanelDialog({ type: 'Legend' }));
    return;
  }

  const eventIndex = events.findIndex(e => e.id === newVarianceEvent.id);
  if (eventIndex === -1 || !isIdNew(oldVarianceEvent?.data?.id ?? '')) return;

  yield put(
    setRightPanelDialog({
      type: 'VarianceChangeEvent',
      data: {
        id: newVarianceEvent.id,
        index: eventIndex,
      },
    }),
  );
}

function* updateRemoteVarianceEventConflictSaga(
  action,
): Generator<any, any, any> {
  const { message, code } = getGraphqlPayload(action);

  if (code === 409) {
    yield put(pushNotification({ message }));
  }
}

function* openVariancePanelFromRouteSaga(action): Generator<any, any, any> {
  const events = parseVarianceEvents(action);
  const searchParams = parseSearchParams(window.location.search);
  const currentWellId = yield select(getCurrentWellId);

  if (
    !searchParams.eventPanel ||
    searchParams.eventPanel.type !== EventPanel.variance ||
    R.isEmpty(events)
  )
    return;

  const { id } = searchParams.eventPanel;
  const index = events
    .map(e => ({ date: Date.parse(e.dayStart), id: e.id, wellId: e.wellId }))
    .sort((a, b) => b.date - a.date)
    .findIndex(e => e.id === id && e.wellId === currentWellId);

  if (index < 0) {
    const message = `Variance event with id '${id}' does not exist or has been deleted`;
    yield put(pushNotification({ message, level: 'Warning' }));
    yield put({ type: CLOSE_VARIANCE_DIALOG });
    return;
  }

  yield put(
    setRightPanelDialog({ type: 'VarianceChangeEvent', data: { index, id } }),
  );
}

function* openVarianceConversationSaga(action): Generator<any, any, any> {
  const { id, wellId } = action.payload;

  const events = yield select(getWellVarianceEventsSortedByDate, {
    wellId,
  });
  const eventIndex = events.findIndex(e => e.id === id);
  const index = Math.max(0, eventIndex);
  const data = { index, id };

  yield put(setRightPanelDialog({ type: 'VarianceChangeEvent', data }));
}

function* updateVarianceEventDescriptionRemotelySaga(
  action,
): Generator<any, any, any> {
  const { description, id } = action.payload;

  yield put(
    updateRemoteVarianceEventDescription({
      description,
      id,
    }),
  );
}

function* populateVarianceEventsSaga(action): Generator<any, any, any> {
  if (action.payload.type !== `${FETCH_VARIANCE_EVENTS}_SUCCESS`) return;
  const rawVariance = parseVarianceEvents(action.payload);
  const { wellId } = getGraphqlPrevActionVariables(action.payload);
  const normalizedVarianceEvents: {
    [id: string]: VarianceEvent;
  } = normalizeVarianceEvents(rawVariance);

  yield put(
    populateVarianceEvents({
      wellId,
      events: normalizedVarianceEvents,
    }),
  );
}

function* varianceEventSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(POPULATE_PRIMARY_CHART_DATA, populateVarianceEventsSaga),
    takeLatest(
      [
        UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY,
        UPDATE_VARIANCE_EVENT_DATES_LOCALLY,
        UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY,
        UPDATE_VARIANCE_EVENT_NOTES_LOCALLY,
        SYNC_VARIANCE_EVENT_REMOTELY,
      ],
      initSyncVarianceWithApiSaga,
    ),
    takeLatest(OPEN_VARIANCE_CONVERSATION, openVarianceConversationSaga),
    takeLatest(
      `${CREATE_REMOTE_VARIANCE_EVENT}_SUCCESS`,
      syncVarianceEventLocally,
    ),
    takeLatest(
      `${UPDATE_REMOTE_VARIANCE_EVENT}_SUCCESS`,
      updateRemoteVarianceEventConflictSaga,
    ),
    takeLatest(CREATE_VARIANCE_EVENT_LOCALLY, calculateNewVarianceEventSaga),
    takeLatest(DELETE_VARIANCE_EVENT_LOCALLY, deleteVarianceEventRemotelySaga),
    takeLatest(
      UPDATE_VARIANCE_EVENT_DESCRIPTION_LOCALLY,
      updateVarianceEventDescriptionRemotelySaga,
    ),
    takeLatest(
      `${FETCH_VARIANCE_EVENTS}_SUCCESS`,
      openVariancePanelFromRouteSaga,
    ),
  ]);
}

export default varianceEventSagas;
