import styled from 'styled-components';
import KeyboardKey from 'components/KeyboardKey';
import { MouseIcon } from 'components/Icons';

export const Shortcut = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000000;
  font-size: 12px;
`;

export const ForwardSlash = () => (
  <Shortcut>
    <KeyboardKey>/</KeyboardKey>
  </Shortcut>
);

export const KeyUpOrKeyDown = () => (
  <Shortcut>
    <KeyboardKey>↑</KeyboardKey> or <KeyboardKey>↓</KeyboardKey>
  </Shortcut>
);

export const KeyE = () => (
  <Shortcut>
    <KeyboardKey>e</KeyboardKey>
  </Shortcut>
);

export const KeyR = () => (
  <Shortcut>
    <KeyboardKey>r</KeyboardKey>
  </Shortcut>
);

export const Esc = () => (
  <Shortcut>
    <KeyboardKey>esc</KeyboardKey>
  </Shortcut>
);

export const KeyV = () => (
  <Shortcut>
    <KeyboardKey>v</KeyboardKey>
  </Shortcut>
);

export const KeyC = () => (
  <Shortcut>
    <KeyboardKey>c</KeyboardKey>
  </Shortcut>
);

export const KeyBAndDrag = () => (
  <Shortcut>
    <KeyboardKey>b</KeyboardKey> and drag <MouseIcon />
  </Shortcut>
);

export const Del = () => (
  <Shortcut>
    <KeyboardKey>del</KeyboardKey>
  </Shortcut>
);

export const Enter = () => (
  <Shortcut>
    <KeyboardKey>enter</KeyboardKey>
  </Shortcut>
);

export const KeyN = () => (
  <Shortcut>
    <KeyboardKey>n</KeyboardKey>
  </Shortcut>
);

export const KeyLeftOrKeyRight = () => (
  <Shortcut>
    <KeyboardKey>←</KeyboardKey> or <KeyboardKey>→</KeyboardKey>
  </Shortcut>
);

export const CtrlAndClick = () => (
  <Shortcut>
    <KeyboardKey>ctrl</KeyboardKey> and click <MouseIcon />
  </Shortcut>
);

export const ShiftAndClick = () => (
  <Shortcut>
    <KeyboardKey>shift</KeyboardKey> and click <MouseIcon />
  </Shortcut>
);

export const Scroll = () => (
  <Shortcut>
    Scroll <MouseIcon />
  </Shortcut>
);

export const KeyS = () => (
  <Shortcut>
    <KeyboardKey>s</KeyboardKey>
  </Shortcut>
);

export const KeyI = () => (
  <Shortcut>
    <KeyboardKey>i</KeyboardKey>
  </Shortcut>
);

export const KeyO = () => (
  <Shortcut>
    <KeyboardKey>o</KeyboardKey>
  </Shortcut>
);

export const KeyP = () => (
  <Shortcut>
    <KeyboardKey>p</KeyboardKey>
  </Shortcut>
);

export const KeyZThenNum1 = () => (
  <Shortcut>
    <KeyboardKey>z</KeyboardKey> then <KeyboardKey>1</KeyboardKey>
  </Shortcut>
);

export const KeyZThenNum2 = () => (
  <Shortcut>
    <KeyboardKey>z</KeyboardKey> then <KeyboardKey>2</KeyboardKey>
  </Shortcut>
);

export const KeyZThenNum3 = () => (
  <Shortcut>
    <KeyboardKey>z</KeyboardKey> then <KeyboardKey>3</KeyboardKey>
  </Shortcut>
);

export const KeyZThenNum4 = () => (
  <Shortcut>
    <KeyboardKey>z</KeyboardKey> then <KeyboardKey>4</KeyboardKey>
  </Shortcut>
);

export const Num1 = () => (
  <Shortcut>
    <KeyboardKey>1</KeyboardKey>
  </Shortcut>
);

export const Num2 = () => (
  <Shortcut>
    <KeyboardKey>2</KeyboardKey>
  </Shortcut>
);

export const Num3 = () => (
  <Shortcut>
    <KeyboardKey>3</KeyboardKey>
  </Shortcut>
);

export const Num4 = () => (
  <Shortcut>
    <KeyboardKey>4</KeyboardKey>
  </Shortcut>
);

export const Num5 = () => (
  <Shortcut>
    <KeyboardKey>5</KeyboardKey>
  </Shortcut>
);

export const Num6 = () => (
  <Shortcut>
    <KeyboardKey>6</KeyboardKey>
  </Shortcut>
);

export const Num7 = () => (
  <Shortcut>
    <KeyboardKey>7</KeyboardKey>
  </Shortcut>
);

export const Num8 = () => (
  <Shortcut>
    <KeyboardKey>8</KeyboardKey>
  </Shortcut>
);

export const Num9 = () => (
  <Shortcut>
    <KeyboardKey>9</KeyboardKey>
  </Shortcut>
);

export const Num0 = () => (
  <Shortcut>
    <KeyboardKey>0</KeyboardKey>
  </Shortcut>
);
