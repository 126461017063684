import * as R from 'ramda';
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import styled from 'styled-components';

import {
  ChartOptionsGroup,
  ITEM_TYPES,
  ItemTypes,
  ListChartOptions,
  OPTIONS_TYPES,
} from '../models';

interface IntergroupSpaceProps {
  nextGroup: ChartOptionsGroup | null;
  previousGroup: ChartOptionsGroup | null;
  hoveredGroupIndex: number | null;
  index: number;
  isInsideAvaliableArea: boolean;
  onCreateNewGroup: (data: {
    optionId: string;
    newGroupIndex: number;
    groupId?: string;
  }) => void;
  onSetHoveredGroupIndex: (value: number | null) => void;
  onSetHoveredOptionIndex: (index: number | null) => void;
  onSetTempGroupIndex: (value: number | null) => void;
  onStopHovering: () => void;
  options: ListChartOptions;
  tempGroupIndex: number | null;
  isInsideDragArea: boolean;
  onSetIsShowOriginOption: (value: boolean) => void;
  hoveredOptionIndex: number | null;
  onSetDraggedGroupIndex: (idnex: number | null) => void;
  onSetIsShowDraggedGroup: (value: boolean) => void;
  isShowDraggedGroup: boolean;
  onStopGroupMoving: () => void;
}

const IntergroupSpace = (props: IntergroupSpaceProps) => {
  const {
    index,
    isInsideAvaliableArea,
    tempGroupIndex,
    isInsideDragArea,
    onCreateNewGroup,
    onSetIsShowOriginOption,
    onSetHoveredGroupIndex,
    onSetTempGroupIndex,
    onSetHoveredOptionIndex,
    onSetIsShowDraggedGroup,
    nextGroup,
    previousGroup,
    options,
    hoveredGroupIndex,
    isShowDraggedGroup,
    onStopGroupMoving,
  } = props;
  const elementRef = React.useRef<HTMLElement | null>(null);

  const showFakeGroup =
    index === tempGroupIndex && !isInsideAvaliableArea && isInsideDragArea;

  const padding = showFakeGroup
    ? index === 0
      ? '14px 0 24px'
      : '24px 0'
    : index === 0
    ? '6px 0'
    : '11px 0';

  const [, connectDropTarget] = useDrop(
    {
      accept: ItemTypes.SERIES,
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
      drop: (item: any, monitor) => {
        onStopGroupMoving();
        const node = elementRef.current;
        if (!node || monitor.didDrop()) {
          return;
        }
        const hoverBoundingRect = node.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;
        const newGroupIndex = hoverClientY < hoverMiddleY ? index : 1 + index;
        if (
          !monitor.didDrop() &&
          item.props.itemType === ITEM_TYPES.available
        ) {
          onCreateNewGroup({
            optionId: item.props.option.id,
            newGroupIndex,
          });
        } else if (
          !monitor.didDrop() &&
          item.props.itemType === ITEM_TYPES.selected
        ) {
          onCreateNewGroup({
            optionId: item.props.option.id,
            newGroupIndex,
            groupId: item.props.perentGroup.groupId,
          });
        }
      },
      hover: (item: any, monitor) => {
        const node = elementRef.current;
        if (!node) return null;

        const groupBoundingRect = node.getBoundingClientRect();
        const { bottom, top } = groupBoundingRect;
        const { y } = monitor.getClientOffset() as XYCoord;
        const isAllowedAddToNextGroup =
          nextGroup !== null && nextGroup.options.length < 5;
        const isAllowedAddToPrevGroup =
          previousGroup !== null && previousGroup.options.length < 5;
        const optionId = R.path(['props', 'option', 'id'], item);
        const optionType = R.path([optionId, 'type'], options);

        // handle selected item
        if (item.props.itemType === ITEM_TYPES.selected) {
          // handle cases where a fake group is displayed
          if (bottom && top) {
            if (
              item.props.groupIndex < index &&
              5 + y < top &&
              isAllowedAddToPrevGroup
            ) {
              const newHoveredGroupIndex = index - 1;
              if (newHoveredGroupIndex === item.props.groupIndex) {
                onSetIsShowOriginOption(true);
              }

              onSetHoveredGroupIndex(newHoveredGroupIndex);
              onSetTempGroupIndex(null);
              //@ts-ignore
              onSetHoveredOptionIndex(previousGroup.options.length);
              return;
            }
            if (
              item.props.groupIndex === index &&
              5 + y < top &&
              isAllowedAddToPrevGroup
            ) {
              const newHoveredGroupIndex = index - 1;
              onSetHoveredGroupIndex(newHoveredGroupIndex);
              onSetTempGroupIndex(null);
              //@ts-ignore
              onSetHoveredOptionIndex(previousGroup.options.length);
            }
            if (y + 3 > bottom && isAllowedAddToNextGroup) {
              onSetHoveredOptionIndex(0);
              onSetHoveredGroupIndex(index);
              onSetTempGroupIndex(null);
              if (index === item.props.groupIndex) {
                onSetIsShowOriginOption(true);
              }
              return;
            }
          }
          if (index !== tempGroupIndex) {
            onSetIsShowOriginOption(false);
            onSetTempGroupIndex(index);
          }
        }

        // handle available item
        if (item.props.itemType === ITEM_TYPES.available) {
          // handle cases where avaliable option is Series
          if (bottom && top) {
            if (optionType === OPTIONS_TYPES.series) {
              if (y + 3 > bottom && isAllowedAddToNextGroup) {
                onSetHoveredOptionIndex(0);
                onSetHoveredGroupIndex(index);
                onSetTempGroupIndex(null);
                return;
              }
              if (9 + y < top && isAllowedAddToPrevGroup && index !== 0) {
                onSetTempGroupIndex(null);
                //@ts-ignore
                onSetHoveredOptionIndex(previousGroup.options.length);
                onSetHoveredGroupIndex(index - 1);
                return;
              }
            }
          }
          if (index !== tempGroupIndex) {
            onSetIsShowOriginOption(false);
            onSetTempGroupIndex(index);
          }
        }

        //handle single option group
        if (item.props.itemType === ITEM_TYPES.singleOptionGroup) {
          if (
            item.props.index >= index &&
            isAllowedAddToPrevGroup &&
            previousGroup?.groupId !== item.props.currentGroup.groupId
          ) {
            onSetHoveredGroupIndex(index - 1);
            //@ts-ignore
            onSetHoveredOptionIndex(previousGroup.options.length);
            onSetIsShowOriginOption(false);
            onSetIsShowDraggedGroup(false);
            return null;
          }
          if (
            hoveredGroupIndex !== index &&
            isAllowedAddToNextGroup &&
            item.props.index <= index
          ) {
            onSetHoveredGroupIndex(index);
            onSetHoveredOptionIndex(0);
            onSetIsShowOriginOption(false);
            onSetIsShowDraggedGroup(false);
            return null;
          }

          if (!isShowDraggedGroup) {
            onSetIsShowDraggedGroup(true);
          }
        }

        if (y > top && y < bottom && hoveredGroupIndex !== null) {
          onSetHoveredGroupIndex(null);
          onSetHoveredOptionIndex(null);
        }
      },
    },
    [props],
  );

  return connectDropTarget(
    <div>
      <IntergroupSpace.Container padding={padding}>
        <IntergroupSpace.EmptySpace
          ref={elementRef}
          show={
            index === tempGroupIndex &&
            !isInsideAvaliableArea &&
            isInsideDragArea
          }
        />
      </IntergroupSpace.Container>
    </div>,
  );
};

IntergroupSpace.Container = styled.div`
  padding: ${props => props.padding};
`;

IntergroupSpace.EmptySpace = styled.div`
  display: ${props => (props.show ? 'block' : ' none')};
  height: ${props => (props.show ? '20px' : ' 0')};
  border: 1px solid;
`;

export default IntergroupSpace;
