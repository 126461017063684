import gql from 'graphql-tag';
import wellRequestsAbortController from 'modules/well/utils/wellRequestsAbortController';

import { GraphqlQueryAction, Action } from 'store/models';
import { GroupChart } from './models/groupChart';

export const namespace = 'GROUP_CHART';
export const CLEAR_GROUP_CHART = `${namespace}/CLEAR_GROUP_CHART`;
export const CLEAR_ALL_GROUP_CHARTS = `${namespace}/CLEAR_ALL_GROUP_CHARTS`;
export const FETCH_GROUP_CHART = `${namespace}/FETCH_GROUP_CHART`;
export const FETCH_GROUP_CHART_DATE_RANGE = `${namespace}/FETCH_GROUP_CHART_DATE_RANGE`;
export const FETCH_GROUP_VARIANCE_CHART = `${namespace}/FETCH_GROUP_VARIANCE_CHART`;
export const FETCH_GROUP_VARIANCE_CHART_DATE_RANGE = `${namespace}/FETCH_GROUP_VARIANCE_CHART_DATE_RANGE`;
export const START_FETCHING_GROUP_CHART = `${namespace}/START_FETCHING_GROUP_CHART`;
export const FETCH_PARTIAL_GROUP_CHART = `${namespace}/FETCH_PARTIAL_GROUP_CHART`;
export const START_FETCHING_NET_GROUP_CHART = `${namespace}/START_FETCHING_NET_GROUP_CHART`;
export const FETCH_PARTIAL_NET_GROUP_CHART = `${namespace}/FETCH_PARTIAL_NET_GROUP_CHART`;
export const FETCH_NET_GROUP_CHART = `${namespace}/FETCH_NET_GROUP_CHART`;
export const FETCH_NET_GROUP_CHART_DATE_RANGE = `${namespace}/FETCH_NET_GROUP_CHART_DATE_RANGE`;
export const FETCH_NET_GROUP_VARIANCE_CHART = `${namespace}/FETCH_NET_GROUP_VARIANCE_CHART`;
export const FETCH_NET_GROUP_VARIANCE_CHART_DATE_RANGE = `${namespace}/FETCH_NET_GROUP_VARIANCE_CHART_DATE_RANGE`;
export const POPULATE_GROUP_CHART = `${namespace}/POPULATE_GROUP_CHART`;
export const POPULATE_GROUP_VARIANCE_CHART = `${namespace}/POPULATE_GROUP_VARIANCE_CHART`;

export const POPULATE_PARTIAL_GROUP_CHART = `${namespace}/POPULATE_PARTIAL_GROUP_CHART`;

const GET_GROUP_CHART_QUERY = gql`
  query ($payload: GroupChartInput!) {
    getGroupChart(data: $payload) {
      groupChartData
    }
  }
`;

type fetchGroupChartAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: {
    custom?: { attributeId: string; values: string[] }[];
    default?: { columnIndex: number; values: string[] }[];
  };
}) => GraphqlQueryAction;

export const fetchGroupChart: fetchGroupChartAC = payload => ({
  type: FETCH_GROUP_CHART,
  payload: {
    key: 'getGroupChart',
    graphql: {
      query: GET_GROUP_CHART_QUERY,
      variables: { payload },
    },
    options: { getContext: wellRequestsAbortController.getContext },
  },
});

const GET_GROUP_CHART_DATE_RANGE_QUERY = gql`
  query ($payload: GroupChartDateRangeInput!) {
    getGroupChartDateRange(data: $payload) {
      groupChartData
    }
  }
`;

type fetchGroupChartDateRangeAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: {
    custom?: { attributeId: string; values: string[] }[];
    default?: { columnIndex: number; values: string[] }[];
  };
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchGroupChartDateRange: fetchGroupChartDateRangeAC =
  payload => ({
    type: FETCH_GROUP_CHART_DATE_RANGE,
    payload: {
      key: 'getGroupChartDateRange',
      graphql: {
        query: GET_GROUP_CHART_DATE_RANGE_QUERY,
        variables: { payload },
      },
    },
  });

const GET_GROUP_VARIANCE_CHART_QUERY = gql`
  query ($payload: GroupChartInput!) {
    getGroupVarianceChart(data: $payload) {
      groupChartData
    }
  }
`;

export const fetchGroupVarianceChart: fetchGroupChartAC = payload => ({
  type: FETCH_GROUP_VARIANCE_CHART,
  payload: {
    key: 'getGroupVarianceChart',
    graphql: {
      query: GET_GROUP_VARIANCE_CHART_QUERY,
      variables: { payload },
    },
    options: { getContext: wellRequestsAbortController.getContext },
  },
});

const GET_GROUP_VARIANCE_CHART_DATE_RANGE_QUERY = gql`
  query ($payload: GroupChartDateRangeInput!) {
    getGroupVarianceChartDateRange(data: $payload) {
      groupChartData
    }
  }
`;

export const fetchGroupVarianceChartDateRange: fetchGroupChartDateRangeAC =
  payload => ({
    type: FETCH_GROUP_VARIANCE_CHART_DATE_RANGE,
    payload: {
      key: 'getGroupVarianceChartDateRange',
      graphql: {
        query: GET_GROUP_VARIANCE_CHART_DATE_RANGE_QUERY,
        variables: { payload },
      },
    },
  });

type startFetchingGroupChartAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: {
    custom?: { attributeId: string; values: string[] }[];
    default?: { columnIndex: number; values: string[] }[];
  };
}) => Action;

export const startFetchingGroupChart: startFetchingGroupChartAC = payload => ({
  type: START_FETCHING_GROUP_CHART,
  payload,
});

export const startFetchingNetGroupChart: startFetchingGroupChartAC =
  payload => ({
    type: START_FETCHING_NET_GROUP_CHART,
    payload,
  });

const GET_NET_GROUP_CHART_QUERY = gql`
  query ($payload: GroupChartInput!) {
    getNetGroupChart(data: $payload) {
      groupChartData
    }
  }
`;

type fetchNetGroupChartAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: {
    custom?: { attributeId: string; values: string[] }[];
    default?: { columnIndex: number; values: string[] }[];
  };
}) => GraphqlQueryAction;

export const fetchNetGroupChart: fetchNetGroupChartAC = payload => ({
  type: FETCH_NET_GROUP_CHART,
  payload: {
    key: 'getNetGroupChart',
    graphql: {
      query: GET_NET_GROUP_CHART_QUERY,
      variables: { payload },
    },
    options: { getContext: wellRequestsAbortController.getContext },
  },
});

const GET_NET_GROUP_CHART_DATE_RANGE_QUERY = gql`
  query ($payload: GroupChartDateRangeInput!) {
    getNetGroupChartDateRange(data: $payload) {
      groupChartData
    }
  }
`;

type fetchNetGroupChartDateRangeAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: {
    custom?: { attributeId: string; values: string[] }[];
    default?: { columnIndex: number; values: string[] }[];
  };
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchNetGroupChartDateRange: fetchNetGroupChartDateRangeAC =
  payload => ({
    type: FETCH_NET_GROUP_CHART_DATE_RANGE,
    payload: {
      key: 'getNetGroupChartDateRange',
      graphql: {
        query: GET_NET_GROUP_CHART_DATE_RANGE_QUERY,
        variables: { payload },
      },
    },
  });

const GET_NET_GROUP_VARIANCE_CHART_QUERY = gql`
  query ($payload: GroupChartInput!) {
    getNetGroupVarianceChart(data: $payload) {
      groupChartData
    }
  }
`;

export const fetchNetGroupVarianceChart: fetchNetGroupChartAC = payload => ({
  type: FETCH_NET_GROUP_VARIANCE_CHART,
  payload: {
    key: 'getNetGroupVarianceChart',
    graphql: {
      query: GET_NET_GROUP_VARIANCE_CHART_QUERY,
      variables: { payload },
    },
    options: { getContext: wellRequestsAbortController.getContext },
  },
});

const GET_NET_GROUP_VARIANCE_CHART_DATE_RANGE_QUERY = gql`
  query ($payload: GroupChartDateRangeInput!) {
    getNetGroupVarianceChartDateRange(data: $payload) {
      groupChartData
    }
  }
`;

export const fetchNetGroupVarianceChartDateRange: fetchNetGroupChartDateRangeAC =
  payload => ({
    type: FETCH_NET_GROUP_VARIANCE_CHART,
    payload: {
      key: 'getNetGroupVarianceChartDateRange',
      graphql: {
        query: GET_NET_GROUP_VARIANCE_CHART_DATE_RANGE_QUERY,
        variables: { payload },
      },
    },
  });

type populateGroupChartAC = (payload: {
  groupSubject: string;
  groupName: string;
  chart: GroupChart;
  isNet: boolean;
}) => Action;

export const populateGroupChart: populateGroupChartAC = payload => ({
  type: POPULATE_GROUP_CHART,
  payload,
});

export const populateGroupVarianceChart: populateGroupChartAC = payload => ({
  type: POPULATE_GROUP_VARIANCE_CHART,
  payload,
});

export const populatePartialGroupChart: populateGroupChartAC = payload => ({
  type: POPULATE_PARTIAL_GROUP_CHART,
  payload,
});

type clearGroupChartAC = (paylpad: { subject: string; item: string }) => Action;

export const clearGroupChart: clearGroupChartAC = payload => ({
  type: CLEAR_GROUP_CHART,
  payload,
});

type clearAllGroupChartsWithExceptAC = (payload?: {
  subject: string;
  item: string;
}) => Action;

export const clearAllGroupChartsWithExcept: clearAllGroupChartsWithExceptAC =
  payload => ({
    type: CLEAR_ALL_GROUP_CHARTS,
    payload,
  });
