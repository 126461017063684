import { timeMonth, timeDay, timeYear } from 'd3-time';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import * as React from 'react';
import styled from 'styled-components';

import type { AppConfig } from 'modules/appConfig/models/appConfig';
import { getDefaultProductionRange } from 'modules/production/utils';

import Button from 'components/Button';
import DateInput from 'components/DateInput';
import {
  endZoomAttributes,
  showAllAttributes,
  sixMonthAttributes,
  startZoomAttributes,
  threeMonthAttributes,
  twelveMonthAttributes,
  twentyFourMonthAttributes,
  zoomInAttributes,
  zoomOutAttributes,
  zoomPreviousAttributes,
} from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

type ZoomMenuProps = {
  changeOneExtremeDate: ({ datePosition: string, dateValue: Date }) => void;
  closeZoomMenuWithTimeout: () => void;
  config: AppConfig;
  extremeDates: { min: Date; max: Date };
  isProductionLoading: boolean;
  offset: number;
  onZoomMenuHover: () => void;
  setZoomPreset: (data: string) => void;
  undoExtremeDatesChange: () => void;
  zoomInModeOn: () => void;
  zoomHistoryLength: number;
  zoomOut: () => void;
  zoomToAll: () => void;
};

const ZoomMenu = (
  {
    changeOneExtremeDate,
    closeZoomMenuWithTimeout,
    config,
    extremeDates,
    isProductionLoading,
    offset,
    onZoomMenuHover,
    setZoomPreset,
    undoExtremeDatesChange,
    zoomInModeOn,
    zoomHistoryLength,
    zoomOut,
    zoomToAll,
  }: ZoomMenuProps,
  zoomMenuEl,
) => {
  return (
    <ZoomMenu.Wrapper
      ref={zoomMenuEl}
      offset={offset}
      onMouseLeave={closeZoomMenuWithTimeout}
      onMouseEnter={onZoomMenuHover}
      className="zoom-menu"
    >
      <Paper>
        <List style={{ outline: 'none' }}>
          <ListItem>
            <ZoomMenu.InputGroup>
              <ZoomMenu.InputLabel>Start Date:</ZoomMenu.InputLabel>
              <ButtonTooltip content={startZoomAttributes}>
                <DateInput
                  id="production-range-start"
                  name="production-range-start"
                  date={extremeDates.min}
                  min={new Date(0)}
                  max={timeDay.offset(extremeDates.max, -1)}
                  onProcess={date =>
                    changeOneExtremeDate({
                      datePosition: 'min',
                      dateValue: date,
                    })
                  }
                  disabled={isProductionLoading}
                />
              </ButtonTooltip>
            </ZoomMenu.InputGroup>
          </ListItem>

          <ListItem>
            <ZoomMenu.InputGroup>
              <ZoomMenu.InputLabel>End Date:</ZoomMenu.InputLabel>
              <ButtonTooltip content={endZoomAttributes}>
                <DateInput
                  id="production-range-end"
                  name="production-range-end"
                  date={timeDay.offset(extremeDates.max, -1)}
                  min={extremeDates.min}
                  max={timeYear.offset(new Date(), 10)}
                  onProcess={date =>
                    changeOneExtremeDate({
                      datePosition: 'max',
                      dateValue: timeDay.offset(date, 1),
                    })
                  }
                  disabled={isProductionLoading}
                />
              </ButtonTooltip>
            </ZoomMenu.InputGroup>
          </ListItem>

          <ListItem>
            <ButtonTooltip content={zoomInAttributes}>
              <Button
                width={155}
                onClick={zoomInModeOn}
                disabled={isProductionLoading}
              >
                Zoom In
              </Button>
            </ButtonTooltip>
          </ListItem>

          <ListItem>
            <ButtonTooltip content={zoomOutAttributes}>
              <Button
                width={155}
                onClick={zoomOut}
                disabled={isProductionLoading}
              >
                Zoom Out
              </Button>
            </ButtonTooltip>
          </ListItem>

          <ListItem>
            <ButtonTooltip content={zoomPreviousAttributes}>
              <Button
                width={155}
                onClick={undoExtremeDatesChange}
                disabled={isProductionLoading || zoomHistoryLength <= 1}
              >
                Zoom Previous
              </Button>
            </ButtonTooltip>
          </ListItem>
          <ListItem>
            <ButtonTooltip content={showAllAttributes}>
              <Button
                width={155}
                onClick={zoomToAll}
                disabled={isProductionLoading}
              >
                Show All
              </Button>
            </ButtonTooltip>
          </ListItem>
          <ListItem>
            <FormControl>
              <ZoomMenu.FormLabel component="legend">
                Zoom to Prior:
              </ZoomMenu.FormLabel>
              <ZoomMenu.RadioGroup
                aria-label="ZoomPreset"
                name="zoomPreset"
                value={
                  extremeDates.max.getTime() ===
                  getDefaultProductionRange(config.today).max.getTime()
                    ? timeMonth
                        .count(extremeDates.min, extremeDates.max)
                        .toString()
                    : 'none'
                }
                onChange={e => setZoomPreset(e.target.value)}
              >
                <ButtonTooltip content={threeMonthAttributes}>
                  <FormControlLabel
                    value="3"
                    control={<Radio color="primary" />}
                    label="3 Months"
                    disabled={isProductionLoading}
                  />
                </ButtonTooltip>
                <ButtonTooltip content={sixMonthAttributes}>
                  <FormControlLabel
                    value="6"
                    control={<Radio color="primary" />}
                    label="6 Months"
                    disabled={isProductionLoading}
                  />
                </ButtonTooltip>
                <ButtonTooltip content={twelveMonthAttributes}>
                  <FormControlLabel
                    value="12"
                    control={<Radio color="primary" />}
                    label="12 Months"
                    disabled={isProductionLoading}
                  />
                </ButtonTooltip>
                <ButtonTooltip content={twentyFourMonthAttributes}>
                  <FormControlLabel
                    value="24"
                    control={<Radio color="primary" />}
                    label="24 Months"
                    disabled={isProductionLoading}
                  />
                </ButtonTooltip>
              </ZoomMenu.RadioGroup>
            </FormControl>
          </ListItem>
        </List>
      </Paper>
    </ZoomMenu.Wrapper>
  );
};

ZoomMenu.Wrapper = styled.div`
  pointer-events: auto;
  position: absolute;
  top: 35px;
  right: ${props => (props.offset ? props.offset + 135 : 55)}px;
`;

ZoomMenu.InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

ZoomMenu.InputLabel = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

ZoomMenu.FormLabel = styled.legend`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 9px;
`;

ZoomMenu.RadioGroup = styled(RadioGroup)`
  width: 155px;
`;

export default React.forwardRef<HTMLElement, ZoomMenuProps>(ZoomMenu);
