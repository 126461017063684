import CircularProgress from '@material-ui/core/CircularProgress';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import { FaPaperclip } from 'react-icons/fa';

import type { User } from 'modules/user/models/user';
import { END_TEXT_MENTION_REG_EXP } from 'modules/mentionNotification/models/mentionNotification';

import Button from 'components/Button';
import { RedCloseIcon } from 'components/Icons';

import type { Attachment } from '../models/notes';
import MentionWindow from '../components/MentionWindow';
import AttachmentFormComponent from '../components/AttachmentFormComponent';
import { addNoteAttachmentAttributes } from '../../../components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';
import { GraphqlMutationAction } from 'store/models';

const ENTER_KEYCODE = 13;
const BYTES_50MB = 1024 * 1024 * 50;

interface NoteFormProps {
  onSubmit: (noteText: string, attachmentIds: string[]) => Promise<any> | void;
  noteText?: string;
  noteAttachments?: Attachment[];
  isForEdit?: boolean;
  isInProgress: boolean;
  close: () => void;
  usersByEmailName: { [emailName: string]: User };
  onAttachmentDelete: (fileName: string) => void;
  onAttachmentUpload: (file: any) => GraphqlMutationAction;
  onSaveStateChange?: (hasUnsavedData: boolean) => void;
  notificate: (notification: string) => void;
  hideNotification: () => void;
}

const NoteForm = ({
  onSubmit,
  noteText,
  isForEdit,
  isInProgress,
  close,
  noteAttachments = [],
  usersByEmailName,
  onAttachmentDelete,
  onAttachmentUpload,
  onSaveStateChange,
  notificate,
  hideNotification,
}: NoteFormProps) => {
  const textAreaEl = React.useRef<HTMLTextAreaElement>(null);
  const [text, setText] = React.useState(noteText || '');
  const [mentionInput, setMentionInput] = React.useState('');
  const [mentionAnchorEl, setMentionAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const [tempAttachments, setTempAttachments] = React.useState({});

  const attachmentIds = React.useMemo(
    () =>
      R.values(tempAttachments).reduce((acc, tempAttachment) => {
        if (tempAttachment.id) acc.push(tempAttachment.id);

        return acc;
      }, []),
    [tempAttachments],
  );
  const [attachmentIdsToDelete, setAttachmentIdsToDelete] = React.useState<
    string[]
  >([]);
  const attachmentsToDisplay = React.useMemo(
    () =>
      noteAttachments.filter(
        attachment => !attachmentIdsToDelete.includes(attachment.id),
      ),
    [noteAttachments, attachmentIdsToDelete],
  );

  const addTempAttachment = React.useCallback(
    async e => {
      const { target } = e;
      const { files } = target;
      const file = files[0];
      if (!file) return;
      if (file.size > BYTES_50MB) {
        notificate('File size is too big. Maximum file size is 50MB');
        return;
      }
      const timestamp = new Date().toISOString();
      const newAttachmentItem = {
        file,
        id: null,
        timestamp,
      };
      setTempAttachments(tempAttachments => ({
        ...tempAttachments,
        [timestamp]: newAttachmentItem,
      }));
      const uploadResult = await onAttachmentUpload(file);
      const attachmentId = uploadResult.payload.data.uploadAttachment.id;
      const newAttachmentWithId = R.assoc(
        'id',
        attachmentId,
        newAttachmentItem,
      );
      await setTempAttachments(tempAttachments => ({
        ...tempAttachments,
        [timestamp]: newAttachmentWithId,
      }));
      hideNotification();
    },
    [onAttachmentUpload, notificate, hideNotification],
  );

  const deleteTempAttachment = React.useCallback(
    timestamp => {
      const attachment = tempAttachments[timestamp];
      if (attachment.id) {
        onAttachmentDelete(attachment.id);
      }
      setTempAttachments(R.dissoc(timestamp, tempAttachments));
    },
    [tempAttachments, onAttachmentDelete],
  );

  const checkBodyIsEmpty = React.useCallback(() => {
    if (attachmentIds?.length === 0 && !text.replace(/\s+/g, '')) {
      return true;
    }

    return false;
  }, [attachmentIds, text]);

  const handleTextInput = React.useCallback(
    (e: React.MouseEvent) => {
      const { target } = e;
      if (!(target instanceof window.HTMLTextAreaElement)) {
        return;
      }
      const { value } = target;
      const positionOfCaret = target.selectionStart;
      const textBeforeCaret = value.slice(0, positionOfCaret);

      if (END_TEXT_MENTION_REG_EXP.test(textBeforeCaret)) {
        const matchedStringArray = textBeforeCaret.match(
          END_TEXT_MENTION_REG_EXP,
        );
        setMentionAnchorEl(target);
        setText(value);
        setMentionInput(R.last(matchedStringArray));
      } else {
        setText(value);
        setMentionInput('');
        setMentionAnchorEl(null);
      }
    },
    [setText],
  );

  const handleMentionWindowClose = React.useCallback(() => {
    setMentionAnchorEl(null);
    if (textAreaEl.current && textAreaEl.current.setSelectionRange) {
      const positionOfCaret = textAreaEl.current.selectionStart;
      textAreaEl.current.setSelectionRange(positionOfCaret, positionOfCaret);
    }
  }, [setMentionAnchorEl]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      const { keyCode, ctrlKey } = e;
      if (keyCode === ENTER_KEYCODE && ctrlKey) {
        if (checkBodyIsEmpty()) {
          close();
        } else {
          onSubmit(text, attachmentIds);
        }
      }
    },
    [text, onSubmit, attachmentIds, close, checkBodyIsEmpty],
  );

  const handleMentionChoose = React.useCallback(
    async (emailName: string) => {
      if (textAreaEl.current) {
        const positionOfCaret = textAreaEl.current.selectionStart;
        const textBeforeCaret = text.slice(0, positionOfCaret);
        const textAfterCaret = text.slice(positionOfCaret);
        const newTextAreaValueBeforeCaret = textBeforeCaret.replace(
          END_TEXT_MENTION_REG_EXP,
          `@${emailName} `,
        );
        const newTextAreaValue = newTextAreaValueBeforeCaret + textAfterCaret;
        await setText(newTextAreaValue);
        setMentionAnchorEl(null);
        const newCaretPos =
          newTextAreaValue.search(emailName) + emailName.length + 1;
        if (textAreaEl.current && textAreaEl.current.setSelectionRange) {
          textAreaEl.current.setSelectionRange(newCaretPos, newCaretPos);
        }
      }
    },
    [text, setText, textAreaEl],
  );

  React.useEffect(() => {
    const hasUnsaved = !R.isEmpty(tempAttachments) || (noteText ?? '') !== text;

    onSaveStateChange?.call(null, hasUnsaved);
    return () => onSaveStateChange?.call(null, false);
  }, [tempAttachments, text]);

  React.useEffect(() => {
    if (textAreaEl.current) {
      textAreaEl.current.focus();
    }
  }, []);

  const handleCloseWithAttachments = React.useCallback(() => {
    R.values(tempAttachments).forEach(attachmentItem => {
      if (attachmentItem.id) {
        onAttachmentDelete(attachmentItem.id);
      }
    });
    close();
  }, [tempAttachments, onAttachmentDelete, close]);

  const handleSubmitWithCheck = React.useCallback(() => {
    if (R.values(tempAttachments).every(tempAttachment => tempAttachment.id)) {
      if (checkBodyIsEmpty()) {
        close();
      } else {
        onSubmit(text, attachmentIds);
        attachmentIdsToDelete.forEach(attachmentId =>
          onAttachmentDelete(attachmentId),
        );
      }
    } else {
      notificate('Finishing Uploading Attachments...');
    }
  }, [
    text,
    attachmentIds,
    notificate,
    onSubmit,
    tempAttachments,
    attachmentIdsToDelete,
    onAttachmentDelete,
    close,
    checkBodyIsEmpty,
  ]);

  return (
    <NoteForm.Container>
      {isInProgress && (
        <NoteForm.ProgressOverlay>
          <CircularProgress size={24} thickness={4} />
        </NoteForm.ProgressOverlay>
      )}
      <NoteForm.Form>
        <NoteForm.Header>
          {isForEdit ? 'Edit Note' : 'Add New Note'}
          <Button transparent height={30} onClick={handleCloseWithAttachments}>
            <RedCloseIcon />
          </Button>
        </NoteForm.Header>
        <NoteForm.Textarea
          ref={textAreaEl}
          maxLength="2000"
          rows="7"
          value={text}
          onChange={handleTextInput}
          onKeyDown={e => handleKeyDown(e)}
        />
        <NoteForm.FileInput
          type="file"
          id="file-input"
          onChange={e => addTempAttachment(e)}
          onClick={e => (e.target.value = null)}
        />
        <NoteForm.Files>
          {attachmentsToDisplay &&
            !R.isEmpty(attachmentsToDisplay) &&
            attachmentsToDisplay.map((file, i) => (
              <AttachmentFormComponent
                key={file.name}
                attachment={{ file }}
                handleAttachmentDelete={() =>
                  setAttachmentIdsToDelete(prevIds => prevIds.concat(file.id))
                }
              />
            ))}
          {R.values(tempAttachments).map(attachmentItem => (
            <AttachmentFormComponent
              key={attachmentItem.file.name}
              attachment={attachmentItem}
              handleAttachmentDelete={() =>
                deleteTempAttachment(attachmentItem.timestamp)
              }
            />
          ))}
        </NoteForm.Files>
        <NoteForm.Controls>
          <ButtonTooltip content={addNoteAttachmentAttributes}>
            <Button>
              <NoteForm.PaperclipLabel htmlFor="file-input">
                <NoteForm.ClipIcon />
              </NoteForm.PaperclipLabel>
            </Button>
          </ButtonTooltip>
          <Button width={90} onClick={() => handleSubmitWithCheck()}>
            Save Note
          </Button>
        </NoteForm.Controls>
        {mentionAnchorEl && (
          <MentionWindow
            mentionInput={mentionInput}
            usersByEmailName={usersByEmailName}
            anchorEl={mentionAnchorEl}
            handleClose={handleMentionWindowClose}
            handleMentionChoose={handleMentionChoose}
          />
        )}
      </NoteForm.Form>
    </NoteForm.Container>
  );
};

NoteForm.Container = styled.div`
  width: 100%;
  padding: 10px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
  position: relative;
`;

NoteForm.Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato', sans-serif;
  position: relative;
`;

NoteForm.Header = styled.div`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

NoteForm.Textarea = styled.textarea`
  background: #fff;
  border: 1px solid #c1c1c1;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  resize: none;
  width: 100%;
  padding: 10px;
`;

NoteForm.Controls = styled.div`
  width: 135px;
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
`;

NoteForm.FileInput = styled.input`
  display: none;
`;

NoteForm.PaperclipLabel = styled.label`
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
`;

NoteForm.Files = styled.div`
  width: 100%;
`;

NoteForm.File = styled.div`
  border: 1px solid #d5d5d5;
  border-radius: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

NoteForm.FileName = styled.div`
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
`;

NoteForm.ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

NoteForm.ClipIcon = styled(FaPaperclip)`
  width: 15px;
  height: 100%;
`;

export default NoteForm;
