import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { AddEventMenuIcon } from 'components/Icons';

import useMenu from 'hooks/useMenu';
import {
  capacityChangeAttributes,
  eventsAttributes,
  varianceEventAttributes,
} from 'components/ButtonTooltip/buttonTooltipAttributes';
import ButtonTooltip from 'components/ButtonTooltip/ButtonTooltip';

type AddEventMenuProps = {
  isAllowedEditAllocIssues: boolean;
  additionModeOn: (type: string) => void;
  allocIssuesVisibility: boolean;
  isAllowAllocIssues: boolean;
  isAllowedEditCapChanges: boolean;
  isAllowedEditVarEvents: boolean;
};

const AddEventMenu = ({
  isAllowedEditAllocIssues,
  additionModeOn,
  allocIssuesVisibility,
  isAllowAllocIssues,
  isAllowedEditCapChanges,
  isAllowedEditVarEvents,
}: AddEventMenuProps) => {
  const menuEl = React.useRef(null);
  const addEventMenuButtonEl = React.useRef<HTMLDivElement>(null);

  const [menuWrapper, setMenuWrapper] = React.useState<HTMLDivElement | null>(
    null,
  );

  const [isMenuOpen, onMenuHover, onMenuLeave, onTargetClick, closeMenu] =
    useMenu(menuEl);

  const handleMenuItemChoose = React.useCallback(
    e => {
      additionModeOn(e);
      closeMenu();
    },
    [additionModeOn, closeMenu],
  );

  const coordinates = addEventMenuButtonEl.current
    ? addEventMenuButtonEl.current.getBoundingClientRect()
    : ({} as DOMRect);
  const offset = menuWrapper
    ? menuWrapper.getBoundingClientRect()
    : ({} as DOMRect);
  const anchorX =
    R.isEmpty(coordinates) && R.isEmpty(offset)
      ? 0
      : coordinates.right - offset.width - 10;

  return (
    <AddEventMenu.Wrapper ref={menuEl}>
      <div ref={addEventMenuButtonEl}>
        <ButtonTooltip content={eventsAttributes}>
          <AddEventMenu.IconButton
            onClick={onTargetClick}
            disabled={
              !(
                isAllowedEditCapChanges ||
                isAllowedEditVarEvents ||
                (isAllowedEditAllocIssues && isAllowAllocIssues)
              )
            }
          >
            <AddEventMenuIcon />
          </AddEventMenu.IconButton>
        </ButtonTooltip>
      </div>
      {isMenuOpen && (
        <AddEventMenu.MenuWrapper
          anchorY={coordinates.bottom}
          anchorX={anchorX}
          offsetX={offset.width}
          onMouseLeave={onMenuLeave}
          onMouseEnter={onMenuHover}
          ref={el => setMenuWrapper(el)}
        >
          <AddEventMenu.Title>Add Event:</AddEventMenu.Title>
          <AddEventMenu.ButtonWrapper>
            {isAllowedEditCapChanges && (
              <ButtonTooltip content={capacityChangeAttributes}>
                <AddEventMenu.PlusButton
                  onClick={() => handleMenuItemChoose('capacity')}
                >
                  Capacity Change
                </AddEventMenu.PlusButton>
              </ButtonTooltip>
            )}
            {isAllowedEditVarEvents && (
              <ButtonTooltip content={varianceEventAttributes}>
                <AddEventMenu.PlusButton
                  onClick={() => handleMenuItemChoose('variance')}
                >
                  Variance Event
                </AddEventMenu.PlusButton>
              </ButtonTooltip>
            )}
            {isAllowedEditAllocIssues && isAllowAllocIssues && (
              <AddEventMenu.PlusButton
                onClick={() => handleMenuItemChoose('allocIssue')}
                disabled={!allocIssuesVisibility}
              >
                Allocation Issue
              </AddEventMenu.PlusButton>
            )}
          </AddEventMenu.ButtonWrapper>
        </AddEventMenu.MenuWrapper>
      )}
    </AddEventMenu.Wrapper>
  );
};

AddEventMenu.Wrapper = styled.div`
  pointer-events: auto;
`;

AddEventMenu.MenuWrapper = styled.div`
  position: fixed;
  top: ${props => props.anchorY + 4}px;
  left: ${props => props.anchorX}px;
  padding: 10px 10px 0 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background: white;
  z-index: 999;
  pointer-events: auto;
`;

AddEventMenu.ButtonWrapper = styled.div`
  padding: 10px;
`;

AddEventMenu.Title = styled.span`
  margin: 0 0 10px 10px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
`;

AddEventMenu.PlusButton = styled(Button)`
  margin-bottom: 10px;
  padding: 3px;
  height: 31px;
  width: 130px;
  background-color: #f0f0f0;
  pointer-events: auto;
`;

AddEventMenu.IconButton = styled(Button)`
  padding: 0;
  height: 31px;
  width: 31px;
  background-color: #f0f0f0;
  pointer-events: auto;
`;

export default AddEventMenu;
