import { scaleTime } from 'd3-scale';
import { timeDay } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';

interface OverlayNotesProdDayProps {
  notesModeOff: () => void;
  extremeDates: { min: Date; max: Date };
  height: number;
  leftOffset: number;
  setNotesProductionDay: (day: Date) => void;
  width: number;
}

const OverlayNotesProdDay = (
  {
    notesModeOff,
    extremeDates,
    height,
    leftOffset,
    setNotesProductionDay,
    width,
  }: OverlayNotesProdDayProps,
  ref,
) => {
  const xScale = React.useMemo(
    () =>
      scaleTime()
        .range([0, width])
        .domain([extremeDates.min, extremeDates.max]),
    [extremeDates, width],
  );

  const [linePosition, setLinePosition] = React.useState(0);
  const moveLine = React.useCallback(
    e => {
      const newPosition = e.pageX - leftOffset - Y_AXIS_WIDTH - 10;
      setLinePosition(newPosition);
    },
    [leftOffset, setLinePosition],
  );
  const hideLine = () => setLinePosition(0);

  const handleClick = React.useCallback(
    e => {
      e.persist();
      const pointerPosition = e.pageX - leftOffset - Y_AXIS_WIDTH - 10;
      const day = timeDay.round(xScale.invert(pointerPosition));
      setNotesProductionDay(day);
      notesModeOff();
    },
    [leftOffset, notesModeOff, setNotesProductionDay, xScale],
  );

  return (
    <OverlayNotesProdDay.Container height={height}>
      <svg
        ref={ref}
        preserveAspectRatio="none"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <g
          onClick={handleClick}
          onMouseMove={e => moveLine(e)}
          onMouseOver={e => moveLine(e)}
          onMouseLeave={hideLine}
        >
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={process.env.DEBUG ? 'rgba(0, 150, 150, 0.5)' : 'transparent'}
          />

          <OverlayNotesProdDay.Line
            x1={0}
            y1={0}
            x2={0}
            y2={height}
            transform={`translate(${linePosition}, 0)`}
            strokeDasharray="2"
          />
        </g>
      </svg>
    </OverlayNotesProdDay.Container>
  );
};

OverlayNotesProdDay.Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 75;
`;

OverlayNotesProdDay.Line = styled.line`
  stroke: ${(props: Record<string, any>) => props.theme.colors.activeInput};
  stroke-width: 2;
`;
export default React.memo<OverlayNotesProdDayProps & { ref: any }>(
  React.forwardRef<Element, OverlayNotesProdDayProps>(OverlayNotesProdDay),
);
