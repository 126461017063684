export const calculateVariancePercentage = ({
  capacity,
  variance,
  withoutRound,
}: {
  capacity: number;
  variance: number;
  withoutRound?: boolean;
}) => {
  if (!withoutRound)
    return capacity ? ((variance / capacity) * 100).toFixed(1) : '0.0';
  return capacity ? String((variance / capacity) * 100) : '0.0';
};
