import { RouterState } from 'connected-react-router';
import { VARIANCE_TRELLIS } from 'modules/chart/models/chart';
import {
  OIL,
  GAS,
  WATER,
  BOE,
  GOR,
  WATERCUT,
  GLR,
  TOTAL_LIQUID,
} from 'modules/phase/models/phase';
import { SearchParams } from '../utils/router';

export const CORESERIES_BIT_MAP = {
  [BOE]: '1',
  [OIL]: '2',
  [GAS]: '3',
  [GOR]: '4',
  [WATER]: '5',
  [WATERCUT]: '6',
  [GLR]: '7',
  [TOTAL_LIQUID]: '8',
  [VARIANCE_TRELLIS]: '9',
};

export const TRELLISES_BIT_MAP = {
  [BOE]: 1,
  [OIL]: 2,
  [GAS]: 4,
  [GOR]: 8,
  [WATER]: 16,
  [WATERCUT]: 32,
  [GLR]: 64,
  [TOTAL_LIQUID]: 128,
  [VARIANCE_TRELLIS]: 256,
};

export enum EventPanel {
  ribbon,
  variance,
  capacity,
}

export type EventPanelData = {
  id: string;
  type: EventPanel;
};

export type RouterQueryParameters = {
  pathname: string;
  search: SearchParams;
  meta: {
    dataSeriesOptionsGroups?: any[];
  };
};

export type CustomRouterState = RouterState & {
  dashboardQueryParameters: RouterQueryParameters;
};
